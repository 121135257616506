<template>
    <div>
        <slot></slot>

        <f7-row class="margin-bottom">
            <f7-col>
                <label>Seccion</label>
                <f7-input
                    type="select"
                    @change="searchExams('section_id', $event.target.value)"
                    :value="search.seccion"
                >
                    <option value="">Seleccione...</option>
                    <option value="1">Anatomía patológica</option>
                    <option value="2">Biología molecular</option>
                    <option value="3">Citometría de flujo</option>
                    <option value="4">Inmunología</option>
                </f7-input>
            </f7-col>
            <f7-col>
                <label>Estado</label>
                <f7-input
                    type="select"
                    @change="searchExams('currentStatus', $event.target.value)"
                    :value="search.estado"
                >
                    <option value="">Seleccione...</option>
                    <option value="admitido">Admitido</option>
                    <option value="pendiente">Pendiente</option>
                    <option value="rechazado">Rechazado</option>
                    <option value="en_lab">En Laboratorio</option>
                </f7-input>
            </f7-col>
        </f7-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            search: {
                seccion: "",
                estado: "",
            },
        };
    },

    methods: {
        searchExams(property, value) {
            this.$f7.dialog.preloader("Buscando exámenes...");

            this.$firebase
                .database()
                .ref("/admExams")
                .orderByChild(property)
                .equalTo(value)
                .once("value")
                .then((snapshot) => {
                    const exams = snapshot.val() || {};

                    this.$f7.dialog.close();

                    if (Object.keys(exams).length === 0) {
                        this.$f7.dialog.alert("No se han encontrado exámentes");
                    } else {
                        this.$emit("exams-found", exams);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Hubo un error al buscar los exámenes. " + error);
                });
        },
    },
};
</script>
