<template>
    <f7-page name="Insumos" >
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title >Insumos</f7-nav-title>
            <f7-nav-right>
                <f7-link icon-material="refresh" tooltip="Recargar Lista" ></f7-link>
                <f7-link class="searchbar-enable" data-searchbar=".searchbar-demo" icon-material="search"></f7-link>
            </f7-nav-right>
            <f7-searchbar
                class="searchbar-demo"
                expandable
                remove-diacritics
                search-container=".search-list"
                search-in=".item-title"
                :disable-button="!$theme.aurora"
                placeholder="Buscar Insumos"
            ></f7-searchbar>
        </f7-navbar>

        <f7-list class="searchbar-not-found">
            <f7-list-item title="No hay coincidencias"></f7-list-item>
        </f7-list>

        <div class="no-margin-top search-list searchbar-found list media-list">
            <ul>
                <template v-for="(supplie, index) in $store.state.supply">
                    <li class="media-item" :key="index"  @click="detailView(supplie.id)">
                        <a href="#" class="item-link">
                            <div class="item-content">
                                <div class="item-inner">
                                    <div class="item-title-row">
                                        <div class="item-title">{{supplie.description}}
                                          
                                        </div>
                                    </div>
                                    <div class="item-subtitle">
                                    <f7-badge v-if="supplie.active == 0" color="red">Inhabilitado</f7-badge>
                                    <f7-badge v-if="supplie.active == 1" color="green">Habilitado</f7-badge>
                                    </div>
                                    <div class="item-subtitle">{{supplie.unit}}</div>
                                    <div class="item-text">
                                        {{supplie.detail}}
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                </template>
            </ul>
        </div>

        

        <f7-fab position="right-bottom" slot="fixed" color="teal">
            <f7-icon material="add"></f7-icon>
            <f7-icon ios="f7:close" aurora="f7:close" md="material:close"></f7-icon>
            <f7-fab-buttons position="top">
                <f7-fab-button @click="newView"  fab-close label="Crear nuevo insumo">
                    <f7-icon material="playlist_add"></f7-icon>
                </f7-fab-button>
            </f7-fab-buttons>
        </f7-fab>
    </f7-page>
    
</template>

<script>

export default {
    components: {

    },
    methods: {
        newView(){
           this.$f7.views.main.router.navigate('/supply/new');
        },
        detailView(id){
           this.$f7.views.main.router.navigate('/supply/'+id);
        }
    },
    mounted() {

        this.$firebase.auth().currentUser.getIdToken().then((token) => {
            this.$store.dispatch("updateState",{ token , state : [
                {"path" : "universal/?model=Provider", "model" : "provider"},
                {"path" : "universal/?model=ExamSection", "model" : "section"},
                {"path" : "universal/?model=Supply", "model" : "supply"},
                {"path" : "universal/?model=Brand", "model" : "brand"},
            ]});
        });
    }

}
</script>

<style scoped>
.color-green{
    background: #2d968c !important;
}
.color-red{
    background: transparent;
    color: black;
    border: 1px #2d968c solid;
}
</style>