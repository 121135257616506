<template>
    <f7-card>
        <f7-card-header>
            Admisión seleccionada
        </f7-card-header>
        <f7-list media-list>
            <f7-list-item 
                :header="'Institución: ' + selectedAdmission.institution" 
                :title="selectedAdmission.patient ? lastNamesAndNames(selectedAdmission.patient) : 'No hay paciente seleccionado'" 
                :subtitle="selectedAdmission.patient && formatId(selectedAdmission.patient)"
            >
            </f7-list-item>
        </f7-list>
    </f7-card>
</template>

<script>
import { mapState } from 'vuex';
import { paxhelpersmixin } from '../../mixins/paxhelpersmixin';

export default {
    computed: {
        ...mapState(['selectedAdmission'])
    },

    mixins:[ 
        paxhelpersmixin,
    ],
}
</script>