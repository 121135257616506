<template>
    <f7-page name="members">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title>Miembros</f7-nav-title>
        </f7-navbar>

        <f7-list class="no-margin-top" media-list>
            <f7-list-item
                v-for="miembro in allMembers"
                :key="miembro.email"
                :media="miembro.photoURL"
                :title="miembro.displayName"
                :subtitle="miembro.email"
                link="#"
                @click="viewMemberDetail(miembro)"
                :class="{ lisel: focused_member.email == miembro.email }"
                class="member"
            ></f7-list-item>
        </f7-list>

        <f7-fab position="right-bottom" slot="fixed" color="orange">
            <f7-icon material="add"></f7-icon>
            <f7-icon ios="f7:close" aurora="f7:close" md="material:close"></f7-icon>
            <f7-fab-buttons position="top">
                <f7-fab-button fab-close @click="preaddWhiteList()" label="Agregar email externo">
                    <f7-icon material="person_add"></f7-icon>
                </f7-fab-button>
            </f7-fab-buttons>
        </f7-fab>
    </f7-page>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
    data: function() {
        return {};
    },
    computed: {
        ...mapState(["focused_member", "profile_userInfo"]),
        ...mapGetters(["allMembers"]),
    },
    mounted() {
        //console.log(this.$refs.lavirtual.f7VirtualList);
    },
    methods: {
        viewMemberDetail(member) {
            this.$store.commit("setObjTo", { what: "focused_member", to: member });
            this.$f7.views.main.router.navigate("/member/");
        },
        emailIsValid(email) {
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        },
        preaddWhiteList() {
            this.$f7.dialog.prompt("Ingrese email:", (email) => {
                let preemail = email.trim();
                if (preemail == "" || !this.emailIsValid(preemail)) {
                    return this.$f7.dialog.alert("email no parece válido");
                } else {
                    this.addWhitelisted(preemail);
                }
            });
        },
        //whitelist
        addWhitelisted(lemail) {
            console.log("intentando agregar " + lemail);
            let sinpuntos = lemail.replace(/\./g, " ");
            let toset = {
                by: this.profile_userInfo.uid,
                em: lemail,
                w: Date.now(),
                byn: this.profile_userInfo.displayName,
            };
            this.$firebase
                .database()
                .ref("whitelist/" + sinpuntos)
                .set(toset)
                .then(() => {
                    console.log("agregado");
                })
                .catch((error) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, error.code);
                });
        },
    },
};
</script>
<style>
.lisel {
    border-left: 7px solid #36b5aa;
}
.member img {
    max-width: 40px;
}
</style>
