<template>
    <f7-popup :opened="opened" @popup:closed="$emit('popup-closed')">
        <f7-page>
            <f7-navbar title="Pacientes encontrados">
                <f7-nav-right>
                    <f7-link popup-close color="blue">Cerrar</f7-link>
                </f7-nav-right>
            </f7-navbar>

            <f7-block class="margin-top-half">
                <p>
                    La búsqueda ha arrojado más de un resultado. Seleccione un paciente de la lista.
                </p>

                <f7-list media-list>
                    <f7-list-item
                        checkbox
                        media-item
                        v-for="patient in patients"
                        :key="patient.id"
                        class="thin-list"
                        @change="$emit('patient-selected', patient)"
                    >
                        <div slot="title">
                            {{ formatName(patient.name, patient.lastname_f, patient.lastname_m) }}
                        </div>
                        <div slot="after">
                            {{ getDocumentType(patient.document_type) }}
                            {{ patient.document_number }}
                        </div>
                    </f7-list-item>
                </f7-list>

                <div class="display-flex justify-content-center" v-if="patientsCopy.length > 0">
                    <f7-button fill large raised class="padding-horizontal" @click="loadMorePatients" v-if="pagination.page < pagination.totalPages">
                        Cargar más pacientes
                    </f7-button>
                    <div v-else class="text-color-gray">Se han cargado todos los pacientes encontrados</div>
                </div>
            </f7-block>
        </f7-page>
    </f7-popup>
</template>

<script>
import { _formatName, _getDocumentType } from "@/app/helpers";
import { mapActions } from "vuex";

export default {
    props: {
        opened: Boolean,
        patients: Array,
        pagination: Object,
        patientSearch: Object,
    },

    data() {
        return {
            // Helpers
            formatName: _formatName,
            getDocumentType: _getDocumentType,

            patientsCopy: [],
            paginationCopy: {},
        };
    },

    watch: {
        patients() {
            if (this.patients) this.patientsCopy = this.patients;
        },
        pagination() {
            if (this.pagination) this.paginationCopy = this.pagination;
        },
    },

    methods: {
        ...mapActions(["getAPIInfo"]),

        loadMorePatients() {
            this.$f7.dialog.preloader("Cargando más pacientes...");

            const opts = { ...this.patientSearch, rows_per_page: 50 };
            let queryString = new URLSearchParams(opts).toString();

            const page = this.paginationCopy.page + 1;

            queryString += `&page=${page}`;

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({
                        url: "patients/search?" + queryString,
                        userToken: idToken,
                    });
                })
                .then((response) => {
                    const responseData = response.data;

                    if (responseData.data && responseData.data.length) {
                        this.patientsCopy.push(...responseData.data);
                        this.paginationCopy = responseData.pagination;
                    }

                    this.$f7.dialog.close();
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error);
                });
        },
    },
};
</script>
