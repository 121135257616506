module.exports = {
    namespaced: true,

    state: {
        updatedSolicitud: null,
    },

    mutations: {
        set_updated_solicitud(state, solicitud) {
            state.updatedSolicitud = solicitud;
        },
    },
};
