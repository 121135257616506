import Vuex from "vuex";
import Vue from "vue";
import * as moment from "moment";
const axios = require("axios");

Vue.use(Vuex);

const admissionStore = require("./modules/admission.store");
const unitStore = require("./modules/unit.store");
const reasonsStore = require("./modules/reasons.store");
const agreementsStore = require("./modules/agreements.store");
const institutionsStore = require("./modules/institutions.store");
const examsStore = require("./modules/exams.store");
const plantillasStore = require("./modules/plantillas.store");
const buscadoresCacheStore = require("./modules/buscadores-cache.store");
const solicitudesStore = require("./modules/solicitudes.store");
const resultPdfStore = require("./modules/result-pdf.store");

function getEndpointURL(state, isDevelop) {
    let url;

    if (state.env === "production") {
        url = state.baseUrl;
    } else if (state.env === "development" && !isDevelop) {
        url = state.endpoints.cert;
    } else {
        url = state.endpoints.local;
    }

    return url;
}

export const store = new Vuex.Store({
    modules: {
        admission: admissionStore,
        unit: unitStore,
        reasons: reasonsStore,
        agreements: agreementsStore,
        institutions: institutionsStore,
        exams: examsStore,
        plantillas: plantillasStore,
        resultPdf: resultPdfStore,
        buscadoresCache: buscadoresCacheStore,
        solicitudes: solicitudesStore,
    },

    state: {
        endpoints: {
            local: "http://localhost:3000/",
            cert: "https://inmunocel-api-4ou4hnx7xq-uc.a.run.app/",
        },
        buckets: {
            dev: "gs://inmunoportal-dev",
            prod: "gs://inmunocel-portal.appspot.com/",
        },
        appName: "Portal",
        appUrl: "https://portal.inmunocel.com",
        appVersion: "1.2.9",
        activeTab: "/",
        loginOpened: true,
        profile_userInfo: {},
        misprivx: {},
        defsPrivx: null,
        allMembers: [],
        loadingAvatar: "../static/img/ripple.gif",
        profile_defaultAvatar: "../static/img/default_original.png",
        focused_member: {},
        tempOldAvatar: "",
        env: process.env.NODE_ENV,
        sections: [],
        //cola de trabajo
        focused_section: {},
        focused_orden: null,
        exmsPendFirma: [],
        allMyFirmados: [],
        baseUrl: "https://inmunocel-portal.uc.r.appspot.com/",
        //holder para url de pdf en vista
        focusedPDF: null, //no se puede pasar como prop por que incluye ->/ en la ruta de la url
        viewingFocusedPDFExam: null, //no se puede pasar como prop por que incluye ->/ en la ruta de la url
        //Datos admisión de muestra
        openedAdmissions: null,
        selectedAdmission: {},
        filteredExams: [],
        admittedExam: null,
        previousDoctor: {},
        previousResponsible: {},
        //Datos mantenedor de pacientes
        pacienteSeleccionado: null,
        //Datos mantenedor institución
        allInstitutions: null,
        selectedInstitution: {},
        //Datos mantenedor tipos de convenio
        allAgreements: null,
        selectedAgreement: {},
        //Datos mantenedor de muestras
        allSamples: null,
        selectedSample: {},
        sampleRejections: [],
        //Datos mantenedor de motivos de rechazo
        allReasons: null,
        selectedReason: {},
        //Datos mantenedor de examenes
        allExams: null,
        selectedExam: null,
        examSamplesInfo: null,
        examResults: [],
        //Datos mantenedor de métodos de procesamiento
        processingMethods: null,
        selectedMethod: {},
        //Datos mantenedor agrupador de exámenes
        allExamsPacks: null,
        selectedExamsPack: {},
        //Datos mantenedor de unidades
        allUnits: null,
        selectedUnit: {},
        //PAXS
        allPaxsUsersHolder: [],
        focusedPax: {},
        //resultados
        focusedPacienteForResults: {},
        //pcr covid 19 stuff
        covidMethods: [],
        covidBatchToSign: [],
        instituxToFilter: {},
        covidActiveFilters: [],
        //Datos de Mantenedor de Insumos
        supply: {},
        provider: {},
        section: {},
        equip: {},
        brand: {},
        invoice: {},
        stock: {},
        //Datos de Facturacion
        state: {},

        covidMethods: [],
        covidBatchToSign: [],
        instituxToFilter: {},
        covidActiveFilters: [],

        // Countries
        countries: [],

        // Comunas
        comunas: [],

        previsiones: [],
    },
    getters: {
        getInvoice: (state) => (invoiceId) => {
            return Object.values(state.invoice).find((i) => i.id == invoiceId);
        },
        myAvatar: (state) => {
            return state.profile_userInfo && state.profile_userInfo.photoURL ? state.profile_userInfo.photoURL : state.profile_defaultAvatar;
        },
        allMembers: (state) => {
            let miembrs = state.allMembers;
            miembrs.sort((a, b) => {
                let x = a.displayName.toLowerCase();
                let y = b.displayName.toLowerCase();
                if (x < y) {
                    return -1;
                }
                if (x > y) {
                    return 1;
                }
                return 0;
            });
            return miembrs;
        },
        objToArray: (state) => (obj) => {
            let list = [];
            if (state[obj]) {
                list = Object.values(state[obj]);
                list.sort((a, b) => {
                    const x = a.name.toLowerCase();
                    const y = b.name.toLowerCase();
                    if (x > y) {
                        return 1;
                    } else if (x < y) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
            }
            return list;
        },
        sortArray: (state) => (array) => {
            let list = [];
            if (state[array]) {
                list = state[array].slice();
                list.sort((a, b) => {
                    const x = a.name.toLowerCase();
                    const y = b.name.toLowerCase();
                    if (x > y) {
                        return 1;
                    } else if (x < y) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
            }
            return list;
        },
        age: (state) => {
            if (state.pacienteSeleccionado && state.pacienteSeleccionado.fechaNacimiento) {
                let age = moment(state.pacienteSeleccionado.fechaNacimiento, "DD/MM/YYYY");
                let today = moment();
                let years = today.diff(age, "years");
                age.add(years, "y");
                let months = today.diff(age, "months");
                if (years === 1) {
                    if (months === 1) {
                        return `${years} año y ${months} mes`;
                    } else {
                        return `${years} año y ${months} meses`;
                    }
                } else if (months === 1) {
                    return `${years} años y ${months} mes`;
                } else {
                    return `${years} años y ${months} meses`;
                }
            }
        },
        exsConMustrsPendIngrASeccion: (state) => {
            return state.exmsPendFirma.filter((unexam) => {
                let samples = unexam.samples || {};
                let samplesArry = Object.values(samples) || [];
                let faltaAlguno = samplesArry.some((unasample) => {
                    return !unasample.sections || !unasample.sections[state.focused_section.id];
                });
                return faltaAlguno;
            });
        },
        oppositeexsConMustrsPendIngrASeccion: (state, getters) => {
            return state.exmsPendFirma.filter((unexam) => {
                let found = getters.exsConMustrsPendIngrASeccion.find((eseexam) => eseexam.id == unexam.id);
                return !found;
            });
        },
        arrExsAunNoIngresados: (state) => {
            return state.exmsPendFirma.filter((unexam) => {
                return !unexam.ingresado;
            });
        },
        arrExsPendFirma: (state) => {
            return state.exmsPendFirma.filter((unexam) => {
                return unexam.ingresado;
            });
        },
        getFromArrayByProVal: (state) => (valor, arreglo, propiedad) => {
            return state[arreglo].find((uno) => uno[propiedad] === valor);
        },

        getExam: (state) => (id) => {
            if (!id) {
                return null;
            }

            const foundExam = state.allExams.find((e) => e.id == id);

            return foundExam;
        },
        getExamSample: (state, getters) => (examId, sampleId) => {
            if (!examId || !sampleId) {
                return null;
            }

            const foundExam = getters.getExam(examId);

            if (!foundExam) {
                return null;
            }

            const foundSample = foundExam.samples.find((s) => s.id == sampleId);

            return foundSample;
        },

        getCountry: (state) => (id) => {
            if (!id) {
                return null;
            }

            const foundCountry = state.countries.find((c) => c.id == id);
            return foundCountry;
        },
        getComuna: (state) => (id) => {
            if (!id) {
                return null;
            }

            const foundComuna = state.comunas.find((c) => c.id == id);
            return foundComuna;
        },
        getPrevision: (state) => (id) => {
            if (!id) return {};

            const foundPrevision = state.previsiones.find((p) => p.id === id);

            if (!foundPrevision) return {};

            return foundPrevision;
        },
        getInstitution: (state) => (id) => {
            if (!id) {
                return null;
            }

            const foundInstitution = state.allInstitutions.find((c) => c.id == id);
            return foundInstitution;
        },

        allActiveInstitutions(state) {
            if (state.allInstitutions) {
                return state.allInstitutions.filter((i) => i.enabled === 1);
            }

            return [];
        },
    },
    mutations: {
        conactToArray: (state, payload) => {
            let concatenado = state[payload.array].concat(payload.value);
            state[payload.array] = concatenado;
        },
        setWhatTo: (state, payload) => {
            state[payload.what] = payload.to;
        },
        setObjTo: (state, payload) => {
            state[payload.what] = Object.assign({}, payload.to);
        },
        pushToArray: (state, payload) => {
            state[payload.array].push(payload.value);
        },
        removeFromArray: (state, payload) => {
            let indiexist = state[payload.array].findIndex((unitem) => unitem[payload.attr] == payload.value);
            if (indiexist != -1) {
                state[payload.array].splice(indiexist, 1);
            }
        },
        removeFromArraySimple: (state, payload) => {
            if (state[payload.array].includes(payload.value)) {
                state[payload.array].splice(state[payload.array].indexOf(payload.value), 1);
            }
        },
        replaceInArray: (state, payload) => {
            let index = state[payload.array].findIndex((item) => item[payload.attr] == payload.value);
            if (index !== 1) {
                state[payload.array].splice(index, 1, payload.newValue);
            }
        },
        updateMyAvatar: (state, avatarURL) => {
            state.profile_userInfo.photoURL = avatarURL;
        },
        updateObjectAttribute: (state, payload) => {
            let neoobjs = {};
            neoobjs[payload.attr] = payload.value;
            state[payload.obj] = Object.assign({}, state[payload.obj], neoobjs);
        },
        deleteObjectAttribute: (state, payload) => {
            let obj = { ...state[payload.obj] };
            delete obj[payload.attr];
            state[payload.obj] = obj;
        },
        populateInstituxToFilter: (state, payload) => {
            if (!state.instituxToFilter[payload.id]) {
                let neoobjs = {};
                neoobjs[payload.id] = payload.value;
                state.instituxToFilter = Object.assign({}, state.instituxToFilter, neoobjs);
            }
        },
        updateSuppliesInfo: (state, payload) => {
            state.supplies = payload;
        },
        updateProvidersInfo: (state, payload) => {
            console.log(payload);
            state.providers = payload;
        },
        updateMeasuresInfo: (state, payload) => {
            state.measures = payload;
        },
        updateState: (state, payload) => {
            state[payload.state] = payload.value;
            console.log(state);
        },

        updateSelectedExam(state, payload) {
            const { examCode, enabled } = payload;

            Vue.set(state.selectedExam, "enabled", enabled);

            const index = state.allExams.findIndex((e) => e.code === examCode);

            const updatedExam = state.allExams[index];

            updatedExam.enabled = enabled;

            state.allExams.splice(index, 1, updatedExam);
        },
        updateSelectedExamResultTypes(state, resultTypes) {
            if (!state.selectedExam) return;
            Vue.set(state.selectedExam, "result_types", resultTypes)

            const examIndex = state.allExams.findIndex((e) => e.id === state.selectedExam.id);
            if (examIndex !== -1) {
                Vue.set(state.allExams[examIndex], "result_types", resultTypes);
            }
        },
        deleteQualititaiveResult(state, payload) {
            const { examId, resultTypeValueId } = payload;

            const examIndex = state.allExams.findIndex((e) => e.id === examId);

            if (examIndex !== -1) {
                const resultadosCualitativosIndex = state.allExams[examIndex].result_types.findIndex((rt) => rt.name === "cualitativo");

                if (resultadosCualitativosIndex !== -1) {
                    const resultTypeValueIndex = state.allExams[examIndex].result_types[resultadosCualitativosIndex].result_type_values.findIndex((rtv) => rtv.id === resultTypeValueId);

                    if (resultTypeValueIndex !== -1) {
                        state.allExams[examIndex].result_types[resultadosCualitativosIndex].result_type_values.splice(resultTypeValueIndex, 1);
                    }
                }
            }
        },

        disableOnePaxAccount(state, payload) {
            const { uid, disabled } = payload;
            const paxAccountIndex = state.allPaxsUsersHolder.findIndex((pax) => pax.uid == uid);

            if (paxAccountIndex === -1) return;

            Vue.set(state.allPaxsUsersHolder, paxAccountIndex, {
                ...state.allPaxsUsersHolder[paxAccountIndex],
                disabled: disabled,
            })

            if (state.focusedPax && state.focusedPax.uid == uid) {
                state.focusedPax = {
                    ...state.focusedPax,
                    disabled: disabled,
                }
            }
        }
    },
    actions: {
        getAPIInfo({ state }, payload) {
            const url = getEndpointURL(state, this._vm.$isDevelop);

            return axios.get(url + payload.url, {
                headers: { Authorization: "Bearer " + payload.userToken },
                responseType: payload.responseType,
            });
        },

        createNewInfo({ state }, payload) {
            const url = getEndpointURL(state, this._vm.$isDevelop);

            return axios.post(url + payload.url, payload.data, {
                headers: {
                    Authorization: "Bearer " + payload.userToken,
                    "Content-Type": "application/json",
                },
            });
        },
        // todo: renombrar a updateAPIInfo
        updateInfo({ state }, payload) {
            const url = getEndpointURL(state, this._vm.$isDevelop);

            return axios.patch(url + payload.url, payload.data, {
                headers: {
                    Authorization: "Bearer " + payload.userToken,
                    "Content-Type": "application/json",
                },
            });
        },
        deleteAPIInfo({ state }, payload) {
            const url = getEndpointURL(state, this._vm.$isDevelop);

            return axios.delete(url + payload.url, {
                headers: { Authorization: "Bearer " + payload.userToken },
            });
        },
        async toast(store, instance) {
            instance.app.$f7.toast
                .create({
                    text: instance.text,
                    closeTimeout: 3000,
                    destroyOnClose: true,
                    position: "center",
                    horizontalPosition: "center",
                })
                .open();
        },

        async uploadFile({ dispatch }, payload) {
            let preloaderAdmission = "";

            //if(payload.alert ?? false){ dispatch('toast',{ app : payload.app, text : 'Subiendo archivo' }) }

            if (payload.alert ?? false) {
                preloaderAdmission = payload.app.$f7.dialog.preloader("Subiendo archivo");
            }

            await payload.app.$firebase
                .app()
                .storage("gs://inmunoportal-dev")
                .ref(payload.path + payload.file.name)
                .put(payload.file);

            if ((payload.app ?? false) != false) {
                preloaderAdmission.close();
            }

            return await payload.app.$firebase
                .app()
                .storage("gs://inmunoportal-dev")
                .ref(payload.path + payload.file.name)
                .getDownloadURL();
        },

        async apiRequest({ state, dispatch }, payload) {
            let defaultSend = "Enviado informacion...";
            let defaultReceive = "Accion procesada correctamente...";
            let defaultError = "Ha ocurrido un error inesperado...";
            let preloaderAdmission = "";

            //if((payload.app ?? false) != false){ dispatch('toast', { app : payload.app, text : ( (payload.request) ? payload.request.before ?? defaultSend : defaultSend )})}

            if ((payload.app ?? false) != false) {
                preloaderAdmission = payload.app.$f7.dialog.preloader(payload.request ? payload.request.before ?? defaultSend : defaultSend);
            }

            const response = await fetch(getEndpointURL(state, this._vm.$isDevelop) + payload.url, {
                method: payload.method,
                headers: { Authorization: "Bearer " + payload.token, "Content-Type": "application/json" },
                body: JSON.stringify(payload.data),
            });

            let body = await response.json();

            if ((payload.app ?? false) != false) {
                preloaderAdmission.close();
            }

            return {
                code: await response.status,
                info: body,
            };
        },

        async updateState({ state, commit }, payload) {
            try {
                payload.state.forEach(async (stat) => {
                    const response = await fetch(getEndpointURL(state, this._vm.$isDevelop) + stat.path, {
                        method: "GET",
                        headers: { Authorization: "Bearer " + payload.token },
                    });
                    const result = await response.json();
                    const model = stat.model;
                    commit("updateState", { state: model, value: result.data });
                });
            } catch (error) {
                console.log(error);
            }
        },
    },
});
