<template>
    <f7-page name="Facturas" >
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title >Facturas</f7-nav-title>
        </f7-navbar>
        <f7-block>
            <f7-row class="mb-2">
                <f7-col><f7-button @click="openModal('.newInvoice')" btn fill >Agregar factura</f7-button></f7-col>
                <f7-col></f7-col>
                <f7-col/>
                <f7-col/>
                <f7-col/>
                <f7-col/>
            </f7-row>
            <f7-row>
                <f7-col>
                    <f7-list no-hairlines-md>
                        <f7-list-input required type="select" label="Filtro de busqueda" :value="table.searchFilter" @change="preSearch($event.target.value)">
                            <option value="state">Estado</option>
                            <option value="code">Codigo</option>
                            <option value="provider">Proveedor</option>
                            <option value="date">Fecha</option>
                        </f7-list-input>                        
                    </f7-list>
                </f7-col>
                <f7-col>
                    <f7-list no-hairlines-md>
                        <f7-list-input @input="preFilter($event.target.value)" label="Informacion a buscar" type="text" :value="table.searchData" placeholder="Busqueda...."></f7-list-input>
                    </f7-list>
                </f7-col>
                <f7-col>
                    <f7-list no-hairlines-md>
                        <f7-list-input :maxlength="10" :value="table.from | formatDate" placeholder="dd/MM/AAAA"  label="Desde" type="text" @input="changeDate( 'from' ,$event.target.value)"></f7-list-input>
                    </f7-list>
                </f7-col>
                <f7-col>
                    <f7-list no-hairlines-md>
                        <f7-list-input :maxlength="10" :value="table.to | formatDate" placeholder="dd/MM/AAAA" label="Hasta" type="text" @input="changeDate('to' ,$event.target.value)" ></f7-list-input>
                    </f7-list>
                </f7-col>
                <f7-col/>
                <f7-col width="10">
                    <f7-list no-hairlines-md>
                        <f7-list-input required type="select" label="Mostrar" :value="table.perPage" @change="perPageChange($event.target.value)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                        </f7-list-input>                        
                    </f7-list>
                </f7-col>
            </f7-row>
            <f7-row>
                <f7-col>
                    <div class="data-table card" style="min-height: 60vh; max-height: 60vh">
                        <table style="overflow-y:scroll">
                            <thead>
                                <tr>
                                    <td style="width:20%" >Estado</td>
                                    <td style="width:30%" >Codigo</td>
                                    <td style="width:20%" >Proveedor</td>
                                    <td style="width:20%" >Fecha</td>
                                    <td class="align-center" style="width:5%" ><f7-icon f7="doc_fill"/></td>
                                    <td class="align-center" style="width:5%" ><f7-icon f7="pencil"/></td>
                                    <td class="align-center" style="width:5%" ><f7-icon f7="trash"/></td>
                                </tr>
                            </thead>
                            <tbody  style="overflow-y: scroll">
                                <tr v-for="(item) in table.pages[table.page - 1]" :key="item.id" :style="background(item.state_id)">
                                    <td>
                                        <f7-row>
                                            <f7-col>
                                                <f7-input required type="select" :value="item.state_id" @change="stateInvoice($event.target.value, item.id)">
                                                    <option v-for="(item) in $store.state.state" :key="item.id" :value="item.id">{{ item.description }}</option>
                                                </f7-input>               
                                            </f7-col>
                                        </f7-row>
                                    </td>
                                    <td>{{ item.code }}</td>
                                    <td>{{ item.provider }}</td>
                                    <td>{{ item.date }}</td>
                                    <td>
                                       <f7-row>
                                           <f7-col/>
                                             <f7-link icon-f7="doc_fill" icon-size="25" target="_blank" btn outline v-if="invoiceFile(item.file)" :href="item.file" external></f7-link>

                                            <f7-col/>
                                        </f7-row>
                                    </td>
                                    <td>
                                       <f7-row>
                                           <f7-col/>
                                            <f7-col>
                                                <f7-button small fill @click="invoiceDetail(item.id)" ><f7-icon class="icon" f7="pencil" sm /></f7-button>
                                            </f7-col>
                                            <f7-col/>
                                        </f7-row>
                                    </td>
                                    <td>
                                       <f7-row>
                                           <f7-col/>
                                            <f7-col>
                                                 <f7-button small outline @click="elimInvoice(item.id)" ><f7-icon class="icon" f7="trash" sm /></f7-button>
                                            </f7-col>
                                            <f7-col/>
                                        </f7-row>
                                    </td>
                                 </tr>
                            </tbody>
                           
                        </table>
                    </div>
                </f7-col>
            </f7-row>
            <f7-row>
                <f7-col>
                    <div class="data-table card">
                        <f7-row style="padding:1%">
                            <f7-col>
                                <f7-button :disabled="enablePre" @click="table.page--" small btn outline>Anterior</f7-button>
                            </f7-col>
                            <f7-col width="5">
                                    <f7-input type="number" min="1" :max="limitPage" @input="table.page = $event.target.value" :value="table.page" />

                            </f7-col>
                            <f7-col>
                                <f7-button :disabled="enableNext"  @click="table.page++" small btn outline>Siguiente</f7-button>
                            </f7-col>
                            <f7-col style="text-align:center" width="25">mostrando pagina {{ this.table.page}} de {{ (this.table.pages.length == 0) ? 1 :this.table.pages.length  }}, Total: {{ totalRegisters }} Registros</f7-col>
                            
                            <f7-col></f7-col>
                            <f7-col></f7-col>
                            <f7-col></f7-col>
                            <f7-col></f7-col>
                        </f7-row>
                    </div>
                </f7-col>
            </f7-row>
        </f7-block>
        <f7-popup class="newInvoice"><newInvoice :reboot="reboot" @updateTable="updateData()"></newInvoice></f7-popup>
        <f7-popup class="detailedInvoiceModal"><detailedInvoiceModal @updateTable="updateData()" :invoice="selectedInvoice"></detailedInvoiceModal></f7-popup>
    </f7-page>
    
</template>

<script>
import moment from 'moment';
import newInvoice from './module/newInvoice.vue'
import detailedInvoiceModal from './module/detailInvoice.vue'

export default {
    components : {
        newInvoice,
        detailedInvoiceModal
    },
   
    data(){
        return {
            table : {
                page : 1,
                perPage : 10,
                pages : [],
                searchFilter : "code",
                searchData : "",
                from : '05/01/1992',
                to : '05/01/1992'
            },
            datos : [],
            archive : [],
            temp : "",
            selectedInvoice : 0,
            reboot : false
        }
    },
    mounted() {
        this.init();        
    },
    computed : {
        totalRegisters(){

            let total = 0;

            for(const item of this.table.pages){
                total += parseInt(item.length);
            }

            return total;
        },
        limitPage(){
            return this.table.pages.length;
        },
        enablePre(){    
            return (this.table.page > 1) ? false : true;
        },
        enableNext(){
            return (this.table.page < this.limitPage) ? false : true;
            
        },
        
    },
    methods : {
        changeDate(field,value){

            

            this.table[field] = value

            this.chunkArray()
        },
        background(state){
            let colors = { "2" : "rgba(99, 233, 219,0.4)", "3" : "rgba(254, 158, 203,0.4)", "1" : "" };

            return "background-color : " + colors[state] + ";";
        },
        invoiceFile(value){
            if(value != "" && value != null){
                return true;
            }else{
                return false;
            }
        },
       
        async init(){
            

            await this.$firebase.auth().currentUser.getIdToken().then(async (token) => {
                await this.$store.dispatch("updateState",{ token , state : [
                    {"path" : "universal/?model=Provider", "model" : "provider"},
                    {"path" : "universal/?model=State", "model" : "state"},
                    {"path" : "universal/?model=Invoice&relation="+encodeURIComponent(JSON.stringify(['provider','state'])), "model" : "invoice"}
                ]})
            });
            
            this.table.from = moment().startOf('month').format('DD/MM/YYYY');
            this.table.to = moment().endOf('month').format('DD/MM/YYYY');

            this.updateData();
            
        },
        updateData(){

            this.datos = [];
            this.archive = [];

            this.$firebase.auth().currentUser.getIdToken().then(async (token) => {
             
                let response = await this.$store.dispatch("apiRequest",
                    {   
                        app : this ,
                        url : "universal/?model=Invoice&relation="+encodeURIComponent(JSON.stringify(['provider','state'])) ,
                        token , 
                        method : "GET"
                    }
                );

                response.info.data.reverse()

                if(response.code == 200){
                    response.info.data.forEach(item => {
                        item.provider = item.provider.description.toLowerCase();
                        item.state = item.state.description.toLowerCase();
                        item.date = moment(item.date, 'YYYY-MM-DD H:m:s').format("DD/MM/YYYY")
                        this.temp = item;
                        this.datos.push({...this.temp})
                        this.archive.push({...this.temp})
                    })

                   this.chunkArray()
                }
            });
        },
        chunkArray(){

            var results = [];
            var filtered = [];
            const work = this.datos;
            var fromFilter = [];
            var toFilter = [];
            
            if(this.table.searchData != ""){
                work.forEach(element => {
                  

                    if(element[this.table.searchFilter].toLowerCase().search(this.table.searchData) != -1){
                        filtered.push(element)
                    }
                });
            }else{
                filtered = work;
            }

            if(moment(this.table.from,'MM/DD/YYYY').isValid() === true){
                
                var filterDate = moment(this.table.from,"MM/DD/YYYY")

                for(const item of filtered){
                    var tempDate = moment(item.date,"DD/MM/YYYY")
                   
                    if(tempDate.isAfter(filterDate) || tempDate.isSame(filterDate)){
                        fromFilter.push(item)
                    }

                }
            }else{
                fromFilter = filtered;
            }

            if(moment(this.table.to,'MM/DD/YYYY').isValid() === true){
                var filterDate = moment(this.table.to,"MM/DD/YYYY")

                for(const item of fromFilter){
                    var tempDate = moment(item.date,"DD/MM/YYYY")
                    if(tempDate.isBefore(filterDate) || tempDate.isSame(filterDate)){
                        toFilter.push(item)
                    }

                }
            }else{
                toFilter = fromFilter;
            }



            results = this.chunkArrayInGroups(toFilter, this.table.perPage)
    
            this.table.pages = results;
        },
        chunkArrayInGroups(arr, size) {
            var myArray = [];
            for(var i = 0; i < arr.length; i += size) {
                myArray.push(arr.slice(i, i+size));
            }
            return myArray;
        },
        preFilter(value){
            if(value != ""){
                if(value != "no-value"){
                    this.table.searchData = value;
                }

                this.datos = this.archive;

                this.chunkArray()
            }else{
                this.table.searchData = "";

                this.datos = this.archive;

                this.chunkArray()
            }
           
        },
        preSearch(value){
           
            this.table.searchFilter = value;
            
            this.datos = this.archive;

            this.chunkArray()
        },
        perPageChange(value){
            this.table.perPage = value;
            this.chunkArray()
        },
        invoiceDetail(value){
            this.selectedInvoice = value;
            this.$f7.popup.open(".detailedInvoiceModal");
        },
        elimInvoice(value){
            this.$f7.dialog.confirm('Eliminar factura', '¿Desea eliminr esta factura?', () => {
                this.$firebase.auth().currentUser.getIdToken().then(async (token) => {
                    await this.$store.dispatch("apiRequest",{app : this ,url : "universal/" ,token , method : "PUT", data : {info : {deletedAt : moment().format("YYYY-MM-DD")} , model: "Invoice"  , "search" : value}});
                    this.updateData()
                });
            })
        },
        openModal(modal){
            this.reboot = (this.reboot == true) ? false : true;
            this.$f7.popup.open(modal);
        },
        stateInvoice(value, item){
            this.$f7.dialog.confirm('Estado de factura', '¿Desea actualizar el estado de la factura?', () => {
                this.$firebase.auth().currentUser.getIdToken().then(async (token) => {
                    await this.$store.dispatch("apiRequest",{app : this ,url : "universal/" ,token , method : "PUT", data : {info : {state_id : value} , model: "Invoice"  , "search" : item}});
                    this.updateData()
                });
            })
        }
    }

}
</script>

<style scoped>
.mb-2{
    margin-bottom: 4% !important;
    

}
.mb-1{
    margin-bottom: 3% !important;
    
}
.align-left{
    text-align: left;
}
.align-center{
    text-align: center;
}
.padding-1{
    padding: 0% 1%;
}
.toast{
    left: 45% !important;
}
.toast-text{
    font-size: 1.3em;
}
.newEntry{
    width: 109vh !important;
    left : 43%;
}
.detailed{
    width: 109vh !important;
    left : 43%;
}
.icon{
    font-size: 13px;
}
.no-hairlines-md{
    margin: 0;
}
</style>