<template>
    <f7-block>
        <f7-segmented strong tag="p">
            <f7-button 
                @click="setIdType('RUT')" 
                :active="responsible.idType ==='RUT'"
            >
                RUT
            </f7-button>
            <f7-button 
                @click="setIdType('DNI')" 
                :active="responsible.idType ==='DNI'"
            >
                Otro DNI
            </f7-button>
            <f7-button 
                @click="setIdType('PASAPORTE')" 
                :active="responsible.idType ==='PASAPORTE'"
            >
                Pasaporte
            </f7-button>
        </f7-segmented>
        <f7-list class="margin-top no-margin-bottom" no-hairlines>
            <f7-list-input
                :label="responsible.idType"
                type="text"
                name="responsibleIdentifier"
                :value="responsible.identifier"
                :placeholder="'Ingrese ' + responsible.idType"
                autocomplete="off"
                @input="formatRut($event.target.value)"
                error-message='RUT no válido'
                :error-message-force="invalidRut"
                @blur="validateRut"
            ></f7-list-input>
            <f7-list-input
                label="Nombre responsable"
                type="text"
                name="responsibleName"
                placeholder="Ingrese nombre"
                :value="responsible.name"
                @input="responsible.name = $event.target.value"
                @blur="addResponsibleInfo"
            ></f7-list-input>
        </f7-list>
        <f7-link v-if="previousResponsible.name" class="margin-left" @click="repeatResponsible()">
            Repetir {{previousResponsible.name}}
        </f7-link>
    </f7-block>           	
</template>

<script>
import { validate, format } from 'rut.js';
import { mapState } from 'vuex';

export default {
    data() {
        return {
            responsible: {
                idType: 'RUT',
                identifier: null,
                name: null
            },
            invalidRut: false,
        }
    },

    computed: {
        ...mapState(['previousResponsible']),
    },

    props: ['responsibleData'],

    methods: {
        setIdType(type){
            this.invalidRut = false;
            this.responsible.idType = type;
        },

        formatRut(value){
            this.invalidRut = false;
            if(this.responsible.idType === 'RUT'){
                this.responsible.identifier = format(value); 
            }
            else{
                this.responsible.identifier = value;
            }
        },

        addResponsibleInfo() {
            this.$emit('addResponsibleInfo', this.responsible);
        },

        validateRut(){
            if(this.responsible.idType === 'RUT'){
                if(!validate(this.responsible.identifier)){
                    this.invalidRut = true;
                }
                else{
                    this.invalidRut = false;
                    return
                }
            }
            this.addResponsibleInfo();
        },

        repeatResponsible() {
            this.responsible = this.previousResponsible;
            this.$emit('addResponsibleInfo', this.responsible);
        }

    },
    
    mounted(){
        if(this.responsibleData){
            this.responsible = this.responsibleData;
        }
    }
}
</script>