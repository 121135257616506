<template>
    <f7-page name="rejection-reasons-details">
        <f7-navbar
            title="Detalles motivo de rechazo"
            back-link
            back-link-url="/migration/mantenedores/rejection-reasons"
            back-link-force
        ></f7-navbar>

        <f7-block-title v-if="!viewingRejectionReason || !viewingRejectionReason.id">
            Seleccione algún motivo de rechazo
        </f7-block-title>

        <f7-block v-else>
            <f7-card class="demo-card-header-pic">
            <f7-card-header>
                <f7-block-title medium> {{viewingRejectionReason.name}} </f7-block-title>
            </f7-card-header>
            <f7-card-content>
                <p>{{viewingRejectionReason.description}}</p>
            </f7-card-content>
            <f7-card-footer>
                <f7-button
                    fill
                    small
                    round
                    icon-material="edit"
                    @click="goToEdit"
                    >Editar
                </f7-button>
            </f7-card-footer>
        </f7-card>
        </f7-block>
    </f7-page>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState(["profile_userInfo"]),

        ...mapState("reasons", ["viewingRejectionReason"]),
    },

    methods: {
        goToEdit() {
            this.$f7.views.main.router.navigate("/migration/mantenedores/rejection-reasons/create-or-edit");
        },
    },
};
</script>
