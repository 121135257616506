<template>
    <f7-page class="backgris" name="pax-details">
        <f7-navbar :title="focusedPax.email" :subtitle="focusedPax.displayName" back-link></f7-navbar>

        <f7-list class="unpaxholder" media-list inset>
            <f7-list-item
                :title="`Institución: ${focusedPax.institution ? focusedPax.institution.name : 'No identificada'}`"
                :subtitle="focusedPax.email"
                :text="focusedPax.uid"
            >
                <img :src="focusedPax.photoURL || '../../static/img/default_original.png'" slot="media" />
                <div slot="footer">
                    <f7-button @click="sendResetPasswordEmail" small :disabled="resetPasswordEmailSent" class="margin-top">
                        Restablecer contraseña
                    </f7-button>
                    <f7-button @click="inactivateAccount" small :color="focusedPax.disabled ? 'primary' : 'red'">
                        {{ focusedPax.disabled ? "Activar cuenta" : "Inactivar cuenta" }}
                    </f7-button>
                </div>
            </f7-list-item>
        </f7-list>
    </f7-page>
</template>
<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            resetPasswordEmailSent: false,
        };
    },

    computed: {
        ...mapState(["focusedPax"]),
    },

    watch: {
        "focusedPax.uid": {
            handler(val) {
                if (this.resetPasswordEmailSent) this.resetPasswordEmailSent = false;
            },
        },
    },

    methods: {
        sendResetPasswordEmail() {
            this.$f7.dialog.preloader("Enviando correo para restablecer contraseña a " + this.focusedPax.email);

            this.$paxApp
                .auth()
                .sendPasswordResetEmail(this.focusedPax.email)
                .then(() => {
                    this.resetPasswordEmailSent = true;
                    this.$f7.dialog.close();
                })
                .catch((error) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Error al enviar correo de restablecimiento de contraseña para " + this.focusedPax.email);
                    console.error(error);
                });    
        },

        inactivateAccount() {
            this.$f7.dialog.preloader("Inactivando cuenta PAX...");

            const disablePaxAccount = this.$firebase.functions().httpsCallable("disablePaxAccount");

            disablePaxAccount({ uid: this.focusedPax.uid, disabled: this.focusedPax.disabled })
                .then((response) => {
                    console.log("response", response);

                    const { disabled, message } = response.data;

                    this.$store.commit("disableOnePaxAccount", { uid: this.focusedPax.uid, disabled: disabled });

                    this.$f7.toast.show({
                        text: message,
                        closeTimeout: 2000,
                    });

                    this.$f7.dialog.close();
                })
                .catch((error) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, error.code);
                });
        },
    },
};
</script>
<style>
.unpaxholder img {
    width: 60px;
}
</style>
