module.exports = {
    namespaced: true,

    state: {
        viewingExam: null,
    },

    mutations: {
        set_viewing_exam(state, exam) {
            if (!exam) {
                state.viewingExam = null;
                return;
            }

            state.viewingExam = exam;
        },
    },
};
