<template>
    <div v-if="!previewMode">
        <block-toolbar :block="computedBlockInfo('resultado-free-text-block')"></block-toolbar>

        <f7-block class="no-margin-vertical">
            <f7-row>
                <f7-col>
                    <label><b>Seleccione el exámen</b></label>
                    <f7-input type="select" @change="handleSelectedExam" class="margin-bottom" :disabled="disableInputs">
                        <option value="">Seleccione exámen...</option>
                        <option v-for="exam in exams" :key="exam.id" :value="exam.id" :selected="exam.id === defaults.examId">{{ exam.name }}</option>
                    </f7-input>
                </f7-col>
                <f7-col>
                    <div v-if="!selectedExam">
                        <p class="text-color-gray">Debe seleccionar un exámen</p>
                    </div>
                    <div v-else-if="selectedExam && !hasResultadoFreeText">
                        <p class="text-color-red"><b>Este exámen no tiene resultado de texto libre</b></p>
                    </div>
                    <div v-else>
                        <label><b>Resultado</b></label>
                        <f7-input
                            type="text"
                            @change="handleResultChanged($event.target.value)"
                            @focus="handleFocusChanged(true)"
                            @blur="handleFocusChanged(false)"
                            :value="result"
                            placeholder="Ingrese el resultado"
                            :disabled="disableInputs"
                        ></f7-input>
                    </div>
                </f7-col>
            </f7-row>
        </f7-block>
    </div>
    <div v-else>
        <div v-if="selectedExam">
            <div v-if="translation"><i>Results</i></div>
            <div>
                <b>{{ `Resultado ${isCollection ? selectedExam.name : ""}`.trim() }}</b>
                :
                {{ `${result ? result : "Sin resultado"}` }}
            </div>
        </div>
        <div v-else>Sin examen seleccionado</div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import BlockToolbarMixin from "@/app/components-migration/plantillas/blocks/toolbar/block-toolbar-mixin";
import BlockToolbar from "@/app/components-migration/plantillas/blocks/toolbar/block-toolbar.vue";

export default {
    mixins: [BlockToolbarMixin],

    props: {
        defaults: Object,
        translation: Boolean,
        admissionExam: Object,
        exams: Array,
        previewMode: Boolean,
        disableInputs: Boolean,
    },

    components: {
        "block-toolbar": BlockToolbar,
    },

    data() {
        return {
            selectedExam: null,
            result: null,
        };
    },

    watch: {
        defaults: {
            immediate: true,
            deep: true,
            handler() {
                if (this.defaults.examId) {
                    const exam = this.exams.find((e) => e.id === this.defaults.examId);

                    this.selectedExam = exam;
                }

                if (this.defaults.result) this.result = this.defaults.result;
            },
        },

        exams() {
            if (this.exams.length === 0) {
                this.selectedExam = null;

                this.$emit("resultado-free-text-exam-selected", {
                    examId: "",
                });
                this.$emit("resultado-free-text-result-changed", { value: "" });
            }
        },
    },

    computed: {
        hasResultadoFreeText() {
            if (!this.selectedExam) return false;

            if (!this.selectedExam.result_types) return true;

            if (this.selectedExam.result_types.length === 0) return true;

            return false;
        },

        isCollection() {
            if (!this.admissionExam) return false;
            if (!this.admissionExam.exam) return false;
            if (!this.admissionExam.exam.associations) return false;

            return this.admissionExam.exam.associations.length > 0;
        },
    },

    methods: {
        ...mapActions(["getAPIInfo"]),

        handleSelectedExam(event) {
            const examId = Number(event.target.value);

            const exam = this.exams.find((e) => e.id === examId);

            this.selectedExam = exam;

            this.$emit("resultado-free-text-exam-selected", {
                examId: this.selectedExam ? this.selectedExam.id : "",
            });
        },

        handleResultChanged(value) {
            this.result = value;
            this.$emit("resultado-free-text-result-changed", { value });
        },

        handleFocusChanged(value) {
            this.$emit("focus-changed", value);
        },
    },
};
</script>
