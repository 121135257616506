// Import Vue
import Vue from "vue";
import { store } from "../store/store";

// Import Framework7
import Framework7 from "framework7/framework7-lite.esm.bundle.js";

//inicio chelo stuff
import "typeface-titillium-web";

import moment from "moment";
moment.locale("es");
Vue.prototype.$moment = moment;

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/functions";
import { fireBaseConfig } from "../helpers/firebaseConfig";
import { devConfig } from "../helpers/devConfig";
const firebaseConfig = process.env.NODE_ENV === "production" ? fireBaseConfig : devConfig;
//const firebaseConfig = fireBaseConfig;

firebase.initializeApp(firebaseConfig);

// if (location.hostname === "localhost") {
//     firebase.functions().useFunctionsEmulator('http://localhost:5001');
// }

Vue.prototype.$firebase = firebase;

// Import inmunopax Firebase config
import { paxFbConfigProd, paxFbConfigDev } from "../helpers/paxConfig";
const paxApp = firebase.initializeApp(process.env.NODE_ENV === "production" ? paxFbConfigProd : paxFbConfigDev, "secondary");

Vue.prototype.$paxApp = paxApp;

// Import Framework7-Vue Plugin
import Framework7Vue from "framework7-vue/framework7-vue.esm.bundle.js";

// Import Framework7 Styles
import "framework7/css/framework7.bundle.css";

// Import Icons and App Custom Styles
import "../css/icons.css";
import "../css/app.css";

// Import App Component
import App from "../components/app.vue";

//importar browser print
import { BrowserPrint } from "../js/BrowserPrint-2.0.0.75.min.js";
Vue.prototype.$BrowserPrint = BrowserPrint;

Vue.prototype.$capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};

import Alert from "../components/helpers/Alert.vue";
Vue.component("f7c-alert", Alert);

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Helpers
Vue.prototype.$timestamp = () => (Date.now() / 1000) | 0;

Vue.prototype.$capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};

Vue.filter("formatDate", (value) => {
    if (!value) return "";
    let date = value.toString();

    if (date.length === 2 || date.length === 5) {
        return (date += "/");
    }

    return date;
});

Vue.filter("formatPrice", (value) => {
    if (!value) return null;

    const formatter = new Intl.NumberFormat("es-CL", {
        currency: "CLP",
        minimumFractionDigits: 0,
    });

    return formatter.format(value);
});

import VueDraggableResizable from "vue-draggable-resizable";
import "vue-draggable-resizable/dist/VueDraggableResizable.css";
Vue.component("vue-draggable-resizable", VueDraggableResizable);

Vue.prototype.$strictPatientValidation = true;
Vue.prototype.$isDevelop = false;
Vue.prototype.$enableLegacyMode = false;

// Init App
new Vue({
    el: "#app",
    render: (h) => h(App),
    store,
    // Register App Component
    components: {
        app: App,
    },
});
