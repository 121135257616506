<template>
    <f7-popup
        :id="_uid"
        :opened="opened"
        @popup:opened="handlePopupOpened"
        @popup:closed="handlePopupClosed"
        :close-by-backdrop-click="mandatory"
    >
        <f7-page>
            <f7-navbar :title="`Elija un motivo de ${reasonText}`">
                <f7-nav-right v-if="!mandatory">
                    <f7-link :popup-close="`#${_uid}`">Cancelar</f7-link>
                </f7-nav-right>
            </f7-navbar>

            <template v-if="!loading">
                <p v-if="!Object.keys(reasons).length" class="margin-horizontal">
                    No existen motivos de momento. Por favor, escriba uno abajo.
                </p>

                <f7c-alert type="warning" v-if="mandatory" class="margin-half">
                    Esta declaración de motivo de {{ reasonText }} es
                    <b>obligatoria</b>
                    debido a que un examen "admitido" no puede quedar sin muestras.
                </f7c-alert>

                <f7-list media-list class="no-margin-top thin-list">
                    <f7-list-item v-for="reason in reasons" :key="reason.id">
                        <div slot="title">
                            {{ reason.name }}
                        </div>

                        <div slot="after">
                            <f7-button @click="select(reason.name)" small>Seleccionar</f7-button>
                        </div>
                    </f7-list-item>

                    <f7-row class="margin-top padding-top margin-horizontal">
                        <f7-col width="80">
                            <f7-input
                                outline
                                type="text"
                                :placeholder="`Nuevo motivo de ${reasonText}`"
                                clear-button
                                @input="newReason = $event.target.value"
                                :value="newReason"
                            ></f7-input>
                        </f7-col>
                        <f7-col width="20">
                            <f7-button fill @click="addReason">Añadir</f7-button>
                        </f7-col>
                    </f7-row>
                </f7-list>
            </template>
            <template v-else>
                <f7-block
                    class="row demo-preloaders align-items-stretch text-align-center no-margin no-padding margin-top"
                >
                    <f7-col>
                        <f7-preloader></f7-preloader>
                    </f7-col>
                </f7-block>
            </template>
        </f7-page>
    </f7-popup>
</template>

<script>
export default {
    props: {
        opened: Boolean,
        type: String,
        mandatory: Boolean,
        useDefault: Boolean
    },

    data() {
        return {
            reasons: {},

            newReason: "",

            loading: false,
        };
    },

    computed: {
        reasonText() {
            switch (this.type) {
                case "rechazado":
                    return "rechazo";

                case "pendiente":
                    return "pendiente";
            }
        },

        dbNode() {
            switch (this.type) {
                case "rechazado":
                    return "/rejections";

                case "pendiente":
                    return "/pendingReasons";
            }
        },

        defaultReason() {
            return Object.values(this.reasons || {}).find((reason) => reason.default === true) || null;
        },
    },

    methods: {
        select(reason) {
            this.$emit("reason-selected", reason);
        },

        addReason() {
            this.$f7.dialog.preloader("Creando motivo...");

            if (!this.newReason.length) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("El nuevo motivo no puede ser vacío.");
                return;
            }

            const key = this.$firebase
                .database()
                .ref()
                .push().key;

            const reasonObject = {
                id: key,
                name: this.newReason,
                enabled: true,
            };

            this.$firebase
                .database()
                .ref(`${this.dbNode}/${key}`)
                .set(reasonObject)
                .then(() => {
                    this.$f7.dialog.close();

                    this.select(this.newReason);
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Error al crear motivo. " + error.message);
                });
        },

        handlePopupClosed() {
            this.reasons = {};
            this.newReason = "";
            this.$emit("popup-closed");
        },

        handlePopupOpened() {
            // fetch reasons depending on type
            this.loading = true;

            this.$firebase
                .database()
                .ref(this.dbNode)
                .orderByChild("enabled")
                .equalTo(true)
                .once("value", (snapshot) => {
                    this.reasons = snapshot.val() || {};
                    this.loading = false;

                    // if should use a default reason and there is a default reason, use it
                    if (this.useDefault && this.defaultReason) {
                        this.select(this.defaultReason.name);

                        this.$f7.toast.show({
                            text: `Se ha marcado el examen como ${this.reasonText} automáticamente (se utilizó el motivo por defecto)`,
                            position: "bottom",
                            horizontalPosition: "center",
                            closeTimeout: 3000,
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Hubo un error al cargar los motivos. " + error);
                });
        },
    },
};
</script>
