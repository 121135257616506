<template>
    <f7-page name="Inventario">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title>Inventario</f7-nav-title>
        </f7-navbar>
        <f7-block>
            <f7-row>
                <f7-col width="15"><f7-button @click="openModal('.newEntry')" btn outline fill>Ingresar Insumo</f7-button></f7-col>
                <f7-col width="15">
                    <f7-button btn outline fill popover-open=".egress-popover">
                        Egreso de insumo
                    </f7-button>
                </f7-col>
                <f7-col width="15"><f7-button @click="openReingress" btn outline fill>Reingreso de insumos</f7-button></f7-col>
                <f7-col width="55">
                    <f7-list no-hairlines-md style="margin: 0">
                        <li class="">
                            <div class="item-content item-input item-input-with-value">
                                <div class="item-inner" style="padding-top: 0">
                                    <div class="item-input-wrap">
                                        <input
                                            :value="table.searchData"
                                            type="text"
                                            placeholder="Filtrar por Descripcion, Proveedores."
                                            @input="preFilter($event.target.value)"
                                        />
                                    </div>
                                </div>
                            </div>
                        </li>
                    </f7-list>
                </f7-col>
            </f7-row>
            <f7-row>
                <f7-col width="30">
                    <f7-list no-hairlines-md>
                        <multiselect :show-labels="false" multiple v-model="table.stockOptions" :options="stockOptions"></multiselect>
                    </f7-list>
                </f7-col>
                <f7-col width="30">
                    <f7-list no-hairlines-md>
                        <multiselect :show-labels="false" multiple v-model="table.sectionOption" :options="sectionOption"></multiselect>
                    </f7-list>
                </f7-col>
                <f7-col width="20" />
                <f7-col width="10">
                    <f7-list no-hairlines-md>
                        <f7-list-input required type="select" label="Mostrar" :value="table.perPage" @change="perPageChange($event.target.value)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                        </f7-list-input>
                    </f7-list>
                </f7-col>
            </f7-row>
            <f7-row>
                <f7-col width="15">
                    <f7-button @click="exportExel()" btn outline fill>Exportar a Exel</f7-button>
                </f7-col>
            </f7-row>
            <f7-row>
                <f7-col>
                    <div class="data-table card" style="min-height: 60vh; max-height: 60vh">
                        <table style="overflow-y:scroll">
                            <thead>
                                <tr>
                                    <td style="width:25%">Descripcion</td>
                                    <td style="width:35%">Proveedores</td>
                                    <td style="width:30%">Seccion</td>
                                    <td style="width:10%">Medida de Almacenaje</td>
                                    <td style="width:15%">Stock</td>
                                    <td style="width:10%">Critico</td>
                                    <td style="width:10%"><f7-icon f7="doc_fill" /></td>
                                    <td style="width:10%"><f7-icon tooltip="Documento de seguridad" f7="search" /></td>
                                </tr>
                            </thead>
                            <tbody style="overflow-y:scroll">
                                <tr v-for="item in table.pages[table.page - 1]" :key="item.id" style="">
                                    <td>{{ item.description }}</td>
                                    <td>{{ item.provider }}</td>
                                    <td>{{ item.SectionEquip }}</td>
                                    <td>{{ item.unit }}</td>
                                    <td>
                                        {{ item.stock }}
                                        <f7-icon
                                            tooltip="Stock debajo del limite"
                                            style="color:red"
                                            v-if="alertVisible(item.stock, item.limit)"
                                            f7="exclamationmark_triangle"
                                        />
                                    </td>
                                    <td>{{ item.limit }}</td>
                                    <td>
                                        <f7-row>
                                            <f7-col />
                                            <f7-link
                                                tooltip="Documento de seguridad"
                                                icon-f7="doc_fill"
                                                icon-size="25"
                                                target="_blank"
                                                btn
                                                outline
                                                v-if="item.security != ''"
                                                :href="item.security"
                                                external
                                            ></f7-link>

                                            <f7-col />
                                        </f7-row>
                                    </td>
                                    <td class="display-flex align-items-center">
                                         <f7-button class="margin-right-half" tooltip="Inspeccionar lotes ingresados" small fill @click="detail(item.id)">
                                            <f7-icon class="icon" f7="search" sm />
                                        </f7-button>
                                        <f7-button class="margin-right-half" tooltip="Ver log de egresos" small fill @click="viewEgressLog(item)">
                                            <f7-icon material="history" sm />
                                        </f7-button>
                                         <f7-button tooltip="Ver log de reingresos" small fill @click="viewReingressLog(item)">
                                            <f7-icon material="history" sm />
                                        </f7-button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </f7-col>
            </f7-row>
            <f7-row>
                <f7-col>
                    <div class="data-table card">
                        <f7-row style="padding:1%">
                            <f7-col>
                                <f7-button :disabled="enablePre" @click="table.page--" small btn outline>Anterior</f7-button>
                            </f7-col>
                            <f7-col width="5">
                                <f7-input type="number" min="1" :max="limitPage" @input="table.page = $event.target.value" :value="table.page" />
                            </f7-col>
                            <f7-col>
                                <f7-button :disabled="enableNext" @click="table.page++" small btn outline>Siguiente</f7-button>
                            </f7-col>
                            <f7-col style="text-align:center" width="25">
                                mostrando pagina {{ this.table.page }} de {{ this.table.pages.length == 0 ? 1 : this.table.pages.length }}, Total:
                                {{ totalRegisters }} Registros
                            </f7-col>

                            <f7-col></f7-col>
                            <f7-col></f7-col>
                            <f7-col></f7-col>
                            <f7-col></f7-col>
                        </f7-row>
                    </div>
                </f7-col>
            </f7-row>
        </f7-block>
        <f7-popup class="newInvoice"><newInvoice return="return" :reboot="reboot"></newInvoice></f7-popup>
        <f7-popup class="newEntry" @popup:closed="handleNewEntryPopupClosed">
            <newEntry :editing-entry="editingEntry" :reboot="reboot" @updateStock="stock"></newEntry>
        </f7-popup>
        <f7-popup class="newEgress" @popup:closed="viewingEntry = null"><newEgress :reboot="reboot" :entry="viewingEntry" @updateStock="stock" :stock="datos"></newEgress></f7-popup>
        <f7-popup class="detailed">
            <detailed @reloadDetail="detail" :supplyDetail="detailedSupply" @updateStock="stock" @edit-entry="handleEditEntry"></detailed>
        </f7-popup>
        <f7-popup class="reingress"><reingress :reboot="reboot" @updateStock="stock"></reingress></f7-popup>
        <f7-popup class="providerFilter"><providerFilter @updateStock="providerFilterChange"></providerFilter></f7-popup>

        <f7-popup :opened="egressSearchPopupOpened" @popup:closed="handleEgressSearchPopupClosed">
            <f7-page>
                <f7-navbar title="Búsqueda de ingreso del insumo">
                    <f7-nav-right>
                        <f7-link popup-close>Cerrar</f7-link>
                    </f7-nav-right>
                </f7-navbar>

                <f7-block>
                    <f7-list>
                        <f7-list-item divider>Por datos del ingreso</f7-list-item>
                        <f7-list-input
                            type="text"
                            label="Nombre del insumo"
                            :value="search.name"
                            @input="search.name = $event.target.value"
                        ></f7-list-input>
                        <f7-list-input
                            type="text"
                            label="Fecha de expiración"
                            placeholder="dd/mm/yyyy"
                            maxlength="10"
                            :value="search.expirationDate | formatDate"
                            @input="search.expirationDate = $event.target.value"
                        ></f7-list-input>
                        <f7-list-input
                            type="text"
                            label="Número de lote"
                            :value="search.lote"
                            @input="search.lote = $event.target.value"
                        ></f7-list-input>
                        <f7-list-item divider>Por sección</f7-list-item>
                        <f7-list-item
                            checkbox
                            title="Anatomía patológica"
                            :checked="search.sections.includes(1)"
                            @change="sectionCheckboxChanged(1)"
                        ></f7-list-item>
                        <f7-list-item
                            checkbox
                            title="Biología molecular"
                            :checked="search.sections.includes(2)"
                            @change="sectionCheckboxChanged(2)"
                        ></f7-list-item>
                        <f7-list-item
                            checkbox
                            title="Citometría de flujo"
                            :checked="search.sections.includes(3)"
                            @change="sectionCheckboxChanged(3)"
                        ></f7-list-item>
                        <f7-list-item
                            checkbox
                            title="Inmunología"
                            :checked="search.sections.includes(4)"
                            @change="sectionCheckboxChanged(4)"
                        ></f7-list-item>
                    </f7-list>
                    <f7-button fill @click="handleSearch">Buscar</f7-button>

                    <br>

                    <f7-row v-if="loadingEntries">
                        <f7-col class="text-align-center">
                            <f7-preloader></f7-preloader>
                        </f7-col>
                    </f7-row>
                    <div v-else-if="allFoundEntries.length">
                        <h4>Mostrando {{ allFoundEntries.length }} ingresos encontrados</h4>
                        <f7-list media-list class="no-margin-top">
                            <f7-list-item
                                v-for="entry in allFoundEntries"
                                :key="entry.id"
                                link="#"
                                @click="handleEntrySelected(entry)"
                            >
                                <div slot="header">
                                    Ingresado el {{ $moment(entry.createdAt).format("DD/MM/YYYY HH:mm:ss") }} &bull;
                                    Código de ingreso: {{ entry.deposit_code }}
                                </div>
                                <b slot="title">
                                    {{ entry.insumo_description }}
                                </b>
                                <div slot="subtitle">
                                    Stock ingresado: {{ entry.quantity }}
                                </div>
                            </f7-list-item>
                        </f7-list>
                    </div>
                </f7-block>
            </f7-page>
        </f7-popup>

        <f7-popup class="log-egresos">
            <f7-page>
                <f7-navbar title="Log de egresos">
                    <f7-nav-right>
                        <f7-link popup-close>Cerrar</f7-link>
                    </f7-nav-right>
                </f7-navbar>

                <f7-block>
                    <table class="table-sm table-bordered" style="width:100%">
                        <thead>
                            <tr>
                                <th>Cod Insumo</th>
                                <th>Egresos</th>
                                <th>Usuario</th>
                                <th>Fecha</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(log, index) in viewingSupplyEgressLogs" :key="index">
                                <td>{{ log.deposit_code }}</td>
                                <td>{{ log.egressConteo }}</td>
                                <td>{{ log.user }}</td>
                                <td>{{ $moment(log.createdAt).format("DD/MM/YYYY HH:mm") }}</td>
                            </tr>
                        </tbody>
                    </table>
                </f7-block>
            </f7-page>
        </f7-popup>

        <f7-popup class="log-reingresos">
            <f7-page>
                <f7-navbar title="Log de reingresos">
                    <f7-nav-right>
                        <f7-link popup-close>Cerrar</f7-link>
                    </f7-nav-right>
                </f7-navbar>

                <f7-block>
                    <table class="table-sm table-bordered" style="width:100%">
                        <thead>
                            <tr>
                                <th>Cod Insumo</th>
                                <th>Reingresos</th>
                                <th>Usuario</th>
                                <th>Fecha</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(log, index) in viewingSupplyReingressLogs" :key="index">
                                <td>{{ log.deposit_code }}</td>
                                <td>{{ log.egressConteo }}</td>
                                <td>{{ log.user }}</td>
                                <td>{{ $moment(log.deletedAt).format("DD/MM/YYYY HH:mm") }}</td>
                            </tr>
                        </tbody>
                    </table>
                </f7-block>
            </f7-page>
        </f7-popup>

        <f7-popover class="egress-popover">
            <f7-list>
                <f7-list-item
                    link="#"
                    popover-close
                    title="Por código de ingreso del insumo"
                    @click="openEgress"
                ></f7-list-item>
                <f7-list-item
                    link="#"
                    popover-close
                    title="Por búsqueda de ingreso"
                    @click="egressSearchPopupOpened = true"
                ></f7-list-item>
            </f7-list>
        </f7-popover>
    </f7-page>
</template>

<script>
import moment from "moment";
import newEntry from "./modules/newEntry";
import detailed from "./modules/detailed";
import newEgress from "./modules/newEgrees.vue";
import reingress from "./modules/reingress.vue";
import newInvoice from "../invoice/module/newInvoice.vue";
import providerFilter from "./modules/providerFilter.vue";
import exel from "js-export-excel";
import { mapState, mapActions } from "vuex";
import Multiselect from "vue-multiselect";

export default {
    components: {
        newEntry,
        detailed,
        newEgress,
        reingress,
        providerFilter,
        newInvoice,
        Multiselect,
    },
    data() {
        return {
            viewingSupplyEgressLogs: [],
            viewingSupplyReingressLogs: [],

            form: {
                supply_id: "NA",
            },
            datos: [],
            table: {
                page: 1,
                perPage: 10,
                pages: [],
                searchFilter: "code",
                searchData: "",
                stockOptions: ["En stock", "Stock Critico", "Sin stock"],
                sectionOption: [],
                providers: [],
                all: false,
            },
            stockOptions: ["En stock", "Stock Critico", "Sin stock"],
            sectionOption: [],
            supply_id: "NA",
            detailedSupply: [],
            reboot: false,

            editingEntry: null,

            loadingEntries: false,
            egressSearchPopupOpened: false,
            search: {
                name: "",
                sections: [],
                expirationDate: "",
                lote: "",
            },
            foundInsumos: [],

            viewingEntry: null,
        };
    },
    watch: {
        stockChange: {
            handler() {
                this.table.page = 1;
                this.chunkArray();
            },
        },
        sectionChange: {
            handler() {
                this.table.page = 1;
                this.chunkArray();
            },
        },
    },
    computed: {
        stockChange() {
            return this.table.stockOptions;
        },
        sectionChange() {
            return this.table.sectionOption;
        },
        ...mapState(["provider", "section"]),
        totalRegisters() {
            let total = 0;

            for (const item of this.table.pages) {
                total += parseInt(item.length);
            }

            return total;
        },
        supplys() {
            return this.paginate(this.table.supplys);
        },
        limitPage() {
            return this.table.pages.length;
        },
        enablePre() {
            return this.table.page > 1 ? false : true;
        },
        enableNext() {
            return this.table.page < this.limitPage ? false : true;
        },
        stockCheck() {
            return this.table.stock;
        },
        limitCheck() {
            return this.table.limit;
        },
        all() {
            return this.table.all;
        },

        allFoundEntries() {
            const allEntries = [];

            this.foundInsumos.forEach(insumo => {
                insumo.stock.forEach(entry => {
                    allEntries.push({
                        ...entry,
                        insumo_id: insumo.id,
                        insumo_code: insumo.code,
                        insumo_description: insumo.description,
                        insumo_unit: insumo.unit,
                        insumo_image: insumo.image,
                    });
                });
            });

            return allEntries;
        }
    },
    mounted() {},
    beforeMount() {
        this.init();
    },
    beforeCreate() {
        this.$firebase
            .auth()
            .currentUser.getIdToken()
            .then(async (token) => {
                this.$store.dispatch("updateState", {
                    token,
                    state: [
                        { path: "universal/?model=Provider", model: "provider" },
                        { path: "universal/?model=ExamSection", model: "section" },
                        { path: "universal/?model=Equip", model: "equip" },
                        { path: "universal/?model=Supply", model: "supply" },
                        { path: "universal/?model=Brand", model: "brand" },
                        { path: "universal/?model=Invoice", model: "invoice" },
                        { path: "universal/?model=State", model: "state" },
                    ],
                });
            });
    },
    methods: {
        ...mapActions(["getAPIInfo"]),

        viewEgressLog(item) {
            this.$f7.dialog.preloader("Cargando logs...");

            this.viewingSupplyEgressLogs = [];

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => this.getAPIInfo({ url: `supply/${item.id}/egressLog`, userToken: idToken }))
                .then((resp) => {
                    const results = resp.data.results || [];

                    this.viewingSupplyEgressLogs = results;

                    this.$f7.popup.open(".log-egresos");

                    this.$f7.dialog.close();
                })
                .catch((err) => {
                    console.error(err);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("No se encontraron logs de egresos");
                });
        },
        viewReingressLog(item) {
            this.$f7.dialog.preloader("Cargando logs de reingreso...");

            this.viewingSupplyReingressLogs = [];

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => this.getAPIInfo({ url: `supply/${item.id}/reingressLog`, userToken: idToken }))
                .then((resp) => {
                    const results = resp.data.results || [];

                    this.viewingSupplyReingressLogs = results;

                    this.$f7.popup.open(".log-reingresos");

                    this.$f7.dialog.close();
                })
                .catch((err) => {
                    console.error(err);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("No se encontraron logs de reingresos");
                });
        },

        handleEntrySelected(entry) {
            this.viewingEntry = entry;
            this.egressSearchPopupOpened = false;

            // Hack, sin esto se bugea el popup
            setTimeout(() => {
                this.openModal(".newEgress");
            }, 200);
        },
        handleEgressSearchPopupClosed() {
            this.egressSearchPopupOpened = false;
            this.clearEgressSearch();
        },
        clearEgressSearch() {
            this.search.name = "";
            this.search.sections = [];
            this.foundInsumos = [];
        },

        async handleSearch() {
            this.loadingEntries = true;

            try {
                const token = await this.$firebase.auth().currentUser.getIdToken();

                const response = await this.getAPIInfo({
                    url: `supply/searchForEgress?name=${this.search.name}&sections=${this.search.sections.join()}&expiration=${this.search.expirationDate}&lote=${this.search.lote}`,
                    userToken: token
                });

                this.foundInsumos = response.data.data;

                this.loadingEntries = false;

                console.log("response", response);
            } catch (error) {
                this.loadingEntries = false;
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Hubo un error al buscar los insumos.");
                console.error(error);
            }
        },
        sectionCheckboxChanged(sectionId) {
            if (this.search.sections.includes(sectionId)) {
                const index = this.search.sections.indexOf(sectionId);
                this.search.sections.splice(index, 1);
            } else {
                this.search.sections.push(sectionId);
            }
        },
        handleNewEntryPopupClosed() {
            this.editingEntry = null;
        },
        handleEditEntry(entry) {
            console.log("@handleEditEntry", { entry });

            this.editingEntry = entry;

            this.$f7.popup.close(".detailed");
            this.$f7.popup.open(".newEntry");
        },
        exportExel() {
            var options = {
                datas: [
                    {
                        sheetData: [],
                        sheetName: moment().format("DD-MM-YYYY") + " Stock",
                        sheetFilter: [],
                        sheetHeader: [],
                        columnWidths: [5, 8, 8, 10, 8, 5, 5],
                    },
                ],
            };

            options.fileName = moment().format("DD-MM-YYYY") + " Stock";

            options.datas[0].sheetHeader = ["Codigo", "Descripcion", "Proveedores", "Seccion", "Unidad de medida", "Stock", "Stock Critico"];
            options.datas[0].sheetFilter = ["Codigo", "Descripcion", "Proveedores", "Seccion", "Unidad de medida", "Stock", "Stock Critico"];

            for (const item of this.table.pages) {
                for (const item2 of item) {
                    options.datas[0].sheetData.push({
                        Codigo: item2.code,
                        Descripcion: item2.description,
                        Proveedores: item2.provider,
                        Seccion: item2.SectionEquip,
                        "Unidad de medida": item2.unit,
                        Stock: item2.stock,
                        "Stock Critico": item2.limit,
                    });
                }
            }

            console.log(options);
            var toExcel = new exel(options);

            toExcel.saveExcel();
        },
        providerFilterChange(value) {
            this.table.providers = value;
            this.chunkArray();
        },

        alertVisible(stock, limit) {
            return stock < limit ? true : false;
        },
        detail(value) {
            this.supply_id = value;

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then(async (token) => {
                    let values = await this.$store.dispatch("apiRequest", {
                        app: this,
                        url: "universal/search",
                        token,
                        method: "POST",
                        data: { relation: ["provider", "brand", "supply", "used"], model: "Deposit", field: "supply_id", search: value },
                    });
                    this.detailedSupply = values.info.data;
                });

            this.openModal(".detailed");
        },
        openModal(modal) {
            this.reboot = this.reboot == false ? true : false;
            this.$f7.popup.open(modal);
        },
        openEgress() {
            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then(async (token) => {
                    this.$store.dispatch("updateState", {
                        token,
                        state: [{ path: "universal/?model=Deposit&relation=" + encodeURIComponent(JSON.stringify(["used"])) + "", model: "stock" }],
                    });

                    this.reboot = this.reboot == false ? true : false;
                    this.$f7.popup.open(".newEgress");
                });
        },
        openReingress() {
            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then(async (token) => {
                    this.$store.dispatch("updateState", {
                        token,
                        state: [{ path: "universal/?model=Deposit&relation=" + encodeURIComponent(JSON.stringify(["used"])) + "", model: "stock" }],
                    });
                    this.reboot = this.reboot == false ? true : false;
                    this.$f7.popup.open(".reingress");
                });
        },
        newSupply() {
            this.$f7router.navigate("/supply/new?returnUrl=/entry/");
        },
        async init() {
            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then(async (token) => {
                    this.datos = [];

                    let response = await this.$store.dispatch("apiRequest", { url: "supply/deposit", token, method: "GET" });

                    response.info.message.reverse();

                    if (response.code == 200) {
                        this.datos = response.info.message;

                        this.chunkArray();
                    }

                    await this.$store.dispatch("updateState", { token, state: [{ path: "universal/?model=ExamSection", model: "section" }] });

                    for (const item of this.section) {
                        this.sectionOption.push(item.name);
                        this.table.sectionOption.push(item.name);
                    }
                });
        },
        stock() {
            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then(async (token) => {
                    this.datos = [];

                    let response = await this.$store.dispatch("apiRequest", { url: "supply/deposit", token, method: "GET" });

                    response.info.message.reverse();

                    if (response.code == 200) {
                        this.datos = response.info.message;

                        this.chunkArray();
                    }
                });
        },
        chunkArray() {
            var results = [];
            var filtered = [];
            var postFilter = [];
            var sectionFilter = [];
            const work = this.datos;

            if (this.table.searchData != "") {
                work.forEach((element) => {
                    if (
                        element.description.toLowerCase().search(this.table.searchData.toLowerCase()) != -1 ||
                        element.provider.toLowerCase().search(this.table.searchData.toLowerCase()) != -1
                    ) {
                        sectionFilter.push(element);
                    }
                });
            } else {
                sectionFilter = work;
            }

            if (this.table.sectionOption.length > 0) {
                for (const tupla of sectionFilter) {
                    for (const item of this.table.sectionOption) {
                        if (tupla.SectionEquip.toLowerCase().search(item.toLowerCase()) != -1) {
                            filtered.push(tupla);
                        }
                    }
                }
            } else {
                filtered = sectionFilter;
            }
            console.log(filtered);
            filtered.forEach((item) => {
                if (this.table.all == false) {
                    if (this.table.stockOptions.includes("En stock") == true) {
                        if (item.stock > 0) {
                            var found = false;
                            for (const i of postFilter) {
                                if (i.id == item.id) {
                                    found = true;
                                }
                            }
                            if (!found) {
                                postFilter.push(item);
                                return;
                            }
                        }
                    }
                    if (this.table.stockOptions.includes("Sin stock") == true) {
                        if (item.stock < 1) {
                            var found = false;
                            for (const i of postFilter) {
                                if (i.id == item.id) {
                                    found = true;
                                }
                            }
                            if (!found) {
                                postFilter.push(item);
                                return;
                            }
                        }
                    }
                    if (this.table.stockOptions.includes("Stock Critico") == true) {
                        if (item.limit > 0 && item.limit >= item.stock) {
                            var found = false;
                            for (const i of postFilter) {
                                if (i.id == item.id) {
                                    found = true;
                                }
                            }
                            if (!found) {
                                postFilter.push(item);
                                return;
                            }
                        }
                    }
                } else {
                    postFilter.push(item);
                }
            });

            results = this.chunkArrayInGroups(postFilter, this.table.perPage);

            this.table.pages = results;
        },
        chunkArrayInGroups(arr, size) {
            var myArray = [];
            for (var i = 0; i < arr.length; i += size) {
                myArray.push(arr.slice(i, i + size));
            }
            return myArray;
        },
        preFilter(value) {
            if (value != "") {
                if (value != "no-value") {
                    this.table.searchData = value;
                }

                this.chunkArray();
            } else {
                this.table.searchData = "";

                this.chunkArray();
            }
        },
        preSearch(value) {
            this.table.searchFilter = value;

            this.chunkArray();
        },
        perPageChange(value) {
            this.table.perPage = value;
            this.chunkArray();
        },
        log(value) {
            console.log(value);
        },
        changeStock(value) {
            this.table.stock = value;
            this.chunkArray();
        },
        changeLimit(value) {
            this.table.limit = value;
            this.chunkArray();
        },
        allStock(value) {
            this.table.all = value;
            this.chunkArray();
        },
    },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.mb-2 {
    margin-bottom: 4% !important;
}
.mb-1 {
    margin-bottom: 3% !important;
}
.align-left {
    text-align: left;
}
.padding-1 {
    padding: 0% 1%;
}
.toast {
    left: 45% !important;
}
.toast-text {
    font-size: 1.3em;
}
.newEntry {
    width: 109vh !important;
    left: 43%;
    top: 40%;
    height: 70vh !important;
}
.newEgress {
    width: 109vh !important;
    left: 43%;
}
.reingress {
    width: 130vh !important;
    left: 35%;
    top: 40%;
    height: 80vh !important;
}
.detailed {
    width: 130vh !important;
    left: 35%;
    top: 40%;
    height: 80vh !important;
}
.icon {
    font-size: 13px;
}
button.page-link {
    display: inline-block;
}
button.page-link {
    font-size: 20px;
    color: #29b3ed;
    font-weight: 500;
}
.offset {
    width: 500px !important;
    margin: 20px auto;
}
.item-inner {
    padding-top: 0 !important;
}
</style>

<style>
.multiselect__tag {
    background-color: transparent !important;
    color: black !important;
    border: 1px #36b5aa solid;
}
.multiselect__tag-icon:hover {
    background-color: transparent !important;
    color: black;
}
.multiselect__option--highlight {
    background-color: #36b5aa !important;
}
.multiselect__option--selected.multiselect__option--highlight {
    background: #36b5aa !important;
}
.egress-popover {
    --f7-popover-width: 280px;
}
</style>
