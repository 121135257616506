<template>
    <div>
        <!-- Add exam -->
        <f7-row class="align-items-center margin-bottom padding-bottom">
            <!-- Code -->
            <f7-col>
                <label>Código de examen</label>
                <f7-input
                    type="text"
                    @input="newExam.code = $event.target.value"
                    :value="newExam.code"
                    @keyup.native.enter="getExamByCode"
                    info="Presione ENTER para buscar"
                    placeholder="Ej: ALOT"
                ></f7-input>
            </f7-col>

            <!-- Name -->
            <f7-col>
                <label>Nombre del examen</label>
                <f7-list>
                    <f7-list-item
                        class="smart-select-list-item-sm"
                        :title="newExam.name || 'Seleccione...'"
                        smart-select
                        :smart-select-params="{
                            openIn: 'popup',
                            searchbar: true,
                            searchbarPlaceholder: 'Buscar examen',
                            closeOnSelect: true,
                            setValueText: false,
                            popupCloseLinkText: 'Cerrar',
                            pageTitle: examsSelectorTitle,
                        }"
                        :disabled="!allExams"
                    >
                        <select @change="handleExamSelected">
                            <option value="">Seleccione...</option>
                            <option v-for="exam in filteredExams" :key="exam.id" :value="exam.id" :selected="exam.id === newExam.id" :disabled="!exam.enabled">
                                {{ exam.name }}
                            </option>
                        </select>
                    </f7-list-item>
                </f7-list>
            </f7-col>

            <!-- Sample -->
            <f7-col>
                <label>Muestra</label>
                <f7-input type="select" @change="handleSampleSelected">
                    <option value="">Seleccione...</option>
                    <option v-for="sample in samplesToSelect" :key="sample.id" :value="sample.id">
                        {{ sample.name }}
                    </option>
                </f7-input>
            </f7-col>

            <!-- Sample quantity -->
            <f7-col>
                <label>Cantidad de muestras</label>
                <f7-input
                    type="number"
                    min="0"
                    step="1"
                    :value="newExam.sample.quantity"
                    @input="newExam.sample.quantity = Number($event.target.value)"
                    :disabled="!newExam.sample"
                ></f7-input>
            </f7-col>

            <!-- Add exam button -->
            <f7-col>
                <f7-button icon-material="add" fill small @click="addExam">
                    Añadir examen
                </f7-button>
            </f7-col>
        </f7-row>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
    props: {
        context: {
            type: Object,
            required: false,
            default: {},
        }
    },

    data() {
        return {
            newExam: {
                id: 0,
                code: "",
                name: "",

                sample: {
                    id: 0,
                    name: "",
                    quantity: 0,
                }
            },

            samplesToSelect: [],
        };
    },

    computed: {
        ...mapState(["allExams"]),
        ...mapGetters(["getExam"]),

        filteredExams() {
            if (!this.allExams) return [];
            if (!this.context.otSelected) return this.allExams;

            return this.allExams.filter((e) => e.section.id === this.context.otSelected.section.id);
        },

        examsSelectorTitle() {
            let title = "Exámenes";

            if (this.context.otSelected) title += ` (${this.context.otSelected.section.name})`;

            return title;
        }
    },

    created() {
        this.getExams();
    },

    methods: {
        ...mapActions(["getAPIInfo"]),

        getExams() {
            if (this.allExams) {
                return;
            }
            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: `exams?order=ASC&rows_per_page=500&enabled=1`, userToken: idToken });
                })
                .then((resp) => {
                    const exams = resp.data.data;
                    this.$store.commit("setWhatTo", { what: "allExams", to: exams });
                })
                .catch((err) => {
                    console.error(err);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Error. " + err.message);
                });
        },

        handleExamSelected(ev) {
            const id = Number(ev.target.value);
            const exam = this.getExam(id);

            if (!exam) {
                this.clear();
                return;
            }

            const formattedExam = {
                id: exam.id,
                code: exam.code,
                name: exam.name,

                sample: {
                    id: 0,
                    name: "",
                    quantity: 0,
                },

                entrega_normal: exam.entrega_normal,
                entrega_urgente: exam.entrega_urgente,
                entrega_stat: exam.entrega_stat,
            };

            this.newExam = formattedExam;
            this.samplesToSelect = exam.samples;

            console.log("handle exam selected", formattedExam);
        },

        handleSampleSelected(ev) {
            const id = Number(ev.target.value);

            if (!id) {
                this.clear(true);
                return;
            }

            const sample = this.getExam(this.newExam.id).samples.find((s) => s.id == id);

            const formattedSample = {
                id: sample.id,
                name: sample.name,
                quantity: 1,
            }

            this.newExam.sample = formattedSample;

            console.log("handle sample selected", this.newExam);
        },

        addExam() {
            if (this.newExam.id === 0) {
                this.$f7.dialog.alert("Debes seleccionar un examen para agregarlo.");
                return;
            }

            const exam_section = this.getExam(this.newExam.id).section;

            const formattedData = {
                exam: {
                    id: this.newExam.id,
                    code: this.newExam.code,
                    name: this.newExam.name,
                    entrega_normal: this.newExam.entrega_normal,
                    entrega_urgente: this.newExam.entrega_urgente,
                    entrega_stat: this.newExam.entrega_stat
                },
                samples: this.newExam.sample.id ? [
                    {
                        meta: {
                            id: this.newExam.sample.id,
                            name: this.newExam.sample.name,
                        },
                        quantity: this.newExam.sample.quantity
                    }
                ] : [],
                exam_section
            };

            this.$emit("exam-added", formattedData);

            this.clear();
        },

        clear(justSample = false) {
            if (justSample) {
                this.newExam.sample = {
                    id: 0,
                    name: "",
                    quantity: 0,
                };
                return;
            }

            this.newExam = {
                id: 0,
                code: "",
                name: "",
                sample: {
                    id: 0,
                    name: "",
                    quantity: 0,
                },
            };

            this.samplesToSelect = [];
        },
    },
};
</script>
