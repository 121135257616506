<template>
    <div class="table-responsive">
        <table class="table table-sm table-bordered table-hover" v-if="admissions.length > 0">
            <thead class="thead-default">
                <slot name="table-header"></slot>
            </thead>
            <tbody>
                <tr
                    v-for="admission in admissions"
                    :key="admission.id"
                    :class="{ 'table-info': !noHighlights && lastSearch && lastSearch.admissionId === admission.id }"
                >
                    <slot name="table-row" :admission="admission" :f="getFunctionsForScopedSlot()"></slot>
                </tr>
            </tbody>
        </table>

        <div class="display-flex justify-content-center" v-if="admissions.length > 0">
            <template v-if="pagination">
                <f7-button fill large raised class="padding-horizontal" @click="$emit('load-more')" v-if="pagination.page < pagination.totalPages">
                    Cargar más admisiones
                </f7-button>
                <div v-else class="text-color-gray">Se han cargado todas las admisiones encontradas</div>
            </template>
        </div>

        <select-ot-popup
            :admission="selectedAdmission"
            @ot-selected="handleOTSelected"
            @admission-selected="handleAdmissionSelected"
            :opened="selectOTPopupOpened"
            @popup-closed="closeSelectOTPopup"
        ></select-ot-popup>
    </div>
</template>

<script>
import { priorities, admissionOpenedStatus } from "@/app/constants";
import SelectOTPopup from "@/app/components-migration/select-ot-popup.vue";
import { mapState } from "vuex";

export default {
    props: {
        admissions: Array,
        pagination: Object,
        noHighlights: Boolean,
    },

    components: {
        "select-ot-popup": SelectOTPopup,
    },

    data() {
        return {
            selectOTPopupOpened: false,
            selectedAdmission: null,
        };
    },

    computed: {
        ...mapState("admission", ["lastSearch"]),
    },

    methods: {
        handleViewAdmission(admission) {
            this.selectedAdmission = Object.assign({}, admission);

            if (this.selectedAdmission.ots.length === 0) {
                this.redirectToViewAdmission(admission);
                this.$f7.dialog.close();
                return;
            }

            this.selectOTPopupOpened = true;
        },

        closeSelectOTPopup() {
            this.selectOTPopupOpened = false;
            this.selectedAdmission = null;
        },

        handleOTSelected(ot) {
            this.closeSelectOTPopup();

            const admissionIndex = this.admissions.findIndex((admission) => admission.id === ot.admission_id);

            if (admissionIndex === -1) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("No se ha encontrado la admisión que fue seleccionada.");
                return;
            }

            const admission = { ...this.admissions[admissionIndex] };

            if (!admission) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("No se ha encontrado la admisión que fue seleccionada.");
                return;
            }

            const examsSelectedByOT = admission.exams.filter((exam) => exam.section_id === ot.section_id);
            const otsSelected = admission.ots.filter((admissionOT) => admissionOT.section_id === ot.section_id);

            admission.exams = examsSelectedByOT;
            admission.ots = otsSelected;

            this.redirectToViewAdmission(admission, { otSelected: ot });

            this.$f7.dialog.close();
        },
        handleAdmissionSelected(admission) {
            this.closeSelectOTPopup();

            this.redirectToViewAdmission(admission);

            this.$f7.dialog.close();
        },

        patientDocumentInfo(admission) {
            const { patient } = admission;

            if (patient.document_type_info) {
                return `${patient.document_type_info.name} ${patient.document_number}`;
            }

            return "Sin documentos del paciente";
        },
        patientFullName(admission) {
            const { patient } = admission;

            if (patient) {
                return `${patient.name || ""} ${patient.lastname_f || ""} ${patient.lastname_m || ""}`;
            } else {
                return "Sin paciente";
            }
        },
        institutionInfo(admission) {
            if (admission.institution) {
                const { institution } = admission;
                return institution.name || "Sin institución";
            } else {
                return "Sin institución";
            }
        },
        priorityInfo(admission) {
            if (admission.priority) {
                const priority = priorities[admission.priority];

                return priority || "Sin prioridad";
            }

            return "Sin prioridad";
        },
        admissionOpenedInfo(admission) {
            return admissionOpenedStatus[admission.opened] || "Sin estado";
        },
        examsInfo(admission) {
            const exams = admission.exams;

            if (!exams) return "Sin exámenes";
            if (exams.length === 0) return "Sin exámenes";

            return exams.map((e) => {
                if (!e.exam) {
                    return "Exámen no identificado";
                }

                return e.exam.code || e.exam.name;
            }).join(", ");
        },
        otsCodesInfo(admission) {
            const ots = admission.ots;

            if (!ots) return "Sin OTs";
            if (ots.length === 0) return "Sin OTs";

            return ots.map((ot) => ot.code).join(", ");
        },

        redirectToViewAdmission(admission, context) {
            this.$store.commit("admission/set_viewing_admission", { admission, context });
            this.$store.commit("admission/save_last_viewing_admission", admission.id);
            this.$store.commit("setWhatTo", { what: "activeTab", to: "/migration/admissions/index" });

            this.$f7.views.main.router.navigate("/migration/admissions/index");
        },

        getFunctionsForScopedSlot() {
            const {
                patientDocumentInfo,
                patientFullName,
                institutionInfo,
                priorityInfo,
                admissionOpenedInfo,
                handleViewAdmission,
                examsInfo,
                otsCodesInfo,
            } = this;

            return {
                patientDocumentInfo,
                patientFullName,
                institutionInfo,
                priorityInfo,
                examsInfo,
                otsCodesInfo,
                admissionOpenedInfo,
                handleViewAdmission,
            };
        },
    },
};
</script>
