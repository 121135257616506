<template>
    <f7-page class="backgris" name="pcrcovidbatch" @page:beforein="resetSigned()">
    <f7-navbar title="Batch process" subtitle="Firma masiva de examenes PCR COVID" back-link>
       <f7-nav-right>
        <f7-link popover-open=".popover-filterx" icon-only>
        <f7-icon  material="filter_list" >
          <f7-badge :color="covidActiveFilters.length>0?'red':''">{{covidActiveFilters.length}}</f7-badge>
        </f7-icon>
      </f7-link>
       </f7-nav-right>
    </f7-navbar>
   <div class="data-table data-table-init card">
  <!-- Card header -->
  <div class="card-header">
    <!-- Default table header -->
    <div class="data-table-header">
      <!-- Default table title -->
      <div class="data-table-title">Examenes COVID 19 pendientes de informar</div>
      <!-- Default table actions -->
      <div class="data-table-actions">
        
      </div>
    </div>
    <!-- Selected table header -->
    <div class="data-table-header-selected">
      <!-- Selected table title -->
      <div class="data-table-title-selected">{{covidBatchToSign.length}} items seleccionados</div>
      <!-- Selected table actions -->
      <div class="data-table-actions">
        <f7-link icon-material="rate_review" @click="firmaMultiptle()" text="Firmar"></f7-link>
      </div>
    </div>
  </div>
  <div class="card-content">
    <table>
      <thead>
        <tr>
          <th class="checkbox-cell">
            <label class="checkbox">
              <input type="checkbox">
              <i class="icon-checkbox"></i>
            </label>
          </th>
          <th class="label-cell">Apellidos y Nombres</th>
          <th class="label-cell">RUT</th>
          <th class="label-cell">Origen</th>
          <th class="label-cell">Muestra</th>
          <th class="label-cell">Metodo</th>
          <th class="label-cell resuldsxxxx">Resultado</th>
        </tr>
      </thead>
      <tbody>
        <fila-covid-batch :ref="'exm_'+onepending.id" v-for="onepending in covidsPendientes" @firmaesto="oneExamToSign" :key="onepending.id" :ordentrabajo="onepending"></fila-covid-batch>
      </tbody>
    </table>
  </div>
</div>

<f7-popover class="popover-filterx inerliimtheight">
    <f7-list>
      <f7-list-item v-for="resource in instituxToFilter" 
      :key="resource.id" 
      popover-close 
      :title="resource.name"
      checkbox 
      :value="resource.id"
      :checked="covidActiveFilters.includes(resource.id)"
      @change="checkActiveFilters"
      >
      </f7-list-item>
    </f7-list>
  </f7-popover>
    </f7-page>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import filaCovidBatch from '../components/filaCovidBatch.vue';

export default {
     components: {
            filaCovidBatch
        },
    data(){
        return{
         
        }
    },

    watch:{
       
    },
      computed: {
        ...mapState(['focused_section','profile_userInfo','exmsPendFirma','covidBatchToSign','covidActiveFilters']),
       // ...mapGetters(['arrExsAunNoIngresados'])
       covidsPendientes(){
          let soloCovids= this.exmsPendFirma.filter(unexm=>{
           return unexm.externalId=='COVID-19';
         });
         return soloCovids;
       },
       instituxToFilter(){
         let origin=this.$store.state.instituxToFilter || {};
         let arrayado = Object.values(origin) || [];
         arrayado.sort((a, b) => {
            const x = a.name.toLowerCase();
            const y = b.name.toLowerCase();
            if(x > y){
                return 1
            }
            else if(x < y){
                return -1
            }
            else{
                return 0
            }
        });
         return arrayado;
       }
    },

    methods: {
       ...mapActions(['getAPIInfo']),
      checkActiveFilters(event){
          let institucionid = event.target.value;
          if (event.target.checked) {
            this.$store.commit('pushToArray', {array: 'covidActiveFilters', value: institucionid})
          } else {
           	this.$store.commit('removeFromArraySimple',{ array:'covidActiveFilters', value: institucionid });
          }
      },
       firmaMultiptle(){
          this.beginSignatureProcess(this.covidBatchToSign);
       },
       oneExamToSign(metadatatosign){
          this.beginSignatureProcess([metadatatosign]);
       },
        beginSignatureProcess(arrayofmetadatosigns){
          this.$f7.dialog.preloader('Confirmando y Firmando...');
          let promiserox=[];
          arrayofmetadatosigns.forEach(metadatatosign => {
             let dataxs=this.$refs['exm_'+metadatatosign.id];
            let structured_result=dataxs[0].structured_result;
            promiserox.push(this.firmandoOneExam(metadatatosign,structured_result));
          });
         return Promise.all(promiserox).then(()=>{
            this.$f7.dialog.close();
           console.log('ALL SIGNED OK');
           this.resetSigned();
         }).catch(error=>{
           console.log(error);
           this.$f7.dialog.close();
           this.$f7.dialog.alert(err.message || err, 'Ops, ha ocurrido un error')
         })
       },

      firmandoOneExam(exam,structured_result){
        return new Promise((resolve, reject) => {
			let metodoobj=this.$store.getters.getFromArrayByProVal(structured_result.method_id,'covidMethods','id');
			structured_result.method=metodoobj.name;
      let examResult = {
        admissionId: exam.admissionId,
				section_id: exam.section_id,
				 result_type : 'structured',
        structured_result : structured_result
			};
			console.log('guardandno',examResult);
			let now = this.$moment().unix();
			let confirmadoyfirmadoInfo = {
        confirmado: now,
        confirmado_meta: {
          who_id: this.profile_userInfo.uid,
          who_name: this.profile_userInfo.displayName,
				},
				firmado: now,
				firmado_meta: {
          who_id: this.profile_userInfo.uid,
          who_name: this.profile_userInfo.displayName,
				},
			};
			let confirmadoLogInfo = {
        who: this.profile_userInfo.uid,
        whn: now,
        wht: 'Examen confirmado'
      }
			let firmadoLogInfo = {
        who: this.profile_userInfo.uid,
        whn: now,
				wht: 'Examen firmado',
				result:{
					structured: structured_result
				}
			};
			let promsexs=[
				this.$firebase.database().ref('exams_results/' + exam.id).set(examResult),
				this.$firebase.database().ref(`admittedExams/${exam.section_id}/${exam.id}/ingresado`).set(now),
				this.$firebase.database().ref(`admittedExams/${exam.section_id}/${exam.id}`).update(confirmadoyfirmadoInfo),
				this.$firebase.database().ref(`exams_log/${exam.id}`).push(confirmadoLogInfo),
				this.$firebase.database().ref(`exams_log/${exam.id}`).push(firmadoLogInfo)
			];
			let samples=Object.keys((exam.samples || {}));
			samples.forEach(onesampleid => {
				promsexs.push(this.$firebase.database().ref(`admittedSamples/${onesampleid}/exams/${exam.id}`).set(true));
			});
      return Promise.all(promsexs).then(() => {
				console.log('Examen confirmado y firmado.',exam);
				this.$store.commit('removeFromArray',{ array:'exmsPendFirma', attr:'id', value:exam.id });
        return resolve(true);
      }).catch((err) => {
       return reject(err);
      });
      });
		},

   getExamInfo(){
     this.$f7.dialog.preloader('Cargando....');
      this.$firebase.auth().currentUser.getIdToken().then(idToken => {
        return this.getAPIInfo({url: 'exams/COVID-19', userToken: idToken});
        }).then((resp) => {
        let examInfo = resp.data || {};
        let covidMethods = examInfo.data.exam_processing_methods;
        let newStringMethods = covidMethods.map(onemtdo=>{
          let splitstring = onemtdo.name.split('PCR en tiempo real ');
          onemtdo.nombre = splitstring[1];
          return onemtdo;
        });
          this.$store.commit('setWhatTo',{what:'covidMethods',to:newStringMethods});
          this.$f7.dialog.close();
      })
      .catch((err) => {
       this.$f7.dialog.close();
        this.$f7.dialog.alert(err.message || err, 'Ops, ha ocurrido un error')
      });
    },
    resetSigned(){
        this.$store.commit('setWhatTo',{what:'covidBatchToSign',to:[]});
    }
    },
    created(){
      this.getExamInfo();
    },
    beforeDestroy(){
      //this.$store.commit('setWhatTo',{what:'allMyFirmados',to:[]});
    }


}
</script>
<style>
.resuldsxxxx{
  min-width: 161px;
}



</style>