<template>
    <f7-popup class="create-patient" :opened="opened" @popup:opened="init" @popup:closed="$emit('popup-closed')">
        <f7-page>
            <f7-navbar :title="computedTitle">
                <f7-nav-right>
                    <f7-link popup-close>Cerrar</f7-link>
                </f7-nav-right>
            </f7-navbar>
            <f7-block class="margin-top">
                <f7-row class="margin-bottom">
                    <f7-col>
                        <label>Tipo de documento</label>
                        <f7-input
                            type="select"
                            :disabled="readonly"
                            @change="newPatient.document_type = Number($event.target.value)"
                            :value="newPatient.document_type"
                        >
                            <option value="">Seleccione...</option>
                            <option :value="1">RUN</option>
                            <option :value="2">Pasaporte</option>
                            <option :value="3">DNI</option>
                            <option :value="4">Otro</option>
                        </f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Número de documento</label>
                        <f7-input
                            :disabled="readonly"
                            type="text"
                            @change="newPatient.document_number = $event.target.value"
                            :value="newPatient.document_number"
                            @keyup.native.enter="search"
                        ></f7-input>
                    </f7-col>
                </f7-row>

                <f7-row class="margin-bottom">
                    <f7-col>
                        <label>Apellido paterno</label>
                        <f7-input
                            :disabled="readonly"
                            class="input-uppercase"
                            type="text"
                            @change="newPatient.lastname_f = $event.target.value"
                            :value="newPatient.lastname_f"
                            @keyup.native.enter="search"
                        ></f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Apellido materno</label>
                        <f7-input
                            :disabled="readonly"
                            class="input-uppercase"
                            type="text"
                            @change="newPatient.lastname_m = $event.target.value"
                            :value="newPatient.lastname_m"
                            @keyup.native.enter="search"
                        ></f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Nombres</label>
                        <f7-input
                            :disabled="readonly"
                            class="input-uppercase"
                            type="text"
                            @change="newPatient.name = $event.target.value"
                            :value="newPatient.name"
                            @keyup.native.enter="search"
                        ></f7-input>
                    </f7-col>
                </f7-row>

                <f7-row class="margin-bottom">
                    <f7-col>
                        <label>Fecha de nacimiento</label>
                        <f7-input
                            :disabled="readonly"
                            class="input-uppercase"
                            type="text"
                            :maxlength="10"
                            @input="newPatient.birth_date = $event.target.value"
                            :value="newPatient.birth_date | formatDate"
                            placeholder="dd/MM/AAAA"
                            clear-button
                            @keyup.native.enter="search"
                        ></f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Sexo</label>
                        <f7-input
                            type="select"
                            :disabled="readonly"
                            @change="newPatient.gender = Number($event.target.value)"
                            :value="newPatient.gender"
                        >
                            <option :value="0">Seleccione...</option>
                            <option :value="1">Femenino</option>
                            <option :value="2">Masculino</option>
                        </f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Nacionalidad</label>
                        <f7-list no-hairlines class="no-margin-vertical">
                            <f7-list-item
                                class="smart-select-list-item-sm"
                                :title="newPatient.country_id && countries.length ? getCountry(newPatient.country_id).name_es : 'Seleccione...'"
                                smart-select
                                :smart-select-params="{
                                    openIn: 'popup',
                                    searchbar: true,
                                    searchbarPlaceholder: 'Buscar nacionalidad',
                                    closeOnSelect: true,
                                    setValueText: false,
                                    popupCloseLinkText: 'Cerrar',
                                    pageTitle: 'Seleccione una nacionalidad',
                                }"
                                :disabled="countries.length === 0"
                            >
                                <select @change="newPatient.country_id = Number($event.target.value)" :value="newPatient.country_id">
                                    <option value="">Seleccione...</option>
                                    <option v-for="country in countries" :key="country.id" :value="country.id">
                                        {{ country.name_es }}
                                    </option>
                                </select>
                            </f7-list-item>
                        </f7-list>
                    </f7-col>
                </f7-row>

                <f7-row class="margin-bottom">
                    <f7-col>
                        <label>Dirección</label>
                        <f7-input
                            :disabled="readonly"
                            class="input-uppercase"
                            type="text"
                            @input="newPatient.address = $event.target.value"
                            :value="newPatient.address"
                        ></f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Comuna</label>
                        <f7-list no-hairlines class="no-margin-vertical">
                            <f7-list-item
                                class="smart-select-list-item-sm"
                                :title="newPatient.comuna_id && comunas.length ? getComuna(newPatient.comuna_id).name : 'Seleccione...'"
                                smart-select
                                :smart-select-params="{
                                    openIn: 'popup',
                                    searchbar: true,
                                    searchbarPlaceholder: 'Buscar comuna',
                                    closeOnSelect: true,
                                    setValueText: false,
                                    popupCloseLinkText: 'Cerrar',
                                    pageTitle: 'Seleccione una comuna',
                                }"
                            >
                                <select @change="newPatient.comuna_id = Number($event.target.value)" :value="newPatient.comuna_id">
                                    <option value="">Seleccione...</option>
                                    <option v-for="comuna in comunas" :key="comuna.id" :value="comuna.id">
                                        {{ comuna.name }}
                                    </option>
                                </select>
                            </f7-list-item>
                        </f7-list>
                    </f7-col>
                    <f7-col>
                        <label>Telefono</label>
                        <f7-input
                            :disabled="readonly"
                            class="input-uppercase"
                            type="text"
                            @input="newPatient.phone = $event.target.value"
                            :value="newPatient.phone"
                        ></f7-input>
                    </f7-col>
                </f7-row>

                <f7-row class="margin-bottom">
                    <f7-col>
                        <label>Mail</label>
                        <f7-input
                            :disabled="readonly"
                            class="input-uppercase"
                            type="email"
                            @input="newPatient.email = $event.target.value"
                            :value="newPatient.email"
                        ></f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Prevision</label>
                        <f7-list no-hairlines class="no-margin-vertical">
                            <f7-list-item
                                class="smart-select-list-item-sm"
                                :title="newPatient.prevision && previsiones.length ? getPrevision(newPatient.prevision).name : 'Seleccione...'"
                                smart-select
                                :smart-select-params="{
                                    openIn: 'popup',
                                    searchbar: true,
                                    searchbarPlaceholder: 'Buscar prevision',
                                    closeOnSelect: true,
                                    setValueText: false,
                                    popupCloseLinkText: 'Cerrar',
                                    pageTitle: 'Seleccione una prevision',
                                }"
                            >
                                <select @change="newPatient.prevision = Number($event.target.value)" :value="newPatient.prevision">
                                    <option value="">Seleccione...</option>
                                    <option v-for="prevision in previsiones" :key="prevision.id" :value="prevision.id">
                                        {{ prevision.name }}
                                    </option>
                                </select>
                            </f7-list-item>
                        </f7-list>
                    </f7-col>
                </f7-row>

                <template v-if="!readonly">
                    <f7-button v-if="edit" fill @click="editPatient">Editar paciente</f7-button>
                    <f7-button v-else fill @click="create">Crear paciente</f7-button>
                </template>
            </f7-block>
        </f7-page>
    </f7-popup>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { _patientAge } from "@/app/helpers";
import { clean, validate } from "rut.js";

export default {
    props: {
        opened: Boolean,
        patient: Object,
        edit: { type: Boolean, required: false },
        readonly: { type: Boolean, required: false },
    },

    data() {
        return {
            newPatient: {
                id: null,
                document_type: 1,
                document_number: "",
                lastname_f: "",
                lastname_m: "",
                name: "",
                birth_date: "",
                gender: 0,
                country_id: 40,
                email: "",
                comuna_id: 0,
                phone: "",
                address: "",
                prevision: "",
                estado: "",
            },

            // Helpers
            patientAge: _patientAge,
        };
    },

    computed: {
        ...mapState(["countries", "comunas", "previsiones"]),

        ...mapGetters(["getCountry", "getComuna", "getPrevision"]),

        computedTitle() {
            if (this.readonly) {
                return "Viendo paciente";
            }

            if (this.edit) {
                return "Editando al paciente";
            }

            return "Crear nuevo paciente";
        },

        isNewPatientValid() {
            const reasons = [];

            let isValid = true;

            if (this.newPatient.document_type === 0) {
                reasons.push("Debe indicar tipo de documento");
                isValid = false;
            }
            if (
                (this.newPatient.document_type === 1 && !this.newPatient.document_number) ||
                (this.newPatient.document_type === 1 && this.newPatient.document_number && this.newPatient.document_number.length < 8)
            ) {
                reasons.push("El rut debe tener un minimo de 8 caracteres");
                isValid = false;
            } else if (this.$strictPatientValidation && this.newPatient.document_type === 1 && !validate(this.newPatient.document_number)) {
                reasons.push("El rut no es valido");
                isValid = false;
            }

            if (!this.newPatient.name || this.newPatient.name.length === 0) {
                reasons.push("Debe indicar el nombre de paciente");
                isValid = false;
            }
            if (this.newPatient.gender !== 1 && this.newPatient.gender !== 2) {
                reasons.push("Debe indicar el genero de paciente");
                isValid = false;
            }
            if (this.newPatient.birth_date.length > 0 && this.newPatient.birth_date.length !== 10) {
                reasons.push("La fecha de nacimiento debe estar en formato DD/MM/YYYY");
                isValid = false;
            } else if (!this.$moment(this.newPatient.birth_date, "DD/MM/YYYY").isValid()) {
                reasons.push("Debe ingresar una fecha de nacimiento válida y en formato DD/MM/YYYY");
                isValid = false;
            } else {
                const year = this.$moment(this.newPatient.birth_date, "DD/MM/YYYY").format("YYYY");

                if (Number(year) < 1900) {
                    reasons.push("El año de la fecha de nacimiento debe ser como mínimo 1900");
                    isValid = false;
                } else if (this.$moment(this.newPatient.birth_date, "DD/MM/YYYY").isAfter()) {
                    reasons.push("La fecha de nacimiento no puede ser mayor a la fecha actual");
                    isValid = false;
                }
            }

            return { isValid, reasons };
        },
    },

    methods: {
        ...mapActions(["createNewInfo", "updateInfo"]),

        init() {
            if (this.patient) {
                this.setPatient();
            }
        },

        setPatient() {
            this.newPatient.id = this.patient.id || null;
            this.newPatient.document_type = this.patient.document_type || 0;
            this.newPatient.document_number = this.patient.document_number || null;
            this.newPatient.name = this.patient.name || null;
            this.newPatient.lastname_m = this.patient.lastname_m || null;
            this.newPatient.lastname_f = this.patient.lastname_f || null;

            if (this.patient.birth_date) {
                if (this.patient.birth_date == this.$moment(this.patient.birth_date).format("YYYY-MM-DD")) {
                    this.newPatient.birth_date = this.$moment(this.patient.birth_date, "YYYY-MM-DD").format("DD/MM/YYYY");
                } else {
                    this.newPatient.birth_date = this.patient.birth_date;
                }
            } else {
                this.newPatient.birth_date = "";
            }

            this.newPatient.gender = this.patient.gender || null;
            this.newPatient.address = this.patient.address || null;
            this.newPatient.comuna_id = this.patient.comuna_id || null;
            this.newPatient.country_id = this.patient.country_id || null;
            this.newPatient.email = this.patient.email || null;
            this.newPatient.phone = this.patient.phone || null;
            this.newPatient.prevision = this.patient.prevision || null;
        },

        create() {
            const { isValid, reasons } = this.isNewPatientValid;

            if (!isValid) {
                const reasonsList = this.invalidReasonsList(reasons);
                this.$f7.dialog.alert(reasonsList);
                return;
            }

            this.$f7.dialog.preloader("Creando paciente...");

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    const patientCopy = { ...this.newPatient };

                    if (patientCopy.document_type === 1) {
                        patientCopy.document_number = clean(patientCopy.document_number);
                    }

                    return this.createNewInfo({ url: "patients", data: patientCopy, userToken: idToken });
                })
                .then((response) => {
                    if (response.data.error) {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert("Error. " + response.data.error);
                        return;
                    }

                    const createdPatient = response.data.data.patient;

                    this.$emit("patient-created", createdPatient);

                    this.$f7.dialog.close();
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Error. " + err.message);
                    console.error(err.message);
                });
        },

        editPatient() {
            const { isValid, reasons } = this.isNewPatientValid;

            if (!isValid) {
                const reasonsList = this.invalidReasonsList(reasons);
                this.$f7.dialog.alert(reasonsList);
                return;
            }

            this.$f7.dialog.preloader("Editando paciente...");

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.updateInfo({ url: `patients/${this.patient.id}`, data: this.newPatient, userToken: idToken });
                })
                .then((response) => {
                    if (response.data.error) {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert("Error. " + response.data.error);
                        return;
                    }

                    const updatedPatient = response.data.data;

                    this.$emit("patient-edited", updatedPatient);

                    this.$f7.dialog.close();
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Error. " + err.message);
                    console.error(err.message);
                });
        },

        invalidReasonsList(reasons) {
            let list = "<ul>";

            reasons.forEach((reason) => {
                list += `<li>${reason}</li>`;
            });

            list += "</ul>";

            return list;
        },
    },
};
</script>
