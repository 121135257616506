<template>
    <div id="ot-pdf" style="letter-spacing: 0.2px; width: 842px; font-size:9px">
        <div class="display-flex justify-content-space-between align-items-center">
            <img src="../../static/img/logoinmuno.png" alt="Inmunocel" style="width: 150px" />

            <h1 class="no-margin">Orden de trabajo</h1>

            <div>
                <vue-barcode :id="`barcode-${ot.code}`" :value="ot.code" font-size="12" format="CODE128" width="1" height="20"></vue-barcode>
            </div>
        </div>

        <div class="text-align-center margin-bottom-half">
            <b>Seccion:</b>
            {{ otSection }}

            &nbsp;

            <b>Prioridad:</b>
            {{ admissionPriority }}
        </div>

        <f7-row class="margin-bottom-half">
            <f7-col>
                <div>
                    <b>Nombre:</b>
                    {{ patientFullName }}
                </div>
                <div>
                    <b>{{ patientDocumentType }}:</b>
                    {{ patientDocumentType === 'RUT' ? formatRut(patientDocumentNumber) : patientDocumentNumber }}
                </div>
                <div>
                    <b>Fecha de nacimiento:</b>
                    {{ patientBirthDate }}
                </div>
                <div>
                    <b>Edad:</b>
                    {{ patientAgeForHumans(admission.patient.birth_date, "YYYY-MM-DD") }}
                </div>
                <div>
                    <b>Sexo:</b>
                    {{ patientGender }}
                </div>
            </f7-col>
            <f7-col>
                <div>
                    <b>Diagnóstico:</b>
                    {{ admissionDiagnostic }}
                </div>
                <div>
                    <b>Observaciones:</b>
                    {{ otObservations }}
                </div>

                <div v-if="admissionDoctor.name">
                    <b>Médico solicitante</b>
                    {{ admissionDoctor.name }}
                </div>
                <div v-if="admissionDoctor.contacto_1">
                    <b>Contacto 1 médico solicitante</b>
                    {{ admissionDoctor.contacto_1 }}
                </div>
                <div v-if="admissionDoctor.contacto_1">
                    <b>Contact0 2 médico solicitante</b>
                    {{ admissionDoctor.contacto_2 }}
                </div>
            </f7-col>
            <f7-col>
                <div>
                    <b>Procedencia:</b>
                    {{ admissionInstitution }}
                </div>
                <div>
                    <b>Fecha hora de ingreso:</b>
                    {{ $moment(admission.createdAt).format("DD/MM/YYYY HH:mm:ss") }}
                </div>
                <div>
                    <b>Recepcionista:</b>
                    {{ admission.created_by }}
                </div>
            </f7-col>
        </f7-row>

        <exams-table :exams="exams" extra-small>
            <template #table-header>
                <th>CB Muestras</th>
                <th>Cod Exámen</th>
                <th>Exámen</th>
                <th>Muestras</th>
                <th>Estado</th>
                <th>Fecha cargado</th>
                <th>Fecha entrega</th>
                <th>Observacion</th>
            </template>
            <template #table-row="{ admissionExam, f }">
                <tr>
                    <td>{{ f.admissionExamSamplesCodes(admissionExam) }}</td>
                    <td>{{ admissionExam.exam.code }}</td>
                    <td>{{ admissionExam.exam.name }}</td>
                    <td>{{ f.admissionExamSamplesNames(admissionExam) }}</td>
                    <td>{{ admissionExam.exam_status ? admissionExam.exam_status.name : "Sin estado" }}</td>
                    <td>{{ admissionExam.createdAt ? $moment(admissionExam.createdAt, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY HH:mm") : "Sin fecha de carga" }}</td>
                    <td>{{ admissionExam.fecha_entrega || "Sin fecha de entrega" }}</td>
                    <td>{{ admissionExam.observations }}</td>
                </tr>
            </template>
        </exams-table>
    </div>
</template>

<script>
import VueBarcode from "vue-barcode";
import ExamsTable from "@/app/components-migration/exams-table.vue";
import { _patientAge } from "@/app/helpers";
import { format } from "rut.js";

export default {
    props: {
        admission: Object,
        ot: Object,
        exams: Array,
    },

    components: {
        "vue-barcode": VueBarcode,
        "exams-table": ExamsTable,
    },

    data() {
        return {
            formatRut: format,
            patientAgeForHumans: _patientAge,
        };
    },

    computed: {
        otSection() {
            if (!this.ot.hasOwnProperty("section")) {
                return "";
            }

            return this.ot.section.name || "";
        },

        admissionPriority() {
            if (!this.admission.hasOwnProperty("priority")) {
                return "";
            }

            switch (this.admission.priority) {
                case 1:
                    return "Normal";
                case 2:
                    return "Urgente";
                case 3:
                    return "STAT";
                default:
                    return "";
            }
        },

        patientDocumentType() {
            if (!this.admission.hasOwnProperty("patient")) {
                return "Tipo documento no identificado";
            }
            if (!this.admission.patient.hasOwnProperty("document_type_info")) {
                return "Tipo documento no identificado";
            }

            return this.admission.patient.document_type_info.name || "";
        },

        patientDocumentNumber() {
            if (!this.admission.hasOwnProperty("patient")) {
                return "";
            }
            if (!this.admission.patient.hasOwnProperty("document_number")) {
                return "";
            }

            return this.admission.patient.document_number || "";
        },

        patientFullName() {
            if (!this.admission.hasOwnProperty("patient")) return "";

            const name = this.admission.patient.name || "";
            const lastnameF = this.admission.patient.lastname_f || "";
            const lastnameM = this.admission.patient.lastname_m || "";

            return `${name} ${lastnameF} ${lastnameM}`.toUpperCase();
        },

        patientBirthDate() {
            if (!this.admission.hasOwnProperty("patient")) {
                return "";
            }
            if (!this.admission.patient.hasOwnProperty("birth_date")) {
                return "";
            }

            return this.admission.patient.birth_date || "";
        },

        patientGender() {
            if (!this.admission.hasOwnProperty("patient")) {
                return "";
            }
            if (!this.admission.patient.hasOwnProperty("gender")) {
                return "";
            }

            switch (this.admission.patient.gender) {
                case 1:
                    return "Femenino";
                case 2:
                    return "Masculino";
                default:
                    return "";
            }
        },

        admissionDiagnostic() {
            if (!this.admission.hasOwnProperty("diagnostic_obs")) {
                return "";
            }

            return this.admission.diagnostic_obs || "";
        },

        otObservations() {
            if (!this.ot.hasOwnProperty("observations")) {
                return "";
            }

            return this.ot.observations || "";
        },

        admissionDoctor() {
            const doctor = {};

            const { doctor_name, doctor_rut, doctor_contacto_1, doctor_contacto_2 } = this.admission;

            if (doctor_name) doctor.name = doctor_name;
            if (doctor_rut) doctor.rut = doctor_rut;
            if (doctor_contacto_1) doctor.contacto_1 = doctor_contacto_1;
            if (doctor_contacto_2) doctor.contacto_2 = doctor_contacto_2;

            return doctor;
        },

        admissionInstitution() {
            if (!this.admission.institution) {
                return "";
            }

            return this.admission.institution.name || "";
        },
    },
};
</script>
