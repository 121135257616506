<template>
    <div class="block-toolbar display-flex justify-content-space-between" style="padding-bottom: 2px">
        <div class="display-flex align-items-center">
            <div>
                <f7-icon
                    v-if="block.isLocked"
                    material="lock"
                    color="yellow"
                    :size="toolbarIconSize"
                    tooltip="No puedes modificar los resultados de este exámen porque ya fue confirmado"
                ></f7-icon>
            </div>
            <slot name="toolbar-block-info"></slot>
        </div>

        <slot name="toolbar-content"></slot>

        <div class="display-flex align-items-center">
            <div v-if="blockHasInfo">
                <f7-icon
                    v-if="useIcon"
                    material="info"
                    :color="blockInfoColor"
                    :size="toolbarIconSize"
                    :tooltip="tooltipBlockInfoText"
                    class="block-toolbar-icon"
                ></f7-icon>
                <f7-badge v-else :color="blockInfoColor" style="margin-top: 2px; margin-right: 4px">
                    {{ block.displayName }}
                </f7-badge>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        block: {},
    },

    data() {
        return {
            toolbarIconSize: 14,

            useIcon: false,
        };
    },

    computed: {
        blockHasInfo() {
            return this.block.displayName && this.block.description;
        },

        tooltipBlockInfoText() {
            return `${this.block.displayName || "-"}: ${this.block.description || "-"}`;
        },

        blockInfoColor() {
            let color = "gray";

            if (this.block.code === "resultado-cualitativo-block") {
                color = "primary";
            } else if (this.block.code === "resultado-cuantitativo-block") {
                color = "blue";
            } else if (this.block.code === "resultado-free-text-block") {
                color = "purple";
            } else if (this.block.code === "resultado-collection-block") {
                color = "deeporange";
            } else if (this.block.code === "processing-method-block") {
                color = "lime";
            }

            return color;
        },
    },
};
</script>

<style scoped>
.block-toolbar {
    background-color: rgba(0, 0, 0, 0.03);
    padding: 0px 4px;
}
</style>
