<template>
    <f7-page name="agreements" @page:beforeremove="removeSelectedAgreement">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title >Convenios</f7-nav-title>
            <f7-nav-right>
                <f7-link icon-material="refresh" tooltip="Recargar Lista" @click="getAgreements"></f7-link>
                <f7-link class="searchbar-enable" data-searchbar=".searchbar-demo" icon-material="search"></f7-link>
            </f7-nav-right>
            <f7-searchbar
                class="searchbar-demo"
                remove-diacritics
                expandable
                search-container=".search-list"
                search-in=".item-title"
                :disable-button="!$theme.aurora"
                placeholder="Buscar tipo de convenio"
            ></f7-searchbar>
        </f7-navbar>

        <f7-list class="searchbar-not-found">
            <f7-list-item title="No hay coincidencias"></f7-list-item>
        </f7-list>
        <f7-list class="no-margin-top search-list searchbar-found" media-list>
            <f7-list-item 
                v-for="(agreement, index) in $store.getters.objToArray('allAgreements')" 
                :key="index"
                :title="agreement.name"
                link="#"
                :class="{selected:(selectedAgreement.id === agreement.id)}"
                @click="viewAgreementDetail(agreement)"
            ></f7-list-item>
        </f7-list>
        
        <f7-fab position="right-bottom" slot="fixed" color="teal">
            <f7-icon material="add"></f7-icon>
            <f7-icon ios="f7:close" aurora="f7:close" md="material:close"></f7-icon>
            <f7-fab-buttons position="top">
                <f7-fab-button fab-close @click="goToNewAgreement()" label="Crear nuevo tipo de convenio">
                    <f7-icon material="playlist_add"></f7-icon>
                </f7-fab-button>
            </f7-fab-buttons>
        </f7-fab>
    </f7-page>
</template>

<script>

import { mapState} from 'vuex';

export default {

    data(){
        return {
        }
    },
    computed: {
        ...mapState(['allAgreements', 'selectedAgreement']),
    },
    methods: {
        viewAgreementDetail(agreement){
            this.$store.commit('setWhatTo',{what: 'selectedAgreement', to: agreement});
            this.$f7.views.main.router.navigate('/agreement/');
        },
        goToNewAgreement(){
            this.$store.commit('setWhatTo',{what:'selectedAgreement', to:{} });
            if(this.$f7.views.main.router.currentRoute.url === '/agreements/'){
                this.$f7.views.main.router.navigate('/new-agreement/');
                }
            else{
                this.$f7.views.main.router.navigate('/new-agreement/', {reloadCurrent: true});
            }
        },
        getAgreements(){
            this.$f7.dialog.preloader('Cargando');
            this.$firebase.database().ref('agreements/').orderByChild('enabled').equalTo(true).once('value')
                .then((snapshot) => {
                    this.$store.commit('setObjTo', {what: 'allAgreements', to: snapshot.val()})
                    this.$f7.dialog.close();
                })
                .catch((err)=>{
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, err.code);
                });
        },
        removeSelectedAgreement(){
            this.$store.commit('setWhatTo', {what: 'selectedAgreement', to: {} });
        }
    },
    mounted(){
        if(!this.allAgreements){
            this.getAgreements();
        }
    }
}
</script>

<style scoped>
    .selected{
        border-left: 7px solid #36b5aa;
        color: #2d968c;
        background-color: #f3f3f3;
    }
</style>