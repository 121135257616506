<template>
    <div>
        <f7-list class="linksmenu">
            <f7-list-item divider title="Menu (LEGACY)"></f7-list-item>
            <f7-list-item
                v-for="onemenu in visibleMenu"
                :key="onemenu.url"
                @click="setactiveTab(onemenu.url)"
                v-bind:class="{ active: activeTab == onemenu.url }"
                :footer="onemenu.footer"
                link="#"
                view=".view-main"
                panel-close
            >
                <f7-icon slot="media" :material="onemenu.icono">
                    <f7-badge v-if="onemenu.new" color="red">New</f7-badge>
                    <f7-badge v-else-if="onemenu.migration" color="lightblue">Mig</f7-badge>
                </f7-icon>

                <div slot="title" class="title-no-wrap">
                    {{ onemenu.title }}
                </div>
            </f7-list-item>
            <template>
                <f7-list-item v-if="visibleSecctions.length > 0" divider title="Secciones"></f7-list-item>
                <f7-list-item
                    v-for="seccion in visibleSecctions"
                    :key="seccion.id"
                    @click="goToColaDeTrabajo(seccion)"
                    v-bind:class="{
                        active: activeTab == '/coladetrabajo/' && seccion.id == focused_section.id,
                    }"
                    link="#"
                    :title="seccion.name"
                    view=".view-main"
                    panel-close
                ></f7-list-item>
            </template>

            <template v-if="visibleMantenedores.length > 0">
                <f7-list-item divider title="Maestros"></f7-list-item>
                <f7-list-item
                    v-for="onemenu in visibleMantenedores"
                    :key="onemenu.url"
                    @click="setactiveTab(onemenu.url)"
                    v-bind:class="{ active: activeTab == onemenu.url }"
                    :footer="onemenu.footer"
                    link="#"
                    :title="onemenu.title"
                    view=".view-main"
                    panel-close
                >
                    <f7-icon slot="media" :material="onemenu.icono">
                        <f7-badge v-if="onemenu.new" color="red">New</f7-badge>
                        <f7-badge v-else-if="onemenu.migration" color="lightblue">Mig</f7-badge>
                    </f7-icon>
                </f7-list-item>
            </template>
        </f7-list>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    data() {
        return {
            menu_mantenedores: [
                {
                    url: "/members/",
                    footer: "Equipo Inmunocel",
                    title: "Miembros",
                    icono: "group_work",
                    privs: ["members"],
                },
                {
                    url: "/institutions/",
                    footer: "Orígenes solicitudes",
                    title: "Instituciones",
                    icono: "account_balance",
                    privs: ["mantenedores"],
                },
                {
                    url: "/agreements/",
                    footer: "Tipos de convenios",
                    title: "Convenios",
                    icono: "work",
                    privs: ["mantenedores"],
                },
                {
                    url: "/samples/",
                    footer: "Tipos de muestras",
                    title: "Muestras",
                    icono: "opacity",
                    privs: ["mantenedores"],
                },
                {
                    url: "/rejections/",
                    footer: "Motivos de rechazo de muestras",
                    title: "Rechazos",
                    icono: "assignment_late",
                    privs: ["mantenedores"],
                },
                {
                    url: "/exams/",
                    footer: "Maestro exámenes",
                    title: "Exámenes",
                    icono: "list_alt",
                    privs: ["mantenedores"],
                },
                {
                    url: "/methods/",
                    footer: "Métodos procesamiento",
                    title: "Métodos",
                    icono: "insert_chart_outlined",
                    privs: ["mantenedores"],
                },
                {
                    url: "/units/",
                    footer: "Unidades de medida",
                    title: "Unidades",
                    icono: "straighten",
                    privs: ["mantenedores"],
                },
                {
                    url: "/pacientes/",
                    footer: "Maestro de pacientes",
                    title: "Pacientes",
                    icono: "people",
                    privs: ["pacientes"],
                },
                {
                    url: "/privilegios/",
                    footer: "Definición permisos",
                    title: "Privilegios",
                    icono: "https",
                    privs: ["god"],
                },
                {
                    url: "/paxs/",
                    footer: "Usuarios de PAX",
                    title: "Accesos PAX",
                    icono: "vpn_key",
                    privs: ["pax"],
                },
                {
                    url: "/printer/",
                    footer: "Config Impresora",
                    title: "ZEBRA",
                    icono: "print",
                    privs: ["god"],
                },
            ],

            menu: [
                // Previo a migración
                {
                    url: "/admit/",
                    footer: "Ingreso muestras entrantes",
                    title: "Admitir muestra",
                    icono: "move_to_inbox",
                    privs: ["admit"],
                },
                {
                    url: "/scanner/",
                    footer: "Escaneo de Órdenes",
                    title: "Scanner",
                    icono: "important_devices",
                    privs: ["admit"],
                    mobileOnly: true,
                },
                {
                    url: "/sample-move/",
                    footer: "Mediante escaneo",
                    title: "Traslado muestras",
                    icono: "directions",
                    privs: ["admit"],
                },
                {
                    url: "/import-admissions-legacy/",
                    footer: "A través de archivos .xlsx",
                    title: "Importar COVID",
                    icono: "note_add",
                    privs: ["admit", "pacientes"],
                },
                {
                    url: "/entry/",
                    footer: "Stock",
                    title: "Stock",
                    icono: "directions",
                    privs: ["admit"],
                },
                {
                    url: "/resultados/",
                    footer: "Búsqueda por paciente",
                    title: "Admisiones",
                    icono: "folder_shared",
                    privs: ["resultados"],
                },
                {
                    url: "/export/",
                    footer: "Exportar exámenes ",
                    title: "Exportar",
                    icono: "unarchive",
                    privs: ["export"],
                },
                {
                    url: "/queue/",
                    footer: "Workflow visual",
                    title: "Colas de Trabajo",
                    icono: "playlist_add_check",
                    privs: ["colas"],
                },
                {
                    url: "/reports/",
                    footer: "Estadisticas & Graficas",
                    title: "Reportes",
                    icono: "pie_chart",
                    privs: ["reportes"],
                },
            ],
        };
    },

    computed: {
        ...mapState(["misprivx", "defsPrivx", "activeTab", "sections", "focused_section"]),

        visibleSecctions() {
            if (!this.defsPrivx || !this.defsPrivx.bysection) {
                return [];
            } else {
                let mypvirxbysecction = this.misprivx.vercolas || {};
                let privsByUnits = Object.values(this.defsPrivx.bysection);
                return this.sections.filter((unasection) => {
                    return privsByUnits.some((unpriv) => {
                        return mypvirxbysecction[unasection.id] && mypvirxbysecction[unasection.id][unpriv.i];
                    });
                });
            }
        },
        visibleMenu() {
            let lemenu = this.menu.filter((unmenu) => {
                if (unmenu.privs) {
                    return unmenu.privs.every((unprivrequired) => {
                        return this.misprivx[unprivrequired];
                    });
                } else {
                    return true;
                }
            });

            //filtar por tipo de dispositivo
            let finalmenu = lemenu.filter((unmenu) => {
                if (unmenu.mobileOnly) {
                    return !this.$f7.device.desktop;
                } else {
                    return true;
                }
            });
            return finalmenu;
        },
        visibleMantenedores() {
            let lemenu = this.menu_mantenedores.filter((unmenu) => {
                if (unmenu.privs) {
                    return unmenu.privs.some((unprivrequired) => {
                        return this.misprivx[unprivrequired];
                    });
                } else {
                    return true;
                }
            });
            return lemenu;
        },
    },

    methods: {
        setactiveTab(activeTab) {
            console.log("setting ctive tav", activeTab);
            this.$store.commit("setWhatTo", { what: "activeTab", to: activeTab });
            if (activeTab == "/members/") {
                this.goToMembers();
            } else if (activeTab == "/privilegios/" && !this.defsPrivx) {
                this.goToPrivilegios();
            } else {
                this.$f7.views.main.router.navigate(activeTab, { reloadAll: true });
            }
            //   this.$f7.views.main.router.navigate(activeTab);
        },

        goToColaDeTrabajo(seccion) {
            //focused_section
            this.$store.commit("setWhatTo", {
                what: "activeTab",
                to: "/coladetrabajo/",
            });
            this.$store.commit("setObjTo", { what: "focused_section", to: seccion });
            this.$f7.views.main.router.navigate("/coladetrabajo/", {
                reloadAll: true,
            });
        },
        goToPrivilegios() {
            if (!this.defsPrivx) {
                this.$f7.dialog.preloader("Cargando...");
                let promisex = [
                    this.$firebase
                        .database()
                        .ref("defs/privx")
                        .once("value"),
                    this.$firebase
                        .database()
                        .ref("defs/privx_by_section")
                        .orderByChild("o")
                        .once("value"),
                ];
                return Promise.all(promisex)
                    .then((snpsh) => {
                        let privbysex = [];
                        snpsh[1].forEach((unPrivbysec) => {
                            privbysex.push(unPrivbysec.val());
                        });
                        let defsPrivx = {
                            globales: snpsh[0].val(),
                            bysection: privbysex,
                        };
                        this.$store.commit("setWhatTo", {
                            what: "defsPrivx",
                            to: defsPrivx,
                        });
                        this.$f7.dialog.close();
                        this.$f7.views.main.router.navigate("/privilegios/", {
                            reloadAll: true,
                        });
                    })
                    .catch((error) => {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(error.message, error.code);
                    });
            } else {
                this.$f7.views.main.router.navigate("/privilegios/", {
                    reloadAll: true,
                });
            }
        },
        goToMembers() {
            this.$f7.dialog.preloader("Cargando...");
            let mymembers = this.$firebase.functions().httpsCallable("mymembers");
            let allpromi = [];
            if (this.$store.state.allMembers.length == 0) {
                allpromi.push(
                    mymembers({
                        prod: this.$store.state.env === "production" ? true : false,
                    })
                );
            } else {
                allpromi.push(false);
            }
            if (!this.defsPrivx) {
                allpromi.push(
                    this.$firebase
                        .database()
                        .ref("defs/privx")
                        .once("value")
                );
                allpromi.push(
                    this.$firebase
                        .database()
                        .ref("defs/privx_by_section")
                        .orderByChild("o")
                        .once("value")
                );
            }
            Promise.all(allpromi)
                .then((multisnap) => {
                    if (this.$store.state.allMembers.length == 0) {
                        let result = multisnap[0];
                        // Read result of the Cloud Function.
                        let allusers = result.data;
                        console.log(allusers);
                        this.$store.commit("setWhatTo", {
                            what: "allMembers",
                            to: allusers.allMyUsers || [],
                        });
                    }
                    //ahora cargar privielgios
                    if (!this.defsPrivx) {
                        let privbysex = [];
                        multisnap[2].forEach((unPrivbysec) => {
                            privbysex.push(unPrivbysec.val());
                        });
                        let defsPrivx = {
                            globales: multisnap[1].val(),
                            bysection: privbysex,
                        };
                        this.$store.commit("setWhatTo", {
                            what: "defsPrivx",
                            to: defsPrivx,
                        });
                    }
                    this.$f7.dialog.close();
                    this.$f7.views.main.router.navigate("/members/", { reloadAll: true });
                })
                .catch((error) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, error.code);
                });
        },
    },
};
</script>
