import { formatString } from "@/app/helpers";

export default {
    data() {
        return {
            filterInput: "",
        };
    },

    computed: {
        filteredExams() {
            const filterInput = formatString(this.filterInput);

            if (filterInput.length === 0) return this.exams;

            const filteredExams = this.exams.filter((admissionExam) => {
                const { patient, institution } = admissionExam.admission;
                const { exam, exam_section, exam_status, samples, ot } = admissionExam;

                let matched = false;

                if (!matched) {
                    let cleanPatientFullname = "";

                    if (patient.name) cleanPatientFullname += patient.name;
                    if (patient.lastname_f) cleanPatientFullname += patient.lastname_f;
                    if (patient.lastname_m) cleanPatientFullname += patient.lastname_m;

                    cleanPatientFullname = formatString(cleanPatientFullname);

                    const patientDocumentNumber = formatString(patient.document_number);

                    if (cleanPatientFullname.match(filterInput) || patientDocumentNumber.match(filterInput)) {
                        matched = true;
                    }
                }
                if (!matched) {
                    if (institution && institution.name) {
                        const cleanInstitutionName = formatString(institution.name);
                        if (cleanInstitutionName.match(filterInput)) {
                            matched = true;
                        }
                    }
                }
                if (!matched) {
                    if (exam) {
                        const cleanExamName = formatString(exam.name);
                        const cleanedExamCode = exam.code;

                        if (cleanExamName.match(filterInput) || cleanedExamCode.match(filterInput)) {
                            matched = true;
                        }
                    }
                }
                if (!matched) {
                    if (exam_section) {
                        const cleanExamSectionName = formatString(exam_section.name);

                        if (cleanExamSectionName.match(filterInput)) {
                            matched = true;
                        }
                    }
                }
                if (!matched) {
                    if (exam_status) {
                        const cleanExamStatusName = formatString(exam_status.name);

                        if (cleanExamStatusName.match(filterInput)) {
                            matched = true;
                        }
                    }
                }
                if (!matched) {
                    if (samples && samples.length) {
                        samples.forEach((sample) => {
                            const cleanSampleName = formatString(sample.meta.name);

                            if (cleanSampleName.match(filterInput) || sample.sample_code.match(filterInput)) {
                                matched = true;
                            }
                        });
                    }
                }
                if (!matched) {
                    if (ot) {
                        const cleanOtCode = ot.code.toLowerCase();
                        if (cleanOtCode.match(filterInput)) {
                            matched = true;
                        }
                    }
                }

                return matched;
            });

            return filteredExams;
        },
    },
};
