var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-row',{staticClass:"align-items-center margin-bottom padding-bottom"},[_c('f7-col',[_c('label',[_vm._v("Código de examen")]),_vm._v(" "),_c('f7-input',{attrs:{"type":"text","value":_vm.exam.code,"info":"Presione ENTER para buscar","placeholder":"Ej: ALOT"},on:{"input":function($event){_vm.exam.code = $event.target.value}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getExamByCode($event)}}})],1),_vm._v(" "),_c('f7-col',[_c('label',[_vm._v("Nombre del examen")]),_vm._v(" "),_c('f7-list',[_c('f7-list-item',{staticClass:"smart-select-list-item-sm",attrs:{"title":_vm.allExams.length === 0 ? 'Cargando...' : _vm.exam.name.length ? _vm.exam.name : 'Seleccione...',"smart-select":"","smart-select-params":{
                    openIn: 'popup',
                    searchbar: true,
                    searchbarPlaceholder: 'Buscar examen',
                    closeOnSelect: true,
                    setValueText: false,
                    popupCloseLinkText: 'Cerrar',
                    pageTitle: 'Seleccione un examen',
                },"disabled":_vm.allExams.length === 0}},[_c('select',{on:{"change":_vm.handleExamSelected}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._v(" "),_vm._l((_vm.filteredExams),function(ex){return _c('option',{key:ex.id,domProps:{"value":ex.code,"selected":ex.code === _vm.exam.code}},[_vm._v("\n                        "+_vm._s(ex.name)+"\n                    ")])})],2)])],1)],1),_vm._v(" "),_c('f7-col',[_c('label',[_vm._v("Muestra")]),_vm._v(" "),_c('f7-input',{attrs:{"type":"select"},on:{"change":function($event){return _vm.handleSampleSelected($event)}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._v(" "),_vm._l((_vm.exam.samples),function(sample){return _c('option',{key:sample.id,domProps:{"value":sample.id}},[_vm._v("\n                "+_vm._s(sample.name)+"\n            ")])})],2)],1),_vm._v(" "),_c('f7-col',[_c('label',[_vm._v("Cantidad de muestras")]),_vm._v(" "),_c('f7-input',{attrs:{"type":"number","min":"0","step":"1","value":_vm.selectedSample.quantity},on:{"input":function($event){_vm.selectedSample.quantity = parseInt($event.target.value)}}})],1),_vm._v(" "),_c('f7-col',[(!_vm.isAddingSample)?_c('div',{staticClass:"display-flex"},[_c('f7-button',{attrs:{"icon-material":"add","fill":"","small":""},on:{"click":_vm.addExam}},[_vm._v("\n                Añadir examen\n            ")])],1):_c('f7-block',{staticClass:"row demo-preloaders align-items-stretch text-align-center no-margin no-padding"},[_c('f7-col',[_c('f7-preloader')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }