<template>
    <f7-page name="home" class="background" >
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title >Dashboard</f7-nav-title>
        </f7-navbar>
        <f7-block>
            <f7-row>
                <f7-col v-for="(section, index) in sections" :key="index" width="100" medium="25">
                    <section-summary :section="section" :examsBySection="examsBySection[section.id]"></section-summary>
                </f7-col>
            </f7-row>
        </f7-block>
    </f7-page>
</template>

<script>
import * as moment from 'moment';
import { mapState } from 'vuex';
import SectionSummary from '../components/sectionSummary';


export default {
    data(){
        return{
            examsBySection: {},
            examsBySectionsRefs: [],
        }
    },

    components: {
        SectionSummary
    },

    computed: {
       ...mapState(['sections'])
    },

    methods: {
    },

	mounted(){
        //this.$f7.dialog.preloader('Cargando');
        this.examsBySectionsRefs = this.sections.map((section) => {
            let ref = this.$firebase.database().ref('admittedExams/' + section.id)
            ref.on('value', (snapshot) => {
                snapshot.val() && (this.examsBySection[section.id] = Object.values(snapshot.val()));
                this.examsBySection = {...this.examsBySection};
            });
            return ref;    
        })
    },
    beforeDestroy(){
        this.examsBySectionsRefs.forEach((ref) => ref.off());
    }
}
</script>
<style>

    .background{
        background: #ececec;
    }

</style>