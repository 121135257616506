module.exports = {
    namespaced: true,

    state: {
        allUnits: [],

        viewingUnit: null,
    },

    mutations: {
        set_all_units(state, units) {
            state.allUnits = units;
        },

        add_unit(state, newUnit) {
            state.allUnits.push(newUnit);
        },

        update_unit(state, updatedUnit) {
            const index = state.allUnits.findIndex(u => u.id === updatedUnit.id);

            state.allUnits.splice(index, 1, updatedUnit);
            console.log("state.allUnits", state.allUnits);
        },

        set_viewing_unit(state, unit) {
            state.viewingUnit = unit;
        },
    },
};
