<template>
    <f7-block class="padding-vertical">
        <f7-block v-if="loading" class="row demo-preloaders align-items-stretch text-align-center margin-bottom">
            <f7-col>
                <f7-preloader></f7-preloader>
            </f7-col>
        </f7-block>
        <template v-else>
            <f7-list class="margin-bottom">
                <f7-list-item title="Solo admisiones generadas desde Inmunopax">
                    <f7-toggle :checked="options.source.length > 0" @change="handleChangePaxToggle($event)"></f7-toggle>
                </f7-list-item>

                <f7-list-item
                    title="Institución"
                    smart-select
                    :smart-select-params="{ openIn: 'popup', searchbar: true, searchbarPlaceholder: 'Buscar instituciones', setValueText: false }"
                >
                    <select multiple v-model="options.institutions">
                        <option v-for="institution in allInstitutions" :key="institution.id" :value="institution.id">
                            {{ institution.name }}
                        </option>
                    </select>
                    <div slot="after">{{ optionsInstitutionsArray.join(", ") }}</div>
                </f7-list-item>

                <f7-list-item title="Prioridad" smart-select :smart-select-params="{ openIn: 'popup', setValueText: false }">
                    <select multiple v-model="options.priorities">
                        <option v-for="priority in priorities" :key="priority.id" :value="priority.id">
                            {{ priority.name }}
                        </option>
                    </select>
                    <div slot="after">{{ optionsPrioritiesArray.join(", ") }}</div>
                </f7-list-item>
            </f7-list>

            <f7-block>
                <p class="margin-bottom-half"><b>Código de OT</b></p>
                <f7-input
                    class="margin-bottom"
                    type="text"
                    @input="options.ot_code = $event.target.value"
                    :value="options.ot_code"
                    @keyup.native.enter="emitSearch"
                    info="Puedes buscar un código de OT parcialmente. Ej: 20210731"
                ></f7-input>

                <p class="margin-bottom-half padding-top"><b>Diagnóstico</b></p>
                <f7-input
                    class="margin-bottom"
                    type="text"
                    @input="options.diagnostic = $event.target.value"
                    :value="options.diagnostic"
                    @keyup.native.enter="emitSearch"
                ></f7-input>

                <p class="margin-bottom-half"><b>Fecha de admisión</b></p>
                <f7-row class="margin-bottom padding-bottom">
                    <f7-col>
                        <label>Fecha inicio</label>
                        <f7-input
                            class="input-uppercase margin-top-half"
                            type="text"
                            :maxlength="10"
                            @input="options.initDate = $event.target.value"
                            :value="options.initDate | formatDate"
                            placeholder="dd/MM/AAAA"
                            @keyup.native.enter="emitSearch"
                        ></f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Fecha término</label>
                        <f7-input
                            class="input-uppercase margin-top-half"
                            type="text"
                            :maxlength="10"
                            @input="options.endDate = $event.target.value"
                            :value="options.endDate | formatDate"
                            placeholder="dd/MM/AAAA"
                            @keyup.native.enter="emitSearch"
                        ></f7-input>
                    </f7-col>
                </f7-row>

                <p><b>Datos del paciente</b></p>
                <f7-row class="margin-bottom">
                    <f7-col>
                        <label>Tipo de documento</label>
                        <f7-input type="select" @change="$set(options, 'document_type', Number($event.target.value))">
                            <option value="">Seleccione</option>
                            <option value="1">RUN</option>
                            <option value="2">Pasaporte</option>
                            <option value="3">DNI</option>
                            <option value="4">Otro</option>
                        </f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Número de documento</label>
                        <f7-input
                            type="text"
                            @input="$set(options, 'document_number', $event.target.value)"
                            :value="options.document_number"
                            @keyup.native.enter="emitSearch"
                        ></f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Nombre</label>
                        <f7-input
                            type="text"
                            class="input-uppercase"
                            @input="$set(options, 'name', $event.target.value)"
                            :value="options.name"
                            @keyup.native.enter="emitSearch"
                        ></f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Apellido paterno</label>
                        <f7-input
                            type="text"
                            class="input-uppercase"
                            @input="$set(options, 'lastname_f', $event.target.value)"
                            :value="options.lastname_f"
                            @keyup.native.enter="emitSearch"
                        ></f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Apellido materno</label>
                        <f7-input
                            type="text"
                            class="input-uppercase"
                            @input="$set(options, 'lastname_m', $event.target.value)"
                            :value="options.lastname_m"
                            @keyup.native.enter="emitSearch"
                        ></f7-input>
                    </f7-col>
                </f7-row>
                <f7-row class="margin-bottom">
                    <f7-col>
                        <label>Fecha de nacimiento</label>
                        <f7-input
                            class="input-uppercase"
                            type="text"
                            :maxlength="10"
                            @input="$set(options, 'birth_date', $event.target.value)"
                            :value="options.birth_date | formatDate"
                            placeholder="dd/MM/AAAA"
                            @keyup.native.enter="emitSearch"
                        ></f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Sexo</label>
                        <f7-input type="select" @change="$set(options, 'gender', $event.target.value)">
                            <option value="">Seleccione</option>
                            <option value="1">Femenino</option>
                            <option value="2">Masculino</option>
                        </f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Email</label>
                        <f7-input
                            type="text"
                            class="input-uppercase"
                            @input="$set(options, 'email', $event.target.value)"
                            :value="options.email"
                            @keyup.native.enter="emitSearch"
                        ></f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Telefono</label>
                        <f7-input
                            type="text"
                            @input="$set(options, 'phone', $event.target.value)"
                            :value="options.phone"
                            @keyup.native.enter="emitSearch"
                        ></f7-input>
                    </f7-col>
                </f7-row>

                <f7-row class="margin-bottom padding-bottom">
                    <f7-col>
                        <label>Dirección</label>
                        <f7-input
                            type="text"
                            class="input-uppercase"
                            @change="$set(options, 'address', $event.target.value)"
                            :value="options.address"
                            @keyup.native.enter="emitSearch"
                        ></f7-input>
                    </f7-col>

                    <f7-col>
                        <label>Comuna</label>
                        <f7-list no-hairlines class="no-margin-vertical">
                            <f7-list-item
                                class="smart-select-list-item-sm"
                                smart-select
                                :smart-select-params="{
                                    openIn: 'popup',
                                    searchbar: true,
                                    searchbarPlaceholder: 'Buscar comunas',
                                    setValueText: false,
                                    closeOnSelect: true,
                                }"
                            >
                                <select v-model="options.comuna_id">
                                    <option v-for="comuna in allComunas" :key="comuna.id" :value="comuna.id">{{ comuna.name }}</option>
                                </select>
                                <div slot="title">{{ selectedComuna || "Seleccione..." }}</div>
                            </f7-list-item>
                        </f7-list>
                    </f7-col>

                    <f7-col>
                        <label>País</label>
                        <f7-list no-hairlines class="no-margin-vertical">
                            <f7-list-item
                                class="smart-select-list-item-sm"
                                smart-select
                                :smart-select-params="{
                                    openIn: 'popup',
                                    searchbar: true,
                                    searchbarPlaceholder: 'Buscar paises',
                                    setValueText: false,
                                    closeOnSelect: true,
                                }"
                            >
                                <select v-model="options.country_id">
                                    <option v-for="country in allCountries" :key="country.id" :value="country.id">{{ country.name_es }}</option>
                                </select>
                                <div slot="title">{{ selectedCountry || "Seleccione..." }}</div>
                            </f7-list-item>
                        </f7-list>
                    </f7-col>

                    <f7-col>
                        <label>Previsión</label>
                        <f7-list>
                            <f7-list-item
                                class="smart-select-list-item-sm"
                                smart-select
                                :smart-select-params="{
                                    openIn: 'popup',
                                    searchbar: true,
                                    searchbarPlaceholder: 'Buscar prevision',
                                    closeOnSelect: true,
                                    setValueText: false,
                                    popupCloseLinkText: 'Cerrar',
                                    pageTitle: 'Seleccione una prevision',
                                }"
                            >
                                <select @change="$set(options, 'prevision', Number($event.target.value))" :value="options.prevision">
                                    <option value="">Seleccione...</option>
                                    <option v-for="prevision in allPrevisiones" :key="prevision.id" :value="prevision.id">
                                        {{ prevision.name }}
                                    </option>
                                </select>
                                <div slot="title">{{ selectedPrevision || "Seleccione..." }}</div>
                            </f7-list-item>
                        </f7-list>
                    </f7-col>
                </f7-row>
            </f7-block>

            <slot name="search-footer"></slot>
        </template>
    </f7-block>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
    props: {
        defaultOptions: {
            type: Function,
            required: false,
        },
        reset: {
            type: Boolean,
            required: false,
        },
    },

    data() {
        return {
            loadingInstitutions: false,
            loadingComunas: false,
            loadingCountries: false,
            loadingPrevisiones: false,

            opened: [
                { id: 0, name: "Admitidas" },
                { id: 1, name: "Abiertas" },
            ],
            priorities: [
                { id: 1, name: "Normal" },
                { id: 2, name: "Urgente" },
                { id: 3, name: "STAT" },
            ],
            institutions: [],
            comunas: [],
            countries: [],
            previsiones: [],

            options: {
                institutions: [],
                opened: [],
                priorities: [],
                diagnostic: "",
                initDate: "",
                endDate: "",
                ot_code: "",
                source: [],

                // para que funcione con v-model
                comuna_id: 0,
                country_id: 0,
            },
        };
    },

    computed: {
        ...mapGetters(["getPrevision", "getInstitution", "getComuna", "getCountry"]),

        ...mapState("buscadoresCache", ["allInstitutions", "allComunas", "allCountries", "allPrevisiones"]),

        loading() {
            const loading = this.loadingInstitutions || this.loadingComunas || this.loadingCountries || this.loadingPrevisiones;

            this.$emit("is-loading", loading);

            return loading;
        },

        // Para divs after en smart select. Problema de reactividad
        optionsInstitutionsArray() {
            if (!this.options.institutions.length) return [];
            return this.options.institutions.map((institutionId) => {
                const institution = this.getInstitution(institutionId);
                return institution.name;
            });
        },
        optionsPrioritiesArray() {
            if (!this.options.priorities.length) return [];
            return this.options.priorities.map((priorityId) => {
                const priority = this.priorities.find((p) => p.id === priorityId);
                return priority.name;
            });
        },
        selectedComuna() {
            if (!this.options.comuna_id) return "";
            const foundComuna = this.getComuna(this.options.comuna_id);
            if (!foundComuna) return "Comuna no identificada";
            return foundComuna.name;
        },
        selectedCountry() {
            if (!this.options.country_id) return "";
            const foundCountry = this.getCountry(this.options.country_id);
            if (!foundCountry) return "País no identificado";
            return foundCountry.name_es;
        },
        selectedPrevision() {
            if (!this.options.prevision) return "";
            const prevision = this.allPrevisiones.find((p) => p.id === this.options.prevision);
            if (!prevision) return "Previsión no identificada";
            return prevision.name;
        },
    },

    watch: {
        reset: function() {
            this.resetOptions();
        },

        options: {
            deep: true,
            handler() {
                this.$emit("options-changed", this.options);
            },
        },
    },

    created() {
        if (this.allInstitutions.length === 0) {
            this.getInstitutions();
        }
        if (this.allCountries.length === 0) {
            this.getCountries();
        }
        if (this.allComunas.length === 0) {
            this.getComunas();
        }
        if (this.allPrevisiones.length === 0) {
            this.getPrevisiones();
        }
    },

    methods: {
        ...mapActions(["getAPIInfo"]),

        emitSearch() {
            this.$emit("search-triggered");
        },

        resetOptions() {
            this.options = {
                institutions: [],
                opened: [],
                priorities: [],
                diagnostic: "",
                initDate: "",
                endDate: "",
                ot_code: "",
                source: "",

                comuna_id: 0,
                country_id: 0,
            };
        },

        handleChangePaxToggle(event) {
            if (event.target.checked) {
                this.$set(this.options, "source", ["pax"]);
            } else {
                this.$set(this.options, "source", "");
            }
        },

        getInstitutions() {
            if (this.allInstitutions.length > 0) {
                this.loadingInstitutions = true;
                return;
            }

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: "institutions", userToken: idToken });
                })
                .then((response) => {
                    const institutions = response.data.data;

                    this.$store.commit("buscadoresCache/set_all_institutions", institutions);
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Hubo un error al cargar las instituciones. " + error);
                })
                .finally(() => (this.loadingInstitutions = false));
        },
        getCountries() {
            if (this.allCountries.length > 0) {
                this.loadingCountries = false;
                return;
            }

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: "countries", userToken: idToken });
                })
                .then((response) => {
                    const countries = response.data.data;

                    this.$store.commit("buscadoresCache/set_all_countries", countries);
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Hubo un error al cargar paises. " + error);
                })
                .finally(() => (this.loadingCountries = false));
        },
        getComunas() {
            if (this.allComunas.length > 0) {
                this.loadingComunas = false;
                return;
            }

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: "comunas", userToken: idToken });
                })
                .then((response) => {
                    const comunas = response.data.data;

                    this.$store.commit("buscadoresCache/set_all_comunas", comunas);
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Hubo un error al cargar comunas. " + error);
                })
                .finally(() => (this.loadingComunas = false));
        },
        getPrevisiones() {
            if (this.allPrevisiones.length > 0) {
                this.loadingPrevisiones = false;
                return;
            }

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: "previsiones", userToken: idToken });
                })
                .then((response) => {
                    const previsiones = response.data.data;

                    this.$store.commit("buscadoresCache/set_all_previsiones", previsiones);
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Hubo un error al cargar previsiones. " + error);
                })
                .finally(() => (this.loadingPrevisiones = false));
        },
    },
};
</script>
