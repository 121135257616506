var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('f7-block',[_c('f7-button',{staticClass:"margin-top",attrs:{"icon-material":"warning","fill":"","color":"purple","round":"","popup-open":".admission-popup"}},[_vm._v("\n            Admisión abreviada COVID-19\n        ")])],1),_vm._v(" "),_c('f7-popup',{staticClass:"admission-popup",on:{"popup:open":function($event){return _vm.getSampleTypes()}}},[_c('f7-page',[_c('f7-navbar',{attrs:{"title":"Detalles de muestra"}},[(_vm.selectedAdmission.opened)?_c('f7-nav-right',[_c('f7-link',{attrs:{"popup-close":""}},[_vm._v("Cerrar")])],1):_vm._e()],1),_vm._v(" "),(_vm.isLoading)?_c('div',{staticClass:"text-align-center margin-top"},[_c('f7-preloader',{attrs:{"color":"teal"}})],1):_c('f7-block',{staticClass:"margin-top-half",attrs:{"nset":""}},[_c('f7-block-title',[_vm._v("Seleccione un tipo de muestra")]),_vm._v(" "),_c('f7-list',{staticClass:"margin-top-half",attrs:{"no-hairlines":""}},_vm._l((_vm.sampleTypes),function(sample){return _c('f7-list-item',{key:sample.id,attrs:{"radio":"","title":sample.name,"checked":sample.name === 'Hisopado nasofaríngeo',"name":"sample-type"},on:{"change":function($event){return _vm.selectSample(sample)}}})}),1),_vm._v(" "),_c('f7-row',{staticClass:"no-margin-bottom"},[_c('f7-col',{attrs:{"width":"100","medium":"40"}},[_c('f7-list',{staticClass:"margin-top-half margin-right",attrs:{"no-hairlines":""}},[_c('f7-list-input',{attrs:{"id":"sample-calendar","label":"Fecha y hora toma de muestra","type":"datepicker","placeholder":"Fecha y hora toma de muestra","readonly":"","clear-button":"","calendar-params":{ 
                                    closeOnSelect: true,
                                    value: _vm.defaultDate,  
                                    timePicker: true, 
                                    timePickerPlaceholder: 'Seleccione hora',  
                                    maxDate: new Date() ,  
                                    dateFormat: 'dd/mm/yyyy HH::mm',
                                },"error-message":"Debe ingresar fecha y hora","error-message-force":_vm.invalidDate},on:{"change":function($event){return _vm.selectDate($event)}}})],1)],1),_vm._v(" "),_c('f7-col',{attrs:{"width":"100","medium":"60"}},[_c('f7-list',{staticClass:"margin-top-half margin-left",attrs:{"no-hairlines":""}},[_c('f7-list-item',{key:_vm.newSample.dateUnknown,staticClass:"margin-top",attrs:{"checkbox":"","title":"Fecha toma de muestra desconocida","name":"date-unknown","checked":_vm.newSample.dateUnknown},on:{"click":function($event){_vm.newSample.dateUnknown = !_vm.newSample.dateUnknown}}})],1)],1)],1),_vm._v(" "),(_vm.selectedAdmission.opened)?_c('f7-row',[_c('f7-col',{attrs:{"width":"10","medium":"20"}}),_vm._v(" "),_c('f7-col',{attrs:{"width":"80","medium":"60"}},[_c('f7-button',{attrs:{"fill":"","round":""},on:{"click":function($event){return _vm.createSampleId()}}},[_vm._v("\n                                Procesar admisión\n                            ")])],1),_vm._v(" "),_c('f7-col',{attrs:{"width":"10","medium":"20"}})],1):_c('f7-row',[_c('f7-col',{attrs:{"width":"100","medium":"40"}}),_vm._v(" "),_c('f7-col',{attrs:{"width":"100","medium":"20"}}),_vm._v(" "),_c('f7-col',{attrs:{"width":"0","medium":"40"}},[_c('f7-button',{attrs:{"outline":"","popup-close":""}},[_vm._v("\n                                Cerrar\n                            ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }