<template>
    <f7-page>

        <f7-navbar
            :title="selectedAdmission.opened ? 'Nueva admisión' : 'Editar admisión'"  
            back-link
        >
        </f7-navbar>

        <f7-block class="text-align-center padding-vertical margin-horizontal alert" v-if="signedExams.length > 0">
            <h4>HAY EXÁMENES FIRMADOS EN ESTA ADMISIÓN</h4>
            {{alertText}}
            <br>
            <span v-for="(exam, index) in signedExams" :key="exam.id">
                <span v-if="index > 0">-</span> 
                <b> {{exam.name}} </b> 
            </span>
            <br>
            Para realizar estos cambios debe revertir la firma.
        </f7-block>

        <f7-block>
            <f7-list class="no-margin-bottom" no-hairlines media-list>

                <f7-list-item divider title="Datos de la institución"></f7-list-item>
                <f7-list-item
                    :title="selectedAdmission.institution || 'Seleccione una institución'"
                >
                    <template v-if="signedExams.length === 0">
                        <f7-button
                            v-if="!selectedAdmission.institution"
                            slot="after"
                            outline
                            small
                            raised
                            icon-material="search"
                            text="Buscar institución"
                            @click="openInstitutionSelector()"
                        />
                        <f7-button
                            v-else
                            slot="after"
                            outline
                            small
                            raised
                            icon-material="sync_alt"
                            text="Cambiar institución"
                            @click="openInstitutionSelector()"
                        />
                    </template>
                </f7-list-item>
                <f7-list-input
                    v-if="selectedAdmission.institution"
                    label="Convenio"
                    type="select"
                    :value="selectedAdmission.agreement"
                    @change="selectAgreement($event)"
                >
                    <option value="Sin convenio">Sin convenio</option>
                    <option 
                        v-for="(agreement) in agreements" 
                        :key="agreement"
                        :value="agreement"
                        :selected="agreement === selectedAdmission.agreement"
                    >
                        {{agreement}}
                    </option>
                </f7-list-input>

                <f7-list-item divider title="Datos del paciente" class="margin-top"></f7-list-item>
                <f7-list-item
                    :title="selectedAdmission.patient ? lastNamesAndNames(selectedAdmission.patient) : 'Seleccione un paciente'"
                    :subtitle="selectedAdmission.patient && formatId(selectedAdmission.patient)"
                    :text="selectedAdmission.patient && selectedAdmission.patient.fechaNacimiento"
                >
                    <f7-button
                        v-if="selectedAdmission.opened"
                        slot="after"
                        outline
                        small
                        raised
                        icon-material="search"
                        text="Buscar/agregar paciente"
                        tooltip="Agregar paciente"
                        @click="openPatientSearch()"
                    />
                    <f7-button
                        v-if="selectedAdmission.patientId && signedExams.length === 0"
                        class="margin-left"
                        slot="after"
                        outline
                        small
                        raised
                        icon-material="edit"
                        text="Editar datos"
                        tooltip="Editar datos paciente"
                        @click="editPatient()"
                    />
                </f7-list-item>

                <f7-list-item>
                    <span>Agregar diagnóstico y/o antecedentes </span>
                    <f7-toggle
                        class="margin-left background-toggle"
                        :checked="!noBackground"
                        @change="checkNoBackground()"
                    >
                    </f7-toggle>
                </f7-list-item>
                <f7-list-input
                    v-if="!noBackground"
                    label="Sospecha diagnóstica"
                    type="text"
                    placeholder="Ingrese sospecha diagnóstica"
                    :value="diagnosticObs"
                    @input="diagnosticObs = $event.target.value"
                    @blur="saveBackgroundInfo('diagnosticObs')"
                >
                </f7-list-input>
                <f7-list-input
                    v-if="!noBackground"
                    label="Antecedentes relevantes"
                    type="textarea"
                    placeholder="Ingrese antecedentes relevantes"
                    :value="background"
                    @input="background = $event.target.value"
                    @blur="saveBackgroundInfo('background')"
                ></f7-list-input>

            </f7-list>
            <template v-if="selectedAdmission.institution && selectedAdmission.patient.nombres">
                <f7-list class="no-margin-bottom">
                    <f7-list-item divider title="Exámenes" class="margin-top"></f7-list-item>
                    <f7-list-item v-if="!selectedAdmission.exams" title="No hay examenes asociados a esta admisión"></f7-list-item>
                </f7-list>
    
                <f7-card v-for="exam in selectedAdmission.exams" :key="exam.id" class="no-margin-horizontal">
                    <f7-card-header  class="no-padding no-border">
                        <f7-list media-list>
                            <f7-list-item
                                :title="exam.name"
                                :footer="exam.doctor && ('Solicitante: ' + exam.doctor.name)"
                                class="exam-list"
                            >
                            </f7-list-item>
                        </f7-list>
                        <div v-if="!signedExams.find((signedExam) => exam.id === signedExam.id)">
                            <f7-button
                                class="margin-right exam-button"
                                outline
                                small
                                raised
                                icon-material="edit" 
                                tooltip="Editar examen"
                                :href="'/exam-admission/' + exam.id" 
                                text="Editar"
                            />
                            <f7-button
                                class="margin-right exam-button"
                                outline
                                small
                                raised
                                icon-material="delete" 
                                tooltip="Eliminar examen"
                                @click="deleteExam(exam.id)" 
                                text="Eliminar"
                            />
                        </div>
                    </f7-card-header>
                    <f7-card-content>
                        <f7-list media-list>
                            <f7-list-item 
                                v-for="sample in exam.samples"
                                :key="sample.id" 
                                class="margin-left-half"
                            >
                                <div slot="text">
                                    <f7-icon material="opacity" />
                                        {{samples[sample.id].name}} (<b>#{{sample.id}}</b>)
                                    <f7-badge 
                                        v-if="sample.isRejected" 
                                        color="red" 
                                        class="margin-left-half"
                                    >
                                        Rechazada
                                    </f7-badge>
                                </div>
                            </f7-list-item>
                        </f7-list>
                    </f7-card-content>
                </f7-card>

                <f7-list media-list no-hairlines class="no-margin-top">
                    <f7-list-item>
                        <f7-button
                            slot="after"
                            outline
                            small
                            raised
                            icon-material="add"
                            text="Agregar examen"
                            href="/exam-admission/"
                        />
                    </f7-list-item>

                    <f7-list-item divider title="Muestras"></f7-list-item>
                    <f7-list-item v-if="!samples" title="No hay muestras asociadas a esta admisión"></f7-list-item>
                    <f7-list-item
                        v-for="(sample, index) in samples"
                        :key="index"
                        :title="sample.name"
                        :text="'ID: ' + sample.id"
                        :footer="(sample.resp && sample.resp.name) && ('Responsable: ' + sample.resp.name)"
                    >
                        <f7-badge v-if="noLikedSamples.includes(sample.id)" color="yellow">
                            No vinculada
                        </f7-badge>
                        <f7-badge v-if="sample.isRejected" color="red">
                            Rechazada
                        </f7-badge>
                        <f7-link
                            class="margin-right-half" 
                            slot="after" 
                            icon-material="print" 
                            tooltip="Imprimir etiquetas"
                            @click="printLabel(sample)"
                        />
                        <template v-if="signedExams.length === 0 || noLikedSamples.includes(sample.id)">
                            <f7-link
                                class="margin-right-half" 
                                slot="after" 
                                icon-material="edit" 
                                tooltip="Editar muestra"
                                :href="'/sample-admission/' + sample.id"
                            />
                            <f7-link 
                                v-if="!noLikedSamples.includes(sample.id)" 
                                class="margin-right-half" 
                                slot="after" 
                                icon-material="sync_alt" 
                                tooltip="Permutar muestra" 
                                @click="changeSample(sample.id)"
                            />
                            <f7-link 
                                slot="after" 
                                icon-material="delete" 
                                tooltip="Eliminar muestra" 
                                @click="checkDeleteSample(sample.id)"
                            />
                        </template>
                    </f7-list-item>
                    <f7-list-item>
                        <f7-button
                            slot="after"
                            outline
                            small
                            raised
                            icon-material="add"
                            text="Agregar muestra"
                            href="/sample-admission/"
                        />
                    </f7-list-item>
                    <f7-list-item divider title="Respaldo de orden"></f7-list-item>
                    <f7-list-item
                        title="Órdenes"
                        link="/add-order/"
                    />
                </f7-list>
            </template>

            <f7-row class="margin-top">
                <f7-col width="5" large="35">
                </f7-col>
                <f7-col width="90" large="30">
                    <template v-if="selectedAdmission.opened">
                        <abbreviated-admission
                            v-if="(selectedAdmission.institution && selectedAdmission.patientId) && 
                                (!selectedAdmission.exams && !selectedAdmission.samples)"
                            :processAdmission="processAdmission"
                        ></abbreviated-admission>
                        <f7-button
                            class="margin-horizontal margin-bottom"
                            fill
                            raised
                            round
                            @click="sendAdmissionInfo()"
                        >
                            Procesar admisión
                        </f7-button>
                        <f7-button
                            v-if="signedExams.length === 0"
                            class="margin-horizontal margin-bottom"
                            outline
                            raised
                            round
                            small
                            @click="deleteAdmission"
                        >
                            Eliminar admisión
                        </f7-button>
                    </template>
                </f7-col>
                <f7-col width="5" large="35">
                </f7-col>
            </f7-row>
        </f7-block>

        <institution-selector
            :selectedInstitution="selectedAdmission.institution"
            v-on:sendAgreementsInfo="assignAgreement($event)"
        />

        <patient-search
            v-on:addNewPatient="openNewPatientForm()"
            :savePatient="selectPatient"
        />

        <f7-popup class="new-patient-form" @popup:closed="isPatientFormOpened = false">
            <f7-page>
                <f7-navbar title="Crear nuevo paciente">
                    <f7-nav-right>
                        <f7-link popup-close>Cerrar</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <New-patient v-if="isPatientFormOpened" :save="createNewPatient"></New-patient>
            </f7-page>
        </f7-popup>

    </f7-page>
</template>

<script>
import { mapState } from 'vuex';
import { paxhelpersmixin } from '../../mixins/paxhelpersmixin';
import { saveInfoMixin } from '../../mixins/save-info-mixin';
import { printermixin } from '../../mixins/printermixin';
import InstitutionSelector from '../../components/admission-components/institution-selector';
import PatientSearch from '../../components/admission-components/patient-search';
import NewPatient from '../../components/newPatient';
import AbbreviatedAdmission from '../../components/admission-components/abbreviated-admission';
import ResponsibleSample from '../../components/admission-components/responsible-sample';

export default {
    mixins:[
        paxhelpersmixin,
        saveInfoMixin,
        printermixin,
    ],

    data(){
        return {
            signedExams: [],
            agreements: [],
            diagnosticObs: null,
            background: null,
            noBackground: true,
            db: this.$firebase.database(),
            isPatientFormOpened: false,
        }
    },

    components: {
        InstitutionSelector,
        PatientSearch,
        NewPatient,
        AbbreviatedAdmission,
        ResponsibleSample,
    },

    computed: {
        ...mapState(['selectedAdmission', 'profile_userInfo']),

        alertText(){
            if(this.signedExams.length === 1){
                return 'No es posible editar los datos generales de la admisión (institución, datos de paciente) porque el siguiente examen asociado está firmado:'
            }
            return 'No es posible editar los datos generales de la admisión (institución, datos de paciente) porque los siguientes exámenes asociados están firmados:'
        },

        samples() {
            return this.selectedAdmission.samples
        },

        noLikedSamples() {
            let noLikedSamples = [];
            const samples = this.selectedAdmission.samples ? Object.values(this.selectedAdmission.samples) : [];
            const exams = this.selectedAdmission.exams ? Object.values(this.selectedAdmission.exams) : [];
            samples.forEach((sample) => {
                if(!exams.find(exam => exam.samples[sample.id])){
                    noLikedSamples.push(sample.id);
                }
            });
            return noLikedSamples
        }
    },

    methods: {
        verifyBackground() {
            if(this.selectedAdmission.diagnosticObs || this.selectedAdmission.background){
                this.diagnosticObs = this.selectedAdmission.diagnosticObs;
                this.background = this.selectedAdmission.background;
                this.noBackground = false;
            }
        },

        openInstitutionSelector() {
            this.$f7.dialog.preloader('Cargando');
            this.$f7.popup.open('.institution-selector');
        },

        getAgreements() {
            if(this.selectedAdmission.institution_id){
                this.db.ref('institutions/' + this.selectedAdmission.institution_id + '/agreements').once('value')
                    .then((snapshot) => {
                        snapshot.val() && (this.agreements = Object.keys(snapshot.val()));
                    }).catch((error) => this.$f7.dialog.alert(error.message, error.code));
            }
            else{
                this.db.ref('institutions/').once('value')
                    .then((snapshot) => {
                        const institutions = Object.values(snapshot.val());
                        const selectedInstitution = institutions.find((institution) => {
                            return institution.name === this.selectedAdmission.institution
                        });
                        selectedInstitution.agreements && (this.agreements = Object.keys(selectedInstitution.agreements));
                    }).catch((error) => this.$f7.dialog.alert(error.message, error.code));
            }
        },

        assignAgreement(agreements) {
            agreements && (this.agreements = Object.keys(agreements));
        },

        selectAgreement(event) {
            const newAgreement = { agreement: event.target.value };
            this.db.ref('admissions/' + this.selectedAdmission.id).update(newAgreement).then(() => {
                this.$store.commit('updateObjectAttribute', {
                    obj: 'selectedAdmission',
                    attr: 'agreement',
                    value: event.target.value,
                });
                this.showToast('Datos guardados con éxito');
                if(!this.selectedAdmission.opened){
                    const logInfo = {
                        what: 'edit-admission',
                        details: 'Actualiza convenio',
                        admissionId: this.selectedAdmission.id,
                    };
                    this.saveAdmissionLog(logInfo).catch((error)=>{
                        this.$f7.dialog.alert(error.message, error.code)
                    });
                }
            }).catch((error) => this.$f7.dialog.alert(error.message, error.code));
        },

        openPatientSearch() {
            if(this.selectedAdmission.exams || this.selectedAdmission.samples){
                this.$f7.dialog.confirm(
                    'Si cambia el paciente se eliminaran los exámenes y muestras asociados a la admisión, ¿Desea continuar?',
                    'Cambiar paciente',
                    () => {
                        this.resetSamples()
                    }
                )
            }
            else{
                this.$f7.popup.open('.patient-search');
            }
        },

        openNewPatientForm() {
            this.isPatientFormOpened = true;
            this.$f7.popup.open('.new-patient-form');
        },

        resetSamples() {
            const preloaderAdmission = this.$f7.dialog.preloader('Guardando');
            const admission = { ...this.selectedAdmission };
            admission.exams = null;
            admission.samples = null;
            this.db.ref('admissions/' + this.selectedAdmission.id).set(admission).then(() => {
                this.$store.commit('setObjTo', {what: 'selectedAdmission', to: admission});
                preloaderAdmission.close();
                this.showToast('Cambios guardados con éxito');
                this.$f7.popup.open('.patient-search');
            }).catch((error) => {
                preloaderAdmission.close();
                this.$f7.dialog.alert(error.message, error.code);
            })
        },

        selectPatient(patient, popupClass) {
            const patientInfo = {
                patient,
                patientId: patient.id,
            };
            const updatedAdmission = { ...this.selectedAdmission, ...patientInfo};
            this.$store.commit('setObjTo', {what: 'selectedAdmission', to: updatedAdmission});

            this.db.ref('admissions/' + this.selectedAdmission.id).update(patientInfo).then(()=>{
                this.$f7.popup.close(popupClass);
                this.$f7.dialog.close();
                this.showToast('Paciente guardado con éxito');
            }).catch((err)=>{
                this.$f7.dialog.close();
                this.$f7.dialog.alert(err.message, err.code);
            })
        },

        createNewPatient(id, patient, what) {
            this.db.ref('pacientes/' + id).set(patient).then(() => { 
                this.selectPatient(patient, '.new-patient-form'); 
                this.savePatientLog(what, id, this.selectedAdmission.id).catch((err)=>{
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, err.code);
                });
            }).catch((err)=>{
                this.$f7.dialog.close();
                this.$f7.dialog.alert(err.message, err.code);
            });
        },

        editPatient() {
            this.$store.commit('setObjTo', {
                what: 'pacienteSeleccionado',
                to: this.selectedAdmission.patient,
            });
            this.$f7.views.main.router.navigate('/editPatientMeta/' + this.selectedAdmission.id);
        },

        checkNoBackground(){
            const toggle = this.$f7.toggle.get('.background-toggle');
            if(!toggle.checked){
                this.$f7.dialog.confirm(
                    '¿Está seguro de guardar esta admisión sin antecedentes?',
                    'Guardando',
                    ()=>{
                        this.noBackground = true;
                        this.diagnosticObs = null;
                        this.background = null;
                        if(!this.selectedAdmission.opened){
                            this.$f7.dialog.preloader('Guardando');
                            const newInfo = {
                                diagnosticObs: null,
                                background: null,
                            }
                            const editedAdmission = { ...this.selectedAdmission, ...newInfo}
                            this.db.ref('admissions/' + this.selectedAdmission.id).update(newInfo).then(() => {
                                this.$store.commit('setObjTo', {
                                    what: 'selectedAdmission',
                                    to: editedAdmission,
                                });
                                this.showToast('Datos guardados con éxito');
                                const logInfo = {
                                    what: 'edit-admission',
                                    details: 'Elimina diagnóstico y antecedentes',
                                    admissionId: this.selectedAdmission.id,
                                };
                                this.$f7.dialog.close();
                                this.saveAdmissionLog(logInfo).catch((error)=>{
                                    this.$f7.dialog.alert(error.message, error.code);
                                });
                            })
                        }
                    },
                    ()=>{
                        this.noBackground = false;
                        toggle.checked = true;
                    }
                );
            }
            else{
                this.noBackground = false;
            }
        },

        saveBackgroundInfo(attribute) {
            const newInfo = { [attribute] : this[attribute] }
            this.db.ref('admissions/' + this.selectedAdmission.id).update(newInfo).then(() => {
                this.$store.commit('updateObjectAttribute', {
                    obj: 'selectedAdmission',
                    attr: attribute,
                    value: this[attribute]
                });
                this.showToast('Datos guardados con éxito');

                if(!this.selectedAdmission.opened){
                    let details = 'diagnóstico';
                    (attribute === 'background') && (details = 'antecedentes');
                    const logInfo = {
                        what: 'edit-admission',
                        details: 'Actualiza ' + details,
                        admissionId: this.selectedAdmission.id,
                    };
                    this.saveAdmissionLog(logInfo).catch((error)=>{
                        this.$f7.dialog.alert(error.message, error.code)
                    });
                }
            }).catch((error) => this.$f7.dialog.alert(error.message, error.code))
        },

        printLabel(sample){
            const patient = this.selectedAdmission.patient;
            const fullName = this.lastNamesAndNames(patient);
            this.makelabel(
                fullName, 
                this.formatId(patient), 
                patient.fechaNacimiento, 
                this.suage(patient.fechaNacimiento), 
                sample.id, 
                sample.name, 
                sample.dateTime
            );
        },

        changeSample(sampleId) {
            this.$f7.dialog.confirm(
                'La muestra será reemplazada en todos los examenes vinculados ¿Desea continuar?',
                'Permutar muestra',
                ()=>{
                    this.$f7.views.main.router.navigate('/sample-admission/change/' + sampleId);
                },
            );
        },

        deleteSample(admission, sampleId) {
            delete admission.samples[sampleId];
            return this.db.ref('admissions/' + admission.id).set(admission)
        },

        deleteSampleOpenedAdmission(admission, sampleId) {
            const deletePreloader = this.$f7.dialog.preloader('Guardando');
            if(!this.noLikedSamples.includes(sampleId)){
                const admissionExams = Object.values(admission.exams);
                const linkedExams = admissionExams.filter((exam) => exam.samples[sampleId]);
                const foundExam = linkedExams.find((exam) => {
                    return Object.values(exam.samples).length === 1
                })
                if(foundExam){
                    deletePreloader.close();
                    this.$f7.dialog.alert(
                        'El examen ' + foundExam.name + ' sólo tiene vinculada esta muestra, debe permutarla antes de eliminar', 
                        'No es posible eliminar la muestra'
                    );
                    return
                }
                linkedExams.forEach((exam) => {
                    delete admission.exams[exam.id].samples[sampleId];
                })
            }
            this.deleteSample(admission, sampleId).then(() => {
                this.$store.commit('setObjTo', {what: 'selectedAdmission', to: admission});
                deletePreloader.close();
                this.showToast('Cambios guardados con éxito');
            }).catch((error) => {
                deletePreloader.close();
                this.$f7.dialog.alert(error.message, error.code);
            });
        },

        deleteSampleClosedAdmission(admission, sampleId) {
            const deletePreloader = this.$f7.dialog.preloader('Guardando');
            let allPromises = [];
            if(!this.noLikedSamples.includes(sampleId)){
                const admissionExams = Object.values(admission.exams);
                const linkedExams = admissionExams.filter((exam) => exam.samples[sampleId]);
                const foundExam = linkedExams.find((exam) => {
                    return Object.values(exam.samples).length === 1
                })
                if(foundExam){
                    deletePreloader.close();
                    this.$f7.dialog.alert(
                        'El examen ' + foundExam.name + ' sólo tiene vinculada esta muestra, debe permutarla antes de eliminar', 
                        'No es posible eliminar la muestra'
                    );
                    return
                }
                linkedExams.forEach((exam) => {
                    delete admission.exams[exam.id].samples[sampleId];
                    allPromises.push(
                        this.db.ref(`admittedExams/${exam.section_id}/${exam.id}/samples`).set(admission.exams[exam.id].samples)
                    );
                });
                const admittedSampleData = {
                    exams: null,
                    sections: null,
                }
                allPromises.push(this.db.ref(`admittedSamples/${sampleId}`).update(admittedSampleData));
            }
            this.deleteSample(admission, sampleId).then(() => {
                Promise.all(allPromises).then(() => {
                    this.$store.commit('setObjTo', {what: 'selectedAdmission', to: admission});
                    deletePreloader.close();
                    this.showToast('Cambios guardados con éxito');

                    const admissionLog = {
                        what: 'edit-admission',
                        details: 'Elimina muestra con ID: ' + sampleId,
                        admissionId: this.selectedAdmission.id,
                    };
                    this.saveAdmissionLog(admissionLog).catch((error) => {
                        this.$f7.dialog.alert(error.message, error.code)
                    });
                }).catch((error) => {
                    deletePreloader.close();
                    this.$f7.dialog.alert(error.message, error.code);
                })
            }).catch((error) => {
                deletePreloader.close();
                this.$f7.dialog.alert(error.message, error.code);
            });
        },

        checkDeleteSample(sampleId) {
            this.$f7.dialog.confirm(
                '¿Está seguro que desea eliminar a muestra',
                'Eliminar muestra',
                ()=>{
                    let admission = { ...this.selectedAdmission };
                    if(admission.opened){
                        this.deleteSampleOpenedAdmission(admission, sampleId);
                        return
                    }
                    else{
                        this.deleteSampleClosedAdmission(admission, sampleId);
                    }
                }
            );
        },

        deleteExam(examId) {
            const exams = { ...this.selectedAdmission.exams };
            let promises = [];
            this.$f7.dialog.confirm(
                '¿Está seguro que desea eliminar el examen',
                'Eliminar examen',
                ()=>{
                    this.$f7.dialog.preloader('Guardando');
                    if(!this.selectedAdmission.opened){
                        promises.push(this.db.ref('admittedExams/' + exams[examId].section_id + '/' + examId).set(null));
                        const logInfo = {
                            who: this.profile_userInfo.uid,
                            whn: this.$moment().unix(),
                            wht: 'Examen eliminado',
                        }
                        const logId = this.$firebase.database().ref('exams_log/' + examId).push().key;
                        promises.push(this.$firebase.database().ref(`exams_log/${examId}/${logId}`).set(logInfo));

                        const examSamples = Object.keys(exams[examId].samples);
                        examSamples.forEach((sampleId) => {
                            promises.push(this.db.ref('admittedSamples/' + sampleId + '/exams/' + examId).set(null));     
                        });
                    }
                    promises.push(this.db.ref('admissions/' + this.selectedAdmission.id + '/exams/' + examId).set(null));
                    Promise.all(promises).then(() => {
                        const examName = exams[examId].name;
                        delete exams[examId];
                        if(Object.keys(exams).length === 0){
                            this.$store.commit('updateObjectAttribute', {
                                obj: 'selectedAdmission',
                                attr: 'exams',
                                value: null
                            });
                        }
                        else{
                            this.$store.commit('updateObjectAttribute', {
                                obj: 'selectedAdmission',
                                attr: 'exams',
                                value: exams
                            });
                        }
                        this.$f7.dialog.close();
                        this.showToast('Examen eliminado');
                        if(!this.selectedAdmission.opened){
                            const admissionLog = {
                                what: 'edit-admission',
                                details: 'Elimina examen ' + examName,
                                admissionId: this.selectedAdmission.id,
                            };
                            this.saveAdmissionLog(admissionLog).catch((error) => {
                                this.$f7.dialog.alert(error.message, error.code)
                            });
                        }
                    }).catch((error) => {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(error.message, error.code)
                    });
                }
            )
        },

        processExams(admission, currentDate){
            let updates = {};
            Object.values(admission.exams).forEach((exam) => {
                updates['admittedExams/' + exam.section_id + '/' + exam.id] = {
                    ...exam,
                    admissionId: admission.id
                };
                const examLogId = this.db.ref('exams_log/' + exam.id).push().key;
                updates['exams_log/' + exam.id + '/' + examLogId] = {
                    who: this.profile_userInfo.uid,
                    whn: currentDate,
                    wht: 'Examen admitido',
                }
            });
            return updates
        },

        processSamples(admission, currentDate){
            let updates = {};
            Object.values(admission.samples).forEach((sample) => {
                let admittedSample = {
                    admissionId: admission.id,
                    from: currentDate,
                    sections: {},
                    exams:{},
                    admissionDate: currentDate,
                }
                Object.values(admission.exams).forEach((exam) => {
                    Object.values(exam.samples).forEach((examSample) => {
                        if(examSample.id === sample.id){
                            admittedSample.sections[exam.section_id] = false;
                            admittedSample.exams[exam.id] = false;
                        }
                    })
                });
                updates['admittedSamples/' + sample.id] = admittedSample;
                const sampleLogId =  this.db.ref('samples_log/' + sample.id).push().key;
                updates['samples_log/' + sample.id + '/' + sampleLogId] = {
                    who: this.profile_userInfo.uid,
                    whn: currentDate,
                    how: 'manual',
                    wht: 'Admitida',
                } 
            });
            return updates
        },

        processAdmission(){
            let newAdmission = { ...this.selectedAdmission };
            const currentDate = this.$moment().unix();
            newAdmission.opened = null;
            newAdmission.creationDate = new Date().getTime();
            !newAdmission.ordenes && delete newAdmission.ordenes;
            let admissionUpdates = {};
            Object.values(newAdmission.samples).forEach((sample) => {
                sample.admissionDate = currentDate;
            })
            admissionUpdates['admissions/' + newAdmission.id] = newAdmission;
            const admissionLogId = this.db.ref('admissions_log/' + newAdmission.id).push().key;
            admissionUpdates['admissions_log/' + newAdmission.id + '/' + admissionLogId] = {
                who: this.profile_userInfo.uid,
                wht: 'add-admission',
                whn: currentDate,   
                dls: 'Procesa nueva admisión',
                ptn_id: newAdmission.patientId,
            }
            admissionUpdates = Object.assign(
                admissionUpdates,
                this.processSamples(newAdmission, currentDate), 
                this.processExams(newAdmission, currentDate),
            );
            return this.db.ref().update(admissionUpdates)
        },

        sendAdmissionInfo(){
            if(!this.selectedAdmission.institution){
                this.$f7.dialog.alert('Debe seleccionar alguna institución', 'No se puede guardar admisión');
                return
            }
            else if(!this.selectedAdmission.patient){
                this.$f7.dialog.alert('Debe seleccionar algún paciente', 'No se puede guardar admisión');
                return
            }
            else if(!this.selectedAdmission.exams ){
                this.$f7.dialog.alert('Debe seleccionar al menos un examen', 'No se puede guardar admisión');
                return
            }
            const preloaderAdmission = this.$f7.dialog.preloader('Guardando')
            this.processAdmission().then(() => {
                preloaderAdmission.close();
                this.showToast('Admisión guardada con éxito');
                this.$f7.views.main.router.navigate('/admit/');
            }).catch((err)=>{
                preloaderAdmission.close();
                this.$f7.dialog.alert(err.message, err.code);
            })
        },

        deleteAdmission(){
            this.$f7.dialog.confirm(
                '¿Está seguro que desea eliminar la admisión?',
                'Eliminar admisión',
                ()=>{
                    if(this.selectedAdmission.opened){
                        this.db.ref('admissions/' + this.selectedAdmission.id).remove().then(() => {
                            this.showToast('Admisión eliminada');
                            this.$f7.views.main.router.navigate('/admit/');
                        }).catch((err)=>{
                            this.$f7.dialog.alert(err.message, err.code);
                        })
                    }
                }
            )
        },

        checkSignedExams() {
            let promises = [];
            Object.values(this.selectedAdmission.exams).forEach((exam) => {
                promises.push(this.$firebase.database().ref('admittedExams/' + exam.section_id + '/' + exam.id).once('value'))
            })
            Promise.all(promises).then((snapshots) => {
                snapshots.forEach((snapshot) => {
                    const examInfo = snapshot.val();
                    examInfo.firmado && this.signedExams.push(examInfo);
                })
            })
        }
    },

    mounted() {
        this.verifyBackground();
        if((this.agreements.length === 0) && this.selectedAdmission.institution){
            this.getAgreements();
        };
        !this.selectedAdmission.opened && this.checkSignedExams();
    }

}
</script>

<style>

.exam-list .item-text{
    height: unset !important;
	max-height: unset !important;
}

.alert{
    background: #ffe1e1;
    color: #5a0000;
    border-radius: 10px;
}

.exam-button{
    display: inline;
    padding: 3px 6px;
}

</style>