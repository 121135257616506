<template>
    <f7-page name="newAgreement">
        <f7-navbar v-if="!isEditor" title="Agregar nuevo convenio" back-link></f7-navbar>
        <f7-navbar v-else title="Editar convenio" back-link></f7-navbar>
    
        <f7-list no-hairlines-md>
            <f7-list-input
                label="Nombre convenio"
                type="text"
                name="agreementName"
                placeholder="Nombre"
                :value="agreement.name"
                @input="agreement.name = $event.target.value"
                required validate
                error-message="Debe indicar el nombre del convenio"
            ></f7-list-input>
            <f7-list-input
                label="Descripción"
                type="textarea"
                name="description"
                placeholder="Descripción (Opcional)"
                :value="agreement.description"
                @input="agreement.description = $event.target.value"
            ></f7-list-input>
        </f7-list>
        
        <f7-block>
            <f7-row>
                <f7-col width="10" medium="33">
                </f7-col>
                <f7-col width="0" medium="33">
                    <f7-button
                        :icon-material="!isEditor ? 'playlist_add' : 'edit'" 
                        fill
                        raised
                        round
                        :text="!isEditor ? 'Agregar Convenio' : 'Guardar cambios'"
                        @click=" !isEditor ? saveAgreement() : editAgreement()" 
                    />
                </f7-col>
                <f7-col width="10" medium="33">
                </f7-col>
            </f7-row>
        </f7-block>
    </f7-page>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data(){
        return {
            agreement: {
                name: '',
                description: '',
                id: '',
                enabled: true
            },
            isEditor: false
        }
    },
    computed: {
        ...mapState(['selectedAgreement', 'profile_userInfo']),
    },
    methods: {
        setAddOrEdit(){
            //Si está activado el modo de editar convenio se deben mostrar datos de convenio seleccionada
            if(this.selectedAgreement.name){
                this.isEditor = true;
                this.agreement = { ...this.selectedAgreement};
            }
        },
        redirectAgreementDetails(){
            this.$store.commit('setWhatTo',{what: 'selectedAgreement', to: this.agreement});
            this.$f7.views.main.router.navigate('/agreement/');
        },
        saveAgreement(){
            this.$f7.dialog.preloader('Guardando');
            const id = this.$firebase.database().ref('agreements/').push().key;
            this.agreement.id = id;
            this.$firebase.database().ref('agreements/' + id).set(this.agreement)
                .then(()=>{
                    this.$store.commit('updateObjectAttribute', {
                        obj: 'allAgreements',
                        attr: id,
                        value: this.agreement
                    })
                    this.redirectAgreementDetails();
                    this.$f7.dialog.close();
                    this.$f7.toast.create({text: 'Los datos se han guardado con éxito', closeTimeout: 2500}).open();
                    
                    //Se agrega información al nodo log
                    let info = {
                        who: this.profile_userInfo.uid,
                        wht: 'add-agreement',
                        whn: this.$moment().unix(),    
                        dls: 'Crea nuevo convenio',
                        pth: 'agreements/' + this.agreement.id,
                    }
                    let id = this.$firebase.database().ref('/log').push().key;
                    this.$firebase.database().ref('log/' + id).set(info)
                        .catch((err)=>{console.log(err.message)})
                })

                .catch((err)=>{
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, err.code);
                })
        },
        editAgreement(){
            this.$f7.dialog.preloader('Editando');

            this.$firebase.database().ref('agreements/' + this.agreement.id).update(this.agreement)
            .then(()=>{
                this.$store.commit('updateObjectAttribute', {
                    obj: 'allAgreements',
                    attr: this.agreement.id,
                    value: this.agreement
                })
                this.redirectAgreementDetails();
                this.$f7.dialog.close();
                this.$f7.toast.create({text: 'Los cambios se han guardado con éxito', closeTimeout: 2500}).open();
                
                //Se agrega información al nodo log
                    let logInfo = {
                        who: this.profile_userInfo.uid,
                        wht: 'edit-agreement',
                        whn: this.$moment().unix(),    
                        dls: 'Edita convenio',
                        pth: 'agreements/' + this.agreement.id,
                    }
                    let logId = this.$firebase.database().ref('/log').push().key;
                    this.$firebase.database().ref('log/' + logId).set(logInfo)
                        .catch((err)=>{console.log(err.message)})
                })
            .catch((err)=>{
                this.$f7.dialog.close();
                this.$f7.dialog.alert(err.message, err.code);
            })
        }
    },
    mounted(){
        this.setAddOrEdit();
    }
}
</script>