<template>
    <div
        class="pdf-page bg-color-white margin-top"
        :class="{ grid: !previewMode, 'pdf-page-preview': previewMode }"
        :style="`height: ${plantillasSizes.height}px; width: ${plantillasSizes.width}px;`"
    >
        <slot></slot>
    </div>
</template>

<script>
import { _plantillasSizes } from "@/app/constants";

export default {
    props: {
        previewMode: Boolean,
    },

    data() {
        return {
            plantillasSizes: _plantillasSizes,
        };
    },
};
</script>

<style scoped>
.pdf-page-preview {
    background-image: url("../../../static/img/fondox.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: 826.6px 1169.3px !important;
}
.grid {
    background: linear-gradient(-90deg, rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0% 0% / 20px 20px,
        linear-gradient(rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0% 0% / 20px 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}
</style>
