<template>
    <f7-page >
        <f7-navbar>
            <f7-nav-title >Insumos</f7-nav-title>
        </f7-navbar>
        
            <f7-block>
                <f7-row>
                    <!-- tabla proveedores y marcas -->
                    <f7-col width="50">
                        <form @submit.prevent="update" >
                        <f7-row class="mb-2">
                            <f7-col width="50" class="padding-1">
                                <f7-button v-if="form.active" @click="form.active = 0" btn fill >Inhabilitar Insumo</f7-button>
                                <f7-button v-if="!form.active" @click="form.active = 1" btn outline >Habilitar insumo</f7-button>
                            </f7-col>
                            <f7-col width="50" class="padding-1">
                                <f7-button @click="openModal('.image')" btn outline >Imagen del insumo</f7-button>
                            </f7-col>
                        </f7-row>
                        <f7-row class="mb-2"> 
                            <f7-col width="100"  class="padding-1">
                                <label>Descripcion</label>
                                <f7-input @change="form.description = $event.target.value"  required type="text" placeholder="Pipeta" info="Descripcion del insumo en deposito" clear-button :value="form.description" />
                            </f7-col>
                        </f7-row>
                         <f7-row class="mb-2"> 
                            <f7-col width="100"  class="padding-1">
                                <label class="item-title" style="font-size: 13px !important; color: black; margin-top:1%">Secciones</label>
                            </f7-col>
                        </f7-row>
                        <f7-row class="mb-2" no-gap>
                           
                                <f7-col v-for="item of sectionsCheck()" width="50" :key="item.id">
                                    <f7-list no-hairlines-md style="margin:0">
                                        <f7-list-item style="padding: 0 !important" :checked="checkedBox(item.id)" checkbox @change="changeSection($event.target.checked,item.id)" :title="item.name"></f7-list-item>
                                    </f7-list>
                                </f7-col>

                        </f7-row>
                        <f7-row class="mb-2">
                            <f7-col>
                                <f7-list no-hairlines-md> 
                                    <label class="item-title" style="font-size: 13px; color: black; padding-left:1.5%">Documento de seguridad</label>
                                    <f7-list-input name="fileInput" accept="application/pdf"  type="file"  @change="security = $event.target.files[0]" ></f7-list-input>
                                    <template v-if="(form.security == '')">
                                        <label style="padding-left:2%">Sin archivo asociado</label>
                                    </template>
                                    <template v-if="(form.security !== '')">
                                        <f7-link style="padding-left:2%" target="_blank" external :href="form.security" text="Haga click para ver el archivo asociado"></f7-link>
                                    </template>
                                </f7-list>
                            </f7-col>
                        </f7-row>
                        <f7-row class="mb-2"> 
                            <f7-col width="60"  class="padding-1">
                                <label>Medida de almacenaje</label>
                                <f7-input required type="text" @change="form.unit = $event.target.value" :value="form.unit" placeholder="Unidad de medida Descriptiva" info="Unidad de medida de alamacen: 100 Ml" />
                            </f7-col>
                            <f7-col width="40"  class="padding-1">
                                <label>Stock Critico</label>
                                <f7-input min="0" required type="number" @change="form.limit = $event.target.value" :value="form.limit" placeholder="100" info="Limite de stock antes de alertar"/>
                            </f7-col>
                        </f7-row>
                        <f7-row class="mb-2"> 
                            <f7-col width="100"  class="padding-1">
                                <label>Observaciones y detalles</label> 
                                <f7-input :value="form.detail" @change="form.detail = $event.target.value" class="rows" type="textarea" placeholder="..." info="Observaciones y detalles" resizable/>
                            </f7-col>
                        </f7-row>
                        <f7-row class="mb-2"> 
                            <f7-col width="100"  class="padding-1">
                                <label>Precauciones y advertencias</label> 
                                <f7-input :value="form.danger" @change="form.danger = $event.target.value" class="rows" type="textarea" placeholder="..." info="Medidas y reglas de manejo para su seguridad" resizable/>
                            </f7-col>
                        </f7-row>
                        <f7-row class="mb-2"> 
                            <f7-col />
                            <f7-col />
                            <f7-col >
                                <f7-button type="submit" fill  >Guardar</f7-button>
                            </f7-col>
                        </f7-row>
                        </form>
                    </f7-col>
                    <!-- Informacion del insumo -->
                    <f7-col width="50">
                        <f7-block class="tablevh">
                            <f7-row class="mb-2">
                                <f7-col width="100"  class="padding-1">
                                    <label>Relacionar proveedor a este insumo</label>
                                    <f7-input @change="providerSelected = $event.target.value" type="select" required info="Agregue los proveedor asociados a este insumo" >
                                        <option value="NA" disabled selected>Seleccione Proveedor</option>
                                        <option v-for="(item) in provider" :key="item.id" :value="item.id">{{ item.description }} </option>
                                    </f7-input>
                                </f7-col>
                            </f7-row>
                            <f7-row class="mb-2">
                                <f7-col class="padding-1"><f7-button @click="openModal('.proveedor')"  fill>Nuevo Proveedor</f7-button></f7-col>
                                <f7-col class="padding-1"><f7-button @click="relation('Supply', 'providers', 'add', providerSelected)"  outline >Agregar Prov</f7-button></f7-col>
                            </f7-row>
                            <f7-row >
                                <f7-col>
                                    <div class="data-table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style="width:90%" class="label-cell">Proveedores relacionados</th>
                                                    <th style="width:10%;text-align: center" class="numeric-cell"><f7-icon f7="trash"></f7-icon></th>
                                                </tr>
                                            </thead>
                                            <tbody style="overflow-y:scroll">
                                                <tr v-for="(item) in form.providers" :key="item.id">
                                                    <td class="label-cell">{{ item.description }}</td>
                                                    <td class="numeric-cell"><f7-button @click="relation('Supply', 'providers', 'remove', item.id)"  btn round small class="color-red" outline><f7-icon size="15" f7="trash"></f7-icon></f7-button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </f7-col>
                            </f7-row>
                        </f7-block>
                        <hr class="mb-2">
                        <f7-block class="tablevh">
                            <f7-row class="mb-2">
                                <f7-col class="padding-1">
                                    <label>Relacionar marca a este insumo</label>
                                    <f7-input @change="brandSelected = $event.target.value" type="select" required   info="Agregue las marcas asociadas a este insumo" >
                                        <option value="NA" disabled selected>Seleccione Marca</option>
                                        <option v-for="(item) in brand" :key="item.id" :value="item.id">{{ item.description }} </option>
                                    </f7-input>
                                </f7-col>
                               
                            </f7-row>
                            <f7-row class="mb-2">
                                <f7-col class="padding-1"><f7-button @click="openModal('.brands')" fill >Nueva marca</f7-button></f7-col>
                                <f7-col class="padding-1"><f7-button @click="relation('Supply', 'brands', 'add', brandSelected)" outline>Agregar Marca</f7-button></f7-col>
                            </f7-row>
                            <f7-row>
                                <f7-col>
                                    <div class="data-table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style="width:90%" class="label-cell">Marcas relacionadas</th>
                                                    <th style="width:10%;text-align: center" class="numeric-cell"><f7-icon f7="trash"></f7-icon></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item) in form.brands" :key="item.id">
                                                    <td class="label-cell">{{ item.description }}</td>
                                                    <td class="numeric-cell"><f7-button @click="relation('Supply', 'brands', 'remove', item.id)" btn round small class="color-red" outline><f7-icon size="15" f7="trash"></f7-icon></f7-button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </f7-col>
                            </f7-row>
                        </f7-block>
                    </f7-col>
                </f7-row>
            </f7-block>
        
        <f7-popup class="proveedor"><proveedor @addElementArray="addElementArray" ></proveedor></f7-popup>
        <f7-popup class="brands"><brands @addElementArray="addElementArray" ></brands></f7-popup>
        <f7-popup class="image"><imageSupply :idSupply="this.$props.id" :imageUrl="form.image" @fileUrl="image"></imageSupply></f7-popup>
        <f7-popup class="sectionsAsign"><sectionsAsign :sections="form.sections" @changeSections="changeSection" ></sectionsAsign></f7-popup>
    </f7-page>
</template>

<script>


import { mapActions, mapState } from 'vuex';
import proveedor from '../../providers/provider';
import brands from '../../brands/brands';
import imageSupply from '../components/image'
import { f7 } from 'framework7-vue';
import sectionsAsign from '../components/secciones.vue'


export default {

    props : {
        id : { required : true }
    },
    components : {
        proveedor,
        brands,
        imageSupply,
        sectionsAsign
    },
    computed : {
        ...mapState(["section", "equip", "provider", "brand"])
    },
    data(){
        return {
            form : {
                id : "",
                code : "",
                description : "",
                detail : "",
                unit : "",
                limit : 0,                
                danger : "",
                image : "",
                providers : [],
                brands : [],
                security : "",
                sections : [],
                active : 0
            },
            providerSelected : "",
            brandSelected : "",
            security : ""
        } 
    },
    mounted(){
            let textareas = document.getElementsByTagName('textarea');
    
            for (let item of textareas) {
                item.setAttribute('rows', 5);
            }
            this.init();
    },
    methods : {
        addElementArray(item){

            console.log(item)
            this.relation('Supply',item.element + "s",'add',item.value.id)
        },
        sectionsCheck(){
            console.log(this.section)
            return this.section;
        },
        changeSection(check, section){
            this.changeSection2({ check , section })
            
        },
        checkedBox(value){
            
            for(const item of this.form.sections){
                console.log(item)
                if(item == value){
                    return true
                }
            }
            return false
        },
        updateSection(values){
            this.form.sections = values;
        },
        changeSection2(values){
            if(values.check == true){
                this.form.sections.push(values.section)
            }else{
                let temp = [];
                for(const item of this.form.sections){
                    if(item != values.section){
                        temp.push(item)
                    }
                }
                this.form.sections = temp;
            }
        },
        image(url){
            this.form.image = url;
        },
        asign(value){
            this.form[value.field] = value.value;
        },
        update(){
            this.$firebase.auth().currentUser.getIdToken().then(async (token) => {
                if(this.security !== ""){ this.form.security = await this.$store.dispatch('uploadFile', {app : this, path : 'securityPDF/', file : this.security, alert : true}) }

                var result = await this.$store.dispatch("apiRequest",{ url : "supply/" ,token , method : "PUT", data : this.form, app : this});
                console.log(result)
                if(result.code == 200){
                    this.$store.dispatch("updateState", {token , state : [{"path" : "universal/?model=Supply", "model" : "supply"}]});
                }
            });
        },
        relation(model,relation,action,value){
           console.log(value)
            if(value == "NA" || value == ""){this.$store.dispatch("toast",{app : this, text : "No ha seleccionado ninguna opcion"}); return;}
            this.$firebase.auth().currentUser.getIdToken().then(async (token) => {
                let result = await this.$store.dispatch("apiRequest",{ url : "universal/relation" ,token , method : "POST", data : {
                    "model" : model , 
                    "method" : relation, 
                    "search" : this.$props.id,
                    "relation" : action+this.capitalize(relation),
                    "data" : value    
                }, app : this });

                if(result.code == 200){ this.init() }
            });
        },
        init(){
            var x = document.getElementsByName('fileInput');
            for(const item of x){
                console.log(item)
                item.value = ""
            }
            this.$firebase.auth().currentUser.getIdToken().then(async (token) => {

                var result = await this.$store.dispatch("apiRequest",{ url : "universal/search" ,token , method : "POST", data : {relation : ["providers","brands", "sections"] , model: "Supply" ,field : "id" , "search" : this.$props.id}});
                console.log(result);
                this.form.id = this.$props.id;
                this.form.code = result.info.data[0].code.padStart(10,'0');
                this.form.description = result.info.data[0].description;
                this.form.detail = result.info.data[0].detail;
                this.form.unit = result.info.data[0].unit;
                this.form.limit = result.info.data[0].limit;
                this.form.danger = result.info.data[0].danger;
                this.form.image = result.info.data[0].image;
                this.form.providers = result.info.data[0].providers;
                this.form.brands = result.info.data[0].brands;
                this.form.security = result.info.data[0].security;
                this.form.active = result.info.data[0].active;
                var temp = [];
                for(const i of result.info.data[0].sections){
                    temp.push(i.id)
                }
                this.form.sections = temp

            });
        },
        openModal(modal){
            this.$f7.popup.open(modal);
        },
        capitalize(s){
            if (typeof s !== 'string') return ''
            return s.charAt(0).toUpperCase() + s.slice(1)
        }
       

    }

}
</script>

<style scoped>
.mb-2{
    margin-bottom: 6% !important;
    

}
.mb-1{
    margin-bottom: 4% !important;
    
}
.align-left{
    text-align: left;
}
.padding-1{
    padding: 0% 2%;
}
.tablevh{
    height: 35vh;
}
.tablevh  table{
    height: 200px;
    overflow-y: scroll;
}
table{
    display: block;
}
table  th{
        background-color: white;
        width: 90%;
        z-index: 1;
        position: sticky;
        top: 0;
}
</style>