<template>
    <f7-block class="padding-vertical">
        <f7-block v-if="isLoading" class="row demo-preloaders align-items-stretch text-align-center margin-bottom">
            <f7-col>
                <f7-preloader></f7-preloader>
            </f7-col>
        </f7-block>
        <template v-else>
            <div v-if="simpleSearch">
                <f7-block class="margin-bottom">
                    <p class="margin-bottom-half"><b>Código de muestra</b></p>
                    <f7-input
                        type="text"
                        placeholder="Ingrese código de muestra"
                        @change="$set(options, 'sample_code', $event.target.value)"
                        :value="options.sample_code"
                        info="Al buscar por código de muestra se ignorarán los demas filtros"
                        @keyup.native.enter="$emit('search-triggered')"
                    ></f7-input>
                </f7-block>

                <f7-block class="padding-top-half margin-bottom">
                    <p class="margin-bottom-half"><b>Código de OT</b></p>
                    <f7-input
                        type="text"
                        placeholder="Ingrese código de OT"
                        @change="$set(options, 'ot_code', $event.target.value)"
                        :value="options.ot_code"
                        info="Puedes buscar un código de OT parcialmente. Ej: 20210731"
                        @keyup.native.enter="$emit('search-triggered')"
                    ></f7-input>
                </f7-block>

                <f7-list class="padding-top-half">
                    <f7-list-item title="Sección" smart-select :smart-select-params="{ openIn: 'popup', setValueText: false }">
                        <select multiple v-model="options.sections">
                            <option v-for="section in allSections" :key="section.id" :value="section.id">{{ section.name }}</option>
                        </select>
                        <div slot="after">{{ optionsSectionsArray.join(", ") }}</div>
                    </f7-list-item>

                    <f7-list-item title="Tipo de informe" smart-select :smart-select-params="{ openIn: 'popup', setValueText: false }">
                        <select multiple v-model="options.report_types">
                            <option v-for="reportType in allReportTypes" :key="reportType.id" :value="reportType.name">{{ reportType.name }}</option>
                        </select>
                        <div slot="after">{{ optionsReportTypesArray.join(", ") }}</div>
                    </f7-list-item>

                    <f7-list-item title="Estado del exámen" smart-select :smart-select-params="{ openIn: 'popup', setValueText: false }">
                        <select multiple v-model="options.statuses">
                            <option v-for="status in allStatuses" :key="status.id" :value="status.id">{{ status.name }}</option>
                        </select>
                        <div slot="after">{{ optionsStatusesArray.join(", ") }}</div>
                    </f7-list-item>
                </f7-list>

                <f7-button color="blue" class="margin-vertical" @click="toggleAdvancedSearch = !toggleAdvancedSearch">
                    {{ toggleAdvancedSearch ? "Ocultar" : "Mostrar" }} búsqueda avanzada
                </f7-button>
            </div>

            <div>
                <div v-show="!simpleSearch || (simpleSearch && toggleAdvancedSearch)">
                    <f7-list class="margin-bottom">
                        <f7-list-item
                            title="Institución"
                            smart-select
                            :smart-select-params="{
                                openIn: 'popup',
                                searchbar: true,
                                searchbarPlaceholder: 'Buscar instituciones',
                                setValueText: false,
                            }"
                        >
                            <select multiple v-model="options.institutions">
                                <option v-for="institution in allInstitutions" :key="institution.id" :value="institution.id">
                                    {{ institution.name }}
                                </option>
                            </select>
                            <div slot="after">{{ optionsInstitutionsArray.join(", ") }}</div>
                        </f7-list-item>

                        <f7-list-item title="Prioridad" smart-select :smart-select-params="{ openIn: 'popup', setValueText: false }">
                            <select multiple v-model="options.priorities">
                                <option v-for="priority in priorities" :key="priority.id" :value="priority.id">
                                    {{ priority.name }}
                                </option>
                            </select>
                            <div slot="after">{{ optionsPrioritiesArray.join(", ") }}</div>
                        </f7-list-item>

                        <f7-list-item
                            title="Exámenes"
                            smart-select
                            :smart-select-params="{ openIn: 'popup', searchbar: true, searchbarPlaceholder: 'Buscar exámenes', setValueText: false }"
                        >
                            <select multiple v-model="options.exams">
                                <option v-for="exam in examsBySelectedSections" :key="exam.id" :value="exam.id">
                                    {{ exam.name }}
                                </option>
                            </select>
                            <div slot="after">{{ optionsExamsArray.join(", ") }}</div>
                        </f7-list-item>

                        <f7-list-item title="Solo exámenes ingresados desde Inmunopax">
                            <f7-toggle :checked="options.source.length > 0" @change="handleChangePaxToggle($event)"></f7-toggle>
                        </f7-list-item>

                        <template v-if="useExportFields">
                            <f7-list-item
                                title="Datos del paciente"
                                smart-select
                                :smart-select-params="{ openIn: 'popup', pageTitle: 'Estas columnas aparecerán en el Excel' }"
                            >
                                <select multiple v-model="options.patient_cols">
                                    <option v-for="field in patientColumns" :key="field.key" :value="field.key">{{ field.name }}</option>
                                </select>
                            </f7-list-item>

                            <f7-list-item
                                title="Formato"
                                smart-select
                                :smart-select-params="{ openIn: 'popup', closeOnSelect: true, setValueText: false }"
                            >
                                <select @change="options.format = $event.target.value">
                                    <option value="xlsx">Excel</option>
                                    <option value="csv">CSV</option>
                                </select>
                                <div slot="after">{{ optionsFormatName }}</div>
                            </f7-list-item>
                        </template>
                    </f7-list>

                    <f7-block>
                        <p><b>Fecha de carga del examen</b></p>
                        <f7-row class="margin-bottom padding-bottom">
                            <f7-col>
                                <label>Fecha inicio</label>
                                <f7-input
                                    class="input-uppercase margin-top-half"
                                    type="text"
                                    :maxlength="10"
                                    @input="options.initDate = $event.target.value"
                                    :value="options.initDate | formatDate"
                                    placeholder="dd/MM/AAAA"
                                    @keyup.native.enter="$emit('search-triggered')"
                                ></f7-input>
                            </f7-col>
                            <f7-col>
                                <label>Fecha término</label>
                                <f7-input
                                    class="input-uppercase margin-top-half"
                                    type="text"
                                    :maxlength="10"
                                    @input="options.endDate = $event.target.value"
                                    :value="options.endDate | formatDate"
                                    placeholder="dd/MM/AAAA"
                                    @keyup.native.enter="$emit('search-triggered')"
                                ></f7-input>
                            </f7-col>
                        </f7-row>

                        <p><b>Datos del paciente</b></p>
                        <f7-row class="margin-bottom">
                            <f7-col>
                                <label>Tipo de documento</label>
                                <f7-input type="select" @change="$set(options, 'document_type', Number($event.target.value))">
                                    <option value="">Seleccione</option>
                                    <option value="1">RUN</option>
                                    <option value="2">Pasaporte</option>
                                    <option value="3">DNI</option>
                                    <option value="4">Otro</option>
                                </f7-input>
                            </f7-col>
                            <f7-col>
                                <label>Número de documento</label>
                                <f7-input
                                    type="text"
                                    @input="$set(options, 'document_number', $event.target.value)"
                                    :value="options.document_number"
                                    @keyup.native.enter="$emit('search-triggered')"
                                ></f7-input>
                            </f7-col>
                            <f7-col>
                                <label>Nombre</label>
                                <f7-input
                                    type="text"
                                    class="input-uppercase"
                                    @input="$set(options, 'name', $event.target.value)"
                                    :value="options.name"
                                    @keyup.native.enter="$emit('search-triggered')"
                                ></f7-input>
                            </f7-col>
                            <f7-col>
                                <label>Apellido paterno</label>
                                <f7-input
                                    type="text"
                                    class="input-uppercase"
                                    @input="$set(options, 'lastname_f', $event.target.value)"
                                    :value="options.lastname_f"
                                    @keyup.native.enter="$emit('search-triggered')"
                                ></f7-input>
                            </f7-col>
                            <f7-col>
                                <label>Apellido materno</label>
                                <f7-input
                                    type="text"
                                    class="input-uppercase"
                                    @input="$set(options, 'lastname_m', $event.target.value)"
                                    :value="options.lastname_m"
                                    @keyup.native.enter="$emit('search-triggered')"
                                ></f7-input>
                            </f7-col>
                        </f7-row>
                        <f7-row class="margin-bottom">
                            <f7-col>
                                <label>Fecha de nacimiento</label>
                                <f7-input
                                    class="input-uppercase"
                                    type="text"
                                    :maxlength="10"
                                    @input="$set(options, 'birth_date', $event.target.value)"
                                    :value="options.birth_date | formatDate"
                                    placeholder="dd/MM/AAAA"
                                    @keyup.native.enter="$emit('search-triggered')"
                                ></f7-input>
                            </f7-col>
                            <f7-col>
                                <label>Sexo</label>
                                <f7-input type="select" @change="$set(options, 'gender', $event.target.value)">
                                    <option value="">Seleccione</option>
                                    <option value="1">Femenino</option>
                                    <option value="2">Masculino</option>
                                </f7-input>
                            </f7-col>
                            <f7-col>
                                <label>Email</label>
                                <f7-input
                                    type="text"
                                    class="input-uppercase"
                                    @input="$set(options, 'email', $event.target.value)"
                                    :value="options.email"
                                    @keyup.native.enter="$emit('search-triggered')"
                                ></f7-input>
                            </f7-col>
                            <f7-col>
                                <label>Telefono</label>
                                <f7-input
                                    type="text"
                                    @input="$set(options, 'phone', $event.target.value)"
                                    :value="options.phone"
                                    @keyup.native.enter="$emit('search-triggered')"
                                ></f7-input>
                            </f7-col>
                        </f7-row>

                        <f7-row class="margin-bottom padding-bottom">
                            <f7-col>
                                <label>Dirección</label>
                                <f7-input
                                    type="text"
                                    class="input-uppercase"
                                    @change="$set(options, 'address', $event.target.value)"
                                    :value="options.address"
                                    @keyup.native.enter="$emit('search-triggered')"
                                ></f7-input>
                            </f7-col>

                            <f7-col>
                                <label>Comuna</label>
                                <f7-list no-hairlines class="no-margin-vertical">
                                    <f7-list-item
                                        class="smart-select-list-item-sm"
                                        smart-select
                                        :smart-select-params="{
                                            openIn: 'popup',
                                            searchbar: true,
                                            searchbarPlaceholder: 'Buscar comunas',
                                            closeOnSelect: true,
                                            setValueText: false,
                                            opupCloseLinkText: 'Cerrar',
                                            pageTitle: 'Seleccione una comuna',
                                        }"
                                    >
                                        <select v-model="options.comuna_id">
                                            <option v-for="comuna in allComunas" :key="comuna.id" :value="comuna.id">{{ comuna.name }}</option>
                                        </select>
                                        <div slot="title">{{ optionsPatientComunaName }}</div>
                                    </f7-list-item>
                                </f7-list>
                            </f7-col>

                            <f7-col>
                                <label>País</label>
                                <f7-list no-hairlines class="no-margin-vertical">
                                    <f7-list-item
                                        class="smart-select-list-item-sm"
                                        smart-select
                                        :smart-select-params="{
                                            openIn: 'popup',
                                            searchbar: true,
                                            searchbarPlaceholder: 'Buscar países',
                                            closeOnSelect: true,
                                            setValueText: false,
                                            opupCloseLinkText: 'Cerrar',
                                            pageTitle: 'Seleccione un país',
                                        }"
                                    >
                                        <select v-model="options.country_id">
                                            <option v-for="country in allCountries" :key="country.id" :value="country.id">
                                                {{ country.name_es }}
                                            </option>
                                        </select>
                                        <div slot="title">{{ optionsPatientCountryName }}</div>
                                    </f7-list-item>
                                </f7-list>
                            </f7-col>

                            <f7-col>
                                <label>Prevision</label>
                                <f7-list>
                                    <f7-list-item
                                        class="smart-select-list-item-sm"
                                        :title="options.prevision && allPrevisiones.length ? getPrevision(options.prevision).name : 'Seleccione...'"
                                        smart-select
                                        :smart-select-params="{
                                            openIn: 'popup',
                                            searchbar: true,
                                            searchbarPlaceholder: 'Buscar prevision',
                                            closeOnSelect: true,
                                            setValueText: false,
                                            popupCloseLinkText: 'Cerrar',
                                            pageTitle: 'Seleccione una prevision',
                                        }"
                                    >
                                        <select @change="$set(options, 'prevision', Number($event.target.value))" :value="options.prevision">
                                            <option value="">Seleccione...</option>
                                            <option v-for="prevision in allPrevisiones" :key="prevision.id" :value="prevision.id">
                                                {{ prevision.name }}
                                            </option>
                                        </select>
                                    </f7-list-item>
                                </f7-list>
                            </f7-col>
                        </f7-row>
                    </f7-block>
                </div>
            </div>

            <slot name="search-footer"></slot>
        </template>
    </f7-block>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { examStatusesIds } from "@/app/constants";

export default {
    props: {
        defaultOptions: Function,
        useExportFields: Boolean,
        simpleSearch: Boolean,
        reset: Boolean,
    },

    data() {
        return {
            loadingStatuses: false,
            loadingSections: false,
            loadingInstitutions: false,
            loadingComunas: false,
            loadingCountries: false,
            loadingExams: false,
            loadingPrevisiones: false,

            toggleAdvancedSearch: false,

            priorities: [
                { id: 1, name: "Normal" },
                { id: 2, name: "Urgente" },
                { id: 3, name: "STAT" },
            ],

            allReportTypes: [
                { id: 1, name: "Informe preliminar" },
                { id: 2, name: "Informe final" },
                { id: 3, name: "Informe complementario" },
            ],

            patientColumns: [
                { key: "document_type", name: "Tipo de documento" },
                { key: "document_number", name: "Numero de documento" },
                { key: "name", name: "Nombre" },
                { key: "lastname_f", name: "Apellido paterno" },
                { key: "lastname_m", name: "Apellido materno" },
                { key: "birth_date", name: "Fecha de nacimiento" },
                { key: "gender", name: "Sexo" },
                { key: "comuna_id", name: "Comuna" },
                { key: "country_id", name: "Pais" },
                { key: "address", name: "Dirección" },
                { key: "email", name: "Email" },
                { key: "phone", name: "Telefono" },
                { key: "prevision", name: "Prevision" },
            ],

            options: {
                statuses: [],
                sections: [],
                report_types: [],
                institutions: [],
                priorities: [],
                exams: [],
                initDate: "",
                endDate: "",
                patient_cols: [],
                format: "xlsx",
                sample_code: "",
                ot_code: "",
                source: "",

                // para que funcione con v-model
                comuna_id: 0,
                country_id: 0,
            },
        };
    },

    computed: {
        ...mapGetters(["getPrevision"]),

        ...mapState("buscadoresCache", ["allStatuses", "allSections", "allInstitutions", "allComunas", "allCountries", "allExams", "allPrevisiones"]),

        simpleOptions() {
            return {
                sections: this.options.sections || [],
                sample_code: this.options.sample_code || "",
                ot_code: this.options.ot_code || "",
                statuses: this.options.statuses || [],
                report_types: this.options.report_types || [],
            };
        },

        examsBySelectedSections() {
            if (this.options.sections.length === 0) {
                return this.allExams;
            }

            const exams = [];

            this.options.sections.forEach((sectionId) => {
                exams.push(...this.allExams.filter((e) => e.section.id === sectionId));
            });

            return exams;
        },

        // Para divs after en smart select. Problema de reactividad
        optionsSectionsArray() {
            if (!this.options.sections.length) return [];
            return this.options.sections.map((sectionId) => {
                const section = this.allSections.find((s) => s.id === sectionId);
                return section ? section.name : "Sin sección";
            });
        },
        optionsReportTypesArray() {
            return this.options.report_types || []
        },
        optionsStatusesArray() {
            if (!this.options.statuses.length) return [];
            return this.options.statuses.map((statusId) => examStatusesIds[statusId] || "Sin status");
        },
        optionsInstitutionsArray() {
            if (!this.options.institutions.length) return [];
            return this.options.institutions.map((institutionId) => {
                const institution = this.allInstitutions.find((i) => i.id == institutionId);

                return institution ? institution.name : "Sin institución";
            });
        },
        optionsPrioritiesArray() {
            if (!this.options.priorities.length) return [];
            return this.options.priorities.map((priorityId) => {
                const priority = this.priorities.find((p) => p.id === priorityId);

                return priority ? priority.name : "Sin prioridad";
            });
        },
        optionsExamsArray() {
            if (!this.options.exams.length) return [];
            return this.options.exams.map((examId) => {
                const exam = this.allExams.find((e) => e.id === examId);

                return exam ? exam.code : "Sin examen";
            });
        },
        optionsFormatName() {
            if (!this.options.format) return "Seleccione...";

            const format = this.options.format;

            if (format === "xlsx") return "Excel";
            if (format === "csv") return "CSV";
            return "Seleccione...";
        },
        optionsPatientCountryName() {
            if (!this.options.country_id) return "Seleccione...";
            const country = this.allCountries.find((c) => c.id === this.options.country_id);

            return country ? country.name_es : "Pais no encontrado";
        },
        optionsPatientComunaName() {
            if (!this.options.comuna_id) return "Seleccione...";
            const comunas = this.allComunas.find((c) => c.id === this.options.comuna_id);

            return comunas ? comunas.name : "Comuna no encontrada";
        },

        isLoading() {
            const loading =
                this.loadingSections ||
                this.loadingInstitutions ||
                this.loadingComunas ||
                this.loadingCountries ||
                this.loadingExams ||
                this.loadingPrevisiones ||
                this.loadingStatuses;

            this.$emit("is-loading", loading);

            return loading;
        },
    },

    watch: {
        reset: function() {
            this.setDefaultOptions();
        },

        toggleAdvancedSearch() {
            this.emitOptionsChanged();
        },

        options: {
            deep: true,
            handler() {
                this.emitOptionsChanged();
            },
        },

        // quita los examenes de una seccion deseleccionada
        "options.sections": function(newValue, oldValue) {
            if (newValue.length < oldValue.length) {
                const sectionRemoved = oldValue.filter((value) => !newValue.includes(value))[0];

                this.allExams
                    .filter((exam) => exam.section.id === sectionRemoved && this.options.exams.includes(exam.id))
                    .forEach((exam) => {
                        const index = this.options.exams.findIndex((examId) => exam.id === examId);
                        this.options.exams.splice(index, 1);
                    });
            }
        },
    },

    created() {
        if (this.allInstitutions.length === 0) {
            this.getInstitutions();
        }
        if (this.allCountries.length === 0) {
            this.getCountries();
        }
        if (this.allComunas.length === 0) {
            this.getComunas();
        }
        if (this.allPrevisiones.length === 0) {
            this.getPrevisiones();
        }

        if (this.allSections.length === 0) {
            this.getSections();
        }
        if (this.allExams.length === 0) {
            this.getExams();
        }
        if (this.allStatuses.length === 0) {
            this.getStatuses();
        }

        if (this.defaultOptions) {
            this.setDefaultOptions();
        }
    },

    methods: {
        ...mapActions(["getAPIInfo"]),

        setDefaultOptions() {
            this.resetOptions();

            const { institutions, patient, statuses } = this.defaultOptions();

            if (Array.isArray(institutions) && institutions.length > 0) {
                this.options.institutions = institutions;
            }

            if (typeof patient === "string" && patient === "basic-info") {
                this.options.patient_cols = ["document_type", "document_number", "name", "lastname_f", "lastname_m"];
            } else if (Array.isArray(patient) && patient.length > 0) {
                this.options.patient_cols = patient;
            }

            if (Array.isArray(statuses) && statuses.length > 0) {
                this.options.statuses = statuses;
            }
        },

        resetOptions() {
            this.options = {
                statuses: [],
                report_types: [],
                sections: [],
                institutions: [],
                priorities: [],
                exams: [],
                initDate: "",
                endDate: "",
                patient_cols: [],
                format: "xlsx",
                sample_code: "",
                ot_code: "",
                source: "",

                comuna_id: 0,
                country_id: 0,
            };
        },

        emitOptionsChanged() {
            if (this.simpleSearch && !this.toggleAdvancedSearch) {
                this.$emit("options-changed", this.simpleOptions);
            } else {
                this.$emit("options-changed", this.options);
            }
        },

        handleChangePaxToggle(event) {
            if (event.target.checked) {
                this.$set(this.options, "source", ["pax"]);
            } else {
                this.$set(this.options, "source", "");
            }
        },

        // Fetch
        getInstitutions() {
            if (this.allInstitutions.length > 0) {
                this.loadingInstitutions = false;
                return;
            }

            this.loadingInstitutions = true;

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: "institutions", userToken: idToken });
                })
                .then((response) => {
                    const institutions = response.data.data;

                    this.$store.commit("buscadoresCache/set_all_institutions", institutions);
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Hubo un error al cargar las instituciones. " + error);
                })
                .finally(() => (this.loadingInstitutions = false));
        },
        getCountries() {
            if (this.allCountries.length > 0) {
                this.loadingCountries = false;
                return;
            }

            this.loadingCountries = true;

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: "countries", userToken: idToken });
                })
                .then((response) => {
                    const countries = response.data.data;

                    this.$store.commit("buscadoresCache/set_all_countries", countries);
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Hubo un error al cargar paises. " + error);
                })
                .finally(() => (this.loadingCountries = false));
        },
        getComunas() {
            if (this.allComunas.length > 0) {
                this.loadingComunas = false;
                return;
            }

            this.loadingComunas = true;

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: "comunas", userToken: idToken });
                })
                .then((response) => {
                    const comunas = response.data.data;

                    this.$store.commit("buscadoresCache/set_all_comunas", comunas);
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Hubo un error al cargar comunas. " + error);
                })
                .finally(() => (this.loadingComunas = false));
        },
        getPrevisiones() {
            if (this.allPrevisiones.length > 0) {
                this.loadingPrevisiones = false;
                return;
            }

            this.loadingPrevisiones = true;

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: "previsiones", userToken: idToken });
                })
                .then((response) => {
                    const previsiones = response.data.data;

                    this.$store.commit("buscadoresCache/set_all_previsiones", previsiones);
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Hubo un error al cargar previsiones. " + error);
                })
                .finally(() => (this.loadingPrevisiones = false));
        },
        getSections() {
            if (this.allSections.length > 0) {
                this.loadingSections = false;
                return;
            }

            this.loadingSections = true;

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: "exam_sections", userToken: idToken });
                })
                .then((response) => {
                    const sections = response.data.data;

                    this.$store.commit("buscadoresCache/set_all_sections", sections);
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Hubo un error al cargar secciones. " + error);
                })
                .finally(() => (this.loadingSections = false));
        },
        getExams() {
            if (this.allExams.length > 0) {
                this.loadingExams = false;
                return;
            }

            this.loadingExams = true;

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: "exams?rows_per_page=1000", userToken: idToken });
                })
                .then((response) => {
                    const exams = response.data.data;

                    this.$store.commit("buscadoresCache/set_all_exams", exams);
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Hubo un error al cargar exámenes. " + error);
                })
                .finally(() => (this.loadingExams = false));
        },
        getStatuses() {
            if (this.allStatuses.length > 0) {
                this.loadingStatuses = false;
                return;
            }

            this.loadingStatuses = true;

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: "exam_status", userToken: idToken });
                })
                .then((response) => {
                    const statuses = response.data.data;

                    this.$store.commit("buscadoresCache/set_all_statuses", statuses);
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Hubo un error al cargar statuses. " + error);
                })
                .finally(() => (this.loadingStatuses = false));
        },
    },
};
</script>
