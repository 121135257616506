<template>
    <!-- Exámenes -->
    <f7-row class="align-items-center margin-bottom padding-bottom">
        <f7-col>
            <label>Código de examen</label>
            <f7-input
                type="text"
                @input="exam.code = $event.target.value"
                :value="exam.code"
                @keyup.native.enter="getExamByCode"
                info="Presione ENTER para buscar"
                placeholder="Ej: ALOT"
            ></f7-input>
        </f7-col>
        <f7-col>
            <label>Nombre del examen</label>

            <f7-list>
                <f7-list-item
                    class="smart-select-list-item-sm"
                    :title="
                        allExams.length === 0 ? 'Cargando...' : exam.name.length ? exam.name : 'Seleccione...'
                    "
                    smart-select
                    :smart-select-params="{
                        openIn: 'popup',
                        searchbar: true,
                        searchbarPlaceholder: 'Buscar examen',
                        closeOnSelect: true,
                        setValueText: false,
                        popupCloseLinkText: 'Cerrar',
                        pageTitle: 'Seleccione un examen',
                    }"
                    :disabled="allExams.length === 0"
                >
                    <select @change="handleExamSelected">
                        <option value="">Seleccione...</option>
                        <option
                            v-for="ex in filteredExams"
                            :key="ex.id"
                            :value="ex.code"
                            :selected="ex.code === exam.code"
                        >
                            {{ ex.name }}
                        </option>
                    </select>
                </f7-list-item>
            </f7-list>
        </f7-col>
        <f7-col>
            <label>Muestra</label>
            <f7-input type="select" @change="handleSampleSelected($event)">
                <option value="">Seleccione...</option>
                <option v-for="sample in exam.samples" :key="sample.id" :value="sample.id">
                    {{ sample.name }}
                </option>
            </f7-input>
        </f7-col>
        <f7-col>
            <label>Cantidad de muestras</label>
            <f7-input
                type="number"
                min="0"
                step="1"
                :value="selectedSample.quantity"
                @input="selectedSample.quantity = parseInt($event.target.value)"
            ></f7-input>
        </f7-col>

        <f7-col>
            <div v-if="!isAddingSample" class="display-flex">
                <f7-button icon-material="add" fill small @click="addExam">
                    Añadir examen
                </f7-button>
            </div>
            <f7-block
                v-else
                class="row demo-preloaders align-items-stretch text-align-center no-margin no-padding"
            >
                <f7-col>
                    <f7-preloader></f7-preloader>
                </f7-col>
            </f7-block>
        </f7-col>
    </f7-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { _sources } from "@/app/constants";

export default {
    props: {
        source: String,
        allExams: Array,
        reset: Boolean,
        otSection: String,
    },

    data() {
        return {
            exam: {
                code: "",
                name: "",
                samples: [],
                section: {
                    id: "",
                    name: "",
                },
            },

            selectedSample: {
                id: "",
                snomed_code: "",
                name: "",
                quantity: 1,
            },

            isAddingSample: false,
        };
    },

    computed: {
        ...mapState(["env"]),

        filteredExams() {
            if (this.source !== _sources.OT_SEARCH) {
                return this.allExams;
            }

            const filteredExams = [];

            this.allExams.forEach((exam) => {
                if (exam.section.id == this.otSection) {
                    filteredExams.push(exam);
                }
            });

            return filteredExams;
        },
    },

    watch: {
        reset() {
            this.clear();
        }
    },

    methods: {
        ...mapActions(["getAPIInfo"]),

        handleSampleSelected(ev) {
            const sampleId = ev.target.value;

            if (!sampleId) {
                this.clearSelectedSample();
                return;
            }

            const sample = this.exam.samples.find((s) => s.id == sampleId);

            this.selectedSample = {
                ...this.selectedSample,
                ...sample,
            };
        },

        addExam() {
            this.isAddingSample = true;

            const key = this.$firebase
                .database()
                .ref()
                .push().key;

            const newExam = {
                externalId: this.exam.code,
                id: key,
                name: this.exam.name,
                fechaCargado: (Date.now() / 1000) | 0,
                section_id: this.exam.section.id.toString(),
                samples: {},
            };

            const examHasSample = this.selectedSample.name.length || this.selectedSample.id.toString().length;

            if (!examHasSample) {
                // emit newExam sin samples
                // retornar
                this.isAddingSample = false;

                this.$emit("exam-added", {
                    hasSamples: false,
                    newExam,
                });

                this.clear();

                /* console.log("newExam created (sin samples)", newExam); */
                return;
            }

            const createSampleId = this.$firebase.functions().httpsCallable("createSampleId");

            createSampleId({ env: this.env })
                .then((resp) => {
                    const id = resp.data;

                    const newSample = {
                        id: id,
                        externalId: this.selectedSample.id.toString(),
                        name: this.selectedSample.name,
                        quantity: parseInt(this.selectedSample.quantity),
                    };

                    newExam.samples = {
                        [id]: newSample,
                    };

                    this.selectedSample.quantity = 1;

                    this.isAddingSample = false;

                    this.$emit("exam-added", {
                        hasSamples: true,
                        newExam,
                    });

                    this.clear();
                })
                .catch((error) => {
                    this.isAddingSample = false;

                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, error.code);
                });
        },

        getExamByCode() {
            if (!this.exam.code) {
                return;
            }

            this.clearSelectedSample();

            this.$f7.dialog.preloader("Obteniendo examen...");

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: `exams/${this.exam.code}`, userToken: idToken });
                })
                .then((resp) => {
                    const exam = resp.data.data;

                    this.exam = exam;

                    this.$f7.dialog.close();

                    this.$f7.toast.show({
                        text: "Examen encontrado",
                        position: "bottom",
                        horizontalPosition: "center",
                        closeTimeout: 3000,
                    });
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("No se ha encontrado el exámen indicado.");
                });
        },

        handleExamSelected(ev) {
            this.clearSelectedSample();

            this.exam = {
                code: "",
                name: "",
                samples: [],
                section: {
                    id: "",
                    name: "",
                },
            };

            const examCode = ev.target.value;

            if (!examCode) {
                return;
            }

            const exam = this.allExams.find((e) => e.code === examCode);

            const examObject = {
                code: exam.code,
                name: exam.name,
                samples: exam.samples,
                section: {
                    id: exam.section.id,
                    name: exam.section.name,
                },
            };

            this.exam = examObject;
        },

        clearSelectedSample() {
            this.selectedSample = {
                id: "",
                snomed_code: "",
                name: "",
                quantity: 1,
            };
        },

        clear() {
            this.clearSelectedSample();

            this.exam = {
                code: "",
                name: "",
                samples: [],
                section: {
                    id: "",
                    name: "",
                },
            };

            this.isAddingSample = false;
        },
    },
};
</script>
