<template>
    <div class="table-responsive">
        <table class="table table-bordered table-hover" :class="{'table-sm': !extraSmall, 'table-xs': extraSmall}" v-if="exams.length > 0">
            <thead class="thead-default">
                <slot name="table-header"></slot>
            </thead>
            <tbody>
                <template v-for="admissionExam in exams">
                    <slot
                        name="table-row"
                        :admission-exam="admissionExam"
                        :f="getFunctionsForScopedSlot()"
                        :class="tableRowClass(admissionExam)"
                    ></slot>
                </template>
            </tbody>
        </table>

        <div class="display-flex justify-content-center" v-if="pagination && exams.length > 0">
            <f7-button fill large raised class="padding-horizontal" @click="$emit('load-more')" v-if="pagination.page < pagination.totalPages">
                Cargar más exámenes
            </f7-button>
            <div v-else class="text-color-gray">Se han cargado todos los examenes encontrados</div>
        </div>

        <exam-status-log-popup
            :admission-exam="viewingExam"
            :opened="examStatusLogOpened"
            @status-log-added="handleExamStatusLogAdded"
            @popup-closed="handleExamStatusLogClosed"
        ></exam-status-log-popup>

        <f7-popup :opened="patientPopupOpened" @popup:closed="patientPopupOpened = false">
            <f7-page>
                <f7-navbar title="Viendo paciente">
                    <f7-nav-right>
                        <f7-link popup-close color="blue">Cerrar</f7-link>
                    </f7-nav-right>
                </f7-navbar>

                <f7-block class="margin-top-half">
                    <patient-form :admission-patient="viewingPatient || {}" show-patient readonly class="padding-horizontal-half"></patient-form>
                </f7-block>
            </f7-page>
        </f7-popup>
    </div>
</template>

<script>
import ExamStatusLogPopup from "@/app/components-migration/exam-status-log-popup.vue";
import PatientForm from "@/app/admission-migration/components/patient-form.vue";
import { examStatuses } from "@/app/constants";
import { mapGetters, mapState } from 'vuex';

export default {
    props: {
        exams: Array,
        pagination: Object,
        selectedExams: Array,
        extraSmall: Boolean,
    },

    components: {
        "exam-status-log-popup": ExamStatusLogPopup,
        "patient-form": PatientForm,
    },

    data() {
        return {
            examStatusLogOpened: false,
            viewingExam: null,

            patientPopupOpened: false,
            viewingPatient: null,
        };
    },

    watch: {
        exams: {
            deep: true,
            handler() {
                if (this.viewingExam) {
                    const updatedExam = this.exams.find((e) => e.id === this.viewingExam.id);

                    this.viewingExam = updatedExam;
                }
            },
        },
    },

    computed: {
        ...mapGetters("plantillas", ["usedPlantillaIdByReportType", "currentStatusIdByReportType"]),

        ...mapState("plantillas", ["viewingAdmissionExamOriginal", "selectedReportType"])
    },

    methods: {
        examIsChecked(examId) {
            const index = this.selectedExams.findIndex((id) => id === examId);
            return index === -1 ? false : true;
        },

        examIsCheckeable(admissionExam, status) {
            const requiredStatus = status || examStatuses.admitido;

            return admissionExam.current_status_id === requiredStatus;
        },

        handleCheckExam(wasChecked, admissionExamId) {
            this.$emit("exam-selection-changed", { wasChecked, admissionExamId });
        },

        handleExamStatusLogAdded(payload) {
            this.$emit("exam-status-log-added", payload);
        },

        handleExamStatusLogClosed() {
            this.viewingExam = null;
            this.examStatusLogOpened = false;
        },

        viewExamLog(admissionExam) {
            this.viewingExam = admissionExam;
            this.examStatusLogOpened = true;
        },

        async viewExamPDF() {
            if (!this.usedPlantillaIdByReportType || !this.currentStatusIdByReportType) {
                return;
            }

            this.$f7.dialog.close();
            this.$f7.dialog.preloader("Obteniendo información del examen...");

            const usedPlantillaId = this.usedPlantillaIdByReportType;

            if (!usedPlantillaId) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Para generar el PDF primero debes firmar el exámen con alguna plantilla");
                return;
            }

            const plantillaSnapshot = await this.$firebase
                .database()
                .ref(`/results_plantillas/${usedPlantillaId}`)
                .once("value");
            const plantilla = plantillaSnapshot.val() || null;

            if (!plantilla) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("No se ha encontrado la plantilla con la que fue infomado este exámen");
                return;
            }

            const examResults = this.viewingAdmissionExamOriginal.exam_result || [];

            let result = examResults.find(examResult => examResult.report_type === this.selectedReportType);

            if (result) {
                result = [result];
            } else {
                result = [];
            }

            const updatedViewingAdmissionExam = Object.assign({}, this.viewingAdmissionExamOriginal, { exam_result: result });

            this.$store.commit("resultPdf/set_viewing_admission_exam", updatedViewingAdmissionExam);
            this.$store.commit("resultPdf/set_using_plantilla", plantilla);

            this.$f7.views.main.router.navigate("/result-pdf-preview");

            this.$f7.dialog.close();
        },

        admissionExamSamplesCodes(admissionExam) {
            const { samples } = admissionExam;

            if (samples && Array.isArray(samples) && samples.length > 0) {
                const sampleCodes = samples.map((s) => s.sample_code).join("-");

                return sampleCodes;
            }

            return "Sin código de muestra";
        },

        admissionExamSamplesNames(admissionExam) {
            const { samples } = admissionExam;

            if (samples && Array.isArray(samples) && samples.length > 0) {
                const sampleNames = samples.map((s) => s.meta.name).join(", ");

                return sampleNames;
            }

            return "Sin muestra";
        },

        admissionExamOtCode(admissionExam) {
            const { ot } = admissionExam;

            if (ot && ot.code) {
                return ot.code;
            }

            return "Sin código de OT";
        },

        patientDocumentInfo(admissionExam) {
            const { patient } = admissionExam.admission;

            if (patient.document_type_info) {
                return `${patient.document_type_info.name} ${patient.document_number}`;
            }

            return "Sin documentos del paciente";
        },

        patientFullName(admissionExam) {
            const { patient } = admissionExam.admission;

            if (patient) {
                return `${patient.name || ""} ${patient.lastname_f || ""} ${patient.lastname_m || ""}`;
            } else {
                return "Sin paciente";
            }
        },

        getOrigin(admissionExam) {
            let institution;

            if (admissionExam.admission.institution && admissionExam.admission.institution.name) {
                institution = admissionExam.admission.institution.name;
            } else {
                institution = "Sin institución";
            }

            let admissionDate = this.$moment(admissionExam.admission.createdAt).format("lll");

            return institution + " " + admissionDate;
        },

        tableRowClass(admissionExam) {
            if (admissionExam.current_status_id === examStatuses.examen_pendiente) {
                return "table-warning";
            } else if (admissionExam.current_status_id === examStatuses.examen_rechazado) {
                return "table-danger";
            }

            return "";
        },

        viewPatient(admissionExam) {
            console.log("viewPatient", admissionExam);
            this.viewingPatient = admissionExam.admission.patient;
            this.patientPopupOpened = true;
        },

        getFunctionsForScopedSlot() {
            const {
                examIsChecked,
                examIsCheckeable,
                handleCheckExam,
                admissionExamSamplesCodes,
                admissionExamSamplesNames,
                admissionExamOtCode,
                patientDocumentInfo,
                patientFullName,
                getOrigin,
                viewExamLog,
                viewExamPDF,
                viewPatient,
            } = this;

            return {
                examIsChecked,
                examIsCheckeable,
                handleCheckExam,
                admissionExamSamplesCodes,
                admissionExamSamplesNames,
                admissionExamOtCode,
                patientDocumentInfo,
                patientFullName,
                getOrigin,
                viewExamLog,
                viewExamPDF,
                viewPatient,
            };
        },
    },
};
</script>
