export const paxFbConfigProd = {
    apiKey: "AIzaSyDAdFW3YNryaMzSWomJTifm5UrFBzNEx5c",
    authDomain: "inmunopax.firebaseapp.com",
    databaseURL: "https://inmunopax.firebaseio.com",
    projectId: "inmunopax",
    storageBucket: "inmunopax.appspot.com",
    messagingSenderId: "233858207360",
    appId: "1:233858207360:web:b13fa4b0268c907f0d6065",
    measurementId: "G-BEL69P4PD7"
};

export const paxFbConfigDev = {
    apiKey: "AIzaSyDAdFW3YNryaMzSWomJTifm5UrFBzNEx5c",
    authDomain: "inmunopax.firebaseapp.com",
    databaseURL: "https://inmunopax-dev.firebaseio.com",
    projectId: "inmunopax",
    storageBucket: "inmunopax.appspot.com",
    messagingSenderId: "233858207360",
    appId: "1:233858207360:web:b13fa4b0268c907f0d6065",
    measurementId: "G-BEL69P4PD7",
};
