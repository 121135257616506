<template>
    <f7-popup class="patient-search">
        <f7-page>
            <f7-navbar title="Seleccione un paciente">
                <f7-nav-right>
                    <f7-link popup-close>Cerrar</f7-link>
                </f7-nav-right>
            </f7-navbar>

            <f7-block>
                <f7-card class="padding margin-bottom">
                    <f7-block-title class="margin-top margin-left">Busque un paciente ya existente</f7-block-title>
                    <f7-row>
                        <f7-col width="100" medium="70">
                            <div class="list no-hairlines no-margin-vertical">
                                <ul>
                                    <li class="item-content item-input">
                                        <f7-icon class="margin-right" material="search"></f7-icon>
                                        <div class="item-inner">
                                            <div class="item-input-wrap">
                                                <input
                                                    type="text"
                                                    placeholder="Busque por nombre, apellido o identificador"
                                                    :value="query"
                                                    @input="query = $event.target.value"
                                                    @keyup="writeSearch"
                                                >
                                                <span class="input-clear-button"></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </f7-col>
                        <f7-col width="100" medium="30">
                            <f7-button
                                class="margin-top-half"
                                outline
                                raised
                                icon-material="search"
                                @click="searchPatient()"
                            >
                                Buscar
                            </f7-button>
                        </f7-col>
                    </f7-row>
                </f7-card>

                <f7-block v-if="invalidQuery">
                    Ingrese 3 o más carácteres para buscar
                </f7-block>

                <div v-else-if="isDataSearch" class="text-align-center margin-top">
                    <p>Buscando</p>
                    <f7-preloader color="teal"></f7-preloader>
                </div>

                <div class="data-table" v-else-if="searchResults.length > 0">
                    <f7-block-title class="margin-top margin-left">Seleccione un paciente de la lista</f7-block-title>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th class="label-cell">Identificador</th>
                                <th>Nombres</th>
                                <th>Apellidos</th>
                                <th>Fecha de nacimiento</th>
                            </tr>
                        </thead>
                        <tbody class="rows">
                            <tr
                                v-for="(patient, index) in searchResults"
                                :key="'r'+ index"
                                :class="{selected:(selectedAdmission.patient.id === patient.id)}"
                                @click="selectPatient(patient)"
                            >
                                <td class="checkbox-cell">
                                    <label class="checkbox">
                                        <input
                                            type="checkbox"
                                            :checked="patient.id === selectedAdmission.patient.id"
                                        />
                                        <i class="icon-checkbox"></i>
                                    </label>
                                </td>
                                <td class="label-cell">{{formatId(patient)}}</td>
                                <td>{{patient.nombres}}</td>
                                <td>{{patient.primerApellido}} {{patient.segundoApellido}}</td>
                                <td>{{patient.fechaNacimiento}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <f7-block v-else-if="noResults"> Sin resultados para {{query}}</f7-block>

                <f7-block v-if="button">
                    <f7-block-title class="margin-top text-align-center">
                        Si no encuentra el paciente buscado, agregue uno nuevo
                    </f7-block-title>
                    <f7-row class="margin-top">
                        <f7-col width="10" medium="33">
                        </f7-col>
                        <f7-col width="80" medium="33">
                            <f7-button
                                raised
                                fill
                                round
                                icon-material="person_add"
                                @click="addNewPatient()"
                                > Nuevo paciente
                            </f7-button>
                        </f7-col>
                        <f7-col width="10" medium="33">
                        </f7-col>
                    </f7-row>
                </f7-block>

            </f7-block>
        </f7-page>
    </f7-popup>
</template>

<script>
import { format } from 'rut.js';
import { mapState } from 'vuex';
import { saveInfoMixin } from '../../mixins/save-info-mixin';
import { paxhelpersmixin } from '../../mixins/paxhelpersmixin';

export default {
    props: ['savePatient'],

    data(){
        return {
            isDataSearch: false,
            searchResults:[],
            patient: null,
            button: false,
            noResults: false,
            invalidQuery: false,
            query: '',
        }
    },

    mixins: [saveInfoMixin, paxhelpersmixin],

    computed: {
        ...mapState(['selectedAdmission', 'env'])
    },

    methods:{
        searchPatient(){
            this.invalidQuery = false;
            const trimedQuery = this.query.trim();
            const cleanedQuery = trimedQuery.normalize('NFD').replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi,"$1$2").normalize();
            if(cleanedQuery.length < 3) {
                this.invalidQuery = true;
                return
            }
            this.noResults = false;
            this.isDataSearch = true;
            let search = this.$firebase.functions().httpsCallable('searchPatient');
            search({
                busqueda: cleanedQuery,
                prod: (this.$store.state.env === 'production' ? true : false),
            }).then((results) => {
                this.isDataSearch = false;
                this.button = true;
                this.searchResults = results.data.searchResults || [];
                if(this.searchResults.length === 0){
                    this.noResults = true
                }
            }).catch((error) => {
                this.isDataSearch = false;
                this.$f7.dialog.alert(error.message,error.code);
            })
        },

        selectPatient(selectedPatient){
            let patient = { ...selectedPatient };
            delete patient.score;
            this.savePatient(patient, '.patient-search');
        },

        addNewPatient(){
            this.$store.commit('setWhatTo', { what: 'pacienteSeleccionado', to: null });
            this.$f7.popup.close('.patient-search');
            this.$emit('addNewPatient')
        },

        writeSearch(event) {
            if (event.keyCode === 13){
                this.searchPatient();
            }
        }

    },

}
</script>

<style scoped>

.rows:hover{
    cursor: pointer;
}
.selected{
    color: #87c9c2;
    font-weight: 600;
}

</style>