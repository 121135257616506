<template>
    <f7-popup :opened="opened" @popup:opened="getInstitutions" @popup:closed="$emit('popup-closed')">
        <f7-page>
            <f7-navbar title="Creando una cuenta Inmunopax">
                <f7-nav-right>
                    <f7-link popup-close>Cerrar</f7-link>
                </f7-nav-right>
            </f7-navbar>

            <f7-block class="no-margin" v-if="allInstitutions && allInstitutions.length">
                <f7-list class="no-hairlines">
                    <f7-list-item
                        class="margin-bottom"
                        :title="selectedInstitutionName"
                        smart-select
                        :smart-select-params="{
                            openIn: 'popup',
                            searchbar: true,
                            searchbarPlaceholder: 'Buscar institución',
                            closeOnSelect: true,
                            setValueText: false,
                            popupCloseLinkText: 'Cerrar',
                            pageTitle: 'Seleccione una institución',
                        }"
                        after="Seleccione una institución..."
                    >
                        <select @change="handleInstitutionSelected($event.target.value)" :value="newPaxAccount.institutionId">
                            <option value="">Seleccione...</option>
                            <option v-for="institution in allInstitutions" :key="institution.id" :value="institution.id">
                                {{ institution.name }}
                            </option>
                        </select>
                    </f7-list-item>

                    <f7-list-input
                        label="Correo electrónico"
                        type="email"
                        name="email"
                        :value="newPaxAccount.email"
                        @input="newPaxAccount.email = $event.target.value"
                        placeholder="Ingrese el correo electrónico de la institución"
                    ></f7-list-input>
                </f7-list>

                <f7-button fill class="margin-horizontal" @click="createAccount">Crear cuenta Inmunopax</f7-button>
            </f7-block>
        </f7-page>
    </f7-popup>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    props: {
        opened: Boolean,
    },

    data() {
        return {
            newPaxAccount: {
                email: "",
                institutionId: "",
                institutionName: "",
            },
        };
    },

    computed: {
        ...mapState("institutions", ["allInstitutions"]),

        selectedInstitutionName() {
            if (!this.allInstitutions.length || this.newPaxAccount.institutionId === "") {
                return "Institución";
            }

            return this.newPaxAccount.institutionName || "Institución no identificada";
        },
    },

    methods: {
        ...mapActions(["getAPIInfo"]),

        getInstitutions() {
            if (this.allInstitutions && this.allInstitutions.length > 0) {
                return;
            }

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: "institutions", userToken: idToken });
                })
                .then((response) => {
                    const institutions = response.data.data;

                    this.$store.commit("institutions/set_all_institutions", institutions);

                    this.$f7.dialog.close();
                })
                .catch((err) => {
                    console.error(err);
                    this.$f7.dialog.alert("Error " + err);
                });
        },

        handleInstitutionSelected(institutionId) {
            if (!institutionId) {
                this.newPaxAccount.institutionId = "";
                this.newPaxAccount.email = "";
                return;
            }

            const institution = this.allInstitutions.find((i) => i.id == institutionId);

            this.newPaxAccount.institutionId = institution.id || "";
            this.newPaxAccount.institutionName = institution.name || "";
            this.newPaxAccount.email = institution.email || "";
        },

        createAccount() {
            this.$f7.dialog.preloader("Creando cuenta Inmunopax...");

            const createPaxAccount = this.$firebase.functions().httpsCallable("createPaxAccount");

            createPaxAccount(this.newPaxAccount)
                .then((resp) => {
                    const account = resp.data.user;

                    // Enviamos el correo para el establecimiento de contraseña
                    this.$paxApp
                        .auth()
                        .sendPasswordResetEmail(this.newPaxAccount.email)
                        .then(() => {
                            this.$emit("account-created", account);
                            this.$f7.dialog.close();
                        })
                        .catch((error) => {
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert("Error al enviar correo establecimiento de contraseña para el correo ", this.newPaxAccount.email);
                            console.error(error);
                        });
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, error.code);
                });
        },
    },
};
</script>
