<template>
    <f7-page name="lab-config">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title>Configuración del laboratorio</f7-nav-title>
        </f7-navbar>

        <f7-block>
            <h3 class="margin-bottom">Dias de atención</h3>
            <f7-checkbox value="1" class="margin-right" @change="daysChanged" :checked="selectedDays[1] !== undefined">Lunes</f7-checkbox>
            <f7-checkbox value="2" class="margin-right" @change="daysChanged" :checked="selectedDays[2] !== undefined">Martes</f7-checkbox>
            <f7-checkbox value="3" class="margin-right" @change="daysChanged" :checked="selectedDays[3] !== undefined">Miercoles</f7-checkbox>
            <f7-checkbox value="4" class="margin-right" @change="daysChanged" :checked="selectedDays[4] !== undefined">Jueves</f7-checkbox>
            <f7-checkbox value="5" class="margin-right" @change="daysChanged" :checked="selectedDays[5] !== undefined">Viernes</f7-checkbox>
            <f7-checkbox value="6" class="margin-right" @change="daysChanged" :checked="selectedDays[6] !== undefined">Sábado</f7-checkbox>
            <f7-checkbox value="7" class="margin-right" @change="daysChanged" :checked="selectedDays[7] !== undefined">Domingo</f7-checkbox>

            <h3 class="margin-bottom">Hora de apertura</h3>
            <f7-input type="text" :value="formatedHoraApertura" @input="horaApertura = $event.target.value" placeholder="HH:MM"></f7-input>

            <h3 class="margin-bottom">Hora de cierre</h3>
            <f7-input type="text" :value="formatedHoraCierre" @input="horaCierre = $event.target.value" placeholder="HH:MM"></f7-input>

            <f7-button fill @click="saveConfig" class="margin-top">Guardar</f7-button>
        </f7-block>
    </f7-page>
</template>

<script>

export default {
    data() {
        return {
            selectedDays: {},

            horaApertura: "",
            horaCierre: "",
        }
    },

    computed: {
        formatedHoraApertura() {
            if (this.horaApertura.length < 4) {
                this.horaApertura = "";
                return "";
            }

            const n = Number(this.horaApertura);

            if (isNaN(n)) {
                this.horaApertura = "";
                return "";
            }

            if (n < 0 || n > 2359) {
                this.horaApertura = "";
                return "";
            }

            return `${this.horaApertura[0]}${this.horaApertura[1]}:${this.horaApertura[2]}${this.horaApertura[3]}`
        },
        formatedHoraCierre() {
            if (this.horaCierre.length < 4) {
                this.horaCierre = "";
                return "";
            }

            const n = Number(this.horaCierre);

            if (isNaN(n)) {
                this.horaCierre = "";
                return "";
            }

            if (n < 0 || n > 2359) {
                this.horaCierre = "";
                return "";
            }

            return `${this.horaCierre[0]}${this.horaCierre[1]}:${this.horaCierre[2]}${this.horaCierre[3]}`
        }
    },

    async created() {
        // fetch config
        const snapshot = await this.$firebase.database().ref("labConfig/horarios").once("value");
        const labConfig = snapshot.val() || {};

        console.log("lab config", labConfig);

        labConfig.labDays.split(",").forEach(day => {
            this.$set(this.selectedDays, day, true);
        });

        this.horaApertura = labConfig.apertura.replace(":", "");
        this.horaCierre = labConfig.cierre.replace(":", "");
    },

    methods: {
        daysChanged(event) {
            const { value, checked } = event.target;

            if (checked) {
                this.$set(this.selectedDays, value, true);
            } else {
                this.$delete(this.selectedDays, value);
            }
        },

        async saveConfig() {
            this.$f7.dialog.preloader("Guardando configuración");

            const hrApertura = Number(this.horaApertura);
            const hrCierre = Number(this.horaCierre);

            if (Object.keys(this.selectedDays).length === 0) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Debe seleccionar un dia de atencion");
                return;
            }

            if (this.horaApertura.length === 0 || this.horaCierre.length === 0) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Debe ingresar hora de apertura y cierre");
                return;
            }

            if (isNaN(hrApertura) || isNaN(hrCierre)) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Revise el formato de hora de apertura y cierre");
                return;
            }

            const labConfig = {
                horarios: {
                    apertura: `${this.horaApertura[0]}${this.horaApertura[1]}:${this.horaApertura[2]}${this.horaApertura[3]}`,
                    cierre: `${this.horaCierre[0]}${this.horaCierre[1]}:${this.horaCierre[2]}${this.horaCierre[3]}`,
                    labDays: Object.keys(this.selectedDays).join(",")
                }
            }

            console.log("save this", labConfig);

            try {
                await this.$firebase.database().ref("labConfig").set(labConfig);

                this.$f7.dialog.close();
            } catch (error) {
                console.log(error);
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Hubo un error al guardar la configuracion del laboratorio. " + error);
            }
        }
    }
};
</script>
