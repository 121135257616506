<template>
    <f7-page name="newPendingReason">
        <f7-navbar v-if="!isEditor" title="Agregar nuevo motivo de pendiente" back-link></f7-navbar>
        <f7-navbar v-else title="Editar motivo de pendiente" back-link></f7-navbar>

        <f7-list no-hairlines-md>
            <f7-list-input
                label="Motivo de pendiente"
                type="text"
                name="pending-name"
                placeholder="Motivo de pendiente"
                :value="pendingReason.name"
                @input="pendingReason.name = $event.target.value"
                required
                validate
                error-message="Debe indicar el nombre del motivo de pendiente"
            ></f7-list-input>
            <f7-list-input
                label="Descripción"
                type="textarea"
                name="pending-description"
                placeholder="Descripción (Opcional)"
                :value="pendingReason.description"
                @input="pendingReason.description = $event.target.value"
            ></f7-list-input>
        </f7-list>

        <f7-block>
            <f7-button
                :icon-material="!isEditor ? 'playlist_add' : 'edit'"
                large
                outline
                :text="!isEditor ? 'Agregar motivo de pendiente' : 'Guardar cambios'"
                @click="!isEditor ? addPendingReason() : editPendingReason()"
            />
        </f7-block>
    </f7-page>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    data() {
        return {
            pendingReason: {
                name: "",
                description: "",
                id: "",
                enabled: true,
            },
            isEditor: false,
        };
    },

    computed: {
        ...mapState(["profile_userInfo"]),

        ...mapState("reasons", ["viewingPendingReason"]),
    },

    methods: {
        ...mapActions(["createNewInfo", "updateInfo"]),

        setAddOrEdit() {
            if (this.viewingPendingReason) {
                this.isEditor = true;
                this.pendingReason = { ...this.viewingPendingReason };
            }
        },

        redirectPendingDetails() {
            this.$store.commit("reasons/set_viewing_pending_reason", this.pendingReason);
            this.$f7.views.main.router.navigate("/migration/mantenedores/pending-reasons/details");
        },

        addPendingReason() {
            if (!this.pendingReason.name) {
                this.$f7.dialog.alert("Es necesario ingresar una razón de rechazo", "Error");
                return;
            }

            this.$f7.dialog.preloader("Guardando...");

            let pendingReason = { ...this.pendingReason };
            delete pendingReason.id;

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.createNewInfo({
                        url: "reasons/pending",
                        userToken: idToken,
                        data: pendingReason,
                    });
                })
                .then((response) => {
                    const createdPendingReason = response.data.data;

                    this.$store.commit("reasons/add_pending_reason", createdPendingReason);

                    this.pendingReason = createdPendingReason;

                    this.$f7.dialog.close();
                    this.$f7.toast.create({ text: "Los datos se han guardado con éxito", closeTimeout: 2500 }).open();

                    this.redirectPendingDetails();
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, err.code);
                });
        },

        editPendingReason() {
            if (!this.pendingReason.name) {
                this.$f7.dialog.alert("Es necesario ingresar una razón de pendiente", "Error");
                return;
            }

            this.$f7.dialog.preloader("Editando...");

            let pendingReason = { ...this.pendingReason };
            delete pendingReason.id;

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.updateInfo({
                        url: `reasons/pending/${this.pendingReason.id}`,
                        userToken: idToken,
                        data: pendingReason,
                    });
                })
                .then((response) => {
                    const updatedPendingReason = response.data.data;

                    this.$store.commit("reasons/update_pending_reason", updatedPendingReason);

                    this.pendingReason = updatedPendingReason;

                    this.$f7.dialog.close();
                    this.$f7.toast.create({ text: "Los datos se han guardado con éxito", closeTimeout: 2500 }).open();

                    this.redirectPendingDetails();
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, err.code);
                });
        },
    },
    mounted() {
        this.setAddOrEdit();
    },
};
</script>
