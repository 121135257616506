<template>
    <f7-page>
        <f7-navbar title="Nuevo egreso">
            <f7-nav-right>
                <f7-link @click="closeWindows">Cerrar</f7-link>
            </f7-nav-right>
        </f7-navbar>
        <form @submit.prevent="submit">
            <f7-block>
                <f7-row>
                    <f7-col>
                        <f7-list no-hairlines-md>
                            <li class="">
                                <div class="item-content item-input item-input-with-value">
                                    <div class="item-inner">
                                        <div class="item-title item-label">
                                            Código de ingreso del insumo
                                        </div>
                                        <div class="item-input-wrap">
                                            <input
                                                :value="find"
                                                list="deposit"
                                                type="text"
                                                placeholder="0000000000000"
                                                @input="setSupply($event.target.value)"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <datalist id="deposit">
                                <option v-for="item in supplyList" :key="item.id" :value="item.description" />
                            </datalist>
                            <f7-list-input readonly label="Descripcion del insumo" type="text" :value="supply_text"></f7-list-input>
                            <f7-list-input readonly label="Factura de ingreso" type="text" :value="supply_invoice"></f7-list-input>
                            <f7-list-input readonly label="Fecha de ingreso" type="text" :value="supply_entry"></f7-list-input>
                            <f7-list-input readonly label="Fecha de expiracion" type="text" :value="supply_expire"></f7-list-input>
                            <f7-list-input readonly label="Unidad de almacenaje" type="text" :value="supply_unit"></f7-list-input>
                            <f7-list-input readonly label="Unidades en deposito" type="text" :value="supply_deposit"></f7-list-input>
                            <f7-list-input
                                :value="retire"
                                label="Unidades a retirar"
                                type="number"
                                @change="retire = $event.target.value"
                                :min="min"
                                :max="max"
                                @blur="validarUnidades($event.target.value)"
                            ></f7-list-input>
                            <f7-button type="submit" btn fill>Guardar</f7-button>
                        </f7-list>
                    </f7-col>
                    <f7-col style="text-align: center">
                        <f7-list no-hairlines-md>
                            <f7-list-input label="Advertencia y precauciones" type="textarea" readonly :value="supply_danger"></f7-list-input>
                            <img
                                :src="supply_image"
                                style="max-width:100%,border:1px solid #fff; border-radius: 5px; padding: 2%; max-width : 100%;margin:auto"
                            />
                        </f7-list>
                    </f7-col>
                </f7-row>
            </f7-block>
        </form>
    </f7-page>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";

export default {
    props: {
        reboot: { required: true },
        entry: Object,
    },
    data() {
        return {
            find: "",
            form: {
                sypply_id: "NA",
            },
            save: {
                code: "",
                deposit_supply: "",
                date: "",
                user: "",
            },
            supply_text: "",
            supply_danger: "",
            supply_image: "",
            supply_unit: "",
            supply_invoice: "",
            supply_entry: "",
            supply_expire: "",
            supply_deposit: "",
            max: 0,
            min: 0,
            retire: 0,
            supplyList: [],
        };
    },
    watch: {
        reboot: {
            handler() {
                this.init();
            },
        },
    },
    computed: {
        ...mapState(["stock", "profile_userInfo", "supply"]),
    },
    methods: {
        supplyListFunction(value) {
            this.supplyList = [];
            var total = 0;
            for (const item of this.stock) {
                var code = item.deposit_code;
                var description = "";
                for (const supply of this.supply) {
                    if (supply.id == item.supply_id) {
                        description = supply.description;
                    }
                }
                var stringFinal = code + " - " + description;
                if (stringFinal.search(value)) {
                    if (item.quantity - item.used.length > 0) {
                        if (total < 20) {
                            this.supplyList.push({ id: item.id, description: stringFinal });
                            total++;
                        }
                    }
                }
            }
        },
        setSupply(value) {
            this.supplyListFunction(value);
            if (value.search(" - ")) {
                value = value.split(" - ")[0];
            }

            this.find = value;
            this.stock.forEach((element) => {
                if (element.deposit_code == value) {
                    this.save.deposit_supply = element.id;
                    this.save.date = moment().format("YYYY-MM-DD");
                    this.supply_id = element.supply_id;
                    this.$firebase
                        .auth()
                        .currentUser.getIdToken()
                        .then(async (token) => {
                            let values = await this.$store.dispatch("apiRequest", {
                                url: "universal/search",
                                token,
                                method: "POST",
                                data: {
                                    relation: ["provider", "brand", "supply", "used", "invoice"],
                                    model: "Deposit",
                                    field: "deposit_code",
                                    search: value,
                                },
                            });

                            this.max = values.info.data[0].quantity - values.info.data[0].used.length;
                            this.supply_text = values.info.data[0].supply.description;
                            this.supply_danger = values.info.data[0].supply.danger;
                            this.supply_image = values.info.data[0].supply.image;
                            this.supply_unit = values.info.data[0].supply.unit;
                            this.supply_invoice = values.info.data[0].invoice.code;
                            this.supply_entry = moment(values.info.data[0].entry, "YYYY-MM-DD H:m:s").format("DD/MM/YYYY");
                            this.supply_expire = moment(values.info.data[0].expiration, "YYYY-MM-DD").format("DD/MM/YYYY");
                            this.supply_deposit = values.info.data[0].quantity - values.info.data[0].used.length;

                            return;
                        });
                } else {
                    this.form.supply_id = "NA";
                }
            });
        },
        submit() {
            var found = false;
            this.save.code = this.save.deposit_supply + "" + moment().unix() + Math.floor(Math.random() * 90000) + 10000;

            if (!Object.values(this.entry || {}).length) {
                for (const element of this.stock) {
                    if (element.deposit_code == this.find) {
                        found = true;
                    }
                }
            } else {
                found = true
            }

            if (!found) {
                this.$store.dispatch("toast", { app: this, text: "Codigo de insumo invalido" });
                return false;
            }

            if (this.retire == 0) {
                this.$store.dispatch("toast", { app: this, text: "La cantidad a despachar debe ser mayor a 0" });
                return false;
            }

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then(async (token) => {
                    let preloaderAdmission = "";
                    preloaderAdmission = this.$f7.dialog.preloader("Generando egreso, espere...");

                    await this.$store.dispatch("apiRequest", {
                        url: "supply/egress",
                        token,
                        method: "POST",
                        data: { quantity: this.retire, info: this.save },
                    });

                    preloaderAdmission.close();
                    this.$emit("updateStock");
                    this.init();
                    this.$store.dispatch("toast", { app: this, text: "Egresos de insumos generados" });
                    this.$f7.popup.close(".newEgress");
                });
        },
        closeWindows() {
            this.init();
            this.$f7.popup.close(".newEgress");
        },
        init() {
            if (Object.values(this.entry || {}).length) {
                console.log("entry precargada", this.entry);

                this.find = this.entry.deposit_code;
                this.supply_text = this.entry.insumo_description;
                this.supply_invoice = this.entry.invoice.code;
                this.supply_entry = this.$moment(this.entry.entry).format("DD/MM/YYYY");
                this.supply_expire = this.$moment(this.entry.expiration).format("DD/MM/YYYY");
                this.supply_unit = this.entry.insumo_unit;
                this.supply_deposit = this.entry.quantity - this.entry.used.length;
                this.supply_image = this.entry.insumo_image;
                this.max = this.entry.quantity - this.entry.used.length;
                this.save.user = this.profile_userInfo.displayName;
                this.save.deposit_supply = this.entry.id;
                this.save.date = this.$moment().format("YYYY-MM-DD");
                return;
            }

            this.find = "";
            this.max = 0;
            this.supply_text = "";
            this.supply_danger = "";
            this.supply_image = "";
            this.supply_unit = "";
            this.supply_invoice = "";
            this.supply_entry = "";
            this.supply_expire = "";
            this.supply_deposit = "";
            this.retire = 0;
            this.save.user = this.profile_userInfo.displayName;
        },
        validarUnidades(val) {
            if (val > this.max || val < this.min) {
                this.retire = 0;
                return this.$f7.dialog.alert("Las unidades a retirar deben ser menor o igual a " + this.max + " y mayor a " + this.min);
            }
        }
    },
};
</script>

<style scoped>
.mb-2 {
    margin-bottom: 4% !important;
}
.mb-1 {
    margin-bottom: 3% !important;
}
.align-left {
    text-align: left;
}
.padding-1 {
    padding: 0% 1%;
}
.toast {
    left: 45% !important;
}
.toast-text {
    font-size: 1.3em;
}
</style>
