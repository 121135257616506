import { render, staticRenderFns } from "./director-tecnico-signature-block.vue?vue&type=template&id=2659ee2f&scoped=true&"
import script from "./director-tecnico-signature-block.vue?vue&type=script&lang=js&"
export * from "./director-tecnico-signature-block.vue?vue&type=script&lang=js&"
import style0 from "./director-tecnico-signature-block.vue?vue&type=style&index=0&id=2659ee2f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2659ee2f",
  null
  
)

export default component.exports