<template>
    <f7-popup :opened="opened" @popup:closed="$emit('popup-closed')">
        <f7-page>
            <f7-navbar :title="`Usar plantilla para ${selectedReportType}`">
                <f7-nav-right>
                    <f7-link popup-close color="blue">Cerrar</f7-link>
                </f7-nav-right>
            </f7-navbar>

            <f7-block v-if="isLoading" class="row demo-preloaders align-items-stretch margin-top text-align-center no-padding">
                <f7-col>
                    <f7-preloader></f7-preloader>
                </f7-col>
            </f7-block>
            <f7-block v-else class="margin-top-half">
                <div>
                    <f7-button @click="refreshPlantillas">Recargar plantillas</f7-button>
                </div>
                <f7c-alert v-if="!Object.keys(plantillas).length" type="info" class="margin-vertical">
                    No se encontró ninguna plantilla asociada al examen
                    <b>{{ selectedExam.exam.name }}</b>
                    .
                    <br />
                    Por favor,
                    <f7-link popup-close color="blue" @click="navToAssociateExam">asocie una plantilla al examen</f7-link>
                    previamente.
                </f7c-alert>

                <f7c-alert type="warning" class="margin-vertical" v-if="showAlertFirmadoSistemaLegacy">
                    Este exámen fue firmado en el sistema legacy por lo tanto no tiene plantilla asociada.
                </f7c-alert>

                <f7-list media-list>
                    <f7-list-item media-item v-for="plantilla in sortedPlantillas" :key="plantilla.id">
                        <div slot="header">
                            <span v-if="plantilla.inactive" class="text-color-red">Plantilla inactiva</span>
                            <span v-if="selectedExam && usedPlantillaIdByReportType === plantilla.id" class="text-color-blue">
                                <b>Se cambió el estado de este examen por última vez usando esta plantilla ({{ selectedReportType }}).</b>
                            </span>
                        </div>
                        <div slot="title">{{ plantilla.name }}</div>
                        <div slot="subtitle">{{ plantilla.description }}</div>
                        <div slot="after">
                            <f7-button
                                popup-close
                                small
                                fill
                                @click="usePlantilla(plantilla)"
                                :disabled="plantilla.inactive && selectedExam && selectedExam.used_plantilla_id !== plantilla.id"
                            >
                                Usar
                            </f7-button>
                        </div>
                    </f7-list-item>
                </f7-list>
            </f7-block>
        </f7-page>
    </f7-popup>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { examStatuses } from "@/app/constants";

export default {
    props: {
        opened: Boolean,
        selectedExam: Object,
    },

    data() {
        return {
            plantillas: {},
            isLoading: true,
        };
    },

    computed: {
        ...mapState("plantillas", ["selectedReportType"]),

        ...mapGetters("plantillas", ["usedPlantillaIdByReportType"]),

        showAlertFirmadoSistemaLegacy() {
            return this.selectedExam && this.selectedExam.current_status_id >= examStatuses.examen_firmado && !this.selectedExam.used_plantilla_id;
        },

        sortedPlantillas() {
            return Object.values(this.plantillas).sort((a, b) => {
                if (this.usedPlantillaIdByReportType === a.id) {
                    return -1;
                }

                if (this.usedPlantillaIdByReportType === b.id) {
                    return 1;
                }

                return 0;
            });
        },
    },

    watch: {
        opened: {
            async handler(value) {
                if (!value) {
                    return;
                }

                await this.fetchPlantillas();
            },
        },
    },

    methods: {
        usePlantilla(plantilla) {
            this.$emit("plantilla-selected", { plantilla });
            this.$emit("popup-closed");
        },

        navToAssociateExam() {
            const path = "/migration/mantenedores/plantillas";

            this.$store.commit("setWhatTo", { what: "activeTab", to: path });
            this.$f7.views.main.router.navigate(path, { reloadAll: true });
        },

        async refreshPlantillas() {
            await this.fetchPlantillas(true);
        },

        async fetchPlantillas(refetch = false) {
            if (!this.selectedExam) return;

            this.isLoading = true;

            const plantillas = await this.$store.dispatch("plantillas/getPlantillasByExamId", {
                examId: this.selectedExam.exam.id,
                firebase: this.$firebase,
                refetch,
            });

            this.plantillas = plantillas;

            this.isLoading = false;
        },
    },
};
</script>
