<template>
    <f7-popup :opened="opened" @popup:closed="$emit('popup-closed')" @popup:opened="handlePopupOpened">
        <f7-page>
            <f7-navbar title="Editar una muestra">
                <f7-nav-right>
                    <f7-link popup-close color="blue">Cancelar</f7-link>
                </f7-nav-right>
            </f7-navbar>

            <f7-block v-if="!loading" class="margin-top-half">
                <label>Muestra</label>
                <f7-input type="select" @change="handleSampleChanged($event.target.value)" class="margin-bottom">
                    <option v-if="editingSample.meta.id === 0" value="">Seleccione...</option>
                    <option v-for="sample in samplesToSelect" :key="sample.id" :value="sample.id" :selected="editingSample.meta.id == sample.id">
                        {{ sample.name }}
                    </option>
                </f7-input>

                <template v-if="editingSample.meta.id !== 0">
                    <label>Cantidad</label>
                    <f7-input
                        type="number"
                        @change="editingSample.quantity = Number($event.target.value)"
                        :value="editingSample.quantity"
                        :min="1"
                    ></f7-input>
                </template>

                <f7-button class="margin-top" fill @click="handleConfirm">Listo</f7-button>
            </f7-block>
        </f7-page>
    </f7-popup>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        opened: Boolean,
        admissionExam: Object,
    },

    data() {
        return {
            editingSample: {
                meta: {
                    id: 0,
                    name: "",
                },
                quantity: 1,
            },

            loading: true,
        };
    },

    computed: {
        ...mapGetters(["getExam"]),

        samplesToSelect() {
            if (!this.admissionExam) return [];
            const exam = this.getExam(this.admissionExam.exam.id);
            return exam.samples;
        },
    },

    methods: {
        handleSampleChanged(id) {
            if (!id) {
                this.clearEditingSample();
            }

            const sample = this.samplesToSelect.find((s) => s.id == id);

            this.editingSample.meta = {
                id: sample.id,
                name: sample.name,
            };
        },

        handlePopupOpened() {
            this.clearEditingSample();

            if (this.admissionExam.samples && this.admissionExam.samples.length) {
                this.editingSample = Object.assign({}, this.admissionExam.samples[0]);
            }

            this.loading = false;
        },
        handleConfirm() {
            const payload = {
                admissionExamId: this.admissionExam.id,
                sample: this.editingSample,
            };

            this.clearEditingSample();

            this.$emit("sample-edited", payload);
            this.$emit("popup-closed");
        },

        clearEditingSample() {
            this.editingSample = {
                meta: {
                    id: 0,
                    name: "",
                },
                quantity: 1,
            };
        },
    },
};
</script>
