<template>
    <f7-card :padding="false" class="card">
        <f7-card-header  class="soyblock no-padding">
            <f7-list media-list>
				<f7-list-item 
					:header="'Id Amisión: '+admision.id"
					:title="'Admisión el '+$moment(admision.creationDate).format('dddd D [de] MMMM [de] YYYY [a las] HH:mm')" 
					:subtitle="'por '+admision.username"
					>
				</f7-list-item>
            </f7-list>
			<f7-button
				v-if="!admision.opened && isEditable"
				class="margin-right"
				outline
				small
				raised
				icon-material="edit"
				text="Editar admisión"
				@click="editAdmission(admision)"
			/>
			<f7-chip 
				outline 
				v-else-if="admision.opened" 
				class="margin-right" 
				text="Admisión sin procesar"
			/>
			<f7-chip 
				outline 
				v-else-if="!admision.exams" 
				class="margin-right" 
				text="Admisión sin exámenes asociados"
			/>
        </f7-card-header>
		<f7-card-content :padding="false" v-if="!admision.opened">
			<f7-list v-for="examen in admision.exams" :key="examen.id" media-list>
				<f7-list-item 
					:class="{'skeleton-text skeleton-effect-blink':!examsMeta[examen.id],exminfirmado:(examsMeta[examen.id] && examsMeta[examen.id].firmado)}"
					class="comexmin" 
					:title="examen.name"
					:checkbox="(examsMeta[examen.id] && examsMeta[examen.id].firmado)?true:null"
					:checked="examsToPrint.includes(examen.id)"
     				@change="checkExams"
					:value="examen.id"
					:subtitle="(examsMeta[examen.id] && examsMeta[examen.id].firmado) ? 
						('Firmado el '+$moment.unix(examsMeta[examen.id].firmado).format('dddd D [de] MMMM [de] YYYY [a las] HH:mm')):'Pendiente firma'"
				>
				<f7-icon 
					material="hourglass_empty" 
					slot="after" 
					v-if="(examsMeta[examen.id] && !examsMeta[examen.id].firmado)"
				>
				</f7-icon>
				</f7-list-item>
			</f7-list>
		</f7-card-content>
		<f7-card-footer v-if="examsToPrint.length > 0">
			<f7-link 
				@click="ViewAndPrint(true, admision, examsToPrint, focusedPacienteForResults.id)" 
				isabled 
				icon-material="assignment_returned"
			>
				Descargar
			</f7-link>
			<f7-link 
				@click="ViewAndPrint(false,admision,examsToPrint,focusedPacienteForResults.id)" 
				icon-material="printer"
			>
				Ver e Imprimir
			</f7-link>
		</f7-card-footer>
	</f7-card>
</template>

<script>
import { mapState } from 'vuex';

import {pdfermixin} from '../mixins/pdfermixin'

export default {
	name: 'resultAdmission',
	
	props: [ 'admision' ],

	mixins:[ pdfermixin ],

    data() {
        return {
			observables:{},
			examsMeta:{},
			examsToPrint:[],
        }
	},
	
    computed: {
		...mapState([ 'focusedPacienteForResults', 'misprivx' ]),

		isEditable() {
			const exams = this.admision.exams ? Object.values(this.admision.exams) : [];
			for(let i = 0; i < exams.length; i++){
				if(this.misprivx.vercolas[exams[i].section_id].fir){
					return true
				}
			}
			return false
		}
	},
	
    methods:{
		checkExams(event){
			const value = event.target.value;
			if (event.target.checked) {
				this.examsToPrint.push(value);
			} 
			else {
				this.examsToPrint.splice(this.examsToPrint.indexOf(value), 1);
        	}
		},
		  
	  	editAdmission(admission){
			this.$store.commit('setObjTo', { what: 'selectedAdmission', to: admission });
			this.$store.commit('setObjTo', { what: 'previousDoctor', to: {} });
			this.$f7.views.main.router.navigate('/admission/');
		},
		
	},

    mounted(){
		let potexams=this.admision.exams || {};
		let examsids=Object.keys(potexams);
		examsids.forEach(unexamid => {
			let exammeta=potexams[unexamid];
			let seccionid=exammeta.section_id;
			this.$firebase.database().ref('admittedExams/' + seccionid +'/' + unexamid).once('value')
				.then((snapths) => {
					console.log('lleganod snap',snapths.val());
				}).catch((error) => {
					console.log(error);
			});
			this.observables[unexamid]=this.$firebase.database().ref('admittedExams/' + seccionid +'/' + unexamid);
			this.observables[unexamid].on('value', (snapshot)=> {
				let exammeta=snapshot.val();
				console.log('llego info examen',exammeta)
				let nuevo={};
				nuevo[unexamid]=exammeta;
				this.examsMeta=Object.assign({},this.examsMeta,nuevo);
			});
		});
	},
	
    beforeDestroy(){
		//apagar los observables, if any
		console.log('Apagando observables del componente examenes resultado');
		let observables=Object.values(this.observables);
		observables.forEach(observable => {
			observable.off();//apagarlo
		});
    }
}
</script>

<style>

.comexmin .item-title{
  	font-weight: bold!important;
}

.comexmin{
	background-color: #ffedf2;
}

.comexmin.exminfirmado{
	background-color: #edfff3!important;
}

</style>