<template>
    <f7-page name="newInstitution">
        <f7-navbar v-if="!isEditor" title="Agregar nueva institución" back-link></f7-navbar>
        <f7-navbar v-else title="Editar institución" back-link></f7-navbar>

        <f7-block>
            <f7-block-title class="no-margin-top no-margin-left">DATOS INSTITUCIÓN</f7-block-title>
            <f7-list no-hairlines-md>
                <f7-list-input
                    label="Nombre de la institución"
                    type="text"
                    :value="institution.name"
                    @input="institution.name = $event.target.value"
                ></f7-list-input>
                <f7-list-input
                    label="Dirección"
                    type="text"
                    :value="institution.address"
                    @input="institution.address = $event.target.value"
                ></f7-list-input>
                <f7-list-item
                    :title="institution.country_id ? getCountry(institution.country_id).name_es : 'Seleccione...'"
                    smart-select
                    :smart-select-params="{
                        openIn: 'popup',
                        searchbar: true,
                        searchbarPlaceholder: 'Buscar país',
                        closeOnSelect: true,
                        setValueText: false,
                        popupCloseLinkText: 'Cerrar',
                        pageTitle: 'Seleccione un país',
                    }"
                    :disabled="countries.length === 0"
                >
                    <select @change="institution.country_id = Number($event.target.value)" :value="institution.country_id">
                        <option value="">Seleccione...</option>
                        <option v-for="country in countries" :key="country.id" :value="country.id">
                            {{ country.name_es }}
                        </option>
                    </select>
                </f7-list-item>
            </f7-list>

            <f7-block-title class="no-margin-top no-margin-left">DATOS DEL CONTACTO</f7-block-title>
            <f7-list no-hairlines-md>
                <f7-list-input
                    label="Nombre del contacto"
                    type="text"
                    :value="institution.contact_name"
                    @input="institution.contact_name = $event.target.value"
                ></f7-list-input>
                <f7-list-input
                    label="Email del contacto"
                    type="text"
                    :value="institution.email"
                    @input="institution.email = $event.target.value"
                ></f7-list-input>
            </f7-list>
        </f7-block>

        <f7-block>
            <f7-button
                :icon-material="!isEditor ? 'playlist_add' : 'edit'"
                large
                outline
                :text="!isEditor ? 'Agregar Institución' : 'Guardar cambios'"
                @click="!isEditor ? saveInstitution() : editInstitution()"
            />
        </f7-block>
    </f7-page>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
    data() {
        return {
            institution: {
                identifier_type: "RUT",
                name: "",
                address: "",
                country_id: 0,
                contact_name: "",
                email: "",
            },
            isEditor: false,
        };
    },

    computed: {
        ...mapState(["profile_userInfo", "countries"]),

        ...mapState("institutions", ["viewingInstitution"]),

        ...mapGetters(["getCountry"]),
    },

    created() {
        this.getCountries();
    },

    methods: {
        ...mapActions(["createNewInfo", "updateInfo"]),

        setAddOrEdit() {
            if (this.viewingInstitution) {
                this.isEditor = true;
                this.institution = { ...this.viewingInstitution };
            }
        },

        redirectInstitutionDetails() {
            this.$store.commit("institutions/set_viewing_institution", this.institution);
            this.$f7.views.main.router.navigate("/migration/mantenedores/institutions/details");
        },

        saveInstitution() {
            if (!this.institution.name) {
                this.$f7.dialog.alert("Es necesario ingresar el nombre de la institución", "Error");
                return;
            }

            this.$f7.dialog.preloader("Guardando...");

            let institution = { ...this.institution };
            delete institution.id;

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.createNewInfo({
                        url: "institutions",
                        userToken: idToken,
                        data: institution,
                    });
                })
                .then((response) => {
                    const createdInstitution = response.data.data.institution;

                    this.$store.commit("institutions/add_institution", createdInstitution);

                    this.institution = createdInstitution;

                    this.$f7.dialog.close();
                    this.$f7.toast.create({ text: "Los datos se han guardado con éxito", closeTimeout: 2500 }).open();

                    this.redirectInstitutionDetails();
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, err.code);
                });
        },

        editInstitution() {
            if (!this.institution.name) {
                this.$f7.dialog.alert("Es necesario ingresar el nombre de la institución", "Error");
                return;
            }

            this.$f7.dialog.preloader("Editando...");

            let institution = { ...this.institution };
            delete institution.id;
            delete institution.createdAt;
            delete institution.updatedAt;
            delete institution.country;

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.updateInfo({
                        url: `institutions/${this.institution.id}`,
                        userToken: idToken,
                        data: institution,
                    });
                })
                .then((response) => {
                    const updatedInstitution = response.data.data;

                    this.$store.commit("institutions/update_institution", updatedInstitution);

                    this.institution = updatedInstitution;

                    this.$f7.dialog.close();
                    this.$f7.toast.create({ text: "Los datos se han guardado con éxito", closeTimeout: 2500 }).open();

                    this.redirectInstitutionDetails();
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, err.code);
                });
        },

        getCountries() {
            if (this.countries.length > 0) {
                return;
            }

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: "countries", userToken: idToken });
                })
                .then((response) => {
                    const countries = response.data.data;

                    this.$store.commit("setWhatTo", { what: "countries", to: countries });
                })
                .catch((err) => {
                    console.error(err);
                    this.$f7.dialog.alert("Error " + err);
                });
        },
    },
    mounted() {
        this.setAddOrEdit();
    },
};
</script>
