<template>
    <div>
        <f7-list class="linksmenu">
            <f7-list-item divider>
                <div class="display-flex align-items-center">
                    <div>Menu</div>
                </div>
            </f7-list-item>
            <f7-list-item
                v-for="onemenu in visibleMenu"
                :key="onemenu.url"
                @click="setactiveTab(onemenu.url)"
                v-bind:class="{ active: activeTab == onemenu.url }"
                :footer="onemenu.footer"
                link="#"
                view=".view-main"
                panel-close
            >
                <f7-icon slot="media" :material="onemenu.icono"></f7-icon>

                <div slot="title" class="title-no-wrap">
                    {{ onemenu.title }}
                </div>
            </f7-list-item>

            <template v-if="visibleMenuCovid.length > 0">
                <f7-list-item divider>
                    <div class="display-flex align-items-center">
                        <div>COVID</div>
                    </div>
                </f7-list-item>
                <f7-list-item
                    v-for="onemenu in visibleMenuCovid"
                    :key="onemenu.url"
                    @click="setactiveTab(onemenu.url)"
                    v-bind:class="{ active: activeTab == onemenu.url }"
                    :footer="onemenu.footer"
                    link="#"
                    :title="onemenu.title"
                    view=".view-main"
                    panel-close
                >
                    <f7-icon slot="media" :material="onemenu.icono"></f7-icon>
                </f7-list-item>
            </template>

            <template v-if="visibleMenuBodega.length > 0">
                <f7-list-item divider>
                    <div class="display-flex align-items-center">
                        <div>Bodega</div>
                    </div>
                </f7-list-item>
                <f7-list-item
                    v-for="onemenu in visibleMenuBodega"
                    :key="onemenu.url"
                    @click="setactiveTab(onemenu.url)"
                    v-bind:class="{ active: activeTab == onemenu.url }"
                    :footer="onemenu.footer"
                    link="#"
                    view=".view-main"
                    panel-close
                >
                    <f7-icon slot="media" :material="onemenu.icono"></f7-icon>

                    <div slot="title" class="title-no-wrap">
                        {{ onemenu.title }}
                    </div>
                </f7-list-item>
            </template>

            <template v-if="visibleMenuInmunopax.length > 0">
                <f7-list-item divider>
                    <div class="display-flex align-items-center">
                        <div>Inmunopax</div>
                    </div>
                </f7-list-item>
                <f7-list-item
                    v-for="onemenu in visibleMenuInmunopax"
                    :key="onemenu.url"
                    @click="setactiveTab(onemenu.url)"
                    v-bind:class="{ active: activeTab == onemenu.url }"
                    :footer="onemenu.footer"
                    link="#"
                    view=".view-main"
                    panel-close
                >
                    <f7-icon slot="media" :material="onemenu.icono"></f7-icon>

                    <div slot="title" class="title-no-wrap">
                        {{ onemenu.title }}
                    </div>
                </f7-list-item>
            </template>

            <template v-if="visibleMantenedores.length > 0">
                <f7-list-item divider title="Maestros"></f7-list-item>
                <f7-list-item
                    v-for="onemenu in sortMenuAlphabetically(visibleMantenedores)"
                    :key="onemenu.url"
                    @click="setactiveTab(onemenu.url)"
                    v-bind:class="{ active: activeTab == onemenu.url }"
                    :footer="onemenu.footer"
                    link="#"
                    :title="onemenu.title"
                    view=".view-main"
                    panel-close
                >
                    <f7-icon slot="media" :material="onemenu.icono"></f7-icon>
                </f7-list-item>
            </template>
        </f7-list>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    data() {
        return {
            menu_covid: [
                {
                    url: "/migration/firma-masiva",
                    footer: "Firma exámenes en batch",
                    title: "Firma COVID",
                    icono: "edit",
                    privs: ["change-exam-status-firmar"],
                },
                {
                    url: "/import-admissions/",
                    footer: "A través de archivos .xlsx",
                    title: "Importar COVID",
                    icono: "note_add",
                    privs: ["admit", "pacientes"],
                },
            ],

            menu_bodega: [
                {
                    url: "/entry/",
                    footer: "Stock de insumos",
                    title: "Inventario",
                    icono: "file_download",
                    privs: ["bodega"],
                },
                {
                    url: "/supply/",
                    footer: "Maestro Desposito",
                    title: "Insumos",
                    icono: "list_alt",
                    privs: ["bodega"],
                },
                {
                    url: "/invoice/",
                    footer: "Maestro Facturas",
                    title: "Facturas",
                    icono: "insert_chart_outlined",
                    privs: ["bodega"],
                },
                {
                    url: "/providers/",
                    footer: "Maestro Proveedores de insumos",
                    title: "Proveedores",
                    icono: "people",
                    privs: ["bodega"],
                },
            ],
            menu_inmunopax: [
                {
                    url: "/pax/solicitudes/admissions",
                    footer: "Gestiona solicitudes generadas en Pax",
                    title: "Solicitudes",
                    icono: "move_to_inbox",
                    privs: ["pax"],
                },
                {
                    url: "/migration/mantenedores/pax",
                    footer: "Usuarios de PAX",
                    title: "Accesos PAX",
                    icono: "vpn_key",
                    privs: ["pax"],
                    migration: true,
                },
            ],
            menu_mantenedores: [
                {
                    url: "/migration/mantenedores/members/",
                    footer: "Equipo Inmunocel",
                    title: "Miembros",
                    icono: "group_work",
                    privs: ["members"],
                    migration: true,
                },
                {
                    url: "/migration/mantenedores/plantillas",
                    footer: "Gestiona plantillas",
                    title: "Plantillas",
                    icono: "format_shapes",
                    privs: ["manage_plantillas"],
                    new: true,
                },
                {
                    url: "/migration/mantenedores/institutions",
                    footer: "Orígenes solicitudes",
                    title: "Instituciones",
                    icono: "account_balance",
                    privs: ["mantenedores"],
                    migration: true,
                },
                {
                    url: "/samples/",
                    footer: "Tipos de muestras",
                    title: "Muestras",
                    icono: "opacity",
                    privs: ["mantenedores"],
                },
                {
                    url: "/migration/mantenedores/patients",
                    footer: "Maestro de pacientes",
                    title: "Pacientes",
                    icono: "people",
                    privs: ["pacientes"],
                    migration: true,
                },
                {
                    url: "/migration/mantenedores/rejection-reasons",
                    footer: "Motivos de rechazo",
                    title: "Motivos Rechazos",
                    icono: "assignment_late",
                    privs: ["mantenedores"],
                    migration: true,
                },
                {
                    url: "/migration/mantenedores/pending-reasons",
                    footer: "Motivos de pendiente",
                    title: "Motivos Pendiente",
                    icono: "assignment_late",
                    privs: ["mantenedores"],
                    new: true,
                },
                {
                    url: "/migration/mantenedores/exams",
                    footer: "Maestro exámenes",
                    title: "Exámenes",
                    icono: "list_alt",
                    privs: ["mantenedores"],
                    migration: true,
                },
                {
                    url: "/methods/",
                    footer: "Métodos procesamiento",
                    title: "Métodos",
                    icono: "insert_chart_outlined",
                    privs: ["mantenedores"],
                },
                {
                    url: "/migration/mantenedores/units",
                    footer: "Unidades de medida",
                    title: "Unidades",
                    icono: "straighten",
                    privs: ["mantenedores"],
                    migration: true,
                },
                {
                    url: "/privilegios/",
                    footer: "Definición permisos",
                    title: "Privilegios",
                    icono: "https",
                    privs: ["god"],
                },
                {
                    url: "/printer/",
                    footer: "Config Impresora",
                    title: "ZEBRA",
                    icono: "print",
                    privs: ["god"],
                },
            ],

            menu: [
                {
                    url: "/migration/admissions/index",
                    footer: "Ingreso de exámenes y muestras entrantes",
                    title: "Crear admisión",
                    icono: "move_to_inbox",
                    privs: ["admit"],
                },
                {
                    url: "/migration/admissions/list",
                    footer: "Búsqueda avanzada",
                    title: "Buscar admisiones",
                    icono: "search",
                    privs: ["admit"],
                },
                {
                    url: "/migration/lista-de-trabajo",
                    footer: "Mueve exámenes admitidos a laboratorio",
                    title: "Lista de trabajo",
                    icono: "publish",
                    privs: ["admit"],
                },
                {
                    url: "/migration/carga-resultados",
                    footer: "Ve y carga resultados de exámenes",
                    title: "Resultados",
                    icono: "done",
                    privs: ["admit"],
                },
                {
                    url: "/migration/export",
                    footer: "Exporta exámenes en xlsx y csv",
                    title: "Exportar exámenes",
                    icono: "file_download",
                    privs: ["export"],
                },
                {
                    url: "/lab-config",
                    footer: "Configura el laboratorio",
                    title: "Configuración",
                    icono: "settings",
                    privs: ["lab_config"],
                },
            ],
        };
    },

    computed: {
        ...mapState(["misprivx", "activeTab"]),

        ...mapState("admission", ["shouldAskToLeave"]),

        visibleMenu() {
            let lemenu = this.menu.filter((unmenu) => {
                if (unmenu.privs) {
                    return unmenu.privs.every((unprivrequired) => {
                        return this.misprivx[unprivrequired];
                    });
                } else {
                    return true;
                }
            });

            //filtar por tipo de dispositivo
            let finalmenu = lemenu.filter((unmenu) => {
                if (unmenu.mobileOnly) {
                    return !this.$f7.device.desktop;
                } else {
                    return true;
                }
            });
            return finalmenu;
        },
        visibleMantenedores() {
            let lemenu = this.menu_mantenedores.filter((unmenu) => {
                if (unmenu.privs) {
                    return unmenu.privs.some((unprivrequired) => {
                        return this.misprivx[unprivrequired];
                    });
                } else {
                    return true;
                }
            });
            return lemenu;
        },
        visibleMenuCovid() {
            let lemenu = this.menu_covid.filter((unmenu) => {
                if (unmenu.privs) {
                    if (unmenu.privs[0] === "change-exam-status-firmar") {
                        if (!this.misprivx.change_exam_status) return false;

                        return this.misprivx.change_exam_status.firmar ? true : false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            });
            return lemenu;
        },
        visibleMenuBodega() {
            let lemenu = this.menu_bodega.filter((unmenu) => {
                if (unmenu.privs) {
                    return unmenu.privs.some((unprivrequired) => {
                        return this.misprivx[unprivrequired];
                    });
                } else {
                    return true;
                }
            });
            return lemenu;
        },
        visibleMenuInmunopax() {
            let lemenu = this.menu_inmunopax.filter((unmenu) => {
                if (unmenu.privs) {
                    return unmenu.privs.some((unprivrequired) => {
                        return this.misprivx[unprivrequired];
                    });
                } else {
                    return true;
                }
            });
            return lemenu;
        },
    },

    methods: {
        setactiveTab(activeTab) {
            const admissionPage = "/migration/admissions/index";

            if (this.activeTab === admissionPage && this.shouldAskToLeave) {
                if (!window.confirm("Tienes cambios sin guardar, ¿estás seguro que deseas abandonar?")) {
                    return;
                } else {
                    this.$store.commit("admission/set_should_ask_to_leave", false);
                }
            }

            console.log("setting ctive tav", activeTab);
            this.$store.commit("setWhatTo", { what: "activeTab", to: activeTab });
            this.$f7.views.main.router.navigate(activeTab, { reloadAll: true });
        },

        sortMenuAlphabetically(menu) {
            return menu.sort((a, b) => a.title.localeCompare(b.title));
        },
    },
};
</script>
