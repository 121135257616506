const { examStatuses } = require("@/app/constants");

module.exports = {
    namespaced: true,

    state: {
        plantillasCache: {},

        allPlantillas: {},

        selectedReportType: null,

        viewingPlantilla: null,

        viewingAdmissionExam: null,
        viewingAdmissionExamOriginal: null,

        examsPerCollectionBlock: 10,

        triggerReload: false,
    },

    getters: {
        usedPlantillaIdByReportType: (state) => {
            let plantilla = null;

            const { viewingAdmissionExam, selectedReportType } = state;

            if (viewingAdmissionExam && selectedReportType) {
                if (selectedReportType === "Informe preliminar") {
                    plantilla = viewingAdmissionExam.used_plantilla_preliminar;
                } else if (selectedReportType === "Informe final") {
                    plantilla = viewingAdmissionExam.used_plantilla_final;
                } else if (selectedReportType === "Informe complementario") {
                    plantilla = viewingAdmissionExam.used_plantilla_compl;
                }
            }

            return plantilla;
        },

        currentStatusIdByReportType: (state) => {
            let currentStatusId = null;

            const { viewingAdmissionExam, selectedReportType } = state;

            if (viewingAdmissionExam && selectedReportType) {
                if (selectedReportType === "Informe preliminar") {
                    currentStatusId = viewingAdmissionExam.status_id_preliminar;
                } else if (selectedReportType === "Informe final") {
                    currentStatusId = viewingAdmissionExam.status_id_final;
                } else if (selectedReportType === "Informe complementario") {
                    currentStatusId = viewingAdmissionExam.status_id_compl;
                }
            }

            return currentStatusId || examStatuses.en_laboratorio;
        },
    },

    mutations: {
        trigger_reload(state) {
            state.triggerReload = !state.triggerReload;
        },

        set_selected_report_type(state, reportType) {
            state.selectedReportType = reportType;
        },

        set_all_plantillas(state, plantillas) {
            state.allPlantillas = plantillas;
        },

        add_plantilla(state, newPlantilla) {
            state.allPlantillas = {
                ...state.allPlantillas,
                [newPlantilla.id]: newPlantilla,
            };
        },

        update_plantilla(state, updatedPlantilla) {
            state.allPlantillas = {
                ...state.allPlantillas,
                [updatedPlantilla.id]: updatedPlantilla,
            };
        },

        remove_plantilla(state, plantillaId) {
            this._vm.$delete(state.allPlantillas, plantillaId);
        },

        set_viewing_plantilla(state, plantilla) {
            state.viewingPlantilla = plantilla;
        },

        set_viewing_admission_exam(state, admissionExam) {
            state.viewingAdmissionExam = admissionExam;
        },
        set_viewing_admission_exam_original(state, admissionExam) {
            state.viewingAdmissionExamOriginal = admissionExam;
        },

        update_viewing_admission_exam(state, updatedAdmissionExam) {
            state.viewingAdmissionExam = {
                ...state.viewingAdmissionExam,
                ...updatedAdmissionExam,
            };
        },
        update_viewing_admission_exam_original(state, updatedAdmissionExam) {
            state.viewingAdmissionExamOriginal = {
                ...state.viewingAdmissionExamOriginal,
                ...updatedAdmissionExam,
            };
        },

        add_plantillas_to_cache(state, plantillas) {
            state.plantillasCache = {
                ...state.plantillasCache,
                ...plantillas,
            };
        },
    },

    actions: {
        async getPlantillasByExamId({ commit, state }, payload) {
            const { firebase, examId, refetch } = payload;

            let plantillasReturn = {};

            const hasCachePlantillaByExam = Object.values(state.plantillasCache || {}).filter((plantilla) => plantilla.exams[examId]).length > 0;

            if (refetch || Object.keys(state.plantillasCache).length === 0 || !hasCachePlantillaByExam) {
                console.log("no hay cache o refetch, fetcheando");
                const plantillasSnapshot = await firebase
                    .database()
                    .ref("/results_plantillas")
                    .orderByChild(`exams/${examId}`)
                    .equalTo(true)
                    .once("value");

                plantillasReturn = plantillasSnapshot.val() || {};

                commit("add_plantillas_to_cache", plantillasReturn);
            } else {
                plantillasReturn = Object.values(state.plantillasCache).filter((plantilla) => plantilla.exams[examId]);
            }

            return plantillasReturn;
        },
    },
};
