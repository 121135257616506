<template>
    <f7-popup :opened="opened" @popup:closed="handlePopupClosed">
        <f7-page>
            <f7-navbar title="Muestras de este examen">
                <f7-nav-right>
                    <f7-link popup-close>Cerrar</f7-link>
                </f7-nav-right>
            </f7-navbar>
            <div class="no-margin-top">
                <f7-list media-list class="no-margin no-padding">
                    <f7-list-item media-item v-for="sample in samples" :key="sample.id">
                        <div slot="header">Código: {{ sample.id }}</div>

                        <div slot="title">
                            <b>{{ sample.name }}</b>
                        </div>

                        <div slot="after" class="display-flex flex-direction-row justify-content-center">
                            <div class="margin-right-half">
                                Cantidad
                            </div>
                            <f7-input
                                outline
                                class="small-input margin-right"
                                type="number"
                                min="1"
                                step="1"
                                :value="sample.quantity"
                                @input="sample.quantity = parseInt($event.target.value)"
                                :disabled="!misprivx.modify_ot"
                            ></f7-input>

                            <f7-button
                                v-if="misprivx.modify_ot"
                                small
                                color="red"
                                @click="$emit('sample-deleted', sample.id)"
                                icon-material="delete"
                                tooltip="Eliminar muestra"
                            ></f7-button>
                        </div>
                    </f7-list-item>
                </f7-list>

                <f7-block class="margin-top-half">
                    <p v-if="!Object.keys(samples).length">
                        Este examen no tiene muestras de momento.
                    </p>
                </f7-block>

                <f7-card outline class="padding-vertical-half" v-if="!Object.keys(samples).length && samplesToAdd.length">
                    <b class="text-color-gray margin-left margin-top">Añadiendo muestras a este examen</b>
                    <f7-card-content>
                        <f7-row class="align-items-center">
                            <f7-col width="50">
                                <label>Muestra</label>
                                <f7-input type="select" @change="handleSampleSelected">
                                    <option value="">Seleccione...</option>
                                    <option
                                        v-for="sample in samplesToAdd"
                                        :key="sample.id"
                                        :value="sample.id"
                                    >
                                        {{ sample.name }}
                                    </option>
                                </f7-input>
                            </f7-col>
                            <f7-col width="20">
                                <label>Cantidad</label>
                                <f7-input
                                    outline
                                    class="small-input"
                                    type="number"
                                    min="0"
                                    step="1"
                                    :value="newSample.quantity"
                                    @input="newSample.quantity = parseInt($event.target.value)"
                                ></f7-input>
                            </f7-col>
                            <f7-col width="30">
                                <div v-if="!loadingAddingSample" class="display-flex">
                                    <f7-button
                                        icon-material="add"
                                        fill
                                        small
                                        @click="addSample"
                                        :disabled="!isSampleAddable"
                                    >
                                        Añadir muestra
                                    </f7-button>
                                </div>
                                <f7-block
                                    v-else
                                    class="row demo-preloaders align-items-stretch text-align-center no-margin no-padding"
                                >
                                    <f7-col>
                                        <f7-preloader></f7-preloader>
                                    </f7-col>
                                </f7-block>
                            </f7-col>
                        </f7-row>
                    </f7-card-content>
                </f7-card>
            </div>
        </f7-page>
    </f7-popup>
</template>

<script>
import { mapState } from "vuex";

export default {
    props: {
        opened: Boolean,
        samples: Object,
        exam: Object,
        loadingAddingSample: Boolean,
    },

    data() {
        return {
            newSample: {
                id: "",
                snomed_code: "",
                name: "",
                quantity: 1,
            },
        };
    },

    computed: {
        ...mapState(["misprivx"]),

        samplesToAdd() {
            if (!Object.keys(this.exam).length) {
                return [];
            }

            const samples = [];

            const currentSamples = Object.values(this.samples);

            this.exam.samples.forEach((sample) => {
                const found = currentSamples.findIndex((s) => s.externalId == sample.id) !== -1;

                if (!found) {
                    samples.push(sample);
                }
            });

            return samples;
        },

        isSampleAddable() {
            return (
                this.newSample.snomed_code.toString().length > 0 &&
                this.newSample.name.length > 0 &&
                parseInt(this.newSample.quantity) > 0
            );
        },

        // You can only add samples if:
        // - You have 'modify_ot' priv
        // - There are no samples added to this exam already (only 1 can be added)
        // - There are samples remaining in this exam to be added
        canAddSamples() {
            return this.misprivx.modify_ot && Object.keys(this.samples).length === 0 && this.samplesToAdd.length > 0;
        }
    },

    methods: {
        handleSampleSelected(ev) {
            const sampleId = ev.target.value;

            if (!sampleId.length) {
                this.newSample = {
                    id: "",
                    snomed_code: "",
                    name: "",
                    quantity: 1,
                };

                return;
            }

            const foundSample = this.exam.samples.find((s) => s.id == sampleId);

            this.newSample = {
                ...this.newSample,
                ...foundSample,
            };

            console.log("newSample after selected", this.newSample);
        },

        handlePopupClosed() {
            this.$emit("popup-closed");

            this.clear();
        },

        addSample() {
            this.$emit("sample-added", this.newSample);

            this.clear();
        },

        clear() {
            this.newSample = {
                id: "",
                snomed_code: "",
                name: "",
                quantity: 1,
            };
        },
    },
};
</script>
