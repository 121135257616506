<template>
    <f7-block v-if="patientCopy !== null">
        <f7-row class="margin-bottom">
            <f7-col>
                <label>Tipo de documento</label>
                <f7-input
                    type="select"
                    @change="patientCopy.document_type = $event.target.value"
                    :value="patientCopy.document_type"
                    :disabled="readonly"
                >
                    <option value="">Seleccione...</option>
                    <option value="1">RUN</option>
                    <option value="2">Pasaporte</option>
                    <option value="3">DNI</option>
                    <option value="4">Otro</option>
                </f7-input>
            </f7-col>
            <f7-col>
                <label>Número de documento</label>
                <f7-input
                    type="text"
                    @input="patientCopy.document_number = $event.target.value"
                    :value="patientCopy.document_number"
                    :disabled="readonly"
                ></f7-input>
            </f7-col>
        </f7-row>

        <f7-row class="margin-bottom">
            <f7-col :class="highlightedFields.lastname_f ? 'highlight' : ''">
                <label>Apellido paterno</label>
                <f7-input
                    class="input-uppercase"
                    type="text"
                    @input="patientCopy.lastname_f = $event.target.value"
                    :value="patientCopy.lastname_f"
                ></f7-input>
            </f7-col>
            <f7-col :class="highlightedFields.lastname_m ? 'highlight' : ''">
                <label>Apellido materno</label>
                <f7-input
                    class="input-uppercase"
                    type="text"
                    @input="patientCopy.lastname_m = $event.target.value"
                    :value="patientCopy.lastname_m"
                    :disabled="readonly"
                ></f7-input>
            </f7-col>
            <f7-col :class="highlightedFields.name ? 'highlight' : ''">
                <label>Nombres</label>
                <f7-input
                    class="input-uppercase"
                    type="text"
                    @input="patientCopy.name = $event.target.value"
                    :value="patientCopy.name"
                    :disabled="readonly"
                ></f7-input>
            </f7-col>
        </f7-row>

        <f7-row class="margin-bottom">
            <f7-col :class="highlightedFields.birth_date ? 'highlight' : ''">
                <label>Fecha de nacimiento</label>
                <f7-input
                    class="input-uppercase"
                    type="text"
                    :maxlength="10"
                    @input="patientCopy.birth_date = $event.target.value"
                    :value="patientCopy.birth_date"
                    placeholder="dd/MM/AAAA"
                    :disabled="readonly"
                ></f7-input>
            </f7-col>
            <f7-col>
                <label>Edad</label>
                <f7-input disabled>
                    <span class="uppercase">{{ patientAge(patientCopy.birth_date) }}</span>
                </f7-input>
            </f7-col>
            <f7-col :class="highlightedFields.gender ? 'highlight' : ''">
                <label>Sexo</label>
                <f7-input
                    type="select"
                    @change="patientCopy.gender = $event.target.value"
                    :value="patientCopy.gender"
                    :disabled="readonly"
                >
                    <option value="">Seleccione...</option>
                    <option value="1">Femenino</option>
                    <option value="2">Masculino</option>
                </f7-input>
            </f7-col>
            <f7-col :class="highlightedFields.country_id ? 'highlight' : ''">
                <label>Nacionalidad</label>
                <f7-list class="list-no-margin-vertical no-hairlines">
                    <f7-list-item
                        class="smart-select-list-item-sm"
                        :title="selectedCountry || 'Seleccione un país...'"
                        smart-select
                        :smart-select-params="{
                            openIn: 'popup',
                            searchbar: true,
                            searchbarPlaceholder: 'Buscar nacionalidad',
                            closeOnSelect: true,
                            setValueText: false,
                            popupCloseLinkText: 'Cerrar',
                            pageTitle: 'Seleccione una nacionalidad',
                        }"
                        :disabled="readonly"
                    >
                        <select
                            @change="patientCopy.country_id = $event.target.value"
                            :value="patientCopy.country_id"
                        >
                            <option value="">Seleccione...</option>
                            <option v-for="country in countries" :key="country.id" :value="country.id">
                                {{ country.name_es }}
                            </option>
                        </select>
                    </f7-list-item>
                </f7-list>
            </f7-col>
        </f7-row>

        <f7-row>
            <f7-col width="60" :class="highlightedFields.address ? 'highlight' : ''">
                <label>Dirección</label>
                <f7-input
                    type="text"
                    @input="patientCopy.address = $event.target.value"
                    :value="patientCopy.address"
                    :disabled="readonly"
                ></f7-input>
            </f7-col>
            <f7-col width="40" :class="highlightedFields.comuna_id ? 'highlight' : ''">
                <label>Comuna</label>
                <f7-list no-hairlines class="no-margin-vertical">
                    <f7-list-item
                        class="smart-select-list-item-sm"
                        :title="patientCopy.comuna_id && comunas.length ? getComuna(patientCopy.comuna_id).name : 'Seleccione...'"
                        smart-select
                        :smart-select-params="{
                            openIn: 'popup',
                            searchbar: true,
                            searchbarPlaceholder: 'Buscar comuna',
                            closeOnSelect: true,
                            setValueText: false,
                            popupCloseLinkText: 'Cerrar',
                            pageTitle: 'Seleccione una comuna',
                        }"
                        :disabled="comunas.length === 0 || readonly"
                    >
                        <select @change="patientCopy.comuna_id = Number($event.target.value)" :value="patientCopy.comuna_id">
                            <option value="">Seleccione...</option>
                            <option v-for="comuna in comunas" :key="comuna.id" :value="comuna.id">
                                {{ comuna.name }}
                            </option>
                        </select>
                    </f7-list-item>
                </f7-list>
            </f7-col>
        </f7-row>

        <f7-row class="margin-bottom">
            <f7-col :class="highlightedFields.phone ? 'highlight' : ''">
                <label>Telefono</label>
                <f7-input
                    type="text"
                    @input="patientCopy.phone = $event.target.value"
                    :value="patientCopy.phone"
                    :disabled="readonly"
                ></f7-input>
            </f7-col>
            <f7-col :class="highlightedFields.email ? 'highlight' : ''">
                <label>Email</label>
                <f7-input
                    type="text"
                    @input="patientCopy.email = $event.target.value"
                    :value="patientCopy.email"
                    :disabled="readonly"
                ></f7-input>
            </f7-col>
        </f7-row>

        <f7-row class="margin-bottom">
            <f7-col :class="highlightedFields.prevision ? 'highlight' : ''">
                <label>Prevision</label>
                <f7-list no-hairlines class="no-margin-vertical">
                    <f7-list-item
                        class="smart-select-list-item-sm"
                        :title="patientCopy.prevision && previsiones.length ? getPrevision(patientCopy.prevision).name : 'Seleccione...'"
                        smart-select
                        :smart-select-params="{
                            openIn: 'popup',
                            searchbar: true,
                            searchbarPlaceholder: 'Buscar prevision',
                            closeOnSelect: true,
                            setValueText: false,
                            popupCloseLinkText: 'Cerrar',
                            pageTitle: 'Seleccione una prevision',
                        }"
                        :disabled="previsiones.length === 0 || readonly"
                    >
                        <select @change="patientCopy.prevision = Number($event.target.value)" :value="patientCopy.prevision">
                            <option value="">Seleccione...</option>
                            <option v-for="prevision in previsiones" :key="prevision.id" :value="prevision.id">
                                {{ prevision.name }}
                            </option>
                        </select>
                    </f7-list-item>
                </f7-list>
            </f7-col>
        </f7-row>

        <f7-button v-if="!readonly" fill @click="updatePatientInfo">Actualizar paciente</f7-button>
    </f7-block>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import { _patientAge } from "@/helpers/helpers";

export default {
    props: {
        patient: {
            required: true,
        },
        admissionId: String,
        readonly: {
            type: Boolean,
            default: false,
            required: false,
        },
        highlightedFields: {
            type: Object,
            required: false,
            default() {
                return {};
            },
        }
    },

    data() {
        return {
            patientCopy: null,

            patientAge: _patientAge,
        };
    },

    computed: {
        ...mapState(["countries", "comunas", "previsiones"]),

        ...mapGetters(["getComuna", "getPrevision"]),

        selectedCountry() {
            if (!this.patientCopy || !this.patientCopy.country_id) return null;

            const foundCountry = this.countries.find((c) => c.id == this.patientCopy.country_id);

            return foundCountry ? foundCountry.name_es : null;
        },

        selectedComuna() {
            if (!this.patientCopy || !this.patientCopy.comuna_id) return null;

            const foundComuna = this.comunas.find((c) => c.id == this.patientCopy.comuna_id);

            return foundComuna ? foundComuna.name : null;
        },

        selectedPrevision() {
            if (!this.patientCopy || !this.patientCopy.prevision) return null;

            const foundPrevision = this.previsiones.find((p) => p.id == this.patientCopy.prevision);

            return foundPrevision ? foundPrevision.name : null;
        },
    },

    watch: {
        patient() {
            this.patientCopy = Object.assign({}, this.patient);
        },
    },

    methods: {
        updatePatientInfo() {
            this.$emit("patient-info-changed", {
                patient: this.patientCopy,
                admissionId: this.admissionId,
            });
        },
    },
};
</script>
<style scoped>
.highlight {
    border-bottom: 1px solid rgb(255, 0, 0)!important;
}

.highlight label {
    color: red;
}
</style>
