var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('f7-row',{staticClass:"align-items-center margin-bottom padding-bottom"},[_c('f7-col',[_c('label',[_vm._v("Código de examen")]),_vm._v(" "),_c('f7-input',{attrs:{"type":"text","value":_vm.newExam.code,"info":"Presione ENTER para buscar","placeholder":"Ej: ALOT"},on:{"input":function($event){_vm.newExam.code = $event.target.value}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getExamByCode($event)}}})],1),_vm._v(" "),_c('f7-col',[_c('label',[_vm._v("Nombre del examen")]),_vm._v(" "),_c('f7-list',[_c('f7-list-item',{staticClass:"smart-select-list-item-sm",attrs:{"title":_vm.newExam.name || 'Seleccione...',"smart-select":"","smart-select-params":{
                        openIn: 'popup',
                        searchbar: true,
                        searchbarPlaceholder: 'Buscar examen',
                        closeOnSelect: true,
                        setValueText: false,
                        popupCloseLinkText: 'Cerrar',
                        pageTitle: _vm.examsSelectorTitle,
                    },"disabled":!_vm.allExams}},[_c('select',{on:{"change":_vm.handleExamSelected}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._v(" "),_vm._l((_vm.filteredExams),function(exam){return _c('option',{key:exam.id,attrs:{"disabled":!exam.enabled},domProps:{"value":exam.id,"selected":exam.id === _vm.newExam.id}},[_vm._v("\n                            "+_vm._s(exam.name)+"\n                        ")])})],2)])],1)],1),_vm._v(" "),_c('f7-col',[_c('label',[_vm._v("Muestra")]),_vm._v(" "),_c('f7-input',{attrs:{"type":"select"},on:{"change":_vm.handleSampleSelected}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._v(" "),_vm._l((_vm.samplesToSelect),function(sample){return _c('option',{key:sample.id,domProps:{"value":sample.id}},[_vm._v("\n                    "+_vm._s(sample.name)+"\n                ")])})],2)],1),_vm._v(" "),_c('f7-col',[_c('label',[_vm._v("Cantidad de muestras")]),_vm._v(" "),_c('f7-input',{attrs:{"type":"number","min":"0","step":"1","value":_vm.newExam.sample.quantity,"disabled":!_vm.newExam.sample},on:{"input":function($event){_vm.newExam.sample.quantity = Number($event.target.value)}}})],1),_vm._v(" "),_c('f7-col',[_c('f7-button',{attrs:{"icon-material":"add","fill":"","small":""},on:{"click":_vm.addExam}},[_vm._v("\n                Añadir examen\n            ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }