<template>
    <f7-page >
        <f7-navbar :title="'Editar factura : ' + form.code">
            <f7-nav-right>
                <f7-link popup-close>Cerrar</f7-link>
            </f7-nav-right>
        </f7-navbar>
        <form @submit.prevent="submit" >
            <f7-block>
                <f7-row class="mb-2">
                    <f7-col width="45">
                        <f7-list no-hairlines-md>
                            <f7-list-input @blur="padd($event.target.value)" required label="Codigo de Factura" type="text" :maxlength="15" @input="form.code = $event.target.value" :value="form.code" placeholder="00000000"></f7-list-input>
                        </f7-list>
                    </f7-col>
                    <f7-col width="10">
                     
                    </f7-col>
                    <f7-col width="45">
                        <f7-list no-hairlines-md> 
                            <f7-list-input required label="Fecha de Factura" type="text" :maxlength="10" @input="form.date = $event.target.value" :value="form.date | formatDate" placeholder="dd/MM/AAAA"></f7-list-input>
                        </f7-list>
                    </f7-col>
                </f7-row>
                <f7-row class="mb-2">
                    <f7-col>
                        <f7-list no-hairlines-md>
                            <f7-list-input required type="select" label="Proveedor" :value="form.provider_id" @change="form.provider_id = $event.target.value">
                                <option value="NA" disabled>Seleccione un proveedor</option>
                                <option v-for="(item) in this.$store.state.provider" :key="item.id" :value="item.id">{{ item.description }}</option>
                            </f7-list-input>                        
                        </f7-list>
                    </f7-col>
                </f7-row>
                <f7-row class="mb2">
                    <f7-col width="45">
                        <f7-list no-hairlines-md> 
                            <f7-list-input label="RUT" type="text" :maxlength="10" :value="getRut" readonly></f7-list-input>
                        </f7-list>
                    </f7-col>
                    <f7-col width="45">
                        <f7-list no-hairlines-md>
                            <f7-list-input required type="select" label="Condicion de la factura" :value="form.state_id" @change="form.state_id = $event.target.value">
                                <option v-for="(item) in this.$store.state.state" :key="item.id" :value="item.id">{{ item.description }}</option>
                            </f7-list-input>                        
                        </f7-list>
                    </f7-col>
                </f7-row>
                <f7-row class="mb-2">
                     <f7-col>
                        <f7-list no-hairlines-md> 
                            <f7-list-input rows="5" label="Detalles y observaciones" type="textarea" :maxlength="250" @change="form.detail = $event.target.value" :value="form.detail"></f7-list-input>
                        </f7-list>
                    </f7-col>
                </f7-row>
                <f7-row class="mb-2">
                     <f7-col>
                        <f7-list no-hairlines-md> 
                            <f7-list-input name="fileInput" accept="application/pdf" label="Cambiar archivo asociado" type="file" @change="file = $event.target.files[0]" ></f7-list-input>
                        </f7-list>
                    </f7-col>
                </f7-row>
                <f7-row >
                     <f7-col style="padding: 0px 3%">
                        <label>URL del archivo asociado</label>
                    </f7-col>
                </f7-row>
                <f7-row class="mb-2">
                     <f7-col style="padding: 0px 3%">
                        <f7-link external target="_blank" :href="form.file" :text="form.file"></f7-link>
                    </f7-col>
                </f7-row>
                <f7-row>
                     <f7-col>
                        <f7-button fill btn type="submit">Guardar</f7-button>
                    </f7-col>
                </f7-row>
            </f7-block>
        </form>
    </f7-page>
</template>

<script>

import moment from 'moment';


export default {

    props: {
        invoice : { required : true }
    },
    watch : {
        invoice : {
            handler(value){
                this.init(value);
            }
        }
    },
    data(){
        return {  
            
            form : {
                id : 0,
                code : "",
                date : "",
                provider_id : "NA",
                state_id : 1,
                detail : "",
                file : ""
            },
            file : ""

        }
    },
    computed : {
        getRut(){

            var rut = "";
            if(this.form.provider_id != "NA"){
                this.$store.state.provider.forEach(item => {
                    if(item.id == this.form.provider_id){
                        rut = item.dnu;
                    }                    
                });
            }
            return rut;
        }
    },
    methods : {
        
        init(value){
            
            this.$firebase.auth().currentUser.getIdToken().then(async (token) => {
                var result = await this.$store.dispatch("apiRequest",{url : "universal/search" ,token , method : "POST", data : {relation : ["provider","state"] , model: "Invoice" ,field : "id" , "search" : value}});
                
                if(result.code == 200){
                    this.form.id = result.info.data[0].id;
                    this.form.code = result.info.data[0].code;
                    this.form.date =  moment(result.info.data[0].date, 'YYYY-MM-DD H:m:s').format("DD/MM/YYYY")
                    this.form.provider_id = result.info.data[0].provider_id;
                    this.form.state_id = result.info.data[0].state_id;
                    this.form.detail = result.info.data[0].detail;
                    this.form.file = result.info.data[0].file;
                    var x = document.getElementsByName('fileInput');
                    for(const item of x){
                        console.log(item)
                        item.value = ""
                    }
                }
            });
        },
        submit(){
            this.$firebase.auth().currentUser.getIdToken().then(async (token) => {

                if(this.file !== ""){ 
                    this.form.file = await this.$store.dispatch('uploadFile', 
                        {
                            app : this, 
                            path : 'invoices/', 
                            file : this.file, 
                            alert : true
                        }
                    )
                }
               
                let bulk = this.form;
                bulk.date = moment(bulk.date, "DD/MM/YYYY").format("YYYY-MM-DD")

                var result = await this.$store.dispatch("apiRequest",{app : this ,url : "universal/" ,token , method : "PUT", data : {info : bulk , model: "Invoice", search : this.invoice}});

                if(result.code == 200){
                    this.$emit("updateTable")
                    this.$f7.popup.close('.detailedInvoiceModal');
                }

                bulk.date = moment(bulk.date, "YYYY-MM-DD").format("DD/MM/YYYY")

            });
        },
        padd(val){
            this.form.code = val.replace(/[a-z]/gi, '') .padStart(10,'0')
        },
    }
}
</script>

<style scoped>
.mb-2{
    margin-bottom: 4% !important;
    

}
.mb-1{
    margin-bottom: 3% !important;
    
}
.align-left{
    text-align: left;
}
.padding-1{
    padding: 0% 1%;
}
.toast{
    left: 45% !important;
}
.toast-text{
    font-size: 1.3em;
}
.no-hairlines-md{
    margin: 0;
}
</style>