var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-page',{attrs:{"name":"newInstitution"}},[(!_vm.isEditor)?_c('f7-navbar',{attrs:{"title":"Agregar nueva institución","back-link":""}}):_c('f7-navbar',{attrs:{"title":"Editar institución","back-link":""}}),_vm._v(" "),_c('f7-block',[_c('f7-block-title',{staticClass:"no-margin-top no-margin-left"},[_vm._v("DATOS INSTITUCIÓN")]),_vm._v(" "),_c('f7-list',{attrs:{"no-hairlines-md":""}},[_c('f7-list-input',{attrs:{"label":"Nombre de la institución","type":"text","value":_vm.institution.name},on:{"input":function($event){_vm.institution.name = $event.target.value}}}),_vm._v(" "),_c('f7-list-input',{attrs:{"label":"Dirección","type":"text","value":_vm.institution.address},on:{"input":function($event){_vm.institution.address = $event.target.value}}}),_vm._v(" "),_c('f7-list-item',{attrs:{"title":_vm.institution.country_id ? _vm.getCountry(_vm.institution.country_id).name_es : 'Seleccione...',"smart-select":"","smart-select-params":{
                    openIn: 'popup',
                    searchbar: true,
                    searchbarPlaceholder: 'Buscar país',
                    closeOnSelect: true,
                    setValueText: false,
                    popupCloseLinkText: 'Cerrar',
                    pageTitle: 'Seleccione un país',
                },"disabled":_vm.countries.length === 0}},[_c('select',{domProps:{"value":_vm.institution.country_id},on:{"change":function($event){_vm.institution.country_id = Number($event.target.value)}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._v(" "),_vm._l((_vm.countries),function(country){return _c('option',{key:country.id,domProps:{"value":country.id}},[_vm._v("\n                        "+_vm._s(country.name_es)+"\n                    ")])})],2)])],1),_vm._v(" "),_c('f7-block-title',{staticClass:"no-margin-top no-margin-left"},[_vm._v("DATOS DEL CONTACTO")]),_vm._v(" "),_c('f7-list',{attrs:{"no-hairlines-md":""}},[_c('f7-list-input',{attrs:{"label":"Nombre del contacto","type":"text","value":_vm.institution.contact_name},on:{"input":function($event){_vm.institution.contact_name = $event.target.value}}}),_vm._v(" "),_c('f7-list-input',{attrs:{"label":"Email del contacto","type":"text","value":_vm.institution.email},on:{"input":function($event){_vm.institution.email = $event.target.value}}})],1)],1),_vm._v(" "),_c('f7-block',[_c('f7-button',{attrs:{"icon-material":!_vm.isEditor ? 'playlist_add' : 'edit',"large":"","outline":"","text":!_vm.isEditor ? 'Agregar Institución' : 'Guardar cambios'},on:{"click":function($event){!_vm.isEditor ? _vm.saveInstitution() : _vm.editInstitution()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }