<template>
    <f7-app :params="f7params">
        <!-- Left panel with cover effect when hidden -->
        <f7-panel left cover theme-dark :visible-breakpoint="960">
            <f7-view>
                <f7-page>
                    <f7-block class="no-margin-bottom margin-top-half">
                        <img class="fwidth" src="../static/img/logoinmuno.png" />
                    </f7-block>
                    <f7-list class="linksmenu margin-top-half">
                        <!--Indicador de modo desarrollo-->
                        <f7-list-item v-if="env === 'development'" class="devMode" title="MODO DESARROLLO"></f7-list-item>
                        <!-------------------------------->

                        <f7-list-item divider title="Perfil"></f7-list-item>
                        <f7-list-item
                            view=".view-main"
                            panel-close
                            :class="{ active: activeTab == '/profile/' }"
                            @click="setactiveTab('/profile/')"
                            link="#"
                            class="userprofile"
                            :title="profile_userInfo.displayName"
                            :footer="profile_userInfo.email"
                        >
                            <img :src="profile_userInfo.photoURL" alt="avatar user" slot="media" />
                        </f7-list-item>
                    </f7-list>

                    <f7-segmented tag="p" class="margin-horizontal" v-if="$enableLegacyMode">
                        <f7-button small outline @click="viewingMenu = 'app'" :active="viewingMenu === 'app'">Menu</f7-button>
                        <f7-button small outline @click="viewingMenu = 'legacy'" :active="viewingMenu === 'legacy'">Menu (legacy)</f7-button>
                    </f7-segmented>

                    <legacy-sidebar v-if="viewingMenu === 'legacy'"></legacy-sidebar>
                    <app-sidebar v-else-if="viewingMenu === 'app'"></app-sidebar>

                    <f7-block>
                        <p class="sogris">
                            {{ appName }} V
                            <span class="myver">{{ appVersion }}</span>
                        </p>
                    </f7-block>
                    <f7-block>
                        <f7-button @click="cerrarSesion" outline>Cerrar sesión</f7-button>
                    </f7-block>
                </f7-page>
            </f7-view>
        </f7-panel>

        <!-- Your main view, should have "view-main" class -->
        <f7-view main :masterDetailBreakpoint="960" class="safe-areas" url="/hold/"></f7-view>

        <f7-login-screen id="my-login-screen" :opened="loginOpened">
            <f7-view>
                <f7-page login-screen>
                    <f7-block class="text-align-center no-margin-top no-margin-bottom">
                        <img class="loginlogo" src="../static/img/logoinmuno.png" />
                    </f7-block>
                    <f7-login-screen-title class="no-margin-top grayoporte">
                        Portal
                        <span class="lesmall">v {{ appVersion }}</span>
                    </f7-login-screen-title>
                    <f7-block class="text-align-center menvein">
                        <f7-button v-if="!loadingData" outline large raised @click="attemptLogin">
                            Ingresar
                        </f7-button>
                        <div class="text-color-primary" v-else>
                            <div class="preloader color-multi">
                                <span class="preloader-inner">
                                    <span class="preloader-inner-gap"></span>
                                    <span class="preloader-inner-left">
                                        <span class="preloader-inner-half-circle"></span>
                                    </span>
                                    <span class="preloader-inner-right">
                                        <span class="preloader-inner-half-circle"></span>
                                    </span>
                                </span>
                            </div>
                            <br />
                            <br />
                            {{ loadingData === 1 ? "Inicializando " + appName : "Redireccionando..." }}
                        </div>
                    </f7-block>
                    <f7-list class="menvein">
                        <f7-block-footer class="foneleven">
                            Inmunocel Portal &copy; {{ computaAhora() }}
                            <br />
                            Powered by HICAPPS
                        </f7-block-footer>
                    </f7-list>
                </f7-page>
            </f7-view>
        </f7-login-screen>
    </f7-app>
</template>
<script>
import routes from "../js/routes.js";
import { mapState, mapActions } from "vuex";
import LegacySidebar from "@/app/sidebars/legacy-sidebar.vue";
import AppSidebar from "@/app/sidebars/app-sidebar.vue";

export default {
    components: {
        "legacy-sidebar": LegacySidebar,
        "app-sidebar": AppSidebar,
    },

    data() {
        return {
            viewingMenu: "app",

            observaMyPrivx: null,
            loadingData: 1,
            menu_mantenedores: [
                {
                    url: "/members/",
                    footer: "Equipo Inmunocel",
                    title: "Miembros",
                    icono: "group_work",
                    privs: ["members"],
                },
                {
                    url: "/institutions/",
                    footer: "Orígenes solicitudes",
                    title: "Instituciones",
                    icono: "account_balance",
                    privs: ["mantenedores"],
                },
                {
                    url: "/agreements/",
                    footer: "Tipos de convenios",
                    title: "Convenios",
                    icono: "work",
                    privs: ["mantenedores"],
                },
                {
                    url: "/samples/",
                    footer: "Tipos de muestras",
                    title: "Muestras",
                    icono: "opacity",
                    privs: ["mantenedores"],
                },

                {
                    url: "/rejections/",
                    footer: "Motivos de rechazo de muestras",
                    title: "Rechazos",
                    icono: "assignment_late",
                    privs: ["mantenedores"],
                },
                {
                    url: "/exams/",
                    footer: "Maestro exámenes",
                    title: "Exámenes",
                    icono: "list_alt",
                    privs: ["mantenedores"],
                },
                {
                    url: "/supply/",
                    footer: "Maestro Desposito",
                    title: "Insumos",
                    icono: "list_alt",
                    privs: ["mantenedores"],
                },
                {
                    url: "/methods/",
                    footer: "Métodos procesamiento",
                    title: "Métodos",
                    icono: "insert_chart_outlined",
                    privs: ["mantenedores"],
                },
                {
                    url: "/units/",
                    footer: "Unidades de medida",
                    title: "Unidades",
                    icono: "straighten",
                    privs: ["mantenedores"],
                },
                {
                    url: "/pacientes/",
                    footer: "Maestro de pacientes",
                    title: "Pacientes",
                    icono: "people",
                    privs: ["pacientes"],
                },
                {
                    url: "/privilegios/",
                    footer: "Definición permisos",
                    title: "Privilegios",
                    icono: "https",
                    privs: ["god"],
                },
                {
                    url: "/paxs/",
                    footer: "Usuarios de PAX",
                    title: "Accesos PAX",
                    icono: "vpn_key",
                    privs: ["pax"],
                },
                {
                    url: "/printer/",
                    footer: "Config Impresora",
                    title: "ZEBRA",
                    icono: "print",
                    privs: ["god"],
                },
            ],

            menu: [
                // {
                //   url: '/',
                //   footer: 'Vista Global Procesos',
                //   title:'Dashboard',
                //   icono:'chrome_reader_mode'
                // },
                {
                    url: "/admit/",
                    footer: "Ingreso muestras entrantes",
                    title: "Admitir muestra",
                    icono: "move_to_inbox",
                    privs: ["admit"],
                },
                {
                    url: "/scanner/",
                    footer: "Escaneo de Órdenes",
                    title: "Scanner",
                    icono: "important_devices",
                    privs: ["admit"],
                    mobileOnly: true,
                },
                {
                    url: "/sample-move/",
                    footer: "Mediante escaneo",
                    title: "Traslado muestras",
                    icono: "directions",
                    privs: ["admit"],
                },
                {
                    url: "/resultados/",
                    footer: "Búsqueda por paciente",
                    title: "Admisiones",
                    icono: "folder_shared",
                    privs: ["resultados"],
                },
                {
                    url: "/export/",
                    footer: "Exportar exámenes ",
                    title: "Exportar",
                    icono: "unarchive",
                    privs: ["export"],
                },
                {
                    url: "/queue/",
                    footer: "Workflow visual",
                    title: "Colas de Trabajo",
                    icono: "playlist_add_check",
                    privs: ["colas"],
                },
                {
                    url: "/reports/",
                    footer: "Estadisticas & Graficas",
                    title: "Reportes",
                    icono: "pie_chart",
                    privs: ["reportes"],
                },
                {
                    url: "/import-admissions/",
                    footer: "A través de archivos .xlsx",
                    title: "Importar COVID",
                    icono: "publish",
                    privs: ["admit", "pacientes"],
                },

                {
                    url: "/entry/",
                    footer: "Deposito de insumos",
                    title: "Deposito",
                    icono: "publish",
                    privs: ["admit", "pacientes"],
                },
            ],

            // Framework7 Parameters
            f7params: {
                name: "Inmunocel Portal", // App name
                theme: "md", // Automatic theme detection
                // App routes
                routes: routes,
                dialog: { buttonOk: "Aceptar", buttonCancel: "Cancelar" },
                toast: {
                    position: "bottom",
                    horizontalPosition: "center",
                },
            },
        };
    },
    computed: {
        ...mapState([
            "appVersion",
            "loginOpened",
            "appName",
            "activeTab",
            "profile_userInfo",
            "misprivx",
            "defsPrivx",
            "env",
        ]),
    },
    methods: {
        ...mapActions(["getAPIInfo"]),
        computaAhora() {
            let ahoraYear = "2019";
            let ahora = this.$moment().format("YYYY");
            if (ahora != ahoraYear) {
                ahoraYear = ahoraYear + "-" + ahora;
            }
            return ahoraYear;
        },
        attemptLogin() {
            this.loadingData = 2;
            let provider = new this.$firebase.auth.GoogleAuthProvider();
            provider.setCustomParameters({
                login_hint: "user@inmunocel.com",
            });
            this.$firebase.auth().signInWithRedirect(provider);
        },
        setactiveTab(activeTab) {
            console.log("setting ctive tav", activeTab);
            this.$store.commit("setWhatTo", { what: "activeTab", to: activeTab });
            this.$f7.views.main.router.navigate(activeTab, { reloadAll: true });
        },

        cerrarSesion() {
            this.observaMyPrivx.off();
            this.$firebase.auth().signOut();
        },
        proceedToLoginFlow(user) {
            //TODO FIX FLUJO LOGIN

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    let minimunToLoad = [
                        this.$firebase
                            .database()
                            .ref("privx/" + user.uid)
                            .once("value"),
                        this.getAPIInfo({ url: "exam_sections/", userToken: idToken }),
                        this.$firebase
                            .database()
                            .ref("defs/privx")
                            .once("value"),
                        this.$firebase
                            .database()
                            .ref("defs/privx_by_section")
                            .orderByChild("o")
                            .once("value"),
                        this.$firebase
                            .database()
                            .ref("defs/privx_exam_status")
                            .once("value"),
                    ];
                    return Promise.all(minimunToLoad);
                })
                .then((miutisnaps) => {
                    //  this.$store.commit('setObjTo', {what: 'sections', to: miutisnaps[1].val()});
                    let responstapi = miutisnaps[1].data || {};
                    let secciones = responstapi.data || [];
                    // console.log('API ANSER',responstapi);
                    this.$store.commit("setWhatTo", { what: "sections", to: secciones });
                    let privbysex = [];
                    miutisnaps[3].forEach((unPrivbysec) => {
                        privbysex.push(unPrivbysec.val());
                    });

                    const privsExamStatus = [];
                    miutisnaps[4].forEach(priv => {
                        privsExamStatus.push(priv.val());
                    })

                    let defsPrivx = {
                        globales: miutisnaps[2].val(),
                        bysection: privbysex,
                        examStatus: privsExamStatus,
                    };
                    this.$store.commit("setWhatTo", { what: "defsPrivx", to: defsPrivx });
                    let snpsht = miutisnaps[0];
                    let misprivx = snpsht.val() || null;
                    if (misprivx) {
                        this.$store.commit("setObjTo", { what: "misprivx", to: misprivx });
                        this.welcomeBack(user);
                    } else {
                        let domain = user.email.split("@");
                        let authorizedDomains = ["inmunocel.com"];
                        if (authorizedDomains.includes(domain[1])) {
                            //es inmunocel, welcome
                            this.welcomeBack(user);
                        } else {
                            this.attemptCheckWhitelist(user, domain);
                        }
                    }
                })
                .catch((error) => {
                    console.log("error llamando datos criticos", error);
                    this.loadingData = false;
                    this.$f7.dialog.alert(error.message, error.code);
                });
        },
        welcomeBack(user) {
            this.loadingData = false;
            this.$store.commit("setWhatTo", { what: "activeTab", to: "/migration/admissions/index" });
            this.$store.commit("setWhatTo", { what: "loginOpened", to: false });
            this.$f7.views.main.router.navigate("/migration/admissions/index", { reloadAll: true });

            //aqui encender observable a privilegios
            this.observaMyPrivx = this.$firebase.database().ref("privx/" + user.uid);
            this.observaMyPrivx.on("value", (snapshot) => {
                let misprivx = snapshot.val() || {};
                this.$store.commit("setObjTo", { what: "misprivx", to: misprivx });
            });
        },
        attemptCheckWhitelist(user, domain) {
            return this.$firebase
                .database()
                .ref("whitelist")
                .once("value")
                .then((snpsht) => {
                    let whitelisted = snpsht.val() || {};
                    let sinpuntos = user.email.replace(/\./g, " ");
                    if (whitelisted[sinpuntos]) {
                        this.welcomeBack(user);
                    } else {
                        this.loadingData = false;
                        //oh oh, no es @inmunocel ni whitelisteado, botar
                        this.$firebase
                            .auth()
                            .signOut()
                            .then(() => {
                                this.$f7.dialog.alert("Dominio " + domain[1] + " no autorizado");
                            });
                    }
                })
                .catch((error) => {
                    this.loadingData = false;
                    this.$f7.dialog.alert(error.message, error.code);
                });
        },
    },
    mounted() {
        this.$f7ready((f7) => {
            // Call F7 APIs here
            //iniciar chequeo status login
            this.$firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    // User is signed in.
                    console.log("welcome", user);
                    let profile_userInfo = {
                        displayName: user.displayName,
                        email: user.email,
                        photoURL: user.photoURL,
                        uid: user.uid,
                    };
                    this.$store.commit("setObjTo", {
                        what: "profile_userInfo",
                        to: profile_userInfo,
                    });
                    this.proceedToLoginFlow(user);
                } else {
                    // No user is signed in.
                    console.log("no user, please login");
                    this.loadingData = false;
                    this.$store.commit("setWhatTo", { what: "loginOpened", to: true });
                }
            });
        });
    },
};
</script>
