<template>
    <f7-popup :opened="opened" @popup:opened="init" @popup:closed="$emit('popup-closed')">
        <f7-page>
            <f7-navbar title="Seleccione exámenes">
                <f7-nav-right>
                    <f7-link popup-close>Cerrar</f7-link>
                </f7-nav-right>
            </f7-navbar>

            <f7-searchbar
                class="searchbar-exams"
                search-container=".search-list-exams"
                search-in=".item-title"
                placeholder="Buscar exámenes"
                found-el=".searchbar-found-exams"
                not-found-el=".searchbar-not-found-exams"
            ></f7-searchbar>

            <f7c-alert v-if="section" type="info" class="margin no-margin-bottom">
                Se han filtrado los exámenes a seleccionar para la sección <b>{{ sectionName }}</b>.
            </f7c-alert>

            <f7-list class="searchbar-not-found searchbar-not-found-exams">
                <f7-list-item title="No hay coincidencias"></f7-list-item>
            </f7-list>

            <f7-block class="no-margin-top">
                <f7-list media-list class="search-list-exams searchbar-found-exams no-margin-top margin-bottom">
                    <f7-list-item 
                        v-for="exam in filteredExams" 
                        :key="exam.id"
                        checkbox
                        @change="$emit('exam-selected', exam)"
                        :checked="selectedExams[exam.id]"
                    >
                        <div slot="title">
                            {{ exam.name }}
                        </div>
                    </f7-list-item>
                </f7-list>
            </f7-block>
        </f7-page>
    </f7-popup>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    props: {
        opened: Boolean,
        selectedExams: Object,
        section: Number,
    },

    computed: {
        ...mapState(["allExams", "sections"]),

        filteredExams() {
            if (!this.section) return this.allExams;

            return this.allExams.filter((e) => e.section.id === this.section);
        },

        sectionName() {
            if (!this.section) return "Sección no identificada.";

            const section = this.sections.find((s) => s.id === this.section);

            return section.name;
        }
    },

    methods: {
        ...mapActions(["getAPIInfo"]),

        init() {
            console.log("selectedExams", this.selectedExams);

            if (this.allExams && this.allExams.length) return;

            this.getExams();
        },

        getExams() {
            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: `exams?order=ASC&rows_per_page=500`, userToken: idToken });
                })
                .then((resp) => {
                    const exams = resp.data.data;
                    this.$store.commit("setWhatTo", { what: "allExams", to: exams });
                })
                .catch((err) => {
                    console.error(err);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Error. " + err.message);
                });
        },
    },
};
</script>
