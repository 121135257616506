<template>
    <div>
        <div class="display-flex justify-content-space-between align-items-center">
            <slot></slot>

            <f7-button v-if="!isEditingAdmission && !readonly" @click="clear(true)" color="red">
                Limpiar Paciente
            </f7-button>
        </div>

        <f7-row class="margin-bottom">
            <f7-col>
                <label>Tipo de documento</label>
                <f7-input
                    type="select"
                    @change="patient.document_type = Number($event.target.value)"
                    :value="patient.document_type"
                    :disabled="patientSelected"
                >
                    <option value="">Seleccione...</option>
                    <option :value="1">RUN</option>
                    <option :value="2">Pasaporte</option>
                    <option :value="3">DNI</option>
                    <option :value="4">Otro</option>
                </f7-input>
            </f7-col>
            <f7-col>
                <label>Número de documento</label>
                <f7-input
                    type="text"
                    @change="patient.document_number = $event.target.value"
                    :value="patient.document_number"
                    @keyup.native.enter="search"
                    :disabled="patientSelected"
                ></f7-input>
            </f7-col>
        </f7-row>

        <f7-row class="margin-bottom">
            <f7-col>
                <label>Apellido paterno</label>
                <f7-input
                    class="input-uppercase"
                    type="text"
                    @change="patient.lastname_f = $event.target.value"
                    :value="patient.lastname_f"
                    @keyup.native.enter="search"
                    :disabled="patientSelected"
                ></f7-input>
            </f7-col>
            <f7-col>
                <label>Apellido materno</label>
                <f7-input
                    class="input-uppercase"
                    type="text"
                    @change="patient.lastname_m = $event.target.value"
                    :value="patient.lastname_m"
                    @keyup.native.enter="search"
                    :disabled="patientSelected"
                ></f7-input>
            </f7-col>
            <f7-col>
                <label>Nombres</label>
                <f7-input
                    class="input-uppercase"
                    type="text"
                    @change="patient.name = $event.target.value"
                    :value="patient.name"
                    @keyup.native.enter="search"
                    :disabled="patientSelected"
                ></f7-input>
            </f7-col>
        </f7-row>

        <f7-row class="margin-bottom">
            <f7-col>
                <label>Fecha de nacimiento</label>
                <f7-input
                    class="input-uppercase"
                    type="text"
                    :maxlength="10"
                    @input="patient.birth_date = $event.target.value"
                    :value="patient.birth_date | formatDate"
                    placeholder="dd/MM/AAAA"
                    @keyup.native.enter="search"
                    :disabled="patientSelected"
                ></f7-input>
            </f7-col>
            <f7-col>
                <label>Edad</label>
                <f7-input>
                    <span class="uppercase">{{ patientAge(patient.birth_date) }}</span>
                </f7-input>
            </f7-col>
            <f7-col>
                <label>Sexo</label>
                <f7-input type="select" @change="patient.gender = Number($event.target.value)" :value="patient.gender" :disabled="patientSelected">
                    <option :value="0">Seleccione...</option>
                    <option :value="1">Femenino</option>
                    <option :value="2">Masculino</option>
                </f7-input>
            </f7-col>
            <f7-col>
                <label>Nacionalidad</label>
                <f7-list no-hairlines class="no-margin-vertical">
                    <f7-list-item
                        class="smart-select-list-item-sm"
                        :title="patient.country_id && countries.length ? getCountry(patient.country_id).name_es : 'Seleccione...'"
                        smart-select
                        :smart-select-params="{
                            openIn: 'popup',
                            searchbar: true,
                            searchbarPlaceholder: 'Buscar nacionalidad',
                            closeOnSelect: true,
                            setValueText: false,
                            popupCloseLinkText: 'Cerrar',
                            pageTitle: 'Seleccione una nacionalidad',
                        }"
                        :disabled="countries.length === 0 || patientSelected"
                    >
                        <select @change="patient.country_id = Number($event.target.value)" :value="patient.country_id">
                            <option value="">Seleccione...</option>
                            <option v-for="country in countries" :key="country.id" :value="country.id">
                                {{ country.name_es }}
                            </option>
                        </select>
                    </f7-list-item>
                </f7-list>
            </f7-col>
        </f7-row>

        <f7-row class="margin-bottom">
            <f7-col>
                <label>Dirección</label>
                <f7-input
                    class="input-uppercase"
                    type="text"
                    @change="patient.address = $event.target.value"
                    :value="patient.address"
                    @keyup.native.enter="search"
                    :disabled="patientSelected"
                ></f7-input>
            </f7-col>
            <f7-col>
                <label>Comuna</label>
                <f7-list no-hairlines class="no-margin-vertical">
                    <f7-list-item
                        class="smart-select-list-item-sm"
                        :title="patient.comuna_id && comunas.length ? getComuna(patient.comuna_id).name : 'Seleccione...'"
                        smart-select
                        :smart-select-params="{
                            openIn: 'popup',
                            searchbar: true,
                            searchbarPlaceholder: 'Buscar comuna',
                            closeOnSelect: true,
                            setValueText: false,
                            popupCloseLinkText: 'Cerrar',
                            pageTitle: 'Seleccione una comuna',
                        }"
                        :disabled="patientSelected"
                    >
                        <select @change="patient.comuna_id = Number($event.target.value)" :value="patient.comuna_id">
                            <option value="">Seleccione...</option>
                            <option v-for="comuna in comunas" :key="comuna.id" :value="comuna.id">
                                {{ comuna.name }}
                            </option>
                        </select>
                    </f7-list-item>
                </f7-list>
            </f7-col>
        </f7-row>
        <f7-row class="margin-bottom">
            <f7-col>
                <label>Teléfono</label>
                <f7-input
                    class="input-uppercase"
                    type="text"
                    @change="patient.phone = $event.target.value"
                    :value="patient.phone"
                    @keyup.native.enter="search"
                    :disabled="patientSelected"
                ></f7-input>
            </f7-col>
            <f7-col>
                <label>Mail</label>
                <f7-input
                    class="input-uppercase"
                    type="email"
                    @change="patient.email = $event.target.value"
                    :value="patient.email"
                    @keyup.native.enter="search"
                    :disabled="patientSelected"
                ></f7-input>
            </f7-col>
            <f7-col>
                <label>Prevision</label>
                <f7-list no-hairlines class="no-margin-vertical">
                    <f7-list-item
                        class="smart-select-list-item-sm"
                        :title="patient.prevision && previsiones.length ? getPrevision(patient.prevision).name : 'Seleccione...'"
                        smart-select
                        :smart-select-params="{
                            openIn: 'popup',
                            searchbar: true,
                            searchbarPlaceholder: 'Buscar prevision',
                            closeOnSelect: true,
                            setValueText: false,
                            popupCloseLinkText: 'Cerrar',
                            pageTitle: 'Seleccione una prevision',
                        }"
                        :disabled="previsiones.length === 0 || patientSelected"
                    >
                        <select @change="patient.prevision = Number($event.target.value)" :value="patient.prevision">
                            <option value="">Seleccione...</option>
                            <option v-for="prevision in previsiones" :key="prevision.id" :value="prevision.id">
                                {{ prevision.name }}
                            </option>
                        </select>
                    </f7-list-item>
                </f7-list>
            </f7-col>
        </f7-row>

        <template v-if="!isManagingSolicitud">
            <div class="display-flex flex-direction-row justify-content-center" v-if="!readonly">
                <f7-button fill icon-material="search" @click="search" v-if="!patientSelected">
                    Buscar paciente
                </f7-button>
                <f7-button fill icon-material="edit" @click="editSelectedPatient" v-else-if="patientSelected && misprivx.update_patient">
                    Editar paciente
                </f7-button>
            </div>
        </template>

        <select-patient-popup
            :opened="selectPopupOpened"
            :patients="patientsFound"
            :pagination="pagination"
            :patient-search="patient"
            @patient-selected="handlePatientSelected"
            @popup-closed="selectPopupOpened = false"
        ></select-patient-popup>

        <create-patient-popup
            :opened="createPopupOpened"
            :patient="patient"
            @patient-created="handlePatientCreated"
            @popup-closed="createPopupOpened = false"
        ></create-patient-popup>

        <create-patient-popup
            edit
            :opened="editPatientPopupOpened"
            :patient="patient"
            @patient-edited="handlePatientEdited"
            @popup-closed="handleCloseEditPatient"
        ></create-patient-popup>
    </div>
</template>

<script>
import SelectPatientPopup from "@/app/admission-migration/components/select-patient-popup.vue";
import CreatePatientPopup from "@/app/admission-migration/components/create-patient-popup.vue";

import { mapState, mapActions, mapGetters } from "vuex";
import { _patientAge } from "@/app/helpers";
import { clean } from "rut.js";

export default {
    props: {
        admissionPatient: Object,
        reset: Boolean,
        isEditingAdmission: Boolean,
        readonly: Boolean,
        showPatient: Boolean,
        isManagingSolicitud: Boolean,
    },

    components: {
        SelectPatientPopup,
        CreatePatientPopup,
    },

    data() {
        return {
            patientSelected: false,

            patient: {
                id: null,
                document_type: 1,
                document_number: "",
                lastname_f: "",
                lastname_m: "",
                name: "",
                birth_date: "",
                gender: 0,
                country_id: 40,
                email: "",
                comuna_id: 0,
                phone: "",
                address: "",
                prevision: "",
                estado: "",
            },

            // Select patient popup
            selectPopupOpened: false,
            patientsFound: [],
            pagination: {},

            // Create patient popup
            createPopupOpened: false,
            editPatientPopupOpened: false,

            // Helpers
            patientAge: _patientAge,
        };
    },

    computed: {
        ...mapState(["misprivx"]),

        ...mapState(["countries", "comunas", "previsiones"]),

        ...mapGetters(["getCountry", "getComuna", "getPrevision"]),
    },

    watch: {
        reset() {
            this.clear();
        },

        "admissionPatient.id": {
            handler: function(id) {
                if (id) this.setPatient(this.admissionPatient);
            },
        },
    },

    created() {
        if (this.admissionPatient.id || this.showPatient || this.isManagingSolicitud) {
            this.setPatient(this.admissionPatient);
        }

        this.getCountries();
        this.getComunas();
        this.getPrevisiones();
    },

    methods: {
        ...mapActions(["getAPIInfo"]),

        search() {
            if (this.isPatientSearchable() === false) {
                this.$f7.dialog.alert(
                    "Los campos válidos son: <ul><li>Número de documento</li> <li>Nombres</li> <li>Apellido paterno</li> <li>Apellido materno</li> <li>Fecha de nacimiento</li> <li>Dirección</li> <li>Teléfono</li> <li>Mail</li></ul>",
                    "Debe buscar por al menos un campo válido."
                );
                return;
            }

            this.$f7.dialog.preloader("Buscando paciente...");


            if (this.patient.document_type === 1) {
                this.$set(this.patient, "document_number", clean(this.patient.document_number || ""));
            }

            const queryString = new URLSearchParams(this.patient).toString();

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: `patients/search?rows_per_page=50&${queryString}`, userToken: idToken });
                })
                .then((response) => {
                    const responseData = response.data;

                    console.log("response data", responseData);

                    const patientsFound = responseData.data;
                    const count = patientsFound.length;

                    this.pagination = responseData.pagination;

                    if (count === 0) {
                        this.$emit("patient-found", null);

                        this.createPopupOpened = true;
                    } else if (count === 1) {
                        const patient = patientsFound[0];

                        this.setPatient(patient);

                        this.$emit("patient-found", this.patient);
                    } else {
                        this.patientsFound = patientsFound;

                        this.selectPopupOpened = true;
                    }

                    this.$f7.dialog.close();
                })
                .catch((err) => {
                    console.error(err);
                    this.$f7.dialog.alert("Error " + err);
                    this.$f7.dialog.close();
                });
        },

        editSelectedPatient() {
            this.editPatientPopupOpened = true;
        },

        handlePatientEdited(patient) {
            this.setPatient(patient);

            this.handleCloseEditPatient();
            this.$emit("patient-edited", patient);
        },
        handleCloseEditPatient() {
            this.editPatientPopupOpened = false;
        },

        setPatient(patient) {
            this.patient.id = patient.id || null;
            this.patient.document_type = patient.document_type || 0;
            this.patient.document_number = patient.document_number || null;
            this.patient.name = patient.name || null;
            this.patient.lastname_m = patient.lastname_m || null;
            this.patient.lastname_f = patient.lastname_f || null;

            if (patient.birth_date) {
                if (patient.birth_date == this.$moment(patient.birth_date).format("YYYY-MM-DD")) {
                    this.patient.birth_date = this.$moment(patient.birth_date, "YYYY-MM-DD").format("DD/MM/YYYY");
                } else {
                    this.patient.birth_date = patient.birth_date;
                }
            } else {
                this.patient.birth_date = "";
            }

            this.patient.gender = patient.gender || null;
            this.patient.address = patient.address || null;
            this.patient.comuna_id = patient.comuna_id || null;
            this.patient.country_id = patient.country_id || null;
            this.patient.email = patient.email || null;
            this.patient.phone = patient.phone || null;
            this.patient.prevision = patient.prevision || null;

            this.patientSelected = true;
        },

        // Select patient popup stuff
        handlePatientSelected(patient) {
            this.setPatient(patient);
            this.$emit("patient-found", this.patient);

            this.selectPopupOpened = false;
        },

        // Create patient popup stuff
        handlePatientCreated(patient) {
            console.log("@patient-created in patient-form", patient);

            this.setPatient(patient);

            this.$emit("patient-found", this.patient);

            this.createPopupOpened = false;
        },

        // Fetchers
        getCountries() {
            if (this.countries.length > 0) {
                return;
            }

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: "countries", userToken: idToken });
                })
                .then((response) => {
                    const countries = response.data.data;

                    this.$store.commit("setWhatTo", { what: "countries", to: countries });
                })
                .catch((err) => {
                    console.error(err);
                    this.$f7.dialog.alert("Error " + err);
                });
        },
        getComunas() {
            if (this.comunas.length > 0) {
                return;
            }

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: "comunas", userToken: idToken });
                })
                .then((response) => {
                    const comunas = response.data.data;

                    this.$store.commit("setWhatTo", { what: "comunas", to: comunas });
                })
                .catch((err) => {
                    console.error(err);
                    this.$f7.dialog.alert("Error " + err);
                });
        },
        getPrevisiones() {
            if (this.previsiones.length > 0) {
                return;
            }

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: "previsiones", userToken: idToken });
                })
                .then((response) => {
                    const previsiones = response.data.data;

                    this.$store.commit("setWhatTo", { what: "previsiones", to: previsiones });
                })
                .catch((err) => {
                    console.error(err);
                    this.$f7.dialog.alert("Error " + err);
                });
        },

        isPatientSearchable() {
            const searchableFields = [
                "document_number",
                "name",
                "lastname_f",
                "lastname_m",
                "birth_date",
                "gender",
                "comuna_id",
                "email",
                "phone",
                "address",
                "prevision",
            ];

            let isSearchable = false;

            searchableFields.forEach((field) => {
                if (this.patient[field] !== "" && this.patient[field] !== 0) {
                    isSearchable = true;
                }
            });

            return isSearchable;
        },

        clear(emitEvent = false) {
            this.patient = {
                id: null,
                document_type: 1,
                document_number: "",
                lastname_f: "",
                lastname_m: "",
                name: "",
                birth_date: "",
                gender: 0,
                country_id: 40,
                email: "",
                comuna_id: 0,
                phone: "",
                address: "",
                prevision: "",
                estado: "",
            };

            this.patientSelected = false;

            if (emitEvent) this.$emit("patient-cleared");
        },
    },
};
</script>
