<template>
    <f7-block class="padding-vertical">
        <f7-block v-if="loading" class="row demo-preloaders align-items-stretch text-align-center margin-bottom">
            <f7-col>
                <f7-preloader></f7-preloader>
            </f7-col>
        </f7-block>
        <template v-else>
            <f7-block>
                <f7-row class="margin-bottom">
                    <f7-col>
                        <label>Tipo de documento</label>
                        <f7-input type="select" @change="$set(patientFields, 'document_type', Number($event.target.value))">
                            <option value="">Seleccione</option>
                            <option value="1">RUN</option>
                            <option value="2">Pasaporte</option>
                            <option value="3">DNI</option>
                            <option value="4">Otro</option>
                        </f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Número de documento</label>
                        <f7-input
                            type="text"
                            @input="$set(patientFields, 'document_number', $event.target.value)"
                            :value="patientFields.document_number"
                        ></f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Nombre</label>
                        <f7-input
                            type="text"
                            class="input-uppercase"
                            @input="$set(patientFields, 'name', $event.target.value)"
                            :value="patientFields.name"
                        ></f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Apellido paterno</label>
                        <f7-input
                            type="text"
                            class="input-uppercase"
                            @input="$set(patientFields, 'lastname_f', $event.target.value)"
                            :value="patientFields.lastname_f"
                        ></f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Apellido materno</label>
                        <f7-input
                            type="text"
                            class="input-uppercase"
                            @input="$set(patientFields, 'lastname_m', $event.target.value)"
                            :value="patientFields.lastname_m"
                        ></f7-input>
                    </f7-col>
                </f7-row>
                <f7-row class="margin-bottom">
                    <f7-col>
                        <label>Fecha de nacimiento</label>
                        <f7-input
                            class="input-uppercase"
                            type="text"
                            :maxlength="10"
                            @input="$set(patientFields, 'birth_date', $event.target.value)"
                            :value="patientFields.birth_date | formatDate"
                            placeholder="dd/MM/AAAA"
                        ></f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Edad</label>
                        <f7-input disabled>
                            <span class="uppercase">{{ patientAge(patientFields.birth_date) }}</span>
                        </f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Sexo</label>
                        <f7-input type="select" @change="$set(patientFields, 'gender', $event.target.value)">
                            <option value="">Seleccione</option>
                            <option value="1">Femenino</option>
                            <option value="2">Masculino</option>
                        </f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Email</label>
                        <f7-input
                            type="text"
                            class="input-uppercase"
                            @input="$set(patientFields, 'email', $event.target.value)"
                            :value="patientFields.email"
                        ></f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Telefono</label>
                        <f7-input type="text" @input="$set(patientFields, 'phone', $event.target.value)" :value="patientFields.phone"></f7-input>
                    </f7-col>
                </f7-row>

                <f7-row class="margin-bottom padding-bottom">
                    <f7-col>
                        <label>Dirección</label>
                        <f7-input
                            type="text"
                            class="input-uppercase"
                            @change="$set(patientFields, 'address', $event.target.value)"
                            :value="patientFields.address"
                        ></f7-input>
                    </f7-col>

                    <f7-col>
                        <label>Comuna</label>
                        <f7-list no-hairlines class="no-margin-vertical">
                            <f7-list-item
                                class="smart-select-list-item-sm"
                                title="Comuna"
                                smart-select
                                :smart-select-params="{ openIn: 'popup', searchbar: true, searchbarPlaceholder: 'Buscar comunas' }"
                            >
                                <select v-model="patientFields.comuna_id">
                                    <option v-for="comuna in comunas" :key="comuna.id" :value="comuna.id">{{ comuna.name }}</option>
                                </select>
                            </f7-list-item>
                        </f7-list>
                    </f7-col>

                    <f7-col>
                        <label>País</label>
                        <f7-list no-hairlines class="no-margin-vertical">
                            <f7-list-item
                                class="smart-select-list-item-sm"
                                title="País"
                                smart-select
                                :smart-select-params="{ openIn: 'popup', searchbar: true, searchbarPlaceholder: 'Buscar paises' }"
                            >
                                <select v-model="patientFields.country_id">
                                    <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name_es }}</option>
                                </select>
                            </f7-list-item>
                        </f7-list>
                    </f7-col>

                    <f7-col>
                        <label>Prevision</label>
                        <f7-list no-hairlines class="no-margin-vertical">
                            <f7-list-item
                                class="smart-select-list-item-sm"
                                :title="patientFields.prevision && previsiones.length ? getPrevision(patientFields.prevision).name : 'Seleccione...'"
                                smart-select
                                :smart-select-params="{
                                    openIn: 'popup',
                                    searchbar: true,
                                    searchbarPlaceholder: 'Buscar prevision',
                                    closeOnSelect: true,
                                    setValueText: false,
                                    popupCloseLinkText: 'Cerrar',
                                    pageTitle: 'Seleccione una prevision',
                                }"
                            >
                                <select @change="patientFields.prevision = Number($event.target.value)" :value="patientFields.prevision">
                                    <option value="">Seleccione...</option>
                                    <option v-for="prevision in previsiones" :key="prevision.id" :value="prevision.id">
                                        {{ prevision.name }}
                                    </option>
                                </select>
                            </f7-list-item>
                        </f7-list>
                    </f7-col>
                </f7-row>
            </f7-block>

            <slot name="search-footer"></slot>
        </template>
    </f7-block>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { _patientAge } from "@/app/helpers";

export default {
    props: {
        reset: {
            type: Boolean,
            required: false,
        },
    },

    data() {
        return {
            patientAge: _patientAge,

            loading: false,

            comunas: [],
            countries: [],
            previsiones: [],

            patientFields: {
                document_type: "",
                document_number: "",
                name: "",
                lastname_f: "",
                lastname_m: "",
                birth_date: "",
                email: "",
                phone: "",
                address: "",
                gender: "",
                comuna_id: 0,
                country_id: 0,
                prevision: "",
            },
        };
    },

    computed: {
        ...mapGetters(["getPrevision"])
    },

    watch: {
        reset: function() {
            this.resetPatientFields();
        },

        loading: function() {
            this.$emit("is-loading", this.loading);
        },

        patientFields: {
            deep: true,
            handler() {
                this.$emit("fields-changed", this.patientFields);
            },
        },
    },

    created() {
        this.loading = true;

        this.$firebase
            .auth()
            .currentUser.getIdToken()
            .then((idToken) => {
                const promises = [];

                // todo: implementar cache
                promises.push(
                    this.getAPIInfo({ url: "countries", userToken: idToken }),
                    this.getAPIInfo({ url: "comunas", userToken: idToken }),
                    this.getAPIInfo({ url: "previsiones", userToken: idToken }),
                );

                Promise.all(promises)
                    .then((responses) => {
                        const countries = responses[0];
                        const comunas = responses[1];
                        const previsiones = responses[2];

                        this.countries = countries.data.data;
                        this.comunas = comunas.data.data;
                        this.previsiones = previsiones.data.data;
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
    },

    methods: {
        ...mapActions(["getAPIInfo"]),

        resetPatientFields() {
            this.patientFields = {
                document_type: "",
                document_number: "",
                name: "",
                lastname_f: "",
                lastname_m: "",
                birth_date: "",
                email: "",
                phone: "",
                address: "",
                gender: "",
                comuna_id: 0,
                country_id: 0,
                prevision: "",
            };
        },
    },
};
</script>
