<template>
    <f7-page name="agreements" @page:beforeremove="removeSelectedAgreement">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title>Convenio</f7-nav-title>
            <f7-nav-right>
                <f7-link icon-material="refresh" tooltip="Recargar Lista" @click="getAgreements"></f7-link>
                <f7-link class="searchbar-enable" data-searchbar=".searchbar-demo" icon-material="search"></f7-link>
            </f7-nav-right>
            <f7-searchbar
                class="searchbar-demo"
                remove-diacritics
                expandable
                search-container=".search-list"
                search-in=".item-title"
                :disable-button="!$theme.aurora"
                placeholder="Buscar tipo de convenio"
            ></f7-searchbar>
        </f7-navbar>

        <f7-list class="searchbar-not-found">
            <f7-list-item title="No hay coincidencias"></f7-list-item>
        </f7-list>
        <f7-list class="no-margin-top search-list searchbar-found" media-list>
            <f7-list-item
                v-for="agreement in allAgreements"
                :key="agreement.id"
                :title="agreement.name"
                link="#"
                :class="{ selected: viewingAgreement && viewingAgreement.id === agreement.id }"
                @click="viewAgreementDetail(agreement)"
            ></f7-list-item>
        </f7-list>

        <f7-fab position="right-bottom" slot="fixed" color="teal">
            <f7-icon material="add"></f7-icon>
            <f7-icon ios="f7:close" aurora="f7:close" md="material:close"></f7-icon>
            <f7-fab-buttons position="top">
                <f7-fab-button fab-close @click="goToNewAgreement()" label="Crear nuevo tipo de convenio">
                    <f7-icon material="playlist_add"></f7-icon>
                </f7-fab-button>
            </f7-fab-buttons>
        </f7-fab>
    </f7-page>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    computed: {
        ...mapState("agreements", ["allAgreements", "viewingAgreement"]),
    },

    methods: {
        ...mapActions(["getAPIInfo"]),

        viewAgreementDetail(agreement) {
            this.$store.commit("agreements/set_viewing_agreement", agreement);

            this.$f7.views.main.router.navigate("/migration/mantenedores/agreements/details");
        },

        goToNewAgreement() {
            this.$store.commit("agreements/set_viewing_agreement", null);

            this.$f7.views.main.router.navigate("/migration/mantenedores/agreements/create-or-edit", { reloadCurrent: true });
        },

        getAgreements() {
            this.$f7.dialog.preloader("Cargando...");

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({
                        url: "agreements",
                        userToken: idToken,
                    });
                })
                .then((response) => {
                    const responseData = response.data;

                    if (responseData) {
                        const agreements = responseData.data;

                        this.$store.commit("agreements/set_all_agreements", agreements);
                    }

                    this.$f7.dialog.close();
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error);
                });
        },

        removeSelectedAgreement() {
            this.$store.commit("agreements/set_viewing_agreement", null);
        },
    },

    mounted() {
        console.log("mounted");
        if (this.allAgreements.length === 0) {
            console.log("call");
            this.getAgreements();
        }
    },
};
</script>

<style scoped>
.selected {
    border-left: 7px solid #36b5aa;
    color: #2d968c;
    background-color: #f3f3f3;
}
</style>
