<template>
    <f7-page name="carga-resultados-index" @page:beforein="isGoingToPlantilla = false" @page:beforeout="handleBeforeOut">
        <f7-navbar title="Resultados"></f7-navbar>

        <f7-block>
            <f7c-alert type="info" dismissible class="margin-horizontal-half">
                Solo puedes cargar resultados de exámenes con estado
                <b>En Laboratorio</b>
            </f7c-alert>

            <f7-card outline>
                <f7-card-header class="display-flex justify-content-space-between">
                    <div class="text-color-gray">Buscador de exámenes</div>
                    <div class="display-flex">
                        <f7-button small v-if="showingExamsSearch" @click="showingExamsSearch = false">Ocultar</f7-button>
                        <f7-button small v-else @click="showingExamsSearch = true">Mostrar</f7-button>
                        <f7-button small class="margin-left-half" color="red" @click="clearSearchFilters(true)">
                            Limpiar filtros
                        </f7-button>
                    </div>
                </f7-card-header>
                <f7-card-content v-show="showingExamsSearch">
                    <exams-search
                        simple-search
                        :default-options="getExamsSearchDefaultOptions"
                        @options-changed="handleExamsSearchOptionsChanged"
                        @search-triggered="handleSearchExams"
                        @is-loading="handleExamsSearchIsLoading"
                        :reset="resetExamsSearchComponent"
                    >
                        <template #search-footer>
                            <f7-button class="margin-horizontal margin-bottom" fill large raised @click="handleSearchExams">
                                <f7-icon material="search"></f7-icon>
                                Buscar exámenes
                            </f7-button>
                        </template>
                    </exams-search>
                </f7-card-content>
            </f7-card>
        </f7-block>

        <f7-block v-if="selectedAdmissionExams.length">
            <div class="display-flex justify-content-space-between margin-bottom">
                <h2 v-show="didSearchByCompleteOTCode" class="no-margin">Viendo exámenes de la OT: {{ currentOt }}</h2>
                <f7-button small fill @click="clearViewingExam(true)">
                    Limpiar {{ selectedAdmissionExams.length > 1 ? "exámenes" : "examen actual" }}
                </f7-button>
            </div>

            <exams-table :exams="selectedAdmissionExams">
                <template #table-header>
                    <th>CB Muestras</th>
                    <th>OT</th>
                    <th>Seccion</th>
                    <th>Documento paciente</th>
                    <th>Paciente</th>
                    <th>Exámen</th>
                    <th>Muestras</th>
                    <th>Estado</th>
                    <th>Fecha cargado</th>
                    <th v-if="shouldShowInformeColumn(selectedAdmissionExams)">Informe</th>
                    <th></th>
                </template>

                <!-- @dblclick="handleRowClickViewResults(admissionExam)" -->
                <template #table-row="{ admissionExam, f }">
                    <tr>
                        <td>{{ f.admissionExamSamplesCodes(admissionExam) }}</td>
                        <td>{{ f.admissionExamOtCode(admissionExam) }}</td>
                        <td>{{ admissionExam.exam_section.name }}</td>
                        <td>{{ f.patientDocumentInfo(admissionExam) }}</td>
                        <td>
                            {{ f.patientFullName(admissionExam) }}
                        </td>
                        <td>{{ admissionExam.exam.name }}</td>
                        <td>{{ f.admissionExamSamplesNames(admissionExam) }}</td>
                        <td>
                            <span :class="{ 'text-color-gray': currentExamStatusBySelectedReportType === 'Seleccione Informe' }">
                                {{ currentExamStatusBySelectedReportType }}
                            </span>
                        </td>
                        <td>{{ admissionExam.createdAt ? $moment(admissionExam.createdAt).format("DD/MM/YYYY HH:mm") : "Sin fecha de carga" }}</td>

                        <td
                            v-if="
                                !admissionExam.exam.result_as_pdf &&
                                    admissionExam.exam_status.id > examStatusesData.admitido &&
                                    admissionExam.exam_status.id !== examStatusesData.examen_pendiente &&
                                    admissionExam.exam_status.id !== examStatusesData.examen_rechazado
                            "
                        >
                            <f7-input
                                type="select"
                                name="report-types"
                                @change="$store.commit('plantillas/set_selected_report_type', $event.target.value)"
                            >
                                <option value="">Seleccione un tipo de informe</option>
                                <option
                                    v-for="reportType in reportTypes"
                                    :value="reportType.name"
                                    :key="reportType.id"
                                    :selected="reportType.name === selectedReportType"
                                >
                                    {{ reportType.shortName }}
                                </option>
                            </f7-input>
                        </td>

                        <td class="display-flex justify-content-center align-items-center">
                            <f7-button
                                @click.stop="f.viewPatient(admissionExam)"
                                icon-material="person"
                                tooltip="Ver información del paciente"
                            ></f7-button>
                            <template v-if="admissionExam.exam.result_as_pdf">
                                <f7-button
                                    v-if="admissionExam.exam_result.length > 0"
                                    icon-material="delete"
                                    @click.stop="handleDeletePdf(admissionExam)"
                                    :disabled="shouldDisableButtons(admissionExam)"
                                    tooltip="Eliminar PDF"
                                    color="red"
                                ></f7-button>
                                <f7-button
                                    v-if="admissionExam.exam_result.length > 0"
                                    icon-material="refresh"
                                    @click.stop="handleModifyPdf(admissionExam)"
                                    :disabled="shouldDisableButtons(admissionExam)"
                                    tooltip="Reemplazar PDF"
                                ></f7-button>
                                <f7-button
                                    icon-material="picture_as_pdf"
                                    @click.stop="handleViewOrCargarResultadoAsPdf(admissionExam)"
                                    :disabled="shouldDisableButtons(admissionExam)"
                                    :tooltip="admissionExam.exam_result.length ? 'Ver PDF de resultado' : 'Cargar resultado como PDF'"
                                ></f7-button>
                                <f7-button
                                    icon-material="verified_user"
                                    @click.stop="handleDownloadVerifyPdf(admissionExam)"
                                    :disabled="shouldDisableButtons(admissionExam) || admissionExam.exam_result.length === 0"
                                    tooltip="Descargar PDF de verificación"
                                ></f7-button>
                            </template>
                            <template v-else>
                                <f7-button
                                    v-if="userHasChangeExamStatusPrivileges"
                                    icon-material="assignment_turned_in"
                                    @click.stop="handleShowSelectPlantilla(admissionExam)"
                                    :disabled="shouldDisableButtons(admissionExam)"
                                    tooltip="Cargar resultados / Firmar este exámen"
                                ></f7-button>
                                <f7-button
                                    @click.stop="isGoingToVisorPDF = true && f.viewExamPDF()"
                                    small
                                    icon-material="print"
                                    tooltip="Imprimir resultado en PDF"
                                    :disabled="!canPrintExam"
                                ></f7-button>
                            </template>
                            <f7-button @click.stop="f.viewExamLog(admissionExam)" small icon-material="reorder" tooltip="Flujograma"></f7-button>
                        </td>
                    </tr>
                </template>
            </exams-table>
        </f7-block>

        <input type="file" id="pdf-result-input" @change="pdfResultFileChanged($event)" accept="application/pdf" style="display: none;" />

        <select-plantilla-popup
            :opened="showSelectPlantilla"
            @popup-closed="showSelectPlantilla = false"
            :selected-exam="viewingAdmissionExam"
            @plantilla-selected="handlePlantillaSelected"
        ></select-plantilla-popup>

        <select-exam-popup
            v-show="exams.length > 0"
            :opened="isSelectPopupOpened"
            :exams="examsToSelect"
            :pagination="pagination"
            @popup-closed="isSelectPopupOpened = false"
            @exam-selected="handleExamSelected"
            :exams-search-options="examsSearchOptions"
        ></select-exam-popup>
    </f7-page>
</template>

<script>
import jsPDF from "jspdf";
import QRCode from "qrcode";
import { format } from "rut.js";
import { mapActions, mapGetters, mapState } from "vuex";
import ExamsSearch from "@/app/components-migration/exams-search.vue";
import SearchMixin from "@/app/mixins/search-mixin.js";
import ExamsTable from "@/app/components-migration/exams-table.vue";
import SelectExamPopup from "@/app/carga-resultados/components/select-exam-popup.vue";
import SelectPlantillaPopup from "@/app/carga-resultados/components/select-plantilla-popup.vue";
import { examStatuses, reportTypes as reportTypesData, examStatusesNames } from "@/app/constants";

export default {
    mixins: [SearchMixin],

    components: {
        "exams-search": ExamsSearch,
        "select-exam-popup": SelectExamPopup,
        "select-plantilla-popup": SelectPlantillaPopup,
        "exams-table": ExamsTable,
    },

    data() {
        return {
            reportTypes: reportTypesData,
            examStatusesData: examStatuses,

            resetExamsSearchComponent: false,

            examsSearchIsLoading: true,
            examsSearchOptions: {},
            showingExamsSearch: true,
            didSearchByCompleteOTCode: false,

            exams: [],
            pagination: {},

            // Select exam popup stuff
            isSelectPopupOpened: false,
            examsToSelect: [],
            otExams: [],

            showSelectPlantilla: false,

            isGoingToPlantilla: false,
            isGoingToVisorPDF: false,

            // Upload pdf as result stuff
            currentAdmissionExamUploadingPdf: null,
            modifyingPdfAdmissionExam: null,
        };
    },

    computed: {
        ...mapState(["misprivx", "env", "buckets"]),

        ...mapGetters("plantillas", ["usedPlantillaIdByReportType", "currentStatusIdByReportType"]),

        ...mapState("plantillas", ["viewingAdmissionExam", "viewingAdmissionExamOriginal", "selectedReportType"]),

        searchQueryString() {
            const queryString = this.queryStringFromOptions(this.examsSearchOptions, {
                rowsPerPage: 100,
            });

            return queryString;
        },

        userHasChangeExamStatusPrivileges() {
            const examStatusPrivs = this.misprivx.change_exam_status;

            if (!examStatusPrivs) return false;

            const hasAtLeastOne = examStatusPrivs["cargar_resultado"] || examStatusPrivs["confirmar"] || examStatusPrivs["firmar"];

            return hasAtLeastOne;
        },

        selectedAdmissionExams() {
            if (this.didSearchByCompleteOTCode) {
                return this.otExams;
            } else if (this.viewingAdmissionExam) {
                return [this.viewingAdmissionExam];
            }

            return [];
        },

        currentOt() {
            if (!this.didSearchByCompleteOTCode) return "OT no identificada";

            return this.otExams[0].ot.code;
        },

        canPrintExam() {
            if (!this.selectedReportType) return false;

            if (this.currentStatusIdByReportType < this.examStatusesData.examen_impreso) return false;

            return true;
        },

        currentExamStatusBySelectedReportType() {
            const { exam_status, current_status_id, status_log, exam } = this.viewingAdmissionExam;

            if (
                current_status_id === examStatuses.admitido ||
                current_status_id === examStatuses.en_laboratorio ||
                current_status_id === examStatuses.examen_pendiente ||
                current_status_id === examStatuses.examen_rechazado
            ) {
                return exam_status ? exam_status.name : "Sin estado";
            }

            if (!this.selectedReportType && !exam.result_as_pdf) {
                return "Seleccione Informe";
            } else {
                const statusLog = status_log.find((statusLog) => statusLog.detail === this.selectedReportType);

                if (statusLog) {
                    return statusLog.exam_status_meta ? statusLog.exam_status_meta.name : "Sin estado";
                } else {
                    return examStatusesNames.en_laboratorio;
                }
            }
        },
    },

    watch: {
        selectedReportType() {
            const examResults = this.viewingAdmissionExamOriginal.exam_result || [];

            let result = examResults.find((examResult) => examResult.report_type === this.selectedReportType);

            if (result) {
                result = [result];
            } else {
                result = [];
            }

            const updatedViewingAdmissionExam = Object.assign({}, this.viewingAdmissionExamOriginal, { exam_result: result });

            this.$store.commit("plantillas/set_viewing_admission_exam", updatedViewingAdmissionExam);
        },

        viewingAdmissionExam: {
            deep: true,
            handler() {
                if (!this.viewingAdmissionExam) return;

                const viewingAdmissionId = this.viewingAdmissionExam.id;

                const admissionExamIndex = this.otExams.findIndex((e) => e.id === viewingAdmissionId);

                if (admissionExamIndex) {
                    this.$set(this.otExams, admissionExamIndex, this.viewingAdmissionExamOriginal);
                }
            },
        },
    },

    methods: {
        ...mapActions(["getAPIInfo", "updateInfo", "deleteAPIInfo"]),

        handleExamsSearchOptionsChanged(options) {
            this.examsSearchOptions = options;
        },

        handleExamSelected(exam) {
            this.$store.commit("plantillas/set_viewing_admission_exam", exam);

            this.$store.commit("plantillas/set_viewing_admission_exam_original", exam);

            this.isSelectPopupOpened = false;
        },
        handlePlantillaSelected(payload) {
            this.isGoingToPlantilla = true;

            const { plantilla } = payload;

            this.$store.commit("plantillas/set_viewing_plantilla", plantilla);

            const firmarPath = "/migration/carga-resultados/firma";

            this.$store.commit("setWhatTo", { what: "activeTab", to: firmarPath });
            this.$f7.views.main.router.navigate(firmarPath);
        },

        handleSearchExams() {
            this.$store.commit("plantillas/set_selected_report_type", null);

            this.$f7.dialog.preloader("Buscando examenes...");

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({
                        url: "admission_exams?" + this.searchQueryString,
                        userToken: idToken,
                    });
                })
                .then((response) => {
                    const { data } = response;

                    this.pagination = data.pagination;

                    const exams = data.data;

                    /* if (this.examsSearchOptions.ot_code) {
                        const splittedOTCode = this.examsSearchOptions.ot_code.split("-");

                        this.didSearchByCompleteOTCode = splittedOTCode.length >= 2;
                    } */

                    if (this.didSearchByCompleteOTCode && exams.length) {
                        this.otExams = exams;
                    } else {
                        if (exams.length === 1) {
                            const selectedExam = exams[0];
                            this.$store.commit("plantillas/set_viewing_admission_exam", selectedExam);
                            this.$store.commit("plantillas/set_viewing_admission_exam_original", selectedExam);
                        } else if (exams.length > 1) {
                            this.examsToSelect = exams;
                            this.isSelectPopupOpened = true;
                        } else {
                            this.$f7.dialog.alert("No se han encontrado exámenes para los criterios de búsqueda.");
                        }
                    }

                    this.$f7.dialog.close();
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error);
                });
        },

        handleExamsSearchIsLoading(isLoading) {
            this.examsSearchIsLoading = isLoading;
        },

        getExamsSearchDefaultOptions() {
            return { statuses: [examStatuses.en_laboratorio] };
        },

        clearSearchFilters(confirm) {
            const _clear = () => {
                this.resetExamsSearchComponent = !this.resetExamsSearchComponent;
                this.showingExamsSearch = true;
                this.examsSearchIsLoading = false;
            };

            if (confirm) {
                this.$f7.dialog.confirm("¿Está seguro?", () => _clear());
            } else {
                _clear();
            }
        },
        clearViewingExam(confirm) {
            const _clear = () => {
                this.$store.commit("plantillas/set_viewing_admission_exam", null);
                this.$store.commit("plantillas/set_viewing_admission_exam_original", null);
                this.$store.commit("plantillas/set_viewing_plantilla", null);
                this.$store.commit("plantillas/set_selected_report_type", null);

                this.otExams = [];
                this.didSearchByCompleteOTCode = false;
            };

            if (confirm) {
                this.$f7.dialog.confirm("¿Está seguro?", () => _clear());
            } else {
                _clear();
            }
        },

        handleBeforeOut() {
            if (!this.isGoingToPlantilla && !this.isGoingToVisorPDF) {
                this.$store.commit("plantillas/set_viewing_admission_exam", null);
                this.$store.commit("plantillas/set_viewing_admission_exam_original", null);
                this.$store.commit("plantillas/set_selected_report_type", null);
                this.$store.commit("plantillas/set_viewing_plantilla", null);
            }
        },

        async handleRowClickViewResults(admissionExam) {
            if (!this.userHasChangeExamStatusPrivileges) {
                return;
            }

            if (!this.selectedReportType) {
                this.$f7.dialog.alert("Debe seleccionar un tipo de informe");
                return;
            }

            if (admissionExam.current_status_id < this.examStatusesData.en_laboratorio) {
                return;
            }

            if (admissionExam.exam.result_as_pdf) {
                await this.handleViewOrCargarResultadoAsPdf(admissionExam);
                return;
            }

            await this.handleShowSelectPlantilla(admissionExam);
        },

        async handleShowSelectPlantilla(admissionExam) {
            if (!this.selectedReportType) {
                this.$f7.dialog.alert("Debe seleccionar un tipo de informe");
                return;
            }

            let usedPlantillaByReportType;
            let isSignedByReportType;

            if (this.selectedReportType === "Informe preliminar" && admissionExam.used_plantilla_preliminar && admissionExam.signed_by_preliminar) {
                usedPlantillaByReportType = admissionExam.used_plantilla_preliminar;
                isSignedByReportType = admissionExam.signed_by_preliminar;
            } else if (this.selectedReportType === "Informe final" && admissionExam.used_plantilla_final && admissionExam.signed_by_final) {
                usedPlantillaByReportType = admissionExam.used_plantilla_final;
                isSignedByReportType = admissionExam.signed_by_final;
            } else if (this.selectedReportType === "Informe complementario" && admissionExam.used_plantilla_compl && admissionExam.signed_by_compl) {
                usedPlantillaByReportType = admissionExam.used_plantilla_compl;
                isSignedByReportType = admissionExam.signed_by_compl;
            }

            if (!usedPlantillaByReportType || !isSignedByReportType) {
                this.$store.commit("plantillas/set_viewing_admission_exam", admissionExam);
                this.showSelectPlantilla = true;
                return;
            }

            this.$f7.dialog.preloader("Cargando plantilla...");

            const plantillaSnapshot = await this.$firebase
                .database()
                .ref(`/results_plantillas/${usedPlantillaByReportType}`)
                .once("value");

            const usedPlantilla = plantillaSnapshot.val();

            if (!usedPlantilla) {
                this.$f7.dialog.close();
                this.$f7.toast.show({
                    text: "<b>Error.</b> No se encontró la plantilla usada. Seleccione alguna de la lista",
                    closeTimeout: 4000,
                });

                this.showSelectPlantilla = true;
                return;
            }

            this.$f7.dialog.close();

            this.handlePlantillaSelected({ plantilla: usedPlantilla });
        },

        async handleViewOrCargarResultadoAsPdf(admissionExam) {
            if (admissionExam.exam_result && admissionExam.exam_result.length) {
                this.viewPdfResult(admissionExam);
                return;
            }

            this.currentAdmissionExamUploadingPdf = admissionExam;
            this.$$("#pdf-result-input").click();
        },

        async handleDownloadVerifyPdf(admissionExam) {
            this.$f7.dialog.preloader("Generando PDF de verificación");

            const getQRCode = this.$firebase.functions().httpsCallable("getQRCode");

            const response = await getQRCode({
                admissionExamId: admissionExam.id,
                patientId: admissionExam.admission.patient.id,
                reportType: "Informe pdf",
            });

            const baseUrl = this.env !== "production" ? "https://verify-inmunocel-dev.web.app" : "https://verify.inmunocel.com";

            const qrValue = response && response.data ? `${baseUrl}/?code=${response.data}` : "https://portal.inmunocel.com";

            await this.generateVerifyPdf(admissionExam, qrValue);

            this.$f7.dialog.close();
        },

        async generateVerifyPdf(admissionExam, qrValue) {
            if (!qrValue || !admissionExam.admission.patient) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("No se pudo generar el PDF de verificación");
                return;
            }

            const { patient } = admissionExam.admission;

            const doc = new jsPDF();

            const backImage = new Image();
            backImage.src = "../../static/img/fondox.jpg";
            doc.addImage(backImage, "jpg", 0, 0, 210, 297);

            const logoImage = new Image();
            logoImage.src = "../../static/img/logoinmuno.png";
            doc.addImage(logoImage, "png", 5, 5, 80, 25);

            doc.setFontSize(20);
            doc.setFont(undefined, "bold");
            doc.text("Verificación de exámen", 100, 38, {
                align: "center",
            });

            doc.setFontSize(11);
            doc.setFont(undefined, "normal");
            doc.text(`Nombre: ${patient.name || ''}`, 20, 55);
            doc.text(`Apellido paterno: ${patient.lastname_f || ''}`, 20, 65);
            doc.text(`Apellido materno: ${patient.lastname_m || ''}`, 20, 75);
            doc.text(`Documento: ${this.formatPatientDocInfo(patient)}`, 20, 85);

            doc.text(`Fecha nacimiento: ${this.formatPatientFechaNacimiento(patient)}`, 20, 95);
            doc.text(`Exámen: ${admissionExam.exam.name || ''}`, 20, 105);
            doc.text(`Fecha informe: ${this.formatAdmissionExamInformeDate(admissionExam)}`, 20, 115);

            doc.setFontSize(10);
            doc.setFont(undefined, "italic");
            doc.text("Escanee el siguiente código para ver el estado y resultados del exámen", 100, 135, { align: "center" });

            const qrImage = await QRCode.toDataURL(qrValue);
            doc.addImage(qrImage, "png", 68, 148, 70, 70);

            let filename = "";

            if (patient) {
                if (patient.lastname_f) filename += `${patient.lastname_f}_`;
                if (patient.lastname_m) filename += `${patient.lastname_m}_`;
                if (patient.name) filename += `${patient.name}`;
            }

            if (!filename) filename = "examen";

            filename += "_verificacion";
            filename += ".pdf";

            doc.save(filename + ".pdf");

            return;
        },

        formatPatientDocInfo(patient) {
            let docType = "";
            let docNumber = "";

            docType = patient.document_type_info.name;

            if (docType === "RUT") docType = "RUN";

            docNumber = patient.document_number;

            if (docType === "RUN") {
                docNumber = format(docNumber);
            }

            return `${docType} ${docNumber}`;
        },
        formatPatientFechaNacimiento(patient) {
            return patient.birth_date ? this.$moment(patient.birth_date, "YYYY-MM-DD").format("DD/MM/YYYY") : "";
        },
        formatAdmissionExamInformeDate(admissionExam) {
            const lastFirmadoLog = admissionExam.status_log.find((statusLog) => statusLog.status_id === this.examStatusesData.examen_firmado);
            const dateFirmado =
                lastFirmadoLog && lastFirmadoLog.when ? this.$moment(lastFirmadoLog.when, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm") : "";

            return dateFirmado;
        },

        viewPdfResult(admissionExam) {
            try {
                const url = admissionExam.exam_result[0].details[0].value;

                this.isGoingToVisorPDF = true;
                this.$store.commit("setWhatTo", { what: "focusedPDF", to: url });
                this.$store.commit("setWhatTo", { what: "viewingFocusedPDFExam", to: admissionExam });
                this.$f7.views.main.router.navigate("/pdfviewer/");
            } catch (error) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Hubo un error al mostrar el pdf del examen", error.message);
                console.error(error);
            }
        },

        async pdfResultFileChanged(e) {
            const confirmText = this.modifyingPdfAdmissionExam
                ? "Se modificará el archivo PDF cargado como resultado. El archivo actual <b>no se podrá recuperar</b> en el futuro."
                : "El examen se marcará como resultado cargado, confirmado y firmado (por usted).";

            const toastText = this.modifyingPdfAdmissionExam
                ? "Se modificó exitosamente el PDF del resultado."
                : "Se cargó el PDF como resultado del examen. El examen quedó en estado Firmado.";

            this.$f7.dialog.confirm(confirmText, "¿Está seguro?", async () => {
                this.$f7.dialog.preloader("Cargando PDF como resultado...");

                const [file] = e.target.files;

                if (!file) {
                    this.$f7.dialog.alert("Hubo un problema al cargar el PDF, por favor reintente.");
                    return;
                }

                const admissionExamId = this.modifyingPdfAdmissionExam ? this.modifyingPdfAdmissionExam.id : this.currentAdmissionExamUploadingPdf.id;

                const uploadedFile = await this.uploadFileToStorage({
                    file,
                    path: `pdfs_results/${admissionExamId}`,
                });

                const pdfUrl = await uploadedFile.ref.getDownloadURL();

                try {
                    const token = await this.$firebase.auth().currentUser.getIdToken();

                    let updatedAdmissionExam;

                    if (!this.modifyingPdfAdmissionExam) {
                        const dataToSendResultadoCargado = {
                            admission_exam_id: this.currentAdmissionExamUploadingPdf.id,
                            exam_result_type: "pdf",
                            method_id: 0,
                            observations: null,
                            results: [
                                {
                                    exam_id: this.currentAdmissionExamUploadingPdf.exam.id,
                                    other_result: pdfUrl,
                                },
                            ],
                        };

                        await this.updateInfo({
                            url: `admission_exams/status/${this.examStatusesData.resultado_cargado}`,
                            userToken: token,
                            data: { exams: [dataToSendResultadoCargado] },
                        });

                        await this.updateInfo({
                            url: `admission_exams/status/${this.examStatusesData.examen_confirmado}`,
                            userToken: token,
                            data: { exams: [{ admission_exam_id: this.currentAdmissionExamUploadingPdf.id }] },
                        });

                        const response = await this.updateInfo({
                            url: `admission_exams/status/${this.examStatusesData.examen_firmado}`,
                            userToken: token,
                            data: { exams: [{ admission_exam_id: this.currentAdmissionExamUploadingPdf.id }] },
                        });

                        console.log("Se marcó como firmado", response);

                        [updatedAdmissionExam] = response.data.updatedAdmissionExams;
                    } else {
                        // Modificar pdf cargado
                        const response = await this.updateInfo({
                            url: "admission_exams/pdf",
                            userToken: token,
                            data: {
                                exam: {
                                    admission_exam_id: this.modifyingPdfAdmissionExam.id,
                                    result: {
                                        detail_id: this.modifyingPdfAdmissionExam.exam_result[0].details[0].id,
                                        exam_id: this.modifyingPdfAdmissionExam.exam.id,
                                        pdf_url: pdfUrl,
                                    },
                                },
                            },
                        });

                        [updatedAdmissionExam] = response.data.data;
                    }

                    if (this.didSearchByCompleteOTCode) {
                        const index = this.otExams.findIndex((e) => e.id == updatedAdmissionExam.id);

                        if (index !== -1) {
                            this.$set(this.otExams, index, {
                                ...this.otExams[index],
                                ...updatedAdmissionExam,
                            });
                        } else {
                            this.$f7.dialog.alert("Hubo un problema al actualizar localmente el examen. Por favor, refresque el portal.");
                        }

                        console.log("Actualizando otExams", this.otExams);
                    } else {
                        this.$store.commit("plantillas/set_viewing_admission_exam", {
                            ...this.viewingAdmissionExam,
                            ...updatedAdmissionExam,
                        });

                        console.log("Actualizando viewingAdmissionExam", this.viewingAdmissionExam);
                    }

                    this.currentAdmissionExamUploadingPdf = null;
                    this.modifyingPdfAdmissionExam = null;

                    this.$f7.toast.show({
                        text: toastText,
                        closeTimeout: 3000,
                    });

                    this.$f7.dialog.close();
                } catch (error) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Error al cargar el resultado como pdf", error.message);
                    console.error(error);
                }
            });
        },
        handleModifyPdf(admissionExam) {
            this.modifyingPdfAdmissionExam = admissionExam;
            this.$$("#pdf-result-input").click();
        },
        handleDeletePdf(admissionExam) {
            this.$f7.dialog.confirm("Esta acción no se puede deshacer", "Eliminar PDF", () => {
                if (admissionExam.exam_result && admissionExam.exam_result[0]) {
                    this.$f7.dialog.preloader("Eliminando PDF...");

                    const detail = admissionExam.exam_result[0].details ? admissionExam.exam_result[0].details[0] : null;

                    if (detail) {
                        this.$firebase
                            .auth()
                            .currentUser.getIdToken()
                            .then((idToken) => {
                                return this.deleteAPIInfo({ url: `admission_exams/${admissionExam.id}/pdf`, userToken: idToken });
                            })
                            .then((response) => {
                                const updatedAdmissionExam = response.data.data;

                                if (this.didSearchByCompleteOTCode) {
                                    const index = this.otExams.findIndex((e) => e.id == updatedAdmissionExam.id);

                                    if (index !== -1) {
                                        this.$set(this.otExams, index, {
                                            ...this.otExams[index],
                                            ...updatedAdmissionExam,
                                        });
                                    } else {
                                        this.$f7.dialog.alert("Hubo un problema al actualizar localmente el examen. Por favor, refresque el portal.");
                                    }
                                } else {
                                    this.$store.commit("plantillas/set_viewing_admission_exam", {
                                        ...this.viewingAdmissionExam,
                                        ...updatedAdmissionExam,
                                    });
                                }

                                const file = detail.value;

                                const fileRef = this.$firebase
                                    .app()
                                    .storage()
                                    .refFromURL(file);

                                fileRef
                                    .delete()
                                    .then(() => {
                                        console.log("file deleted");
                                    })
                                    .catch((error) => {
                                        console.error(error);
                                    });

                                this.$f7.dialog.close();
                            })
                            .catch((err) => {
                                console.error(err);
                                this.$f7.dialog.alert("Error " + err);
                            });
                    }
                }
            });
        },
        async uploadFileToStorage({ file, path }) {
            try {
                console.log({ file, path });

                const targetBucket = this.env === "production" ? this.buckets.prod : this.buckets.dev;

                const uploadedFile = await this.$firebase
                    .app()
                    .storage(targetBucket)
                    .ref(`${path}/resultado_examen.pdf`)
                    .put(file);

                return uploadedFile;
            } catch (error) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Error al guardar el pdf de resultado en storage", error.message);
                console.error(error);
            }
        },

        shouldShowInformeColumn(admissionExams) {
            return admissionExams.some(
                (admissionExam) =>
                    admissionExam.exam_status.id > examStatuses.admitido &&
                    admissionExam.exam_status.id !== examStatuses.examen_pendiente &&
                    admissionExam.exam_status.id !== examStatuses.examen_rechazado
            );
        },
        shouldDisableButtons(admissionExam) {
            const { current_status_id } = admissionExam;

            return (
                current_status_id < examStatuses.en_laboratorio ||
                current_status_id === examStatuses.examen_pendiente ||
                current_status_id === examStatuses.examen_rechazado
            );
        },
    },
};
</script>
