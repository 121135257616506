<template>
    <f7-page>
        <f7-navbar :title="computedTitle">
            <f7-nav-right>
                <f7-link popup-close>Cerrar</f7-link>
            </f7-nav-right>
        </f7-navbar>
        <form @submit.prevent="submit">
            <f7-block>
                <f7-row>
                    <f7-col>
                        <f7-row>
                            <f7-col>
                                <f7-list no-hairlines-md style="margin:0">
                                    <li class="">
                                        <div class="item-content item-input item-input-with-value">
                                            <div class="item-inner">
                                                <div class="item-title item-label">
                                                    Insumo a ingresar
                                                </div>
                                                <div class="item-input-wrap">
                                                    <input
                                                        :value="insumo_code"
                                                        list="insumosList"
                                                        type="text"
                                                        placeholder="Cupric acetate"
                                                        @input="insumoSearch($event.target.value)"
                                                        :disabled="isEditing"
                                                    />
                                                </div>
                                                <datalist id="insumosList">
                                                    <option v-for="item in datalistInsumo" :key="item.id" :value="item.description" />
                                                </datalist>
                                            </div>
                                        </div>
                                    </li>
                                </f7-list>
                            </f7-col>
                        </f7-row>
                        <f7-row>
                            <f7-col>
                                <f7-row>
                                    <f7-col width="70">
                                        <f7-list no-hairlines-md style="margin:0">
                                            <li class="">
                                                <div class="item-content item-input item-input-with-value">
                                                    <div class="item-inner">
                                                        <div class="item-title item-label">
                                                            Factura de ingreso
                                                        </div>
                                                        <div class="item-input-wrap">
                                                            <input
                                                                id="invoiceFocus"
                                                                :value="invoice_code"
                                                                list="invoice"
                                                                type="text"
                                                                placeholder="0000000000000"
                                                                @input="setInvoice($event.target.value)"
                                                                :disabled="isEditing"
                                                            />
                                                        </div>
                                                        <datalist id="invoice">
                                                            <option v-for="item in invoiceDatalist" :key="item.id" :value="item.text" />
                                                        </datalist>
                                                    </div>
                                                </div>
                                            </li>
                                        </f7-list>
                                    </f7-col>
                                    <f7-col width="30">
                                        <f7-button
                                            @click="toInvoice()"
                                            style="position: absolute; margin-top: 3%;"
                                            icon-size="20"
                                            btn
                                            fill
                                            small
                                            icon-f7="plus"
                                            tooltip="Ingresar factura"
                                            :disabled="isEditing"
                                        ></f7-button>
                                    </f7-col>
                                </f7-row>
                            </f7-col>
                            <f7-col>
                                <f7-list no-hairlines-md style="margin:0">
                                    <f7-list-input
                                        @change="form.lote = $event.target.value"
                                        label="Codigo de lote (opcional)"
                                        type="text"
                                        :value="form.lote"
                                    ></f7-list-input>
                                </f7-list>
                            </f7-col>
                        </f7-row>

                        <f7-row>
                            <f7-col>
                                <f7-list no-hairlines-md style="margin:0">
                                    <f7-list-input
                                        name="brandFocus"
                                        required
                                        type="select"
                                        label="Marca"
                                        :value="form.brand_id"
                                        @change="form.brand_id = $event.target.value"
                                    >
                                        <option value="NA" disabled>Seleccione una Marca</option>
                                        <option v-for="item in this.brands" :key="item.id" :value="item.id">{{ item.description }}</option>
                                    </f7-list-input>
                                </f7-list>
                            </f7-col>
                        </f7-row>
                        <f7-row>
                            <f7-col>
                                <f7-list no-hairlines-md style="margin:0">
                                    <f7-list-input
                                        required
                                        type="select"
                                        label="Proveedor"
                                        :value="form.provider_id"
                                        @change="form.provider_id = $event.target.value"
                                    >
                                        <option value="NA" disabled>Seleccione un proveedor</option>
                                        <option v-for="item in this.providers" :key="item.id" :value="item.id">{{ item.description }}</option>
                                    </f7-list-input>
                                </f7-list>
                            </f7-col>
                        </f7-row>
                        <f7-row>
                            <f7-col>
                                <f7-list no-hairlines-md style="margin:0">
                                    <f7-list-input
                                        label="Cantidad a ingresar"
                                        type="number"
                                        min="1"
                                        @change="form.quantity = $event.target.value"
                                        :value="form.quantity"
                                    ></f7-list-input>
                                </f7-list>
                            </f7-col>
                            <f7-col>
                                <f7-list no-hairlines-md style="margin:0">
                                    <f7-list-input label="Unidad de medida" type="text" min="1" :value="unit"></f7-list-input>
                                </f7-list>
                            </f7-col>
                        </f7-row>
                        <f7-row>
                            <f7-col>
                                <f7-list no-hairlines-md style="margin:0">
                                    <f7-list-input
                                        label="Fecha de ingreso"
                                        type="text"
                                        :maxlength="10"
                                        @input="form.entry = $event.target.value"
                                        :value="form.entry | formatDate"
                                        placeholder="dd/MM/AAAA"
                                    ></f7-list-input>
                                </f7-list>
                            </f7-col>
                        </f7-row>
                        <f7-row>
                            <f7-col>
                                <f7-list no-hairlines-md style="margin:0">
                                    <f7-list-input
                                        class="mb-2"
                                        label="Fecha de vencimiento"
                                        type="text"
                                        :maxlength="10"
                                        @input="form.expiration = $event.target.value"
                                        :value="form.expiration | formatDate"
                                        placeholder="dd/MM/AAAA"
                                    ></f7-list-input>
                                </f7-list>
                            </f7-col>
                        </f7-row>
                        <f7-button type="submit" btn fill>{{ computedSave }}</f7-button>
                    </f7-col>
                    <f7-col style="text-align: center">
                        <f7-list no-hairlines-md>
                            <f7-list-input label="Advertencia y precauciones" type="textarea" readonly :value="danger"></f7-list-input>
                            <img
                                :src="image"
                                style="max-width:100%,border:1px solid #fff; border-radius: 5px; padding: 2%; max-width : 100%;margin:auto"
                            />
                        </f7-list>
                    </f7-col>
                </f7-row>
            </f7-block>
        </form>
    </f7-page>
</template>

<script>
import moment from "moment";
import { mapGetters, mapState } from "vuex";
import { printermixin } from "../../../mixins/printermixin";

export default {
    mixins: [printermixin],
    components: {},

    props: {
        reboot: { required: true },
        editingEntry: Object
    },
    data() {
        return {
            form: {
                supply_id: "NA",
                brand_id: "NA",
                provider_id: "NA",
                entry: "",
                expiration: "",
                quantity: 1,
                invoice_id: "",
                deposit_code: "",
                lote: "",
                user: "",
            },
            details: "",
            image: "",
            danger: "",
            providers: [],
            brands: [],
            invoice_code: "NA",
            insumo_code: "",
            unit: "",
            invoiceDatalist: [],
            datalistInsumo: [],
        };
    },
    mounted() {
        this.init();
    },
    watch: {
        reboot: {
            handler() {
                this.init();
            },
        },
        invoice_code: {
            handler() {},
        },
        isEditing: {
            handler() {
                this.init();
            }
        }
    },
    computed: {
        ...mapState(["invoice", "supply", "profile_userInfo", "provider"]),

        ...mapGetters(["getInvoice"]),

        isEditing() {
            return this.editingEntry !== null;
        },

        computedTitle() {
            return !this.isEditing ? "Nuevo ingreso" : "Editando ingreso";
        },
        computedSave() {
            return !this.isEditing ? "Guardar" : "Editar";
        }
    },
    methods: {
        toInvoice() {
            this.$f7.popup.close(".newEntry");
            this.$f7.popup.open(".newInvoice");
        },
        invoicesFilter() {},
        setInvoice(value) {
            if (value.search(" - ")) {
                value = value.split(" - ")[0];
            }

            let found = false;

            for (const item of this.invoice) {
                if (item.code == value) {
                    console.log("enter");
                    this.form.invoice_id = item.id;
                    this.form.provider_id = item.provider_id;
                    this.invoice_code = value;
                    document.getElementsByName("brandFocus")[0].focus();

                    found = true;
                }
            }

            if (found == false) {
                this.form.invoice_id = "NA";
                this.invoice_code = value;
            }
            this.invoiceDatalist = [];
            var limit = 0;
            for (const item of this.invoice) {
                console.log(item);
                var reg = new RegExp(this.invoice_code);

                if (item.code.toLowerCase().search(reg) != -1 || this.invoice_code == "") {
                    console.log("stage 1");
                    if (limit < 10) {
                        var pass = false;
                        if (this.providers.length == 0) {
                            pass == true;
                        }
                        console.log("stage 2");
                        console.log(pass);
                        for (const p of this.providers) {
                            console.log(item.provider_id + " - " + p.id);

                            if (item.provider_id == p.id) {
                                pass = true;
                            }
                        }
                        console.log("stage 3");
                        console.log(pass);
                        if (pass) {
                            var provider = "";
                            for (const i of this.provider) {
                                if (item.provider_id == i.id) {
                                    item.provider = i;
                                }
                            }

                            item.text = item.code + " - " + item.provider.description;
                            this.invoiceDatalist.push(item);
                            limit++;
                        }
                    }
                }
            }
        },
        insumoSearch(value) {
            let found = false;

            for (const item of this.supply) {
                if (item.description == value) {
                    console.log("enter");
                    this.selectSupply(item.id);
                    found = true;
                }
            }
            this.insumo_code = value;

            this.datalistInsumo = [];
            var limit = 0;
            for (const item of this.supply) {
                var reg = new RegExp(value.toLowerCase());
                if (item.description.toLowerCase().search(reg) != -1 || value == "") {
                    if (limit < 10) {
                        this.datalistInsumo.push(item);
                        limit++;
                    }
                }
            }
        },

        async submit() {
            if (
                this.invoice_code == "" ||
                this.invoice_code == "NA" ||
                this.form.supply_id == "NA" ||
                this.form.brand_id == "NA" ||
                this.form.provider_id == "NA"
            ) {
                this.$store.dispatch("toast", { app: this, text: "Todos los campos son obligatorios" });
            } else if (moment(this.form.expiration, "DD/MM/YYYY").isValid() == false) {
                this.$store.dispatch("toast", { app: this, text: "La fecha de expiracion es obligatoria y debe ser valida" });
            } else if (moment(this.form.expiration, "DD/MM/YYYY").isSameOrBefore(moment())) {
                this.$store.dispatch("toast", { app: this, text: "La fecha de expiracion no puede ser menor que la fecha de ingreso del lote" });
            } else if (this.isEditing) {
                try {
                    const token = await this.$firebase.auth().currentUser.getIdToken();

                    const formattedForm = {
                        brand_id: this.form.brand_id ? Number(this.form.brand_id) : 0,
                        deposit_code: this.form.deposit_code,
                        entry: this.$moment(this.form.entry, "DD/MM/YYYY").format("YYYY-MM-DD"),
                        expiration: this.$moment(this.form.expiration, "DD/MM/YYYY").format("YYYY-MM-DD"),
                        invoice_id: this.form.invoice_id ? Number(this.form.invoice_id) : 0,
                        lote: this.form.lote || "",
                        provider_id: this.form.provider_id ? Number(this.form.provider_id) : 0,
                        quantity: this.form.quantity,
                        supply_id: this.form.supply_id ? Number(this.form.supply_id) : 0,
                        user: this.form.user,
                    }

                    const result = await this.$store.dispatch("apiRequest", {
                        url: "universal/",
                        token,
                        method: "PUT",
                        data: { info: formattedForm, model: "Deposit", search: this.editingEntry.id },
                        request: { afterOk: "Ingreso editado" },
                        app: this,
                    });

                    console.log("Ingreso editado!!", result);

                    this.$emit("updateStock");

                    this.$f7.popup.close(".newEntry");
                } catch (error) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Hubo un error al editar el ingreso.", error);
                    console.error(error);
                }
            } else {
                this.$firebase
                    .auth()
                    .currentUser.getIdToken()
                    .then(async (token) => {
                        this.form.deposit_code = moment().unix() + Math.floor(Math.random() * 90000) + 10000;

                        if (this.form.invoice_id == "NA") {
                            var search = await this.$store.dispatch("apiRequest", {
                                url: "universal/search",
                                token,
                                method: "POST",
                                data: { model: "Invoice", field: "code", search: this.invoice_code },
                            });

                            if (search.info.data.length == 0) {
                                var invoice = {
                                    code: this.invoice_code,
                                    date: moment().format("YYYY-MM-DD"),
                                    provider_id: this.form.provider_id,
                                    state_id: 1,
                                    detail: "",
                                    file: "",
                                };
                                var result = await this.$store.dispatch("apiRequest", {
                                    url: "universal/",
                                    token,
                                    method: "POST",
                                    data: { info: invoice, model: "Invoice" },
                                });

                                if (result.code == 200) {
                                    this.form.invoice_id = result.info.message[0].id;
                                }
                            }
                        }

                        this.form.deposit_code =
                            this.form.deposit_code + "" + this.form.supply_id + "" + this.form.provider_id + "" + this.form.brand_id;

                        var preform = this.form;
                        const supply_id = this.form.supply_id;

                        preform.entry = moment(preform.entry, "DD/MM/YYYY").format("YYYY-MM-DD");
                        preform.expiration = moment(preform.expiration, "DD/MM/YYYY").format("YYYY-MM-DD");

                        var result = await this.$store.dispatch("apiRequest", {
                            url: "universal/",
                            token,
                            method: "POST",
                            data: { info: preform, model: "Deposit" },
                            request: { afterOk: "Insumo ingresado" },
                            app: this,
                        });

                        this.$f7.dialog.prompt(
                            "Impresion de etiquetas",
                            "¿Confirme la cantidad de etiquetas a imprimir?",
                            (value) => {
                                var description = "";
                                for (const item of this.$store.state.supply) {
                                    console.log(item.id + " - " + supply_id);
                                    if (item.id == supply_id) {
                                        description = item.description;
                                    }
                                }
                                try {
                                    this.makeSupplyLabel(
                                        this.form.deposit_code,
                                        this.form.entry,
                                        this.form.expiration,
                                        description,
                                        value
                                    );
                                } catch (error) {
                                    console.log(error);
                                }
                            },
                            () => {},
                            1
                        );

                        this.init();
                        this.$emit("updateStock");
                        this.$f7.popup.close(".newEntry");
                    });
            }
        },
        async selectSupply(value) {
            this.form.supply_id = value;
            this.relations();
        },
        async relations() {
            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then(async (token) => {
                    let values = await this.$store.dispatch("apiRequest", {
                        app: this,
                        url: "universal/search",
                        token,
                        method: "POST",
                        data: { relation: ["providers", "brands"], model: "Supply", field: "id", search: this.form.supply_id },
                    });
                    this.providers = values.info.data[0].providers;
                    this.brands = values.info.data[0].brands;
                    this.details = values.info.data[0].details;
                    this.danger = values.info.data[0].danger;
                    this.image = values.info.data[0].image;
                    this.unit = values.info.data[0].unit;
                    document.getElementById("invoiceFocus").focus();
                });
        },
        async init() {
            console.log(`isEditing: ${this.isEditing}`);

            if (this.isEditing) {
                this.form.deposit_code = this.editingEntry.deposit_code;
                this.form.supply_id = this.editingEntry.supply_id;
                this.form.brand_id = this.editingEntry.brand_id;
                this.form.provider_id = this.editingEntry.provider_id;
                this.form.quantity = this.editingEntry.quantity;
                this.form.expiration = this.$moment(this.editingEntry.expiration, "YYYY-MM-DD").format("DD/MM/YYYY");
                this.form.unit = this.editingEntry.unit;
                this.unit = this.editingEntry.supply.unit;
                this.danger = this.editingEntry.supply.danger;
                this.image = this.editingEntry.supply.image;

                const invoice = this.getInvoice(this.editingEntry.invoice_id);
                this.invoice_code = invoice ? invoice.code : null;
                
                this.form.invoice_id = this.editingEntry.invoice_id;
                this.insumo_code = this.editingEntry.supply.description;
                this.form.entry = this.$moment(this.editingEntry.entry, "YYYY-MM-DD").format("DD/MM/YYYY");
                this.form.user = this.editingEntry.user;

                await this.relations();

                return;
            }

            //console.log(this.profile_userInfo.displayName)
            this.form.deposit_code = moment().valueOf() + Math.floor(Math.random() * 90000) + 10000;
            this.form.supply_id = "NA";
            this.form.brand_id = "NA";
            this.form.provider_id = "NA";
            this.form.quantity = 1;
            this.form.expiration = "";
            this.form.unit = "";
            this.unit = "";
            this.danger = "";
            this.image = "";
            this.invoice_code = "";
            this.insumo_code = "";
            this.form.entry = moment().format("DD/MM/YYYY");
            this.form.user = this.profile_userInfo.displayName;
            //this.form.expiration = moment().format("DD/MM/YYYY");
        },
    },
};
</script>

<style scoped>
.mb-2 {
    margin-bottom: 4% !important;
}
.mb-1 {
    margin-bottom: 3% !important;
}
.align-left {
    text-align: left;
}
.padding-1 {
    padding: 0% 1%;
}
.toast {
    left: 45% !important;
}
.toast-text {
    font-size: 1.3em;
}
</style>
