<template>
    <div v-if="!previewMode">
        <block-toolbar :block="computedBlockInfo('observations-block')"></block-toolbar>

        <f7-block class="no-margin-vertical">
            <f7-input
                type="textarea"
                resizable
                @change="handleTextChanged($event.target.value)"
                @focus="handleFocusChanged(true)"
                @blur="handleFocusChanged(false)"
                :value="observations"
                placeholder="Ingrese las observaciones"
                :disabled="disableInputs"
            ></f7-input>
        </f7-block>
    </div>
    <div v-else>
        <div v-if="translation"><i>Observations</i></div>
        <div>
            <b>Observaciones</b>
            :
            <span style="white-space: break-spaces">{{ observations }}</span>
        </div>
    </div>
</template>

<script>
import BlockToolbarMixin from "@/app/components-migration/plantillas/blocks/toolbar/block-toolbar-mixin";
import BlockToolbar from "@/app/components-migration/plantillas/blocks/toolbar/block-toolbar.vue";

export default {
    mixins: [BlockToolbarMixin],

    props: {
        previewMode: Boolean,
        translation: Boolean,
        defaults: Object,
        disableInputs: Boolean,
    },

    components: {
        "block-toolbar": BlockToolbar,
    },

    data() {
        return {
            observations: "",
        };
    },

    created() {
        this.observations = this.defaults.observations || "";
    },

    methods: {
        handleTextChanged(text) {
            this.observations = text;
            this.$emit("observations-input-changed", text);
        },

        handleFocusChanged(value) {
            this.$emit("focus-changed", value);
        },
    },
};
</script>
