<template>
    <f7-page name="plantillas-list">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title>Plantillas</f7-nav-title>
            <f7-nav-right>
                <f7-button v-if="hideInactive" @click="hideInactive = !hideInactive">Mostrar inactivas</f7-button>
                <f7-button v-else @click="hideInactive = !hideInactive">Ocultar inactivas</f7-button>

                <f7-link icon-material="refresh" tooltip="Recargar Lista" @click="getPlantillas"></f7-link>
                <f7-link class="searchbar-enable" data-searchbar=".searchbar-demo" icon-material="search"></f7-link>
                <f7-button popover-open=".popover-menu-section" class="margin-right-half">
                    <f7-icon material="filter_list"></f7-icon>
                    Sección
                </f7-button>
            </f7-nav-right>
            <f7-searchbar
                class="searchbar-demo"
                remove-diacritics
                expandable
                search-container=".search-list"
                search-in=".item-title"
                :disable-button="!$theme.aurora"
                placeholder="Buscar plantillas"
            ></f7-searchbar>
        </f7-navbar>

        <f7-list class="searchbar-not-found">
            <f7-list-item title="No hay coincidencias"></f7-list-item>
        </f7-list>

        <f7-block v-if="filteredPlantillas.length === 0">
            No hay plantillas en la sección indicada
        </f7-block>

        <div class="margin">
           <b>Mostrando {{ filteredByActiveStatus.length }} plantillas</b>
        </div>

        <f7-list style="margin-bottom: 100px" class="no-margin-top search-list searchbar-found" media-list>
            <f7-list-item v-for="plantilla in filteredByActiveStatus" :key="plantilla.id">
                <div slot="header" v-if="plantilla.inactive"><span class="text-color-red">Plantilla inactiva</span></div>
                <div slot="title">{{ plantilla.name }}</div>
                <div slot="subtitle">{{ plantilla.description }}</div>
                <div slot="text">
                    {{ plantilla.createdBy || "Sin información" }} &bull;
                    {{ plantilla.createdAt ? "Creada el " + $moment.unix(plantilla.createdAt).format("DD/MM/YYYY HH:mm") : "Sin fecha" }}
                </div>
                <div slot="after" class="display-flex">
                    <f7-button outline small color="red" class="margin-right-half" @click="deletePlantilla(plantilla)">Eliminar</f7-button>
                    <f7-button outline small class="margin-right-half" @click="clone(plantilla)">Duplicar</f7-button>
                    <f7-button fill small color="blue" @click="goToPlantilla(plantilla)">Ver y editar</f7-button>
                </div>
            </f7-list-item>
        </f7-list>

        <f7-fab position="right-bottom" slot="fixed" color="teal">
            <f7-icon material="add"></f7-icon>
            <f7-icon ios="f7:close" aurora="f7:close" md="material:close"></f7-icon>
            <f7-fab-buttons position="top">
                <f7-fab-button fab-close @click="goToPlantilla()" label="Crear nueva plantilla">
                    <f7-icon material="playlist_add"></f7-icon>
                </f7-fab-button>
            </f7-fab-buttons>
        </f7-fab>

        <!-- Select stage popover (todo: only visible in stage 2) -->
        <f7-popover class="popover-menu-section">
            <f7-block>
                <div>
                    <f7-block-title>Seleccione una sección</f7-block-title>
                    <p>
                        <f7-radio
                            name="selectedSectionRadio"
                            value="0"
                            :checked="selectedSection === '0'"
                            @change="selectedSection = $event.target.value"
                        ></f7-radio>
                        Todas
                    </p>

                    <p>
                        <f7-radio
                            name="selectedSectionRadio"
                            value="1"
                            :checked="selectedSection === '1'"
                            @change="selectedSection = $event.target.value"
                        ></f7-radio>
                        Anatomia patológica
                    </p>

                    <p>
                        <f7-radio
                            name="selectedSectionRadio"
                            value="2"
                            :checked="selectedSection === '2'"
                            @change="selectedSection = $event.target.value"
                        ></f7-radio>
                        Biología molecular
                    </p>

                    <p>
                        <f7-radio
                            name="selectedSectionRadio"
                            value="3"
                            :checked="selectedSection === '|'"
                            @change="selectedSection = $event.target.value"
                        ></f7-radio>
                        Citometría de flujo
                    </p>

                    <p>
                        <f7-radio
                            name="selectedSectionRadio"
                            value="4"
                            :checked="selectedSection === '4'"
                            @change="selectedSection = $event.target.value"
                        ></f7-radio>
                        Inmunología
                    </p>
                </div>
            </f7-block>
        </f7-popover>
    </f7-page>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    data() {
        return {
            selectedSection: "0",

            hideInactive: true,
        };
    },

    computed: {
        ...mapState(["profile_userInfo"]),

        ...mapState("plantillas", ["allPlantillas"]),

        filteredPlantillas() {
            if (this.selectedSection == 0) {
                return Object.values(this.allPlantillas || {}).sort((a, b) => a.name.localeCompare(b.name));
            }

            const filtered = Object.values(this.allPlantillas || {}).filter((p) => p.section == this.selectedSection);

            return filtered.sort((a, b) => a.name.localeCompare(b.name));
        },

        filteredByActiveStatus() {
            if (this.hideInactive) {
                const activePlantillas = this.filteredPlantillas.filter(p => !p.inactive);
                return activePlantillas;
            } else {
                return this.filteredPlantillas;
            }
        },
    },

    methods: {
        ...mapActions(["getAPIInfo"]),

        goToPlantilla(plantilla = null) {
            this.$store.commit("plantillas/set_viewing_plantilla", plantilla);

            this.$f7.views.main.router.navigate("/migration/mantenedores/plantillas/create-or-edit");
        },

        deletePlantilla(plantilla) {
            this.$f7.dialog.preloader("Verificando si plantilla puede ser eliminada");

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => this.getAPIInfo({ url: `plantillas/${plantilla.id}/check`, userToken: idToken }))
                .then((resp) => {
                    console.log("resp", resp.data.count);

                    if (resp.data && resp.data.count === 0) {
                        this.$f7.dialog.close();
                        this.$f7.dialog.confirm("¿Esta seguro?", async () => {
                            await this.$firebase
                                .database()
                                .ref(`/results_plantillas/${plantilla.id}`)
                                .set(null);

                            this.$store.commit("plantillas/remove_plantilla", plantilla.id);

                            this.$f7.toast.show({
                                text: `Se ha eliminado la plantilla correctamente`,
                                position: "bottom",
                            horizontalPosition: "center",
                            closeTimeout: 2000,
                            });
                        });
                    } else {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert("La plantilla que se está intentando eliminar se encuentra en uso. Considere inactivarla");
                    }
                })
                .catch((err) => {
                    console.error(err);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Error al intentar eliminar plantilla. " + err);
                });
        },

        getPlantillas() {
            this.$f7.dialog.preloader("Cargando...");

            this.$firebase
                .database()
                .ref("results_plantillas")
                .once("value", (snapshot) => {
                    const plantillas = snapshot.val();

                    this.$store.commit("plantillas/set_all_plantillas", plantillas);

                    this.$f7.dialog.close();
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Error al obtener plantillas", error);
                });
        },

        clone(plantilla) {
            this.$f7.dialog.prompt("Nombre de la plantilla copia", "Duplicar plantilla", async (plantillaName) => {
                let plantillaCopy = { ...plantilla };

                const plantillaKey = this.$firebase
                    .database()
                    .ref()
                    .push().key;

                plantillaCopy.id = plantillaKey;
                plantillaCopy.name = plantillaName;
                plantillaCopy.createdAt = this.$timestamp();
                plantillaCopy.createdBy = this.profile_userInfo.displayName;
                plantillaCopy.isUsed = null;

                try {
                    await this.$firebase
                        .database()
                        .ref(`/results_plantillas/${plantillaCopy.id}`)
                        .set(plantillaCopy);

                    this.$store.commit("plantillas/add_plantilla", plantillaCopy);

                    this.$f7.dialog.close();
                } catch (error) {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Error al crear plantilla", error);
                }
            }, () => {}, `${plantilla.name} (copy)`);
        },
    },

    mounted() {
        if (Object.keys(this.allPlantillas || {}).length === 0) {
            this.getPlantillas();
        }
    },
};
</script>

<style scoped>
.selected {
    border-left: 7px solid #36b5aa;
    color: #2d968c;
    background-color: #f3f3f3;
}
</style>
