<template>
    <f7-page name="newRejectionReason">
        <f7-navbar v-if="!isEditor" title="Agregar nuevo motivo de rechazo" back-link></f7-navbar>
        <f7-navbar v-else title="Editar motivo de rechazo" back-link></f7-navbar>

        <f7-list no-hairlines-md>
            <f7-list-input
                label="Motivo de rechazo"
                type="text"
                name="rejection-name"
                placeholder="Motivo de rechazo"
                :value="rejectionReason.name"
                @input="rejectionReason.name = $event.target.value"
                required
                validate
                error-message="Debe indicar el nombre del motivo de rechazo"
            ></f7-list-input>
            <f7-list-input
                label="Descripción"
                type="textarea"
                name="rejection-description"
                placeholder="Descripción (Opcional)"
                :value="rejectionReason.description"
                @input="rejectionReason.description = $event.target.value"
            ></f7-list-input>
        </f7-list>

        <f7-block>
            <f7-button
                :icon-material="!isEditor ? 'playlist_add' : 'edit'"
                large
                outline
                :text="!isEditor ? 'Agregar motivo de rechazo' : 'Guardar cambios'"
                @click="!isEditor ? addRejectionReason() : editRejectionReason()"
            />
        </f7-block>
    </f7-page>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    data() {
        return {
            rejectionReason: {
                name: "",
                description: "",
                id: "",
                enabled: true,
            },
            isEditor: false,
        };
    },

    computed: {
        ...mapState(["profile_userInfo"]),

        ...mapState("reasons", ["viewingRejectionReason"]),
    },

    methods: {
        ...mapActions(["createNewInfo", "updateInfo"]),

        setAddOrEdit() {
            if (this.viewingRejectionReason) {
                this.isEditor = true;
                this.rejectionReason = { ...this.viewingRejectionReason };
            }
        },

        redirectRejectionDetails() {
            this.$store.commit("reasons/set_viewing_rejection_reason", this.rejectionReason);
            this.$f7.views.main.router.navigate("/migration/mantenedores/rejection-reasons/details");
        },

        addRejectionReason() {
            if (!this.rejectionReason.name) {
                this.$f7.dialog.alert("Es necesario ingresar una razón de rechazo", "Error");
                return;
            }

            this.$f7.dialog.preloader("Guardando...");

            let rejectionReason = { ...this.rejectionReason };
            delete rejectionReason.id;

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.createNewInfo({
                        url: "reasons/rejection",
                        userToken: idToken,
                        data: rejectionReason,
                    });
                })
                .then((response) => {
                    const createdRejectionReason = response.data.data;

                    this.$store.commit("reasons/add_rejection_reason", createdRejectionReason);

                    this.rejectionReason = createdRejectionReason;

                    this.$f7.dialog.close();
                    this.$f7.toast.create({ text: "Los datos se han guardado con éxito", closeTimeout: 2500 }).open();

                    this.redirectRejectionDetails();
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, err.code);
                });
        },

        editRejectionReason() {
            if (!this.rejectionReason.name) {
                this.$f7.dialog.alert("Es necesario ingresar una razón de rechazo", "Error");
                return;
            }

            this.$f7.dialog.preloader("Editando...");

            let rejectionReason = { ...this.rejectionReason };
            delete rejectionReason.id;

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.updateInfo({
                        url: `reasons/rejection/${this.rejectionReason.id}`,
                        userToken: idToken,
                        data: rejectionReason,
                    });
                })
                .then((response) => {
                    const updatedRejectionReason = response.data.data;

                    this.$store.commit("reasons/update_rejection_reason", updatedRejectionReason);

                    this.rejectionReason = updatedRejectionReason;

                    this.$f7.dialog.close();
                    this.$f7.toast.create({ text: "Los datos se han guardado con éxito", closeTimeout: 2500 }).open();

                    this.redirectRejectionDetails();
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, err.code);
                });
        },
    },
    mounted() {
        this.setAddOrEdit();
    },
};
</script>
