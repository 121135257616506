<template>
    <div v-if="!previewMode">
        <block-toolbar :block="computedBlockInfo('resultado-cualitativo-block')"></block-toolbar>

        <f7-block class="no-margin-vertical">
            <f7-row>
                <f7-col>
                    <label><b>Seleccione el exámen</b></label>
                    <f7-input type="select" @change="handleSelectedExam" class="margin-bottom" :disabled="disableInputs">
                        <option value="">Seleccione exámen...</option>
                        <option v-for="exam in exams" :key="exam.id" :value="exam.id" :selected="exam.id === defaults.examId">{{ exam.name }}</option>
                    </f7-input>
                </f7-col>
                <f7-col>
                    <div v-if="!selectedExam">
                        <p class="text-color-gray">Debe seleccionar un exámen</p>
                    </div>
                    <div v-else-if="selectedExam && !hasResultadoCualitativo">
                        <p class="text-color-red"><b>Este exámen no tiene resultado cualitativo</b></p>
                    </div>
                    <template v-else>
                        <label><b>Seleccione el resultado cualitativo</b></label>
                        <f7-input type="select" @change="handleResultadoCualitativoChanged" :disabled="disableInputs">
                            <option value="">Seleccione resultado...</option>
                            <option
                                v-for="resultTypeValue in resultTypesValues"
                                :key="resultTypeValue.id"
                                :value="resultTypeValue.value"
                                :selected="resultTypeValue.value == result"
                                :disabled="resultTypeValue.inactive"
                            >
                                {{ resultTypeValue.value }}
                            </option>
                        </f7-input>
                    </template>
                </f7-col>
            </f7-row>
        </f7-block>
    </div>
    <div v-else>
        <div v-if="selectedExam">
            <div v-if="translation"><i>Results</i></div>
            <div>
                <!-- <b>Resultado {{ isCollection ? selectedExam.name : "" }}</b>: -->
                <b>{{ `Resultado ${isCollection ? selectedExam.name : ""}`.trim() }}</b>
                :
                {{ result ? result : "Sin resultado seleccionado" }}
                <span v-if="translation && isCovid && isNegativeOrPositive(result)">
                    / <i>{{ traducirResultadoCovid(result) }}</i>
                </span>
            </div>
        </div>
        <div v-else>Sin examen seleccionado</div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import BlockToolbarMixin from "@/app/components-migration/plantillas/blocks/toolbar/block-toolbar-mixin";
import BlockToolbar from "@/app/components-migration/plantillas/blocks/toolbar/block-toolbar.vue";

export default {
    mixins: [BlockToolbarMixin],

    props: {
        defaults: Object,
        translation: Boolean,
        admissionExam: Object,
        exams: Array,
        previewMode: Boolean,
        disableInputs: Boolean,
    },

    components: {
        "block-toolbar": BlockToolbar,
    },

    data() {
        return {
            selectedExam: null,

            result: "",
        };
    },

    watch: {
        defaults: {
            immediate: true,
            deep: true,
            handler() {
                this.setDefaultsToBlock();
            },
        },

        exams() {
            if (this.exams.length === 0) {
                this.selectedExam = null;

                this.$emit("resultado-cualitativo-exam-selected", {
                    examId: "",
                });
                this.$emit("resultado-cualitativo-result-changed", { result: "" });
            }
        },

        viewingPlantilla: {
            handler: function(plantilla) {
                if (plantilla) this.setDefaultsToBlock();
            }
        },
    },

    computed: {
        ...mapState("plantillas", ["viewingPlantilla"]),

        hasResultadoCualitativo() {
            if (!this.selectedExam) return false;

            if (!this.selectedExam.result_types) return false;

            const found = this.selectedExam.result_types.find((rt) => rt.result_type === 1);

            return found ? true : false;
        },

        resultTypesValues() {
            if (!this.hasResultadoCualitativo) return [];

            const resultType = this.selectedExam.result_types.find((rt) => rt.result_type === 1);

            return resultType.result_type_values || [];
        },

        isCollection() {
            if (!this.admissionExam) return false;
            if (!this.admissionExam.exam) return false;
            if (!this.admissionExam.exam.associations) return false;

            return this.admissionExam.exam.associations.length > 0;
        },

        isCovid() {
            if (!this.admissionExam) return false;
            if (!this.admissionExam.exam) return false;

            return this.admissionExam.exam.id === 368;
        },
    },

    methods: {
        setDefaultsToBlock() {
            if (this.defaults.examId) {
                const exam = this.exams.find((e) => e.id === this.defaults.examId);

                this.$set(this, "selectedExam", exam);
            }

            if (this.defaults.result || this.defaults.result === "") {
                this.$set(this, "result", this.defaults.result);
            }
        },
        handleSelectedExam(event) {
            const examId = Number(event.target.value);

            const exam = this.exams.find((e) => e.id === examId);

            this.selectedExam = exam;

            this.$emit("resultado-cualitativo-exam-selected", {
                examId: this.selectedExam ? this.selectedExam.id : "",
            });
        },
        handleResultadoCualitativoChanged(event) {
            const result = event.target.value;

            this.result = result;

            this.$emit("resultado-cualitativo-result-changed", {
                result: result,
            });
        },

        // Traducción hardcodeada
        isNegativeOrPositive(result) {
            return ["Negativo", "Positivo"].includes(result);
        },
        traducirResultadoCovid(result) {
            const traducciones = {
                Negativo: "Negative",
                Positivo: "Positive",
            };

            return traducciones[result];
        },
    },
};
</script>
