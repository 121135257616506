<template>
    <f7-page >
        <f7-navbar title="Nueva factura">
            <f7-nav-right>
                <f7-link popup-close>Cerrar</f7-link>
            </f7-nav-right>
        </f7-navbar>
        <form @submit.prevent="submit" >
            <f7-block>
                <f7-row class="mb-2">
                    <f7-col width="45">
                        <f7-list no-hairlines-md>
                            <f7-list-input @blur="padd($event.target.value)" required label="Codigo de Factura" type="text" :maxlength="15" @input="form.code = $event.target.value" :value="form.code" placeholder="00000000"></f7-list-input>
                        </f7-list>
                    </f7-col>
                    <f7-col width="10">

                    </f7-col>
                    <f7-col width="45">
                        <f7-list no-hairlines-md>
                            <f7-list-input required label="Fecha de Factura" type="text" :maxlength="10" @input="form.date = $event.target.value" :value="form.date | formatDate" placeholder="dd/MM/AAAA"></f7-list-input>
                        </f7-list>
                    </f7-col>
                </f7-row>
                <f7-row class="mb-2">
                    <f7-col>
                        <f7-list no-hairlines-md>
                            <f7-list-input required type="select" label="Proveedor" :value="form.provider_id" @change="form.provider_id = $event.target.value">
                                <option value="NA" disabled>Seleccione un proveedor</option>
                                <option v-for="(item) in this.$store.state.provider" :key="item.id" :value="item.id">{{ item.description }}</option>
                            </f7-list-input>
                        </f7-list>
                    </f7-col>
                </f7-row>
                <f7-row class="mb2">
                    <f7-col width="45">
                        <f7-list no-hairlines-md>
                            <f7-list-input label="RUT" type="text" :maxlength="10" :value="getRut" readonly></f7-list-input>
                        </f7-list>
                    </f7-col>
                    <f7-col width="45">
                        <f7-list no-hairlines-md>
                            <f7-list-input required type="select" label="Condicion de la factura" :value="form.state_id" @change="form.state_id = $event.target.value">
                                <option v-for="(item) in this.$store.state.state" :key="item.id" :value="item.id">{{ item.description }}</option>
                            </f7-list-input>
                        </f7-list>
                    </f7-col>
                </f7-row>

                <f7-row class="mb-2">
                     <f7-col>
                        <f7-list no-hairlines-md>
                            <f7-list-input rows="5" label="Detalles y observaciones" type="textarea" :maxlength="250" @change="form.detail = $event.target.value" :value="form.detail"></f7-list-input>
                        </f7-list>
                    </f7-col>
                </f7-row>
                <f7-row class="mb-2">
                     <f7-col>
                        <f7-list no-hairlines-md>
                            <f7-list-input name="fileInput"  accept="application/pdf"  label="Archivo asociado" type="file" :maxlength="250" @change="file = $event.target.files[0]" ></f7-list-input>
                        </f7-list>
                    </f7-col>
                </f7-row>
                <f7-row>
                     <f7-col>
                        <f7-button fill btn type="submit">Guardar</f7-button>
                    </f7-col>
                </f7-row>
            </f7-block>
        </form>
    </f7-page>
</template>

<script>

import moment from 'moment';
import { mapState } from 'vuex';

export default {


    props :{
        reboot : { required : false },
        return : {required : false}
    },
    computed :{
        ...mapState(['invoice'])
    },
    data(){
        return {
            form : {
                code : "",
                date : "",
                provider_id : "NA",
                state_id : 1,
                detail : "",
                file : ""
            },
            file : ""

        }
    },
    watch: {
        reboot : {
            handler() {
                this.init()
            }
        }
    },
    mounted() {
        this.init();
    },
    computed : {
        getRut(){

            var rut = "";
            if(this.form.provider_id != "NA"){
                this.$store.state.provider.forEach(item => {
                    if(item.id == this.form.provider_id){
                        rut = item.dnu;
                    }
                });
            }
            return rut;
        }
    },
    methods : {

        async submit(){
            let progress = true;
            await this.$firebase.auth().currentUser.getIdToken().then(async (token) => {
                await this.$store.dispatch("updateState",{ token , state : [
                    {"path" : "universal/?model=Invoice&relation="+encodeURIComponent(JSON.stringify(['provider','state'])), "model" : "invoice"}
                ]})
            });
            for(const item of this.$store.state.invoice){
                console.log(item.code + " - " + this.form.code)
                if(item.code == this.form.code && this.form.provider_id == item.provider_id){
                    console.log("es false")
                    progress = false;
                }
            }

            console.log("Me sali")
            if(progress == false){
                this.$store.dispatch("toast",{ app : this, text : "Ya existe una factura con el mismo codigo" });
                return false;
            }

            if(this.form.provider_id !== "NA"){

                if(this.file !== ""){
                    this.form.file = await this.$store.dispatch('uploadFile',
                        {
                            app : this,
                            path : 'invoices/',
                            file : this.file,
                            alert : true
                        }
                    )
                }

                this.$firebase.auth().currentUser.getIdToken().then(async (token) => {

                    let bulk = this.form;
                    bulk.date = moment(bulk.date, "DD/MM/YYYY").add(1, "days").format("YYYY-MM-DD")

                    var result = await this.$store.dispatch(
                        "apiRequest",
                        {
                            url : "universal/" ,
                            token ,
                            method : "POST",
                            data : { info : bulk, model : 'Invoice'},
                            request : {afterOk : 'Factura ingresada'},
                            app : this
                        }
                    );



                    this.$emit("updateTable")
                    this.init()
                    this.$f7.popup.close('.newInvoice');
                    if(this.return == "return"){

                        this.$firebase.auth().currentUser.getIdToken().then(async (token) => {

                            this.$store.dispatch("updateState",{ token , state : [
                                {"path" : "universal/?model=Invoice", "model" : "invoice"}
                            ]});

                             this.$f7.popup.open('.newEntry');
                        });


                    }
                });
            }else{
                this.$store.dispatch("toast",{ app : this, text : "Debe seleccionar un proveedor" });
                return false;
            }
        },
        padd(val){
            this.form.code = val.replace(/[a-z]/gi, '') .padStart(10,'0')
        },
        init(){

            this.form.code = "";
            this.form.provider_id = "NA",
            this.form.detail = "";
            this.file = "";
            this.form.file = "";
            this.form.state_id = 1;
            this.form.date = moment().format("DD/MM/YYYY");
            var x = document.getElementsByName('fileInput');
            for(const item of x){
                item.value = ""
            }

        }

    }
}
</script>

<style scoped>
.mb-2{
    margin-bottom: 4% !important;


}
.mb-1{
    margin-bottom: 3% !important;

}
.align-left{
    text-align: left;
}
.padding-1{
    padding: 0% 1%;
}
.toast{
    left: 45% !important;
}
.toast-text{
    font-size: 1.3em;
}
.no-hairlines-md{
    margin: 0;
}
</style>