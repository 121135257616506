<template>
    <div id="ot-pdf" style="letter-spacing: 0.2px; width: 842px; font-size:10px">
        <div class="display-flex justify-content-space-between align-items-center">
            <img src="../../../static/img/logoinmuno.png" alt="Inmunocel" style="width: 180px" />
            <h1>Orden de trabajo</h1>
            <div>
                <vue-barcode
                    :id="`barcode-${ot.code}`"
                    :value="ot.code"
                    format="CODE128"
                    width="1"
                    height="30"
                ></vue-barcode>
            </div>
        </div>
        <div class="text-align-center">
            <b>Seccion:</b>
            {{ getSectionById(ot.sectionId) }}

            &nbsp;

            <b>Prioridad:</b>
            {{ admission.priority }}
        </div>
        <f7-row>
            <f7-col>
                <p>
                    <b>Nombre:</b>
                    <span v-html="patientFullName"></span>
                </p>
                <p>
                    <b>{{ formatTipoIdentificador(admission.patient.tipoIdentificador) }}:</b>
                    {{ formatIdentificador(admission.patient) }}
                </p>
                <p>
                    <b>Fecha de nacimiento:</b>
                    {{ admission.patient.fechaNacimiento }}
                </p>
                <p>
                    <b>Edad:</b>
                    {{ patientAge(admission.patient.fechaNacimiento) }}
                </p>
                <p>
                    <b>Sexo:</b>
                    {{ admission.patient.sexo }}
                </p>
            </f7-col>
            <f7-col>
                <p>
                    <b>Diagnóstico:</b>
                    <span v-if="admission.diagnosticObs">
                        {{ admission.diagnosticObs }}
                    </span>
                    <span v-else class="text-color-gray">
                        Sin datos
                    </span>
                </p>
                <p>
                    <b>Observaciones:</b>
                    <span v-if="ot.observations">
                        {{ ot.observations }}
                    </span>
                    <span v-else class="text-color-gray">
                        Sin datos
                    </span>
                </p>
                <div v-if="hasDoctor(admission)">
                    <p>
                        <b>Médico solicitante:</b>
                        <span v-if="admission.doctor.name">
                            {{ admission.doctor.name }}
                        </span>
                        <span v-else class="text-color-gray">
                            Sin datos
                        </span>
                    </p>
                    <p v-if="admission.doctor.contacto1">
                        <b>Contacto 1 médico solicitante</b>
                        {{ admission.doctor.contacto1 }}
                    </p>
                    <p v-if="admission.doctor.contacto2">
                        <b>Contacto 2 médico solicitante</b>
                        {{ admission.doctor.contacto2 }}
                    </p>
                </div>
                <p v-else>Sin datos de médico solicitante</p>
            </f7-col>
            <f7-col>
                <p>
                    <b>Procedencia:</b>
                    <span v-if="admission.institution">
                        {{ admission.institution }}
                    </span>
                    <span v-else class="text-color-gray">
                        Sin datos
                    </span>
                </p>
                <p>
                    <b>Fecha hora de ingreso:</b>
                    {{ $moment(admission.creationDate).format("DD-MM-YYYY HH:mm:ss") }}
                </p>
                <p>
                    <b>Recepcionista:</b>
                    {{ admission.username }}
                </p>
            </f7-col>
        </f7-row>
        <table class="table table-sm table-bordered margin-top">
            <thead class="thead-default">
                <tr>
                    <th>Cod. Barras</th>
                    <th>Cod. examen</th>
                    <th>Examen</th>
                    <th>Muestras</th>
                    <th>Estado examen</th>
                    <th>Fecha ingreso</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="exam in exams" :key="exam.admExamId">
                    <td>{{ examSampleCode(exam) }}</td>
                    <td>{{ exam.externalId }}</td>
                    <td>{{ exam.name }}</td>
                    <td v-html="examSamples(exam.samples)"></td>
                    <td>
                        <div>{{ getStatusText(exam.currentStatus) }}</div>
                        <div v-if="exam.currentStatus === 'pendiente' || exam.currentStatus === 'rechazado'">
                            Razón: {{ getStatusReason(exam) }}
                        </div>
                    </td>
                    <td>{{ $moment.unix(exam.fechaCargado).format("DD-MM-YYYY HH:mm:ss") }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { _getSectionById, _getStatusText, _patientAge } from "@/app/helpers";
import VueBarcode from "vue-barcode";

import { format } from "rut.js";

export default {
    props: {
        admission: Object,
        ot: Object,
        otExams: Object,
    },

    components: {
        "vue-barcode": VueBarcode,
    },

    data() {
        return {
            patientAge: _patientAge,
            getSectionById: _getSectionById,
            getStatusText: _getStatusText,
        };
    },

    computed: {
        patientFullName() {
            return `${this.admission.patient.nombres} ${this.admission.patient.primerApellido} ${
                this.admission.patient.segundoApellido
            }`.toUpperCase();
        },

        exams() {
            if (Object.keys(this.otExams || {}).length > 0) {
                return this.otExams;
            }

            return this.ot.exams;
        },
    },

    methods: {
        examSampleCode(exam) {
            if (!exam.samples) {
                return "-";
            }

            const samplesKeys = Object.keys(exam.samples);

            return samplesKeys[0];
        },
        formatTipoIdentificador(tipoIdentificador) {
            if (tipoIdentificador.toLowerCase() === "pasaporte") {
                return "Pasaporte";
            }

            if (tipoIdentificador.toLowerCase() === "rut") {
                return "RUN";
            }

            return tipoIdentificador;
        },
        formatIdentificador(patient) {
            if (patient.tipoIdentificador === "RUT") {
                return format(patient.identificador);
            }

            return patient.identificador;
        },

        examSamples(samples) {
            if (!Object.keys(samples || {}).length) {
                return "Sin muestras";
            }

            let list = "<ul class='no-margin' style='list-style-type: none'>";

            Object.values(samples).forEach((sample) => {
                list += `<li>${sample.name} (${sample.quantity})</li>`;
            });

            list += "</ul>";

            return list;
        },
        getStatusReason(exam) {
            console.log("status reason");

            const status = ["pendiente", "rechazado"];

            if (!status.includes(exam.currentStatus)) {
                return "";
            }

            const statusLog = exam.statusLog;

            if (!statusLog) {
                return "No status log";
            }

            const logs = Object.keys(statusLog);
            const lastLogId = logs[logs.length - 1];
            const lastLog = statusLog[lastLogId];

            if (!lastLog.reason) {
                return "Sin razón indicada";
            }

            return lastLog.reason;
        },

        hasDoctor(admission) {
            return (
                (admission.doctor && admission.doctor.nombre) ||
                admission.doctor.rut ||
                admission.doctor.contacto1 ||
                admission.doctor.contacto2
            );
        },
    },
};
</script>
