<template>
    <f7-page name="institutions" @page:beforeremove="removeSelectedInstitution">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title>Instituciones</f7-nav-title>
            <f7-nav-right>
                <f7-link icon-material="refresh" tooltip="Recargar Lista" @click="getInstitutions"></f7-link>
                <f7-link class="searchbar-enable" data-searchbar=".searchbar-demo" icon-material="search"></f7-link>
            </f7-nav-right>
            <f7-searchbar
                class="searchbar-demo"
                remove-diacritics
                expandable
                search-container=".search-list"
                search-in=".item-title"
                :disable-button="!$theme.aurora"
                placeholder="Buscar instituciones"
            ></f7-searchbar>
        </f7-navbar>

        <f7-list class="searchbar-not-found">
            <f7-list-item title="No hay coincidencias"></f7-list-item>
        </f7-list>
        <f7-list class="no-margin-top search-list searchbar-found" media-list>
            <f7-list-item
                v-for="institution in allInstitutions"
                :key="institution.id"
                :title="institution.name"
                link="#"
                :class="{ selected: viewingInstitution && viewingInstitution.id === institution.id }"
                @click="viewInstitutionDetail(institution)"
            >
                <div slot="header" v-if="institution.enabled === 0" class="text-color-red">Institución inactiva</div>
            </f7-list-item>
        </f7-list>

        <f7-fab position="right-bottom" slot="fixed" color="teal">
            <f7-icon material="add"></f7-icon>
            <f7-icon ios="f7:close" aurora="f7:close" md="material:close"></f7-icon>
            <f7-fab-buttons position="top">
                <f7-fab-button fab-close @click="goToNewInstitution()" label="Crear nueva institución">
                    <f7-icon material="playlist_add"></f7-icon>
                </f7-fab-button>
            </f7-fab-buttons>
        </f7-fab>
    </f7-page>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    computed: {
        ...mapState("institutions", ["allInstitutions", "viewingInstitution"]),
    },

    methods: {
        ...mapActions(["getAPIInfo"]),

        viewInstitutionDetail(institution) {
            this.$store.commit("institutions/set_viewing_institution", institution);

            this.$f7.views.main.router.navigate("/migration/mantenedores/institutions/details");
        },

        goToNewInstitution() {
            this.$store.commit("institutions/set_viewing_institution", null);

            this.$f7.views.main.router.navigate("/migration/mantenedores/institutions/create-or-edit", { reloadCurrent: true });
        },

        getInstitutions() {
            this.$f7.dialog.preloader("Cargando...");

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({
                        url: "institutions/all",
                        userToken: idToken,
                    });
                })
                .then((response) => {
                    const responseData = response.data;

                    if (responseData) {
                        const institutions = responseData.data;

                        this.$store.commit("institutions/set_all_institutions", institutions);
                    }

                    this.$f7.dialog.close();
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error);
                });
        },

        removeSelectedInstitution() {
            this.$store.commit("institutions/set_viewing_institution", null);
        },
    },

    mounted() {
        if (this.allInstitutions.length === 0) {
            this.getInstitutions();
        }
    },
};
</script>

<style scoped>
.selected {
    border-left: 7px solid #36b5aa;
    color: #2d968c;
    background-color: #f3f3f3;
}
</style>
