<template>
    <f7-page name="migration-admission-index">
        <f7-navbar>
            <f7-nav-title>Firma masiva</f7-nav-title>
            <f7-nav-right>
                <template>
                    <f7-button @click="clear(true)" outline class="margin-right" color="blue">
                        Limpiar
                    </f7-button>
                </template>
            </f7-nav-right>
        </f7-navbar>

        <f7-block v-if="activePlantillasCovid.length > 0">
            <label>
                <b>Seleccione plantilla Covid para firmar</b>
            </label>
            <f7-input type="select" @change="selectedPlantillaId = $event.target.value">
                <option value="">Seleccione...</option>
                <option v-for="plantilla in activePlantillasCovid" :key="plantilla.id" :value="plantilla.id">
                    {{ plantilla.name }}
                </option>
            </f7-input>
        </f7-block>
        <f7-block v-else>
            <p class="text-color-red">No hay plantillas para exámenes COVID, o todas las plantillas están inactivas</p>
        </f7-block>

        <f7-block class="display-flex align-items-center">
            <f7-checkbox :value="markStatusAsImpreso" @change="markStatusAsImpreso = $event.target.checked"></f7-checkbox>
            <span class="margin-left">Pasar los exámenes directamente a estado <b>Examen impreso</b></span>
        </f7-block>

        <f7-block v-if="activePlantillasCovid.length === 0">Hay {{ exams.length }} exámenes con estado En Laboratorio pendientes de firma.</f7-block>
        <f7-block v-else-if="exams.length > 0">
            <f7-button :disabled="selectedAdmissionExams.length === 0" fill raised @click="signSelectedExams" class="margin-bottom">
                Firmar masivamente
            </f7-button>

            <exams-table :exams="exams" :selected-exams="selectedAdmissionExams" @exam-selection-changed="handleExamSelectionChanged">
                <template #table-header>
                    <th>
                        <f7-checkbox @change="handleSelectAll($event.target.checked)"></f7-checkbox>
                    </th>
                    <th>Documento paciente</th>
                    <th>Paciente</th>
                    <th>Origen</th>
                    <th>Cod Exámen</th>
                    <th>Muestras</th>
                    <th>
                        Método
                        <f7-list class="no-margin">
                            <f7-list-input type="select" @change="changeGlobalProcessingMethod($event.target.value)">
                                <option v-for="method in processingMethodsCOVID" :key="method.id" :value="method.id" :selected="method.id === 25">
                                    {{ method.name }}
                                </option>
                            </f7-list-input>
                        </f7-list>
                    </th>
                    <th>Resultado</th>
                    <th></th>
                </template>

                <template #table-row="{ admissionExam, f }">
                    <tr>
                        <td>
                            <f7-checkbox
                                :checked="f.examIsChecked(admissionExam.id)"
                                :disabled="
                                    !f.examIsCheckeable(admissionExam, examStatusesData.en_laboratorio) ||
                                        signedExams.find((e) => e == admissionExam.id) != undefined
                                "
                                @change="f.handleCheckExam($event.target.checked, admissionExam.id)"
                            ></f7-checkbox>
                        </td>

                        <td>{{ f.patientDocumentInfo(admissionExam) }}</td>
                        <td>{{ f.patientFullName(admissionExam) }}</td>

                        <td>{{ f.getOrigin(admissionExam) }}</td>

                        <td>{{ admissionExam.exam.code }}</td>
                        <td>{{ f.admissionExamSamplesNames(admissionExam) }} {{ f.admissionExamSamplesCodes(admissionExam) }}</td>

                        <td>
                            <f7-list class="no-margin">
                                <f7-list-input type="select" @change="processingMethodChanged(admissionExam.id, $event.target.value)">
                                    <option v-for="method in processingMethodsCOVID" :key="method.id" :value="method.id" :selected="examsResults[admissionExam.id].method_id == method.id">
                                        {{ method.name }}
                                    </option>
                                </f7-list-input>
                            </f7-list>
                        </td>

                        <td>
                            <f7-list class="no-margin">
                                <f7-list-input type="select" @change="resultChanged(admissionExam.id, $event.target.value)">
                                    <option value="Detectado">Detectado</option>
                                    <option value="No detectado">No detectado</option>
                                    <option value="No concluyente">No concluyente</option>
                                    <option value="Positivo">Positivo</option>
                                    <option value="Negativo" selected>Negativo</option>
                                </f7-list-input>
                            </f7-list>
                        </td>
                        <td>
                            <f7-button
                                small
                                fill
                                @click="signSingleExam(admissionExam)"
                                :disabled="signedExams.find((e) => e == admissionExam.id) != undefined"
                            >
                                Firmar
                            </f7-button>
                        </td>
                    </tr>
                </template>
            </exams-table>
        </f7-block>
        <f7-block v-else>
            No hay exámenes con estado En Laboratorio pendientes de firma
        </f7-block>
    </f7-page>
</template>

<script>
import { mapActions } from "vuex";
import { examStatuses } from "@/app/constants";
import ExamsTable from "@/app/components-migration/exams-table.vue";

export default {
    data() {
        return {
            examStatusesData: examStatuses,

            exams: [],
            selectedAdmissionExams: [],

            processingMethodsCOVID: [],

            examsResults: {},

            signedExams: [],

            plantillasCovid: {},

            selectedPlantillaId: "",

            markStatusAsImpreso: false,
        };
    },

    components: {
        "exams-table": ExamsTable,
    },

    async created() {
        await this.fetchPlantillasCOVID();

        this.fetchCOVIDMethods();

        this.fetchCOVIDAdmissionExams();
    },

    computed: {
        activePlantillasCovid() {
            return Object.values(this.plantillasCovid).filter((p) => !p.inactive);
        },
    },

    methods: {
        ...mapActions(["getAPIInfo", "createNewInfo"]),

        signSingleExam(admissionExam) {
            const requestData = {};
            requestData[admissionExam.id] = this.examsResults[admissionExam.id];

            this.sendFirmaMasivaRequest(requestData);
        },
        signSelectedExams() {
            const requestData = {};

            this.selectedAdmissionExams.forEach((selectedExamId) => {
                if (this.examsResults[selectedExamId]) {
                    requestData[selectedExamId] = this.examsResults[selectedExamId];
                }
            });

            this.sendFirmaMasivaRequest(requestData);
        },

        sendFirmaMasivaRequest(requestData) {
            if (!this.selectedPlantillaId) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Debes seleccionar una plantilla para firmar estos exámenes");
                return;
            }

            const request = {
                exams: requestData,
                plantilla_id: this.selectedPlantillaId,
                status_impreso: this.markStatusAsImpreso,
            };

            this.$f7.dialog.preloader("Firmando exámenes...");

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.createNewInfo({
                        url: "admission_exams/firma-masiva",
                        data: request,
                        userToken: idToken,
                    });
                })
                .then((response) => {
                    this.signedExams = [...this.signedExams, ...response.data.signedExams];

                    this.selectedAdmissionExams = [];

                    this.$f7.dialog.close();
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error);
                });
        },

        changeGlobalProcessingMethod(methodId) {
            Object.keys(this.examsResults).forEach((admissionExamId) => {
                this.processingMethodChanged(admissionExamId, methodId);
            });
        },
        processingMethodChanged(admissionExamId, methodId) {
            if (!this.examsResults[admissionExamId]) {
                this.$set(this.examsResults, admissionExamId, {});
            }

            this.$set(this.examsResults[admissionExamId], "method_id", Number(methodId));
        },
        resultChanged(admissionExamId, result) {
            if (!this.examsResults[admissionExamId]) {
                this.$set(this.examsResults, admissionExamId, {});
            }

            this.$set(this.examsResults[admissionExamId], "result", result);
        },

        handleExamSelectionChanged(payload) {
            const { wasChecked, admissionExamId } = payload;

            if (wasChecked) {
                this.selectedAdmissionExams.push(admissionExamId);
            } else {
                const index = this.selectedAdmissionExams.findIndex((selectedExams) => selectedExams === admissionExamId);
                this.selectedAdmissionExams.splice(index, 1);
            }
        },

        handleSelectAll(checked) {
            if (checked) {
                this.selectedAdmissionExams = this.exams.map((e) => e.id);
            } else {
                this.selectedAdmissionExams = [];
            }
        },

        async fetchPlantillasCOVID() {
            const covidExamId = 368;
            const plantillasSnapshot = await this.$firebase
                .database()
                .ref("/results_plantillas")
                .orderByChild(`exams/${covidExamId}`)
                .equalTo(true)
                .once("value");

            const plantillasCovid = plantillasSnapshot.val() || {};

            this.plantillasCovid = Object.values(plantillasCovid);
        },

        fetchCOVIDMethods() {
            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({
                        url: "exams/COVID-19",
                        userToken: idToken,
                    });
                })
                .then((response) => {
                    const { data } = response;

                    console.log(data);

                    this.processingMethodsCOVID = data.data.exam_processing_methods;
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error);
                });
        },

        fetchCOVIDAdmissionExams() {
            this.$f7.dialog.preloader("Buscando examenes COVID en laboratorio no firmados...");

            const options = {
                statuses: 2,
                sections: 2,
                exams: 368,
                rows_per_page: 1000,
            };

            const queryString = new URLSearchParams(options).toString();

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({
                        url: "admission_exams?" + queryString,
                        userToken: idToken,
                    });
                })
                .then((response) => {
                    const { data } = response;

                    this.exams = data.data;

                    this.exams.forEach((admissionExam) => {

                        this.$set(this.examsResults, admissionExam.id, {
                            method_id: 25,
                            result: "Negativo"
                        });
                    });

                    this.$f7.dialog.close();
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error);
                });
        },

        clear(confirm) {
            const _clear = () => {
                console.log("clearing...");

                this.exams = this.exams.filter((e) => !this.signedExams.find((se) => se == e.id));

                this.signedExams = [];
                this.selectedAdmissionExams = [];
            };

            if (confirm) {
                this.$f7.dialog.confirm("¿Está seguro?", () => _clear());
            } else {
                _clear();
            }
        },
    },
};
</script>
