import moment from "moment";
moment.locale("es");

export function formatString(string) {
    if (!string) return "";
    if (typeof string !== "string") return "";

    const anySpaceRegex = / /g;
    const noAccentsRegex = /[\u0300-\u036f]/g;

    return string
        .toLowerCase()
        .trim()
        .replaceAll(anySpaceRegex, "")
        .normalize("NFD")
        .replace(noAccentsRegex, "");
}

/**
 * Date Helpers
 */

export function _patientAge(date, format) {
    const invalidDateText = "Fecha de nacimiento inválida";

    if (!date || date === "") {
        return "0";
    } else if (date.length !== 10) {
        return invalidDateText;
    }

    const patientAge = formatDate(date, format);

    if (patientAge === "invalid") {
        return invalidDateText;
    }

    if (patientAge.includes("NaN") || patientAge.includes("-") || !patientAge || patientAge < 0) {
        return invalidDateText;
    }

    return patientAge;
}

function formatDate(date, format) {
    if (!format) format = "DD/MM/YYYY";

    date = moment(date, format);

    const years = moment().diff(date, "years");

    const MAX_AGE = 150;

    if (years > MAX_AGE) {
        return "invalid";
    }

    if (years >= 2) {
        return years + " años";
    } else {
        const now = moment();
        const diffDuration = moment.duration(now.diff(date));

        const years = diffDuration.years();
        let months = diffDuration.months();
        const days = diffDuration.days();

        let formattedDate = "";

        if (years) {
            months = months + years * 12;
        }

        if (months) {
            formattedDate = formattedDate + `${months} meses `;
        }

        if (days || days === 0) {
            formattedDate = formattedDate + `${days} días`;
        }

        return formattedDate;
    }
}

/**
 * Name helpers
 */

export function _formatName(names, firstLastName, secondLastName) {
    let namesArray = names.split(" ");
    namesArray = namesArray.map((name) => {
        return capitalizeText(name);
    });
    const lastNames = `${capitalizeText(firstLastName)} ${capitalizeText(secondLastName)}`;
    if (!namesArray[1]) {
        return `${namesArray[0]} ${lastNames}`;
    } else {
        return `${namesArray[0]} ${namesArray[1]} ${lastNames}`;
    }
}

function capitalizeText(text) {
    if (!text) {
        return "";
    }
    return text.charAt(0).toUpperCase() + text.slice(1);
}

/**
 * Section helpers
 */

export function _getSectionById(id) {
    switch (id) {
        case "1":
        case 1:
            return "Anatomía patológica";

        case "2":
        case 2:
            return "Biología molecular";

        case "3":
        case 3:
            return "Citometría de flujo";

        case "4":
        case 4:
            return "Inmunología";

        default:
            return "Sección no identificada";
    }
}

/**
 * Exam Status helpers
 */

export function _getStatusText(status) {
    switch (status) {
        case "admitido":
            return "Admitido";

        case "pendiente":
            return "Pendiente";

        case "rechazado":
            return "Rechazado";

        case "en_lab":
            return "En Laboratorio";

        default:
            return "Estado no identificado";
    }
}

/**
 * Document Type helpers
 */
export function _getDocumentType(id) {
    switch (id) {
        case 1:
        case "1":
            return "RUN";

        case 2:
        case "2":
            return "PASAPORTE";

        case 3:
        case "3":
            return "DNI";

        case 4:
        case "4":
            return "OTRO";

        default:
            return "Tipo de documento no identificado";
    }
}
