<template>
    <qrcode-vue :value="qrValue" :size="size" />
</template>

<script>
import QRCodeVue from "qrcode.vue";
import { mapState } from "vuex";

export default {
    components: { "qrcode-vue": QRCodeVue },

    data() {
        return {
            qrValue: "https://portal.inmunocel.com",
        };
    },

    props: {
        size: Number,
        admissionExamId: Number,
        examResult: Array,
        patientId: Number,
        defaults: Object,
    },

    computed: {
        ...mapState(["env"]),

        ...mapState("plantillas", ["selectedReportType"]),
    },

    watch: {
        selectedReportType: {
            handler: async function () {
                await this.getQRCode();
            }
        },

        examResult: {
            async handler() {
                await this.getQRCode();
            }
        },
    },

    async mounted() {
        await this.getQRCode();
    },

    methods: {
        async getQRCode() {
            const baseUrl = this.env !== "production" ? "https://verify-inmunocel-dev.web.app" : "https://verify.inmunocel.com";

            if (!this.examResult || this.examResult.length === 0) {
                this.qrValue = "https://portal.inmunocel.com";
                return;
            }

            if (this.selectedReportType && this.isValidNumber(this.admissionExamId) && this.isValidNumber(this.patientId)) {
                try {
                    const getQRCode = this.$firebase.functions().httpsCallable("getQRCode");

                    const response = await getQRCode({
                        admissionExamId: this.admissionExamId,
                        patientId: this.patientId,
                        reportType: this.selectedReportType,
                    });

                    this.qrValue = response && response.data ? `${baseUrl}/?code=${response.data}` : "https://portal.inmunocel.com"
                } catch (error) {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Hubo un error al obtener el código QR", error.message);
                }
            }
        },

        isValidNumber(n) {
            return Number.isInteger(n) && n > 0;
        },
    },
};
</script>
