<template>
    <div>
        <div class="text-align-center">
            <div>
                <img class="signature" :src="cmio.signatureUrl" alt="Firma Inmunocel" />
            </div>
            <span>
                {{ cmio.name }}
                <br />
                Médico director
            </span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            cmio: {
                name: "Dr. Alejandro Berkovits",
                signatureUrl: "../../../../static/img/cmio_signature.jpg",
            },
        };
    },
};
</script>

<style scoped>
.signature {
    width: 200px;
    height: 100px;
}
</style>
