<template>
    <f7-panel :opened="opened" @panel:closed="$emit('side-panel-closed')" right resizable style="width: 400px">
        <f7-view>
            <f7-page>
                <f7-block v-if="plantilla.inactive" class="margin-vertical-half">
                    <f7c-alert type="danger">
                        Esta plantilla se encuentra inactiva
                    </f7c-alert>
                </f7-block>

                <template v-if="isEditor">
                    <div v-if="!plantilla.isUsed">
                        <!-- Save -->
                        <f7-button fill color="blue" class="margin display-flex" @click="$emit('save-plantilla')">
                            <f7-icon material="save" class="margin-right-half"></f7-icon>
                            Guardar
                        </f7-button>
                    </div>
                    <div v-else>
                        <f7c-alert type="warning" class="margin">
                            Esta plantilla ya fue usada para cargar resultados o firmar exámenes. Para poder editarla primero debes duplicarla
                        </f7c-alert>
                        <f7-button small fill class="margin" @click="duplicatePlantilla">Duplicar</f7-button>
                    </div>
                    <!-- Inactivate -->
                    <template v-if="plantilla.id">
                        <f7-button v-if="!plantilla.inactive" class="margin" outline small color="red" @click="toggleInactive(plantilla, true)">
                            Inactivar
                        </f7-button>
                        <f7-button v-else outline small class="margin" color="green" @click="toggleInactive(plantilla, null)">Activar</f7-button>
                    </template>
                </template>

                <f7c-alert v-if="!isEditor && !userHasSignature" type="danger" class="margin-top margin-horizontal">
                    No tienes firma autorizada. Para poder firmar exámenes debes autorizar tu firma
                </f7c-alert>

                <f7c-alert v-if="plantillaWasChanged" type="warning" class="margin-top margin-horizontal">
                    La plantilla fue modificada, cuando cambies el estado del exámen se guardará automáticamente como una plantilla copia
                </f7c-alert>

                <template v-if="isEditor">
                    <f7-block class="margin-bottom-half">
                        <h3 class="no-margin">Información de la plantilla</h3>
                    </f7-block>
                    <f7-list no-hairlines class="margin-top-half">
                        <f7-list-input
                            required
                            info="Requerido"
                            label="Nombre de la plantilla"
                            type="text"
                            @change="inputChanged('name', $event.target.value)"
                            :value="plantilla.name"
                        ></f7-list-input>
                        <f7-list-input
                            info="Opcional"
                            label="Descripción de la plantilla"
                            type="text"
                            @change="inputChanged('description', $event.target.value)"
                            :value="plantilla.description"
                        ></f7-list-input>
                    </f7-list>
                </template>
                <f7-block v-else class="margin-bottom-half">
                    <div>Usando plantilla</div>
                    <h2 class="no-margin">{{ plantilla.name }}</h2>
                </f7-block>

                <f7-block class="margin-bottom margin-top">
                    <h3 class="no-margin">Exámen asociado</h3>
                    <p v-if="!Object.keys(plantilla.exams || {}).length">Esta plantilla no tiene exámen asociado.</p>
                    <div v-else class="margin-top-half margin-bottom">
                        <f7-chip
                            v-for="exam in associatedExams"
                            :key="exam.id"
                            :text="exam.name"
                            color="primary"
                            class="margin-right-half"
                            :deleteable="isEditor"
                            @click="handleRemoveAssociatedExam(exam)"
                        ></f7-chip>
                    </div>
                    <template v-if="isEditor">
                        <f7-button v-if="Object.keys(plantilla.exams || {}).length === 0" @click="associatedExamsPopupOpened = true" outline>
                            Asociar exámenes a esta plantilla
                        </f7-button>
                    </template>
                </f7-block>

                <f7-card outline v-if="!isEditor" class="margin-horizontal">
                    <f7-card-content class="no-padding-bottom padding-top-half">
                        <h3>Tipo de informe</h3>
                        <f7-segmented tag="p">
                            <f7-button
                                outline
                                @click="changeExamReportType(reportType.name)"
                                :active="reportType.name === selectedReportType"
                                v-for="reportType in reportTypesData"
                                :key="reportType.id"
                            >
                                {{ reportType.shortName }}
                            </f7-button>
                        </f7-segmented>

                        <h3 class="no-margin">Acciones</h3>

                        <div class="margin-bottom-half">
                            <div class="margin-vertical-half">
                                <b>Estado actual ({{ selectedReportType }}):</b>
                                {{ statusNameById(currentStatusIdByReportType) }}
                            </div>
                            <f7-button
                                v-if="hasPriv('cargar_resultado')"
                                @click="
                                    $emit('exam-action-triggered', {
                                        action: 'resultado-cargado',
                                        revert: checkExamIsInStatus(examStatusesData.resultado_cargado),
                                    })
                                "
                                fill
                                class="margin-vertical"
                                :color="currentStatusIdByReportType >= examStatusesData.resultado_cargado ? 'blue' : 'primary'"
                                :disabled="!shouldAllowResultadoCargado"
                            >
                                {{ currentStatusIdByReportType >= examStatusesData.resultado_cargado ? "Modificar resultado" : "Resultado cargado" }}
                            </f7-button>

                            <f7-button
                                v-if="hasPriv('confirmar')"
                                @click="
                                    $emit('exam-action-triggered', {
                                        action: 'confirmado',
                                        revert: checkExamIsInStatus(examStatusesData.examen_confirmado),
                                    })
                                "
                                fill
                                class="margin-vertical"
                                :color="currentStatusIdByReportType >= examStatusesData.examen_confirmado ? 'red' : 'primary'"
                                :disabled="!shouldAllowConfirmado"
                            >
                                {{ currentStatusIdByReportType >= examStatusesData.examen_confirmado ? "Revertir confirmado" : "Confirmar" }}
                            </f7-button>

                            <template v-if="userHasSignature">
                                <f7-button
                                    v-if="hasPriv('firmar')"
                                    @click="
                                        $emit('exam-action-triggered', {
                                            action: 'firmado',
                                            revert: checkExamIsInStatus(examStatusesData.examen_firmado),
                                        })
                                    "
                                    fill
                                    class="margin-vertical"
                                    :color="currentStatusIdByReportType >= examStatusesData.examen_firmado ? 'red' : 'primary'"
                                    :disabled="!shouldAllowFirmado"
                                >
                                    {{ currentStatusIdByReportType >= examStatusesData.examen_firmado ? "Revertir firma" : "Firmar" }}
                                </f7-button>

                                <f7-button
                                    @click="
                                        $emit('exam-action-triggered', {
                                            action: 'imprimir',
                                            revert: checkExamIsInStatus(examStatusesData.examen_impreso),
                                        })
                                    "
                                    fill
                                    class="margin-vertical"
                                    :color="currentStatusIdByReportType >= examStatusesData.examen_impreso ? 'red' : 'primary'"
                                    :disabled="!shouldAllowImpreso"
                                >
                                    {{ currentStatusIdByReportType >= examStatusesData.examen_impreso ? "Revertir impreso" : "Imprimir" }}
                                </f7-button>
                            </template>

                            <f7-button
                                v-if="currentStatusIdByReportType >= examStatusesData.examen_impreso"
                                @click="$emit('exam-action-triggered', { action: 'print-pdf' })"
                                small
                            >
                                Imprimir nuevamente
                            </f7-button>
                        </div>

                        <div class="padding-top-half">
                            <f7-button @click="examStatusLogOpened = true" outline class="display-flex margin-vertical" icon-material="reorder">
                                <span class="margin-left-half">Ver flujograma del examen</span>
                            </f7-button>
                        </div>
                    </f7-card-content>
                </f7-card>

                <f7-block class="margin-bottom-half">
                    <h3 class="no-margin">Añadir bloques</h3>
                </f7-block>
                <template v-if="isEditor || currentStatusIdByReportType < examStatusesData.examen_impreso">
                    <div class="margin-horizontal-half">
                        <f7-searchbar
                            class="searchbar-blocks"
                            remove-diacritics
                            search-container=".search-list"
                            search-in=".item-title, .item-subtitle"
                            placeholder="Buscar bloques"
                            :backdrop="false"
                            :no-shadow="false"
                        ></f7-searchbar>
                    </div>

                    <f7c-alert
                        type="info"
                        v-if="!isEditor && currentStatusIdByReportType > examStatusesData.resultado_cargado"
                        class="margin-horizontal margin-top no-margin-bottom"
                    >
                        Los bloques de resultados y bloques de datos (texto, tabla, imágenes, etc) solo pueden utilizarse si el examen está en estado
                        <b>En Laboratorio</b>
                        ó
                        <b>Resultado cargado</b>
                    </f7c-alert>

                    <f7-list class="searchbar-not-found">
                        <f7-list-item title="No hay coincidencias"></f7-list-item>
                    </f7-list>
                    <f7-list no-hairlines media-list class="margin-top-half search-list searchbar-found">
                        <f7-list-item media-item v-for="block in availableBlocks" :key="block.id">
                            <div slot="title">
                                <b>{{ block.displayName }}</b>
                            </div>
                            <div slot="subtitle">{{ block.description }}</div>
                            <div slot="footer" v-if="maxBlocksAllowed[block.code]">Máx. {{ maxBlocksAllowed[block.code] }}</div>
                            <div slot="after">
                                <f7-button
                                    :disabled="shouldDisableAddBlock(block.code)"
                                    small
                                    fill
                                    icon-material="add"
                                    @click="$emit('block-added', { blockCode: block.code })"
                                ></f7-button>
                            </div>
                        </f7-list-item>
                    </f7-list>
                </template>
                <f7c-alert type="info" class="margin" v-else>
                    Una vez el examen se encuentra en estado
                    <b>Examen impreso</b>
                    no puedes añadir más bloques o reposicionar estos.
                </f7c-alert>

                <f7-block v-if="isEditor || currentStatusIdByReportType <= examStatusesData.examen_firmado">
                    <f7-card outline class="border-color-red">
                        <f7-card-content>
                            <f7-row>
                                <f7-col width="60">
                                    <h3 class="no-margin">Eliminar página</h3>
                                </f7-col>
                                <f7-col width="20">
                                    <f7-input
                                        type="number"
                                        min="1"
                                        :max="plantilla.pages.length"
                                        @change="pageToDelete = $event.target.value"
                                        :value="pageToDelete"
                                    ></f7-input>
                                </f7-col>
                                <f7-col width="20">
                                    <f7-button fill small color="red" @click="$emit('page-deleted', { pageNumber: Number(pageToDelete) })">
                                        Ok
                                    </f7-button>
                                </f7-col>
                            </f7-row>
                        </f7-card-content>
                    </f7-card>
                </f7-block>

                <select-exam-popup
                    :opened="associatedExamsPopupOpened"
                    :section="plantilla.section"
                    :selected-exams="plantilla.exams || {}"
                    @exam-selected="handleExamAssociation"
                    @popup-closed="associatedExamsPopupOpened = false"
                ></select-exam-popup>

                <exam-status-log-popup
                    :show-change-state-buttons="false"
                    :admission-exam="viewingAdmissionExam"
                    :opened="examStatusLogOpened"
                    @popup-closed="examStatusLogOpened = false"
                ></exam-status-log-popup>
            </f7-page>
        </f7-view>

        <select-plantilla-popup
            :opened="showSelectPlantilla"
            @popup-closed="handleSelectPlantillaClosed"
            :selected-exam="viewingAdmissionExam"
            @plantilla-selected="handlePlantillaSelected"
        ></select-plantilla-popup>
    </f7-panel>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { examStatuses, examStatusesIds, reportTypes } from "@/app/constants";
import SelectExamPopup from "@/app/components-migration/plantillas/select-exam-popup.vue";
import SelectPlantillaPopup from "@/app/carga-resultados/components/select-plantilla-popup.vue";
import ExamStatusLogPopup from "@/app/components-migration/exam-status-log-popup.vue";

export default {
    props: {
        isEditor: Boolean,
        opened: Boolean,
        blocks: Array,
        plantilla: Object,
        admissionExam: Object,
        plantillaWasChanged: Boolean,
        userHasSignature: Boolean,
    },

    components: {
        "select-exam-popup": SelectExamPopup,
        "exam-status-log-popup": ExamStatusLogPopup,
        "select-plantilla-popup": SelectPlantillaPopup,
    },

    data() {
        return {
            reportTypesData: reportTypes,
            examStatusesData: examStatuses,

            associatedExamsPopupOpened: false,
            examStatusLogOpened: false,

            maxBlocksAllowed: {
                "patient-info-block": 1,
                "exam-info-block": 1,
                "resultado-collection-block": 0,
                "processing-method-block": 1,
                "report-type-block": 1,
                "observations-block": 1,
            },

            pageToDelete: 1,

            showSelectPlantilla: false,

            originalReportType: null,
            originalPlantillaId: null,
            plantillaWasSelected: false,
        };
    },

    computed: {
        ...mapState(["misprivx"]),

        ...mapState("plantillas", ["viewingAdmissionExam", "examsPerCollectionBlock", "selectedReportType"]),
        ...mapGetters("plantillas", ["currentStatusIdByReportType", "usedPlantillaIdByReportType"]),

        ...mapGetters(["getExam"]),

        allBlocks() {
            return this.blocks.map((block, i) => {
                return {
                    ...block,
                    id: i + 1,
                };
            });
        },

        availableBlocks() {
            const dataBlocks = [
                "resultado-cualitativo-block",
                "resultado-cuantitativo-block",
                "resultado-free-text-block",
                "resultado-collection-block",
                "processing-method-block",
                "report-type-block",
                "observations-block",
                "text-block",
                "table-block",
                "image-block",
            ];

            if (this.isEditor || this.currentStatusIdByReportType <= examStatuses.resultado_cargado) {
                return this.allBlocks;
            } else {
                return this.allBlocks.filter((b) => !dataBlocks.includes(b.code));
            }
        },

        associatedExams() {
            if (!this.plantilla.exams || !Object.keys(this.plantilla.exams || {}).length) return [];

            const exams = [];

            Object.keys(this.plantilla.exams).forEach((examId) => {
                const exam = this.getExam(examId);
                exams.push(exam);
            });

            return exams;
        },

        blocksCount() {
            const plantillaBlocks = Object.values(this.plantilla.blocks || {});

            const patientInfoBlocksCount = plantillaBlocks.filter((b) => b.block.code === "patient-info-block").length;
            const examInfoBlocksCount = plantillaBlocks.filter((b) => b.block.code === "exam-info-block").length;
            const resultadoCollectionBlocksCount = plantillaBlocks.filter((b) => b.block.code === "resultado-collection-block").length;
            const processingMethodBlocksCount = plantillaBlocks.filter((b) => b.block.code === "processing-method-block").length;
            const reportTypeBlocksCount = plantillaBlocks.filter((b) => b.block.code === "report-type-block").length;
            const observationsBlocksCount = plantillaBlocks.filter((b) => b.block.code === "observations-block").length;

            return {
                "patient-info-block": patientInfoBlocksCount,
                "exam-info-block": examInfoBlocksCount,
                "resultado-collection-block": resultadoCollectionBlocksCount,
                "processing-method-block": processingMethodBlocksCount,
                "report-type-block": reportTypeBlocksCount,
                "observations-block": observationsBlocksCount,
            };
        },

        shouldAllowResultadoCargado() {
            return (
                this.currentStatusIdByReportType === this.examStatusesData.en_laboratorio ||
                this.currentStatusIdByReportType === this.examStatusesData.resultado_cargado
            );
        },
        shouldAllowConfirmado() {
            return (
                this.currentStatusIdByReportType === this.examStatusesData.resultado_cargado ||
                this.currentStatusIdByReportType === this.examStatusesData.examen_confirmado
            );
        },
        shouldAllowFirmado() {
            return (
                this.currentStatusIdByReportType === this.examStatusesData.examen_confirmado ||
                this.currentStatusIdByReportType === this.examStatusesData.examen_firmado
            );
        },
        shouldAllowImpreso() {
            return (
                this.currentStatusIdByReportType === this.examStatusesData.examen_firmado ||
                this.currentStatusIdByReportType === this.examStatusesData.examen_impreso
            );
        },
    },

    watch: {
        associatedExams: {
            deep: true,
            handler() {
                const associatedExamsCount = this.associatedExams[0]?.associations.length || 0;

                const requiredCollectionBlocks = Math.ceil(associatedExamsCount / this.examsPerCollectionBlock);

                this.maxBlocksAllowed["resultado-collection-block"] = requiredCollectionBlocks;
            },
        },
    },

    created() {
        this.originalReportType = this.selectedReportType;
        this.originalPlantillaId = this.usedPlantillaIdByReportType;
    },

    methods: {
        async changeExamReportType(reportType) {
            this.plantillaWasSelected = false;

            this.$store.commit("plantillas/set_selected_report_type", reportType);

            if (this.usedPlantillaIdByReportType) {
                const plantillaSnapshot = await this.$firebase.database().ref(`/results_plantillas/${this.usedPlantillaIdByReportType}`).once("value");
                const plantilla = plantillaSnapshot.val();

                if (!plantilla) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("No se encontró la plantilla asociada al reporte seleccionado.");

                    this.$store.commit("plantillas/set_selected_report_type", this.originalReportType);
                } else {
                    this.$store.commit("plantillas/set_viewing_plantilla", plantilla);

                    this.$store.commit("plantillas/trigger_reload");
                }
            } else {
                this.showSelectPlantilla = true;
            }
        },

        handlePlantillaSelected(payload) {
            this.plantillaWasSelected = true;

            const { plantilla } = payload;

            if (this.selectedReportType) {
                const reportTypeBlockId = Object.keys(plantilla.blocks || {}).find((blockId) => plantilla.blocks[blockId].block.code === "report-type-block");

                if (!plantilla.blocks[reportTypeBlockId].defaults) this.$set(plantilla.blocks[reportTypeBlockId], "defaults", {});
                this.$set(plantilla.blocks[reportTypeBlockId].defaults, "reportType", this.selectedReportType);
            }
            
            this.showSelectPlantilla = false;

            this.$store.commit("plantillas/set_viewing_plantilla", plantilla);

            this.$store.commit("plantillas/trigger_reload");
        },
        handleSelectPlantillaClosed() {
            if (!this.plantillaWasSelected) {
                this.$store.commit("plantillas/set_selected_report_type", this.originalReportType);
            }

            this.showSelectPlantilla = false;
        },

        statusNameById(id) {
            return examStatusesIds[id] || "Sin estado identificado";
        },

        hasPriv(priv) {
            const examStatusPrivs = this.misprivx.change_exam_status;

            if (!examStatusPrivs) return false;

            const hasPriv = examStatusPrivs[priv] || false;

            return hasPriv;
        },

        duplicatePlantilla() {
            this.$emit("plantilla-duplicated");
        },

        inputChanged(input, value) {
            this.$emit("info-changed", { input, value });
        },

        handleRemoveAssociatedExam(exam) {
            if (!this.isEditor) {
                return;
            }

            this.$f7.dialog.confirm(`Se eliminará el exámen <b>${exam.name}</b> asociado a esta plantilla.`, "¿Estás seguro?", () => {
                this.$emit("associated-exam-changed", {
                    actionType: "remove",
                    examId: exam.id,
                });
            });
        },

        handleExamAssociation(exam) {
            if (!this.plantilla.exams) {
                this.$emit("info-changed", { input: "exams", value: {} });
            }

            if (!this.plantilla.exams[exam.id]) {
                this.$emit("associated-exam-changed", {
                    actionType: "add",
                    examId: exam.id,
                });

                this.associatedExamsPopupOpened = false;
            } else {
                this.$emit("associated-exam-changed", {
                    actionType: "remove",
                    examId: exam.id,
                });
            }
        },

        checkExamIsInStatus(statusId) {
            if (!this.viewingAdmissionExam) return false;

            return this.currentStatusIdByReportType === statusId;
        },

        shouldDisableAddBlock(blockCode) {
            if (!this.blocksCount.hasOwnProperty(blockCode)) {
                return false;
            }

            return this.blocksCount[blockCode] >= this.maxBlocksAllowed[blockCode];
        },

        toggleInactive(plantilla, inactive) {
            this.$f7.dialog.confirm("¿Está seguro?", async () => {
                const actionText = inactive ? "Inactivando" : "Activando";

                this.$f7.dialog.preloader(`${actionText} plantilla...`);

                let plantillaCopy = { ...plantilla };

                plantillaCopy.inactive = inactive;

                try {
                    await this.$firebase
                        .database()
                        .ref(`/results_plantillas/${plantillaCopy.id}`)
                        .set(plantillaCopy);

                    this.$store.commit("plantillas/add_plantilla", plantillaCopy);

                    plantilla.inactive = inactive;

                    console.log("debug", { plantillaPorParametro: plantilla, thisPlantilla: this.plantilla });

                    this.$f7.dialog.close();
                } catch (error) {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Error al crear plantilla", error);
                }
            });
        },
    },
};
</script>
