<template>
    <div>
        <table class="table table-sm table-bordered margin-top">
            <thead>
                <tr>
                    <th v-if="selectable">
                        <f7-checkbox
                            :checked="areAllExamsChecked"
                            :disabled="examsCheckeableCount === 0"
                            @change="handleSelectAllExams($event.target.checked)"
                        ></f7-checkbox>
                    </th>
                    <th>CB Muestra</th>
                    <th scope="col">Cod Examen</th>
                    <th scope="col">Examen</th>
                    <th scope="col">Sección</th>
                    <th scope="col">Muestras</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Fecha cargado</th>
                    <template v-if="!admissionView">
                        <th scope="col">Paciente</th>
                        <th scope="col">OT</th>
                    </template>
                    <th scope="col" v-if="buttons.length">Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="exam in exams" :key="exam.admExamId" :class="examRowClass(exam)">
                    <td v-if="selectable">
                        <f7-checkbox
                            :checked="examIsChecked(exam)"
                            :disabled="!examIsCheckeable(exam)"
                            @change="handleSelectExam($event.target.checked, exam)"
                        ></f7-checkbox>
                    </td>
                    <td class="text-align-center">{{ examSampleCode(exam) }}</td>
                    <th scope="row">{{ exam.externalId }}</th>
                    <td>
                        <div class="text-align-center">
                            {{ exam.name }}
                        </div>
                    </td>
                    <td>
                        <div class="text-align-center">
                            {{ getSectionById(exam.section_id) }}
                        </div>
                    </td>
                    <td>
                        <div class="text-align-center">
                            <f7-link
                                v-if="admissionView && Object.keys(exam.samples || {}).length === 0"
                                icon-material="add"
                                icon-size="14"
                                @click="showSamples(exam)"
                            >
                                Añadir
                            </f7-link>

                            <template v-else>
                                <f7-link @click="showSamples(exam)" v-if="admissionView">
                                    {{ getSampleText(exam) }}
                                </f7-link>
                                <span v-else>
                                    {{ getSampleText(exam) }}
                                </span>
                            </template>
                        </div>
                    </td>
                    <td>
                        <div class="text-align-center">
                            {{ getStatusText(exam.currentStatus) }}
                        </div>
                    </td>
                    <td>
                        <div class="text-align-center">
                            {{ $moment.unix(exam.fechaCargado).format("DD/MM/YYYY HH:MM") }}
                        </div>
                    </td>
                    <template v-if="!admissionView">
                        <td>
                            <b>{{ patientName(exam.patient) }}</b>
                            <br />
                            {{ patientTipoIdentificador(exam.patient) }}:
                            {{ patientIdentificador(exam.patient) }}
                        </td>
                        <td>
                            {{ exam.ot }}
                        </td>
                    </template>
                    <td v-if="buttons.length">
                        <div class="display-flex justify-content-center align-items-center">
                            <template v-if="buttons.includes('reject') && misprivx.mark_exam_rejected && misprivx.modify_ot">
                                <change-state-button
                                    state="rejected"
                                    :exam="exam"
                                    :is-reason-mandatory="isReasonMandatory"
                                    @status-changed="handleExamStatusChanged"
                                ></change-state-button>
                            </template>

                            <template v-if="buttons.includes('pending') && misprivx.mark_exam_pending && misprivx.modify_ot">
                                <change-state-button
                                    state="pending"
                                    :exam="exam"
                                    @status-changed="handleExamStatusChanged"
                                    :use-default-reason="false"
                                ></change-state-button>
                            </template>

                            <f7-button
                                v-if="buttons.includes('patient')"
                                @click="viewPatient(exam)"
                                icon-material="person"
                                tooltip="Ver paciente asociado"
                            ></f7-button>

                            <f7-button
                                v-if="buttons.includes('flujograma')"
                                @click="openExamLogPopup(exam)"
                                icon-material="reorder"
                                tooltip="Flujograma"
                                :disabled="!exam.hasOwnProperty('statusLog')"
                            ></f7-button>

                            <f7-button
                                v-if="buttons.includes('delete') && misprivx.modify_ot"
                                @click="removeExam(exam)"
                                icon-material="delete"
                                tooltip="Eliminar examen"
                                color="red"
                            ></f7-button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <patient-more-info-popup
            readonly
            :patient="viewingExamPatient"
            :popup-opened="patientPopupOpened"
            @popup-closed="patientPopupOpened = false"
        ></patient-more-info-popup>

        <exam-log-popup
            :opened="showExamLogPopup"
            :exam="viewingExamLog"
            @popup-closed="showExamLogPopup = false"
            @exam-status-changed="handleExamStatusChanged"
        ></exam-log-popup>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { _getStatusText, _getSectionById } from "@/app/helpers";
import { format } from "rut.js";

import ExamLogPopup from "@/app/components/exam-log-popup.vue";
import PatientMoreInfoPopup from "@/app/components/patient-more-info-popup.vue";
import ChangeStateButton from "@/app/components/change-state-button.vue";

export default {
    props: {
        exams: Object,
        selectable: Boolean,
        selectedExams: Object,
        admissionView: Boolean,
        buttons: {
            type: Array,
            default() {
                return [];
            },
        },
        isReasonMandatory: Boolean,
    },

    components: {
        "exam-log-popup": ExamLogPopup,
        "patient-more-info-popup": PatientMoreInfoPopup,
        "change-state-button": ChangeStateButton,
    },

    data() {
        return {
            getStatusText: _getStatusText,
            getSectionById: _getSectionById,

            showExamLogPopup: false,
            viewingExamLog: {},
            viewingExamPatient: {},

            patientPopupOpened: false,
        };
    },

    computed: {
        ...mapState(["misprivx"]),

        examsCheckeableCount() {
            var count = 0;

            Object.keys(this.exams).forEach((examId) => {
                const exam = this.exams[examId];

                if (this.examIsCheckeable(exam)) {
                    count++;
                }
            });

            return count;
        },

        areAllExamsChecked() {
            if (this.examsCheckeableCount === 0) {
                return false;
            }

            return this.examsCheckeableCount === Object.keys(this.selectedExams).length;
        },
    },

    methods: {
        showSamples(exam) {
            this.$emit("show-samples-clicked", exam);
        },

        removeExam(exam) {
            this.$emit("exam-deleted", exam);
        },

        handleExamStatusChanged(data) {
            this.$emit("exam-status-changed", data);
        },

        viewPatient(exam) {
            this.viewingExamPatient = exam.patient;
            this.patientPopupOpened = true;
        },

        handleSelectExam(checked, exam) {
            this.$emit("exam-selection-changed", {
                selected: checked,
                exam,
            });
        },

        handleSelectAllExams(checked) {
            Object.keys(this.exams).forEach((examId) => {
                const exam = this.exams[examId];

                if (this.examIsCheckeable(exam)) {
                    this.handleSelectExam(checked, exam);
                }
            });
        },

        openExamLogPopup(exam) {
            this.viewingExamLog = exam;

            this.showExamLogPopup = true;
        },

        patientName(patient) {
            if (patient) {
                return `${patient.nombres} ${patient.primerApellido} ${patient.segundoApellido}`;
            }

            return "";
        },
        patientTipoIdentificador(patient) {
            if (patient) {
                return patient.tipoIdentificador === "RUT" ? "RUN" : patient.tipoIdentificador;
            }

            return "";
        },
        patientIdentificador(patient) {
            if (patient) {
                return patient.tipoIdentificador === "RUT"
                    ? format(patient.identificador)
                    : patient.identificador;
            }

            return "";
        },

        getExamSampleText(samples) {
            if (Object.keys(samples || {}).length === 0) {
                return "Sin muestras";
            }

            const sample = samples[Object.keys(samples)[0]];

            return `${sample.name} (${sample.quantity})`;
        },

        examIsChecked(exam) {
            return this.selectedExams[exam.admExamId];
        },
        examIsCheckeable(exam) {
            return exam.currentStatus === "admitido";
        },

        examRowClass(exam) {
            if (this.selectable && this.selectedExams[exam.admExamId]) {
                return "table-info";
            }

            if (exam.currentStatus === "pendiente") {
                return "table-warning";
            }

            if (exam.currentStatus === "rechazado") {
                return "table-danger";
            }

            return "";
        },

        getExamTotalSamples(exam) {
            if (!exam.hasOwnProperty("samples")) {
                return 0;
            }

            const sample = exam.samples[Object.keys(exam.samples)[0]];
            return sample.quantity;
        },

        getSampleName(exam) {
            const sample = exam.samples[Object.keys(exam.samples)[0]];
            return sample.name;
        },

        getSampleText(exam) {
            if (Object.keys(exam.samples || {}).length === 0) {
                return "Sin muestras";
            }

            const name = this.getSampleName(exam);
            const totalSamples = this.getExamTotalSamples(exam);

            return `${name} (${totalSamples})`;
        },

        examSampleCode(exam) {
            if (Object.keys(exam.samples || {}).length === 0) {
                return "-";
            }

            const samplesKeys = Object.keys(exam.samples);

            return samplesKeys[0];
        },
    },
};
</script>
