<template>
    <div>
        <template
            v-if="source === sources.CREATE_ADMISSION || (source === sources.OT_SEARCH && misprivx.modify_ot)"
        >
            <add-exam :reset="reset" :all-exams="allExams" :ot-section="otSection" :source="source" @exam-added="handleExamAdded"></add-exam>
        </template>

        <div
            v-if="Object.keys(exams || {}).length === 0"
            class="text-align-center margin-vertical text-color-gray"
        >
            No hay examenes añadidos
        </div>
        <exams-table
            v-else
            :exams="exams"
            admission-view
            :buttons="['flujograma', 'reject', 'pending', 'delete']"
            @show-samples-clicked="handleShowSamplesClicked"
            :is-reason-mandatory="isReasonMandatory"
            @exam-status-changed="handleExamStatusChanged"
            @exam-deleted="handleExamDeleted"
        ></exams-table>

        <exam-samples-popup
            :opened="showExamSamplesPopup"
            :samples="samplesToShow"
            :exam="currentExam"
            :loading-adding-sample="loadingAddingSample"
            @popup-closed="handleExamSamplesPopupClosed"
            @sample-deleted="handleSampleDeleted"
            @sample-added="handleSampleAddedToExam"
        ></exam-samples-popup>

        <reasons-popup
            :opened="showReasonsPopup"
            :type="reasonsPopupType"
            :mandatory="isReasonMandatory"
            @popup-closed="
                isReasonMandatory = false;
                showReasonsPopup = false;
            "
            @reason-selected="handleReasonSelected"
            :use-default="useDefaultReason"
        ></reasons-popup>

        <exam-log-popup
            :opened="showExamLogPopup"
            :exam="viewingExamLog"
            @popup-closed="showExamLogPopup = false"
            @exam-status-changed="handleExamStatusChanged"
        ></exam-log-popup>
    </div>
</template>

<script>
import ReasonsPopup from "../components/reasons-popup.vue";
import ExamSamplesPopup from "../components/exam-samples-popup.vue";
import AddExam from "./add-exam.vue";
import ExamLogPopup from "@/app/components/exam-log-popup";

import ChangeStateButton from "@/app/components/change-state-button.vue";
import ExamsTable from "@/app/components/exams-table.vue";

import { mapActions, mapState } from "vuex";

import { _sources } from "@/app/constants";
import { _getSectionById, _getStatusText } from "@/app/helpers";

export default {
    props: {
        source: String,
        exams: Object,
        reset: Boolean,
        saved: Boolean,
        otSection: String,
    },

    components: {
        "reasons-popup": ReasonsPopup,
        "exam-samples-popup": ExamSamplesPopup,
        "add-exam": AddExam,
        "change-state-button": ChangeStateButton,
        "exam-log-popup": ExamLogPopup,
        "exams-table": ExamsTable,
    },

    data() {
        return {
            sources: _sources,
            getStatusText: _getStatusText,

            allExams: [],

            // exam being added to admission
            newExam: {
                externalId: "",
                id: "",
                name: "",
                samples: {},
                section_id: "",
                fechaCargado: "",
            },

            samplesToShow: {},
            samplesToShowExamId: "",
            currentExam: {},
            showExamSamplesPopup: false,
            loadingAddingSample: false,

            showReasonsPopup: false,
            useDefaultReason: false,
            reasonsPopupType: "",
            isReasonMandatory: false,
            currentExamId: "",

            getSectionById: _getSectionById,

            examsPreviousStates: {},

            showExamLogPopup: false,
            viewingExamLog: {},
        };
    },

    watch: {
        reset() {
            this.clearExamInfo();
            this.clearSelectedExam();
        },

        saved() {
            Object.values(this.exams || {}).forEach((exam) => {
                this.$set(this.examsPreviousStates, exam.id, exam.currentStatus);
            });
        },

        exams() {
            if (this.source === _sources.OT_SEARCH) {
                Object.values(this.exams || {}).forEach((exam) => {
                    this.$set(this.examsPreviousStates, exam.id, exam.currentStatus);
                });
            }
        },
    },

    computed: {
        ...mapState(["env", "profile_userInfo", "misprivx"]),

        isSampleAddable() {
            return (
                this.selectedSample.snomed_code.toString().length > 0 &&
                this.selectedSample.name.length > 0 &&
                parseInt(this.selectedSample.quantity) > 0
            );
        },
    },

    created() {
        this.getAllExams();
    },

    methods: {
        ...mapActions(["getAPIInfo"]),

        handleShowExamLogPopup(exam) {
            this.viewingExamLog = exam;
            this.showExamLogPopup = true;
        },

        handleExamStatusChanged(data) {
            const { examId, statusLog } = data;

            this.$emit("exam-log-added", {
                examId,
                statusLogObj: statusLog,
            });
        },

        // reasons popup related
        handleReasonSelected(reason) {
            this.addStatusLog(this.reasonsPopupType, this.currentExamId, reason);

            this.showReasonsPopup = false;
        },

        // exams related

        handleExamAdded(payload) {
            const { hasSamples, newExam } = payload;

            // añadir newExam a la admision
            this.$emit("exam-added", newExam);

            // si no tiene samples, marcar como pendiente
            // nota: esto se hace luego de emitir "exam-added" porque es necesario
            // que el examen esté añadido a la admisión previamente a cambiar su estado
            if (!hasSamples) {
                this.isReasonMandatory = true;
                this.useDefaultReason = true;
                this.markAs("pendiente", newExam.id);
            } else {
                this.addStatusLog("admitido", newExam.id, null);
            }

            this.clearExamInfo();
            this.clearSelectedExam();

            this.$f7.toast.show({
                text: "Examen añadido correctamente",
                position: "bottom",
                horizontalPosition: "center",
                closeTimeout: 3000,
            });
        },

        handleExamDeleted(exam) {
            this.$f7.dialog.confirm(
                "¿Estás seguro que deseas eliminar el examen de la admisión?",
                "Eliminar examen",
                () => {
                    this.$emit("exam-removed", exam);
                }
            );
        },
        clearExamInfo() {
            this.newExam = {
                externalId: "",
                id: "",
                name: "",
                samples: {},
                section_id: "",
                status: {},
            };

            this.selectedSample = {
                id: "",
                snomed_code: "",
                name: "",
                quantity: 1,
            };

            this.samples = {};
        },
        markAs(status, examId) {
            this.currentExamId = examId;

            // La operación es inválida si se está desmarcando de pendiente/rechazado y el examen no tiene muestras
            const isOperationInvalid =
                ["no-pendiente", "no-rechazado"].includes(status) &&
                !Object.keys(this.exams[examId].samples || {}).length;

            if (isOperationInvalid) {
                this.$f7.dialog.alert(
                    "No puedes marcar como admitido un examen sin muestras. Por favor, agrégale muestras al examen antes de realizar esta operación."
                );
                return;
            }

            if (["pendiente", "rechazado"].includes(status)) {
                this.reasonsPopupType = status;
                this.showReasonsPopup = true;
            } else {
                const previousState = this.examsPreviousStates[examId] || "";

                if (!previousState.length || ["pendiente", "rechazado"].includes(previousState)) {
                    this.addStatusLog("admitido", examId);
                } else {
                    this.addStatusLog(previousState, examId);
                }
            }
        },
        addStatusLog(status, examId, reason = null) {
            const statusLogObj = {
                who: this.profile_userInfo.displayName,
                when: (Date.now() / 1000) | 0,
                status,
                reason,
            };

            this.$emit("exam-log-added", {
                examId,
                statusLogObj,
            });
        },
        examRowClass(status) {
            if (status === "pendiente") {
                return "table-warning";
            } else if (status === "rechazado") {
                return "table-danger";
            } else {
                return "";
            }
        },

        // exam samples related

        handleShowSamplesClicked(exam) {
            this.samplesToShowExamId = exam.id;
            this.currentExam = this.allExams.find((e) => e.code === exam.externalId);
            this.showExamSamplesPopup = true;

            if (!Object.keys(exam.samples || {}).length) {
                this.samplesToShow = {};
                return;
            }

            const sample = exam.samples[Object.keys(exam.samples)[0]];

            this.samplesToShow = { [sample.id]: sample };
        },
        handleExamSamplesPopupClosed() {
            if (
                Object.keys(this.samplesToShow).length === 0 &&
                !["pendiente", "rechazado"].includes(this.exams[this.samplesToShowExamId].currentStatus)
            ) {
                this.isReasonMandatory = true;
                this.markAs("pendiente", this.samplesToShowExamId);
            }

            this.showExamSamplesPopup = false;
        },

        handleSampleAddedToExam(newSample) {
            this.loadingAddingSample = true;

            const createSampleId = this.$firebase.functions().httpsCallable("createSampleId");

            createSampleId({ env: this.env })
                .then((resp) => {
                    const id = resp.data;

                    const newSampleFormatted = {
                        id,
                        externalId: newSample.id.toString(),
                        name: newSample.name,
                        quantity: parseInt(newSample.quantity),
                    };

                    if (!this.exams[this.samplesToShowExamId].samples) {
                        this.$set(this.exams[this.samplesToShowExamId], "samples", {});
                    }

                    this.$set(this.exams[this.samplesToShowExamId].samples, id, newSampleFormatted);

                    this.samplesToShow = {
                        ...this.samplesToShow,
                        [id]: newSampleFormatted,
                    };

                    this.$emit("sample-added", newSampleFormatted);

                    this.loadingAddingSample = false;

                    this.$f7.toast.show({
                        text: "Muestra añadida correctamente",
                        position: "bottom",
                        horizontalPosition: "center",
                        closeTimeout: 3000,
                    });
                })
                .catch((error) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, error.code);
                });
        },

        // todo: change
        handleSampleDeleted(sampleId) {
            this.$delete(this.exams[this.samplesToShowExamId].samples, sampleId);
            this.$delete(this.samplesToShow, sampleId);
            this.$delete(this.samples, sampleId);

            this.$emit("sample-deleted", sampleId);
        },
        clearSelectedExam() {
            this.exam = {
                code: "",
                name: "",
                samples: [],
                section: {
                    id: "",
                    name: "",
                },
            };
        },

        getAllExams() {
            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: `exams?order=ASC`, userToken: idToken });
                })
                .then((resp) => {
                    const exams = resp.data.data;

                    this.allExams = exams;
                })
                .catch((err) => {
                    console.error(err);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Error. " + err.message);
                });
        },
    },
};
</script>
