<template>
    <div>
        <div class="display-flex justify-content-space-between align-items-center">
            <slot></slot>

            <f7-button
                @click="clearPatientInfo(patient, true)"
                color="red"
                :fill="patientSelected"
                v-if="source === sources.CREATE_ADMISSION"
            >
                Limpiar Paciente
            </f7-button>
        </div>

        <f7-row class="margin-bottom">
            <f7-col>
                <label>Tipo de documento</label>
                <f7-input
                    type="select"
                    @change="patient.tipoIdentificador = $event.target.value"
                    :value="patient.tipoIdentificador"
                    :disabled="patientSelected"
                >
                    <option value="">Seleccione...</option>
                    <option value="RUT">RUN</option>
                    <option value="PASAPORTE">Pasaporte</option>
                    <option value="DNI">DNI</option>
                    <option value="otro">Otro</option>
                </f7-input>
            </f7-col>
            <f7-col>
                <label>Número de documento</label>
                <f7-input
                    type="text"
                    @input="patient.identificador = $event.target.value"
                    :value="patient.identificador"
                    @keyup.native.enter="searchForPatient"
                    :clear-button="!patientSelected"
                    :disabled="patientSelected"
                ></f7-input>
            </f7-col>
            <f7-col>
                <label>Alarma</label>
                <f7-input type="select" :disabled="patientSelected">
                    <option value="sin_deuda">Sin Deuda</option>
                    <option value="con_deuda">Con Deuda</option>
                </f7-input>
            </f7-col>
        </f7-row>

        <f7-row class="margin-bottom">
            <f7-col>
                <label>Apellido paterno</label>
                <f7-input
                    class="input-uppercase"
                    type="text"
                    @input="patient.primerApellido = $event.target.value"
                    :value="patient.primerApellido"
                    @keyup.native.enter="searchForPatient"
                    :clear-button="!patientSelected"
                    :disabled="patientSelected"
                ></f7-input>
            </f7-col>
            <f7-col>
                <label>Apellido materno</label>
                <f7-input
                    class="input-uppercase"
                    type="text"
                    @input="patient.segundoApellido = $event.target.value"
                    :value="patient.segundoApellido"
                    @keyup.native.enter="searchForPatient"
                    :clear-button="!patientSelected"
                    :disabled="patientSelected"
                ></f7-input>
            </f7-col>
            <f7-col>
                <label>Nombres</label>
                <f7-input
                    class="input-uppercase"
                    type="text"
                    @input="patient.nombres = $event.target.value"
                    :value="patient.nombres"
                    @keyup.native.enter="searchForPatient"
                    :clear-button="!patientSelected"
                    :disabled="patientSelected"
                ></f7-input>
            </f7-col>
        </f7-row>

        <f7-row class="margin-bottom">
            <f7-col>
                <label>Fecha de nacimiento</label>
                <f7-input
                    class="input-uppercase"
                    type="text"
                    :maxlength="10"
                    @input="patient.fechaNacimiento = $event.target.value"
                    :value="patient.fechaNacimiento | formatDate"
                    @keyup.native.enter="searchForPatient"
                    placeholder="dd/MM/AAAA"
                    :clear-button="!patientSelected"
                    :disabled="patientSelected"
                ></f7-input>
            </f7-col>
            <f7-col>
                <label>Edad</label>
                <f7-input>
                    <span class="uppercase">{{ patientAge(patient.fechaNacimiento) }}</span>
                </f7-input>
            </f7-col>
            <f7-col>
                <label>Sexo</label>
                <f7-input
                    type="select"
                    @change="patient.sexo = $event.target.value"
                    :value="patient.sexo"
                    :disabled="patientSelected"
                >
                    <option value="">Seleccione...</option>
                    <option value="Masculino">M</option>
                    <option value="Femenino">F</option>
                </f7-input>
            </f7-col>
            <f7-col>
                <label>Nacionalidad</label>
                <f7-list>
                    <f7-list-item
                        class="smart-select-list-item-sm"
                        :title="patient.nacionalidad || 'Seleccione...'"
                        smart-select
                        :smart-select-params="{
                            openIn: 'popup',
                            searchbar: true,
                            searchbarPlaceholder: 'Buscar nacionalidad',
                            closeOnSelect: true,
                            setValueText: false,
                            popupCloseLinkText: 'Cerrar',
                            pageTitle: 'Seleccione una nacionalidad',
                        }"
                        :disabled="patientSelected"
                    >
                        <select
                            @change="patient.nacionalidad = $event.target.value"
                            :value="patient.nacionalidad"
                        >
                            <option value="">Seleccione...</option>
                            <option v-for="country in paises" :key="country.id" :value="country.name">
                                {{ country.name }}
                            </option>
                        </select>
                    </f7-list-item>
                </f7-list>
            </f7-col>
        </f7-row>

        <div class="display-flex flex-direction-row justify-content-center">
            <f7-button
                v-if="!patientSelected"
                @click="searchForPatient"
                fill
                :disabled="!isSearchable(patient) || patientSelected"
                icon-material="search"
            >
                Buscar paciente
            </f7-button>
            <template v-if="!readOnly">
                <f7-button
                    v-if="patientSelected && !misprivx.update_patient"
                    @click="showPatientMoreInfo('complete-data')"
                    outline
                    class="margin-left"
                >
                    Completar datos
                </f7-button>
                <f7-button
                    v-if="patientSelected && misprivx.update_patient"
                    @click="showPatientMoreInfo('modify-data')"
                    outline
                    class="margin-left"
                >
                    Modificar paciente
                </f7-button>
            </template>
        </div>

        <f7-popup
            class="found-patients"
            :opened="selectPatientPopupOpened"
            @popup:closed="selectPatientPopupOpened = false"
        >
            <f7-page>
                <f7-navbar title="Seleccione un paciente">
                    <f7-nav-right>
                        <f7-link popup-close>Cerrar</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <f7-block class="margin-top-half">
                    <p>
                        La búsqueda ha arrojado más de un resultado. Seleccione un paciente de la lista
                    </p>

                    <f7-list media-list>
                        <f7-list-item
                            checkbox
                            media-item
                            v-for="patient in foundPatients"
                            :key="patient.id"
                            @change="handleSelectPatient(patient.id)"
                            class="thin-list"
                        >
                            <div slot="title" class="uppercase">
                                {{ patient.nombres }}
                                {{ patient.primerApellido }}
                                {{ patient.segundoApellido }}
                            </div>
                            <div slot="after">
                                {{ patient.tipoIdentificador }}
                                {{ patient.identificador }}
                            </div>
                        </f7-list-item>
                    </f7-list>
                </f7-block>
            </f7-page>
        </f7-popup>

        <!-- Create Patient popup -->
        <f7-popup
            class="create-patient"
            :opened="createPatientPopupOpened"
            @popup:closed="createPatientPopupOpened = false"
        >
            <f7-page>
                <f7-navbar title="Crear un paciente">
                    <f7-nav-right>
                        <f7-link popup-close>Cerrar</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <f7-block class="margin-top">
                    <f7c-alert v-if="identificadorExists" type="warning">
                        El identificador ingresado ya existe.
                    </f7c-alert>

                    <b class="margin-bottom">Ingrese los datos del nuevo paciente</b>

                    <f7-row class="margin-vertical">
                        <f7-col>
                            <label>Tipo de documento</label>
                            <f7-input
                                type="select"
                                @change="newPatient.tipoIdentificador = $event.target.value"
                                :value="newPatient.tipoIdentificador"
                            >
                                <option value="">Seleccione...</option>
                                <option value="RUT">RUN</option>
                                <option value="PASAPORTE">Pasaporte</option>
                                <option value="DNI">DNI</option>
                                <option value="otro">Otro</option>
                            </f7-input>
                        </f7-col>
                        <f7-col>
                            <label>Número de documento</label>
                            <f7-input
                                type="text"
                                @input="newPatient.identificador = $event.target.value"
                                :value="newPatient.identificador"
                                @blur="checkUniqueIdentificador"
                                clear-button
                            ></f7-input>
                        </f7-col>
                    </f7-row>

                    <f7-row class="margin-bottom">
                        <f7-col>
                            <label>Apellido paterno</label>
                            <f7-input
                                class="input-uppercase"
                                type="text"
                                @input="newPatient.primerApellido = $event.target.value"
                                :value="newPatient.primerApellido"
                                clear-button
                            ></f7-input>
                        </f7-col>
                        <f7-col>
                            <label>Apellido materno</label>
                            <f7-input
                                class="input-uppercase"
                                type="text"
                                @input="newPatient.segundoApellido = $event.target.value"
                                :value="newPatient.segundoApellido"
                                clear-button
                            ></f7-input>
                        </f7-col>
                        <f7-col>
                            <label>Nombres</label>
                            <f7-input
                                class="input-uppercase"
                                type="text"
                                @input="newPatient.nombres = $event.target.value"
                                :value="newPatient.nombres"
                                clear-button
                            ></f7-input>
                        </f7-col>
                    </f7-row>

                    <f7-row class="margin-bottom">
                        <f7-col>
                            <label>Fecha de nacimiento</label>
                            <f7-input
                                type="text"
                                @input="newPatient.fechaNacimiento = $event.target.value"
                                :value="newPatient.fechaNacimiento | formatDate"
                                :maxlength="10"
                                clear-button
                            ></f7-input>
                        </f7-col>
                        <f7-col>
                            <label>Sexo</label>
                            <f7-input
                                type="select"
                                @change="newPatient.sexo = $event.target.value"
                                :value="newPatient.sexo"
                            >
                                <option value="">Seleccione...</option>
                                <option value="Masculino">M</option>
                                <option value="Femenino">F</option>
                            </f7-input>
                        </f7-col>
                        <f7-col>
                            <label>Nacionalidad</label>
                            <f7-list no-hairlines class="no-margin-top">
                                <f7-list-item
                                    class="smart-select-list-item-sm"
                                    :title="newPatient.nacionalidad || 'Seleccione...'"
                                    smart-select
                                    :smart-select-params="{
                                        openIn: 'popup',
                                        searchbar: true,
                                        searchbarPlaceholder: 'Buscar nacionalidad',
                                        closeOnSelect: true,
                                        setValueText: false,
                                        popupCloseLinkText: 'Cerrar',
                                        pageTitle: 'Seleccione una nacionalidad',
                                    }"
                                >
                                    <select
                                        @change="newPatient.nacionalidad = $event.target.value"
                                        :value="newPatient.nacionalidad"
                                    >
                                        <option value="">Seleccione...</option>
                                        <option
                                            v-for="country in paises"
                                            :key="country.id"
                                            :value="country.name"
                                        >
                                            {{ country.name }}
                                        </option>
                                    </select>
                                </f7-list-item>
                            </f7-list>
                        </f7-col>
                    </f7-row>

                    <f7-row class="margin-bottom">
                        <f7-col>
                            <label>Dirección</label>
                            <f7-input
                                class="input-uppercase"
                                type="text"
                                @input="newPatient.direccion = $event.target.value"
                                :value="newPatient.direccion"
                            ></f7-input>
                        </f7-col>
                        <f7-col>
                            <label>Comuna</label>
                            <f7-list no-hairlines class="no-margin-top">
                                <f7-list-item
                                    class="smart-select-list-item-sm"
                                    :title="newPatient.comuna || 'Seleccione...'"
                                    smart-select
                                    :smart-select-params="{
                                        openIn: 'popup',
                                        searchbar: true,
                                        searchbarPlaceholder: 'Buscar comuna',
                                        closeOnSelect: true,
                                        setValueText: false,
                                        popupCloseLinkText: 'Cerrar',
                                        pageTitle: 'Seleccione una comuna',
                                    }"
                                    :disabled="patientSelected"
                                >
                                    <select
                                        @change="newPatient.comuna = $event.target.value"
                                        :value="newPatient.comuna"
                                    >
                                        <option value="">Seleccione...</option>
                                        <optgroup
                                            v-for="region in comunas.regions"
                                            :key="region.number"
                                            :label="region.name"
                                        >
                                            <option
                                                v-for="(comuna, index) in region.communes"
                                                :key="index"
                                                :value="`${comuna.name}-${region.romanNumber}`"
                                            >
                                                {{ comuna.name }}
                                            </option>
                                        </optgroup>
                                    </select>
                                </f7-list-item>
                            </f7-list>
                        </f7-col>
                        <f7-col>
                            <label>Telefono</label>
                            <f7-input
                                class="input-uppercase"
                                type="text"
                                @input="newPatient.telefono = $event.target.value"
                                :value="newPatient.telefono"
                            ></f7-input>
                        </f7-col>
                        <f7-col>
                            <label>Mail</label>
                            <f7-input
                                class="input-uppercase"
                                type="email"
                                @input="newPatient.email = $event.target.value"
                                :value="newPatient.email"
                            ></f7-input>
                        </f7-col>
                    </f7-row>

                    <f7-row class="margin-bottom">
                        <f7-col>
                            <label>Previsión</label>
                            <f7-input
                                type="select"
                                @change="newPatient.prevision = $event.target.value"
                                :value="newPatient.prevision"
                            >
                                <option value="">Seleccione...</option>
                                <option value="prevision_1">Prevision 1</option>
                                <option value="prevision_2">Prevision 2</option>
                            </f7-input>
                        </f7-col>
                        <f7-col>
                            <label>Estado</label>
                            <f7-input
                                class="input-uppercase"
                                type="text"
                                @input="newPatient.estado = $event.target.value"
                                :value="newPatient.estado"
                            ></f7-input>
                        </f7-col>
                    </f7-row>

                    <f7-button fill @click="createPatient">Crear paciente</f7-button>
                </f7-block>
            </f7-page>
        </f7-popup>

        <!-- More info about the patient and edit -->
        <patient-more-info-popup
            :type="popupType"
            :popup-opened="moreInfoPopupOpened"
            :patient="patientMoreInfo"
            @popup-closed="moreInfoPopupOpened = false"
            @modify-patient="updatePatient"
            @clear-patient-info="clearPatientInfo(patientMoreInfo)"
        ></patient-more-info-popup>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { _patientAge } from "@/app/helpers";
import { _sources } from "@/app/constants";
import PatientMoreInfoPopup from "@/app/components/patient-more-info-popup";
import { saveInfoMixin } from "@/mixins/save-info-mixin";
import countries from "@/app/countries.json";
import communes from "@/app/comunas.json";

export default {
    mixins: [saveInfoMixin],

    props: {
        source: String,
        readOnly: Boolean,
        aPatient: {
            required: false,
        },
        reset: Boolean,
    },

    components: {
        "patient-more-info-popup": PatientMoreInfoPopup,
    },

    created() {
        if (this.aPatient && Object.keys(this.aPatient).length > 0) {
            this.setPatient(this.aPatient);
        }

        if (this.source !== _sources.OT_SEARCH || this.source !== _sources.EXAMS_SEARCH) {
            this.patient.tipoIdentificador = "RUT";
            this.patient.nacionalidad = "Chile";
        }
    },

    data() {
        return {
            sources: _sources,
            paises: countries,
            comunas: communes,

            foundPatients: {},

            patient: {
                id: "",
                tipoIdentificador: "",
                identificador: "",
                primerApellido: "",
                segundoApellido: "",
                nombres: "",
                fechaNacimiento: "",
                sexo: "",
                nacionalidad: "",
                email: "",
                comuna: "",
                telefono: "",
                direccion: "",
                prevision: "",
                estado: "",
            },

            newPatient: {},
            patientMoreInfo: {},

            patientSelected: false,

            moreInfoPopupOpened: false,
            createPatientPopupOpened: false,
            selectPatientPopupOpened: false,

            popupType: "",

            patientAge: _patientAge,

            missingFields: [],

            identificadorExists: false,
        };
    },

    computed: {
        ...mapState(["misprivx", "env"]),

        missingFieldsList() {
            let list = "<ul>";

            this.missingFields.forEach((field) => {
                list += `<li>${field}</li>`;
            });

            list += "</ul>";

            return list;
        },
    },

    watch: {
        aPatient(value) {
            if (value) {
                this.setPatient(value);
            }
        },

        reset(value) {
            this.clearPatientInfo(this.patient, true);
        },

        "newPatient.tipoIdentificador": function() {
            this.checkUniqueIdentificador();
        },
    },

    methods: {
        checkUniqueIdentificador() {
            this.identificadorExists = false;

            if (!this.newPatient.identificador) {
                return;
            }

            this.$firebase
                .database()
                .ref("/pacientes")
                .orderByChild("identificador")
                .equalTo(this.newPatient.identificador)
                .once("value")
                .then((snapshot) => {
                    const patients = snapshot.val();

                    if (!patients) {
                        return;
                    }

                    for (let i = 0; i < Object.keys(patients).length; i++) {
                        const patient = patients[Object.keys(patients)[i]];

                        if (patient.tipoIdentificador === this.newPatient.tipoIdentificador) {
                            this.identificadorExists = true;
                            return;
                        }

                        this.identificadorExists = false;
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        },

        patientInfoIsValid(patient) {
            this.missingFields = [];
            let isValid = true;

            if (!patient.tipoIdentificador.length) {
                this.missingFields.push("Tipo de documento");
                isValid = false;
            }

            if (!patient.identificador.length) {
                this.missingFields.push("Número de documento");
                isValid = false;
            }

            if (!patient.nombres.length) {
                this.missingFields.push("Nombres");
                isValid = false;
            }

            if (!patient.primerApellido.length) {
                this.missingFields.push("Primer apellido");
                isValid = false;
            }

            return isValid;
        },

        searchForPatient() {
            if (!this.isSearchable(this.patient)) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Debe indicar campos de búsqueda");
                return;
            }

            this.$f7.dialog.preloader("Buscando paciente...");

            const searchFields = {};

            Object.keys(this.patient).forEach((field) => {
                if (typeof this.patient[field] === "string" && this.patient[field].length > 0) {
                    searchFields[field] = this.patient[field].trim().toLowerCase();
                }
            });

            const searchForPatient = this.$firebase.functions().httpsCallable("searchForPatient");

            searchForPatient({
                searchFields,
                prod: this.env === "production",
            })
                .then((searchResults) => {
                    const results = searchResults.data;

                    const count = Object.keys(results).length;

                    if (count === 0) {
                        this.newPatient = { ...this.patient };

                        this.createPatientPopupOpened = true;

                        this.patientSelected = false;
                        this.$emit("patient-searched", null);
                    } else if (count === 1) {
                        this.setPatientData(Object.values(results)[0]);

                        this.patientSelected = true;
                        this.$emit("patient-searched", this.patient);

                        this.$f7.toast.show({
                            text: "Paciente encontrado",
                            position: "bottom",
                            horizontalPosition: "center",
                            closeTimeout: 3000,
                        });
                    } else {
                        // mostrar lista de pacientes encontrados
                        this.selectPatientPopupOpened = true;
                        this.foundPatients = results;

                        this.patientSelected = false;
                        this.$emit("patient-searched", null);
                    }

                    this.$f7.dialog.close();
                })
                .catch((err) => {
                    console.error(err);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Hubo un error al buscar al paciente. " + err);
                });
        },

        createPatient() {
            if (!this.patientInfoIsValid(this.newPatient)) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(
                    "Faltan datos requeridos para crear al paciente." + this.missingFieldsList
                );
                return;
            }

            this.$f7.dialog.preloader("Creando paciente...");

            const id = this.$firebase
                .database()
                .ref("/pacientes")
                .push().key;

            this.newPatient.id = id;

            this.$firebase
                .database()
                .ref(`/pacientes/${id}`)
                .set(this.newPatient)
                .then(() => {
                    Object.assign(this.patient, this.newPatient);

                    this.patientSelected = true;
                    this.$emit("patient-searched", this.patient);

                    this.$f7.dialog.close();
                    this.$f7.toast.show({
                        text: "Paciente creado con éxito",
                        closeTimeout: 2000,
                    });

                    // log crear paciente
                    this.savePatientLog("add-patient", id).catch((err) => {
                        this.$f7.dialog.alert(err.message, err.code);
                    });

                    this.createPatientPopupOpened = false;
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, err.code);
                });
        },
        updatePatient() {
            const updatedFields = {};

            Object.keys(this.patientMoreInfo).forEach((field) => {
                if (this.patient[field] != this.patientMoreInfo[field]) {
                    updatedFields[field] = this.patientMoreInfo[field];
                }
            });

            if (Object.keys(updatedFields).length === 0) {
                return;
            }

            if (!this.patientInfoIsValid(this.patientMoreInfo)) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(
                    "Faltan datos requeridos para modificar al paciente." + this.missingFieldsList
                );
                return;
            }

            this.$f7.dialog.preloader("Actualizando los datos del paciente");

            const updates = {};

            Object.keys(updatedFields).forEach((field) => {
                updates[`/pacientes/${this.patient.id}/${field}`] = updatedFields[field];
            });

            this.$firebase
                .database()
                .ref()
                .update(updates)
                .then(() => {
                    this.$f7.dialog.close();
                    this.$f7.toast.show({
                        text: "Paciente actualizado con éxito",
                        closeTimeout: 2000,
                    });

                    Object.keys(updatedFields).forEach((field) => {
                        this.patient[field] = updatedFields[field];
                    });
                    this.moreInfoPopupOpened = false;

                    this.$emit("patient-updated", this.patient);

                    // log editar paciente
                    this.savePatientLog("edit-patient", this.patient.id).catch((err) => {
                        this.$f7.dialog.alert(err.message, err.code);
                    });
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, err.code);
                });
        },

        handleSelectPatient(patientId) {
            this.selectPatientPopupOpened = false;
            this.setPatientData(this.foundPatients[patientId]);
            this.foundPatients = {};

            this.patientSelected = true;
            this.$emit("patient-searched", this.patient);
        },
        setPatient(patient) {
            this.patient = Object.assign({}, patient);
            this.patientSelected = true;
        },
        setPatientData(data) {
            this.patient = {
                id: data.id,
                tipoIdentificador: data.tipoIdentificador || "",
                identificador: data.identificador || "",
                primerApellido: data.primerApellido || "",
                segundoApellido: data.segundoApellido || "",
                nombres: data.nombres || "",
                fechaNacimiento: data.fechaNacimiento || "",
                sexo: data.sexo || "",
                nacionalidad: data.nacionalidad || "",
                email: data.email || "",
                comuna: data.comuna || "",
                telefono: data.telefono || "",
                direccion: data.direccion || "",
                prevision: data.prevision || "",
                estado: data.estado || "",
            };
        },
        clearPatientInfo(patient, clear = false) {
            patient.tipoIdentificador = "RUT";
            patient.identificador = "";
            patient.primerApellido = "";
            patient.segundoApellido = "";
            patient.nombres = "";
            patient.fechaNacimiento = "";
            patient.sexo = "";
            patient.nacionalidad = "Chile";
            patient.id = "";

            patient.email = "";
            patient.comuna = "";
            patient.telefono = "";
            patient.direccion = "";
            patient.prevision = "";
            patient.estado = "";

            if (clear) {
                this.patientSelected = false;
                this.$emit("patient-searched", null);
            }
        },

        // posible cambio acá:
        // function hasAtLeastOneFieldFilled(unit, mandatoryFields = []) {}
        isSearchable(patient) {
            const filled = [];
            const fields = Object.keys(patient);

            fields.forEach((field) => {
                if (patient[field] !== "" && patient[field] !== 0) {
                    filled.push(field);
                }
            });

            if (filled.includes("nacionalidad") && filled.length === 1) {
                return false;
            }

            // filled.length === 2 ["tipoIdentificador", "nacionalidad"]
            if (
                filled.includes("tipoIdentificador") &&
                patient.identificador.length === 0 &&
                filled.length === 2
            ) {
                return false;
            }

            return true;
        },

        // popups handlers
        openCreatePatientPopup() {
            Object.assign(this.newPatient, this.patient);
            this.createPatientPopupOpened = true;
        },

        showPatientMoreInfo(type) {
            this.patientMoreInfo = Object.assign({}, this.patient);
            this.popupType = type;
            this.moreInfoPopupOpened = true;
        },
    },
};
</script>
