<template>
    <f7-page >
        <f7-navbar title="Agregar nueva marca">
            <f7-nav-right>
                <f7-link popup-close>Cerrar</f7-link>
            </f7-nav-right>
        </f7-navbar>
        <form @submit.prevent="create" >
            <f7-block>
                <f7-row class="mb-7">
                    <f7-col width="100" >
                        <label>Descripcion</label>
                        <f7-input type="text" placeholder="" info="Nombre de la marca" :value="form.description"  @change="form.description = $event.target.value" />
                    </f7-col>
                </f7-row>
                <f7-row class="mb-2">
                    <f7-col width="100" >
                        <f7-button fill type="submit">Guardar</f7-button>
                    </f7-col>
                </f7-row>
            </f7-block>
        </form>
    </f7-page>
</template>

<script>


import { mapActions, mapState } from 'vuex';

export default {

    components : {

    },
    data(){
        return {
            form : {
                description : ""
            }
        } 
    },
    mounted(){
       this.form.description = "";
    },
    methods : {
        create(){
            this.$firebase.auth().currentUser.getIdToken().then(async (token) => {
                var result = await this.$store.dispatch('apiRequest', {url : 'universal/', method : 'POST', token, data : { model : 'Brand', info : this.form}, app : this});
                await this.$store.dispatch("updateState",{ token , state : [
                    {"path" : "universal/?model=Brand", "model" : "brand"}
                ]});
                if(result.code == 200){
                    this.$emit('addElementArray',{'element' : "brand" , 'value' : result.info.message[0]})
                    this.form.description = "";
                }
                this.$f7.popup.close('.brands');
            });
        }
    }

}
</script>

<style scoped>
.mb-2{
    margin-bottom: 4% !important;
}
.mb-7{
    margin-bottom: 7% !important;
}
.align-left{
    text-align: left;
}
.pull-right{
    float:right;
}
.pull-left{
    float: left;
}
</style>