<template>
    <f7-page name="newExam" @page:beforeremove="updateExam" @page:afterin="getSelectedSamples">
        <f7-navbar back-link>
            <f7-nav-title v-if="!isEditor">
                {{ !isCollection ? "Agregar nuevo examen" : "Agregar nueva colección" }}
            </f7-nav-title>
            <f7-nav-title v-else>
                {{ !isCollection ? "Editar examen" : "Editar colección" }}
            </f7-nav-title>
            <f7-nav-right>
                <span class="collection-label">Es una colección</span>
                <f7-toggle class="margin-horizontal collection-toggle" :checked="isCollection" @toggle:change="checkExamCollection"></f7-toggle>
            </f7-nav-right>
        </f7-navbar>

        <f7-block inset>
            <f7-list no-hairlines class="no-margin-bottom margin-top-half">
                <f7-list-item divider :title="!isCollection ? 'Datos generales de examen' : 'Datos generales de colección'"></f7-list-item>
            </f7-list>
            <f7-row>
                <f7-col width="100" medium="50">
                    <f7-list no-hairlines-md class="no-margin-vertical">
                        <f7-list-item v-if="isEditor" class="exam-code-margin" header="Código examen" :title="exam.code"></f7-list-item>
                        <f7-list-input
                            v-else
                            label="Código examen"
                            type="text"
                            name="code"
                            placeholder="Código examen"
                            :value="exam.code"
                            @input="exam.code = $event.target.value"
                            required
                            validate
                            error-message="Debe indicar el código del examen"
                        ></f7-list-input>
                        <f7-list-input
                            label="Nombre de examen"
                            type="text"
                            name="examName"
                            placeholder="Nombre"
                            :value="exam.name"
                            @input="exam.name = $event.target.value"
                            required
                            validate
                            error-message="Debe indicar el nombre del examen"
                        ></f7-list-input>
                    </f7-list>
                    <div class="list no-hairlines no-margin-top margin-bottom-half">
                        <ul>
                            <li>
                                <a
                                    class="item-link smart-select method-select"
                                    data-open-in="popup"
                                    data-popup-close-link-text="Cerrar"
                                    @smartselect:close="selectProcessingMethod()"
                                >
                                    <select name="processing-method" multiple>
                                        <option
                                            v-for="(method, index) in $store.getters.sortArray('processingMethods')"
                                            :key="index"
                                            :value="method.id"
                                            :selected="exam.exams_processing_methods.includes(method.id)"
                                        >
                                            {{ method.name }}
                                        </option>
                                    </select>
                                    <div class="item-content">
                                        <div class="item-inner">
                                            <div class="item-title">Métodos de procesamiento</div>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </f7-col>
                <f7-col width="100" medium="50">
                    <f7-list no-hairlines-md class="no-margin-vertical">
                        <f7-list-input
                            label="Sección"
                            type="select"
                            :value="exam.section_id"
                            @change="exam.section_id = $event.target.value"
                            required
                            validate
                            error-message="Debe seleccionar una sección"
                        >
                            <option></option>
                            <option
                                v-for="(section, index) in $store.getters.sortArray('sections')"
                                :key="index"
                                :value="section.id"
                                :checked="section.id === exam.section_id"
                            >
                                {{ section.name }}
                            </option>
                        </f7-list-input>
                        <!-- <f7-list-input
                            label="Tiempo entrega de resultados (hrs)"
                            type="number"
                            placeholder="Ingrese tiempo en horas"
                            :value="exam.normal_processing_time"
                            @input="exam.normal_processing_time = $event.target.value"
                        ></f7-list-input> -->
                        <f7-list-item
                            checkbox
                            :checked="exam.result_as_pdf"
                            @change="$set(exam, 'result_as_pdf', $event.target.checked)"
                            title="Este examen sólo acepta como resultado archivos PDF."
                        ></f7-list-item>
                        <f7-list-item
                            checkbox
                            :checked="exam.has_classes"
                            @change="$set(exam, 'has_classes', $event.target.checked)"
                            title="Este examen incluye clases e interpretaciones (Ej: Alergias)."
                        ></f7-list-item>
                    </f7-list>
                </f7-col>
            </f7-row>

            <exam-collections
                v-if="isCollection && exam.section_id"
                :key="'collection-section-' + exam.section_id"
                :sectionId="exam.section_id"
                v-on:selectAssociatedExams="addAssociatedExams"
            />

            <f7-list no-hairlines class="margin-vertical-half" media-list>
                <f7-list-input
                    label="Descripción"
                    type="textarea"
                    name="description"
                    placeholder="Descripción (Opcional)"
                    :value="exam.description"
                    @input="exam.description = $event.target.value"
                ></f7-list-input>
            </f7-list>
            <template v-if="!isCollection">
                <f7-block-header class="margin-left">
                    ¿Sólo solicitar examen dentro de una colección?
                </f7-block-header>
                <f7-list no-hairlines>
                    <f7-list-item
                        radio
                        title="No"
                        name="only-group-selector"
                        :checked="exam.only_group === 0"
                        @change="exam.only_group = 0"
                    ></f7-list-item>
                    <f7-list-item
                        radio
                        title="Si"
                        name="only-group-selector"
                        :checked="exam.only_group === 1"
                        @change="exam.only_group = 1"
                    ></f7-list-item>
                </f7-list>
            </template>

            <f7-row v-if="isEditor">
                <f7-col width="0" medium="75"></f7-col>
                <f7-col width="100" medium="25">
                    <f7-button class="margin-horizontal no-margin-top margin-bottom" icon-material="edit" small fill @click="validateExamData()">
                        Guardar cambios de {{ !isCollection ? "examen" : "colección" }}
                    </f7-button>
                </f7-col>
            </f7-row>

            <template v-if="!isCollection">
                <results-types v-on:addResultsInfo="addResultsInfo" :result-types-saved="resultTypesSavedToggle"/>

                <f7-row v-if="isEditor">
                    <f7-col width="0" medium="75"></f7-col>
                    <f7-col width="100" medium="25">
                        <f7-button class="margin" icon-material="edit" small fill @click="editResults()">
                            Guardar cambios de resultados
                        </f7-button>
                    </f7-col>
                </f7-row>
            </template>

            <!-- <f7-list no-hairlines class="no-margin-bottom margin-top-half">
                <f7-list-item divider title="Días de procesamiento"></f7-list-item>
                <f7-list-item
                    v-if="!isEditor"
                    checkbox
                    title="Todos los días (Lun - Vie)"
                    :checked="!selectorDays"
                    @change="selectWeek"
                ></f7-list-item>
            </f7-list>
            <f7-block v-if="selectorDays" :key="dayReloader">
                <f7-block-title>Seleccione los días de procesamiento</f7-block-title>
                <f7-row v-for="(day, index) in draftSchedules" :key="index">
                    <f7-col width="100" medium="40">
                        <f7-list no-hairlines class="no-margin-bottom margin-top-half">
                            <f7-list-item checkbox :title="day.day" @change="selectDay(index)" :checked="day.checked"></f7-list-item>
                        </f7-list>
                    </f7-col>
                    <f7-col width="50" medium="25" v-if="day.checked">
                        <f7-list no-hairlines class="no-margin-bottom margin-top-half">
                            <f7-list-input
                                placeholder="Hora Inicio"
                                type="time"
                                :value="day.start_hour"
                                @input="day.start_hour = $event.target.value"
                            ></f7-list-input>
                        </f7-list>
                    </f7-col>
                    <f7-col width="50" medium="25" v-if="day.checked">
                        <f7-list no-hairlines class="no-margin-bottom margin-top-half">
                            <f7-list-input
                                placeholder="Hora Término"
                                type="time"
                                :value="day.end_hour"
                                @input="day.end_hour = $event.target.value"
                            ></f7-list-input>
                        </f7-list>
                    </f7-col>
                </f7-row>
            </f7-block>

            <f7-row v-if="isEditor">
                <f7-col width="0" medium="75"></f7-col>
                <f7-col width="100" medium="25">
                    <f7-button class="margin-horizontal no-margin-top margin-bottom" icon-material="edit" small fill @click="editSchedules">
                        Guardar cambios de horarios
                    </f7-button>
                </f7-col>
            </f7-row> -->

            <div class="list no-hairlines no-margin-top margin-bottom-half">
                <ul>
                    <li class="item-divider">Muestras permitidas</li>
                    <li>
                        <a
                            href="#"
                            class="item-link smart-select select-samples"
                            data-open-in="popup"
                            data-popup-close-link-text="Cerrar"
                            data-searchbar="true"
                            data-searchbar-placeholder="Busque los tipos de muestras"
                            @smartselect:close="selectSamples"
                        >
                            <select name="select-samples" multiple>
                                <option
                                    v-for="(sample, index) in $store.getters.sortArray('allSamples')"
                                    :key="index"
                                    :value="sample.id"
                                    :selected="checkSelectedSamples(sample.id)"
                                >
                                    {{ sample.name }}
                                </option>
                            </select>
                            <div class="item-content">
                                <div class="item-inner">
                                    <div class="item-title">Muestras</div>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>

            <f7-list no-hairlines media-list :key="reloading">
                <f7-list-item
                    v-for="(sample, index) in examSamples"
                    :key="index"
                    :title="sample.name"
                    :subtitle="sample.min_volume && 'Vol. mínimo: ' + sample.min_volume + ' μL'"
                    :text="sample.observations"
                    :footer="sample.n_required_samples && 'N° mínimo de muestras: ' + sample.n_required_samples"
                >
                    <f7-link
                        @click="openSamplePopup(sample)"
                        class="margin-left"
                        slot="after"
                        tooltip="Editar detalles de muestra"
                        icon-material="edit"
                    ></f7-link>
                    <f7-link
                        @click="deleteSample(index, sample.id)"
                        class="margin-left"
                        slot="after"
                        tooltip="Eliminar muestra permitida"
                        icon-material="delete"
                    ></f7-link>
                </f7-list-item>
            </f7-list>

            <f7-row v-if="isEditor">
                <f7-col width="0" medium="75"></f7-col>
                <f7-col width="100" medium="25">
                    <f7-button class="margin-horizontal no-margin-top margin-bottom" icon-material="edit" small fill @click="editAllSamples()">
                        Guardar cambios en muestras
                    </f7-button>
                </f7-col>
            </f7-row>
        </f7-block>

        <f7-block>
            <f7-card outline>
                <f7-card-content class="no-padding-top">
                    <h4>Configuración del proceso de examen</h4>

                    <p class="margin-bottom"><b>Dias de procesamiento</b></p>
                    <f7-checkbox value="d_1" class="margin-right" @change="(payload) => examProcessingConfigChanged('days', payload)" :checked="examProcessingConfig.days['d_1'] !== undefined">Lunes</f7-checkbox>
                    <f7-checkbox value="d_2" class="margin-right" @change="(payload) => examProcessingConfigChanged('days', payload)" :checked="examProcessingConfig.days['d_2'] !== undefined">Martes</f7-checkbox>
                    <f7-checkbox value="d_3" class="margin-right" @change="(payload) => examProcessingConfigChanged('days', payload)" :checked="examProcessingConfig.days['d_3'] !== undefined">Miercoles</f7-checkbox>
                    <f7-checkbox value="d_4" class="margin-right" @change="(payload) => examProcessingConfigChanged('days', payload)" :checked="examProcessingConfig.days['d_4'] !== undefined">Jueves</f7-checkbox>
                    <f7-checkbox value="d_5" class="margin-right" @change="(payload) => examProcessingConfigChanged('days', payload)" :checked="examProcessingConfig.days['d_5'] !== undefined">Viernes</f7-checkbox>
                    <f7-checkbox value="d_6" class="margin-right" @change="(payload) => examProcessingConfigChanged('days', payload)" :checked="examProcessingConfig.days['d_6'] !== undefined">Sábado</f7-checkbox>
                    <f7-checkbox value="d_7" class="margin-right" @change="(payload) => examProcessingConfigChanged('days', payload)" :checked="examProcessingConfig.days['d_7'] !== undefined">Domingo</f7-checkbox>

                    <p class="margin-bottom"><b>Horarios de corte</b></p>
                    <f7-list no-hairlines class="margin-bottom">
                        <f7-list-input clear-button type="text" placeholder="HH:MM" @keyup.enter.native="payload => examProcessingConfigChanged('add-corte', payload)" :maxlength="5" info="Enter para añadir corte. Los horarios de corte aplican para todos los dias seleccionados"></f7-list-input>
                    </f7-list>

                    <p class="text-color-gray" v-if="Object.keys(examProcessingConfig.cortes).length === 0">
                        No hay horarios de cortes configurados
                    </p>
                    <f7-list media-list v-else>
                        <f7-list-item media-item v-for="(corte, index) in orderedCortes" :key="index">
                            <div slot="title">{{ corte }}</div>
                            <div slot="after">
                                <f7-button small color="red" @click="examProcessingConfigChanged('remove-corte', corte)">Quitar</f7-button>
                            </div>
                        </f7-list-item>
                    </f7-list>

                    <p class="margin-bottom"><b>Horario de entrega</b></p>
                    <f7-list no-hairlines class="margin-bottom">
                        <f7-list-input clear-button type="text" placeholder="HH:MM" @blur="($event) => formatHoraEntrega($event)" @input="payload => examProcessingConfigChanged('horario-entrega', payload)" :value="examProcessingConfig.horario_entrega || ''" info="Importante: Si se configura horario de entrega, las horas de corte serán ignoradas"></f7-list-input>
                    </f7-list>

                    <p class="margin-bottom"><b>Demora del proceso</b></p>
                    <f7-row v-if="examProcessingConfig && examProcessingConfig.demora">
                        <f7-col width="70">
                            <label>Entrega Normal</label>
                            <f7-input
                                class="margin-bottom"
                                type="number"
                                placeholder="Tiempo de entrega normal"
                                :value="examProcessingConfig.demora.normal.value"
                                @input="examProcessingConfig.demora.normal.value = Number($event.target.value)"
                            ></f7-input>

                            <label>Entrega Urgente</label>
                            <f7-input
                                class="margin-bottom"
                                type="number"
                                placeholder="Tiempo de entrega urgente"
                                :value="examProcessingConfig.demora.urgente.value"
                                @input="examProcessingConfig.demora.urgente.value = Number($event.target.value)"
                            ></f7-input>

                            <label>Entrega STAT</label>
                            <f7-input
                                type="number"
                                placeholder="Tiempo de entrega STAT"
                                :value="examProcessingConfig.demora.stat.value"
                                @input="examProcessingConfig.demora.stat.value = Number($event.target.value)"
                            ></f7-input>
                        </f7-col>
                        <f7-col width="30">
                            <label>Unidad</label>
                            <f7-input
                                class="margin-bottom"
                                type="select"
                                @change="examProcessingConfig.demora.normal.unit = $event.target.value"
                                :value="examProcessingConfig.demora.normal.unit"
                            >
                                <option value="hours">Horas</option>
                                <option value="minutes">Minutos</option>
                            </f7-input>

                            <label>Unidad</label>
                            <f7-input
                                class="margin-bottom"
                                type="select"
                                @change="examProcessingConfig.demora.urgente.unit = $event.target.value"
                                :value="examProcessingConfig.demora.urgente.unit"
                            >
                                <option value="hours">Horas</option>
                                <option value="minutes">Minutos</option>
                            </f7-input>

                            <label>Unidad</label>
                            <f7-input
                                class="margin-bottom"
                                type="select"
                                @change="examProcessingConfig.demora.stat.unit = $event.target.value"
                                :value="examProcessingConfig.demora.stat.unit"
                            >
                                <option value="hours">Horas</option>
                                <option value="minutes">Minutos</option>
                            </f7-input>
                        </f7-col>
                    </f7-row>

                    <f7-row v-if="isEditor">
                        <f7-col width="0" medium="75"></f7-col>
                        <f7-col width="100" medium="25">
                            <f7-button class="margin-horizontal no-margin-top margin-bottom" icon-material="edit" small fill @click="editExamProcessingConfig()">
                                Guardar cambios
                            </f7-button>
                        </f7-col>
                    </f7-row>
                </f7-card-content>
            </f7-card>
        </f7-block>

        <f7-block>
            <f7-row>
                <f7-col width="10" medium="33"></f7-col>
                <f7-col width="0" medium="33">
                    <f7-button v-if="!isEditor" icon-material="playlist_add" fill raised round @click="validateExamData()">
                        Agregar nuevo examen
                    </f7-button>
                </f7-col>
                <f7-col width="10" medium="33"></f7-col>
            </f7-row>
        </f7-block>

        <sample-exam v-on:saveSample="addSampleInfo($event)" :selectedSample="selectedSample" :state="popupOpened"></sample-exam>
    </f7-page>
</template>

<script>
import { mapState, mapActions } from "vuex";
//import SampleExam from "../../components/sample-exam.vue";
import SampleExam from "../../../components/sample-exam.vue";
import ResultsTypes from "../../../components/results-types.vue";
import ExamCollections from "../../../components/exam-collections";

export default {
    data() {
        return {
            resultTypesSavedToggle: false,

            examProcessingConfig: {
                days: {},
                cortes: {},
                horario_entrega: "",
                demora: {
                    normal: {
                        value: 0,
                        unit: "Horas"
                    },
                    urgente: {
                        value: 0,
                        unit: "Horas"
                    },
                    stat: {
                        value: 0,
                        unit: "Horas"
                    },
                },
            },

            exam: {
                name: "",
                section_id: "",
                code: "",
                normal_processing_time: null,
                urgent_processing_time: 0, //evaluar si este campo debe mantenerse
                exams_processing_methods: [],
                associated_exams: [],
                description: null,
                enabled: 1,
                only_group: 0,
                result_as_pdf: false,
                has_classes: false,

                entrega_normal: 0,
                entrega_urgente: 0,
                entrega_stat: 0,
            },
            selectorDays: false,
            daysNames: ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes"],
            schedules: [],
            draftSchedules: ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"].map((day) => {
                let newDay = {
                    day,
                    start_hour: null,
                    end_hour: null,
                    checked: false,
                };
                return newDay;
            }),
            examSamples: [],
            resultsInfo: {},
            isEditor: false,
            popupOpened: false,
            selectedSample: {},
            reloading: false,
            dayReloader: "dayRealoaderOff",
            examId: "",
            userToken: "",
            isCollection: false,
        };
    },
    components: {
        SampleExam,
        ResultsTypes,
        ExamCollections,
    },

    computed: {
        ...mapState(["sections", "allSamples", "selectedExam", "baseUrl", "processingMethods", "examSamplesInfo", "profile_userInfo"]),

        orderedCortes() {
            return Object.keys(this.examProcessingConfig.cortes).sort((a, b) => a.localeCompare(b));
        }
    },
    methods: {
        ...mapActions(["getAPIInfo", "createNewInfo", "updateInfo", "deleteAPIInfo"]),

        examProcessingConfigChanged(property, payload) {
            if (property === "days") {
                const { value, checked } = payload.target;

                if (checked) {
                    this.$set(this.examProcessingConfig.days, value, true)
                } else {
                    this.$delete(this.examProcessingConfig.days, value);
                }
            }

            if (property === "add-corte") {
                const { value } = payload.target;

                const formattedTime = this.$moment(value, "HH:mm");

                if (!formattedTime.isValid()) {
                    this.$f7.dialog.alert("El horario de corte ingresado no es válido. Por favor utilice el formato hh:mm.");
                    return;
                }

                this.$set(this.examProcessingConfig.cortes, formattedTime.format("HH:mm"), true);
            }

            if (property === "remove-corte") {
                this.$delete(this.examProcessingConfig.cortes, payload);
            }

            if (property === "horario-entrega") {
                const { value } = payload.target;

                this.$set(this.examProcessingConfig, "horario_entrega", value);
            }
        },

        async editExamProcessingConfig() {
            this.$f7.dialog.preloader("Guardando configuracion de procesamiento");

            if (Object.keys(this.examProcessingConfig.days || {}).length === 0) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Debe configurar por lo menos un dia de procesamiento");
                return;
            }

            try {
                await this.$firebase.database().ref(`examProcessingConfig/${this.selectedExam.id}`).set(this.examProcessingConfig);

                this.$f7.dialog.close();

                this.$f7.toast.show({
                    text: "Cambios guardados con exito",
                    closeTimeout: 2000,
                });
            } catch (error) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Hubo un error al actualizar la configuracion de proceso del examen. " + error);
            }
        },

        formatHoraEntrega(event) {
            const rawHour = event.target.value;

            if (!rawHour) return;

            const formattedTime = this.$moment(rawHour, "HH:mm");

            let setValue;

            if (!formattedTime.isValid()) {
                setValue = "";
                this.$f7.dialog.alert("El horario de entrega ingresado no es válido. Por favor utilice el formato hh:mm.");
            } else {
                setValue = formattedTime.format("HH:mm")
            }

            this.$set(this.examProcessingConfig, "horario_entrega", setValue);
        },

        getSelectedMethods(methodNames) {
            //Se obtienen la métodos de procesamiento seleccionados para mostrarlos en el after del smart-select
            if (this.isEditor) {
                this.$f7.smartSelect.create({
                    el: ".method-select",
                    setValueText: true,
                    formatValueText: (values) => {
                        return values.length > 0 ? values : methodNames;
                    },
                });
            }
        },

        setAddOrEdit() {
            if (this.selectedExam) {
                this.isEditor = true;
                //Se construye objeto de examen para enviar a la API, desde objeto seleccionado
                let {
                    id,
                    createdAt,
                    updatedAt,
                    samples,
                    exam_schedules,
                    section,
                    exam_processing_methods,
                    associations,
                    ...editableExam
                } = this.selectedExam;
                this.exam = Object.assign(editableExam, {
                    section_id: section ? section.id : 0,
                    exams_processing_methods: exam_processing_methods.map((method) => method.id),
                    associated_exams: associations.map((association) => {
                        return {
                            exam: association.associated_exam.code,
                            order: association.order,
                        };
                    }),
                    enabled: 1,
                });
                const methodNames = exam_processing_methods.map((method) => method.name);
                this.getSelectedMethods(methodNames);
                this.examId = id;
                this.examSamples = this.examSamplesInfo;

                this.draftSchedules = this.draftSchedules.map((schedule) => {
                    let auxSchedule = exam_schedules.find((exam_schedule) => exam_schedule.day === schedule.day);
                    if (auxSchedule) {
                        auxSchedule.checked = true;
                        //API sólo recibe hora en formato HH:mm
                        if (auxSchedule.start_hour) {
                            auxSchedule.start_hour = auxSchedule.start_hour.slice(0, 5);
                        }
                        if (auxSchedule.end_hour) {
                            auxSchedule.end_hour = auxSchedule.end_hour.slice(0, 5);
                        }
                        //////
                        return auxSchedule;
                    } else {
                        return schedule;
                    }
                });
                this.selectorDays = true;
            } else {
                this.addEveryday();
            }
        },

        selectProcessingMethod() {
            let values = this.$f7.smartSelect.get(".method-select").getValue();
            this.exam.exams_processing_methods = values.map((value) => parseInt(value));
        },

        addResultsInfo(resultsInfo) {
            this.resultsInfo = resultsInfo;
        },

        checkExamCollection() {
            const toggle = this.$f7.toggle.get(".collection-toggle");
            if (toggle.checked) {
                this.$f7.dialog.confirm(
                    "Se borraran los tipos de resultados agregados",
                    "Cambiando a colección",
                    () => {
                        this.$f7.toast
                            .create({
                                text: "Creación de colección activada",
                                closeTimeout: 2000,
                                position: "center",
                            })
                            .open();
                        this.isCollection = true;
                        this.resultsInfo = {};
                        this.exam.only_group = 0;
                    },
                    () => {
                        this.isCollection = false;
                        toggle.checked = false;
                    }
                );
            } else {
                this.$f7.toast
                    .create({
                        text: "Creación de examen individual activada",
                        closeTimeout: 2000,
                        position: "center",
                    })
                    .open();
                this.isCollection = false;
                this.exam.associated_exams = [];
            }
        },

        addAssociatedExams(exams) {
            this.exam.associated_exams = exams.map((examCode, index) => {
                return {
                    exam: examCode,
                    order: index + 1,
                };
            });
        },

        addEveryday() {
            this.schedules = this.daysNames.map((day) => {
                let newDay = { day };
                return newDay;
            });
        },

        selectWeek(event) {
            if (event.target.checked) {
                this.selectorDays = false;
                this.addEveryday();
            } else {
                this.selectorDays = true;
                this.schedules = [];
            }
        },

        selectDay(index) {
            this.draftSchedules[index].checked = !this.draftSchedules[index].checked;
            this.dayReloader = this.dayReloader == "dayReloaderOn" ? "dayReloaderOff" : "dayReloaderOn";
        },

        selectSamples() {
            let value = this.$f7.smartSelect.get(".select-samples").getValue();
            value = value.map((id) => parseInt(id));
            if (this.isEditor) {
                this.examSamples.forEach((sample) => {
                    if (!value.includes(sample.sample_id)) {
                        this.deleteExamSample(sample.id);
                    }
                });
            }
            if (value.length > 0) {
                let newSamples = value.map((id) => {
                    const sample = this.examSamples.find((sample) => sample.sample_id == id);
                    if (sample) {
                        return sample;
                    } else {
                        const { name } = this.allSamples.find((sample) => sample.id == id);
                        const auxSample = {
                            name: name,
                            sample_id: id,
                            is_required: 0,
                            n_required_samples: 1,
                            min_volume: 0,
                            volume: 0, //evaluar si este campo debe mantenerse
                            unit_id: 0, //evaluar si este campo debe mantenerse
                            rejections: [],
                        };
                        return auxSample;
                    }
                });
                this.examSamples = newSamples;
            } else {
                this.examSamples = [];
            }
        },

        checkSelectedSamples(id) {
            return this.examSamples.find((item) => item.sample_id == id);
        },

        openSamplePopup(sample) {
            this.$f7.dialog.preloader("Cargando");
            this.$f7.popup.get(".sample-popup").open();
            this.selectedSample = sample;
        },

        addSampleInfo(sample) {
            let changedSample = this.examSamples.find((item) => item.sample_id === this.selectedSample.sample_id);
            changedSample = Object.assign(changedSample, sample);
            this.reloading = !this.reloading;
            if (this.isEditor) {
                this.editSample(changedSample);
            }
        },

        sendResultsInfo() {
            if (this.resultsInfo.resultsTypes) {
                let resultsTypes = {};

                this.resultsInfo.resultsTypes.forEach((result) => {
                    resultsTypes[result] = {
                        name: result,
                        values: {},
                    };

                    if (result === "cualitativo") {
                        this.resultsInfo.qualitativeValues.forEach((qualitativeValueObj) => {
                            // todo: setear un objeto { qualitativeValue: String, status: Boolean }
                            resultsTypes[result].values[qualitativeValueObj.qualitativeValue] = true;
                        });
                    }
                });

                // todo: enviar nuevo objeto a la api

                this.$firebase
                    .auth()
                    .currentUser.getIdToken()
                    .then(async (idToken) => {
                        const response = await this.updateInfo({ url: `exams/${this.selectedExam.code}/results_types`, data: resultsTypes, userToken: idToken });

                        const resultTypes = response.data.data;
                        
                        this.$store.commit("updateSelectedExamResultTypes", resultTypes);
                        this.$store.commit("setWhatTo", { what: "examResults", to: resultTypes });
                    })
                    .catch((error) => {
                        console.error(error);
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert("Hubo un error al actualizar los tipos de resultados. " + error);
                    });

                return Promise.resolve();
            }
        },

        createExam(newExam) {
            this.$f7.dialog.preloader("Guardando");
            newExam.exams_schedules = [];
            newExam.exams_samples = [];
            if (this.schedules.length > 0) {
                newExam.exams_schedules = this.schedules;
            } else {
                this.draftSchedules.forEach((schedule) => {
                    if (schedule.checked) {
                        delete schedule.checked;
                        newExam.exams_schedules.push(schedule);
                    }
                });
            }
            this.examSamples.forEach((sample) => {
                let newSample = { ...sample };
                delete newSample.name;
                newSample.rejections = newSample.rejections.map((rejection) => {
                    return { reason: rejection };
                });
                newExam.exams_samples.push(newSample);
            });
            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    this.userToken = idToken;
                    return this.createNewInfo({ url: "exams/samples/unified", data: newExam, userToken: this.userToken });
                })
                .then((resp) => {
                    let logInfo = {
                        who: this.profile_userInfo.uid,
                        wht: "create-exam",
                        whn: this.$moment().unix(),
                        dls: "Crea nuevo examen: " + newExam.name,
                        pth: this.baseUrl + "exams/samples/unified",
                    };
                    let logId = this.$firebase
                        .database()
                        .ref("/log")
                        .push().key;

                    Promise.all([
                        this.$firebase
                            .database()
                            .ref("log/" + logId)
                            .set(logInfo),
                    ])
                        .then(() => {
                            return this.getAPIInfo({ url: "exams/" + this.exam.code, userToken: this.userToken });
                        })
                        .then(async (resp) => {
                            const examId = resp.data.data.id;

                            try {
                                await this.$firebase.database().ref(`examProcessingConfig/${examId}`).set(this.examProcessingConfig);
                            } catch (error) {
                                this.$f7.dialog.close();
                                this.$f7.dialog.alert("Hubo un error al guardar la configuración de procesamiento del examen.");
                            }

                            this.$f7.dialog.close();
                            this.$store.commit("pushToArray", { array: "allExams", value: resp.data.data });
                            this.$store.commit("setObjTo", { what: "selectedExam", to: resp.data.data });
                            this.$f7.toast.create({ text: "Los datos se han guardado con éxito", closeTimeout: 2500 }).open();
                            this.$f7.views.main.router.navigate("/migration/mantenedores/exams", { reloadAll: true });
                        })
                        .catch((err) => {
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert(err.response.data.message || err.message, "Ops, ha ocurrido un error");
                        });
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.response.data.message || err.message, "Ops, ha ocurrido un error");
                });
        },

        editExam(editedExam) {
            this.$f7.dialog.preloader("Guardando");
            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    this.userToken = idToken;
                    return this.updateInfo({ url: "exams/" + this.selectedExam.code, data: editedExam, userToken: this.userToken });
                })
                .then(() => {
                    let logInfo = {
                        who: this.profile_userInfo.uid,
                        wht: "edit-exam",
                        whn: this.$moment().unix(),
                        dls: "Se edita información general de examen",
                        pth: this.baseUrl + "exams/" + this.selectedExam.code,
                    };
                    let logId = this.$firebase
                        .database()
                        .ref("/log")
                        .push().key;
                    this.$firebase
                        .database()
                        .ref("log/" + logId)
                        .set(logInfo)
                        .catch((err) => {
                            console.log(err.message);
                        });
                    this.$f7.dialog.close();
                    this.$f7.toast.create({ text: "Los datos se han guardado con éxito", closeTimeout: 2000, position: "center" }).open();
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.response.data.message || err.message, "Ops, ha ocurrido un error");
                });
        },

        validateExamData() {
            if (!this.exam.name) {
                this.$f7.dialog.alert("Debe ingresar un nombre para el examen", "Error");
            } else if (!this.exam.section_id) {
                this.$f7.dialog.alert("Debe seleccionar una sección", "Error");
            } else if (!this.exam.code) {
                this.$f7.dialog.alert("Debe ingresar un código para el examen", "Error");
            } else if (this.isCollection && this.exam.associated_exams.length < 2) {
                this.$f7.dialog.alert("Una colección debe tener al menos 2 exámenes asociados", "Error");
            } else if (
                !this.isEditor &&
                this.resultsInfo.resultsTypes &&
                this.resultsInfo.resultsTypes.includes("cualitativo") &&
                this.resultsInfo.qualitativeValues.length < 2
            ) {
                this.$f7.dialog.alert("Debe ingresar al menos 2 valores para los resultados cualitativos", "Error");
            } else {
                let newExam = { ...this.exam };
                !newExam.normal_processing_time && (newExam.normal_processing_time = 0);

                !newExam.entrega_normal && (newExam.entrega_normal = 0);
                !newExam.entrega_urgente && (newExam.entrega_urgente = 0);
                !newExam.entrega_stat && (newExam.entrega_stat = 0);

                !newExam.description && delete newExam.description;
                this.isCollection && (newExam.only_group = 0);

                newExam.resultsInfo = this.resultsInfo;

                !this.isEditor ? this.createExam(newExam) : this.editExam(newExam);
            }
        },

        editResults() {
            if (this.resultsInfo.resultsTypes.includes("cualitativo") && this.resultsInfo.qualitativeValues.length < 2) {
                this.$f7.dialog.alert("Debe ingresar al menos 2 valores para los resultados cualitativos", "Error");
                return;
            }
            this.$f7.dialog.preloader("Guardando");
            this.sendResultsInfo()
                .then(() => {
                    this.$f7.dialog.close();
                    this.$f7.toast.create({ text: "Los datos se han guardado con éxito", closeTimeout: 2000, position: "center" }).open();

                    this.resultTypesSavedToggle = !this.resultTypesSavedToggle;
                })
                .catch((error) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.response.data.message || error.message, "Ops, ha ocurrido un error");
                });
        },

        editSchedules() {
            this.$f7.dialog.preloader("Guardando");
            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    this.userToken = idToken;
                    let schedules = this.draftSchedules.map((schedule) => {
                        if (schedule.checked) {
                            delete schedule.checked;
                            if (!schedule.start_hour) {
                                delete schedule.start_hour;
                            }
                            if (!schedule.end_hour) {
                                delete schedule.end_hour;
                            }
                            if (schedule.id) {
                                let scheduleId = schedule.id;
                                delete schedule.id;
                                return this.updateInfo({
                                    url: `exams/${this.exam.code}/schedules/${scheduleId}`,
                                    data: schedule,
                                    userToken: this.userToken,
                                });
                            } else {
                                return this.createNewInfo({ url: `exams/${this.exam.code}/schedules`, data: schedule, userToken: this.userToken });
                            }
                        } else if (schedule.id) {
                            return this.deleteAPIInfo({ url: `exams/${this.exam.code}/schedules/${schedule.id}`, userToken: this.userToken });
                        }
                    });
                    Promise.all(schedules)
                        .then(() => {
                            //Se guarda información de edición en nodo log
                            let logInfo = {
                                who: this.profile_userInfo.uid,
                                wht: "edit-exam",
                                whn: this.$moment().unix(),
                                dls: "Se edita horarios de examen",
                                pth: `${this.baseUrl}exams/${this.exam.code}/schedules/`,
                            };
                            let logId = this.$firebase
                                .database()
                                .ref("/log")
                                .push().key;
                            this.$firebase
                                .database()
                                .ref("log/" + logId)
                                .set(logInfo)
                                .catch((err) => {
                                    console.log(err.message);
                                });

                            this.$f7.dialog.close();
                            this.$f7.toast.create({ text: "Los datos se han guardado con éxito", closeTimeout: 2000, position: "center" }).open();
                        })
                        .catch((err) => {
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert(err.response.data.message || err.message, "Ops, ha ocurrido un error");
                        });
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.response.data.message || err.message, "Ops, ha ocurrido un error");
                });
        },

        editAllSamples() {
            this.examSamples.forEach((sample) => this.editSample(sample));
        },

        deleteSample(index, id) {
            this.examSamples.splice(index, 1);
            this.isEditor && this.deleteExamSample(id);
        },

        deleteExamSample(id) {
            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    this.userToken = idToken;
                    return this.deleteAPIInfo({ url: `exams/samples/${id}`, userToken: this.userToken });
                })
                .then(() => {
                    //Se guarda información de edición en nodo log
                    let logInfo = {
                        who: this.profile_userInfo.uid,
                        wht: "edit-exam",
                        whn: this.$moment().unix(),
                        dls: "Se elimina muestra vinculada a examen",
                        pth: `${this.baseUrl}exams/samples/${id}`,
                    };
                    let logId = this.$firebase
                        .database()
                        .ref("/log")
                        .push().key;
                    this.$firebase
                        .database()
                        .ref("log/" + logId)
                        .set(logInfo)
                        .catch((err) => console.log(err.message));

                    this.$f7.toast.create({ text: "Los datos se han guardado con éxito", closeTimeout: 2000 }).open();
                })
                .catch((err) => {
                    this.$f7.dialog.alert(err.response.data.message || err.message, "Ops, ha ocurrido un error");
                });
        },

        editSample(sample) {
            let newSample = { ...sample };
            delete newSample.name;
            for (let attr in newSample) {
                (newSample[attr] === "" || newSample[attr] === null) && delete newSample[attr];
            }
            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    this.userToken = idToken;
                    if (newSample.id) {
                        const rejections = newSample.rejections.map((rejection) => {
                            return { reason: rejection.reason };
                        });
                        delete newSample.rejections;
                        const id = newSample.id;
                        delete newSample.id;

                        this.updateInfo({ url: `exams/samples/${id}`, data: newSample, userToken: this.userToken })
                            .then(() => {
                                //Se guarda información de edición en nodo log
                                let logInfo = {
                                    who: this.profile_userInfo.uid,
                                    wht: "edit-exam",
                                    whn: this.$moment().unix(),
                                    dls: "Se edita muestra vinculada a examen",
                                    pth: `${this.baseUrl}exams/samples/${id}`,
                                };
                                let logId = this.$firebase
                                    .database()
                                    .ref("/log")
                                    .push().key;
                                this.$firebase
                                    .database()
                                    .ref("log/" + logId)
                                    .set(logInfo)
                                    .catch((err) => {
                                        console.log(err.message);
                                    });

                                this.$f7.toast.create({ text: "Los datos se han guardado con éxito", closeTimeout: 2000, position: "center" }).open();
                            })
                            .catch((err) => this.$f7.dialog.alert(err.response.data.message || err.message, "Ops, ha ocurrido un error"));
                    } else {
                        const rejections = newSample.rejections.map((rejection) => {
                            return { reason: rejection };
                        });
                        delete newSample.rejections;
                        newSample.exam_id = this.examId;
                        this.createNewInfo({ url: "exams/samples", data: newSample, userToken: this.userToken })
                            .then((response) => {
                                sample.id = response.data.data.id;

                                //Se guarda información de edición en nodo log
                                let logInfo = {
                                    who: this.profile_userInfo.uid,
                                    wht: "edit-exam",
                                    whn: this.$moment().unix(),
                                    dls: "Se agrega muestra vinculada a examen",
                                    pth: `${this.baseUrl}exams/samples/${sample.id}`,
                                };
                                let logId = this.$firebase
                                    .database()
                                    .ref("/log")
                                    .push().key;
                                this.$firebase
                                    .database()
                                    .ref("log/" + logId)
                                    .set(logInfo)
                                    .catch((err) => {
                                        console.log(err.message);
                                    });

                                //Guardado de rechazos por cada muestra/examen
                                rejections.forEach((rejection) => {
                                    this.createNewInfo({
                                        url: `exams/${sample.id}/rejections`,
                                        data: rejection,
                                        userToken: this.userToken,
                                    })
                                        .then(() => {
                                            this.$f7.toast
                                                .create({
                                                    text: "Los datos se han guardado con éxito",
                                                    closeTimeout: 2000,
                                                    position: "center",
                                                })
                                                .open();
                                        })
                                        .catch((err) => {
                                            this.$f7.dialog.alert(err.response.data.message || err.message, "Ops, ha ocurrido un error");
                                        });
                                });
                            })
                            .catch((err) => {
                                this.$f7.dialog.alert(err.response.data.message || err.message, "Ops, ha ocurrido un error");
                            });
                    }
                });
        },

        updateExam() {
            if (this.isEditor) {
                this.$f7.dialog.preloader("Cargando");
                this.$firebase
                    .auth()
                    .currentUser.getIdToken()
                    .then((idToken) => {
                        this.userToken = idToken;
                        return this.getAPIInfo({ url: "exams/" + this.exam.code, userToken: this.userToken });
                    })
                    .then((resp) => {
                        this.$store.commit("replaceInArray", { array: "allExams", attr: "id", value: this.examId, newValue: resp.data.data });
                        this.$store.commit("setObjTo", { what: "selectedExam", to: resp.data.data });
                        this.$f7.dialog.close();
                    })
                    .catch((err) => {
                        this.$f7.dialog.alert(err.response.data.message || err.message, "Ops, ha ocurrido un error");
                    });
            }
        },

        getSelectedSamples() {
            //Se obtienen la muestras seleccionadas para mostrarlas en el after del smart-select
            if (this.isEditor) {
                const selectedSamples = this.examSamples.map((sample) => " " + sample.name);
                this.$f7.smartSelect.create({
                    el: ".select-samples",
                    setValueText: true,
                    formatValueText: (values) => {
                        return values.length > 0 ? values : selectedSamples;
                    },
                });
            }
        },
    },

    created() {
        if (this.selectedExam) {
            this.selectedExam.associations.length > 0 && (this.isCollection = true);
        }
    },

    async mounted() {
        this.$f7.dialog.preloader("Cargando");
        this.setAddOrEdit();
        this.$firebase
            .auth()
            .currentUser.getIdToken()
            .then((idToken) =>
                Promise.all([
                    this.getAPIInfo({ url: "samples/list", userToken: idToken }),
                    this.getAPIInfo({ url: "exam_processing_methods/list", userToken: idToken }),
                ])
            )
            .then((resp) => {
                this.$store.commit("setWhatTo", { what: "allSamples", to: resp[0].data.data });
                const enabledProcessingMethods = resp[1].data.data.filter((method) => {
                    return method.enabled === 1;
                });
                this.$store.commit("setWhatTo", { what: "processingMethods", to: enabledProcessingMethods });
                this.$f7.dialog.close();
            })
            .catch((err) => {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(err.response.data.message || err.message, "Ops, ha ocurrido un error");
            });

        if (this.selectedExam) {
            try {
                const snapshot = await this.$firebase.database().ref(`examProcessingConfig/${this.selectedExam.id}`).once("value");
                let processConfig = snapshot.val() || {};

                if (!processConfig.hasOwnProperty("days")) {
                    processConfig.days = {};
                }

                if (!processConfig.hasOwnProperty("cortes")) {
                    processConfig.cortes = {};
                }

                if (!processConfig.hasOwnProperty("horario_entrega")) {
                    processConfig.horario_entrega = "";
                }

                if (!processConfig.hasOwnProperty("demora")) {
                    processConfig.demora = {
                        normal: {
                            value: 0,
                            unit: "hours"
                        },
                        urgente: {
                            value: 0,
                            unit: "hours"
                        },
                        stat: {
                            value: 0,
                            unit: "hours"
                        },
                    };
                }

                this.$set(this, "examProcessingConfig", processConfig);
            } catch (error) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Hubo un error al cargar la configuracion de procesamiento del examen. " + error);
            }
        }
    },
};
</script>

<style scoped>
.exam-code-margin {
    margin: 6px 13px 6px 0px;
}

.collection-label {
    color: gray;
    font-size: 16px;
}
</style>
