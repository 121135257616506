<template>
    <f7-page name="new-unit">
        <f7-navbar v-if="!isEditor" title="Crear nueva unidad de medida" back-link></f7-navbar>
        <f7-navbar v-else title="Editar unidad de medida" back-link></f7-navbar>
    
        <f7-list no-hairlines-md>
            <f7-list-input
                label="Unidad"
                type="text"
                name="unitName"
                placeholder="Unidad"
                :value="unit.name"
                @input="unit.name = $event.target.value"
                required 
                validate
                error-message="Debe indicar el nombre de la unidad"
            >
            </f7-list-input>
        </f7-list>

        <f7-block>
            <f7-button
                v-if="!isEditor"  
                icon-material="playlist_add" 
                large 
                outline
                @click="addNewUnit()"
            >
                Agregar unidad
            </f7-button>
            <f7-button  
                v-else
                icon-material="edit" 
                large 
                outline
                @click="editUnit()"
            >
                Guardar cambios
            </f7-button>
        </f7-block>
    </f7-page>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data(){
        return {
            unit: {
                name: '',
                id: '',
            },
            isEditor: false,
        }
    },

    computed: {
        ...mapState(['selectedUnit', 'profile_userInfo']),
    },

    methods: {
        setAddorEdit(){
            if(this.selectedUnit.name){
                this.isEditor = true;
                this.unit = {...this.selectedUnit};
            }
        },

        reditectToUnitDetails(){
            this.$store.commit('setWhatTo',{what: 'selectedUnit', to: this.unit});
            this.$f7.views.main.router.navigate('/unit-details/');
        },

        addNewUnit(){
            if(!this.unit.name){
                this.$f7.dialog.alert('Es necesario ingresar una unidad de medida', 'Error')
                return
            }
            this.$f7.dialog.preloader('Guardando');
            const id = this.$firebase.database().ref('units/').push().key;
            this.unit.id = id;
            this.$firebase.database().ref('units/' + id).set(this.unit)
                .then(()=>{
                    this.$store.commit('updateObjectAttribute', {
                        obj: 'allUnits',
                        attr: id,
                        value: this.unit,
                    })
                    this.$f7.dialog.close();
                    this.$f7.toast.create({text: 'Los datos se han guardado con éxito', closeTimeout: 2500}).open();
                    
                    //Se agrega información al nodo log
                    let logInfo = {
                        who: this.profile_userInfo.uid,
                        wht: 'add-unit',
                        whn: this.$moment().unix(),    
                        dls: 'Crea nueva unidad de medida',
                        pth: 'units/' + this.unit.id,
                    }
                    let logId = this.$firebase.database().ref('/log').push().key;
                    this.$firebase.database().ref('log/' + logId).set(logInfo)
                        .catch((err)=>{console.log(err.message)})
                    
                    this.reditectToUnitDetails();
                })

                .catch((err)=>{
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, err.code);
                })
        },

        editUnit(){
            if(!this.unit.name){
                this.$f7.dialog.alert('Es necesario ingresar una unidad de medida', 'Error')
                return
            }
            this.$f7.dialog.preloader('Guardando');
            this.$firebase.database().ref('units/' + this.unit.id).set(this.unit)
                .then(()=>{
                    this.$store.commit('updateObjectAttribute', {
                        obj: 'allUnits',
                        attr: this.unit.id,
                        value: this.unit,
                    })
                    this.$f7.dialog.close();
                    this.$f7.toast.create({text: 'Los datos se han guardado con éxito', closeTimeout: 2500}).open();
                    
                    //Se agrega información al nodo log
                    let logInfo = {
                        who: this.profile_userInfo.uid,
                        wht: 'edit-unit',
                        whn: this.$moment().unix(),    
                        dls: 'Edita unidad de medida',
                        pth: 'units/' + this.unit.id,
                    }
                    let logId = this.$firebase.database().ref('/log').push().key;
                    this.$firebase.database().ref('log/' + logId).set(logInfo)
                        .catch((err)=>{console.log(err.message)})
                    
                    this.reditectToUnitDetails();
                })
                .catch((err)=>{
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, err.code);
                })
        }
    },

    mounted(){
        this.setAddorEdit();
    }
}
</script>
