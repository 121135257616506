<template>
    <f7-page name="member" @page:beforein="loadEsteMeta()">
        <f7-navbar :title="focused_member.displayName" back-link></f7-navbar>

        <f7-block class="memberimg text-align-center no-margin-bottom" inset><img :src="focused_member.photoURL" /></f7-block>
        <f7-block-title class="text-align-center margin-top-half">
            {{ focused_member.displayName }}
            <br />
            {{ focused_member.email }}
        </f7-block-title>

        <f7-row class="margin-top">
            <f7-col width="50" large="30" class="no-margin-top">
                <f7-list no-hairlines-md class="no-margin-top no-margin-bottom">
                    <f7-list-input
                        label="Prefijo miembro"
                        placeholder="Dr, TM, Sr, etc..."
                        type="text"
                        name="prefix"
                        maxlength="5"
                        :value="susprivs.prefix"
                        @input="susprivs.prefix = $event.target.value"
                    ></f7-list-input>
                </f7-list>
            </f7-col>
            <f7-col width="50" large="15">
                <f7-button small outline class="margin-top margin-right" @click="savePrefix()">Guardar</f7-button>
            </f7-col>
            <f7-col width="0" large="55"></f7-col>
        </f7-row>

        <f7-block-title>Privilegios Globales</f7-block-title>
        <f7-list simple-list>
            <f7-list-item v-for="unprivx in defsPrivx.globales" :key="unprivx.i">
                <span>{{ unprivx.n }}</span>
                <f7-toggle
                    v-if="unprivx.i !== 'members' || (unprivx.i == 'members' && profile_userInfo.uid != focused_member.uid)"
                    @change="cambiaEstePriv($event, unprivx)"
                    :checked="susprivs[unprivx.i]"
                ></f7-toggle>
                <f7-icon v-else :material="susprivs[unprivx.i] ? 'check' : 'close'"></f7-icon>
            </f7-list-item>
        </f7-list>
        <f7-block-title>Privilegios por Sección</f7-block-title>
        <f7-list v-for="seccion in sections" :key="seccion.id" class="no-margin iconalmedio">
            <f7-list-item divider :title="seccion.name"></f7-list-item>
            <f7-list-item v-for="unprivx in defsPrivx.bysection" :key="unprivx.i">
                <span>{{ unprivx.n }}</span>
                <f7-toggle
                    v-if="iamvisible(unprivx, seccion.id)"
                    @change="cambiaEstePrivUnity($event, unprivx.i, seccion.id)"
                    :checked="susprivs.vercolas && susprivs.vercolas[seccion.id] && susprivs.vercolas[seccion.id][unprivx.i]"
                ></f7-toggle>
                <f7-icon
                    v-if="susprivs.vercolas && susprivs.vercolas[seccion.id] && susprivs.vercolas[seccion.id][unprivx.i]"
                    slot="media"
                    class="text-color-green"
                    material="check"
                ></f7-icon>
                <f7-icon v-else slot="media" class="text-color-red" material="close"></f7-icon>
            </f7-list-item>
        </f7-list>
    </f7-page>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
    data: function() {
        return {
            susprivs: {},
        };
    },
    computed: {
        ...mapState(["focused_member", "defsPrivx", "sections", "profile_userInfo"]),
    },
    watch: {
        focused_member() {
            this.loadEsteMeta();
        },
    },
    mounted() {},
    methods: {
        iamvisible(unprivx, seccionid) {
            let susprivs = this.susprivs;
            if (unprivx.i == "ver") {
                if (
                    !susprivs.vercolas ||
                    !susprivs.vercolas[seccionid] ||
                    (susprivs.vercolas &&
                        susprivs.vercolas[seccionid] &&
                        !susprivs.vercolas[seccionid].con && !susprivs.vercolas[seccionid].fir && !susprivs.vercolas[seccionid].ing)
                ) {
                    return true;
                }
            } else if (unprivx.i == "fir") {
                return susprivs.vercolas && susprivs.vercolas[seccionid] && susprivs.vercolas[seccionid].ver;
            } else {
                return (
                    (!unprivx.r || (unprivx.r && susprivs.vercolas && susprivs.vercolas[seccionid] && susprivs.vercolas[seccionid][unprivx.r])) &&
                    (!unprivx.p || (unprivx.p && (!susprivs.vercolas || !susprivs.vercolas[seccionid] || !susprivs.vercolas[seccionid][unprivx.p])))
                );
            }
        },
        cambiaEstePriv(event, unprivx) {
            let targetchecked = event.target.checked;
            console.log("target es " + targetchecked + " prepirv es " + this.susprivs[unprivx.i]);
            let elHolderOriginal = this.susprivs[unprivx.i] || false;
            if (elHolderOriginal != targetchecked) {
                console.log("registrar cambio");
                console.log("cambiando privilegio", targetchecked, unprivx);
                let tosave = targetchecked;
                if (!tosave) {
                    tosave = null;
                }
                return this.$firebase
                    .database()
                    .ref("privx/" + this.focused_member.uid + "/" + unprivx.i)
                    .set(tosave)
                    .then(() => {
                        let toastBottom = this.$f7.toast.create({
                            text: "Cambio privilegio OK",
                            icon: '<i class="material-icons">check</i>',
                            closeTimeout: 3000,
                            destroyOnClose: true,
                        });
                        toastBottom.open();
                        this.susprivs[unprivx.i] = tosave;
                    })
                    .catch((error) => {
                        event.target.checked = !targetchecked;
                        this.$f7.dialog.alert(error.message, error.code);
                    });
            } else {
                console.log("solo cambio de ui");
            }
        },
        cambiaEstePrivUnity(event, privilegioid, seccionid) {
            let targetchecked = event.target.checked;
            let elHolderOriginal =
                (this.susprivs.vercolas && this.susprivs.vercolas[seccionid] && this.susprivs.vercolas[seccionid][privilegioid]) || false;
            console.log("cambindo priv", elHolderOriginal, targetchecked, seccionid, privilegioid);
            if (elHolderOriginal != targetchecked) {
                console.log("registrar cambio");
                console.log("cambiando privilegio", targetchecked, privilegioid, seccionid);
                let tosave = targetchecked;
                if (!tosave) {
                    tosave = null;
                }
                return this.$firebase
                    .database()
                    .ref("privx/" + this.focused_member.uid + "/vercolas/" + seccionid + "/" + privilegioid)
                    .set(tosave)
                    .then(() => {
                        let toastBottom = this.$f7.toast.create({
                            text: "Cambio privilegio OK",
                            icon: '<i class="material-icons">check</i>',
                            closeTimeout: 3000,
                            destroyOnClose: true,
                        });
                        toastBottom.open();
                        if (!this.susprivs.vercolas) {
                            let neo = { vercolas: {} };
                            this.susprivs = Object.assign({}, this.susprivs, neo);
                        }
                        if (!this.susprivs.vercolas[seccionid]) {
                            let neoxs = {};
                            neoxs[seccionid] = {};
                            this.susprivs.vercolas = Object.assign({}, this.susprivs.vercolas, neoxs);
                        }
                        let nuevlaors = {};
                        nuevlaors[privilegioid] = tosave;
                        this.susprivs.vercolas[seccionid] = Object.assign({}, this.susprivs.vercolas[seccionid], nuevlaors);
                        this.susprivs = Object.assign({}, this.susprivs);
                    })
                    .catch((error) => {
                        console.log(error);
                        event.target.checked = !targetchecked;
                        this.$f7.dialog.alert(error.message, error.code);
                    });
            } else {
                console.log("solo cambio de ui", targetchecked, privilegioid, seccionid);
            }
        },
        loadEsteMeta() {
            console.log("CARNGO INFO NUEVO MEMBER EN FOCUS");
            this.$f7.dialog.preloader("Cargando info...");
            return this.$firebase
                .database()
                .ref("privx/" + this.focused_member.uid)
                .once("value")
                .then((snaps) => {
                    let susprivx = snaps.val() || {};
                    this.susprivs = Object.assign({}, susprivx);
                    this.$f7.dialog.close();
                })
                .catch((error) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, error.code);
                });
        },
        savePrefix() {
            this.$f7.dialog.preloader("Guardando cambios...");
            this.$firebase
                .database()
                .ref("privx/" + this.focused_member.uid + "/prefix/")
                .set(this.susprivs.prefix)
                .then(() => {
                    this.$f7.dialog.close();
                    this.$f7.toast
                        .create({
                            text: "Cambio de prefijo OK",
                            icon: '<i class="material-icons">check</i>',
                            closeTimeout: 3000,
                            destroyOnClose: true,
                        })
                        .open();
                })
                .catch((error) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, error.code);
                });
        },
    },
};
</script>
<style>
.memberimg img {
    max-width: 50%;
    border-radius: 50%;
}
.iconalmedio .item-media .icon {
    margin: 0 auto !important;
}
</style>
