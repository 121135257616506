<template>
    <f7-page name="migration-admission-index">
        <f7-navbar>
            <f7-nav-left>
                <f7-link @click="backConfirm" icon-material="arrow_back"></f7-link>
            </f7-nav-left>
            <f7-nav-title>
                {{ isManagingSolicitud ? "Gestionando solicitud Inmunopax" : "Admisión" }}
            </f7-nav-title>
            <f7-nav-right v-if="!isManagingSolicitud">
                <f7-button @click="clearAdmission" outline class="margin-right" color="blue">
                    Limpiar
                </f7-button>
            </f7-nav-right>
        </f7-navbar>

        <f7-block class="no-margin no-padding">
            <f7c-alert v-if="admission.source === 'pax'" type="info" class="margin-half">
                Esta admisión fue creada desde Inmunopax
            </f7c-alert>

            <f7c-alert v-if="isEditing" type="info" class="margin-half">
                Estás en
                <b>modo edición</b>
                . Cualquier cambio que hagas solamente surgirá efecto una vez que
                <b>guardes los cambios</b>
                .
            </f7c-alert>

            <f7c-alert v-if="context.otSelected" type="warning" class="margin-half">
                Viendo la OT
                <b>{{ context.otSelected.code }}</b>
                . Solo podrás ver exámenes de la sección
                <b>{{ context.otSelected.section.name }}</b>
            </f7c-alert>

            <div v-if="isManagingSolicitud && !isEditing" class="margin-half">
                <template v-if="context.manageSolicitud.status === paxSolicitudStatusData.pendiente">
                    <f7c-alert v-if="context.manageSolicitud.inmuno_patient_id" type="success">
                        El paciente de esta solicitud fue encontrado en la base de datos de Inmunocel
                    </f7c-alert>
                    <f7c-alert v-else type="info">
                        Este paciente no existe en la base de datos de Inmunocel. Al aceptar esta solicitud de admisión el paciente será creado
                    </f7c-alert>
                </template>
                <f7c-alert type="success" v-else-if="context.manageSolicitud.status === paxSolicitudStatusData.admitida">
                    Esta solicitud fue admitida
                </f7c-alert>
                <f7c-alert type="danger" v-else-if="context.manageSolicitud.status === paxSolicitudStatusData.rechazada">
                    <div>Esta solicitud fue rechazada</div>
                    <div>
                        <b>Razón:</b>
                        {{ context.manageSolicitud.status_reason || "Sin razón" }}
                    </div>
                </f7c-alert>
            </div>

            <!-- ## DATOS DEL PACIENTE  ## -->
            <f7-card outline>
                <f7-card-content class="no-padding-top">
                    <patient-form
                        :is-managing-solicitud="isManagingSolicitud"
                        :admission-patient="admission.patient"
                        :is-editing-admission="isEditing"
                        @patient-found="handlePatientFound"
                        @patient-cleared="handleClearPatient"
                        @patient-edited="handlePatientEdited"
                        :reset="reset"
                    >
                        <h2>Datos del paciente</h2>
                    </patient-form>
                </f7-card-content>
            </f7-card>

            <!-- ## DATOS DE LA ADMISION ## -->
            <f7-card outline>
                <f7-card-content class="no-padding-top">
                    <h2>Datos de la admisión</h2>

                    <!-- Prioridad -->
                    <f7-row class="margin-bottom">
                        <f7-col width="30">
                            <div class="display-flex justify-content-space-between">
                                <div class="margin-right">
                                    <label>Normal</label>
                                    <f7-radio name="priority" @change="admission.priority = 1" :checked="admission.priority === 1"></f7-radio>
                                </div>
                                <div class="margin-right">
                                    <label>Urgente</label>
                                    <f7-radio name="priority" @change="admission.priority = 2" :checked="admission.priority === 2"></f7-radio>
                                </div>
                                <div class="margin-right">
                                    <label>STAT</label>
                                    <f7-radio name="priority" @change="admission.priority = 3" :checked="admission.priority === 3"></f7-radio>
                                </div>
                            </div>
                        </f7-col>

                        <!-- Procedencia -->
                        <f7-col width="70">
                            <label>Procedencia</label>
                            <f7-list class="list-no-margin-vertical no-hairlines">
                                <f7-list-item
                                    class="smart-select-list-item-sm"
                                    :title="(admission.institution && admission.institution.name) || 'Seleccione...'"
                                    smart-select
                                    :smart-select-params="{
                                        openIn: 'popup',
                                        searchbar: true,
                                        searchbarPlaceholder: 'Buscar institución',
                                        closeOnSelect: true,
                                        setValueText: false,
                                        popupCloseLinkText: 'Cerrar',
                                        pageTitle: 'Seleccione una institución',
                                    }"
                                    :disabled="!allActiveInstitutions"
                                >
                                    <select @change="handleInstitutionChange" :value="admission.institutionId">
                                        <option value="">Seleccione...</option>
                                        <option v-for="institution in allActiveInstitutions" :key="institution.id" :value="institution.id">
                                            {{ institution.name }}
                                        </option>
                                    </select>
                                </f7-list-item>
                            </f7-list>
                        </f7-col>
                    </f7-row>
                    <f7-row class="margin-bottom">
                        <f7-col>
                            <label>Diagnostico</label>
                            <f7-input
                                type="text"
                                @input="admission.diagnostic_obs = $event.target.value"
                                :value="admission.diagnostic_obs"
                            ></f7-input>
                        </f7-col>
                    </f7-row>

                    <h4>Médico solicitante</h4>
                    <f7-row class="margin-bottom">
                        <f7-col>
                            <label>RUN</label>
                            <f7-input
                                type="text"
                                @input="admission.doctor_rut = $event.target.value"
                                :value="admission.doctor_rut"
                                @keyup.native.enter="searchDoctor"
                                info="Presione ENTER para buscar"
                            ></f7-input>
                        </f7-col>
                        <f7-col>
                            <label>Nombre</label>
                            <f7-input type="text" @input="admission.doctor_name = $event.target.value" :value="admission.doctor_name"></f7-input>
                        </f7-col>
                        <f7-col>
                            <label>Contacto #1</label>
                            <f7-input
                                type="text"
                                @input="admission.doctor_contacto_1 = $event.target.value"
                                :value="admission.doctor_contacto_1"
                            ></f7-input>
                        </f7-col>
                        <f7-col>
                            <label>Contacto #2</label>
                            <f7-input
                                type="text"
                                @input="admission.doctor_contacto_2 = $event.target.value"
                                :value="admission.doctor_contacto_2"
                            ></f7-input>
                        </f7-col>
                    </f7-row>
                </f7-card-content>
            </f7-card>

            <!-- ## EXÁMENES ## -->
            <f7-card outline>
                <f7-card-content class="no-padding-top">
                    <h2>Exámenes</h2>

                    <h4>Añadiendo exámenes a la admisión</h4>
                    <exams-form :context="context" @exam-added="handleExamAdded"></exams-form>

                    <div v-if="Object.keys(processingConfigs || {}).length > 0" class="display-flex flex-direction-row-reverse">
                        <f7-button @click="clearProcessingConfigs" small>Limpiar cache de configuraciones</f7-button>
                    </div>

                    <div v-if="!admission.exams.length" class="text-align-center margin-vertical text-color-gray">
                        No hay examenes añadidos
                    </div>
                    <exams-table v-else :exams="admission.exams" @exam-status-log-added="handleExamStatusLogAdded">
                        <template #table-header>
                            <th>CB Muestras</th>
                            <th>Cod Exámen</th>
                            <th>Exámen</th>
                            <th>Seccion</th>
                            <th>Muestras</th>
                            <th>Estado</th>
                            <th>Fecha cargado</th>
                            <th>OT</th>
                            <th>Obs.</th>
                            <th>Fecha Entrega</th>
                            <th>Acciones</th>
                        </template>

                        <template #table-row="{ admissionExam, f }">
                            <tr>
                                <td>{{ f.admissionExamSamplesCodes(admissionExam) }}</td>
                                <td>{{ admissionExam.exam.code }}</td>
                                <td>{{ admissionExam.exam.name }}</td>
                                <td>{{ admissionExam.exam_section.name }}</td>
                                <td>
                                    <div class="display-flex">
                                        {{ f.admissionExamSamplesNames(admissionExam) }}
                                        <f7-link @click="editSample(admissionExam)" class="no-ripple margin-left-half" tooltip="Editar">
                                            <f7-icon material="edit" color="primary" size="16"></f7-icon>
                                        </f7-link>
                                    </div>
                                </td>
                                <td>{{ admissionExam.exam_status ? admissionExam.exam_status.name : "Sin estado" }}</td>
                                <td>
                                    <div v-if="admissionExam.createdAt">
                                        {{ $moment(admissionExam.createdAt, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY HH:mm") }}
                                    </div>
                                    <div v-else>Sin fecha de carga</div>

                                    <f7-link @click="editExamFechaCarga(admissionExam)" class="no-ripple margin-left-half" tooltip="Editar">
                                        <f7-icon material="edit" color="primary" size="16"></f7-icon>
                                    </f7-link>
                                </td>
                                <td>{{ f.admissionExamOtCode(admissionExam) }}</td>
                                <td>
                                    <div>
                                        {{ admissionExam.observations }}
                                    </div>
                                    <f7-link @click="editExamObs(admissionExam)" class="no-ripple margin-left-half" tooltip="Editar">
                                        <f7-icon material="edit" color="primary" size="16"></f7-icon>
                                    </f7-link>
                                </td>
                                <td>
                                    <div>
                                        {{ admissionExam.fecha_entrega || "Sin fecha" }}
                                    </div>
                                    <f7-link v-if="misprivx.modify_fecha_entrega" @click="editExamFechaEntrega(admissionExam)" class="no-ripple margin-left-half" tooltip="Editar">
                                        <f7-icon material="edit" color="primary" size="16"></f7-icon>
                                    </f7-link>
                                </td>
                                <td class="display-flex justify-content-center">
                                    <f7-button @click="f.viewExamLog(admissionExam)" small icon-material="reorder" tooltip="Flujograma"></f7-button>
                                    <f7-button
                                        small
                                        @click="printExamLabel(admissionExam)"
                                        icon-material="print"
                                        tooltip="Imprimir etiqueta"
                                        :disabled="disablePrintButton(admissionExam)"
                                    ></f7-button>
                                    <f7-button
                                        small
                                        @click="removeExam(admissionExam)"
                                        icon-material="delete"
                                        tooltip="Eliminar examen"
                                        color="red"
                                    ></f7-button>
                                </td>
                            </tr>
                        </template>
                    </exams-table>

                    <template v-if="admission.ots && admission.ots.length > 0">
                        <h4>Edita observaciones de las OT</h4>

                        <f7-card outline v-for="ot in admission.ots" :key="ot.id" class="margin-bottom">
                            <f7-card-content>
                                <div class="margin-bottom-half display-flex justify-content-space-between">
                                    <div>
                                        <b>{{ ot.code }}</b> ({{ ot.section ? ot.section.name : "Sin sección identificada" }})
                                    </div>

                                    <f7-button small @click="generateOTPdf(ot)" :id="`ot-print-button-${ot.id}`">Imprimir</f7-button>

                                    <ot-pdf :id="ot.code" style="display:none" :admission="admission" :ot="ot" :exams="getOTExams(ot)"></ot-pdf>
                                </div>

                                <f7-input
                                    type="text"
                                    :value="ot.observations || ''"
                                    @input="handleOTUpdated(ot.id, 'observations', $event.target.value)"
                                    placeholder="Escriba aqui las observaciones para esta OT"
                                ></f7-input>
                            </f7-card-content>
                        </f7-card>
                    </template>
                </f7-card-content>
            </f7-card>

            <template v-if="!isManagingSolicitud">
                <f7-button v-if="!isEditing" @click="save" fill class="margin-half">Grabar</f7-button>
                <f7-button v-else @click="update" fill class="margin-half">Guardar cambios</f7-button>
            </template>
            <f7-block v-else-if="context.manageSolicitud.status === paxSolicitudStatusData.pendiente">
                <f7-row>
                    <f7-col>
                        <f7-button fill color="primary" raised large @click="handleAcceptSolicitud">
                            <f7-icon material="done" class="margin-bottom-half"></f7-icon>
                            Aceptar solicitud
                        </f7-button>
                    </f7-col>
                    <f7-col>
                        <f7-button fill color="red" raised large @click="handleRejectSolicitud">
                            <f7-icon material="clear" class="margin-bottom-half"></f7-icon>
                            Rechazar
                        </f7-button>
                    </f7-col>
                </f7-row>
            </f7-block>
        </f7-block>

        <edit-sample-popup
            :opened="isEditSamplePopupOpened"
            :admission-exam="editingAdmissionExam"
            @sample-edited="handleSampleEdited"
            @popup-closed="isEditSamplePopupOpened = false"
        ></edit-sample-popup>
    </f7-page>
</template>

<script>
import PatientForm from "@/app/admission-migration/components/patient-form";
import ExamsForm from "@/app/admission-migration/components/exams-form";
import ExamsTable from "@/app/components-migration/exams-table";
import EditSamplePopup from "@/app/admission-migration/components/edit-sample-popup";
import OtPdf from "@/app/components-migration/ot-pdf.vue";
import { jsPDF } from "jspdf";
import Canvg from "canvg";

import { mapState, mapActions, mapGetters } from "vuex";

import { _formatName } from "@/app/helpers";
import { validate, clean } from "rut.js";
import { examStatuses, examStatusesNames } from "@/app/constants";

import { printermixin } from "../../mixins/printermixin";
import { paxhelpersmixin } from "../../mixins/paxhelpersmixin";

import { paxSolicitudStatus } from "@/app/constants";

export default {
    mixins: [printermixin, paxhelpersmixin],

    components: {
        PatientForm,
        ExamsForm,
        ExamsTable,
        EditSamplePopup,
        OtPdf,
    },

    data() {
        return {
            paxSolicitudStatusData: paxSolicitudStatus,

            reset: false,

            context: {},

            admission: {
                id: null,
                agreement_id: 0,
                background: null,
                createdAt: new Date(),
                created_by: null,
                diagnostic_obs: "",
                priority: 1,

                doctor_rut: "",
                doctor_name: "",
                doctor_contacto_1: "",
                doctor_contacto_2: "",

                exams: [],

                ots: [],

                institution_id: 0,
                institution: {},

                patient_id: 0,
                patient: {},
            },

            // Updating a created admission
            isEditing: false,

            isManagingSolicitud: false,

            updates: {
                admission: {},

                exams: {
                    add: {},
                    edit: {},
                    delete: {},
                },

                samples: {
                    add: {},
                },

                ots: {
                    edit: {},
                },
            },

            // Edit sample popup stuff
            isEditSamplePopupOpened: false,
            editingAdmissionExam: null,

            // Edit admission exam created at stuff
            editingExamCreatedAt: {},

            // Helpers
            somethingHasChanged: false,

            invalidReasons: [],

            formatName: _formatName,

            processingConfigs: {},
            labConfig: {},

            preloading: true,
        };
    },

    computed: {
        ...mapState("admission", ["viewingAdmission", "shouldAskToLeave"]),

        ...mapState(["allInstitutions", "profile_userInfo", "misprivx"]),

        ...mapGetters(["allActiveInstitutions"]),

        createAdmissionRequestBody() {
            if (this.isEditing) return {};

            const formattedAdmission = {
                agreement_id: this.admission.agreement_id,
                patient_id: this.admission.patient_id,
                institution_id: this.admission.institution_id,
                diagnostic_obs: this.admission.diagnostic_obs,
                priority: this.admission.priority,
                exams: [],

                doctor_rut: this.admission.doctor_rut || null,
                doctor_name: this.admission.doctor_name || null,
                doctor_contacto_1: this.admission.doctor_contacto_1 || null,
                doctor_contacto_2: this.admission.doctor_contacto_2 || null,
            };

            this.admission.exams.forEach((admissionExam) => {
                const formattedExam = {
                    id: admissionExam.exam.id,
                    samples: admissionExam.samples[0]
                        ? [
                              {
                                  id: admissionExam.samples[0].meta.id,
                                  quantity: admissionExam.samples[0].quantity,
                              },
                          ]
                        : [],
                    observations: admissionExam.observations || null,
                    createdAt: admissionExam.createdAt || null,
                    fecha_entrega: admissionExam.fecha_entrega || null,
                };

                formattedExam.status = {
                    id: admissionExam.current_status_id || null,
                    reason_id: admissionExam.status_log.length > 0 ? admissionExam.status_log[0].reason_id : null,
                    when: admissionExam.status_log.length > 0 ? admissionExam.status_log[0].when : null,
                };

                formattedAdmission.exams.push(formattedExam);
            });

            return formattedAdmission;
        },

        thereAreChanges() {
            if (
                Object.keys(this.updates.admission).length > 0 ||
                Object.keys(this.updates.exams.add).length > 0 ||
                Object.keys(this.updates.exams.edit).length > 0 ||
                Object.keys(this.updates.exams.delete).length > 0 ||
                Object.keys(this.updates.ots.edit).length > 0 ||
                Object.keys(this.updates.samples.add).length > 0
            ) {
                return true;
            }

            return false;
        },
    },

    watch: {
        thereAreChanges: {
            handler(val) {
                console.log("thereAreChanges watcher", val);
                if (val && !this.shouldAskToLeave) {
                    this.$store.commit("admission/set_should_ask_to_leave", true);
                }
            },
        },
        isEditing: {
            handler(val) {
                console.log("isEditing", val);
            },
        },
        "admission.priority": {
            async handler(val) {
                if (!this.preloading) {
                    if (this.admission.exams && this.admission.exams.length > 0) {
                        let index = 0;

                        for await (let admissionExam of this.admission.exams) {
                            const fechaCarga = this.$moment(admissionExam.createdAt).format("DD/MM/YYYY HH:mm:ss");

                            const newFechaEntrega = await this.calcularFechaEntrega(admissionExam.exam.id, fechaCarga);

                            this.$set(this.admission.exams[index], "fecha_entrega", newFechaEntrega);

                            index = index + 1;
                        }
                    }
                }

                if (this.isEditing) {
                    this.$set(this.updates.admission, "priority", val);

                    if (this.admission.exams && this.admission.exams.length > 0) {
                        this.admission.exams.forEach((admissionExam) => {
                            const examId = admissionExam.id;

                            if (this.updates.exams.add[examId]) {
                                // Es un examen que se creó en edición
                                this.$set(this.updates.exams.add[examId].info, "fecha_entrega", admissionExam.fecha_entrega);
                            } else {
                                // Es un examen que ya existía
                                if (!this.updates.exams.edit[examId]) this.$set(this.updates.exams.edit, examId, {});
                                if (!this.updates.exams.edit[examId].info) this.$set(this.updates.exams.edit[examId], "info", {});

                                this.$set(this.updates.exams.edit[examId].info, "fecha_entrega", admissionExam.fecha_entrega);
                            }
                        });
                    }
                }
            },
        },
        "admission.institution_id": {
            handler(val) {
                if (this.isEditing) this.$set(this.updates.admission, "institution_id", val);
            },
        },
        "admission.diagnostic_obs": {
            handler(val) {
                if (this.isEditing) this.$set(this.updates.admission, "diagnostic_obs", val);
            },
        },
        "admission.doctor_rut": {
            handler(val) {
                if (this.isEditing) this.$set(this.updates.admission, "doctor_rut", val);
            },
        },
        "admission.doctor_name": {
            handler(val) {
                if (this.isEditing) this.$set(this.updates.admission, "doctor_name", val);
            },
        },
        "admission.doctor_contacto_1": {
            handler(val) {
                if (this.isEditing) this.$set(this.updates.admission, "doctor_contacto_1", val);
            },
        },
        "admission.doctor_contacto_2": {
            handler(val) {
                if (this.isEditing) this.$set(this.updates.admission, "doctor_contacto_2", val);
            },
        },
        "admission.exams": {
            handler(val) {
                if (val.length && !this.admission.id && !this.shouldAskToLeave) {
                    this.$store.commit("admission/set_should_ask_to_leave", true);
                }
            },
        },
    },

    created() {
        if (this.viewingAdmission !== null) {
            const formattedAdmission = this.viewingAdmission.context && this.viewingAdmission.context.manageSolicitud ? this.viewingAdmission.admission : this.formatAdmissionDates(this.viewingAdmission.admission);

            this.admission = Object.assign({}, formattedAdmission);

            if (this.viewingAdmission.context) {
                this.context = Object.assign({}, this.viewingAdmission.context);
            }

            this.$store.commit("admission/set_viewing_admission", null);

            this.isManagingSolicitud = this.context.manageSolicitud ? true : false;

            if (!this.isManagingSolicitud) {
                // Hack: isEditing se seteaba a true antes que la admisión, por lo tanto se
                // populaba el objeto updates antes de tiempo
                setTimeout(() => {
                    this.isEditing = true;
                    this.preloading = false;
                }, 0);
            } else {
                this.admission.exams.forEach(async (admissionExam, index) => {
                    if (!admissionExam.fecha_entrega) {
                        const fechaEntrega = await this.calcularFechaEntrega(admissionExam.exam.id);

                        admissionExam.fecha_entrega = fechaEntrega;

                        this.$set(this.admission.exams, index, admissionExam);
                    }
                });
            }
        }

        this.getInstitutions();
    },

    methods: {
        ...mapActions(["getAPIInfo", "createNewInfo", "updateInfo", "deleteAPIInfo"]),

        formatAdmissionDates(admission) {
            const admissionCopy = Object.assign({}, admission);

            admissionCopy.exams.forEach(exam => {
                exam.createdAt = this.$moment(exam.createdAt, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm");
            });

            return admissionCopy;
        },

        // Admission related
        save() {
            if (!this.admissionIsValid()) {
                this.$f7.dialog.alert(this.invalidReasonsList());
                return;
            }

            this.$f7.dialog.preloader("Creando admisión...");

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.createNewInfo({ url: "admissions", data: this.createAdmissionRequestBody, userToken: idToken });
                })
                .then((response) => {
                    console.log("response data", response.data.data);

                    const createdAdmission = response.data.data.admission;

                    const formattedAdmission = this.formatAdmissionDates(createdAdmission);

                    Object.assign(this.admission, formattedAdmission);

                    this.printLabels();

                    // Hack
                    setTimeout(() => {
                        this.isEditing = true;

                        this.$store.commit("admission/set_should_ask_to_leave", false);

                        if (this.admission.ots.length) {
                            this.$f7.toast.show({
                                text: "Comenzando descarga de OTS...",
                                closeTimeout: 6000
                            });
                        }

                        this.admission.ots.forEach(async (ot, index) => {
                            await new Promise(resolve => setTimeout(resolve, 4000 * (index + 1)));

                            this.$$(`#ot-print-button-${ot.id}`).click();
                            console.log(`${ot.code} CLICKED!`);
                        });
                    }, 500);

                    this.$f7.dialog.close();
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Error. ", err.message);
                    console.error(err.message);
                });
        },

        // Edit admission related
        async update() {
            if (!this.thereAreChanges) return;

            this.$f7.dialog.preloader("Editando la admisión...");

            try {
                const promises = [];

                const token = await this.$firebase.auth().currentUser.getIdToken();

                if (Object.keys(this.updates.admission).length > 0) {
                    promises.push(
                        this.updateInfo({
                            url: `admissions/${this.admission.id}`,
                            userToken: token,
                            data: this.updates.admission,
                        })
                    );
                }

                if (Object.keys(this.updates.exams.add).length > 0) {
                    const addPromises = this.getAddPromises(this.updates.exams.add, token);
                    Array.prototype.push.apply(promises, addPromises);
                }

                if (Object.keys(this.updates.exams.edit).length > 0) {
                    const editPromises = this.getEditPromises(this.updates.exams.edit, token);
                    Array.prototype.push.apply(promises, editPromises);
                }

                if (Object.keys(this.updates.exams.delete).length > 0) {
                    const deletePromises = this.getDeletePromises(this.updates.exams.delete, token);
                    Array.prototype.push.apply(promises, deletePromises);
                }

                if (Object.keys(this.updates.ots.edit).length > 0) {
                    const editOTPromises = this.getEditOTPromises(this.updates.ots.edit, token);
                    Array.prototype.push.apply(promises, editOTPromises);
                }

                if (Object.keys(this.updates.samples.add).length > 0) {
                    const addSamplesPromises = this.getSamplesAddPromises(this.updates.samples.add, token);
                    Array.prototype.push.apply(promises, addSamplesPromises);
                }

                const responses = await Promise.all(promises);
                console.log("responses", responses);

                // Se editó correctamente, vamos a buscar la admisión/ots editada
                let queryString = `admissions/${this.admission.id}`;
                if (this.context.otSelected) queryString += `?ot=${this.context.otSelected.code}`;

                const response = await this.getAPIInfo({ url: queryString, userToken: token });
                const editedAdmission = response.data.data;

                console.log("edited admission", editedAdmission);

                const formattedAdmission = this.formatAdmissionDates(editedAdmission);

                Object.assign(this.admission, formattedAdmission);

                this.$store.commit("admission/set_updated_admission", formattedAdmission);

                // Print labels de nuevos exámenes
                if (Object.keys(this.updates.exams.add).length > 0) {
                    const examsAddedInThisUpdate = [];

                    Object.values(this.updates.exams.add).forEach(exam => {
                        examsAddedInThisUpdate.push(exam.info.id);
                    });

                    examsAddedInThisUpdate.forEach((examId, i) => {
                        const index = this.admission.exams.findIndex((e) => e.exam.id == examId);

                        if (index !== -1) {
                            setTimeout(() => {
                                this.makelabel(
                                    this.formatPatientName(this.admission.patient),
                                    this.formatPatientId(this.admission.patient),
                                    this.admission.patient.birth_date,
                                    this.suageEtiquetas(this.admission.patient.birth_date, "YYYY-MM-DD"),
                                    this.admission.exams[index].samples[0].sample_code,
                                    this.admission.exams[index].samples[0].meta.name,
                                    this.$moment(this.admission.exams[index].createdAt).format("DD/MM/YYYY HH:mm"),
                                    this.admission.exams[index].exam_section.id
                                    //sample.dateTime
                                );
                            }, i + 1);
                        }
                    });
                }

                this.resetUpdates();

                this.$store.commit("admission/set_should_ask_to_leave", false);

                this.$f7.dialog.close();
            } catch (error) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Error al editar la admisión." + error);
                console.error(error);
            }
        },
        getAddPromises(addObject, token) {
            console.log("@getAddPromises", addObject);

            const promises = [];

            Object.values(addObject).forEach((exam) => {
                const admissionExam = {};

                if (exam.info) {
                    if (exam.info.id) {
                        admissionExam.id = exam.info.id;
                    }
                    if (exam.info.status && exam.info.status.id) {
                        admissionExam.status = {};

                        admissionExam.status.id = exam.info.status.id;
                    }
                    if (exam.info.status && exam.info.status.reason_id) {
                        admissionExam.status.reason_id = exam.info.status.reason_id;
                    }
                    if (exam.info.createdAt) admissionExam.createdAt = exam.info.createdAt;

                    admissionExam.observations = exam.info.observations;
                    admissionExam.ot_id = exam.info.ot_id;

                    if (exam.info.fecha_entrega) admissionExam.fecha_entrega = exam.info.fecha_entrega;
                }

                if (exam.samples) {
                    admissionExam.samples = exam.samples;
                }

                console.log("admissionExam", admissionExam);

                promises.push(
                    this.createNewInfo({
                        url: `admission_exams/${this.admission.id}/exams`,
                        userToken: token,
                        data: {
                            exams: [admissionExam],
                        },
                    })
                );
            });

            return promises;
        },
        getEditPromises(editObject, token) {
            const promises = [];

            Object.keys(editObject).forEach((examId) => {
                let statusId = null;
                let createdAt = null;

                if (editObject[examId].info) {
                    if (editObject[examId].info.current_status_id) {
                        // Editando el estado del examen
                        statusId = editObject[examId].info.current_status_id;

                        console.log("@getEditPromises, un change status", [
                            { admission_exam_id: examId, reason_id: editObject[examId].info.reason_id },
                        ]);

                        promises.push(
                            this.updateInfo({
                                url: `admission_exams/status/${statusId}`,
                                userToken: token,
                                data: {
                                    exams: [{ admission_exam_id: examId, reason_id: editObject[examId].info.reason_id }],
                                },
                            })
                        );
                    }

                    if (editObject[examId].info.createdAt) {
                        createdAt = this.$moment(editObject[examId].info.createdAt, "YYYY-MM-DD HH:mm:ss").add(3, "hours").format(
                            "YYYY-MM-DD HH:mm:ss"
                        );

                        promises.push(
                            this.updateInfo({
                                url: `admission_exams/${this.admission.id}/exams/${examId}`,
                                userToken: token,
                                data: { createdAt },
                            })
                        );
                    }

                    if (editObject[examId].info.hasOwnProperty("observations")) {
                        promises.push(
                            this.updateInfo({
                                url: `admission_exams/${this.admission.id}/exams/${examId}`,
                                userToken: token,
                                data: {
                                    observations: editObject[examId].info.observations
                                },
                            })
                        );
                    }

                    if (editObject[examId].info.hasOwnProperty("fecha_entrega")) {
                        promises.push(
                            this.updateInfo({
                                url: `admission_exams/${this.admission.id}/exams/${examId}`,
                                userToken: token,
                                data: {
                                    fecha_entrega: editObject[examId].info.fecha_entrega
                                },
                            })
                        );
                    }
                }

                if (editObject[examId].samples) {
                    // Editando las muestras del examen
                    editObject[examId].samples.forEach((sample) => {
                        promises.push(
                            this.updateInfo({
                                url: `admission_exams/${this.admission.id}/exams/${examId}/samples/${sample.id}`,
                                userToken: token,
                                data: sample,
                            })
                        );
                    });
                }
            });

            return promises;
        },
        getDeletePromises(deleteObject, token) {
            const promises = [];

            Object.values(deleteObject).forEach((exam) => {
                promises.push(
                    this.deleteAPIInfo({
                        url: `admission_exams/${this.admission.id}/exams/${exam.id}`,
                        userToken: token,
                    })
                );
            });

            return promises;
        },
        getEditOTPromises(editObject, token) {
            const promises = [];

            Object.keys(editObject).forEach((otId) => {
                const otUpdates = this.updates.ots.edit[otId];

                promises.push(
                    this.updateInfo({
                        url: `ots/${otId}`,
                        userToken: token,
                        data: otUpdates,
                    })
                );
            });

            return promises;
        },
        getSamplesAddPromises(addObject, token) {
            console.log("@getSamplesAddPromsies, addObject", addObject);

            const promises = [];

            Object.keys(addObject).forEach((admissionExamId) => {
                const sampleId = addObject[admissionExamId].samples[0].meta.id;

                promises.push(
                    this.createNewInfo({
                        url: `admission_exams/${this.admission.id}/exams/${admissionExamId}/samples`,
                        userToken: token,
                        data: {
                            ids: [sampleId],
                        },
                    })
                );

                console.log("ids: ", [sampleId]);
            });
        },

        // Inmunopax solicitudes related
        async handleAcceptSolicitud() {
            try {
                const idToken = await this.$firebase.auth().currentUser.getIdToken();

                const patientId = this.context.manageSolicitud.inmuno_patient_id;
                const solicitudId = this.context.manageSolicitud.solicitud_id;

                if (patientId) {
                    this.$set(this.admission, "patient_id", patientId);
                    this.$set(this.admission.patient, "id", patientId);
                } else {
                    const patientForCreate = { ...this.admission.patient };

                    delete patientForCreate.document_type_info;

                    if (patientForCreate.document_type === 1) {
                        patientForCreate.document_number = clean(patientForCreate.document_number);
                    }

                    patientForCreate.birth_date = this.$moment(patientForCreate.birth_date, "YYYY-MM-DD").format("DD/MM/YYYY");

                    this.$f7.dialog.preloader("Creando paciente...");

                    await this.createAndUpdateAdmissionPatient(patientForCreate, idToken);

                    this.$f7.dialog.close();
                }

                this.removeStatusPreadmitido(this.createAdmissionRequestBody);

                this.$f7.dialog.preloader("Aceptando solicitud...");

                const createAdmissionRequestBody = {
                    ...this.createAdmissionRequestBody,
                    source: "pax",
                };

                const promises = [
                    this.createNewInfo({ url: "admissions", data: createAdmissionRequestBody, userToken: idToken }),
                    this.createNewInfo({
                        url: `pax/institutions/${this.admission.institution_id}/solicitudes/${solicitudId}/status`,
                        data: { status: this.paxSolicitudStatusData.admitida },
                        userToken: idToken,
                    }),
                ];

                const responses = await Promise.all(promises);

                const admissionData = responses[0].data.data;

                const formattedAdmission = this.formatAdmissionDates(admissionData.admission);

                this.$store.commit("solicitudes/set_updated_solicitud", {
                    solicitud_id: this.context.manageSolicitud.solicitud_id,
                    admissionData,
                    status: {
                        code: this.paxSolicitudStatusData.admitida,
                    },
                });

                this.$f7.dialog.close();

                this.$set(this, "admission", formattedAdmission);
                this.$set(this.context.manageSolicitud, "status", this.paxSolicitudStatusData.admitida);

                this.printLabels();

                setTimeout(() => {
                    this.isEditing = true;

                    this.$store.commit("admission/set_should_ask_to_leave", false);

                    if (this.admission.ots.length) {
                        this.$f7.toast.show({
                            text: "Comenzando descarga de OTS...",
                            closeTimeout: 6000
                        });
                    }

                    this.admission.ots.forEach(async (ot, index) => {
                        await new Promise(resolve => setTimeout(resolve, 4000 * (index + 1)));

                        this.$$(`#ot-print-button-${ot.id}`).click();
                        console.log(`${ot.code} CLICKED!`);
                    });
                }, 500);

                //this.$f7.views.main.router.back();
            } catch (error) {
                console.error(error);
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Hubo un error al aceptar la solicitud. " + error);
            }
        },
        handleRejectSolicitud() {
            this.$f7.dialog.prompt("Indique la razón de rechazo", (reason) => {
                if (!reason) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Debe indicar una razón de rechazo");
                    return;
                }

                const { solicitud_id } = this.context.manageSolicitud;

                this.$f7.dialog.preloader("Rechazando la solicitud...");

                this.$firebase
                    .auth()
                    .currentUser.getIdToken()
                    .then((idToken) => {
                        return this.createNewInfo({
                            url: `pax/institutions/${this.admission.institution_id}/solicitudes/${solicitud_id}/status`,
                            data: { status: 3, reason: reason },
                            userToken: idToken,
                        });
                    })
                    .then((response) => {
                        this.$store.commit("solicitudes/set_updated_solicitud", {
                            solicitud_id: this.context.manageSolicitud.solicitud_id,
                            status: {
                                code: this.paxSolicitudStatusData.rechazada,
                                reason,
                            },
                        });

                        this.$f7.views.main.router.back();

                        this.$f7.dialog.close();
                    })
                    .catch((error) => {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert("Ha ocurrido un error al rechazar esta solicitud. " + error);
                    });
            });
        },
        // Para solicitudes pax pendientes
        removeStatusPreadmitido(createAdmissionRequestBody) {
            createAdmissionRequestBody.exams.forEach((exam) => {
                if (exam.status.id === examStatuses.preadmitido)
                    exam.status.id = examStatuses.admitido;
            });
        },

        getOTExams(ot) {
            return this.admission.exams.filter((e) => e.section_id === ot.section_id);
        },

        async generateOTPdf(ot) {
            if (this.thereAreChanges) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Debe guardar los cambios antes de imprimir la OT");
                return;
            }

            const otCode = ot.code;

            this.$f7.dialog.preloader("Imprimiendo OT");

            const source = window.document.getElementById(otCode);

            source.style.setProperty("display", "block");

            // replace the svg barcode with an Image
            if (document.getElementById(`barcode-${otCode}`)) {
                let barcodeSvg = document.getElementById(`barcode-${otCode}`).firstChild.outerHTML;

                if (barcodeSvg) {
                    barcodeSvg = barcodeSvg.replace(/\r?\n|\r/g, "").trim();
                }

                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                const v = Canvg.fromString(ctx, barcodeSvg);
                await v.render();

                const imgData = canvas.toDataURL("image/png");
                const img = document.createElement("img");
                img.src = imgData;
                document.getElementById(`barcode-${otCode}`).replaceWith(img);
            }

            const doc = new jsPDF("landscape", "pt", "a4");
            doc.html(source, {
                html2canvas: {
                    scale: 0.95,
                    letterRendering: true,
                },
                callback: (doc) => {
                    doc.autoPrint();
                    window.open(doc.output('bloburl'), '_blank');
                    // doc.save(`${otCode}.pdf`);
                    source.style.setProperty("display", "none");
                    this.$f7.dialog.close();
                },
                x: 20,
                y: 5,
            });
        },

        printLabels() {
            let i = 0;

            const patient = this.admission.patient;

            this.admission.exams.forEach((exam) => {
                exam.samples.forEach((sample) => {
                    setTimeout(() => {
                        this.makelabel(
                            this.formatPatientName(patient),
                            this.formatPatientId(patient),
                            patient.birth_date,
                            this.suageEtiquetas(patient.birth_date, "YYYY-MM-DD"),
                            sample.sample_code,
                            sample.meta.name,
                            this.$moment(this.admission.createdAt).format("DD/MM/YYYY HH:mm"),
                            exam.section_id
                            //sample.dateTime
                        );

                        i++;
                    }, i + 1);
                });
            });
        },

        printExamLabel(admissionExam) {
            if (!(admissionExam.samples && admissionExam.samples.length > 0 && admissionExam.samples[0].sample_code)) {
                return;
            }

            let i = 0;
            const patient = this.admission.patient;

            admissionExam.samples.forEach((sample) => {
                setTimeout(() => {
                    this.makelabel(
                        this.formatPatientName(patient),
                        this.formatPatientId(patient),
                        patient.birth_date,
                        this.suageEtiquetas(patient.birth_date, "YYYY-MM-DD"),
                        sample.sample_code,
                        sample.meta.name,
                        this.$moment(this.admission.createdAt).format("DD/MM/YYYY HH:mm"),
                        admissionExam.section_id,
                    );
                    i++;
                }, i + 1);
            });
        },

        disablePrintButton(admissionExam) {
            if (!admissionExam.samples) {
                return true;
            }
            if (admissionExam.samples.length === 0) {
                return true;
            }
            if (!admissionExam.samples[0].sample_code) {
                return true;
            }

            return false;
        },

        // Patient related
        handlePatientFound(patient) {
            this.admission.patient_id = patient ? patient.id : 0;
            this.admission.patient = patient;
        },

        // Institution related
        getInstitutions() {
            if (this.allInstitutions) {
                return;
            }

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: "institutions", userToken: idToken });
                })
                .then((response) => {
                    const institutions = response.data.data;
                    this.$store.commit("setWhatTo", { what: "allInstitutions", to: institutions });

                    this.$f7.dialog.close();
                })
                .catch((err) => {
                    console.error(err);
                    this.$f7.dialog.alert("Error " + err);
                });
        },
        handleInstitutionChange(ev) {
            const id = Number(ev.target.value);

            const institution = this.allActiveInstitutions.find((i) => i.id === id);

            this.admission.institution_id = id;
            this.admission.institution = institution;

            console.log("this.admission", this.admission);
        },

        // Exam related
        async handleExamAdded(data) {
            console.log("@exam-added in index", data);

            const { exam, exam_section, samples } = data;

            let fechaEntrega = "";

            if (samples.length > 0) {
                fechaEntrega = await this.calcularFechaEntrega(exam.id);
            }

            console.log("fecha de entrega calculada", fechaEntrega);

            const id = this.generateUUID();

            const admissionExam = {
                id,
                exam,
                exam_section,
                samples,

                fecha_entrega: fechaEntrega,

                admission: {
                    createdAt: this.admission.createdAt,
                    patient: this.admission.patient,
                },

                createdAt: new Date(),

                status_log: [],
            };

            let currentStatusId, examStatus, statusLog;

            if (samples.length === 0) {
                currentStatusId = examStatuses.examen_pendiente;

                const statusNameId = Object.keys(examStatuses).find((key) => examStatuses[key] === examStatuses.examen_pendiente);
                const statusName = examStatusesNames[statusNameId];

                examStatus = { id: examStatuses.examen_pendiente, name: statusName };

                const defaultPendingReason = {
                    id: 1,
                    name: "Exámen sin muestra",
                };

                statusLog = {
                    exam_status_meta: examStatus,
                    status_id: examStatuses.examen_pendiente,
                    when: new Date(),
                    whoName: this.profile_userInfo.displayName,
                    reason_id: defaultPendingReason.id,
                    pending_reasons_meta: defaultPendingReason,
                };
            } else {
                currentStatusId = examStatuses.admitido;

                const statusNameId = Object.keys(examStatuses).find((key) => examStatuses[key] === examStatuses.admitido);
                const statusName = examStatusesNames[statusNameId];

                examStatus = { id: examStatuses.admitido, name: statusName };

                statusLog = {
                    exam_status_meta: examStatus,
                    status_id: examStatuses.admitido,
                    when: new Date(),
                    whoName: this.profile_userInfo.displayName,
                };
            }

            admissionExam.current_status_id = currentStatusId;
            admissionExam.exam_status = examStatus;
            admissionExam.status_log = [statusLog];

            this.admission.exams.push(admissionExam);

            console.log("admission.exams", this.admission.exams);

            // Si está en modo edición, agregarlo a updates
            if (this.isEditing) {
                // Buscamos su ot id
                let ot_id = null;

                if (this.context.otSelected) {
                    ot_id = this.context.otSelected.id;
                } else {
                    const examSection = admissionExam.exam_section.id;

                    ot_id = this.findExamOtId(examSection);
                }

                this.$set(this.updates.exams.add, id, {
                    info: {
                        id: exam.id,
                        ot_id,
                        fecha_entrega: admissionExam.fecha_entrega
                    },
                    samples: samples.length ? [{ id: samples[0].meta.id, quantity: samples[0].quantity }] : [],
                });

                if (!samples.length) {
                    this.$set(this.updates.exams.add[id].info, "status", {
                        id: admissionExam.status_log[0].status_id,
                        reason_id: admissionExam.status_log[0].reason_id,
                        when: admissionExam.status_log[0].when,
                    });
                }
            }
        },
        async calcularFechaEntrega(examId, initialDate) {
            this.$f7.dialog.preloader("Calculando fecha de entrega para este examen");

            const selectedPriority = this.admission.priority;

            try {
                if (Object.keys(this.processingConfigs[examId] || {}).length > 0) {
                    console.log("processing config ya fetcheada usando cache");
                } else {
                    console.log("fetcheando processing config");
                    const snapshot = await this.$firebase.database().ref(`examProcessingConfig/${examId}`).once("value");
                    const processingConfig = snapshot.val() || {};

                    this.$set(this.processingConfigs, examId, processingConfig);
                }

                if (Object.keys(this.labConfig || {}).length > 0) {
                    console.log("lab config ya fetcheada usando cache");
                } else {
                    console.log("fetcheando lab config");
                    const snapshot = await this.$firebase.database().ref("labConfig").once("value");
                    const labConfig = snapshot.val() || {};

                    this.$set(this, "labConfig", labConfig);
                }
            } catch (error) {
                console.error(error);
                this.$f7.dialog.close();
                this.$f7.dialog.alert("No se ha podido cargar la configuración del procesamiento del exámen. Se procedera con configuración default");
            }

            let examProcessConfig = this.processingConfigs[examId] || {};
            let labConfig = this.labConfig || {};

            //
            let fromDate = initialDate ? this.$moment(initialDate, "DD/MM/YYYY HH:mm:ss") : this.$moment();

            if (!fromDate.isValid()) {
                fromDate = this.$moment();
            }

            if (Object.keys(examProcessConfig).length === 0) {
                this.$f7.dialog.close();
                this.$f7.toast.show({
                    text: "El examen añadido no tiene configuracion de tiempos de procesamiento",
                    closeTimeout: 3000,
                });
                return fromDate.format("DD/MM/YYYY HH:mm");
            }

            const { days, cortes, demora, horario_entrega } = examProcessConfig;
            const { apertura } = labConfig.horarios || {};

            const defaultDemora = { unit: "hours", value: 0 };
            let demoraByPriority = defaultDemora;

            if (demora) {
                if (selectedPriority === 1) {
                    demoraByPriority = demora.normal || defaultDemora;
                } else if (selectedPriority === 2) {
                    demoraByPriority = demora.urgente || defaultDemora;
                } else if (selectedPriority === 3) {
                    demoraByPriority = demora.stat || defaultDemora;
                }
            }

            console.log("demora segun prioridad", demoraByPriority);

            const format = "DD/MM/YYYY HH:mm";

            let calculatedDate = fromDate;

            const today = fromDate.isoWeekday();

            const isExamProcessedToday = days ? days[`d_${today}`] === true : false;
            const cortesArr = Object.keys(cortes || {})
                .map((time) => Number(time.replace(":", "")))
                .sort((a, b) => a - b);

            const processingDaysNumbers = Object.keys(days || {}).map(d => Number(d.split("_")[1]))

            const horarioEntrega = horario_entrega || apertura;

            const primerCorte = cortesArr.length ? cortesArr[0] : Number(horarioEntrega.replace(":", ""));

            console.log({ cortesArr });

            if (isExamProcessedToday) {
                // Ver si tiene corte
                const corteMasCercano = this.getCorteMasCercano(cortesArr, calculatedDate);

                if (corteMasCercano) {
                    calculatedDate = calculatedDate.set({
                        hour: corteMasCercano.hora,
                        minute: corteMasCercano.minuto
                    });
                } else {
                    const count = this.getConteoParaSiguienteDiaHabil(today, processingDaysNumbers, true);

                    calculatedDate = calculatedDate.add(count, "days");

                    const {hora, minuto} = this.formatTimeNumberToString(primerCorte)

                    calculatedDate = calculatedDate.set({
                        hour: hora,
                        minute: minuto,
                    });
                }
            } else {
                // Buscar el próximo día de procesamiento
                const count = this.getConteoParaSiguienteDiaHabil(today, processingDaysNumbers);

                calculatedDate = calculatedDate.add(count, "days");

                const {hora, minuto} = this.formatTimeNumberToString(primerCorte)

                calculatedDate = calculatedDate.set({
                    hour: hora,
                    minute: minuto,
                });
            }

            // Añadir demora por prioridad
            calculatedDate = calculatedDate.add(demoraByPriority.value, demoraByPriority.unit);

            // Establecer horario de entrega
            const entregaHour = Number(horario_entrega.split(":")[0]);
            const entregaMin = Number(horario_entrega.split(":")[1]);

            calculatedDate = calculatedDate.set({
                hour: entregaHour,
                minute: entregaMin,
            });

            const { isFueraDeHorario, conteoSigDiaHabil } = this.checkFechaFueraDeHorario(calculatedDate, labConfig.horarios);

            if (isFueraDeHorario && !horario_entrega) {
                calculatedDate = calculatedDate.add(conteoSigDiaHabil, "days");

                const aperturaHour = Number(apertura.split(":")[0]);
                const aperturaMin = Number(apertura.split(":")[1]);

                calculatedDate = calculatedDate.set({
                    hour: aperturaHour,
                    minute: aperturaMin,
                });
            }

            console.log({ isFueraDeHorario, conteoSigDiaHabil });

            this.$f7.dialog.close();

            return calculatedDate.format(format);
        },

        getConteoParaSiguienteDiaHabil(dayNumber, labDays, considerarHoy = false) {
                console.log("getSiguienteDiaHabil", { dayNumber, labDays });
                if (!labDays.length) return 0;

                let count = 0;
                let resetI = false;

                let c = considerarHoy;

                for (let i = dayNumber; i <= 7; i++) {
                    if (!c && labDays.includes(i)) {
                        return count;
                    }

                    c = false;
                    count = count + 1;

                    if (i === 7) {
                        i = 0;

                        if (!resetI) resetI = true;
                    }
                }
        },
        checkFechaFueraDeHorario(date, horariosLab) {
            const { apertura, cierre, labDays } = horariosLab;

            const labDaysArr = labDays.split(",").map((day) => Number(day));

            const formattedApertura = Number(apertura.replace(":", ""));
            const formattedCierre = Number(cierre.replace(":", ""));
            const formattedHoraActual = Number(date.format("HHmm"));
            const day = date.isoWeekday();

            if (!labDaysArr.includes(day) || (formattedHoraActual < formattedApertura || formattedHoraActual >= formattedCierre)) {
                const count = this.getConteoParaSiguienteDiaHabil(day, labDaysArr, true);

                return {
                    isFueraDeHorario: true,
                    conteoSigDiaHabil: count,
                };
            }

            return { isFueraDeHorario: false };
        },

        formatTimeNumberToString(time) {
            let timeAsString = time.toString();

            if (timeAsString.length === 3) {
                timeAsString = `0${timeAsString}`;
            }

            return {
                hora: `${timeAsString[0]}${timeAsString[1]}`,
                minuto: `${timeAsString[2]}${timeAsString[3]}`
            };
        },

        getCorteMasCercano(cortesArr, fromDate) {
            let date = fromDate || this.$moment();

            if (!cortesArr.length) {
                return {
                    hora: date.format("HH"),
                    minuto: date.format("mm"),
                }
            }

            const time = date.format("HH:mm");
            const [ hora, minuto ] = time.split(":");

            const horaActual = Number(`${hora}${minuto}`);

            const filteredCortes = cortesArr.filter((horaCorte) => horaCorte > horaActual);

            if (filteredCortes.length === 0) {
                return null;
            }

            return this.formatTimeNumberToString(filteredCortes[0]);
        },

        handleExamStatusLogAdded(payload) {
            console.log("@exam-status-log-added at index", payload);

            const { examId, reason, statusId } = payload;

            // Agregar localmente
            const index = this.admission.exams.findIndex((e) => e.id == examId);

            this.admission.exams[index].current_status_id = statusId;

            const statusNameId = Object.keys(examStatuses).find((key) => examStatuses[key] === statusId);
            const statusName = examStatusesNames[statusNameId];

            const exam_status = { id: statusId, name: statusName };

            this.admission.exams[index].exam_status = exam_status;

            const status_log = {
                exam_status_meta: exam_status,
                status_id: statusId,
                when: new Date(),
                whoName: this.profile_userInfo.displayName,
                reason_id: reason ? reason.id : null,
            };

            if (examStatuses.examen_pendiente === statusId) {
                this.$set(status_log, "pending_reasons_meta", reason);
            } else if (examStatuses.examen_rechazado === statusId) {
                this.$set(status_log, "rejection_reasons_meta", reason);
            }

            if (this.isEditing) {
                // Al estar editando se agrega como un estado más (acumulativo)
                this.admission.exams[index].status_log.unshift(status_log);

                if (this.updates.exams.add[examId]) {
                    // Es un examen que se creó en edición

                    // To do: hacer esto un array de statuses?
                    this.$set(this.updates.exams.add[examId].info, "status", {});

                    this.$set(this.updates.exams.add[examId].info.status, "id", statusId);
                    this.$set(this.updates.exams.add[examId].info.status, "reason_id", status_log.reason_id);
                    this.$set(this.updates.exams.add[examId].info.status, "when", status_log.when);
                } else {
                    if (!this.updates.exams.edit[examId]) this.$set(this.updates.exams.edit, examId, {});
                    if (!this.updates.exams.edit[examId].info) this.$set(this.updates.exams.edit[examId], "info", {});

                    this.$set(this.updates.exams.edit[examId].info, "current_status_id", statusId);
                    this.$set(this.updates.exams.edit[examId].info, "reason_id", status_log.reason_id);
                }
            } else {
                // Si no se está editando, se toma solamente el último estado declarado
                this.$set(this.admission.exams[index], "status_log", [status_log]);
            }
        },
        removeExam(admissionExam) {
            if (this.isEditing) {
                const MIN_EXAMS = 2;

                if (this.admission.ots && this.admission.ots.length && admissionExam.ot) {
                    const editingOt = this.admission.ots.find((ot) => ot.id == admissionExam.ot.id);
                    const otExams = this.admission.exams.filter((e) => (e.ot && e.ot.id == admissionExam.ot.id));

                    if (editingOt && otExams.length < MIN_EXAMS) {
                        this.$f7.dialog.alert(`No puedes eliminar un examen de una OT que tiene 1 solo examen (OT: ${editingOt.code}).`);
                        return;
                    }
                } else {
                    if (this.admission.exams.length < MIN_EXAMS) {
                        this.$f7.dialog.alert("No puedes eliminar el único examen de la admisión.");
                        return;
                    }
                }
            }

            this.$f7.dialog.confirm("El examen será eliminado", "¿Estás seguro?", () => {
                const index = this.admission.exams.findIndex((e) => e.id === admissionExam.id);
                this.admission.exams.splice(index, 1);

                if (this.isEditing) {
                    if (this.updates.exams.add[admissionExam.id]) {
                        // Es un examen agregado en esta instancia de MODO EDICION
                        this.$delete(this.updates.exams.add, admissionExam.id);
                    } else {
                        // Es un examen creado anteriormente
                        this.$delete(this.updates.exams.edit, admissionExam.id);

                        // Agregarlo a updates.exams.delete
                        this.$set(this.updates.exams.delete, admissionExam.id, { id: admissionExam.id });
                    }
                }
            });
        },
        editExamObs(admissionExam) {
            this.$f7.dialog.prompt(
                "Indique la observación del examen",
                (obs) => {
                    this.$set(admissionExam, "observations", obs.length ? obs : null);

                    if (this.isEditing) {
                        // Agregar a objeto updates
                        const examId = admissionExam.id;

                        if (this.updates.exams.add[examId]) {
                            // Es un examen que se creó en edición
                            this.$set(this.updates.exams.add[admissionExam.id].info, "observations", admissionExam.observations);
                        } else {
                            // Es un examen que ya existía
                            if (!this.updates.exams.edit[examId]) this.$set(this.updates.exams.edit, examId, {});
                            if (!this.updates.exams.edit[examId].info) this.$set(this.updates.exams.edit[examId], "info", {});

                            this.$set(this.updates.exams.edit[examId].info, "observations", admissionExam.observations);
                        }
                    }
                },
                () => {},
                admissionExam.observations || ""
            );
        },
        editExamFechaEntrega(admissionExam) {
            if (!this.misprivx.modify_fecha_entrega) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("No tienes privilegio para realizar esta accion");
                return;
            }

            this.$f7.dialog.prompt(
                "Indique la fecha de entrega",
                (fechaEntrega) => {
                    const isValid = this.$moment(fechaEntrega, "DD/MM/YYYY HH:mm", true).isValid();

                    if (!isValid) {
                        this.editExamFechaEntrega(admissionExam);

                        return;
                    }

                    this.$set(admissionExam, "fecha_entrega", fechaEntrega.length ? fechaEntrega : null);

                    if (this.isEditing) {
                        // Agregar a objeto updates
                        const examId = admissionExam.id;

                        if (this.updates.exams.add[examId]) {
                            // Es un examen que se creó en edición
                            this.$set(this.updates.exams.add[admissionExam.id].info, "fecha_entrega", admissionExam.fecha_entrega);
                        } else {
                            // Es un examen que ya existía
                            if (!this.updates.exams.edit[examId]) this.$set(this.updates.exams.edit, examId, {});
                            if (!this.updates.exams.edit[examId].info) this.$set(this.updates.exams.edit[examId], "info", {});

                            this.$set(this.updates.exams.edit[examId].info, "fecha_entrega", admissionExam.fecha_entrega);
                        }
                    }
                },
                () => {},
                admissionExam.fecha_entrega || ""
            );
        },
        editExamFechaCarga(admissionExam) {
            this.$f7.dialog.prompt(
                "Indique la fecha de carga",
                async (fechaCarga) => {
                    const isValid = this.$moment(fechaCarga, "DD/MM/YYYY HH:mm", true).isValid();

                    if (!isValid) {
                        this.editExamFechaCarga(admissionExam);

                        return;
                    }

                    this.$set(admissionExam, "createdAt", this.$moment(fechaCarga, "DD/MM/YYYY HH:mm").toDate());
                    
                    const newFechaEntrega = await this.calcularFechaEntrega(admissionExam.exam.id, fechaCarga);
                    const examIndex = this.admission.exams.findIndex(e => e.exam.id === admissionExam.exam.id);

                    this.$set(this.admission.exams[examIndex], "fecha_entrega", newFechaEntrega);

                    if (this.isEditing) {
                        // Agregar a objeto updates
                        const examId = admissionExam.id;

                        if (this.updates.exams.add[examId]) {
                            // Es un examen que se creó en edición
                            this.$set(this.updates.exams.add[examId].info, "createdAt", this.$moment(admissionExam.createdAt, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD HH:mm"));
                            this.$set(this.updates.exams.add[examId].info, "fecha_entrega", newFechaEntrega);
                        } else {
                            // Es un examen que ya existía
                            if (!this.updates.exams.edit[examId]) this.$set(this.updates.exams.edit, examId, {});
                            if (!this.updates.exams.edit[examId].info) this.$set(this.updates.exams.edit[examId], "info", {});

                            this.$set(this.updates.exams.edit[examId].info, "createdAt", this.$moment(admissionExam.createdAt, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD HH:mm"));
                            this.$set(this.updates.exams.edit[examId].info, "fecha_entrega", newFechaEntrega);
                        }
                    }

                    this.$f7.dialog.close();
                },
                () => {},
                admissionExam.createdAt ? this.$moment(admissionExam.createdAt, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY HH:mm") : ""
            );
        },
        findExamOtId(sectionId) {
            const examWithSameSection = this.admission.exams.find((e) => {
                return e.exam_section.id === sectionId && e.ot;
            });

            if (!examWithSameSection) return null;

            return examWithSameSection.ot.id;
        },

        // Doctor related
        searchDoctor() {
            if (!validate(this.admission.doctor_rut)) {
                this.$f7.dialog.alert("El RUN ingresado no es válido.");
                return;
            }

            const rut = clean(this.admission.doctor_rut);

            this.$f7.dialog.preloader("Buscando médico");

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => this.getAPIInfo({ url: "clinicians/search?q=" + rut, userToken: idToken }))
                .then((resp) => {
                    const doctor = resp.data.data[0];

                    this.admission.doctor_name = this.formatName(doctor.name, doctor.lastname_f, doctor.lastname_m);

                    this.$f7.dialog.close();
                    this.$f7.toast.show({
                        text: "Médico encontrado",
                        closeTimeout: 2000,
                    });
                })
                .catch((err) => {
                    console.error(err);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("No se encontró médico con RUT ingresado.", "Médico no encontrado");
                });
        },

        handleOTUpdated(otId, property, value) {
            const index = this.admission.ots.findIndex((ot) => ot.id === otId);
            const ot = this.admission.ots[index];

            ot[property] = value;

            this.admission.ots.splice(index, 1, ot);

            this.$set(this.updates.ots.edit, otId, { [property]: value });
        },

        // Helpers
        admissionIsValid() {
            let isValid = true;

            this.invalidReasons = [];

            if (this.admission.patient_id === 0) {
                this.invalidReasons.push("Debe buscar o crear un paciente.");
                isValid = false;
            }

            // ** IMPORTANTE **: Al pasar a prod, quitar estos comentarios
            // if (this.admission.patient.document_type === 1 && !validate(this.admission.patient.document_number)) {
            //     this.invalidReasons.push("El RUN del paciente debe ser válido.");
            //     isValid = false;
            // }

            if (![1, 2, 3].includes(this.admission.priority)) {
                this.invalidReasons.push("Debe establecer la prioridad");
                isValid = false;
            }

            if (this.admission.doctor_rut && !validate(this.admission.doctor_rut)) {
                this.invalidReasons.push("El RUT del medico solicitante es incorrecto");
                isValid = false;
            }

            if (this.admission.doctor_rut && !this.admission.doctor_name) {
                this.invalidReasons.push("Debe ingresar el nombre del médico solicitante");
                isValid = false;
            }

            if (this.admission.exams.length === 0) {
                this.invalidReasons.push("Debe haber por lo menos un examen añadido");
                isValid = false;
            }

            return isValid;
        },
        invalidReasonsList() {
            let list = "<ul>";

            this.invalidReasons.forEach((reason) => {
                list += `<li>${reason}</li>`;
            });

            list += "</ul>";

            return list;
        },
        generateUUID() {
            return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
                (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
            );
        },
        resetUpdates() {
            this.updates = {
                admission: {},
                exams: {
                    add: {},
                    delete: {},
                    edit: {},
                },
                ots: {
                    edit: {},
                },
                samples: {
                    add: {},
                },
            };
        },
        clearAdmission(confirm) {
            const _clearAdmission = () => {
                this.reset = !this.reset;

                this.admission = {
                    id: null,
                    agreement_id: 0,
                    background: null,
                    createdAt: new Date(),
                    created_by: null,
                    diagnostic_obs: "",
                    priority: 1,

                    doctor_rut: "",
                    doctor_name: "",
                    doctor_contacto_1: "",
                    doctor_contacto_2: "",

                    exams: [],

                    ots: [],

                    institution_id: 0,
                    institution: {},

                    patient_id: 0,
                    patient: {},
                };

                this.context = {};

                this.resetUpdates();

                this.$store.commit("admission/set_should_ask_to_leave", false);

                this.isEditing = false;
            };

            if (confirm) {
                this.$f7.dialog.confirm("¿Estás seguro?", () => _clearAdmission());
            } else {
                _clearAdmission();
            }
        },
        clearProcessingConfigs() {
            this.$set(this, "processingConfigs", {});
            this.$set(this, "labConfig", {});
            this.$f7.toast.show({
                text: "Se ha limpiado el cache correctamente",
                closeTimeout: 2000
            });
        },
        handleClearPatient() {
            this.admission.patient_id = 0;
            this.admission.patient = {};
        },
        handlePatientEdited(patient) {
            this.admission.patient = patient;
        },
        editSample(admissionExam) {
            this.editingAdmissionExam = Object.assign({}, admissionExam);
            this.isEditSamplePopupOpened = true;
        },
        handleSampleEdited(payload) {
            const { admissionExamId, sample } = payload;

            const index = this.admission.exams.findIndex((admissionExam) => admissionExam.id === admissionExamId);

            const isCurrentlyPending = this.admission.exams[index].current_status_id === examStatuses.examen_pendiente;
            const examHasSamples = this.admission.exams[index].samples && this.admission.exams[index].samples.length > 0;

            if (this.isEditing) {
                if (this.updates.exams.add[admissionExamId]) {
                    // Es un examen que se agregó en esta instancia de modo edición
                    this.$set(this.updates.exams.add[admissionExamId], "samples", [
                        {
                            id: sample.meta.id,
                            quantity: sample.quantity,
                        },
                    ]);
                } else {
                    // Es un examen que ya estaba agregado
                    const addOrEdit = this.admission.exams[index].samples && this.admission.exams[index].samples.lenght > 0 ? "add" : "edit";

                    if (!examHasSamples || this.updates.samples.add[admissionExamId]) {
                        this.$set(this.updates.samples.add, admissionExamId, { samples: [sample] });
                    } else {
                        if (!this.updates.exams[addOrEdit][admissionExamId]) {
                            this.$set(this.updates.exams[addOrEdit], admissionExamId, {});
                        }

                        this.$set(this.updates.exams[addOrEdit][admissionExamId], "samples", [sample]);
                    }
                }
            }

            if (isCurrentlyPending && !examHasSamples) {
                this.handleExamStatusLogAdded({
                    examId: admissionExamId,
                    reason: null,
                    statusId: examStatuses.admitido,
                });
            }

            this.$set(this.admission.exams[index].samples, 0, sample);

            this.editingAdmissionExam = null;
            this.isEditSamplePopupOpened = false;
        },

        async createAndUpdateAdmissionPatient(patient, idToken) {
            const response = await this.createNewInfo({ url: "patients", data: patient, userToken: idToken });
            const createdPatient = response.data.data.patient;

            this.$set(this.admission, "patient_id", createdPatient.id);
            this.$set(this.admission.patient, "id", createdPatient.id);
        },

        backConfirm() {
            if (this.thereAreChanges) {
                this.$f7.dialog.confirm("Hay cambios sin guardar. ¿Está seguro de volver?", () => {
                    this.clearAdmission(false);

                    this.$f7.views.main.router.back();
                });
            } else {
                this.$f7.views.main.router.back();
            }
        },
    },
};
</script>
