<template>
    <f7-popup :opened="opened" @popup:closed="$emit('popup-closed')">
        <f7-page>
            <f7-navbar title="Órdenes de Trabajo encontradas">
                <f7-nav-right>
                    <f7-link popup-close color="blue">Cerrar</f7-link>
                </f7-nav-right>
            </f7-navbar>

            <f7-block class="margin-top-half">
                <p>
                    La búsqueda ha arrojado más de un resultado. Seleccione una Órden de Trabajo de la lista.
                </p>

                <f7-list>
                    <f7-list-item
                        checkbox
                        v-for="ot in orderedOts"
                        :key="ot.code"
                        class="thin-list"
                        @change="$emit('ot-selected', ot)"
                    >
                        <div slot="header">Creada el {{ $moment.unix(ot.createdAt).format("llll") }}</div>
                        <div slot="title">
                            <b>{{ patientName(ot.patient) }}</b>
                            &bull; {{ getSectionById(ot.sectionId) }}
                            <span class="text-color-gray">&bull; {{ ot.priority }}</span>
                        </div>
                        <div slot="footer">
                            <span>{{ ot.code }}</span>
                            &bull;
                            <span>{{ Object.keys(ot.exams || {}).length }} exámenes</span>
                        </div>
                    </f7-list-item>
                </f7-list>
            </f7-block>
        </f7-page>
    </f7-popup>
</template>

<script>
import { _getSectionById } from "@/app/helpers";

export default {
    props: {
        opened: Boolean,
        ots: Object,
    },

    computed: {
        orderedOts() {
            return Object.values(this.ots).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        },
    },

    data() {
        return {
            getSectionById: _getSectionById,
        };
    },

    methods: {
        patientName(patient) {
            return `${patient.nombres} ${patient.primerApellido} ${patient.segundoApellido}`;
        },
    },
};
</script>
