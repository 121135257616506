const { clean } = require("rut.js");

module.exports = {
    methods: {
        queryStringFromOptions(rawOptions, config) {
            let options = rawOptions;

            if (options.document_type === 1 && options.document_number) {
                options.document_number = clean(options.document_number);
            }

            if (options.sample_code) {
                options = {
                    sample_code: options.sample_code,
                }
            }

            if (options.ot_code) {
                const didSearchOtComplete = options.ot_code.split("-").length >= 2;

                if (didSearchOtComplete) {
                    options = {
                        ot_code: options.ot_code,
                    }
                }
            }

            const opts = {};

            Object.keys(options).forEach((optionKey) => {
                const option = options[optionKey];

                if (option) {
                    if (Array.isArray(option) && option.length > 0) {
                        opts[optionKey] = option.join(",");
                    } else if (typeof option === 'string' && option.length > 0) {
                        opts[optionKey] = option.trim();
                    } else if (typeof option === 'number') {
                        opts[optionKey] = option;
                    }
                }
            });

            if (config && config.rowsPerPage) {
                opts["rows_per_page"] = config.rowsPerPage;
            }

            const queryString = new URLSearchParams(opts).toString();

            return queryString;
        },
    },
};
