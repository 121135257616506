var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-block',{staticClass:"padding-vertical"},[(_vm.isLoading)?_c('f7-block',{staticClass:"row demo-preloaders align-items-stretch text-align-center margin-bottom"},[_c('f7-col',[_c('f7-preloader')],1)],1):[_c('div',[_c('f7-list',{staticClass:"margin-bottom"},[_c('f7-list-item',{attrs:{"title":"Institución","smart-select":"","smart-select-params":{
                        openIn: 'popup',
                        searchbar: true,
                        searchbarPlaceholder: 'Buscar instituciones',
                        setValueText: false,
                    }}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.options.institutions),expression:"options.institutions"}],attrs:{"multiple":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.options, "institutions", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.allInstitutions),function(institution){return _c('option',{key:institution.id,domProps:{"value":institution.id}},[_vm._v("\n                            "+_vm._s(institution.name)+"\n                        ")])}),0),_vm._v(" "),_c('div',{attrs:{"slot":"after"},slot:"after"},[_vm._v(_vm._s(_vm.optionsInstitutionsArray.join(", ")))])]),_vm._v(" "),_c('f7-list-item',{attrs:{"title":"Estado de la solicitud","smart-select":"","smart-select-params":{
                        openIn: 'popup',
                        setValueText: false,
                    }}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.options.statuses),expression:"options.statuses"}],attrs:{"multiple":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.options, "statuses", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.allStatuses),function(status){return _c('option',{key:status.id,domProps:{"value":status.id}},[_vm._v("\n                            "+_vm._s(status.name)+"\n                        ")])}),0),_vm._v(" "),_c('div',{attrs:{"slot":"after"},slot:"after"},[_vm._v(_vm._s(_vm.optionsStatusesArray.join(", ")))])])],1),_vm._v(" "),_c('f7-block',[_c('p',[_c('b',[_vm._v("Fecha de solicitud")])]),_vm._v(" "),_c('f7-row',{staticClass:"margin-bottom padding-bottom"},[_c('f7-col',[_c('label',[_vm._v("Fecha inicio")]),_vm._v(" "),_c('f7-input',{staticClass:"input-uppercase margin-top-half",attrs:{"type":"text","maxlength":10,"value":_vm._f("formatDate")(_vm.options.initDate),"placeholder":"dd/MM/AAAA"},on:{"input":function($event){_vm.options.initDate = $event.target.value}}})],1),_vm._v(" "),_c('f7-col',[_c('label',[_vm._v("Fecha término")]),_vm._v(" "),_c('f7-input',{staticClass:"input-uppercase margin-top-half",attrs:{"type":"text","maxlength":10,"value":_vm._f("formatDate")(_vm.options.endDate),"placeholder":"dd/MM/AAAA"},on:{"input":function($event){_vm.options.endDate = $event.target.value}}})],1)],1)],1)],1),_vm._v(" "),_vm._t("search-footer")]],2)}
var staticRenderFns = []

export { render, staticRenderFns }