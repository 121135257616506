<template>
    <f7-page name="units">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title title="Unidades" subtitle="de medida"></f7-nav-title>
            <f7-nav-right>
                <f7-link icon-material="refresh" tooltip="Recargar Lista" @click="getUnits()"></f7-link>
                <f7-link class="searchbar-enable" data-searchbar=".searchbar-demo" icon-material="search"></f7-link>
            </f7-nav-right>
            <f7-searchbar
                class="searchbar-demo"
                expandable
                remove-diacritics
                search-container=".search-list"
                search-in=".item-title"
                :disable-button="!$theme.aurora"
                placeholder="Buscar unidad"
            ></f7-searchbar>
        </f7-navbar>

        <f7-list class="searchbar-not-found">
            <f7-list-item title="No hay coincidencias"></f7-list-item>
        </f7-list>

        <f7-list class="no-margin-top search-list searchbar-found" media-list>
            <f7-list-item
                v-for="unit in allUnits"
                :key="unit.id"
                :title="unit.name"
                link="#"
                :class="{ selected: viewingUnit && viewingUnit.id === unit.id }"
                @click="showUnitDetails(unit)"
            ></f7-list-item>
        </f7-list>

        <f7-fab position="right-bottom" slot="fixed" color="teal">
            <f7-icon material="add"></f7-icon>
            <f7-icon ios="f7:close" aurora="f7:close" md="material:close"></f7-icon>
            <f7-fab-buttons position="top">
                <f7-fab-button fab-close label="Crear nueva unidad" @click="createNewUnit()">
                    <f7-icon material="playlist_add"></f7-icon>
                </f7-fab-button>
            </f7-fab-buttons>
        </f7-fab>
    </f7-page>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    computed: {
        ...mapState("unit", ["allUnits", "viewingUnit"]),
    },

    data() {
        return {
            units: [],
        };
    },

    methods: {
        ...mapActions(["getAPIInfo"]),

        getUnits() {
            this.$f7.dialog.preloader("Cargando...");

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({
                        url: "units",
                        userToken: idToken,
                    });
                })
                .then((response) => {
                    const responseData = response.data;

                    if (responseData) {
                        const units = responseData.data;

                        this.$store.commit("unit/set_all_units", units);
                    }

                    this.$f7.dialog.close();
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error);
                });
        },

        showUnitDetails(unit) {
            this.$store.commit("unit/set_viewing_unit", unit);

            this.$f7.views.main.router.navigate("/migration/mantenedores/units/details");
        },

        createNewUnit() {
            this.$store.commit("unit/set_viewing_unit", null);

            this.$f7.views.main.router.navigate("/migration/mantenedores/units/create-or-edit", { reloadCurrent: true });
        },
    },

    mounted() {
        if (this.allUnits.length === 0) {
            this.getUnits();
        }
    },
};
</script>

<style scoped>
.selected {
    border-left: 7px solid #36b5aa;
    color: #2d968c;
    background-color: #f3f3f3;
}
</style>
