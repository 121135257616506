<template>
    <f7-page name="admission-index">
        <f7-navbar title="Lista de Trabajo"></f7-navbar>

        <f7-block>
            <f7c-alert type="info" dismissible class="margin-horizontal-half">
                Solo puedes pasar a laboratorio exámenes con estado
                <b>Admitido</b>
            </f7c-alert>
            <f7-card outline>
                <f7-card-header class="display-flex justify-content-space-between">
                    <div class="text-color-gray">Buscador de exámenes</div>
                    <div class="display-flex">
                        <f7-button small v-if="showingExamsSearch" @click="showingExamsSearch = false">Ocultar</f7-button>
                        <f7-button small v-else @click="showingExamsSearch = true">Mostrar</f7-button>
                        <f7-button small class="margin-left-half" color="red" @click="clearSearchFilters(true)">
                            Limpiar filtros
                        </f7-button>
                    </div>
                </f7-card-header>
                <f7-card-content v-show="showingExamsSearch">
                    <exams-search
                        simple-search
                        :default-options="getExamsSearchDefaultOptions"
                        @options-changed="handleExamsSearchOptionsChanged"
                        @search-triggered="handleSearchExams"
                        @is-loading="handleExamsSearchIsLoading"
                        :reset="resetExamsSearchComponent"
                    >
                        <template #search-footer>
                            <f7-button class="margin-horizontal margin-bottom" fill large raised @click="handleSearchExams">
                                <f7-icon material="search"></f7-icon>
                                Buscar exámenes
                            </f7-button>
                        </template>
                    </exams-search>
                </f7-card-content>
            </f7-card>

            <f7-block v-if="!examsSearchIsLoading">
                <template v-if="pagination.totalRows">
                    <div class="display-flex justify-content-space-between margin-bottom">
                        <h2 class="no-margin">Viendo {{ exams.length }} de {{ pagination.totalRows }} examenes</h2>
                    </div>

                    <div class="margin-bottom">
                        <label><b>Filtrar datos por paciente, institución, código de OT, exámen, muestra, sección ó estado de exámen</b></label>
                        <f7-input
                            type="text"
                            @input="filterInput = $event.target.value"
                            clear-button
                            class="margin-bottom padding-vertical-half"
                            placeholder="Ingrese su búsqueda..."
                        ></f7-input>
                        <div v-if="filterInput.length > 0" class="margin-top-half text-color-gray">
                            Mostrando {{ filteredExams.length }} examenes filtrados
                            <span v-if="selectedAdmissionExams.length > 0">(Examenes seleccionados: {{ selectedAdmissionExams.length }})</span>
                        </div>
                    </div>

                    <div class="display-flex flex-direction-row-reverse margin-bottom">
                        <f7-button fill small raised @click="moveToLab" :disabled="selectedAdmissionExams.length === 0">
                            Pasar exámenes seleccionados a laboratorio
                        </f7-button>
                    </div>
                </template>

                <exams-table
                    :exams="filteredExams"
                    :pagination="pagination"
                    :selected-exams="selectedAdmissionExams"
                    @exam-selection-changed="handleExamSelectionChanged"
                    @exam-status-log-added="handleExamStatusLogAdded"
                    @load-more="handleLoadMoreExams"
                >
                    <template #table-header>
                        <th>
                            <f7-checkbox @change="handleSelectAll($event.target.checked)"></f7-checkbox>
                        </th>
                        <th>CB Muestras</th>
                        <th>OT</th>
                        <th>Seccion</th>
                        <th>Documento paciente</th>
                        <th>Paciente</th>
                        <th>Cod Exámen</th>
                        <th>Exámen</th>
                        <th>Muestras</th>
                        <th>Estado</th>
                        <th>Fecha cargado</th>
                        <th></th>
                    </template>

                    <template #table-row="{ admissionExam, f }">
                        <tr>
                            <td>
                                <f7-checkbox
                                    :checked="f.examIsChecked(admissionExam.id)"
                                    :disabled="!f.examIsCheckeable(admissionExam)"
                                    @change="f.handleCheckExam($event.target.checked, admissionExam.id)"
                                ></f7-checkbox>
                            </td>
                            <td>{{ f.admissionExamSamplesCodes(admissionExam) }}</td>
                            <td>{{ f.admissionExamOtCode(admissionExam) }}</td>
                            <td>{{ admissionExam.exam_section.name }}</td>
                            <td>{{ f.patientDocumentInfo(admissionExam) }}</td>
                            <td>
                                {{ f.patientFullName(admissionExam) }}
                            </td>
                            <td>{{ admissionExam.exam.code }}</td>
                            <td>{{ admissionExam.exam.name }}</td>
                            <td>{{ f.admissionExamSamplesNames(admissionExam) }}</td>
                            <td v-html="examStatusesByReportType(admissionExam)"></td>
                            <td>
                                {{ admissionExam.createdAt ? $moment(admissionExam.createdAt).format("DD/MM/YYYY HH:mm") : "Sin fecha de carga" }}
                            </td>

                            <td class="display-flex justify-content-center align-items-center">
                                <f7-button
                                    @click="f.viewPatient(admissionExam)"
                                    icon-material="person"
                                    tooltip="Ver información del paciente"
                                ></f7-button>
                                <f7-button @click="f.viewExamLog(admissionExam)" small icon-material="reorder" tooltip="Flujograma"></f7-button>
                            </td>
                        </tr>
                    </template>
                </exams-table>
            </f7-block>
        </f7-block>
    </f7-page>
</template>

<script>
import { mapActions } from "vuex";
import ExamsSearch from "@/app/components-migration/exams-search.vue";
import ExamsTable from "@/app/components-migration/exams-table.vue";
import SearchMixin from "@/app/mixins/search-mixin.js";
import FilterExamsMixin from "@/app/mixins/filter-exams-mixin.js";
import { examStatuses, examStatusesIds } from "@/app/constants";

export default {
    mixins: [SearchMixin, FilterExamsMixin],

    components: {
        "exams-search": ExamsSearch,
        "exams-table": ExamsTable,
    },

    data() {
        return {
            resetExamsSearchComponent: false,

            examsSearchIsLoading: true,
            examsSearchOptions: {},
            showingExamsSearch: true,

            exams: [],
            pagination: {},

            selectedAdmissionExams: [],
        };
    },

    computed: {
        selectedExamsForRequest() {
            const selectedExams = [];
            this.selectedAdmissionExams.forEach((examId) => selectedExams.push({ admission_exam_id: examId }));
            return selectedExams;
        },

        searchQueryString() {
            const queryString = this.queryStringFromOptions(this.examsSearchOptions, {
                rowsPerPage: 100,
            });

            return queryString;
        },
    },

    methods: {
        ...mapActions(["getAPIInfo", "updateInfo"]),

        handleExamsSearchOptionsChanged(options) {
            this.examsSearchOptions = options;
        },

        handleSearchExams() {
            this.$f7.dialog.preloader("Buscando examenes...");

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({
                        url: "admission_exams?" + this.searchQueryString,
                        userToken: idToken,
                    });
                })
                .then((response) => {
                    const { data } = response;

                    this.exams = data.data;
                    this.pagination = data.pagination;

                    if (this.exams.length === 1) {
                        const exam = this.exams[0];

                        if (exam.current_status_id === examStatuses.admitido) {
                            this.selectedAdmissionExams.push(this.exams[0].id);
                        }
                    }

                    this.$f7.dialog.close();
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error);
                });
        },

        handleExamsSearchIsLoading(isLoading) {
            this.examsSearchIsLoading = isLoading;
        },

        handleExamSelectionChanged(payload) {
            const { wasChecked, admissionExamId } = payload;

            if (wasChecked) {
                this.selectedAdmissionExams.push(admissionExamId);
            } else {
                const index = this.selectedAdmissionExams.findIndex((selectedExams) => selectedExams === admissionExamId);
                this.selectedAdmissionExams.splice(index, 1);
            }
        },

        handleSelectAll(checked) {
            if (checked) {
                this.selectedAdmissionExams = this.filteredExams.filter((e) => e.current_status_id === examStatuses.admitido).map((e) => e.id);
            } else {
                this.selectedAdmissionExams = [];
            }
        },

        handleExamStatusLogAdded(payload) {
            this.$f7.dialog.preloader("Cambiando estado del examen...");

            const { examId, statusId, reason } = payload;

            const url = `admission_exams/status/${statusId}`;

            const updateExamRequestBody = {
                exams: [
                    {
                        admission_exam_id: examId,
                        reason_id: reason ? reason.id : null,
                    },
                ],
            };

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.updateInfo({
                        url: url,
                        userToken: idToken,
                        data: updateExamRequestBody,
                    });
                })
                .then((response) => {
                    const updatedAdmissionExams = response.data.updatedAdmissionExams;

                    updatedAdmissionExams.forEach((updatedExam) => {
                        // update exams
                        const examIndex = this.exams.findIndex((e) => e.id === updatedExam.id);
                        const exam = this.exams[examIndex];

                        if (exam && updatedExam) {
                            const updates = { ...exam, ...updatedExam };

                            this.$set(this.exams, examIndex, updates);
                        }

                        // remove exam from selectedExams
                        const selectedExamIndex = this.selectedAdmissionExams.findIndex((examId) => examId === updatedExam.id);
                        if (selectedExamIndex !== -1) {
                            this.selectedAdmissionExams.splice(selectedExamIndex, 1);
                        }
                    });

                    this.$f7.dialog.close();
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error);
                });
        },

        getExamsSearchDefaultOptions() {
            return { statuses: [examStatuses.admitido] };
        },

        moveToLab() {
            if (this.selectedExamsForRequest.length === 0) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Debe seleccionar por lo menos un exámen");
                return;
            }

            this.$f7.dialog.confirm("¿Esta seguro?", () => {
                this.$f7.dialog.preloader("Moviendo exámenes seleccionados a laboratorio...");

                const status = examStatuses.en_laboratorio;

                this.$firebase
                    .auth()
                    .currentUser.getIdToken()
                    .then((idToken) => {
                        return this.updateInfo({
                            url: `admission_exams/status/${status}`,
                            userToken: idToken,
                            data: {
                                exams: this.selectedExamsForRequest,
                            },
                        });
                    })
                    .then((response) => {
                        console.log("response data", response);
                        this.updateSelectedExams(response.data);

                        this.selectedAdmissionExams = [];

                        this.$f7.dialog.close();
                    })
                    .catch((error) => {
                        console.error(error);
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(error);
                    });
            });
        },

        updateSelectedExams(responseData) {
            const updatedAdmissionExams = responseData.updatedAdmissionExams;

            this.selectedAdmissionExams.forEach((selectedExamId) => {
                const examIndex = this.exams.findIndex((e) => e.id === selectedExamId);

                const exam = this.exams[examIndex];
                const updates = updatedAdmissionExams.find((e) => e.id === selectedExamId);

                if (exam && updates) {
                    const updatedExam = { ...exam, ...updates };

                    this.$set(this.exams, examIndex, updatedExam);
                }
            });
        },

        handleLoadMoreExams() {
            this.$f7.dialog.preloader("Cargando más exámenes...");

            let queryString = this.searchQueryString;

            const page = this.pagination.page + 1;

            queryString += `&page=${page}`;

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({
                        url: "admission_exams?" + queryString,
                        userToken: idToken,
                    });
                })
                .then((response) => {
                    const responseData = response.data;

                    if (responseData.data && responseData.data.length) {
                        this.exams.push(...responseData.data);
                        this.pagination = responseData.pagination;
                    }

                    this.$f7.dialog.close();
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error);
                });
        },

        examStatusesByReportType(admissionExam) {
            const _htmlList = (items) => {
                let list = "";

                items.forEach((item) => {
                    list += `${item}<br>`;
                });

                return list;
            };

            const { exam_status, current_status_id, status_log } = admissionExam;

            if (current_status_id === examStatuses.admitido || current_status_id === examStatuses.en_laboratorio) {
                return exam_status ? exam_status.name : "Sin estado";
            }

            let results = [];
            let status = "";

            if (admissionExam.status_id_preliminar) {
                status = examStatusesIds[admissionExam.status_id_preliminar];
                results.push(`<b>Preliminar</b>: ${status}`);
            }

            if (admissionExam.status_id_final) {
                status = examStatusesIds[admissionExam.status_id_final];
                results.push(`<b>Final</b>: ${status}`);
            }

            if (admissionExam.status_id_compl) {
                status = examStatusesIds[admissionExam.status_id_compl];
                results.push(`<b>Complementario</b>: ${status}`);
            }

            if (results.length === 0) {
                return admissionExam.exam_status ? admissionExam.exam_status.name : "Sin estado";
            }

            return _htmlList(results);
        },

        clearSearchFilters(confirm = false) {
            const _clear = () => {
                this.resetExamsSearchComponent = !this.resetExamsSearchComponent;
                this.examsSearchIsLoading = false;
                this.examsSearchOptions = {};
                this.showingExamsSearch = true;
            };

            if (confirm) {
                this.$f7.dialog.confirm("¿Esta seguro?", () => {
                    _clear();
                });
            } else {
                _clear();
            }
        },
    },
};
</script>
