<template>
    <f7-page>
        <f7-navbar title="Importar admisiones"></f7-navbar>

        <f7-block v-if="!fileUploaded">
            <h3>Seleccione la institución</h3>
            <f7-input type="select" @change="selectedInstitution = $event.target.value">
                <option value="">Seleccione...</option>
                <option
                    v-for="institution in institutions"
                    :key="institution.id"
                    :value="institution.id"
                    :selected="selectedInstitution === institution.id"
                >
                    {{ institution.name }}
                </option>
            </f7-input>
        </f7-block>

        <f7-block v-if="selectedInstitution" class="no-margin-top">
            <div class="display-flex justify-content-space-between align-items-center">
                <h3>Cargando admisiones de la institución {{ institutions[selectedInstitution].name }}</h3>
                <f7-button outline small color="red" v-if="fileUploaded" @click="clear">
                    <span v-if="!preadmitted">Cancelar subida</span>
                    <span v-else>Subir nuevo excel</span>
                </f7-button>
            </div>

            <f7c-alert v-if="fileUploaded" type="warning">
                Las admisiones con conflictos sin resolver
                <b>no serán preadmitidas</b>
                .
                {{ Object.keys(admissionsWithConflicts).length > 0 ? `(${Object.keys(admissionsWithConflicts).length} conflictos)` : "" }}
            </f7c-alert>

            <template v-if="!fileUploaded">
                <input type="file" id="input" @change="fileChanged($event)" accept=".xlsx" />
                <h3>Formato requerido del excel</h3>

                <span class="text-color-blue"><b>Campos obligatorios</b></span>

                <!-- prettier-ignore -->
                <f7-row>
                    <f7-col>
                        <p><b>A:</b> codigo_muestra_cliente</p>
                        <p><b>B:</b> rut_responsable</p>
                        <p><b>C:</b> cod_deis</p>
                        <p><b>D:</b> rut_medico</p>
                        <p class="text-color-blue"><b>E: paciente_tipodoc</b> (RUN/RUT, PASAPORTE, DNI)</p>
                        <p class="text-color-blue"><b>F: paciente_run</b> (Sin puntos)</p>
                        <p class="text-color-blue"><b>G: paciente_dv</b></p>
                        <p class="text-color-blue"><b>H: paciente_pasaporte</b></p>
                        <p class="text-color-blue"><b>I: paciente_ext_paisorigen</b> (código iso alpha3)</p>
                    </f7-col>
                    <f7-col>
                        <p class="text-color-blue"><b>J: paciente_nombres</b></p>
                        <p class="text-color-blue"><b>K: paciente_ap_pat</b></p>
                        <p><b>L:</b> paciente_ap_mat</p>
                        <p class="text-color-blue"><b>M: paciente_fecha_nac</b> (DD-MM-AAAA)</p>
                        <p><b>N:</b> paciente_comuna</p>
                        <p><b>O:</b> paciente_direccion</p>
                        <p><b>P:</b> paciente_telefono</p>
                        <p><b>Q:</b> paciente_email</p>
                        <p class="text-color-blue"><b>R: paciente_sexo</b> (F, M)</p>
                    </f7-col>
                    <f7-col>
                        <p><b>S:</b> paciente_prevision</p>
                        <p><b>T:</b> fecha_muestra</p>
                        <p><b>U:</b> tipo_muestra</p>
                        <p><b>V:</b> busqueda_activa</p>
                        <p><b>W:</b> resultado</p>
                        <p><b>X:</b> fecha_recepcion_muestra</p>
                        <p><b>Y:</b> fecha_resultado_muestra</p>
                        <p><b>Z:</b> sin_orden_medica</p>
                    </f7-col>
                </f7-row>
            </template>
            <template v-else>
                <f7-button fill @click="preadmitAdmissions" v-if="!preadmitted">
                    Preadmitir admisiones
                </f7-button>
                <f7-button fill @click="processSelectedAdmissions" v-else-if="!processed">
                    Procesar admisiones
                </f7-button>

                <table class="table table-sm table-bordered margin-top">
                    <thead>
                        <tr>
                            <th v-if="preadmitted && !processed">
                                <f7-checkbox :checked="areAllAdmissionsChecked" @change="selectAllAdmissions($event.target.checked)"></f7-checkbox>
                            </th>
                            <th>#</th>
                            <th>Examen</th>
                            <th>Muestra</th>
                            <th>Paciente</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(data, admissionId, index) in dataToCreate" :key="admissionId">
                            <td v-if="preadmitted && !processed">
                                <f7-checkbox
                                    :disabled="!preadmittedAdmissions[admissionId]"
                                    :checked="isAdmissionChecked(admissionId)"
                                    @change="selectAdmission($event.target.checked, admissionId)"
                                ></f7-checkbox>
                            </td>
                            <td>
                                <b>{{ index + 2 }}</b>
                            </td>
                            <td>
                                {{ Object.values(data.admission.exams)[0].name }}
                            </td>
                            <td>
                                <div>{{ Object.values(data.admission.samples)[0].name }}</div>
                                <div v-if="preadmitted">
                                    {{ Object.values(data.admission.samples)[0].id }}
                                </div>
                            </td>
                            <td class="uppercase">
                                <f7-icon
                                    v-if="!isPatientIdValid(data.admission.patient)"
                                    material="warning"
                                    color="red"
                                    tooltip="El RUT de este paciente no es válido. La admisión va a ser guardada de todas maneras."
                                ></f7-icon>
                                {{ patientInfo(data.admission.patient) }}
                            </td>
                            <td>
                                <f7-button
                                    @click="resolveConflicts(data.admission.patient, data.admission.id)"
                                    color="red"
                                    small
                                    v-if="admissionsWithConflicts[data.admission.id]"
                                    :disabled="preadmitted && admissionsWithConflicts[data.admission.id]"
                                >
                                    Resolver conflicto
                                </f7-button>
                                <f7-button
                                    small
                                    @click="viewPatient(data.admission.patient, data.admission.id)"
                                    :disabled="preadmitted && admissionsWithConflicts[data.admission.id]"
                                >
                                    {{ patientHasId(data.admission.id) ? "Editar" : "Ver" }} paciente
                                </f7-button>
                                <f7-button
                                    v-if="processed"
                                    small
                                    @click="printLabel(data.admission)"
                                >
                                    Imprimir etiqueta
                                </f7-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </f7-block>

        <!-- Editar paciente popup -->
        <f7-popup :opened="patientInfoPopupOpened" @popup:closed="handlePatientInfoPopupClosed">
            <f7-page>
                <f7-navbar title="Viendo al paciente">
                    <f7-nav-right>
                        <f7-link popup-close>Cerrar</f7-link>
                    </f7-nav-right>
                </f7-navbar>

                <f7c-alert v-if="!isPatientIdValid(viewingPatient.patient)" type="warning" class="margin">
                    El RUT de este paciente es inválido. La admisión se va a guardar de todas maneras.
                </f7c-alert>

                <patient-form
                    :patient="viewingPatient.patient"
                    :admissionId="viewingPatient.admissionId"
                    @patient-info-changed="handlePatientInfoChanged"
                    :readonly="!Object.keys(viewingPatient).length ? false : !patientHasId(viewingPatient.admissionId)"
                ></patient-form>
            </f7-page>
        </f7-popup>

        <!-- Resolver conflictos popup -->
        <f7-popup :opened="resolveConflictsPopupOpened" @popup:closed="resolveConflictsPopupOpened = false">
            <f7-page>
                <f7-navbar title="Resolver conflictos del paciente">
                    <f7-nav-right>
                        <f7-link popup-close>Cancelar</f7-link>
                    </f7-nav-right>
                </f7-navbar>

                <h3 class="margin-horizontal">Paciente en el Excel</h3>
                <patient-form
                    :patient="currentConflict.excelPatient"
                    :admissionId="viewingPatient.admissionId"
                    @patient-info-changed="handlePatientInfoChanged"
                    readonly
                ></patient-form>
                <f7-button fill class="margin-horizontal" @click="useExcelPatient">
                    Utilizar este paciente y actualizarlo en la base de datos
                </f7-button>

                <h3 class="margin-horizontal">Paciente en la base de datos</h3>
                <patient-form
                    :patient="currentConflict.dbPatient"
                    :admissionId="viewingPatient.admissionId"
                    @patient-info-changed="handlePatientInfoChanged"
                    readonly
                ></patient-form>
                <f7-button fill class="margin-horizontal margin-bottom" @click="useDbPatient">
                    Utilizar este paciente
                </f7-button>
            </f7-page>
        </f7-popup>

        <!-- Seleccion de pacientes popup -->
        <f7-popup class="found-patients" :opened="selectPatientPopupOpened" @popup:closed="selectPatientPopupOpened = false">
            <f7-page>
                <f7-navbar title="Seleccione un paciente">
                    <f7-nav-right>
                        <f7-link popup-close>Cerrar</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <f7-block class="margin-top-half">
                    <p>
                        La búsqueda ha arrojado más de un resultado. Seleccione un paciente de la lista
                    </p>

                    <f7-list media-list>
                        <f7-list-item
                            checkbox
                            media-item
                            v-for="patient in foundPatients"
                            :key="patient.id"
                            @change="handleSelectPatient(patient)"
                            class="thin-list"
                        >
                            <div slot="title" class="uppercase">
                                {{ patient.nombres }}
                                {{ patient.primerApellido }}
                                {{ patient.segundoApellido }}
                            </div>
                            <div slot="after">
                                {{ patient.tipoIdentificador }}
                                {{ patient.identificador }}
                            </div>
                        </f7-list-item>
                    </f7-list>
                </f7-block>
            </f7-page>
        </f7-popup>
    </f7-page>
</template>

<script>
import { mapState } from "vuex";

import readXlsxFile from "read-excel-file";

import PatientForm from "@/components/admission-components/patient-form.vue";

import _countriesIso from "@/js/countries-iso.json";
import { printermixin } from "../../mixins/printermixin";
import { paxhelpersmixin } from "../../mixins/paxhelpersmixin";

import { clean, validate } from "rut.js";

export default {
    mixins: [printermixin, paxhelpersmixin],

    components: {
        "patient-form": PatientForm,
    },

    data() {
        return {
            verifiedPatients: 0,

            samplesCodeRange: "",

            institutions: {},
            selectedInstitution: "",

            dataToCreate: {},

            admissionsWithConflicts: {},

            samplesCount: 0,

            selectedAdmissions: {},

            preadmittedAdmissions: {},

            // Edit patients
            patientInfoPopupOpened: false,
            viewingPatient: {},

            // Found patients
            selectPatientPopupOpened: false,
            foundPatients: {},

            // Resolve conflicts
            resolveConflictsPopupOpened: false,
            currentConflict: {
                excelPatient: {},
                dbPatient: {},
                admissionId: "",
            },

            // DB Patients with added fields
            updatedPatientsInAdmission: {},

            cols: {
                rut_responsable: 1,
                rut_medico: 3,
                paciente_tipodoc: 4,
                paciente_run: 5,
                paciente_dv: 6,
                paciente_pasaporte: 7,
                paciente_ext_paisorigen: 8,
                paciente_nombres: 9,
                paciente_ap_pat: 10,
                paciente_ap_mat: 11,
                paciente_fecha_nac: 12,
                paciente_comuna: 13,
                paciente_direccion: 14,
                paciente_telefono: 15,
                paciente_email: 16,
                paciente_sexo: 17,
                paciente_prevision: 18,

                fecha_muestra: 19,
                tipo_muestra: 20,

                resultado: 22,

                fecha_recepcion_muestra: 23,
                fecha_resultado_muestra: 24,
            },

            preadmitted: false,
            processed: false,

            fileName: "",

            countriesIso: _countriesIso,
        };
    },

    created() {
        this.getInstitutions();
    },

    computed: {
        ...mapState(["profile_userInfo", "env"]),

        fileUploaded() {
            return Object.keys(this.dataToCreate).length > 0;
        },

        areAllAdmissionsChecked() {
            const rowsCount = Object.keys(this.dataToCreate).length;

            if (rowsCount === 0) {
                return false;
            }

            return Object.keys(this.selectedAdmissions).length === rowsCount;
        },
    },

    watch: {
        verifiedPatients() {
            const admissionsCount = Object.keys(this.dataToCreate).length;

            if (this.verifiedPatients === admissionsCount) {
                this.$f7.dialog.close();
            }
        },
    },

    methods: {
        getBatchLogsUpdates() {
            const range = this.samplesCodeRange;

            const batchLogUpdate = {};
            const timestamp = (new Date() / 1000) | 0;
            const batchLogKey = this.$firebase
                .database()
                .ref()
                .push().key;

            const totalAdmissions = Object.keys(this.dataToCreate).length;
            const ommitedAdmissions = Object.keys(this.admissionsWithConflicts).length;
            const createdAdmissions = totalAdmissions - ommitedAdmissions;

            batchLogUpdate[`/batchLogs/${batchLogKey}`] = {
                ts: timestamp,
                date: this.$moment.unix(timestamp).format("DD/MM/YYYY HH:mm:ss"),
                fileName: this.fileName,
                samplesRange: range,
                who: this.profile_userInfo.displayName,
                admissions: {
                    created: createdAdmissions,
                    ommited: ommitedAdmissions,
                },
            };

            return batchLogUpdate;
        },
        preadmitAdmissions() {
            const conflictsCount = Object.keys(this.admissionsWithConflicts).length;
            const dataToCreateCount = Object.keys(this.dataToCreate).length;

            const confirmText =
                conflictsCount === 0
                    ? `Se preadmitirán ${dataToCreateCount} admisiones.`
                    : `- ${dataToCreateCount -
                          conflictsCount} admisiones serán preadmitidas.<br>- ${conflictsCount} admisiones conflictivas serán omitidas.`;

            this.$f7.dialog.confirm(confirmText, "¿Está seguro?", () => {
                this.$f7.dialog.preloader("Preadmitiendo...");

                this.replaceSamplesCodes().then(() => {
                    const admissionUpdates = {};
                    const patientsUpdates = {};

                    const patientsLogsUpdates = {};

                    Object.values(this.dataToCreate).forEach((data) => {
                        const admission = data.admission;

                        if (!admission.patientId && !this.admissionsWithConflicts[admission.id]) {
                            const patientKey = this.$firebase
                                .database()
                                .ref()
                                .push().key;

                            this.$set(this.dataToCreate[admission.id].admission.patient, "id", patientKey);
                            this.$set(this.dataToCreate[admission.id].admission, "patientId", patientKey);

                            patientsUpdates[`/pacientes/${patientKey}`] = admission.patient;

                            // Patient log
                            const patientLogId = this.$firebase
                                .database()
                                .ref(`/patients_log/${patientKey}`)
                                .push().key;

                            patientsLogsUpdates[`/patients_log/${patientKey}/${patientLogId}`] = {
                                who: this.profile_userInfo.uid,
                                wht: "add-patient",
                                whn: this.$moment().unix(),
                                dls: "Agrega nuevo paciente",
                                admission_id: admission.id,
                            };
                        }

                        if (!this.admissionsWithConflicts[admission.id]) {
                            admissionUpdates[`/admissions/${admission.id}`] = {
                                ...admission,
                                opened: true,
                            };

                            this.$set(this.preadmittedAdmissions, admission.id, true);
                        }
                    });

                    const batchLogUpdates = this.getBatchLogsUpdates();

                    const dbPatientsUpdates = this.getDbPatientUpdates();

                    const updates = {
                        ...patientsUpdates,
                        ...admissionUpdates,

                        ...batchLogUpdates,
                        ...patientsLogsUpdates,
                        ...dbPatientsUpdates,
                    };

                    this.$firebase
                        .database()
                        .ref()
                        .update(updates)
                        .then(() => {
                            console.log("success");

                            this.preadmitted = true;

                            this.selectAllAdmissions(true);

                            this.$f7.dialog.close();
                        })
                        .catch((err) => {
                            console.error(err);
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert("Hubo un error. " + err);
                        });
                });
            });
        },
        processSelectedAdmissions() {
            this.$f7.dialog.confirm("¿Está seguro?", () => {
                this.$f7.dialog.preloader("Procesando admisiones e imprimiendo etiquetas...");

                if (Object.keys(this.selectedAdmissions).length === 0) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Debe seleccionar admisiones para procesar");
                    return;
                }

                const admissionUpdates = {};
                const admittedExamsUpdates = {};
                const admittedSamplesUpdates = {};

                const admissionsLogsUpdates = {};
                const samplesLogsUpdates = {};
                const examsLogsUpdates = {};

                const currentDate = this.$moment().unix();

                Object.keys(this.selectedAdmissions).forEach((admissionId) => {
                    const { admittedExams, admittedSamples } = this.dataToCreate[admissionId];

                    Object.keys(admittedExams).forEach((examId) => {
                        admittedExamsUpdates[`/admittedExams/2/${examId}`] = admittedExams[examId];

                        // Exams log
                        const examLogId = this.$firebase
                            .database()
                            .ref(`/exams_log/${examId}`)
                            .push().key;
                        examsLogsUpdates[`/exams_log/${examId}/${examLogId}`] = {
                            who: this.profile_userInfo.uid,
                            whn: currentDate,
                            wht: "Examen admitido",
                        };
                    });

                    Object.keys(admittedSamples).forEach((sampleId) => {
                        admittedSamplesUpdates[`/admittedSamples/${sampleId}`] = admittedSamples[sampleId];

                        // Samples log
                        const sampleLogId = this.$firebase
                            .database()
                            .ref(`/samples_log/${sampleId}`)
                            .push().key;
                        samplesLogsUpdates[`/samples_log/${sampleId}/${sampleLogId}`] = {
                            who: this.profile_userInfo.uid,
                            whn: currentDate,
                            how: "manual",
                            wht: "Admitida",
                        };
                    });

                    admissionUpdates[`/admissions/${admissionId}/opened`] = null;

                    // Admission log
                    const admissionLogId = this.$firebase
                        .database()
                        .ref(`/admissions_log/${admissionId}`)
                        .push().key;
                    admissionsLogsUpdates[`/admissions_log/${admissionId}/${admissionLogId}`] = {
                        who: this.profile_userInfo.uid,
                        wht: "add-admission",
                        whn: currentDate,
                        dls: "Procesa nueva admisión",
                        ptn_id: this.dataToCreate[admissionId].admission.patientId,
                    };
                });

                const updates = {
                    ...admittedExamsUpdates,
                    ...admittedSamplesUpdates,
                    ...admissionUpdates,

                    ...admissionsLogsUpdates,
                    ...samplesLogsUpdates,
                    ...examsLogsUpdates,
                };

                this.$firebase
                    .database()
                    .ref()
                    .update(updates)
                    .then(() => {
                        console.log("success");

                        this.processed = true;

                        Object.keys(this.selectedAdmissions).forEach((admissionId, index) => {
                            const admission = this.dataToCreate[admissionId].admission;
                            const sampleId = Object.values(admission.samples)[0].id;

                            setTimeout(() => {
                                console.log("imprimiendo", sampleId);

                                this.printLabel(admission);
                            }, 400 * (index + 1));
                        });

                        this.$f7.dialog.close();
                    })
                    .catch((error) => {
                        console.error(error);
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert("Hubo un error. " + error);
                    });
            });
        },
        replaceSamplesCodes() {
            const createSamplesCodes = this.$firebase.functions().httpsCallable("createSamplesCodes");

            return createSamplesCodes({
                env: this.env,
                quantity: Object.keys(this.dataToCreate).length,
            }).then((response) => {
                const codes = response.data;

                this.samplesCodeRange = `${codes[0]}-${codes[codes.length - 1]}`;

                Object.values(this.dataToCreate).forEach((data, index) => {
                    const code = codes[index];

                    const admission = data.admission;

                    // Queremos reemplazar los codigos de las muestras creados localmente con los codigos
                    // generados por el sistema.
                    // Insertamos el codigo de la muestra generado por el sistema y eliminamos el
                    // anterior.
                    const sample = Object.values(this.dataToCreate[admission.id].admission.samples)[0];
                    this.$set(this.dataToCreate[admission.id].admission.samples, code, sample);
                    this.$set(this.dataToCreate[admission.id].admission.samples[code], "id", code);
                    this.$delete(this.dataToCreate[admission.id].admission.samples, index);

                    const examId = Object.keys(this.dataToCreate[admission.id].admittedExams)[0];
                    this.$set(this.dataToCreate[admission.id].admittedExams[examId].samples, code, {
                        id: code,
                    });
                    this.$delete(this.dataToCreate[admission.id].admittedExams[examId].samples, index);

                    const admittedSample = Object.values(this.dataToCreate[admission.id].admittedSamples)[0];
                    this.$set(this.dataToCreate[admission.id].admittedSamples, code, admittedSample);
                    this.$delete(this.dataToCreate[admission.id].admittedSamples, index);
                });
            });
        },
        clear(confirm = true) {
            const _clear = () => {
                this.verifiedPatients = 0;
                this.dataToCreate = {};
                this.selectedInstitution = "";
                this.samplesCount = 0;
                this.admissionsWithConflicts = {};
                this.preadmitted = false;
                this.processed = false;
                this.samplesCodeRange = "";
                this.currentConflict = {
                    excelPatient: {},
                    dbPatient: {},
                    admissionId: "",
                };
                this.fileName = "";
                this.preadmittedAdmissions = {};
                this.selectedAdmissions = {};
                this.updatedPatientsInAdmission = {};
            };

            if (confirm) {
                this.$f7.dialog.confirm("¿Está seguro?", () => {
                    _clear();
                });
            } else {
                _clear();
            }
        },
        fileChanged(ev) {
            const file = ev.target.files[0];

            if (file) {
                this.$f7.dialog.preloader("Importando excel...");

                console.log("file uploaded successfully", file);

                this.fileName = file.name;

                readXlsxFile(file)
                    .then((rows) => {
                        const admissionDate = Date.now();

                        rows.forEach((row, currentRow) => {
                            const rowNumber = currentRow + 1;

                            if (currentRow > 0) {
                                const admissionKey = this.$firebase
                                    .database()
                                    .ref()
                                    .push().key;

                                this.$set(this.dataToCreate, admissionKey, {
                                    admission: {},
                                    admittedExams: {},
                                    admittedSamples: {},
                                });

                                this.dataToCreate[admissionKey].admission = this.createLocalAdmission(rowNumber, row, admissionDate, admissionKey);

                                const exam = Object.values(this.dataToCreate[admissionKey].admission.exams)[0];

                                this.dataToCreate[admissionKey].admittedExams[exam.id] = this.createLocalAdmittedExams(exam, admissionKey);

                                const sampleId = Object.keys(exam.samples)[0];

                                this.dataToCreate[admissionKey].admittedSamples[sampleId] = this.createLocalAdmittedSamples(
                                    this.dataToCreate[admissionKey].admission
                                );

                                const patientIdentificador = this.dataToCreate[admissionKey].admission.patient.identificador;

                                this.$firebase
                                    .database()
                                    .ref(`/pacientes`)
                                    .orderByChild("identificador")
                                    .equalTo(patientIdentificador)
                                    .once("value")
                                    .then((snapshot) => {
                                        this.verifiedPatients = this.verifiedPatients + 1;

                                        if (snapshot.exists()) {
                                            const patientsCount = Object.keys(snapshot.val()).length;

                                            if (patientsCount > 1) {
                                                this.$set(this.admissionsWithConflicts, admissionKey, true);
                                                return;
                                            }

                                            const patientFromExcel = this.dataToCreate[admissionKey].admission.patient;

                                            const patientFromDb = Object.values(snapshot.val())[0];

                                            if (this.patientIsExactlyTheSame(patientFromExcel, patientFromDb)) {
                                                const { patient, wasChanged } = this.addPatientMissingFields(patientFromExcel, patientFromDb);

                                                this.dataToCreate[admissionKey].admission.patientId = patient.id;
                                                this.dataToCreate[admissionKey].admission.patient = patient;

                                                if (wasChanged) {
                                                    this.$set(this.updatedPatientsInAdmission, admissionKey, true);
                                                }
                                            } else {
                                                this.$set(this.admissionsWithConflicts, admissionKey, true);
                                            }
                                        }
                                    });
                            }

                            // Cerrar el dialog cuando todos los conflictos hayan sido verificados
                            //this.$f7.dialog.close();
                        });
                    })
                    .catch((err) => {
                        err.name = "";
                        console.error(err);
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(err, "Error de formato del Excel");

                        this.clear(false);
                    });
            } else {
                this.fileName = "";
                this.$f7.dialog.close();
                console.error("file not found");
            }
        },

        // Admission
        createLocalAdmission(rowNumber, row, admissionDate, admissionKey) {
            try {
                const institution = this.institutions[this.selectedInstitution];

                const admission = {
                    agreement: null,
                    creationDate: admissionDate,
                    exams: {},
                    id: admissionKey,
                    institution: institution.name,
                    institution_id: institution.id,
                    patient: {},
                    patientId: null,
                    samples: {},
                    username: this.profile_userInfo.displayName,
                };

                admission.patient = this.createPatient(row);
                admission.samples[this.samplesCount] = this.createSamples(row, admissionDate);

                const examsKey = this.$firebase
                    .database()
                    .ref()
                    .push().key;

                admission.exams[examsKey] = this.createExams(row, examsKey, admission.samples);

                return admission;
            } catch (err) {
                err.name = "";
                throw new Error(err + ` (Error en fila ${rowNumber})`);
            }
        },

        createPatient(row) {
            const patient = {};

            const tipoId = row[this.cols.paciente_tipodoc] ? row[this.cols.paciente_tipodoc].toUpperCase() : "";

            if (tipoId === "RUN" || tipoId === "RUT" || (tipoId === "" && row[this.cols.paciente_run])) {
                patient.tipoIdentificador = "RUT";

                if (row[this.cols.paciente_run] !== null) {
                    const dv = row[this.cols.paciente_dv] !== null ? row[this.cols.paciente_dv].toString() : "";
                    patient.identificador = clean(row[this.cols.paciente_run].toString()) + dv;
                } else {
                    patient.identificador = "";
                }
            } else if (tipoId === "PASAPORTE") {
                patient.tipoIdentificador = "PASAPORTE";

                if (row[this.cols.paciente_pasaporte]) {
                    patient.identificador = row[this.cols.paciente_pasaporte].toString();
                } else {
                    patient.identificador = "";
                }
            } else if (tipoId === "DNI") {
                patient.tipoIdentificador = "DNI";
                patient.identificador = "";
            } else {
                throw new Error(
                    "Tipo Identificador o Identificador del paciente no especificado. Revisar columnas: paciente_tipo_doc / paciente_run / paciente_dv / paciente_pasaporte"
                );
            }

            if (!row[this.cols.paciente_ext_paisorigen]) {
                patient.nacionalidad = "Chile";
            } else {
                const countryToFind = row[this.cols.paciente_ext_paisorigen].toUpperCase();
                const foundCountry = this.countriesIso.find((c) => c.alpha3.toUpperCase() == countryToFind || c.name.toUpperCase() == countryToFind);

                if (foundCountry && foundCountry.name) {
                    patient.nacionalidad = this.$capitalize(foundCountry.name);
                } else {
                    throw new Error("Nacionalidad del paciente no encontrada. Revisar columnas: paciente_ext_paisorigen");
                }
            }

            patient.nombres = row[this.cols.paciente_nombres] ? row[this.cols.paciente_nombres].toString().toUpperCase() : null;
            patient.primerApellido = row[this.cols.paciente_ap_pat] ? row[this.cols.paciente_ap_pat].toString().toUpperCase() : null;
            patient.segundoApellido = row[this.cols.paciente_ap_mat] ? row[this.cols.paciente_ap_mat].toString().toUpperCase() : null;

            let fechaNac = "";

            if (typeof row[this.cols.paciente_fecha_nac] === "number") {
                fechaNac = this.excelDateToJSDate(row[this.cols.paciente_fecha_nac]);
                fechaNac = this.$moment(fechaNac, "DD-MM-YYYY")
                    .add(1, "days")
                    .format("DD/MM/YYYY");
            } else if (typeof row[this.cols.paciente_fecha_nac] === "string") {
                fechaNac = this.$moment(row[this.cols.paciente_fecha_nac], "DD-MM-YYYY").format("DD/MM/YYYY");
            } else {
                throw new Error("Fecha de nacimiento del paciente inválida. Revisar columnas: paciente_fecha_nac");
            }
            if (fechaNac === "Invalid date") {
                throw new Error("Fecha de nacimiento del paciente inválida. Revisar columnas: paciente_fecha_nac");
            }

            patient.fechaNacimiento = fechaNac;

            patient.direccion = row[this.cols.paciente_direccion] ? row[this.cols.paciente_direccion].toString().toUpperCase() : null;
            patient.comuna = row[this.cols.paciente_comuna] ? row[this.cols.paciente_comuna].toString().toUpperCase() : null;

            patient.telefono = row[this.cols.paciente_telefono] ? row[this.cols.paciente_telefono].toString() : "";

            patient.email = row[this.cols.paciente_email] ? row[this.cols.paciente_email].toString().toUpperCase() : null;

            if (row[this.cols.paciente_sexo].toUpperCase() === "F" || row[this.cols.paciente_sexo].toUpperCase() === "FEMENINO") {
                patient.sexo = "Femenino";
            } else if (row[this.cols.paciente_sexo].toUpperCase() === "M" || row[this.cols.paciente_sexo].toUpperCase() === "MASCULINO") {
                patient.sexo = "Masculino";
            } else {
                throw new Error("Sexo del paciente no identificado. Debe ser F o M. Revisar columnas: paciente_sexo");
            }

            patient.prevision = row[this.cols.paciente_prevision] ? row[this.cols.paciente_prevision].toString().toUpperCase() : null;

            return patient;
        },

        createSamples(row, admissionDate) {
            const samples = {};

            samples.admissionDate = admissionDate;

            // Fix: hay que hacer un metodo con esto para utilizar tambien en createPatient
            let fechaMuestra = "";

            if (typeof row[this.cols.fecha_muestra] === "number") {
                fechaMuestra = this.excelDateToJSDate(row[this.cols.fecha_muestra]);
                fechaMuestra = this.$moment(fechaMuestra, "DD-MM-YYYY")
                    .add(1, "days")
                    .format("DD/MM/YYYY");
            } else if (typeof row[this.cols.fecha_muestra] === "string") {
                if (row[this.cols.fecha_muestra] === "") {
                    const admissionDateUnix = (admissionDate / 1000) | 0;
                    fechaMuestra = this.$moment.unix(admissionDateUnix).format("DD/MM/YYYY");
                } else {
                    fechaMuestra = this.$moment(row[this.cols.fecha_muestra], "DD-MM-YYYY").format("DD/MM/YYYY");
                }
            } else if (typeof row[this.cols.fecha_muestra] === "object") {
                fechaMuestra = this.$moment(row[this.cols.fecha_muestra], "DD-MM-YYYY").format("DD/MM/YYYY");
            } else {
                throw new Error("Fecha de muestra inválida. Revisar columnas: fecha_muestra");
            }
            if (fechaMuestra === "Invalid date") {
                throw new Error("Fecha de muestra inválida. Revisar columnas: fecha_muestra");
            }

            samples.dateTime = fechaMuestra;

            samples.dateUnknown = row[this.cols.fecha_muestra] ? null : true;
            samples.externalId = "20"; // hardcodeado para Hisopado Nasofaríngeo
            samples.id = this.samplesCount;
            samples.name = "Hisopado nasofaríngeo";

            if (row[this.cols.rut_responsable]) {
                samples.resp = {
                    idType: "RUT",
                    identifier: clean(row[this.cols.rut_responsable]),
                };
            }

            // Las samples se crean localmente con un codigo correlativo de 0 a n. Cada sample ingresada
            // incrementa esto
            this.samplesCount++;

            return samples;
        },

        createExams(row, examsKey, samples) {
            const exams = {};

            exams.id = examsKey;
            exams.externalId = "COVID-19";
            exams.name = "PCR Coronavirus (COVID-19)";
            exams.section_id = "2";

            if (row[this.cols.rut_medico]) {
                exams.doctor = {
                    rut: clean(row[this.cols.rut_medico]),
                };
            }

            const sampleKey = Object.keys(samples)[0];

            exams.samples = {
                [sampleKey]: { id: sampleKey },
            };

            return exams;
        },

        // Admitted Exams
        createLocalAdmittedExams(exam, admissionKey) {
            return {
                admissionId: admissionKey,
                ...exam,
            };
        },

        // Admitted Samples
        createLocalAdmittedSamples(admission) {
            const examKey = Object.keys(admission.exams)[0];

            return {
                admissionDate: admission.creationDate,
                admissionId: admission.id,
                exams: {
                    [examKey]: true,
                },
                from: admission.creationDate,
                sections: {
                    2: false,
                },
            };
        },

        // Checkboxes logic
        selectAdmission(checked, admissionId) {
            if (checked) {
                this.$set(this.selectedAdmissions, admissionId, true);
            } else {
                this.$delete(this.selectedAdmissions, admissionId);
            }
        },

        selectAllAdmissions(checked) {
            Object.keys(this.dataToCreate).forEach((admissionId) => {
                if (this.preadmittedAdmissions[admissionId]) {
                    this.selectAdmission(checked, admissionId);
                }
            });
        },

        isAdmissionChecked(admissionId) {
            return Object.keys(this.selectedAdmissions).findIndex((id) => id === admissionId) !== -1;
        },

        handlePatientInfoPopupClosed() {
            this.viewingPatient = {};
            this.patientInfoPopupOpened = false;
        },

        patientInfo(patient) {
            return `${patient.tipoIdentificador}: ${patient.identificador} - ${patient.nombres} ${patient.primerApellido ||
                ""} ${patient.segundoApellido || ""}`;
        },

        viewPatient(patient, admissionId) {
            this.viewingPatient = {
                patient,
                admissionId,
            };

            this.patientInfoPopupOpened = true;
        },

        patientIsExactlyTheSame(excelPatient, dbPatient) {
            let dbPatientCopy = Object.assign({}, dbPatient);
            delete dbPatientCopy.id;

            const dbPatientKeys = Object.keys(dbPatientCopy);

            let isEqual = true;

            for (let i = 0; i < dbPatientKeys.length; i++) {
                const propertyName = dbPatientKeys[i];

                const dbPatientProperty =
                    typeof dbPatient[propertyName] === "string" ? dbPatient[propertyName].toUpperCase() : dbPatient[propertyName];

                const excelPatientProperty =
                    typeof excelPatient[propertyName] === "string" ? excelPatient[propertyName].toUpperCase() : excelPatient[propertyName];

                if (dbPatientProperty !== excelPatientProperty) {
                    isEqual = false;
                }
            }

            return isEqual;
        },
        addPatientMissingFields(excelPatient, dbPatient) {
            const newKeys = [];

            Object.keys(excelPatient).forEach((excelPatientKey) => {
                if (!dbPatient[excelPatientKey] && excelPatient[excelPatientKey]) {
                    newKeys.push(excelPatientKey);
                }
            });

            const dbPatientCopy = Object.assign({}, dbPatient);

            newKeys.forEach((key) => (dbPatientCopy[key] = excelPatient[key]));

            const wasChanged = newKeys.length > 0;

            return { patient: dbPatientCopy, wasChanged };
        },
        getDbPatientUpdates() {
            const updates = {};

            Object.keys(this.updatedPatientsInAdmission).forEach((admissionId) => {
                const admission = this.dataToCreate[admissionId].admission;
                updates[`/pacientes/${admission.patientId}`] = admission.patient;

                // Log patient editted
                const patientLogId = this.$firebase
                    .database()
                    .ref(`/patients_log/${admission.patientId}`)
                    .push().key;

                updates[`/patients_log/${admission.patientId}/${patientLogId}`] = {
                    who: this.profile_userInfo.uid,
                    wht: "edit-patient",
                    whn: this.$moment().unix(),
                    dls: "Edita datos de paciente",
                    admission_id: admissionId,
                };
            });

            return updates;
        },

        handlePatientInfoChanged(data) {
            const { patient, admissionId } = data;

            this.$set(this.dataToCreate[admissionId].admission, "patient", patient);
            this.viewingPatient = {};
            this.patientInfoPopupOpened = false;

            this.$firebase
                .database()
                .ref(`/pacientes`)
                .orderByChild("identificador")
                .equalTo(patient.identificador)
                .once("value")
                .then((snapshot) => {
                    if (snapshot.exists()) {
                        this.$set(this.admissionsWithConflicts, admissionId, true);
                    } else {
                        this.$delete(this.admissionsWithConflicts, admissionId);
                    }
                });
        },

        resolveConflicts(patient, admissionId) {
            this.$f7.dialog.preloader("Cargando...");

            this.currentConflict.excelPatient = patient;
            this.currentConflict.admissionId = admissionId;

            this.$firebase
                .database()
                .ref(`/pacientes`)
                .orderByChild("identificador")
                .equalTo(patient.identificador)
                .once("value")
                .then((snapshot) => {
                    const patients = snapshot.val();
                    const howManyPatients = Object.keys(patients).length;

                    if (howManyPatients > 1) {
                        console.log("hay mas de 1 paciente, debe seleccionar");

                        this.$f7.dialog.close();

                        this.foundPatients = patients;
                        this.selectPatientPopupOpened = true;
                    } else if (howManyPatients === 1) {
                        const onlyPatient = Object.values(patients)[0];

                        this.handleSelectPatient(onlyPatient);
                    }
                });
        },

        handleSelectPatient(patient) {
            this.currentConflict.dbPatient = patient;
            this.selectPatientPopupOpened = false;

            this.foundPatients = {};

            this.$f7.dialog.close();
            this.resolveConflictsPopupOpened = true;
        },

        useDbPatient() {
            this.$f7.dialog.confirm("Esta acción descartará el paciente en el Excel para utilizar el de la base de datos.", "¿Está seguro?", () => {
                const admissionId = this.currentConflict.admissionId;

                this.$set(this.dataToCreate[admissionId].admission, "patient", this.currentConflict.dbPatient);
                this.dataToCreate[admissionId].admission.patientId = this.currentConflict.dbPatient.id;

                this.$delete(this.admissionsWithConflicts, admissionId);

                this.resolveConflictsPopupOpened = false;
            });
        },

        useExcelPatient() {
            this.$f7.dialog.confirm("Esta acción modificará al paciente en la base de datos.", "¿Está seguro?", () => {
                const admissionId = this.currentConflict.admissionId;
                const dbPatientId = this.currentConflict.dbPatient.id;

                this.$set(this.currentConflict.excelPatient, "id", dbPatientId);

                // Patient log
                const patientLogId = this.$firebase
                    .database()
                    .ref(`/patients_log/${dbPatientId}`)
                    .push().key;

                const updates = {
                    [`/pacientes/${dbPatientId}`]: this.currentConflict.excelPatient,
                    [`/patients_log/${dbPatientId}/${patientLogId}`]: {
                        who: this.profile_userInfo.uid,
                        wht: "edit-patient",
                        whn: this.$moment().unix(),
                        dls: "Edita datos de paciente",
                        admission_id: admissionId,
                    },
                };

                this.$firebase
                    .database()
                    .ref()
                    .update(updates)
                    .then(() => {
                        this.dataToCreate[admissionId].admission.patientId = this.currentConflict.dbPatient.id;

                        this.$delete(this.admissionsWithConflicts, admissionId);

                        this.resolveConflictsPopupOpened = false;
                    });
            });
        },

        patientHasId(admissionId) {
            return this.dataToCreate[admissionId].admission.patientId === null;
        },

        getInstitutions() {
            this.$f7.dialog.preloader("Cargando...");

            this.$firebase
                .database()
                .ref("institutions/")
                .orderByChild("enabled")
                .equalTo(true)
                .once("value")
                .then((snapshot) => {
                    this.institutions = snapshot.val() || {};
                    this.$f7.dialog.close();
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, err.code);
                });
        },

        isPatientIdValid(patient) {
            if (!patient || patient.tipoIdentificador !== "RUT") {
                return true;
            }

            return validate(patient.identificador);
        },

        excelDateToJSDate(serial) {
            var utc_days = Math.floor(serial - 25569);
            var utc_value = utc_days * 86400;
            var date_info = new Date(utc_value * 1000);
            var fractional_day = serial - Math.floor(serial) + 0.0000001;
            var total_seconds = Math.floor(86400 * fractional_day);
            var seconds = total_seconds % 60;
            total_seconds -= seconds;
            var hours = Math.floor(total_seconds / (60 * 60));
            var minutes = Math.floor(total_seconds / 60) % 60;
            return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
        },

        printLabel(admission) {
            const patient = admission.patient;
            const sample = Object.values(admission.samples)[0];

            this.makelabel(
                this.lastNamesAndNames(patient),
                this.formatId(patient),
                patient.fechaNacimiento,
                this.suageEtiquetas(patient.fechaNacimiento),
                sample.id,
                sample.name,
                sample.dateTime
            );
        },
    },
};
</script>