<template>
    <table class="table table-sm table-bordered bg-color-white">
        <thead class="thead-default">
            <tr>
                <th>Clase</th>
                <th>kU/L</th>
                <th>Resultado</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(classObject, index) in classes" :key="classObject.class">
                <td :class="`class-int-${classObject.class} text-align-center`">
                    <b>{{ classObject.class }}</b>
                </td>
                <td>{{ getValuesText(classObject, index) }}</td>
                <td>{{ classObject.result }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { classes } from "@/app/constants";

export default {
    data() {
        return {
            classes,
        };
    },

    methods: {
        getValuesText(classObject, index) {
            const { minVal, maxVal } = classObject;

            if (index === 0) {
                return `<${maxVal}`;
            } else if (index === this.classes.length - 1) {
                return `>=${minVal}`;
            }

            return `>=${minVal} a <${maxVal}`;
        },
    },
};
</script>
