<template>
    <f7-popup :opened="opened" @popup:closed="$emit('popup-closed')">
        <f7-page>
            <f7-navbar title="Flujograma del examen">
                <f7-nav-right>
                    <f7-link popup-close color="blue">Cerrar</f7-link>
                </f7-nav-right>
            </f7-navbar>

            <f7-block>
                <p v-if="exam.ot">
                    <b>OT:</b>
                    {{ exam.ot }}
                </p>
                <p>
                    <b>Exámen:</b>
                    ({{ exam.externalId }}) {{ exam.name }}
                </p>

                <f7-row class="margin-bottom">
                    <f7-col>
                        <change-state-button
                            state="admitted"
                            :exam="exam"
                            @status-changed="handleExamStatusChanged"
                            fill
                        >
                            Admitido
                        </change-state-button>
                    </f7-col>
                    <f7-col>
                        <change-state-button
                            state="pending"
                            :exam="exam"
                            @status-changed="handleExamStatusChanged"
                            fill
                            color="yellow"
                        >
                            Pendiente
                        </change-state-button>
                    </f7-col>
                    <f7-col>
                        <change-state-button
                            state="rejected"
                            :exam="exam"
                            @status-changed="handleExamStatusChanged"
                            fill
                            color="red"
                        >
                            Rechazado
                        </change-state-button>
                    </f7-col>
                </f7-row>

                <table class="table table-bordered table-sm">
                    <thead>
                        <th>Estado</th>
                        <th>Usuario</th>
                        <th>Fecha</th>
                    </thead>
                    <tbody>
                        <tr v-for="statusId in Object.keys(exam.statusLog || {})" :key="statusId">
                            <td>
                                <b>{{ getStatusText(exam.statusLog[statusId].status) }}</b>
                                <div
                                    v-if="
                                        ['rechazado', 'pendiente'].includes(exam.statusLog[statusId].status)
                                    "
                                    class="text-color-gray"
                                >
                                    Motivo: {{ exam.statusLog[statusId].reason }}
                                </div>
                            </td>
                            <td>{{ exam.statusLog[statusId].who }}</td>
                            <td>{{ $moment.unix(exam.statusLog[statusId].when).format("llll") }}</td>
                        </tr>
                    </tbody>
                </table>
            </f7-block>
        </f7-page>
    </f7-popup>
</template>

<script>
import { _getStatusText } from "@/app/helpers";
import ChangeStateButton from "@/app/components/change-state-button.vue";

export default {
    props: {
        opened: Boolean,
        exam: Object,
    },

    components: {
        "change-state-button": ChangeStateButton,
    },

    data() {
        return {
            getStatusText: _getStatusText,
        };
    },

    methods: {
        handleExamStatusChanged(data) {
            this.$emit("exam-status-changed", data);
        },
    },
};
</script>
