<template>
    <f7-page >
        <f7-navbar>
            <f7-nav-title >Insumos</f7-nav-title>
        </f7-navbar>
        <form @submit.prevent="create" >
            <f7-block>
                <f7-row>
                    <f7-col>
                        <!-- Codigo -->
                        <f7-row class="mb-2">
                            <f7-col width="50" class="padding-1">
                                <f7-button v-if="form.active" @click="form.active = 0" btn fill >Inhabilitar Insumo</f7-button>
                                <f7-button v-if="!form.active" @click="form.active = 1" btn outline >Habilitar Insumo</f7-button>
                            </f7-col>
                            <f7-col width="50">
                                <f7-col width="60" class="padding-1">
                                    <f7-button @click="openModal('.newImage')" btn outline >Imagen del insumo</f7-button>
                                </f7-col>
                            </f7-col>
                        </f7-row>
                        <f7-row class="mb-2"> 
                            <f7-col width="100"  class="padding-1">
                                <label>Descripcion</label>
                                <f7-input clear-button max-length="150" required @change="form.description = $event.target.value" type="text"  info="Descripcion del insumo en deposito" :value="form.description" />
                            </f7-col>
                        </f7-row>
                         <f7-row class="mb-2"> 
                            <f7-col width="100"  class="padding-1">
                                <label class="item-title" style="font-size: 13px !important; color: black; margin-top:1%">Secciones</label>
                            </f7-col>
                        </f7-row>
                        <f7-row class="mb-2" no-gap>
                           
                                <f7-col v-for="item of sectionsCheck()" width="50" :key="item.id">
                                    <f7-list no-hairlines-md style="margin:0">
                                        <f7-list-item style="padding: 0 !important" :checked="checkedBox(item.id)" checkbox @change="changeSection($event.target.checked,item.id)" :title="item.name"></f7-list-item>
                                    </f7-list>
                                </f7-col>

                        </f7-row>
                        <!-- Botones para crear -->
                        <f7-row class="mb-2">
                            <f7-col>
                                <f7-list no-hairlines-md style=""> 
                                    <label class="item-title" style="font-size: 13px; color: black; padding-left:1.5%">Documento de seguridad</label>
                                    <f7-list-input name="fileInput" class="inputFileStyle"  accept="application/pdf" type="file"  @change="security = $event.target.files[0]" ></f7-list-input>
                                </f7-list>
                            </f7-col>
                        </f7-row>
                        <f7-row class="mb-2"> 
                            <!-- Medida -->
                            <f7-col width="50"  class="padding-1">
                                <label>Medida de almacenaje</label>
                                <f7-input max-length="100" clear-button required type="text" @change="form.unit = $event.target.value" :value="form.unit" placeholder="Unidad de medida Descriptiva" info="Unidad de medida de alamacen: 100 Ml" />
                            </f7-col>
                            <!-- Limite de stock -->
                            <f7-col width="50"  class="padding-1">
                                <label>Stock Critico</label>
                                <f7-input min="0"  required type="number" @change="form.limit = $event.target.value" :value="form.limit" placeholder="100" info="Limite de stock antes de alertar"/>
                            </f7-col>
                        </f7-row>
                        <!-- Observaciones -->
                        <f7-row class="mb-2"> 
                            <f7-col width="100"  class="padding-1">
                                <label>Observaciones y detalles</label> 
                                <f7-input clear-button :value="form.detail" @change="form.detail = $event.target.value" class="rows" type="textarea" placeholder="..." info="Observaciones y detalles" resizable/>
                            </f7-col>
                        </f7-row>
                        <!-- Advertencias -->
                        <f7-row class="mb-2"> 
                            <f7-col width="100"  class="padding-1">
                                <label>Precauciones y advertencias</label> 
                                <f7-input clear-button :value="form.danger" @change="form.danger = $event.target.value" class="rows" type="textarea" placeholder="..." info="Medidas y reglas de manejo para su seguridad" resizable/>
                            </f7-col>
                        </f7-row>
                        <!-- Guardar -->
                        <f7-row class="mb-2"> 
                            <f7-col />
                            <f7-col />
                            <f7-col >
                                <f7-button :disabled="submited" type="submit" fill  >Guardar</f7-button>
                            </f7-col>
                        </f7-row>
                    </f7-col>
                    <f7-col width="50">
                        <f7-block class="tablevh">
                            <f7-row class="mb-2">
                                <f7-col width="100"  class="padding-1">
                                    <label>Relacionar proveedor a este insumo</label>
                                    <f7-input :value="selectedProvider" @change="selectedProvider = $event.target.value" type="select"  info="Agregue los proveedor asociados a este insumo" >
                                        <option value="NA" disabled selected>Seleccione Proveedor</option>
                                        <option v-for="(item) in this.$store.state.provider" :key="item.id" :value="item.id">{{ item.description }} </option>
                                    </f7-input>
                                </f7-col>
                            </f7-row>
                            <f7-row class="mb-2">
                                <f7-col class="padding-1"><f7-button @click="openModal('.proveedor')"  fill>Nuevo Proveedor</f7-button></f7-col>
                                <f7-col class="padding-1"><f7-button @click="addElement('provider' ,selectedProvider)"  outline >Agregar Prov</f7-button></f7-col>
                            </f7-row>
                            <f7-row >
                                <f7-col>
                                    <div class="data-table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style="width:90%" class="label-cell">Proveedores relacionados</th>
                                                    <th style="width:10%;text-align: center" class="numeric-cell"><f7-icon f7="trash"></f7-icon></th>
                                                </tr>
                                            </thead>
                                            <tbody style="overflow-y:scroll">
                                                <tr v-for="(item) in bulk.provider" :key="item.id">
                                                    <td class="label-cell">{{ item.description }}</td>
                                                    <td class="numeric-cell"><f7-button @click="removeElement('provider', item.id)"  btn round small class="color-red" outline><f7-icon size="15" f7="trash"></f7-icon></f7-button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </f7-col>
                            </f7-row>
                        </f7-block>
                        <hr class="mb-2">
                        <f7-block class="tablevh">
                            <f7-row class="mb-2">
                                <f7-col class="padding-1">
                                    <label>Relacionar marca a este insumo</label>
                                    <f7-input :value="selectedBrand" @change="selectedBrand = $event.target.value" type="select"    info="Agregue las marcas asociadas a este insumo" >
                                        <option value="NA" disabled selected>Seleccione Marca</option>
                                        <option v-for="(item) in this.$store.state.brand" :key="item.id" :value="item.id">{{ item.description }} </option>
                                    </f7-input>
                                </f7-col>
                               
                            </f7-row>
                            <f7-row class="mb-2">
                                <f7-col class="padding-1"><f7-button @click="openModal('.brands')" fill >Nueva marca</f7-button></f7-col>
                                <f7-col class="padding-1"><f7-button @click="addElement('brand', selectedBrand)" outline>Agregar Marca</f7-button></f7-col>
                            </f7-row>
                            <f7-row>
                                <f7-col>
                                    <div class="data-table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style="width:90%" class="label-cell">Marcas relacionadas</th>
                                                    <th style="width:10%;text-align: center" class="numeric-cell"><f7-icon f7="trash"></f7-icon></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item) in bulk.brand" :key="item.id">
                                                    <td class="label-cell">{{ item.description }}</td>
                                                    <td class="numeric-cell"><f7-button @click="removeElement('brand', item.id)" btn round small class="color-red" outline><f7-icon size="15" f7="trash"></f7-icon></f7-button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </f7-col>
                            </f7-row>
                        </f7-block>
                    </f7-col>
                </f7-row>
                
            </f7-block>
            
        </form>
        <!-- Modales -->
        <f7-popup class="proveedor"><proveedor @addElementArray="addElementArray"></proveedor></f7-popup>
        <f7-popup class="brands"><brands @addElementArray="addElementArray"></brands></f7-popup>
        <f7-popup class="newImage"><newImage :rebootImage="rebootImage" @save="saveImage"></newImage></f7-popup>
        <f7-popup class="sectionsAsign"><sectionsAsign :sections="bulk.sections" @changeSections="changeSection" @updateSections="updateSection"></sectionsAsign></f7-popup>
    </f7-page>
</template>

<script>


import { mapState } from 'vuex';
import proveedor from '../../providers/provider';
import newImage from '../components/newImage';
import brands from '../../brands/brands.vue'
import sectionsAsign from '../components/secciones.vue'


export default {

    components : {
      proveedor,
      brands,
      newImage,
      sectionsAsign
    },
    data(){
        return {
            form : {
                code : "",
                description : "",
                detail : "",
                unit : "",
                limit : 0,                
                danger : "",
                image : "",
                security : "",
                active : 1
            },
            bulk : {
                provider : [],
                brand : [],
                sections : []
            },            
            selectedBrand : "NA",
            selectedProvider : "NA",
            selectedFile : "",
            security : "",
            submit : false,
            reboot : false,
            rebootImage : false,
            active : 0
        } 
    },
    computed : {
       ...mapState(["section", "equip"]),
       submited(){
           return this.submit;
       }
    },
    mounted(){
        let textareas = document.getElementsByTagName('textarea');
    
        for (let item of textareas) {
            item.setAttribute('rows', 5);
        }

       this.init();
    },
    methods : {
        sectionsCheck(){
            console.log(this.section)
            return this.section;
        },
        changeSection(check, section){
            this.changeSection2({ check , section })
            
        },
        checkedBox(value){
            
            for(const item of this.bulk.sections){
                console.log(item)
                if(item == value){
                    return true
                }
            }
            return false
        },
        updateSection(values){
            this.bulk.sections = values;
        },
        changeSection2(values){
            if(values.check == true){
                this.bulk.sections.push(values.section)
            }else{
                let temp = [];
                for(const item of this.bulk.sections){
                    if(item != values.section){
                        temp.push(item)
                    }
                }
                this.bulk.sections = temp;
            }
        },
        addElement(element,value){
        
            var cont = true;

            if(value != "NA"){
                 this.bulk[element].forEach(item => {
                    if(item.id == value){
                        this.$store.dispatch('toast',{ app : this, text : "Relacion ya definida" })
                        cont = false;;
                    }
                });
                if(cont){
                    this.$store.state[element].forEach(item => {
                        if(item.id == value){
                            this.bulk[element].push(item);
                        }
                    });
                }
                
            }else{
                this.$store.dispatch('toast',{ app : this, text : "Seleccione un elemento" })
            }
            
        },
        addElementArray(array){
            var cont = true;

            this.bulk[array.element].push(array.value);
        },
        removeElement(element,value){
            var tempArray = [];
            this.bulk[element].forEach(item => {
                if(item.id != value){
                    tempArray.push(item);
                }
            });
            this.bulk[element] = tempArray;
        },
        saveImage(file){
            this.selectedFile = file;
        },
        openModal(modal){
            this.reboot = (this.reboot == true) ? false : true ;
            this.$f7.popup.open(modal);
        },
        asign(value){
            this.form[value.field] = value.value;
        },
        padd(val){
            this.form.code = val.replace(/[a-z]/gi, '') .padStart(10,'0')
        },
        async create(){
            if(this.bulk.sections.length == 0){
                this.$store.dispatch("toast",{ app : this, text : "Debe seleccionar una seccion" });
                return false;
            }

            this.submit = true;

            if(this.selectedFile !== ""){ this.form.image = await this.$store.dispatch('uploadFile', {app : this, path : 'images/supply/', file : this.selectedFile, alert : true}) }
            if(this.security !== ""){ this.form.security = await this.$store.dispatch('uploadFile', {app : this, path : 'securityPDF/', file : this.security, alert : true}) }

            this.form.provider = this.bulk.provider;
            this.form.brands = this.bulk.brand;
            this.form.sections = this.bulk.sections

            this.$firebase.auth().currentUser.getIdToken().then(async (token) => {
                
                var result = await this.$store.dispatch(
                    "apiRequest",
                    { url : "supply/" ,token , method : "POST", data : this.form, request : {before : 'Creando insumo...'}, app : this}
                );

               
                    console.log('here')
                  

                    this.init();
                    this.rebootImage = (this.rebootImage == true) ? false : true ;
                    this.$store.dispatch("updateState",{token, state : [{"path" : "universal/?model=Supply", "model" : "supply"}]});
                    if(this.$f7route.query.returnUrl){
                        this.$f7router.navigate(this.$f7route.query.returnUrl);
                    }
                
            });

            this.submit = false;
        },
        init(){
            var x = document.getElementsByName('fileInput');
            for(const item of x){
                console.log(item)
                item.value = ""
            }
            this.$firebase.auth().currentUser.getIdToken().then(async (token) => {
                var result = await this.$store.dispatch("apiRequest",{ url : "supply/" ,token , method : "GET"});
                var id = 0;
                result.info.data.forEach(item => {
                    id = (parseInt(item.code) > id) ? parseInt(item.code) : id;
                })
                console.log(id)
                this.form.code = ((parseInt(id) + 1)+'').padStart(10,'0');
                this.form.description = "";
                this.form.detail = "";
                this.form.unit = "";
                this.form.limit = 0;
                this.form.danger = "";
                this.form.image = "";
                this.bulk.sections = []
                this.bulk.provider = []
                this.bulk.brand = []
                this.selectedBrand = "NA";
                this.selectedProvider = "NA";
                this.security = "";
                this.active = 1;
                
            });
        },
        
    }

}
</script>

<style scoped>
.mb-2{
    margin-bottom: 4% !important;
    

}
.mb-1{
    margin-bottom: 3% !important;
    
}
.align-left{
    text-align: left;
}
.padding-1{
    padding: 0% 1%;
}
.toast{
    left: 45% !important;
}
.toast-text{
    font-size: 1.3em;
}
.item-title{
    font-size: 13px !important;
    color: black !important;
}
.inputFileStyle > div{
    padding-left: 1% !important;
}
</style>