<template>
    <img src="../../../../static/img/footer-inmunocel.jpeg" :width="width" :height="height"  alt="Footer Inmunocel" />
</template>

<script>
export default {
    props: {
        width: Number,
        height: Number,
    },
};
</script>

