module.exports = {
    namespaced: true,

    state: {
        allRejectionReasons: [],
        viewingRejectionReason: null,

        allPendingReasons: [],
        viewingPendingReason: null,
    },

    mutations: {
        // Rejection

        set_all_rejection_reasons(state, reasons) {
            state.allRejectionReasons = reasons;
        },

        add_rejection_reason(state, newRejectionReason) {
            state.allRejectionReasons.push(newRejectionReason);
        },

        update_rejection_reason(state, rejectionReasonUpdated) {
            const index = state.allRejectionReasons.findIndex((r) => r.id === rejectionReasonUpdated.id);

            state.allRejectionReasons.splice(index, 1, rejectionReasonUpdated);
        },

        set_viewing_rejection_reason(state, rejectionReason) {
            state.viewingRejectionReason = rejectionReason;
        },

        // Pending

        set_all_pending_reasons(state, reasons) {
            state.allPendingReasons = reasons;
        },

        add_pending_reason(state, newPendingReason) {
            state.allPendingReasons.push(newPendingReason);
        },

        update_pending_reason(state, pendingReasonUpdated) {
            const index = state.allPendingReasons.findIndex((r) => r.id === pendingReasonUpdated.id);

            state.allPendingReasons.splice(index, 1, pendingReasonUpdated);
        },

        set_viewing_pending_reason(state, pendingReason) {
            state.viewingPendingReason = pendingReason;
        },
    },
};
