<template>
	<f7-list-item
		:class="{'skeleton-text skeleton-effect-blink':!patinfo}"
    	:link="!soyhistorico?'#':null"
		class="noaltoxs"
		@click="!soyhistorico?verDetalle():null"
		:subtitle="patinfo?(lastNamesAndNames(patinfo) + ' | ' + formatId(patinfo)):'ApellidoPAT ApellidoMat, Nombreuno nombredos | 11.111.111-1'"
    	:title="ordentrabajo.name"
		:after="condetail?statusString():null"
    	:header="('Admitido el '+(admissionDate?$moment(admissionDate).format('llll'):' xxxxx de xxx a las xx:xx')) + ' @' + institux"
  	>
   		<div slot="media"> 
			<img :src="!patinfo?loadingAvatar:(patinfo.sexo=='Masculino'?'../static/img/masc.jpg':'../static/img/feme.jpg')"/>
			<br/>
			{{patinfo?suage(patinfo.fechaNacimiento):'XX a'}}
		</div>
	 	<div slot="text">
		 	<div v-for="sample in samplinfo" :key="sample.id">
				<template v-if="ordentrabajo.samples[sample.id]">
					<f7-icon material="opacity"></f7-icon> 
					{{sample.name}} (<b>#{{sample.id}}</b>) - 
					<f7-chip 
						class="labeler" 
						:text="ordentrabajo.samples[sample.id] ? sampleLocation(ordentrabajo.samples[sample.id], sample.id):'Ubicacion'"
					>
        				<f7-icon material="location_on" slot="media"></f7-icon>
      				</f7-chip>
				</template>
			</div>
		</div>
		<span v-if="soyhistorico" slot="after" class="limitaltspan"> 
			<f7-link 
				@click="ViewAndPrint(true,{id:ordentrabajo.admissionId},[ordentrabajo.id],patinfo.id)" 
				icon-material="assignment_returned" 
				class="marginderecho"
			></f7-link> 
			<f7-link 
				@click="ViewAndPrint(false,{id:ordentrabajo.admissionId},[ordentrabajo.id],patinfo.id)" 
				icon-material="printer" class="marginderecho"
			></f7-link> 
			<f7-link 
				@click="verDetalle()" 
				icon-material="pageview"
			></f7-link>
		</span>
  	</f7-list-item>
</template> 

<script>
import { mapState } from 'vuex';
import { format} from 'rut.js';
import {paxhelpersmixin} from '../mixins/paxhelpersmixin';
import {pdfermixin} from '../mixins/pdfermixin'

export default {
    name: 'pendIngreso',
	props: ['ordentrabajo','condetail','soyhistorico'],
	mixins:[paxhelpersmixin,pdfermixin],
    data(){
        return {
			patinfo: null,
			samplinfo: null,
			admissionDate:null,
			institux:null
			// observables:{},
			// 	samplesMeta:{},
        }
	},
    computed:{
		  ...mapState(['loadingAvatar','sections']),
	},
    methods:{
			printMeNow(){
				console.log('attemping to print');
			},
			statusString(){
				let status;
				if(this.ordentrabajo.firmado){
					status='Result. Firmado';
				}
				else if(this.ordentrabajo.confirmado){
						status='Result. Confirmado';
				}
				else if(this.ordentrabajo.ingresado){
					status='Result. Ingresado';
				}
				else{
					status='Sin resultado';
				}
				return status;
			},
			   verDetalle(){
			//focused_orden
			let tosend={
				admisionid:this.ordentrabajo.admissionId,
				examen: this.ordentrabajo,
				patinfo: this.patinfo,
				samplinfo: this.samplinfo
			};
			this.$store.commit('setObjTo',{what:'focused_orden',to:tosend});
			this.$f7.views.main.router.navigate('/ordendetrabajo/');
		},
				getAdmissionInfo(){
			return this.$firebase.database().ref('admissions/'+this.ordentrabajo.admissionId).once('value').then((admisnap) => {
				let admission=admisnap.val();
				this.patinfo = admission.patient;
				this.samplinfo =admission.samples;
				this.admissionDate= admission.creationDate;
				this.institux=admission.institution;
				console.log({patinfo:this.patinfo,samplinfo:this.samplinfo,admissionDate:this.admissionDate});

			}).catch((error) => {
				this.$f7.dialog.alert(error.message,error.code);
			});
		},
		sampleLocation(sample, sampleId){
			if(!sample.where){
				this.samplinfo[sampleId].where = 'En Admisión'
				return 'En Admisión'
			}
			const location = this.sections.find((section) => section.id === sample.where);
			this.samplinfo[sampleId].where = 'En ' + location.name;
			return 'En ' + location.name;
		},


	},
	
    mounted(){
			console.log(this.ordentrabajo);
				this.getAdmissionInfo();
					//En paralelo, encender observables a las muestras
			// let samples=this.ordentrabajo.samples || {};
			// let sampleids=Object.keys(samples);
			// sampleids.forEach(unasampleid => {
			// 	this.observables[unasampleid]=this.$firebase.database().ref('admittedSamples/' + unasampleid );
			// 	this.observables[unasampleid].on('value', (snapshot)=> {
			// 		let nuevo={};
			// 		nuevo[unasampleid]=snapshot.val();
			// 		this.samplesMeta=Object.assign({},this.samplesMeta,nuevo);
			// 		console.log('samplesMeta',this.samplesMeta);
			// 	});
			// });
	},
    beforeDestroy(){
      //apagar los observables, if any
      // let observables=Object.values(this.observables);
      // observables.forEach(observable => {
      //   observable.off();//apagarlo
      // });
    }
}
</script>
<style>

.labeler.chip{
	font-size: 12px!important;
    height: 24px!important;
		background: #fff;
    border: 1px solid #37b5aa;
}
.labeler.chip .chip-label{
	margin-left:0px!important;
}
.labeler .icon{
font-size: 21px!important;
    height: 22px!important;
    color: #37b5aa;
}
.noaltoxs .item-text{
	height: unset!important;
	max-height: unset!important;
}
.marginderecho{
	margin-right: 6px;
}
.limitaltspan{
	height: 24px;
}
</style>