<template>
    <div v-if="!previewMode">
        <block-toolbar :block="computedBlockInfo('text-block')"></block-toolbar>

        <div class="lock-text-editor" v-if="disableInputs"></div>
        <f7-text-editor
            style="cursor: text"
            class="no-margin text-block-editor"
            resizable
            mode="popover"
            :buttons="buttons"
            @texteditor:change="handleTextEditorInputChanged"
            @texteditor:focus="handleFocusChanged(true)"
            @texteditor:blur="handleFocusChanged(false)"
            :value="defaults.text || ''"
            placeholder="Ingrese texto aquí"
        ></f7-text-editor>
    </div>
    <div v-else>
        <div v-html="defaults.text || ''"></div>
    </div>
</template>

<script>
import BlockToolbarMixin from "@/app/components-migration/plantillas/blocks/toolbar/block-toolbar-mixin";
import BlockToolbar from "@/app/components-migration/plantillas/blocks/toolbar/block-toolbar.vue";

export default {
    mixins: [BlockToolbarMixin],

    props: {
        previewMode: Boolean,
        defaults: Object,
        disableInputs: Boolean,
    },

    components: {
        "block-toolbar": BlockToolbar,
    },

    data() {
        return {
            text: "",

            buttons: [
                ["bold", "italic", "underline"],
                ["orderedList", "unorderedList"],
                ["paragraph", "h1", "h2", "h3"],
                ["alignLeft", "alignCenter", "alignRight", "alignJustify"],
            ],
        };
    },

    methods: {
        handleTextEditorInputChanged(value) {
            this.text = value;
            this.$emit("text-input-changed", value);
        },

        handleFocusChanged(value) {
            this.$emit("focus-changed", value);
        },
    },
};
</script>

<style>
.text-block-editor > .text-editor-content * {
    margin: 0 !important;
}

.lock-text-editor {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9999;
}
</style>
