module.exports = {
    namespaced: true,

    state: {
        allInstitutions: [],

        viewingInstitution: null,
    },

    mutations: {
        set_all_institutions(state, institutions) {
            state.allInstitutions = institutions;
        },

        set_viewing_institution(state, institution) {
            state.viewingInstitution = institution;
        },

        add_institution(state, institution) {
            state.allInstitutions.push(institution);
        },

        update_institution(state, updatedInstitution) {
            const index = state.allInstitutions.findIndex((i) => i.id === updatedInstitution.id);

            state.allInstitutions.splice(index, 1, updatedInstitution);
        },

        update_cached_institution(state, updatedInstitution) {
            const index = this.state.allInstitutions.findIndex((i) => i.id === updatedInstitution.id);
            this.state.allInstitutions.splice(index, 1, updatedInstitution);
        },
    },

    actions: {
        updateInstitution({ commit }, updatedInstitution) {
            commit("update_institution", updatedInstitution);
            commit("set_viewing_institution", updatedInstitution);
            commit("update_cached_institution", updatedInstitution);
        },
    },
};
