<template>
    <f7-page name="plantillas-create" class="bg-color-gray" @page:beforeout="handlePageBeforeOut">
        <f7-navbar>
            <f7-nav-left>
                <f7-link @click="backConfirm" icon-material="arrow_back"></f7-link>
            </f7-nav-left>
            <f7-nav-title>{{ isEditing ? "Editando plantilla" : "Crear plantilla" }}</f7-nav-title>
            <f7-nav-right>
                <main-plantilla-control-buttons
                    is-editor
                    :plantilla="plantilla"
                    class="display-flex"
                    @button-clicked="handleMainPlantillaControlButtonClicked"
                ></main-plantilla-control-buttons>
            </f7-nav-right>
        </f7-navbar>

        <f7-block class="margin-top-half display-flex justify-content-center">
            <main-plantilla
                is-editor
                :plantilla="plantilla"
                :admission-exam="admissionExam"
                @block-dragged="handleMainPlantillaBlockDragged"
                @block-resized="handleMainPlantillaBlockResized"
                @block-deleted="(payload) => handleMainPlantillaBlockDeleted(payload, true /** isEditor */)"
                @block-data-changed="handleBlockDataChangedInEditor"
            ></main-plantilla>
        </f7-block>

        <main-plantilla-side-panel
            is-editor
            :opened="sidePanelOpened"
            :blocks="blocks"
            :plantilla="plantilla"
            :admission-exam="admissionExam"
            @plantilla-duplicated="handleMainPlantillaDuplicatedInEditor"
            @save-plantilla="handleMainPlantillaSaveInEditor"
            @info-changed="handleMainPlantillaInfoChanged"
            @block-added="handleMainPlantillaBlockAdded"
            @page-deleted="(payload) => handleMainPlantillaPageDeleted(payload, true /** isEditor */)"
            @side-panel-closed="sidePanelOpened = false"
            @associated-exam-changed="handleMainPlantillaAssociatedExamChanged"
        ></main-plantilla-side-panel>

        <main-plantilla-pages-controls
            :plantilla="plantilla"
            @zoom-changed="handleMainPlantillaZoomChanged"
            @page-added="handleMainPlantillaPageAdded"
        ></main-plantilla-pages-controls>
    </f7-page>
</template>

<script>
import { mapState } from "vuex";

import MainPlantillaMixin from "@/app/components-migration/plantillas/main-plantilla/main-plantilla-mixin";

import MainPlantilla from "@/app/components-migration/plantillas/main-plantilla/main-plantilla.vue";
import MainPlantillaControlButtons from "@/app/components-migration/plantillas/main-plantilla/control-buttons.vue";
import MainPlantillaPagesControls from "@/app/components-migration/plantillas/main-plantilla/pages-controls.vue";
import MainPlantillaSidePanel from "@/app/components-migration/plantillas/main-plantilla/side-panel.vue";

export default {
    mixins: [MainPlantillaMixin],

    components: {
        "main-plantilla": MainPlantilla,
        "main-plantilla-control-buttons": MainPlantillaControlButtons,
        "main-plantilla-pages-controls": MainPlantillaPagesControls,
        "main-plantilla-side-panel": MainPlantillaSidePanel,
    },

    data() {
        return {
            // dummy data
            admissionExam: {
                admission: {
                    institution: {
                        name: "Inmunocel",
                    },
                    patient: {
                        name: "Juanito",
                        lastname_f: "Perez",
                        lastname_m: "Soto",
                        document_type: 1,
                        document_number: "11.111.111-1",
                        document_type_info: {
                            id: 1,
                            name: "RUT",
                        },
                        birth_date: "02/05/1991",
                        phone: "+56972782918",
                    },
                },
                exam: {
                    associations: {},
                },
                exam_section: {
                    id: 1,
                    name: "Biologia molecular",
                },
                samples: [
                    {
                        id: 1,
                        meta: { id: 9, name: "Suero" },
                        quantity: 1,
                        sample_code: "9000005420",
                    },
                ],
            },

            isEditing: false,

            plantillaIsUsedRef: null,
        };
    },

    computed: {
        ...mapState("plantillas", ["viewingPlantilla"]),

        plantillaBlocks() {
            return Object.values(this.plantilla.blocks || {});
        },

        groupedBlocks() {
            if (this.plantillaBlocks.length === 0) return {};

            let groups = {};

            this.plantillaBlocks.forEach((addedBlock) => {
                let pageIndex = this.plantilla.pages.findIndex(
                    (p) => addedBlock.position.y >= p.position.top && addedBlock.position.y <= p.position.bottom
                );

                if (pageIndex === -1) {
                    this.addPdfPage();

                    pageIndex = this.plantilla.pages.length - 1;
                }

                if (!groups[pageIndex]) groups[pageIndex] = {};

                groups[pageIndex][addedBlock.id] = addedBlock;
            });

            return groups;
        },
    },

    created() {
        if (this.viewingPlantilla) {
            this.plantilla = JSON.parse(JSON.stringify(this.viewingPlantilla));

            this.$store.commit("plantillas/set_viewing_plantilla", null);

            this.isEditing = true;

            this.plantillaIsUsedRef = this.$firebase.database().ref(`results_plantillas/${this.plantilla.id}/isUsed`);

            this.plantillaIsUsedRef.on("value", (snapshot) => {
                this.$set(this.plantilla, "isUsed", snapshot.val() || false);
            });
        } else {
            this.setDefaultBlocks();
        }
    },

    methods: {
        setDefaultBlocks() {
            const logoBlock = this.blocks.find((b) => b.code === "logo-block");
            const footerBlock = this.blocks.find((b) => b.code === "footer-block");
            const patientInfoBlock = this.blocks.find((b) => b.code === "patient-info-block");
            const examInfoBlock = this.blocks.find((b) => b.code === "exam-info-block");
            const directorTecnicoSignatureBlock = this.blocks.find((b) => b.code === "director-tecnico-signature-block");
            const CMIOSignaturesBlock = this.blocks.find((b) => b.code === "cmio-signatures-block");

            const logoBlockId = this.$firebase
                .database()
                .ref()
                .push().key;
            const footerBlockId = this.$firebase
                .database()
                .ref()
                .push().key;
            const patientInfoBlockId = this.$firebase
                .database()
                .ref()
                .push().key;
            const examInfoBlockId = this.$firebase
                .database()
                .ref()
                .push().key;
            const directorTecnicoSignatureBlockId = this.$firebase
                .database()
                .ref()
                .push().key;
            const CMIOSignaturesBlockId = this.$firebase
                .database()
                .ref()
                .push().key;

            const defaultBlocks = {
                [logoBlockId]: {
                    id: logoBlockId,
                    block: { code: logoBlock.code },
                    position: { x: 20, y: 20 },
                    size: { width: logoBlock.size.width, height: logoBlock.size.height },
                },
                [footerBlockId]: {
                    id: footerBlockId,
                    block: { code: footerBlock.code },
                    position: { x: 200, y: 1060 },
                    size: { width: footerBlock.size.width, height: footerBlock.size.height },
                },
                [patientInfoBlockId]: {
                    id: patientInfoBlockId,
                    block: { code: patientInfoBlock.code },
                    position: { x: 120, y: 180 },
                    size: { width: patientInfoBlock.size.width, height: patientInfoBlock.size.height },
                },
                [examInfoBlockId]: {
                    id: examInfoBlockId,
                    block: { code: examInfoBlock.code },
                    position: { x: 440, y: 180 },
                    size: { width: examInfoBlock.size.width, height: examInfoBlock.size.height },
                },
                [directorTecnicoSignatureBlockId]: {
                    id: directorTecnicoSignatureBlockId,
                    block: { code: directorTecnicoSignatureBlock.code },
                    position: { x: 120, y: 880 },
                    size: { width: directorTecnicoSignatureBlock.size.width, height: directorTecnicoSignatureBlock.size.height },
                },
                [CMIOSignaturesBlockId]: {
                    id: CMIOSignaturesBlockId,
                    block: { code: CMIOSignaturesBlock.code },
                    position: { x: 460, y: 880 },
                    size: { width: CMIOSignaturesBlock.size.width, height: CMIOSignaturesBlock.size.height },
                },
            };

            this.$set(this.plantilla, "blocks", defaultBlocks);
        },

        handleDelete(keyCode, addedBlock) {
            if (this.isTextInputFocused && (keyCode !== 46 || keyCode !== 8)) return;

            this.$f7.dialog.confirm("Esta acción será irreversible", "Eliminar bloque", () => {
                const foundBlock = this.plantilla.blocks[addedBlock.id];

                this.$delete(this.plantilla.blocks, foundBlock.id);
            });
        },

        handleExamAssociation(exam) {
            if (!this.plantilla.exams) this.$set(this.plantilla, "exams", {});

            if (!this.plantilla.exams[exam.id]) {
                this.$set(this.plantilla.exams, exam.id, true);
            } else {
                this.$delete(this.plantilla.exams, exam.id);
            }
        },
        handleRemoveAssociatedExam(exam) {
            this.$f7.dialog.confirm(`Se eliminará el exámen <b>${exam.name}</b> asociado a esta plantilla.`, "¿Estás seguro?", () => {
                this.$delete(this.plantilla.exams, exam.id);
            });
        },

        backConfirm() {
            this.$f7.dialog.confirm("¿Está seguro de volver?", () => {
                this.$f7.views.main.router.back();
            });
        },

        handlePageBeforeOut() {
            this.plantillaIsUsedRef = null;
        },
    },
};
</script>
