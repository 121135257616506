<template>
    <f7-page >
        <f7-navbar title="Imagen del insumo">
            <f7-nav-right>
                <f7-link @click="clean()" popup-close>Cerrar</f7-link>
            </f7-nav-right>
        </f7-navbar>
        <form @submit.prevent="submit" >
            <f7-block>
                <f7-row class="mb-2">
                    <f7-col width="100" class="padding-1"  style="text-align: center">
                        <label>Imagen del insumo</label>
                        <f7-list no-hairlines-md> 
                        <f7-list-input accept="image/png, image/gif, image/jpeg" id="fileInput" name="img" type="file" @change="imageDefine($event.target.files[0])"/>
                        </f7-list>
                        <img :src="imageLink" style="max-width:100%,border:1px solid #fff; border-radius: 5px; padding: 2%; max-width : 100%;margin:auto">
                    </f7-col>
                </f7-row>
                <f7-row class="mb-2">
                    <f7-col width="100" >
                        <f7-button fill  type="submit" >Guardar</f7-button>
                    </f7-col>
                </f7-row>
                <hr>
                <f7-row class="mb-2">
                    <f7-col width="100" >
                        <f7-button  outline @click="cleanImageSql()" >Limpiar Imagen</f7-button>
                    </f7-col>
                </f7-row>
            </f7-block>
        </form>
    </f7-page>
</template>

<script>
export default {
    props : {
        imageUrl : { required: true }, 
        idSupply : { required : true }
    },
    computed :{
        imageLink(){
            return (this.imageFile) ? this.imageFile : this.$props.imageUrl;
        }
    },
    data(){
        return {
            file : "",
            imageFile : false
        }
    },
    methods : {
        imageDefine(file){
            if (file) {
                this.file = file;
                this.imageFile = URL.createObjectURL(file);
            }
        },
        async submit(){
            if(this.file !== ""){ 
                let url = await this.$store.dispatch('uploadFile', {app : this, path : 'images/supply/', file : this.file, alert : true}) 
               
                this.$firebase.auth().currentUser.getIdToken().then(async (token) => {
                    var result = await this.$store.dispatch(
                    "apiRequest",
                        { url : "supply/" ,token , method : "PUT", data : {image : url, id : this.idSupply}, request : {afterOk : 'Imagen actualizada'}, app : this}
                    );

                    this.$emit('fileUrl', url)
                    this.$f7.popup.close('.image');
                });
            }
        },
        clean(){
            console.log(document.getElementById('fileInput'))
            var input = document.getElementById('fileInput').children;
            for (let item of input) {
                item.value = "";
            }
            this.image = "false";
        },
        cleanImageSql(){
            this.imageFile = false;
            if(this.$props.imageUrl != ""){
                this.$f7.dialog.confirm('Eliminar imagen de insumo', '¿Desea eliminar la imagen del insumo?', () => {
                    document.getElementById('fileInput').value = "";

                    this.$firebase.auth().currentUser.getIdToken().then(async (token) => {
                        var result = await this.$store.dispatch(
                        "apiRequest",
                            { url : "supply/" ,token , method : "PUT", data : {image : "", id : this.idSupply}, request : {afterOk : 'Imagen actualizada'}, app : this}
                        );

                        this.$emit('fileUrl', '')
                        this.$f7.popup.close('.image');
                    });
                })
            }else{
                this.$store.dispatch('toast',{ app : this, text : "No hay imagen asignada a este insumo" })
            }
            

        }
    }
}
</script>

<style>

</style>