import { mapState, mapActions } from "vuex";
import { examStatuses, resultTypes } from "@/app/constants";

import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

export default {
    data() {
        return {
            doc: null,
        };
    },

    computed: {
        ...mapState("plantillas", ["viewingAdmissionExam", "viewingAdmissionExamOriginal", "selectedReportType"]),

        ...mapState(["env", "profile_userInfo"]),
    },

    methods: {
        ...mapActions(["updateInfo"]),

        updateViewingAdmissionExam() {
            let examResults = this.viewingAdmissionExamOriginal.exam_result.find((er) => er.report_type === this.selectedReportType);

            if (examResults) {
                examResults = [examResults];
            } else {
                examResults = [];
            }

            const updatedAdmissionExam = Object.assign({}, this.viewingAdmissionExamOriginal, { exam_result: examResults });

            this.$store.commit("plantillas/update_viewing_admission_exam", updatedAdmissionExam);
        },

        async handleExamActionTriggered(payload) {
            const reportType = this.getReportTypeFromBlocks(this.plantilla.blocks);

            if (!reportType) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Debe seleccionar tipo de reporte");
                return;
            }

            if (this.selectedReportType !== reportType) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(`Debe ingresar resultado para el reporte ${this.selectedReportType}`);
                return;
            }

            const { action, revert } = payload;

            switch (action) {
                case "resultado-cargado":
                    // aca revert no es el mejor nombre, en realidad estamos modificando el resultado
                    this.handleResultadoCargado(revert);
                    return;

                case "confirmado":
                    this.handleConfirmado(revert);
                    return;

                case "firmado":
                    this.handleFirmado(revert);
                    return;

                case "imprimir":
                    this.handleImprimir(revert);
                    return;

                case "print-pdf":
                    this.generateExamResultPDF();
                    return;
            }
        },

        handleResultadoCargado(revert) {
            const isCollection = this.viewingAdmissionExam.exam.associations.length > 0;

            let dataToSend = {
                exam_result_type: isCollection ? "collection" : "structured",
                method_id: 0,
                report_type: null,
                observations: null,
                admission_exam_id: this.viewingAdmissionExam.id,
                results: [],

                report_type: this.selectedReportType,
            };

            dataToSend.method_id = this.getProcessingMethodIdFromBlocks(this.plantilla.blocks);
            dataToSend.report_type = this.getReportTypeFromBlocks(this.plantilla.blocks);
            dataToSend.observations = this.getObservationsFromBlocks(this.plantilla.blocks);

            const resultsBlocks = Object.values(this.plantilla.blocks || {}).filter(
                (b) =>
                    b.block.code === "resultado-cualitativo-block" ||
                    b.block.code === "resultado-cuantitativo-block" ||
                    b.block.code === "resultado-free-text-block" ||
                    b.block.code === "resultado-collection-block"
            );

            const hasResultadoCollectionBlocks = resultsBlocks.filter((b) => b.block.code === "resultado-collection-block").length > 0;

            const { isValid, problems } = this.validateResultBlocks(resultsBlocks, isCollection, hasResultadoCollectionBlocks);

            if (isValid === false) {
                this.$f7.dialog.alert(`Errores detectados:<br>${this.errorList(problems)}`, "No se pudo cargar el resultado.");
                return;
            }

            const results = this.getResultsFromBlocks(resultsBlocks, hasResultadoCollectionBlocks);

            dataToSend.results = results;

            const confirmText = revert
                ? "Se guardarán los resultados en los bloques de resultados y modificará el resultado del exámen"
                : "Se guardarán los resultados en los bloques de resultados y cambiará el estado del examen a Resultado Cargado.";

            this.$f7.dialog.confirm(confirmText, "¿Estás seguro?", async () => {
                this.$f7.dialog.preloader("Cambiando el estado del examen...");

                await this.createPlantillaCopyIfModified();

                this.$firebase
                    .auth()
                    .currentUser.getIdToken()
                    .then((idToken) => {
                        return this.updateInfo({
                            url: `admission_exams/status/3`, // 3: resultado cargado
                            userToken: idToken,
                            data: {
                                exams: [dataToSend],
                                data: {
                                    used_plantilla_id: this.plantilla.id,
                                },
                            },
                        });
                    })
                    .then(async (response) => {
                        console.log("response", response);

                        const updatedAdmissionExam = response.data.updatedAdmissionExams[0];

                        this.$store.commit("plantillas/update_viewing_admission_exam_original", updatedAdmissionExam);

                        this.updateViewingAdmissionExam();

                        this.clearSignatureDefaults();

                        const dataToSendFirebase = this.getDataToSaveInFirebase();

                        console.log("data to save in firebase", dataToSendFirebase);

                        await this.$firebase
                            .database()
                            .ref(
                                `/data_results_plantillas/${this.viewingAdmissionExam.id}/${this.plantilla.id}/${this.selectedReportType ||
                                    "default"}`
                            )
                            .set(dataToSendFirebase);

                        if (!this.plantilla.isUsed) {
                            await this.$firebase
                                .database()
                                .ref(`results_plantillas/${this.plantilla.id}/isUsed`)
                                .set(true);

                            this.plantilla.isUsed = true;

                            this.$store.commit("plantillas/update_plantilla", this.plantilla);
                        }

                        this.$f7.dialog.close();
                    })
                    .catch((err) => {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(err.message, err.code);
                        console.error(err);
                    });
            });
        },

        handleConfirmado(revert) {
            const confirmText = revert ? "Se revertirá la confirmación de este exámen" : "Se cambiará el estado del exámen a Examen Confirmado";
            const targetStatus = revert ? examStatuses.resultado_cargado : examStatuses.examen_confirmado;

            this.$f7.dialog.confirm(confirmText, "¿Estás seguro?", async () => {
                this.$f7.dialog.preloader("Cambiando el estado del examen...");

                await this.createPlantillaCopyIfModified();

                this.$firebase
                    .auth()
                    .currentUser.getIdToken()
                    .then((idToken) => {
                        return this.updateInfo({
                            url: `admission_exams/status/${targetStatus}`,
                            userToken: idToken,
                            data: {
                                exams: [
                                    {
                                        admission_exam_id: this.viewingAdmissionExam.id,
                                        report_type: this.selectedReportType,
                                        revert,
                                    },
                                ],
                                data: {
                                    used_plantilla_id: this.plantilla.id,
                                },
                            },
                        });
                    })
                    .then(async (response) => {
                        console.log("response", response);

                        const updatedAdmissionExam = response.data.updatedAdmissionExams[0];

                        this.$store.commit("plantillas/update_viewing_admission_exam_original", updatedAdmissionExam);

                        this.updateViewingAdmissionExam();

                        this.clearSignatureDefaults();

                        if (!this.plantilla.isUsed) {
                            await this.$firebase
                                .database()
                                .ref(`results_plantillas/${this.plantilla.id}/isUsed`)
                                .set(true);

                            this.plantilla.isUsed = true;

                            this.$store.commit("plantillas/update_plantilla", this.plantilla);
                        }

                        this.$f7.dialog.close();
                    })
                    .catch((err) => {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(err.message, err.code);
                        console.error(err);
                    });
            });
        },

        handleFirmado(revert) {
            const confirmText = revert ? "Se revertirá la firma de este exámen" : "Se cambiará el estado del exámen a Examen Firmado";
            const targetStatus = revert ? examStatuses.examen_confirmado : examStatuses.examen_firmado;

            this.$f7.dialog.confirm(confirmText, "¿Estás seguro?", async () => {
                this.$f7.dialog.preloader("Cambiando el estado del examen...");

                await this.createPlantillaCopyIfModified();

                this.$firebase
                    .auth()
                    .currentUser.getIdToken()
                    .then((idToken) => {
                        return this.updateInfo({
                            url: `admission_exams/status/${targetStatus}`,
                            userToken: idToken,
                            data: {
                                exams: [
                                    {
                                        admission_exam_id: this.viewingAdmissionExam.id,
                                        report_type: this.selectedReportType,
                                        revert,
                                    },
                                ],
                                data: {
                                    used_plantilla_id: this.plantilla.id,
                                },
                            },
                        });
                    })
                    .then(async (response) => {
                        const updatedAdmissionExam = response.data.updatedAdmissionExams[0];

                        this.$store.commit("plantillas/update_viewing_admission_exam_original", updatedAdmissionExam);

                        this.updateViewingAdmissionExam();

                        if (!revert) {
                            let signedByUidByReportType;

                            if (this.selectedReportType === "Informe preliminar") {
                                signedByUidByReportType = this.viewingAdmissionExam.signed_by_preliminar;
                            } else if (this.selectedReportType === "Informe final") {
                                signedByUidByReportType = this.viewingAdmissionExam.signed_by_final;
                            } else if (this.selectedReportType === "Informe complementario") {
                                signedByUidByReportType = this.viewingAdmissionExam.signed_by_compl;
                            }

                            const signatureBlock = Object.values(this.plantilla.blocks || {}).find((b) => b.block.code === "signature-block");
                            const responsableInfoBlock = Object.values(this.plantilla.blocks || {}).find(
                                (b) => b.block.code === "responsable-info-block"
                            );

                            if (signatureBlock || responsableInfoBlock) {
                                if (signatureBlock) {
                                    if (!this.plantilla.blocks[signatureBlock.id].defaults) {
                                        this.$set(this.plantilla.blocks[signatureBlock.id], "defaults", {});
                                    }
                                }

                                if (responsableInfoBlock) {
                                    if (!this.plantilla.blocks[responsableInfoBlock.id].defaults) {
                                        this.$set(this.plantilla.blocks[responsableInfoBlock.id], "defaults", {});
                                    }
                                }

                                this.$firebase
                                    .auth()
                                    .currentUser.getIdToken()
                                    .then((idToken) => {
                                        return this.getAPIInfo({ url: `users/${signedByUidByReportType}/signature`, userToken: idToken });
                                    })
                                    .then((resp) => {
                                        const signatureObject = resp.data.signature;

                                        let env = this.env === "production" ? "prod" : "dev";

                                        if (signatureBlock) {
                                            this.$set(
                                                this.plantilla.blocks[signatureBlock.id].defaults,
                                                "signatureName",
                                                signatureObject ? signatureObject.user_name : null
                                            );
                                            this.$set(
                                                this.plantilla.blocks[signatureBlock.id].defaults,
                                                "signatureUrl",
                                                signatureObject ? signatureObject[`signature_url_${env}`] : null
                                            );
                                        }

                                        if (responsableInfoBlock) {
                                            this.$set(
                                                this.plantilla.blocks[responsableInfoBlock.id].defaults,
                                                "responsableName",
                                                signatureObject ? signatureObject.user_name : null
                                            );
                                            this.$set(
                                                this.plantilla.blocks[responsableInfoBlock.id].defaults,
                                                "responsablePrefix",
                                                signatureObject ? signatureObject.user_prefix : null
                                            );
                                            this.$set(
                                                this.plantilla.blocks[responsableInfoBlock.id].defaults,
                                                "responsableSignture",
                                                signatureObject ? signatureObject[`signature_url_${env}`] : null
                                            );
                                        }
                                    })
                                    .catch((error) => {
                                        console.error(error);
                                        this.$f7.dialog.close();
                                        this.$f7.dialog.alert("Hubo un error al recuperar la firma del usuario. Por favor refresque la plantilla");
                                    });
                            }
                        } else {
                            this.clearSignatureDefaults();
                        }

                        if (!this.plantilla.isUsed) {
                            await this.$firebase
                                .database()
                                .ref(`results_plantillas/${this.plantilla.id}/isUsed`)
                                .set(true);

                            this.plantilla.isUsed = true;

                            this.$store.commit("plantillas/update_plantilla", this.plantilla);
                        }

                        this.$f7.dialog.close();
                    })
                    .catch((err) => {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(err.message, err.code);
                        console.error(err);
                    });
            });
        },

        handleImprimir(revert) {
            const confirmText = revert ? "Se revertirá el estado impreso de este exámen" : "Se cambiará el estado del exámen a Examen Impreso";
            const targetStatus = revert ? examStatuses.examen_firmado : examStatuses.examen_impreso;

            this.$f7.dialog.confirm(confirmText, "¿Estás seguro?", async () => {
                this.$f7.dialog.preloader("Cambiando el estado del examen...");

                await this.createPlantillaCopyIfModified();

                this.$firebase
                    .auth()
                    .currentUser.getIdToken()
                    .then((idToken) => {
                        return this.updateInfo({
                            url: `admission_exams/status/${targetStatus}`,
                            userToken: idToken,
                            data: {
                                exams: [
                                    {
                                        admission_exam_id: this.viewingAdmissionExam.id,
                                        report_type: this.selectedReportType,
                                        revert,
                                    },
                                ],
                                data: {
                                    used_plantilla_id: this.plantilla.id,
                                },
                            },
                        });
                    })
                    .then(async (response) => {
                        console.log("response", response);

                        const updatedAdmissionExam = response.data.updatedAdmissionExams[0];

                        this.$store.commit("plantillas/update_viewing_admission_exam_original", updatedAdmissionExam);

                        this.updateViewingAdmissionExam();

                        this.$f7.dialog.close();

                        if (!revert) {
                            try {
                                await this.generateExamResultPDF();

                                this.plantillaIsLocked = true;
                            } catch (error) {
                                console.error(error);

                                this.$f7.dialog.close();
                                this.$f7.dialog.alert("Hubo un error al generar el PDF");
                            }
                        } else {
                            this.plantillaIsLocked = false;
                        }

                        if (!this.plantilla.isUsed) {
                            await this.$firebase
                                .database()
                                .ref(`results_plantillas/${this.plantilla.id}/isUsed`)
                                .set(true);

                            this.plantilla.isUsed = true;

                            this.$store.commit("plantillas/update_plantilla", this.plantilla);
                        }
                    })
                    .catch((err) => {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(err.message, err.code);
                        console.error(err);
                    });
            });
        },

        getProcessingMethodIdFromBlocks(blocks) {
            const methodBlocks = Object.values(blocks).filter((b) => b.block.code === "processing-method-block");

            console.log("methodBlocks", methodBlocks);

            if (methodBlocks.length > 1) {
                // Arrojar error, solo puede haber 1 bloque de método
                console.log("Arrojar error, solo puede haber 1 bloque de método");
            } else {
                const [methodBlock] = methodBlocks;

                if (!methodBlock) return 0;

                return methodBlock.defaults.processingMethodId || 0;
            }
        },
        getReportTypeFromBlocks(blocks) {
            const reportTypeBlocks = Object.values(blocks).filter((b) => b.block.code === "report-type-block");

            if (reportTypeBlocks.length > 1) {
                // Arrojar error, solo puede haber 1 bloque de tipo de informe
                console.log("Arrojar error, solo puede haber 1 bloque de tipo de informe");
            } else {
                const [reportTypeBlock] = reportTypeBlocks;

                if (!reportTypeBlock) return null;

                return reportTypeBlock.defaults.reportType || null;
            }
        },
        getObservationsFromBlocks(blocks) {
            const observationsBlocks = Object.values(blocks).filter((b) => b.block.code === "observations-block");

            if (observationsBlocks.length > 1) {
                // Arrojar error, solo puede haber 1 bloque de observaciones
                console.log("Arrojar error, solo puede haber 1 bloque de observaciones");
            } else {
                const [observationsBlock] = observationsBlocks;

                if (!observationsBlock) return null;

                return observationsBlock.defaults.text || null;
            }
        },
        getResultsFromBlocks(resultsBlocks, hasResultadoCollectionBlock) {
            const results = [];

            if (!hasResultadoCollectionBlock) {
                resultsBlocks.forEach((block) => {
                    let result = {};

                    const resultIndex = results.findIndex((r) => r.exam_id === block.defaults.examId);

                    if (resultIndex === -1) {
                        result.exam_id = block.defaults.examId;
                    }

                    if (block.block.code === "resultado-cualitativo-block") {
                        result.cualitativo_result = block.defaults.result;
                    }

                    if (block.block.code === "resultado-cuantitativo-block") {
                        result.cuantitativo_result = block.defaults.result;
                        result.cuantitativo_unit = block.defaults.unit;
                    }

                    if (block.block.code === "resultado-free-text-block") {
                        result.other_result = block.defaults.result;
                    }

                    if (this.viewingAdmissionExam.exam_result && this.viewingAdmissionExam.exam_result.length > 0) {
                        const details = this.viewingAdmissionExam.exam_result[0].details;

                        const detail = details.find((d) => d.exam_id === block.defaults.examId);

                        if (detail) {
                            result.detail_id = detail.id;
                        }
                    }

                    if (resultIndex === -1) {
                        results.push(result);
                    } else {
                        results[resultIndex] = { ...results[resultIndex], ...result };
                    }
                });
            } else {
                let collectionResults = {};

                const resultadoCollectionBlocks = resultsBlocks.filter((b) => b.block.code === "resultado-collection-block");

                const associatedExams = this.viewingAdmissionExam.exam.associations.map((e) => {
                    return {
                        ...e.associated_exam,
                        result_types: e.result_types,
                    };
                });

                const hasResultado = (resultTypeId, exam) => {
                    return exam.result_types ? exam.result_types.findIndex((rt) => rt.result_type === resultTypeId) !== -1 : false;
                };

                resultadoCollectionBlocks.forEach((collectionBlock) => {
                    const examsIds = Object.keys(collectionBlock.defaults?.results || {}).map((id) => Number(id));

                    const filteredAssociatedExams = associatedExams.filter((e) => examsIds.includes(e.id));

                    const blockResults = {};

                    filteredAssociatedExams.forEach((associatedExam) => {
                        const examId = associatedExam.id;

                        if (!blockResults[examId]) blockResults[examId] = {};

                        if (hasResultado(resultTypes.cualitativo, associatedExam)) {
                            const result = collectionBlock.defaults.results[examId]?.cualitativo.result || "";

                            blockResults[examId].cualitativo = { result };
                        }
                        if (hasResultado(resultTypes.cuantitativo, associatedExam)) {
                            const result = collectionBlock.defaults.results[examId]?.cuantitativo.result || "";
                            const unit = collectionBlock.defaults.results[examId]?.cuantitativo.unit || "";

                            blockResults[examId].cuantitativo = { result, unit };
                        }
                        if (hasResultado(resultTypes.free_text, associatedExam)) {
                            const result = collectionBlock.defaults.results[examId]?.freeText.result || "";

                            blockResults[examId].freeText = { result };
                        }
                    });

                    collectionResults = {
                        ...collectionResults,
                        ...blockResults,
                    };
                });

                Object.keys(collectionResults).forEach((examId) => {
                    const result = {};

                    result.exam_id = examId;

                    const resultadoCualitativo = collectionResults[examId]?.cualitativo?.result;

                    if (resultadoCualitativo) result.cualitativo_result = resultadoCualitativo;

                    const resultadoCuantitativo = collectionResults[examId]?.cuantitativo?.result;
                    const resultadoCuantitativoUnit = collectionResults[examId]?.cuantitativo?.unit;

                    if (resultadoCuantitativo) result.cuantitativo_result = resultadoCuantitativo;
                    if (resultadoCuantitativoUnit) result.cuantitativo_unit = resultadoCuantitativoUnit;

                    const resultadoFreeText = collectionResults[examId]?.freeText?.result;

                    if (resultadoFreeText) result.free_text_result = resultadoFreeText;

                    if (this.viewingAdmissionExam.exam_result && this.viewingAdmissionExam.exam_result.length > 0) {
                        const details = this.viewingAdmissionExam.exam_result[0].details;

                        const detail = details.find((d) => d.exam_id === Number(examId));

                        if (detail) {
                            result.detail_id = detail.id;
                        }
                    }

                    results.push(result);
                });
            }

            return results;
        },

        /**
         *
         * @param {Array} resultsBlocks
         * @param {Boolean} hasResultadoCollectionBlock
         * @returns {Object}
         */
        validateResultBlocks(resultsBlocks, isCollection, hasResultadoCollectionBlocks) {
            let isValid = true;
            const problems = [];

            if (resultsBlocks.length === 0 && this.selectedReportType === "Informe complementario") {
                return { isValid, problems };
            }

            if (!resultsBlocks.length)
                return {
                    isValid: false,
                    problems: ["Debe agregar los bloques de resultados correspondientes al examen para cargar el resultado"],
                };

            const resultsBlocksWithoutCollection = resultsBlocks.filter((b) => b.block.code !== "resultado-collection-block");

            if (hasResultadoCollectionBlocks && resultsBlocksWithoutCollection.length > 1) {
                return {
                    isValid: false,
                    problems: ["No se puede utilizar el bloque Resultado Colección junto con otros bloques de resultados"],
                };
            }

            const associatedExams = this.viewingAdmissionExam.exam.associations.map((e) => {
                return {
                    ...e.associated_exam,
                    result_types: e.result_types,
                };
            });

            if (!isCollection) {
                // Verificar que los resultados cargados coincidan con
                // los result types del examen.
                const examResultTypes = this.viewingAdmissionExam.exam.result_types.map((rt) => rt.result_type);

                const hasResultadoCualitativo = examResultTypes.findIndex((rt) => rt === 1) !== -1;
                const hasResultadoCuantitativo = examResultTypes.findIndex((rt) => rt === 2) !== -1;
                const hasResultadoFreeText = !hasResultadoCualitativo && !hasResultadoCuantitativo;

                resultsBlocksWithoutCollection.forEach((block) => {
                    if (block.defaults.examId !== this.viewingAdmissionExam.exam.id) {
                        isValid = false;
                        problems.push("Se está cargando resultado de un examen distinto al que debería.");
                    }

                    if (block.block.code === "resultado-cualitativo-block") {
                        if (!hasResultadoCualitativo) {
                            isValid = false;
                            problems.push("Se está cargando un resultado cualitativo a un examen que no está asociado a resultados cualitativos.");
                        } else if (!block.defaults.result) {
                            isValid = false;
                            problems.push("No se ingreso el resultado cualitativo");
                        }
                    }

                    if (block.block.code === "resultado-cuantitativo-block") {
                        if (!hasResultadoCuantitativo) {
                            isValid = false;
                            problems.push("Se está cargando un resultado cuantitativo a un examen que no está asociado a resultados cuantitativos.");
                        } else if (!block.defaults.result || block.defaults.unit === "") {
                            isValid = false;
                            problems.push("No se ingreso el resultado cuantitativo");
                        }
                    }

                    if (block.block.code === "resultado-free-text-block") {
                        if (!hasResultadoFreeText) {
                            isValid = false;
                            problems.push(
                                "Se está cargando un resultado de texto libre a un examen que no está asociado a resultados de texto libre."
                            );
                        } else if (!block.defaults.result) {
                            isValid = false;
                            problems.push("No se ingreso el resultado de texto libre");
                        }
                    }
                });
            } else {
                if (!hasResultadoCollectionBlocks) {
                    // Verificar que se estén informando todos los exámenes
                    // de la colección.
                    const associations = this.viewingAdmissionExam.exam.associations.map((a) => ({
                        examId: a.child_exam_id,
                        resultTypes: a.result_types,
                        exam_name: a.associated_exam ? a.associated_exam.name : "",
                    }));

                    resultsBlocks.forEach((block) => {
                        const examAssociationIndex = associations.findIndex((a) => a.examId === block.defaults.examId);
                        const associatedExam = associations[examAssociationIndex];

                        if (examAssociationIndex === -1) {
                            isValid = false;
                            problems.push("Se está cargando resultado a un examen que no pertenece al examen colección.");
                        } else {
                            const examResultTypes = this.viewingAdmissionExam.exam.associations[examAssociationIndex].result_types.map(
                                (rt) => rt.result_type
                            );
                            const hasResultadoCualitativo = examResultTypes.findIndex((rt) => rt === 1) !== -1;
                            const hasResultadoCuantitativo = examResultTypes.findIndex((rt) => rt === 2) !== -1;
                            const hasResultadoFreeText = !hasResultadoCualitativo && !hasResultadoCuantitativo;

                            if (block.block.code === "resultado-cualitativo-block") {
                                if (!hasResultadoCualitativo) {
                                    isValid = false;
                                    problems.push(
                                        "Se está cargando un resultado cualitativo a un examen de la colección que no está asociado a resultados cualitativos."
                                    );
                                }

                                if (!block.defaults.result) {
                                    isValid = false;
                                    problems.push(`No se ingreso el resultado cualitativo del examen ${associatedExam.exam_name}`);
                                }
                            }

                            if (block.block.code === "resultado-cuantitativo-block") {
                                if (!hasResultadoCuantitativo) {
                                    isValid = false;
                                    problems.push(
                                        "Se está cargando un resultado cuantitativo a un examen de la colección que no está asociado a resultados cuantitativos."
                                    );
                                }

                                if (!block.defaults.result || block.defaults.unit === "") {
                                    isValid = false;
                                    problems.push(`No se ingreso el resultado cuantitativo del examen ${associatedExam.exam_name}`);
                                }
                            }

                            if (block.block.code === "resultado-free-text-block") {
                                if (!hasResultadoFreeText) {
                                    isValid = false;
                                    problems.push(
                                        "Se está cargando un resultado de texto libre a un examen de la colección que no está asociado a resultados de texto libre."
                                    );
                                }

                                if (!block.defaults.result) {
                                    isValid = false;
                                    problems.push(`No se ingreso el resultado de texto libre del examen ${associatedExam.exam_name}`);
                                }
                            }
                        }
                    });
                } else {
                    const hasResultado = (resultTypeId, exam) => {
                        return exam.result_types ? exam.result_types.findIndex((rt) => rt.result_type === resultTypeId) !== -1 : false;
                    };

                    const resultadoCollectionBlocks = Object.values(resultsBlocks).filter((b) => b.block.code === "resultado-collection-block");

                    let results = {};

                    resultadoCollectionBlocks.forEach((collectionBlock) => {
                        const examsIds = Object.keys(collectionBlock.defaults?.results || {}).map((id) => Number(id));

                        const filteredAssociatedExams = associatedExams.filter((e) => examsIds.includes(e.id));

                        const blockResults = {};

                        filteredAssociatedExams.forEach((associatedExam) => {
                            const examId = associatedExam.id;

                            if (!blockResults[examId]) blockResults[examId] = {};

                            if (hasResultado(resultTypes.cualitativo, associatedExam)) {
                                const result = collectionBlock.defaults.results[examId]?.cualitativo.result || "";

                                blockResults[examId].cualitativo = { result };
                            }
                            if (hasResultado(resultTypes.cuantitativo, associatedExam)) {
                                const result = collectionBlock.defaults.results[examId]?.cuantitativo.result || "";
                                const unit = collectionBlock.defaults.results[examId]?.cuantitativo.unit || "";

                                blockResults[examId].cuantitativo = { result, unit };
                            }
                            if (hasResultado(resultTypes.free_text, associatedExam)) {
                                const result = collectionBlock.defaults.results[examId]?.freeText.result || "";

                                blockResults[examId].freeText = { result };
                            }
                        });

                        results = {
                            ...results,
                            ...blockResults,
                        };
                    });

                    associatedExams.forEach((exam) => {
                        const examId = exam.id;

                        if (hasResultado(resultTypes.cualitativo, exam)) {
                            const result = results[examId]?.cualitativo?.result;

                            if (!result) {
                                isValid = false;
                                problems.push(`Falta resultado cualitativo para el exámen ${exam.name}`);
                            }
                        }

                        if (hasResultado(resultTypes.cuantitativo, exam)) {
                            const result = results[examId]?.cuantitativo?.result;
                            const unit = results[examId]?.cuantitativo?.unit;

                            if (!result) {
                                isValid = false;
                                problems.push(`Falta resultado cuantitativo para el exámen ${exam.name}`);
                            }
                            if (!unit) {
                                isValid = false;
                                problems.push(`Falta unidad de resultado cuantitativo para el exámen ${exam.name}`);
                            }
                        }

                        if (hasResultado(resultTypes.free_text, exam)) {
                            const result = results[examId]?.freeText?.result;

                            if (!result) {
                                isValid = false;
                                problems.push(`Falta resultado texto libre para el exámen ${exam.name}`);
                            }
                        }
                    });
                }
            }

            const problemsWithNoDuplicates = [...new Set(problems)];

            return { isValid, problems: problemsWithNoDuplicates };
        },
        errorList(problems) {
            let list = "<ul>";

            problems.forEach((problem) => {
                list += `<li>${problem}</li>`;
            });

            list += "</ul>";

            return list;
        },

        async generateExamResultPDF() {
            this.$f7.dialog.preloader("Generando PDF...");

            if (!this.plantilla.options.previewMode) this.plantilla.options.previewMode = true;
            if (this.plantilla.options.currentScale !== 1) this.plantilla.options.currentScale = 1;

            const pages = window.document.getElementsByClassName("pdf-page");

            this.doc = new jsPDF("portrait", "mm", "a4");

            const width = this.doc.internal.pageSize.getWidth();
            const height = this.doc.internal.pageSize.getHeight();

            const images = [];

            for await (const page of pages) {
                page.classList.remove("margin-top");

                const canvas = await html2canvas(page, {
                    allowTaint: false,
                    useCORS: true,
                });

                const dataUrl = canvas.toDataURL();

                const image = new Image();
                image.src = dataUrl;

                images.push(image);

                page.classList.add("margin-top");
            }

            images.forEach((pageImage, index) => {
                this.doc.addImage(pageImage, "jpeg", 0, 0, width, height);

                if (images.length > index + 1) {
                    this.doc.addPage();
                }
            });

            const { patient } = this.viewingAdmissionExam.admission;

            let filename = "";

            if (patient) {
                if (patient.lastname_f) filename += `${patient.lastname_f}_`;
                if (patient.lastname_m) filename += `${patient.lastname_m}_`;
                if (patient.name) filename += `${patient.name}`;
            }

            if (!filename) filename = "examen";

            filename += ".pdf";

            this.doc.save(filename);

            this.$f7.dialog.close();
        },

        getDataToSaveInFirebase() {
            const data = {};

            const blocksWithData = Object.values(this.plantilla.blocks || {}).filter(
                (b) => b.block.code === "text-block" || b.block.code === "table-block" || b.block.code === "image-block"
            );

            blocksWithData.forEach((block) => {
                data[block.id] = {
                    defaults: block.defaults,
                    position: block.position,
                    size: block.size,
                };
            });

            return data;
        },

        async createPlantillaCopyIfModified() {
            // si la plantilla fue editada, copiarla, guardarla en firebase y actualizar la key de la plantilla
            // para que sea firmado con la nueva plantilla
            if (this.plantillaWasChanged) {
                if (this.plantillaWasCloned) {
                    try {
                        await this.$firebase
                            .database()
                            .ref(`/results_plantillas/${this.plantilla.id}`)
                            .set(this.plantilla);

                        this.originalPlantillaBlocksIds = Object.keys(this.plantilla.blocks || {});
                        this.originalPlantillaPagesCount = this.plantilla.pages.length;

                        this.$f7.dialog.close();
                    } catch (error) {
                        console.error(error);
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert("Error al clonar la plantilla", error);
                    }
                } else {
                    const plantillaCopy = Object.assign({}, this.plantilla);

                    // todo: resetear defaults

                    const plantillaKey = this.$firebase
                        .database()
                        .ref("/results_plantillas")
                        .push().key;

                    plantillaCopy.id = plantillaKey;
                    plantillaCopy.name = `${plantillaCopy.name} (copy)`;
                    plantillaCopy.createdAt = this.$timestamp();
                    plantillaCopy.createdBy = this.profile_userInfo.displayName;
                    try {
                        await this.$firebase
                            .database()
                            .ref(`/results_plantillas/${plantillaCopy.id}`)
                            .set(plantillaCopy);

                        this.plantilla.id = plantillaCopy.id;
                        this.plantilla.name = plantillaCopy.name;

                        this.$store.commit("plantillas/add_plantilla", plantillaCopy);

                        this.originalPlantillaBlocksIds = Object.keys(this.plantilla.blocks || {});
                        this.originalPlantillaPagesCount = this.plantilla.pages.length;

                        this.plantillaWasCloned = true;

                        this.$f7.dialog.close();
                    } catch (error) {
                        console.error(error);
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert("Error al clonar la plantilla", error);
                    }
                }
            }
        },

        clearSignatureDefaults() {
            const signatureBlock = Object.values(this.plantilla.blocks || {}).find((b) => b.block.code === "signature-block");
            const responsableInfoBlock = Object.values(this.plantilla.blocks || {}).find((b) => b.block.code === "responsable-info-block");

            if (signatureBlock) {
                this.$set(this.plantilla.blocks[signatureBlock.id].defaults, "signatureName", null);
                this.$set(this.plantilla.blocks[signatureBlock.id].defaults, "signatureUrl", null);
            }

            if (responsableInfoBlock) {
                this.$set(this.plantilla.blocks[responsableInfoBlock.id].defaults, "responsableName", null);
                this.$set(this.plantilla.blocks[responsableInfoBlock.id].defaults, "responsablePrefix", null);
                this.$set(this.plantilla.blocks[responsableInfoBlock.id].defaults, "responsableSignture", null);
            }
        },
    },
};
