module.exports = {
    namespaced: true,

    state: {
        allStatuses: [],
        allSections: [],
        allInstitutions: [],
        allComunas: [],
        allCountries: [],
        allExams: [],
        allPrevisiones: [],
    },

    mutations: {
        set_all_statuses(state, statuses) {
            state.allStatuses = statuses;
        },
        set_all_sections(state, sections) {
            state.allSections = sections;
        },
        set_all_institutions(state, institutions) {
            state.allInstitutions = institutions;
        },
        set_all_comunas(state, comunas) {
            state.allComunas = comunas;
        },
        set_all_countries(state, countries) {
            state.allCountries = countries;
        },
        set_all_exams(state, exams) {
            state.allExams = exams;
        },
        set_all_previsiones(state, previsiones) {
            state.allPrevisiones = previsiones;
        },
    },
};
