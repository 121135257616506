<template>
    <div class="padding-bottom">
        <slot></slot>
        <f7-row class="margin-bottom">
            <f7-col>
                <label>Prioridad</label>
                <f7-input type="select" @change="searchByPriority">
                    <option value="" :selected="search.priority === ''">Seleccione</option>
                    <option value="normal" :selected="search.priority === 'normal'">Normal</option>
                    <option value="urgente" :selected="search.priority === 'urgente'">Urgente</option>
                    <option value="stat" :selected="search.priority === 'stat'">STAT</option>
                </f7-input>
            </f7-col>
            <f7-col>
                <label>Número OT</label>
                <f7-input
                    type="text"
                    @input="search.numeroOT = $event.target.value"
                    :value="search.numeroOT"
                    @keyup.native.enter="searchByCode"
                    info="Presione ENTER para buscar"
                    clear-button
                ></f7-input>
            </f7-col>
            <f7-col>
                <label>Fecha OT</label>
                <f7-input
                    type="text"
                    :maxlength="10"
                    @input="search.fechaOT = $event.target.value"
                    :value="search.fechaOT | formatDate"
                    @keyup.native.enter="handleEnterSearchByFecha"
                    placeholder="dd/MM/AAAA"
                    info="Presione ENTER para buscar. CTRL+ENTER para buscar HOY."
                    clear-button
                ></f7-input>
            </f7-col>
            <f7-col>
                <label>Número de muestra</label>
                <f7-input
                    type="text"
                    @input="search.numeroDeMuestra = $event.target.value"
                    :value="search.numeroDeMuestra"
                    @keyup.native.enter="searchByNumeroDeMuestra"
                    info="Presione ENTER para buscar"
                    clear-button
                ></f7-input>
            </f7-col>
        </f7-row>

        <ots-found-popup
            :opened="popup__showOTSPopup"
            :ots="otsFound"
            @popup-closed="popup__showOTSPopup = false"
            @ot-selected="handleOTSelected"
        ></ots-found-popup>
    </div>
</template>

<script>
import OTSFoundPopup from "@/app/components/ots-found-popup.vue";

export default {
    props: {
        reset: Boolean,
    },

    components: {
        "ots-found-popup": OTSFoundPopup,
    },

    data() {
        return {
            search: {
                priority: "",
                numeroOT: "",
                fechaOT: "",
                numeroDeMuestra: "",
            },

            ot: {},

            otsFound: {},
            popup__showOTSPopup: false,
        };
    },

    watch: {
        reset() {
            this.clear();
        },
    },

    methods: {
        searchByPriority(ev) {
            console.log("search by priority");
            const prioridad = ev.target.value;

            this.search.priority = prioridad;

            if (!prioridad.length) {
                this.$f7.dialog.close();
                return;
            }

            this.$f7.dialog.preloader("Buscando Orden de Trabajo por prioridad");

            this.$firebase
                .database()
                .ref("/ots")
                .orderByChild("priority")
                .equalTo(prioridad)
                .once("value")
                .then((snapshot) => {
                    if (!snapshot.exists()) {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(
                            "No se ha encontrado ordenes de trabajo con la prioridad indicada"
                        );
                        return;
                    }

                    const ots = snapshot.val();

                    // change handleAdmExamsFound
                    this.handleMultiplesOTfound(ots);

                    this.$f7.dialog.close();
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Hubo un error al buscar la OT. " + error);
                });
        },

        searchByCode() {
            if (!this.search.numeroOT.length) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Debe llenar el campo para poder buscar por número de OT");
                return;
            }
            if (this.search.numeroOT.length < 8) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(
                    "La búsqueda por numero de OT debe realizarse con un mínimo de 8 caracteres"
                );
                return;
            }
            this.$f7.dialog.preloader("Buscando Orden de Trabajo por código");

            let queryString = this.search.numeroOT;

            if (this.search.numeroOT.length === 8 || this.search.numeroOT.length === 14) {
                queryString = this.search.numeroOT + "-"
            }

            this.$firebase
                .database()
                .ref("/ots")
                .orderByKey()
                .startAt(queryString)
                .endAt(queryString + "\uf8ff")
                .once("value")
                .then((snapshot) => {
                    if (!snapshot.exists()) {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert("No se ha encontrado la Orden de Trabajo indicada.");
                        return;
                    }

                    const ots = snapshot.val();

                    this.handleMultiplesOTfound(ots);

                    this.$f7.dialog.close();
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Hubo un error al buscar la OT. " + error);
                });
        },

        searchByFechaOT() {
            this.$f7.dialog.preloader("Buscando Orden de Trabajo por fecha");

            const _format = "DD-MM-YYYY";

            const fecha = this.$moment(this.search.fechaOT, _format);

            if (!fecha.isValid()) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(
                    "La fecha ingresada no es válida. Por favor, utilice el formato dd/mm/aaaa"
                );
                return;
            }

            const fechaStart = fecha.unix();
            const fechaEnd =
                this.$moment(fecha, _format)
                    .add(1, "days")
                    .unix() - 1;

            this.$firebase
                .database()
                .ref("/ots")
                .orderByChild("createdAt")
                .startAt(fechaStart)
                .endAt(fechaEnd)
                .once("value")
                .then((snapshot) => {
                    if (!snapshot.exists()) {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert("No existen órdenes de trabajo para la fecha especificada.");
                        return;
                    }

                    const ots = snapshot.val();

                    this.handleMultiplesOTfound(ots);

                    this.$f7.dialog.close();
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Hubo un error al buscar la OT. " + error);
                });
        },

        searchByNumeroDeMuestra() {
            this.$f7.dialog.preloader("Buscando Orden de Trabajo por número de muestra");

            this.$firebase
                .database()
                .ref("/ots")
                .orderByChild(`/samples/${this.search.numeroDeMuestra}`)
                .equalTo(true)
                .once("value", (snapshot) => {
                    if (!snapshot.exists()) {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert("No se ha encontrado la Orden de Trabajo indicada.");
                        return;
                    }

                    const ot = Object.values(snapshot.val())[0];

                    this.handleOTSelected(ot, false);
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Hubo un error al buscar la OT. " + error);
                });
        },

        handleOTSelected(ot, preloader = true) {
            if (preloader) {
                this.$f7.dialog.preloader("Seleccionando Órden de Trabajo...");
            }

            this.ot = ot;

            this.$firebase
                .database()
                .ref(`/admissions/${ot.admissionId}`)
                .once("value")
                .then((snapshot) => {
                    const admission = snapshot.val() || {};

                    // fetchear los examenes de /admExams de la OT encontrada
                    this.$firebase
                        .database()
                        .ref("/admExams")
                        .orderByChild("ot")
                        .equalTo(this.ot.code)
                        .once("value")
                        .then((snapshot) => {
                            const admExams = snapshot.val() || {};

                            this.$emit("data-found", {
                                admission,
                                ot: this.ot,
                                admExams,
                            });

                            this.search.numeroOT = this.ot.code;
                            this.search.priority = this.ot.priority;
                            this.search.fechaOT = this.$moment.unix(this.ot.createdAt).format("DD/MM/YYYY");

                            this.otsFound = {};
                            this.popup__showOTSPopup = false;

                            this.$f7.dialog.close();
                        })
                        .catch((err) => {
                            console.error(err);
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert("Hubo un error al cargar los examenes de la OT. " + err);
                        });
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Hubo un error al buscar la OT. " + error);
                });
        },

        handleMultiplesOTfound(ots) {
            const howManyOTS = Object.keys(ots).length;

            if (howManyOTS > 1) {
                this.otsFound = ots;

                this.popup__showOTSPopup = true;
            } else if (howManyOTS === 1) {
                const firstKey = Object.keys(ots)[0];
                const ot = ots[firstKey];

                this.handleOTSelected(ot);
            }
        },

        handleEnterSearchByFecha(ev) {
            if (ev.ctrlKey) {
                const today = this.$moment(new Date()).format("DD/MM/YYYY");
                this.search.fechaOT = today;
            }

            this.searchByFechaOT();
        },

        clear() {
            this.search.priority = "";
            this.search.numeroOT = "";
            this.search.fechaOT = "";
            this.search.numeroDeMuestra = "";

            this.ot = {};

            this.otsFound = {};
            this.popup__showOTSPopup = false;
        },
    },
};
</script>
