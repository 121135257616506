<template>
    <f7-page name="agreementDetails">
        <f7-navbar 
            title="Detalles Convenio" 
            back-link 
            back-link-url="/agreements/" 
            back-link-force
        ></f7-navbar>
        <f7-block-title v-if="!selectedAgreement.name">
            Seleccione algún convenio
        </f7-block-title>
      
        <f7-block v-else>
            <f7-card class="demo-card-header-pic">
                <f7-card-header>
                    <f7-block-title medium> {{selectedAgreement.name}} </f7-block-title>
                </f7-card-header>
                <f7-card-content>
                    <p>{{selectedAgreement.description}}</p>
                </f7-card-content>
                <f7-card-footer>
                    <f7-button 
                        fill 
                        small 
                        round 
                        icon-material="edit" 
                        @click="goToEdit"
                        >Editar
                    </f7-button>
                    <f7-button 
                        fill 
                        small 
                        round 
                        icon-material="delete" 
                        color="red"
                        @click="deleteAgreement"
                        >Eliminar
                    </f7-button>
                </f7-card-footer>
            </f7-card>
        </f7-block>

    </f7-page>    
</template>
<script>
import { mapState } from 'vuex';

export default {
    data(){
        return {
        }
    },

    computed: {
        ...mapState(['selectedAgreement', 'profile_userInfo'])
    },

    methods: {
        goToEdit(){
            this.$f7.views.main.router.navigate('/new-agreement/');
        },
        deleteAgreement(){
            this.$f7.dialog.confirm('¿Está seguro que desea eliminar el tipo de convenio?', 'Eliminar', 
                () => {
                    this.selectedAgreement.enabled = false;
                    this.$firebase.database().ref('agreements/' + this.selectedAgreement.id)
                    .set(this.selectedAgreement)
                    .then(()=>{
                        //Se agrega información al nodo log
                        let info = {
                            who: this.profile_userInfo.uid,
                            wht: 'delete-agreement',
                            whn: this.$moment().unix(),    
                            dls: 'Elimina convenio',
                            pth: 'agreements/' + this.selectedAgreement.id,
                        }
                        let id = this.$firebase.database().ref('/log').push().key;
                        this.$firebase.database().ref('log/' + id).set(info)
                            .catch((err)=>{console.log(err.message)})

                        //Se elimina convenio de store
                        this.$store.commit('deleteObjectAttribute', {
                            obj: 'allAgreements', 
                            attr: this.selectedAgreement.id
                        });
                        this.$store.commit('setWhatTo', {what: 'selectedAgreement', to: {} });
                        this.$f7.toast.create({text: 'Tipo de convenio eliminado', closeTimeout: 2500}).open();
                    })
                    .catch((err)=>{
                        this.$f7.dialog.alert(err.message, err.code);
                    })
                }
            );
        }
    }
}
</script>