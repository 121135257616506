<template>
    <f7-block>
        <f7-block-title>Complete tantos datos como pueda</f7-block-title>
        <f7-list-item divider title="Datos demográficos"></f7-list-item>
        <f7-list no-hairlines-md class="no-margin-bottom">
            <li class="item-content item-input">
                <div class="item-inner">
                    <div class="item-title item-label">Nacionalidad</div>
                    <div class="item-input-wrap">
                        <input
                            id="autocomplete-paises"
                            :value="paciente.nacionalidad"
                            @change="
                                paciente.nacionalidad = $event.target.value
                            "
                            type="text"
                            autocomplete="no"
                            placeholder="Pais"
                        />
                        <span class="input-clear-button"></span>
                    </div>
                </div>
            </li>
        </f7-list>
        <f7-block-title class="no-margin-top"
            >Tipo de identificador</f7-block-title
        >
        <f7-block class="no-margin-bottom">
            <f7-segmented strong tag="p">
                <f7-button
                    @click="setTipoId('RUT')"
                    :active="paciente.tipoIdentificador == 'RUT'"
                    >RUT</f7-button
                >
                <f7-button
                    @click="setTipoId('DNI')"
                    :active="paciente.tipoIdentificador == 'DNI'"
                    >Otro DNI</f7-button
                >
                <f7-button
                    @click="setTipoId('PASAPORTE')"
                    :active="paciente.tipoIdentificador == 'PASAPORTE'"
                    >Pasaporte</f7-button
                >
            </f7-segmented>
        </f7-block>

        <f7-list no-hairlines-md class="no-margin-top">
            <f7-list-input
                :label="paciente.tipoIdentificador"
                type="text"
                name="identificador"
                :value="paciente.identificador"
                @input="formatRut($event.target.value)"
                :placeholder="paciente.tipoIdentificador"
                error-message="RUT no parece válido"
                :error-message-force="error.invalidRUT"
                @blur="validaRut()"
            ></f7-list-input>
            <f7-list-input
                label="Apellido 1"
                name="apellidoone"
                type="text"
                placeholder="Primer Apellido"
                :value="paciente.primerApellido"
                @input="paciente.primerApellido = $event.target.value"
                @blur="error.invalidLastName = false"
                required
                validate
                error-message="Debe indicar al menos el primer apellido"
                :error-message-force="error.invalidLastName"
            ></f7-list-input>
            <f7-list-input
                label="Apellido 2"
                name="apellidotwo"
                type="text"
                placeholder="Segundo Apellido"
                :value="paciente.segundoApellido"
                @input="paciente.segundoApellido = $event.target.value"
            ></f7-list-input>
            <f7-list-input
                label="Nombres"
                name="names"
                type="text"
                placeholder="Nombres"
                :value="paciente.nombres"
                @input="paciente.nombres = $event.target.value"
                @blur="error.invalidName = false"
                required
                validate
                error-message="Debe indicar al menos un nombre"
                :error-message-force="error.invalidName"
            ></f7-list-input>
            <f7-list-input
                label="Sexo"
                type="select"
                placeholder="Seleccione uno"
                :value="paciente.sexo"
                @change="paciente.sexo = $event.target.value"
            >
                <option>Femenino</option>
                <option>Masculino</option>
            </f7-list-input>
            <f7-list-input
                label="Teléfono de contacto"
                name="phone"
                type="text"
                placeholder="Teléfono"
                :value="paciente.telefono"
                @input="paciente.telefono = $event.target.value"
            ></f7-list-input>
            <f7-list-input
                label="Email"
                name="email"
                type="email"
                placeholder="Email"
                :value="paciente.email"
                @input="paciente.email = $event.target.value"
                validate
            ></f7-list-input>
            <f7-list-input
                label="Domicilio"
                name="domicilio"
                type="text"
                placeholder="Domicilio"
                :value="paciente.direccion"
                @input="paciente.direccion = $event.target.value"
            ></f7-list-input>
            <f7-list-item
                header="Comuna"
                :title="paciente.comuna || 'Seleccione...'"
                smart-select
                :smart-select-params="{
                    openIn: 'popup',
                    searchbar: true,
                    searchbarPlaceholder: 'Buscar comuna',
                    closeOnSelect: true,
                    setValueText: false,
                    popupCloseLinkText: 'Cerrar',
                    pageTitle: 'Seleccione una comuna',
                }"
            >
                <select
                    @change="paciente.comuna = $event.target.value"
                    :value="paciente.comuna"
                >
                    <option value="">Seleccione...</option>
                    <optgroup
                        v-for="region in comunas.regions"
                        :key="region.number"
                        :label="region.name"
                    >
                        <option
                            v-for="(comuna, index) in region.communes"
                            :key="index"
                            :value="comuna.name"
                        >
                            {{ comuna.name }}
                        </option>
                    </optgroup>
                </select>
            </f7-list-item>
        </f7-list>

        <f7-row>
            <f7-col>
                <f7-list class="no-margin-top" no-hairlines>
                    <f7-list-input
                        label="Fecha de Nacimiento"
                        type="datepicker"
                        placeholder="Fecha de nacimiento"
                        readonly
                        input-id="fechanxi"
                        clear-button
                        :calendar-params="{
                            closeOnSelect: true,
                            value: arregloFecha,
                            inputEl: '#fechanxi',
                            maxDate: today,
                            dateFormat: 'dd/mm/yyyy',
                        }"
                        @change="selectBirthday"
                        @blur="calculateAge"
                        error-message="Debe indicar fecha de nacimiento"
                        :error-message-force="error.invalidBirthDate"
                    ></f7-list-input>
                </f7-list>
            </f7-col>
            <f7-col>
                <f7-list class="no-margin-top">
                    <f7-list-item header="Edad" :title="edad"> </f7-list-item>
                </f7-list>
            </f7-col>
        </f7-row>

        <f7-row class="margin-bottom">
            <f7-col>
                <label>Prevision</label>
                <f7-input
                    type="text"
                    @input="paciente.prevision = $event.target.value"
                    :value="paciente.prevision"
                ></f7-input>
            </f7-col>
        </f7-row>

        <f7-block>
            <f7-row>
                <f7-col width="10" medium="25"> </f7-col>
                <f7-col width="0" medium="50">
                    <f7-button
                        :icon-material="!isEditor ? 'person_add' : 'edit'"
                        fill
                        raised
                        round
                        :text="
                            !isEditor
                                ? 'Agregar nuevo paciente'
                                : 'Guardar cambios'
                        "
                        @click="!isEditor ? addPatient() : editPatient()"
                    />
                </f7-col>
                <f7-col width="10" medium="25"> </f7-col>
            </f7-row>
        </f7-block>
    </f7-block>
</template>

<script>
import countries from "i18n-iso-countries";
import { mapState, mapGetters } from "vuex";
import { validate, format, clean } from "rut.js";
countries.registerLocale(require("i18n-iso-countries/langs/es.json"));
import { paxhelpersmixin } from "../mixins/paxhelpersmixin";

import communes from "@/js/comunas.json";

export default {
    mixins: [paxhelpersmixin],
    props: {
        save: Function,
    },
    data() {
        return {
            isEditor: false,
            paises: [],
            arregloFecha: [],
            paciente: {
                id: "",
                nacionalidad: "Chile",
                tipoIdentificador: "RUT",
                identificador: "",
                primerApellido: "",
                segundoApellido: null,
                nombres: "",
                sexo: null,
                telefono: null,
                email: null,
                direccion: "",
                comuna: "",
                fechaNacimiento: "",
                prevision: "",
            },
            edad: "Ingrese fecha de nacimiento",
            db: this.$firebase.database(),
            today: new Date(),
            error: {
                invalidName: false,
                invalidLastName: false,
                invalidRUT: false,
                invalidBirthDate: false,
            },

            comunas: communes,
        };
    },

    computed: {
        ...mapState(["pacienteSeleccionado"]),
    },

    methods: {
        setAddOrEdit() {
            //Si hay un paciente seleccionado se activa el modo de editor
            if (this.pacienteSeleccionado) {
                this.isEditor = true;
                this.paciente = { ...this.pacienteSeleccionado };

                if (!this.paciente.direccion) {
                    this.$set(this.paciente, "direccion", "");
                }

                if (!this.paciente.comuna) {
                    this.$set(this.paciente, "comuna", "");
                }

                this.arregloFecha = [
                    this.$moment(this.paciente.fechaNacimiento, "DD/MM/YYYY"),
                ];
                this.calculateAge();
            }
        },

        setTipoId(tipo) {
            this.error.invalidRUT = false;
            this.paciente.tipoIdentificador = tipo;
        },

        validaRut() {
            if (this.paciente.tipoIdentificador === "RUT") {
                if (!validate(this.paciente.identificador)) {
                    this.error.invalidRUT = true;
                } else {
                    this.error.invalidRUT = false;
                }
            }
        },

        formatRut(linput) {
            if (this.paciente.tipoIdentificador === "RUT") {
                this.paciente.identificador = format(linput); //Separa el rut con puntos, guion y cambia a mayúscula
            } else {
                this.paciente.identificador = linput;
            }
        },

        //Guarda RUT sin puntos ni guión
        cleanRUT() {
            if (this.paciente.tipoIdentificador === "RUT") {
                this.paciente.identificador = clean(
                    this.paciente.identificador
                );
            }
        },

        selectBirthday(event) {
            this.error.invalidBirthDate = false;
            this.paciente.fechaNacimiento = event.target.value;
        },

        //Calculo de edad para mostrar en años y meses
        calculateAge() {
            if (this.paciente.fechaNacimiento) {
                this.edad = this.suage(this.paciente.fechaNacimiento);
            }
        },

        savePatientInfo() {
            const id = this.db.ref("/pacientes").push().key;
            this.paciente.id = id;
            this.save(id, this.paciente, "add-patient");
        },

        addPatient() {
            if (!this.paciente.identificador) {
                this.$f7.dialog.alert(
                    "Debe ingresar un identificador",
                    "Error"
                );
            } else if (!this.paciente.primerApellido) {
                this.error.invalidLastName = true;
            } else if (!this.paciente.nombres) {
                this.error.invalidName = true;
            } else if (!this.paciente.fechaNacimiento) {
                this.error.invalidBirthDate = true;
            } else {
                this.$f7.dialog.preloader("Guardando");
                this.cleanRUT();
                //Realiza llamado a BD para verificar si existe paciente con mismo identificador
                this.db
                    .ref("pacientes/")
                    .orderByChild("identificador")
                    .equalTo(this.paciente.identificador)
                    .once("value")
                    .then((data) => {
                        if (!data.val()) {
                            this.savePatientInfo();

                            // Legacy: ya no se verifica email al crear paciente
                            /* if(!this.paciente.email){
                            this.savePatientInfo();
                        }
                        //Si hay email ingresado verificar si existe en la BD
                        else{
                            this.db.ref('pacientes/').orderByChild('email').equalTo(this.paciente.email).once('value')
                            .then((resp) => {
                                if(resp.val()){
                                    this.$f7.dialog.close();
                                    this.$f7.dialog.alert('Ya existe un paciente con el mismo email', 'Error');
                                    return
                                }
                                this.savePatientInfo();
                            })
                        } */
                        } else {
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert(
                                "Ya existe un paciente con el mismo identificador",
                                "Error"
                            );
                        }
                    })
                    .catch((err) => {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(err.message, err.code);
                    });
            }
        },

        editPatient() {
            delete this.paciente.score;
            this.$f7.dialog.preloader("Editando");
            this.cleanRUT();
            //Se verifica si existe paciente con el mismo identificado en BD
            this.db
                .ref("pacientes/")
                .orderByChild("identificador")
                .equalTo(this.paciente.identificador)
                .once("value")
                .then((data) => {
                    //Si existe el verificador y el Id del paciente no coincide no guardar
                    if (
                        data.val() &&
                        Object.keys(data.val())[0] !== this.paciente.id
                    ) {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(
                            "Ya existe un paciente con el mismo identificador",
                            "Error"
                        );
                    } else {
                        this.save(
                            this.paciente.id,
                            this.paciente,
                            "edit-patient"
                        );
                    }
                    //Si se ingresa email se verifica que no exista en BD
                    /* else if (!this.paciente.email) {
                        this.save(
                            this.paciente.id,
                            this.paciente,
                            "edit-patient"
                        );
                    } else {
                        this.db
                            .ref("pacientes/")
                            .orderByChild("email")
                            .equalTo(this.paciente.email)
                            .once("value")
                            .then((resp) => {
                                if (
                                    resp.val() &&
                                    Object.keys(resp.val())[0] !==
                                        this.paciente.id
                                ) {
                                    this.$f7.dialog.close();
                                    this.$f7.dialog.alert(
                                        "Ya existe un paciente con el mismo email",
                                        "Error"
                                    );
                                    return;
                                }
                                this.save(
                                    this.paciente.id,
                                    this.paciente,
                                    "edit-patient"
                                );
                            })
                            .catch((err) => {
                                this.$f7.dialog.close();
                                this.$f7.dialog.alert(err.message, err.code);
                            });
                    } */
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, err.code);
                });
        },
    },
    created() {
        this.setAddOrEdit();
    },
    mounted() {
        let localPaises = countries.getNames("es");
        this.paises = Object.values(localPaises);
        let autocompleteDropdownTypeahead = this.$f7.autocomplete.create({
            inputEl: "#autocomplete-paises",
            openIn: "dropdown",
            dropdownPlaceholderText: "Comienza a escribir el nombre del pais",
            typeahead: true,
            source: (query, render) => {
                let results = [];
                if (query.length === 0) {
                    render(results);
                    return;
                }
                // Find matched items
                for (let i = 0; i < this.paises.length; i++) {
                    if (
                        this.paises[i]
                            .toLowerCase()
                            .indexOf(query.toLowerCase()) === 0
                    )
                        results.push(this.paises[i]);
                }
                // Render items by passing array with result items
                render(results);
            },
        });
    },
};
</script>
