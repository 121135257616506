<template>
    <f7-page>
        <f7-navbar title="Importar exámenes COVID-19"></f7-navbar>

        <f7-block v-if="!fileUploaded">
            <h3>Seleccione la institución</h3>
            <f7-input type="select" @change="handleInstitutionSelected(Number($event.target.value))">
                <option value="">Seleccione...</option>
                <option
                    v-for="institution in allInstitutions"
                    :key="institution.id"
                    :value="institution.id"
                    :selected="selectedInstitution.id === institution.id"
                >
                    {{ institution.name }}
                </option>
            </f7-input>
        </f7-block>

        <f7-block v-if="selectedInstitution.id" class="no-margin-top">
            <div class="display-flex justify-content-space-between align-items-center">
                <h3>Cargando exámenes de la institución {{ selectedInstitution.name }}</h3>
                <f7-button outline small color="red" v-if="fileUploaded" @click="clear">
                    <span v-if="!admitted">Cancelar subida</span>
                    <span v-else>Subir nuevo excel</span>
                </f7-button>
            </div>

            <f7c-alert v-if="fileUploaded" type="warning">
                Los exámenes con conflictos sin resolver
                <b>no serán admitidos</b>
                .
                {{ Object.keys(admissionsWithConflicts).length > 0 ? `(${Object.keys(admissionsWithConflicts).length} conflictos)` : "" }}
            </f7c-alert>

            <template v-if="!fileUploaded">
                <input type="file" id="input" @change="fileChanged($event)" accept=".xlsx" />
                <h3>Formato requerido del excel</h3>

                <span class="text-color-blue"><b>Campos obligatorios</b></span>

                <!-- prettier-ignore -->
                <f7-row>
                    <f7-col>
                        <p><b>A:</b> codigo_muestra_cliente</p>
                        <p><b>B:</b> rut_responsable</p>
                        <p><b>C:</b> cod_deis</p>
                        <p><b>D:</b> rut_medico</p>
                        <p class="text-color-blue"><b>E: paciente_tipodoc</b> (RUN/RUT, PASAPORTE, DNI)</p>
                        <p class="text-color-blue"><b>F: paciente_run</b> (Sin puntos)</p>
                        <p class="text-color-blue"><b>G: paciente_dv</b></p>
                        <p class="text-color-blue"><b>H: paciente_pasaporte</b></p>
                        <p class="text-color-blue"><b>I: paciente_ext_paisorigen</b> (código iso alpha3)</p>
                    </f7-col>
                    <f7-col>
                        <p class="text-color-blue"><b>J: paciente_nombres</b></p>
                        <p class="text-color-blue"><b>K: paciente_ap_pat</b></p>
                        <p><b>L:</b> paciente_ap_mat</p>
                        <p class="text-color-blue"><b>M: paciente_fecha_nac</b> (DD-MM-AAAA)</p>
                        <p><b>N:</b> paciente_comuna</p>
                        <p><b>O:</b> paciente_direccion</p>
                        <p><b>P:</b> paciente_telefono</p>
                        <p><b>Q:</b> paciente_email</p>
                        <p class="text-color-blue"><b>R: paciente_sexo</b> (F, M)</p>
                    </f7-col>
                    <f7-col>
                        <p><b>S:</b> paciente_prevision</p>
                        <p><b>T:</b> fecha_muestra</p>
                        <p><b>U:</b> tipo_muestra</p>
                        <p><b>V:</b> busqueda_activa</p>
                        <p><b>W:</b> resultado</p>
                        <p><b>X:</b> fecha_recepcion_muestra</p>
                        <p><b>Y:</b> fecha_resultado_muestra</p>
                        <p><b>Z:</b> sin_orden_medica</p>
                    </f7-col>
                </f7-row>
            </template>
            <template v-else>
                <f7-button fill @click="admitExams" v-if="!admitted">
                    Admitir exámenes
                </f7-button>
                <!-- <f7-button fill @click="processSelectedAdmissions" v-else-if="!processed">
                    Procesar exámenes
                </f7-button> -->

                <table class="table table-sm table-bordered margin-top">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Examen</th>
                            <th>Muestra</th>
                            <th>Paciente</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(data, admissionId, index) in dataToCreate" :key="admissionId">
                            <td>
                                <b>{{ index + 2 }}</b>
                            </td>
                            <td>
                                {{ data.admission.exams[0].name }}
                            </td>
                            <td>
                                <div>{{ data.admission.exams[0].samples[0].name }}</div>
                                <div v-if="admitted">
                                    {{ data.admission.exams[0].samples[0].code }}
                                </div>
                            </td>
                            <td class="uppercase">
                                <f7-icon
                                    v-if="!isPatientIdValid(data.admission.patient)"
                                    material="warning"
                                    color="red"
                                    tooltip="El RUT de este paciente no es válido. La admisión va a ser guardada de todas maneras."
                                ></f7-icon>
                                {{ patientInfo(data.admission.patient) }}
                            </td>
                            <td>
                                <f7-button
                                    @click="resolveConflicts(data.admission.patient, data.admission.id)"
                                    color="red"
                                    small
                                    v-if="admissionsWithConflicts[data.admission.id]"
                                    :disabled="admitted && admissionsWithConflicts[data.admission.id]"
                                >
                                    Resolver conflicto
                                </f7-button>
                                <f7-button
                                    small
                                    @click="viewPatient(data.admission.patient, data.admission.id)"
                                    :disabled="admitted && admissionsWithConflicts[data.admission.id]"
                                >
                                    {{ patientHasId(data.admission.id) ? "Editar" : "Ver" }} paciente
                                </f7-button>
                                <f7-button v-if="processed && !admissionsWithConflicts[data.admission.id]" small @click="printLabel(data.admission)">
                                    Imprimir etiqueta
                                </f7-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </f7-block>

        <!-- Editar paciente popup -->
        <f7-popup :opened="patientInfoPopupOpened" @popup:closed="handlePatientInfoPopupClosed">
            <f7-page>
                <f7-navbar title="Viendo al paciente">
                    <f7-nav-right>
                        <f7-link popup-close>Cerrar</f7-link>
                    </f7-nav-right>
                </f7-navbar>

                <f7c-alert v-if="!isPatientIdValid(viewingPatient.patient)" type="warning" class="margin">
                    El RUT de este paciente es inválido. La admisión se va a guardar de todas maneras.
                </f7c-alert>

                <patient-form
                    :patient="viewingPatient.patient"
                    :admissionId="String(viewingPatient.admissionId)"
                    @patient-info-changed="handlePatientInfoChanged"
                    :readonly="!Object.keys(viewingPatient).length ? false : !patientHasId(viewingPatient.admissionId)"
                ></patient-form>
            </f7-page>
        </f7-popup>

        <!-- Resolver conflictos popup -->
        <f7-popup :opened="resolveConflictsPopupOpened" @popup:closed="resolveConflictsPopupOpened = false">
            <f7-page>
                <f7-navbar title="Resolver conflictos del paciente">
                    <f7-nav-right>
                        <f7-link popup-close>Cancelar</f7-link>
                    </f7-nav-right>
                </f7-navbar>

                <patients-comparison
                    :comparison="currentConflict"
                    @use-patient="useThisPatient"
                ></patients-comparison>
            </f7-page>
        </f7-popup>

        <!-- Seleccion de pacientes popup -->
        <f7-popup class="found-patients" :opened="selectPatientPopupOpened" @popup:closed="selectPatientPopupOpened = false">
            <f7-page>
                <f7-navbar title="Seleccione un paciente">
                    <f7-nav-right>
                        <f7-link popup-close>Cerrar</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <f7-block class="margin-top-half">
                    <p>
                        La búsqueda ha arrojado más de un resultado. Seleccione un paciente de la lista
                    </p>

                    <f7-list media-list>
                        <f7-list-item
                            checkbox
                            media-item
                            v-for="patient in foundPatients"
                            :key="patient.id"
                            @change="handleSelectPatient(patient)"
                            class="thin-list"
                        >
                            <div slot="title" class="uppercase">
                                {{ patient.name }}
                                {{ patient.lastname_f }}
                                {{ patient.lastname_m }}
                            </div>
                            <div slot="after">
                                {{ getDocumentType(patient.document_type) }}
                                {{ patient.document_number }}
                            </div>
                        </f7-list-item>
                    </f7-list>
                </f7-block>
            </f7-page>
        </f7-popup>
    </f7-page>
</template>

<script>
import { mapState, mapActions } from "vuex";

import readXlsxFile from "read-excel-file";

import PatientForm from "@/components/admission-components/patient-form.vue";
import PatientsComparison from "@/components/admission-components/patients-comparison.vue";

import _countriesIso from "@/js/countries-iso.json";
import { printermixin } from "../../mixins/printermixin";
import { paxhelpersmixin } from "../../mixins/paxhelpersmixin";

import { clean, validate } from "rut.js";

import { _getDocumentType } from "@/app/helpers";

export default {
    mixins: [printermixin, paxhelpersmixin],

    components: {
        "patient-form": PatientForm,
        "patients-comparison": PatientsComparison,
    },

    data() {
        return {
            verifiedPatients: 0,

            samplesCodeRange: "",

            institutions: {},
            selectedInstitution: "",

            dataToCreate: {},

            admissionsWithConflicts: {},

            samplesCount: 0,

            selectedAdmissions: {},

            preadmittedAdmissions: {},

            // Edit patients
            patientInfoPopupOpened: false,
            viewingPatient: {},

            // Found patients
            selectPatientPopupOpened: false,
            foundPatients: {},

            // Resolve conflicts
            resolveConflictsPopupOpened: false,
            currentConflict: {
                excelPatient: {},
                dbPatient: {},
                admissionId: "",
            },

            // DB Patients with added fields
            updatedPatientsInAdmission: {},

            cols: {
                rut_responsable: 1,
                rut_medico: 3,
                paciente_tipodoc: 4,
                paciente_run: 5,
                paciente_dv: 6,
                paciente_pasaporte: 7,
                paciente_ext_paisorigen: 8,
                paciente_nombres: 9,
                paciente_ap_pat: 10,
                paciente_ap_mat: 11,
                paciente_fecha_nac: 12,
                paciente_comuna: 13,
                paciente_direccion: 14,
                paciente_telefono: 15,
                paciente_email: 16,
                paciente_sexo: 17,
                paciente_prevision: 18,

                fecha_muestra: 19,
                tipo_muestra: 20,

                resultado: 22,

                fecha_recepcion_muestra: 23,
                fecha_resultado_muestra: 24,
            },

            admitted: false,
            processed: false,

            fileName: "",

            generatedSamplesCodes: [],

            countriesIso: _countriesIso,
            getDocumentType: _getDocumentType,

            errorCtm: false, // hack
        };
    },

    created() {
        this.getInstitutions();
        this.getCountries();
        this.getComunas();
        this.getPrevisiones();
    },

    computed: {
        ...mapState(["allInstitutions", "countries", "comunas", "previsiones", "profile_userInfo", "env"]),

        fileUploaded() {
            return Object.keys(this.dataToCreate).length > 0;
        },

        areAllAdmissionsChecked() {
            const rowsCount = Object.keys(this.dataToCreate).length;

            if (rowsCount === 0) {
                return false;
            }

            return Object.keys(this.selectedAdmissions).length === rowsCount;
        },
    },

    watch: {
        verifiedPatients() {
            const admissionsCount = Object.keys(this.dataToCreate).length;

            if (this.verifiedPatients === admissionsCount) {
                this.$f7.dialog.close();
            }
        },

        errorCtm(val) {
            if (val === true) {
                setTimeout(() => {
                    this.errorCtm = false;
                    this.clear(false);
                }, 1500);
            }
        }
    },

    methods: {
        ...mapActions(["getAPIInfo", "createNewInfo", "updateInfo"]),

        async createNewPatients(dataToCreate, token) {
            const patientsToCreate = [];
            const admissionsWithPatientsToCreate = [];

            Object.values(dataToCreate).forEach((data) => {
                const admissionId = data.admission.id;
                const patient = data.admission.patient;

                const isNewPatient = !data.admission.patient_id && !this.admissionsWithConflicts[admissionId];

                if (isNewPatient) {
                    patientsToCreate.push(patient);
                    admissionsWithPatientsToCreate.push(admissionId);
                }
            });

            console.log("creando pacientes", { patientsToCreate, admissionsWithPatientsToCreate });

            const response = await this.createNewInfo({
                url: `patients/multiple`,
                userToken: token,
                data: { patients: patientsToCreate },
            });

            const createdPatients = response.data.data;

            createdPatients.forEach((patient, index) => {
                const formattedPatient = {
                    ...patient,
                    birth_date: this.$moment(patient.birth_date, "YYYY-MM-DD").format("DD/MM/YYYY"),
                };

                const admissionId = admissionsWithPatientsToCreate[index];

                this.$set(this.dataToCreate[admissionId].admission, "patient", formattedPatient);
                this.$set(this.dataToCreate[admissionId].admission, "patient_id", formattedPatient.id);
            });
        },

        getBatchLogsUpdates() {
            const range = `${this.generatedSamplesCodes[0]}-${this.generatedSamplesCodes[this.generatedSamplesCodes.length - 1]}`;

            const batchLogUpdate = {};
            const timestamp = (new Date() / 1000) | 0;
            const batchLogKey = this.$firebase
                .database()
                .ref()
                .push().key;

            const totalAdmissions = Object.keys(this.dataToCreate).length;
            const ommitedAdmissions = Object.keys(this.admissionsWithConflicts).length;
            const createdAdmissions = totalAdmissions - ommitedAdmissions;

            batchLogUpdate[`/batchLogs/${batchLogKey}`] = {
                ts: timestamp,
                date: this.$moment.unix(timestamp).format("DD/MM/YYYY HH:mm:ss"),
                fileName: this.fileName,
                samplesRange: range,
                who: this.profile_userInfo.displayName,
                admissions: {
                    created: createdAdmissions,
                    ommited: ommitedAdmissions,
                },
            };

            return batchLogUpdate;
        },

        async admitExams() {
            const conflictsCount = Object.keys(this.admissionsWithConflicts).length;
            const dataToCreateCount = Object.keys(this.dataToCreate).length;

            const confirmText =
                conflictsCount === 0
                    ? `Se admitirán ${dataToCreateCount} exámenes.`
                    : `- ${dataToCreateCount -
                          conflictsCount} exámenes serán preadmitidos.<br>- ${conflictsCount} filas conflictivas serán omitidas.`;

            this.$f7.dialog.confirm(confirmText, "¿Está seguro?", async () => {
                this.$f7.dialog.preloader("Admitiendo...");

                const token = await this.$firebase.auth().currentUser.getIdToken();

                // 1. Creamos los pacientes nuevos
                await this.createNewPatients(this.dataToCreate, token);

                for (const admissionKey of Object.keys(this.dataToCreate)) {
                    const data = this.dataToCreate[admissionKey];

                    const isAdmissionGenerable = !this.admissionsWithConflicts[admissionKey] && data.admission.patient_id !== 0;

                    if (isAdmissionGenerable) {
                        const admission = data.admission;

                        const formattedExams = [
                            {
                                id: admission.exams[0].id,
                                status: admission.exams[0].status,
                                samples: admission.exams[0].samples.map((s) => ({ id: s.id, quantity: s.quantity })),
                            },
                        ];

                        const formattedAdmission = {
                            agreement_id: admission.agreement_id,
                            priority: admission.priority,
                            diagnostic_obs: admission.diagnostic_obs,
                            exams: formattedExams,
                            institution_id: admission.institution_id,
                            patient_id: admission.patient_id,
                            doctor_rut: admission.doctor_rut,
                        };

                        console.log("admitiendo examen", formattedAdmission);

                        let response = null;

                        try {
                            response = await this.createNewInfo({
                                url: `admissions`,
                                userToken: token,
                                data: { ...formattedAdmission },
                            });
                        } catch (error) {
                            console.error("Error al crear las admisiones", error.message);
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert("Error al crear las admisiones", error.message);
                            return;
                        }

                        const createdAdmission = response.data.data.admission;

                        this.$set(this.preadmittedAdmissions, data.admission.id, true);

                        // Seteamos el codigo de muestra que nos retornó la API
                        const code = createdAdmission.exams[0].samples[0].sample_code;
                        console.log("data.admission.id", data.admission.id);
                        this.$set(this.dataToCreate[data.admission.id].admission.exams[0].samples[0], "code", code);

                        this.generatedSamplesCodes.push(code);

                        // Seteamos la fecha de creación (para posteriormente mostrar en etiquetas)
                        this.$set(
                            this.dataToCreate[data.admission.id].admission,
                            "createdAt",
                            this.$moment(createdAdmission.createdAt, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm")
                        );

                        this.$set(this.dataToCreate[admission.id].admission, "id", createdAdmission.id);
                    } else {
                        console.log("Admission skipped!", admissionKey);
                    }
                }

                this.admitted = true;
                this.selectAllAdmissions(true);

                // Generamos batch logs
                try {
                    const batchLogUpdates = this.getBatchLogsUpdates();
                    await this.$firebase.database().ref().update(batchLogUpdates);
                } catch (error) {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Error al crear batch logs", error);
                }

                // Imprimimos las etiquetas
                Object.keys(this.selectedAdmissions).forEach((selectedAdmissionId, index) => {
                    const admission = this.dataToCreate[selectedAdmissionId].admission;
                    const code = this.dataToCreate[selectedAdmissionId].admission.exams[0].samples[0].code;

                    setTimeout(() => {
                        console.log("imprimiendo", code);

                        this.printLabel(admission);
                    }, 400 * (index + 1));
                });

                this.$f7.dialog.close();
            });
        },
        async processSelectedAdmissions() {
            this.$f7.dialog.confirm("¿Está seguro?", async () => {
                if (Object.keys(this.selectedAdmissions).length === 0) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Debe seleccionar admisiones para procesar");
                    return;
                }

                this.$f7.dialog.preloader("Procesando admisiones e imprimiendo etiquetas...");

                const token = await this.$firebase.auth().currentUser.getIdToken();

                for await (const selectedAdmissionId of Object.keys(this.selectedAdmissions)) {
                    const admissionId = this.dataToCreate[selectedAdmissionId].admission.id;

                    let response = null;

                    try {
                        response = await this.updateInfo({
                            url: `admissions/${admissionId}`,
                            userToken: token,
                            data: { opened: 0 }, // Admitidos
                        });
                    } catch (error) {
                        console.error("Error al admitir", error.message);
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert("Error al admitir", error.message);
                    }

                    console.log(`Success en /admissions/${admissionId}`, response);
                }

                this.processed = true;

                // Imprimimos las etiquetas
                Object.keys(this.selectedAdmissions).forEach((selectedAdmissionId, index) => {
                    const admission = this.dataToCreate[selectedAdmissionId].admission;
                    const code = this.dataToCreate[selectedAdmissionId].admission.exams[0].samples[0].code;

                    setTimeout(() => {
                        console.log("imprimiendo", code);

                        this.printLabel(admission);
                    }, 400 * (index + 1));
                });

                this.$f7.dialog.close();
            });
        },

        replaceSamplesCodes() {
            const createSamplesCodes = this.$firebase.functions().httpsCallable("createSamplesCodes");

            return createSamplesCodes({
                env: this.env,
                quantity: Object.keys(this.dataToCreate).length,
            }).then((response) => {
                const codes = response.data;

                this.samplesCodeRange = `${codes[0]}-${codes[codes.length - 1]}`;

                Object.values(this.dataToCreate).forEach((data, index) => {
                    const code = codes[index];

                    const admission = data.admission;

                    // Queremos reemplazar los codigos de las muestras creados localmente con los codigos
                    // generados por el sistema.
                    // Insertamos el codigo de la muestra generado por el sistema y eliminamos el
                    // anterior.
                    const sample = Object.values(this.dataToCreate[admission.id].admission.samples)[0];
                    this.$set(this.dataToCreate[admission.id].admission.samples, code, sample);
                    this.$set(this.dataToCreate[admission.id].admission.samples[code], "id", code);
                    this.$delete(this.dataToCreate[admission.id].admission.samples, index);

                    const examId = Object.keys(this.dataToCreate[admission.id].admittedExams)[0];
                    this.$set(this.dataToCreate[admission.id].admittedExams[examId].samples, code, {
                        id: code,
                    });
                    this.$delete(this.dataToCreate[admission.id].admittedExams[examId].samples, index);

                    const admittedSample = Object.values(this.dataToCreate[admission.id].admittedSamples)[0];
                    this.$set(this.dataToCreate[admission.id].admittedSamples, code, admittedSample);
                    this.$delete(this.dataToCreate[admission.id].admittedSamples, index);
                });
            });
        },
        clear(confirm = true) {
            const _clear = () => {
                this.verifiedPatients = 0;
                this.dataToCreate = {};
                this.selectedInstitution = {
                    id: 0,
                    name: "",
                };
                this.samplesCount = 0;
                this.admissionsWithConflicts = {};
                this.admitted = false;
                this.processed = false;
                this.samplesCodeRange = "";
                this.currentConflict = {
                    excelPatient: {},
                    dbPatient: {},
                    admissionId: "",
                };
                this.fileName = "";
                this.preadmittedAdmissions = {};
                this.selectedAdmissions = {};
                this.updatedPatientsInAdmission = {};
            };

            if (confirm) {
                this.$f7.dialog.confirm("¿Está seguro?", () => {
                    _clear();
                });
            } else {
                _clear();
            }
        },
        fileChanged(ev) {
            const file = ev.target.files[0];

            if (file) {
                this.$f7.dialog.preloader("Importando excel...");

                console.log("file uploaded successfully", file);

                this.fileName = file.name;

                readXlsxFile(file)
                    .then((rows) => {
                        const admissionDate = Date.now();

                        for (let i = 0; i < rows.length; i++) {
                            const row = rows[i];
                            const currentRow = i;

                            const rowNumber = currentRow + 1;

                            if (currentRow > 0) {
                                const admissionKey = this.$firebase
                                    .database()
                                    .ref()
                                    .push().key;

                                this.$set(this.dataToCreate, admissionKey, {
                                    admission: {},
                                });

                                this.dataToCreate[admissionKey].admission = this.createLocalAdmission(rowNumber, row, admissionDate, admissionKey);

                                const patientDocumentNumber = this.dataToCreate[admissionKey].admission.patient.document_number;
                                const patientDocumentType = this.dataToCreate[admissionKey].admission.patient.document_type;

                                this.$firebase
                                    .auth()
                                    .currentUser.getIdToken()
                                    .then((idToken) => {
                                        return this.getAPIInfo({
                                            url: `patients/search?document_number=${patientDocumentNumber}&document_type=${patientDocumentType}`,
                                            userToken: idToken,
                                        });
                                    })
                                    .then((response) => {
                                        if (this.errorCtm) {
                                            // hack
                                            return;
                                        }

                                        this.verifiedPatients += 1;

                                        const patients = response.data.data;

                                        const patientsCount = patients.length;

                                        if (patientsCount) {
                                            if (patientsCount > 1) {
                                                this.$set(this.admissionsWithConflicts, admissionKey, true);
                                                return;
                                            }

                                            const patientFromExcel = this.dataToCreate[admissionKey].admission.patient;

                                            let patientFromDb = {
                                                address: patients[0].address,
                                                birth_date: this.$moment(patients[0].birth_date, "YYYY-MM-DD").format("DD/MM/YYYY"),
                                                comuna_id: patients[0].comuna_id,
                                                country_id: patients[0].country_id,
                                                document_number: patients[0].document_number,
                                                document_type: patients[0].document_type,
                                                email: patients[0].email,
                                                gender: patients[0].gender,
                                                lastname_f: patients[0].lastname_f,
                                                lastname_m: patients[0].lastname_m,
                                                name: patients[0].name,
                                                phone: patients[0].phone,
                                                prevision: patients[0].prevision,
                                            };

                                            if (this.patientIsExactlyTheSame(patientFromExcel, patientFromDb)) {
                                                const { patient, wasChanged } = this.addPatientMissingFields(patientFromExcel, patientFromDb);

                                                this.dataToCreate[admissionKey].admission.patient = patient;
                                                this.dataToCreate[admissionKey].admission.patient_id = patients[0].id;

                                                if (wasChanged) {
                                                    this.$set(this.updatedPatientsInAdmission, admissionKey, true);
                                                }
                                            } else {
                                                this.$set(this.admissionsWithConflicts, admissionKey, true);
                                            }
                                        }
                                    })
                                    .catch((err) => {
                                        console.error(err);
                                        this.$f7.dialog.alert("Error " + err);
                                    });
                            }
                        }
                    })
                    .catch((err) => {
                        this.errorCtm = true;
                        err.name = "";
                        console.error(err);
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(err, "Error de formato del Excel");

                        this.clear(false);
                    });
            } else {
                this.fileName = "";
                this.$f7.dialog.close();
                console.error("file not found");
            }
        },

        // Admission
        createLocalAdmission(rowNumber, row, admissionDate, admissionKey) {
            try {
                const admission = {
                    id: admissionKey,
                    priority: 1, // Normal por defecto
                    agreement_id: 0,
                    diagnostic_obs: "",
                    exams: [
                        {
                            id: 368,
                            name: "PCR Coronavirus (COVID-19)",
                            samples: [{ id: 20, name: "Hisopado Nasofaríngeo", quantity: 1 }], // Hisopado nasofaríngeo
                            status: { id: 1 },
                        },
                    ],
                    institution_id: this.selectedInstitution.id,
                    patient_id: 0,
                };

                admission.patient = this.createPatient(row);

                if (row[this.cols.rut_medico]) {
                    admission.doctor_rut = clean(row[this.cols.rut_medico]);
                }

                return admission;
            } catch (err) {
                err.name = "";
                throw new Error(err + ` (Error en fila ${rowNumber})`);
            }
        },

        createPatient(row) {
            const patient = {};

            const tipoId = row[this.cols.paciente_tipodoc] ? row[this.cols.paciente_tipodoc].toUpperCase() : "";

            if (tipoId === "RUN" || tipoId === "RUT" || (tipoId === "" && row[this.cols.paciente_run])) {
                patient.document_type = 1;

                if (row[this.cols.paciente_run] !== null) {
                    const dv = row[this.cols.paciente_dv] !== null ? row[this.cols.paciente_dv].toString() : "";
                    patient.document_number = clean(row[this.cols.paciente_run].toString()) + dv;
                } else {
                    patient.document_number = "";
                }
            } else if (tipoId === "PASAPORTE") {
                patient.document_type = 2;

                if (row[this.cols.paciente_pasaporte]) {
                    patient.document_number = row[this.cols.paciente_pasaporte].toString();
                } else {
                    patient.document_number = "";
                }
            } else if (tipoId === "DNI") {
                patient.document_type = 3;
                patient.document_number = "";
            } else if (tipoId === "OTRO") {
                patient.document_type = 4;
                patient.document_number = row[this.cols.paciente_run];
            } else {
                throw new Error(
                    "Tipo Identificador o Identificador del paciente no especificado. Revisar columnas: paciente_tipo_doc / paciente_run / paciente_dv / paciente_pasaporte"
                );
            }

            if (!row[this.cols.paciente_ext_paisorigen]) {
                patient.country_id = 40;
            } else {
                const countryToFind = row[this.cols.paciente_ext_paisorigen].toUpperCase();
                const foundCountry = this.countries.find((c) => c.iso3 == countryToFind || this.removeAccents(c.name_es.toUpperCase()) == this.removeAccents(countryToFind));

                if (!foundCountry) {
                    throw new Error("Nacionalidad del paciente no encontrada. Revisar columnas: paciente_ext_paisorigen");
                } else {
                    patient.country_id = foundCountry.id;
                }
            }

            patient.name = row[this.cols.paciente_nombres] ? row[this.cols.paciente_nombres].toString().toUpperCase() : null;
            patient.lastname_f = row[this.cols.paciente_ap_pat] ? row[this.cols.paciente_ap_pat].toString().toUpperCase() : null;
            patient.lastname_m = row[this.cols.paciente_ap_mat] ? row[this.cols.paciente_ap_mat].toString().toUpperCase() : null;

            let fechaNac = "";

            if (typeof row[this.cols.paciente_fecha_nac] === "number") {
                fechaNac = this.excelDateToJSDate(row[this.cols.paciente_fecha_nac]);
                fechaNac = this.$moment(fechaNac, "DD-MM-YYYY")
                    .add(1, "days")
                    .format("DD/MM/YYYY");
            } else if (typeof row[this.cols.paciente_fecha_nac] === "string" || typeof row[this.cols.paciente_fecha_nac] === "object") {
                fechaNac = this.$moment(row[this.cols.paciente_fecha_nac], "DD-MM-YYYY").format("DD/MM/YYYY");
            } else {
                throw new Error("Fecha de nacimiento del paciente inválida. Revisar columnas: paciente_fecha_nac");
            }
            if (fechaNac === "Invalid date") {
                throw new Error("Fecha de nacimiento del paciente inválida. Revisar columnas: paciente_fecha_nac");
            }

            if (!this.$moment(fechaNac, "DD/MM/YYYY").isValid()) {
                throw new Error("Fecha de nacimiento del paciente inválida. Revisar columnas: paciente_fecha_nac");
            }

            const yearInBirthDate = this.$moment(fechaNac, "DD/MM/YYYY").year();

            if (yearInBirthDate < 1900) {
                throw new Error("Fecha de nacimiento del paciente inválida. Año de nacimiento es menor a 1900. Revisar columnas: paciente_fecha_nac");
            }

            if (this.$moment(fechaNac, "DD/MM/YYYY").isAfter()) {
                throw new Error("Fecha de nacimiento del paciente inválida. Fecha es mayor a la fecha actual. Revisar columnas: paciente_fecha_nac");
            }

            patient.birth_date = fechaNac;

            patient.address = row[this.cols.paciente_direccion] ? row[this.cols.paciente_direccion].toString().toUpperCase() : null;

            if (row[this.cols.paciente_comuna]) {
                const comunaToFind = row[this.cols.paciente_comuna].toString().toUpperCase();

                const foundComuna = this.comunas.find((c) => this.removeAccents(c.name.toUpperCase()) == this.removeAccents(comunaToFind));

                if (!foundComuna) {
                    throw new Error("Comuna del paciente no encontrada. Revisar columnas: paciente_comuna");
                } else {
                    patient.comuna_id = foundComuna.id;
                }
            } else {
                patient.comuna_id = 0;
            }

            patient.phone = row[this.cols.paciente_telefono] ? row[this.cols.paciente_telefono].toString() : null;

            patient.email = row[this.cols.paciente_email] ? row[this.cols.paciente_email].toString().toUpperCase() : null;

            if (row[this.cols.paciente_sexo].toUpperCase() === "F" || row[this.cols.paciente_sexo].toUpperCase() === "FEMENINO") {
                patient.gender = 1;
            } else if (row[this.cols.paciente_sexo].toUpperCase() === "M" || row[this.cols.paciente_sexo].toUpperCase() === "MASCULINO") {
                patient.gender = 2;
            } else {
                throw new Error("Sexo del paciente no identificado. Debe ser F o M. Revisar columnas: paciente_sexo");
            }

            let prevision = row[this.cols.paciente_prevision] ? row[this.cols.paciente_prevision].toUpperCase() : null;

            if (prevision) {
                const formattedPrevision = this.formatToCompare(prevision);
                const parsedPrevision = this.parsePrevision(formattedPrevision);

                if (!parsedPrevision) {
                    patient.prevision = 0;
                } else {
                patient.prevision = parsedPrevision.id;
                }
            } else {
                patient.prevision = 0;
            }

            return patient;
        },

        parsePrevision(prevision) {
            const foundPrevision = this.previsiones.find((p) => {
                const formattedPrevision = this.formatToCompare(p.name);

                if (formattedPrevision.includes(prevision)) {
                    return p;
                }
            });

            return foundPrevision;
        },

        formatToCompare(str) {
            return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '').replace(/\./g, "").toUpperCase()
        },

        createSamples(row, admissionDate) {
            const samples = {};

            samples.admissionDate = admissionDate;

            // Fix: hay que hacer un metodo con esto para utilizar tambien en createPatient
            let fechaMuestra = "";

            if (typeof row[this.cols.fecha_muestra] === "number") {
                fechaMuestra = this.excelDateToJSDate(row[this.cols.fecha_muestra]);
                fechaMuestra = this.$moment(fechaMuestra, "DD-MM-YYYY")
                    .add(1, "days")
                    .format("DD/MM/YYYY");
            } else if (typeof row[this.cols.fecha_muestra] === "string") {
                if (row[this.cols.fecha_muestra] === "") {
                    const admissionDateUnix = (admissionDate / 1000) | 0;
                    fechaMuestra = this.$moment.unix(admissionDateUnix).format("DD/MM/YYYY");
                } else {
                    fechaMuestra = this.$moment(row[this.cols.fecha_muestra], "DD-MM-YYYY").format("DD/MM/YYYY");
                }
            } else if (typeof row[this.cols.fecha_muestra] === "object") {
                fechaMuestra = this.$moment(row[this.cols.fecha_muestra], "DD-MM-YYYY").format("DD/MM/YYYY");
            } else {
                throw new Error("Fecha de muestra inválida. Revisar columnas: fecha_muestra");
            }
            if (fechaMuestra === "Invalid date") {
                throw new Error("Fecha de muestra inválida. Revisar columnas: fecha_muestra");
            }

            samples.dateTime = fechaMuestra;

            samples.dateUnknown = row[this.cols.fecha_muestra] ? null : true;
            samples.externalId = "20"; // hardcodeado para Hisopado Nasofaríngeo
            samples.id = this.samplesCount;
            samples.name = "Hisopado nasofaríngeo";

            if (row[this.cols.rut_responsable]) {
                samples.resp = {
                    idType: "RUT",
                    identifier: clean(row[this.cols.rut_responsable]),
                };
            }

            // Las samples se crean localmente con un codigo correlativo de 0 a n. Cada sample ingresada
            // incrementa esto
            this.samplesCount++;

            return samples;
        },

        createExams(row, examsKey, samples) {
            const exams = {};

            exams.id = examsKey;
            exams.externalId = "COVID-19";
            exams.name = "PCR Coronavirus (COVID-19)";
            exams.section_id = "2";

            if (row[this.cols.rut_medico]) {
                exams.doctor = {
                    rut: clean(row[this.cols.rut_medico]),
                };
            }

            const sampleKey = Object.keys(samples)[0];

            exams.samples = {
                [sampleKey]: { id: sampleKey },
            };

            return exams;
        },

        // Admitted Exams
        createLocalAdmittedExams(exam, admissionKey) {
            return {
                admissionId: admissionKey,
                ...exam,
            };
        },

        // Admitted Samples
        createLocalAdmittedSamples(admission) {
            const examKey = Object.keys(admission.exams)[0];

            return {
                admissionDate: admission.creationDate,
                admissionId: admission.id,
                exams: {
                    [examKey]: true,
                },
                from: admission.creationDate,
                sections: {
                    2: false,
                },
            };
        },

        // Checkboxes logic
        selectAdmission(checked, admissionId) {
            if (checked) {
                this.$set(this.selectedAdmissions, admissionId, true);
            } else {
                this.$delete(this.selectedAdmissions, admissionId);
            }
        },

        selectAllAdmissions(checked) {
            Object.keys(this.dataToCreate).forEach((admissionId) => {
                if (this.preadmittedAdmissions[admissionId]) {
                    this.selectAdmission(checked, admissionId);
                }
            });
        },

        isAdmissionChecked(admissionId) {
            return Object.keys(this.selectedAdmissions).findIndex((id) => id === admissionId) !== -1;
        },

        handlePatientInfoPopupClosed() {
            this.viewingPatient = {};
            this.patientInfoPopupOpened = false;
        },

        patientInfo(patient) {
            return `${this.getDocumentType(patient.document_type)}: ${patient.document_number} - ${patient.name} ${patient.lastname_f ||
                ""} ${patient.lastname_m || ""}`;
        },

        viewPatient(patient, admissionId) {
            this.viewingPatient = {
                patient,
                admissionId,
            };

            this.patientInfoPopupOpened = true;
        },

        patientIsExactlyTheSame(excelPatient, dbPatient) {
            console.log("check if patients are same", {excelPatient, dbPatient});

            let dbPatientCopy = Object.assign({}, dbPatient);
            delete dbPatientCopy.id;

            const dbPatientKeys = Object.keys(dbPatientCopy);

            let isEqual = true;

            for (let i = 0; i < dbPatientKeys.length; i++) {
                const propertyName = dbPatientKeys[i];

                const dbPatientProperty =
                    typeof dbPatient[propertyName] === "string" ? dbPatient[propertyName].toUpperCase() : dbPatient[propertyName];

                const excelPatientProperty =
                    typeof excelPatient[propertyName] === "string" ? excelPatient[propertyName].toUpperCase() : excelPatient[propertyName];

                if (dbPatientProperty !== excelPatientProperty) {
                    isEqual = false;
                }
            }

            return isEqual;
        },
        addPatientMissingFields(excelPatient, dbPatient) {
            const newKeys = [];

            Object.keys(excelPatient).forEach((excelPatientKey) => {
                if (!dbPatient[excelPatientKey] && excelPatient[excelPatientKey]) {
                    newKeys.push(excelPatientKey);
                }
            });

            const dbPatientCopy = Object.assign({}, dbPatient);

            newKeys.forEach((key) => (dbPatientCopy[key] = excelPatient[key]));

            const wasChanged = newKeys.length > 0;

            return { patient: dbPatientCopy, wasChanged };
        },
        getDbPatientUpdates() {
            const updates = {};

            Object.keys(this.updatedPatientsInAdmission).forEach((admissionId) => {
                const admission = this.dataToCreate[admissionId].admission;
                updates[`/pacientes/${admission.patientId}`] = admission.patient;

                // Log patient editted
                const patientLogId = this.$firebase
                    .database()
                    .ref(`/patients_log/${admission.patientId}`)
                    .push().key;

                updates[`/patients_log/${admission.patientId}/${patientLogId}`] = {
                    who: this.profile_userInfo.uid,
                    wht: "edit-patient",
                    whn: this.$moment().unix(),
                    dls: "Edita datos de paciente",
                    admission_id: admissionId,
                };
            });

            return updates;
        },

        async handlePatientInfoChanged(data) {
            const { patient, admissionId } = data;

            this.$set(this.dataToCreate[admissionId].admission, "patient", patient);
            this.viewingPatient = {};
            this.patientInfoPopupOpened = false;

            const token = await this.$firebase.auth().currentUser.getIdToken();

            let patientsFound = [];

            try {
                patientsFound = await this.getAPIInfo({
                    url: `patients/search?document_number=${patient.document_number}&document_type=${patient.document_type}`,
                    userToken: token,
                });
            } catch (error) {
                console.error(error);
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Error al verificar identidad el paciente editado", error);
            }

            if (patientsFound.length) {
                this.$set(this.admissionsWithConflicts, admissionId, true);
            } else {
                this.$delete(this.admissionsWithConflicts, admissionId);

                let editResponse = null;

                try {
                    editResponse = await this.updateInfo({
                        url: `patients/${this.dataToCreate[admissionId].admission.patient_id}`,
                        data: patient,
                        userToken: token,
                    });
                } catch (error) {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Error al editar el paciente", error);
                }

                this.$f7.toast.show({
                    text: "El paciente ha sido editado exitosamente.",
                    closeTimeout: 2000,
                });
            }
        },

        async resolveConflicts(patient, admissionId) {
            this.$f7.dialog.preloader("Cargando...");

            this.currentConflict.excelPatient = patient;
            this.currentConflict.admissionId = admissionId;

            const token = await this.$firebase.auth().currentUser.getIdToken();

            let response = null;

            try {
                response = await this.getAPIInfo({ url: `patients/search?document_number=${patient.document_number}`, userToken: token });
            } catch (error) {
                console.error("Error al buscar un paciente por número de documento", error.message);
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Error al buscar un paciente por número de documento", error.message);
            }

            console.log("resolveConflicts() response", response);

            this.$f7.dialog.close();

            const patients = response.data.data;

            if (patients.length > 1) {
                console.log("hay mas de 1 paciente, debe seleccionar");

                this.$f7.dialog.close();

                this.foundPatients = patients;
                this.selectPatientPopupOpened = true;
            } else if (patients.length === 1) {
                let onlyPatient = patients[0];

                onlyPatient = {
                    ...onlyPatient,
                    birth_date: this.$moment(onlyPatient.birth_date, "YYYY-MM-DD").format("DD/MM/YYYY"),
                };

                this.handleSelectPatient(onlyPatient);
            }
        },

        handleSelectPatient(patient) {
            this.currentConflict.dbPatient = patient;
            this.selectPatientPopupOpened = false;

            this.foundPatients = {};

            this.$f7.dialog.close();
            this.resolveConflictsPopupOpened = true;
        },

        useThisPatient(patientSelected) {
            if (patientSelected === "dbPatient") {
                this.useDbPatient();
            } else {
                this.useExcelPatient();
            }
        },

        useDbPatient() {
            this.$f7.dialog.confirm("Esta acción descartará el paciente en el Excel para utilizar el de la base de datos.", "¿Está seguro?", () => {
                const admissionId = this.currentConflict.admissionId;

                let formattedDbPatient = {
                    address: this.currentConflict.dbPatient.address,
                    birth_date: this.$moment(this.currentConflict.dbPatient.birth_date, "YYYY-MM-DD").format("DD/MM/YYYY"),
                    comuna_id: this.currentConflict.dbPatient.comuna_id,
                    country_id: this.currentConflict.dbPatient.country_id,
                    document_number: this.currentConflict.dbPatient.document_number,
                    document_type: this.currentConflict.dbPatient.document_type,
                    email: this.currentConflict.dbPatient.email,
                    gender: this.currentConflict.dbPatient.gender,
                    lastname_f: this.currentConflict.dbPatient.lastname_f,
                    lastname_m: this.currentConflict.dbPatient.lastname_m,
                    name: this.currentConflict.dbPatient.name,
                    phone: this.currentConflict.dbPatient.phone,
                    prevision: this.currentConflict.dbPatient.prevision,
                };

                this.$set(this.dataToCreate[admissionId].admission, "patient", formattedDbPatient);
                this.dataToCreate[admissionId].admission.patient_id = this.currentConflict.dbPatient.id;

                this.$delete(this.admissionsWithConflicts, admissionId);

                this.resolveConflictsPopupOpened = false;
            });
        },

        useExcelPatient() {
            this.$f7.dialog.confirm("Esta acción modificará al paciente en la base de datos.", "¿Está seguro?", async () => {
                const admissionId = this.currentConflict.admissionId;
                const dbPatientId = this.currentConflict.dbPatient.id;

                this.$set(this.currentConflict.excelPatient, "id", dbPatientId);

                console.log("excel patient", this.currentConflict.excelPatient);

                const token = await this.$firebase.auth().currentUser.getIdToken();

                let response = null;

                try {
                    response = await this.updateInfo({ url: `patients/${dbPatientId}`, data: this.currentConflict.excelPatient, userToken: token });
                } catch (error) {
                    console.error("Error al editar el paciente", error.message);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Error al editar el paciente", error.message);
                }

                console.log("response", response);

                this.dataToCreate[admissionId].admission.patient_id = dbPatientId;

                this.$delete(this.admissionsWithConflicts, admissionId);

                this.resolveConflictsPopupOpened = false;
            });
        },

        patientHasId(admissionId) {
            const dataFound = Object.values(this.dataToCreate).find((data) => data.admission.id === admissionId);

            if (!dataFound) return false;

            return dataFound.admission.patient_id !== null && dataFound.admission.patient_id !== 0;
        },

        getInstitutions() {
            if (this.allInstitutions) {
                return;
            }

            this.$f7.dialog.preloader("Cargando...");

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: "institutions", userToken: idToken });
                })
                .then((response) => {
                    const institutions = response.data.data;
                    this.$store.commit("setWhatTo", { what: "allInstitutions", to: institutions });

                    this.$f7.dialog.close();
                })
                .catch((err) => {
                    console.error(err);
                    this.$f7.dialog.alert("Error " + err);
                });
        },
        getCountries() {
            if (this.countries.length > 0) {
                return;
            }

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: "countries", userToken: idToken });
                })
                .then((response) => {
                    const countries = response.data.data;

                    this.$store.commit("setWhatTo", { what: "countries", to: countries });
                })
                .catch((err) => {
                    console.error(err);
                    this.$f7.dialog.alert("Error " + err);
                });
        },
        getComunas() {
            if (this.comunas.length > 0) {
                return;
            }

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: "comunas", userToken: idToken });
                })
                .then((response) => {
                    const comunas = response.data.data;

                    this.$store.commit("setWhatTo", { what: "comunas", to: comunas });
                })
                .catch((err) => {
                    console.error(err);
                    this.$f7.dialog.alert("Error " + err);
                });
        },
        getPrevisiones() {
            if (this.previsiones.length > 0) {
                return;
            }

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: "previsiones", userToken: idToken });
                })
                .then((response) => {
                    const previsiones = response.data.data;

                    this.$store.commit("setWhatTo", { what: "previsiones", to: previsiones });
                })
                .catch((err) => {
                    console.error(err);
                    this.$f7.dialog.alert("Error " + err);
                });
        },

        isPatientIdValid(patient) {
            if (!patient || patient.document_type !== 1) {
                return true;
            }

            return validate(patient.document_number);
        },

        excelDateToJSDate(serial) {
            var utc_days = Math.floor(serial - 25569);
            var utc_value = utc_days * 86400;
            var date_info = new Date(utc_value * 1000);
            var fractional_day = serial - Math.floor(serial) + 0.0000001;
            var total_seconds = Math.floor(86400 * fractional_day);
            var seconds = total_seconds % 60;
            total_seconds -= seconds;
            var hours = Math.floor(total_seconds / (60 * 60));
            var minutes = Math.floor(total_seconds / 60) % 60;
            return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
        },

        printLabel(admission) {
            const patient = admission.patient;
            const sample = admission.exams[0].samples[0];

            this.makelabel(
                this.lastNamesAndNamesPostMigration(patient),
                this.formatIdPostMigration(patient),
                patient.birth_date,
                this.suageEtiquetas(patient.birth_date),
                sample.code,
                sample.name,
                admission.createdAt,
                2
            );
        },

        handleInstitutionSelected(institutionId) {
            const foundInstitution = this.allInstitutions.find((i) => i.id === institutionId);

            if (!foundInstitution) {
                this.selectedInstitution = {
                    id: 0,
                    name: "",
                };

                return;
            }

            this.selectedInstitution = {
                id: foundInstitution.id,
                name: foundInstitution.name,
            };
        },

        removeAccents(str) {
            return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        }
    },
};
</script>
