<template>
    <div>
        <div class="text-align-center">
            <div>
                <img class="signature" :src="directorTecnico.signatureUrl" alt="Firma Director Técnico" />
            </div>

            <span>
                {{ directorTecnico.name }}
                <br />
                Director Técnico Laboratorio Clínico
            </span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            directorTecnico: {
                name: "TM. Paola García-Huidobro Poblete",
                signatureUrl: "../../../../static/img/director_tecnico_signature.png",
            },
        };
    },
};
</script>

<style scoped>
.signature {
    width: 200px;
    height: 100px;
}
</style>
