export const _sources = {
    OT_SEARCH: "ot-search",
    CREATE_ADMISSION: "create-admission",

    EXAMS_SEARCH: "exams_search",
};

export const examStatuses = {
    preadmitido: -1, // Estado temporal para solicitudes pax pendientes
    admitido: 1,
    en_laboratorio: 2,
    resultado_cargado: 3,
    examen_confirmado: 4,
    examen_firmado: 5,
    examen_impreso: 6,
    examen_visto_por_internet: 7,
    examen_pendiente: 8,
    examen_rechazado: 9,
};
export const examStatusesNames = {
    admitido: "Admitido",
    en_laboratorio: "En laboratorio",
    resultado_cargado: "Resultado cargado",
    examen_confirmado: "Examen confirmado",
    examen_firmado: "Examen firmado",
    examen_impreso: "Examen impreso",
    examen_visto_por_internet: "Examen visto por internet",
    examen_pendiente: "Examen pendiente",
    examen_rechazado: "Examen rechazado",
};
export const examStatusesIds = {
    1: "Admitido",
    2: "En laboratorio",
    3: "Resultado cargado",
    4: "Examen confirmado",
    5: "Examen firmado",
    6: "Examen impreso",
    7: "Examen visto por internet",
    8: "Examen pendiente",
    9: "Examen rechazado",
};

export const paxSolicitudStatus = {
    pendiente: 1,
    admitida: 2,
    rechazada: 3,
};

export const priorities = {
    1: "Normal",
    2: "Urgente",
    3: "STAT",
};

export const admissionOpenedStatus = {
    0: "Admitida",
    1: "Abierta",
};

// Constantes de plantillas

/** originales -> h: 3508 w: 2480 */
export const _plantillasSizes = {
    height: 1169.3,
    width: 826.6,
};

export const resultTypes = {
    cualitativo: 1,
    cuantitativo: 2,
    free_text: 3,
};

export const classes = [
    {
        class: 0,
        minVal: 0,
        maxVal: 0.35,
        result: "Negativo",
    },
    {
        class: 1,
        minVal: 0.35,
        maxVal: 0.7,
        result: "Baja concentración IgE específico o Síntomas Leves",
    },
    {
        class: 2,
        minVal: 0.7,
        maxVal: 3.5,
        result: "Baja concentración IgE específico o Síntomas Leves",
    },
    {
        class: 3,
        minVal: 3.5,
        maxVal: 17.5,
        result: "Moderada concentración IgE alergeno específico, síntomas clínicos habituales",
    },
    {
        class: 4,
        minVal: 17.5,
        maxVal: 50.0,
        result: "Moderada concentración IgE alergeno específico, síntomas clínicos habituales",
    },
    {
        class: 5,
        minVal: 50.0,
        maxVal: 100.0,
        result: "Alta concentración IgE alergeno específico, con síntomas clínicos frecuentes",
    },
    {
        class: 6,
        minVal: 100.0,
        maxVal: 99999,
        result: "Alta concentración IgE alergeno específico, con síntomas clínicos frecuentes",
    },
];

export const reportTypes = [
    {
        id: 1,
        name: "Informe preliminar",
        shortName: "Preliminar",
    },
    {
        id: 2,
        name: "Informe final",
        shortName: "Final",
    },
    {
        id: 3,
        name: "Informe complementario",
        shortName: "Complementario",
    },
];