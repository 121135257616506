import { format } from "rut.js";

export const paxhelpersmixin = {
    methods: {
        formatId(paxmeta) {
            let lid = paxmeta.identificador;
            if (paxmeta.tipoIdentificador === "RUT") {
                lid = format(paxmeta.identificador);
            }
            return lid;
        },

        formatIdPostMigration(paxmeta) {
            let lid = paxmeta.document_number;
            if (paxmeta.document_type === 1) {
                lid = format(paxmeta.document_number);
            }
            return lid;
        },

        suage(fechanax) {
            let momento = this.$moment(fechanax, "DD/MM/YYYY");
            let anos = this.$moment().diff(momento, "years");

            if (anos >= 2) {
                return anos + " años";
            } else {
                const now = this.$moment();
                const diffDuration = this.$moment.duration(now.diff(momento));

                const years = diffDuration.years();
                var months = diffDuration.months();
                var days = diffDuration.days();

                var finalDate = "";

                if (years) {
                    months = months + years * 12;
                }

                if (months) {
                    finalDate = finalDate + `${months} meses `;
                }

                if (days) {
                    finalDate = finalDate + `${days} días`;
                }

                return finalDate;
            }
        },
        suageEtiquetas(fechanax, format="DD/MM/YYYY") {
            let momento = this.$moment(fechanax, format);
            let anos = this.$moment().diff(momento, "years");

            if (anos >= 2) {
                return anos + " a";
            } else {
                const now = this.$moment();
                const diffDuration = this.$moment.duration(now.diff(momento));

                const years = diffDuration.years();
                var months = diffDuration.months();
                var days = diffDuration.days();

                var finalDate = "";

                if (years) {
                    months = months + years * 12;
                }

                if (months) {
                    finalDate = finalDate + `${months} m `;
                }

                if (days) {
                    finalDate = finalDate + `${days} d`;
                }

                return finalDate;
            }
        },
        lastNamesAndNames(unpax) {
            if (!unpax.primerApellido && !unpax.nombres) {
                return "Paciente no seleccionado";
            } else {
                return (unpax.primerApellido || " ") + (unpax.segundoApellido ? " " + unpax.segundoApellido + ", " : " ") + unpax.nombres;
            }
        },

        lastNamesAndNamesPostMigration(unpax) {
            if (!unpax.lastname_f && !unpax.name) {
                return "Paciente no seleccionado";
            } else {
                return (unpax.lastname_f || " ") + (unpax.lastname_m ? " " + unpax.lastname_m + ", " : " ") + unpax.name;
            }
        },

        formatPatientId(patient) {
            let formated = patient.document_number;

            if (patient.document_type === 1) {
                formated = format(patient.document_number);
            }

            return formated;
        },

        formatPatientName(patient) {
            return (patient.lastname_f || " ") + (patient.lastname_m ? " " + patient.lastname_m + ", " : " ") + patient.name;
        },
    },
};
