<template>
    <div v-if="!previewMode">
        <block-toolbar :block="computedBlockInfo('processing-method-block')"></block-toolbar>

        <f7-block class="no-margin-vertical">
            <f7-row>
                <f7-col>
                    <label><b>Seleccione el exámen</b></label>
                    <f7-input type="select" @change="handleSelectedExam" class="margin-bottom" :disabled="disableInputs">
                        <option value="">Seleccione exámen...</option>
                        <option v-for="exam in examsAgrupadores" :key="exam.id" :value="exam.id" :selected="exam.id === defaults.examId">
                            {{ exam.name }}
                        </option>
                    </f7-input>
                </f7-col>
                <f7-col>
                    <div v-if="!selectedExam">
                        <p class="text-color-gray">Debe seleccionar un exámen</p>
                    </div>
                    <div v-else-if="selectedExam && !processingMethods.length === 0">
                        <p class="text-color-red"><b>Este exámen no tiene métodos de procesamiento</b></p>
                    </div>
                    <template v-else>
                        <label><b>Seleccione el método de procesamiento</b></label>
                        <f7-input type="select" @change="handleProcessingMethodChanged" :disabled="disableInputs">
                            <option value="">Seleccione método de procesamiento...</option>
                            <option
                                v-for="processingMethod in processingMethods"
                                :key="processingMethod.id"
                                :value="processingMethod.id"
                                :selected="processingMethod.id === processingMethodId"
                            >
                                {{ processingMethod.name }}
                            </option>
                        </f7-input>
                    </template>
                </f7-col>
            </f7-row>
        </f7-block>
    </div>
    <div v-else>
        <div v-if="selectedExam">
            <div v-if="translation"><i>Method</i></div>
            <div>
                <b>Método</b>:
                {{ processingMethodId ? getProcessingMethodName(processingMethodId) : "Sin  método de procesamiento seleccionado" }}
            </div>
        </div>
        <div v-else>Sin examen seleccionado</div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import BlockToolbarMixin from "@/app/components-migration/plantillas/blocks/toolbar/block-toolbar-mixin";
import BlockToolbar from "@/app/components-migration/plantillas/blocks/toolbar/block-toolbar.vue";

export default {
    mixins: [BlockToolbarMixin],

    props: {
        defaults: Object,
        translation: Boolean,
        previewMode: Boolean,
        examsAgrupadores: Array,
        disableInputs: Boolean,
    },

    components: {
        "block-toolbar": BlockToolbar,
    },

    data() {
        return {
            selectedExam: null,

            processingMethodId: "",
        };
    },

    watch: {
        defaults: {
            immediate: true,
            deep: true,
            handler() {
                this.setDefaultsToBlock();
            },
        },

        examsAgrupadores() {
            if (this.examsAgrupadores.length === 0) {
                this.selectedExam = null;

                this.$emit("processing-method-exam-selected", {
                    examId: "",
                });
                this.$emit("processing-method-method-selected", { processingMethodId: "" });
            }
        },

        viewingPlantilla: {
            handler: function(plantilla) {
                if (plantilla) this.setDefaultsToBlock();
            },
        },
    },

    computed: {
        ...mapState("plantillas", ["viewingPlantilla"]),

        processingMethods() {
            if (!this.selectedExam) return [];

            if (!this.selectedExam.exam_processing_methods) return [];

            return this.selectedExam.exam_processing_methods;
        },
    },

    methods: {
        setDefaultsToBlock() {
            if (this.defaults.examId) {
                const exam = this.examsAgrupadores.find((e) => e.id === this.defaults.examId);

                this.selectedExam = exam;
            }

            this.processingMethodId = this.defaults.processingMethodId || "";
        },
        handleSelectedExam(event) {
            const examId = Number(event.target.value);

            const exam = this.examsAgrupadores.find((e) => e.id === examId);

            this.selectedExam = exam;

            this.$emit("processing-method-exam-selected", {
                examId: this.selectedExam ? this.selectedExam.id : "",
            });
        },
        handleProcessingMethodChanged(event) {
            const processingMethodId = Number(event.target.value);

            this.processingMethodId = processingMethodId;

            this.$emit("processing-method-method-selected", {
                processingMethodId,
            });
        },
        getProcessingMethodName(processingMethodId) {
            if (!this.selectedExam) return "";

            if (!this.selectedExam.exam_processing_methods) return "";

            const processingMethod = this.selectedExam.exam_processing_methods.find((epm) => epm.id === processingMethodId);

            return processingMethod ? processingMethod.name : "";
        },
    },
};
</script>
