<template>
    <div>
        <f7-button outline raised class="margin-right" @click="handleClick('translations')" :disabled="locked">
            <f7-icon class="margin-right-half" material="translate"></f7-icon>
            Traducciones: {{ plantilla.options.translations ? "Si" : "No" }}
        </f7-button>
        <f7-button outline raised class="margin-right" @click="handleClick('preview-mode')">
            <f7-icon class="margin-right-half" material="visibility"></f7-icon>
            Vista previa: {{ plantilla.options.previewMode ? "Si" : "No" }}
        </f7-button>
        <f7-button v-if="isEditor" fill color="blue" raised class="margin-right" @click="handleClick('save')" :disabled="plantilla.isUsed">
            <f7-icon class="margin-right-half" material="save"></f7-icon>
            Guardar
        </f7-button>
        <f7-button fill raised class="margin-right" @click="handleClick('view-options')">
            <f7-icon class="margin-right-half" material="settings"></f7-icon>
            Ver opciones
        </f7-button>
    </div>
</template>

<script>
export default {
    props: {
        isEditor: Boolean,
        plantilla: Object,
        locked: Boolean,
    },

    methods: {
        handleClick(button) {
            this.$emit("button-clicked", { buttonClicked: button });
        },
    },
};
</script>
