<template>
    <f7-page name="unit-details">
        <f7-navbar title="Detalles unidad de medida" back-link back-link-url="/units/" back-link-force></f7-navbar>
        <f7-block-title v-if="!viewingUnit || !viewingUnit.id">
            Seleccione alguna unidad de medida
        </f7-block-title>

        <f7-block v-else>
            <f7-card>
                <f7-card-header>
                    <f7-block-title medium>{{ viewingUnit.name }}</f7-block-title>
                </f7-card-header>
                <f7-card-footer>
                    <f7-button fill small round icon-material="edit" @click="goToEdit()">
                        Editar
                    </f7-button>
                </f7-card-footer>
            </f7-card>
        </f7-block>
    </f7-page>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {};
    },

    computed: {
        ...mapState(["profile_userInfo"]),

        ...mapState("unit", ["viewingUnit"]),
    },

    methods: {
        goToEdit() {
            this.$f7.views.main.router.navigate("/migration/mantenedores/units/create-or-edit");
        },
    },
};
</script>
