import blocksJson from "@/app/data/blocks.json";

export default {
    computed: {
        computedBlockInfo: (state) => (blockCode) => {

            const block = blocksJson.find((b) => b.code === blockCode);

            return {
                ...block,
                isLocked: state.disableInputs,
            };
        },
    },
};
