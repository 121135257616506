<template>
    <f7-page >
        <f7-navbar title="Imagen del insumo">
            <f7-nav-right>
                <f7-link popup-close>Cerrar</f7-link>
            </f7-nav-right>
        </f7-navbar>
            <f7-block>
                <f7-row class="mb-2">
                    <f7-col width="100" class="padding-1"  style="text-align: center">
                        <label>Imagen del insumo</label>
                        <f7-list no-hairlines-md> 
                        <f7-list-input name="fileInput" accept="image/png, image/gif, image/jpeg"  type="file" @change="imageDefine($event.target.files[0])"/>
                        </f7-list>
                        <img :src="image" style="max-width:100%,border:1px solid #fff; border-radius: 5px; padding: 2%; max-width : 100%;margin:auto">
                    </f7-col>
                </f7-row>
                <f7-row class="mb-2">
                    <f7-col  >
                        
                        <f7-button btn popup-close fill>{{ button }}</f7-button>
                    </f7-col>
                </f7-row>
                <hr>
                <f7-row class="mb-2">
                    <f7-col width="100" >
                        <f7-button  outline @click="clean()" >Limpiar Imagen</f7-button>
                    </f7-col>
                </f7-row>
            </f7-block>
    </f7-page>
</template>

<script>
export default {
    data(){
        return {
            image : "",
        }
    },
    props :{
        rebootImage : { required : false }
    },
    computed : {
        button(){
            if(this.image == ""){
                return "Sin imagen asignada"
            }else{
                return "Imagen asignada"
            }
        }
    },
    watch: {
        rebootImage : {
            handler() {
                this.clean()
            }
        }
    },
    methods : {
        imageDefine(file){
            if (file) {
                this.image = URL.createObjectURL(file);
                this.$emit('save',file);
            }
        },
        clean(){
            this.image = "";
            var x = document.getElementsByName('fileInput');
            for(const item of x){
                item.value = ""
            }
            this.$emit('fileUrl', '')
        },
        async submit(){
            if(this.selectedFile !== false){ 
                let url = await this.$store.dispatch('uploadFile', {app : this, path : 'images/supply/', file : this.file, alert : true}) 
                console.log(url)
                this.$firebase.auth().currentUser.getIdToken().then(async (token) => {
                    var result = await this.$store.dispatch(
                    "apiRequest",
                        { url : "supply/" ,token , method : "PUT", data : {image : url, id : this.idSupply}, request : {afterOk : 'Imagen actualizada'}, app : this}
                    );

                    this.$emit('fileUrl', url)
                    this.$f7.popup.close('.image');
                });
            }
        }
    }
}
</script>

<style>

</style>