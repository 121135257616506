<template>
    <f7-page name="admission-index">
        <f7-navbar title="Admisión">
            <f7-nav-right>
                <template>
                    <div
                        v-if="source === sources.OT_SEARCH && Object.keys(updates).length > 0"
                        class="margin display-flex align-items-center text-color-red"
                    >
                        <f7-icon material="error" class="margin-right-half" size="22"></f7-icon>
                        <span style="font-size: 16px;">Hay cambios sin guardar.</span>
                    </div>

                    <f7-button outline class="margin-right" color="blue" @click="clear">
                        Limpiar
                    </f7-button>
                </template>
            </f7-nav-right>
        </f7-navbar>

        <f7-block class="no-margin no-padding">
            <!-- ## DATOS DEL PACIENTE  ## -->
            <f7-card outline>
                <f7-card-content class="no-padding-top">
                    <patient-form
                        :source="source"
                        :a-patient="admission.patient"
                        @patient-searched="handlePatientSearched"
                        @patient-updated="handlePatientUpdated"
                        :reset="resetAdmission"
                    >
                        <h2>Datos del paciente</h2>
                    </patient-form>
                </f7-card-content>
            </f7-card>

            <!-- ## DATOS DE LA ADMISION ## -->
            <f7-card outline>
                <f7-card-content class="no-padding-top">
                    <h2>Datos de la admisión</h2>

                    <!-- Prioridad -->
                    <f7-row class="margin-bottom">
                        <f7-col width="20">
                            <div class="display-flex justify-content-space-between">
                                <div class="margin-right">
                                    <label>Normal</label>
                                    <f7-radio
                                        name="priority"
                                        @change="changeAdmissionPriority('normal')"
                                        :checked="admission.priority === 'normal'"
                                    ></f7-radio>
                                </div>
                                <div class="margin-right">
                                    <label>Urgente</label>
                                    <f7-radio
                                        name="priority"
                                        @change="changeAdmissionPriority('urgente')"
                                        :checked="admission.priority === 'urgente'"
                                    ></f7-radio>
                                </div>
                                <div class="margin-right">
                                    <label>STAT</label>
                                    <f7-radio
                                        name="priority"
                                        @change="changeAdmissionPriority('stat')"
                                        :checked="admission.priority === 'stat'"
                                    ></f7-radio>
                                </div>
                            </div>
                        </f7-col>

                        <!-- Procedencia -->
                        <f7-col width="40">
                            <label>Procedencia</label>
                            <f7-input
                                type="select"
                                @change="handleInstitutionChange"
                                style="min-width: 300px"
                            >
                                <option value="">Seleccione...</option>
                                <option
                                    v-for="institution in institutions"
                                    :key="institution.id"
                                    :value="institution.id"
                                    :selected="admission.institution_id === institution.id"
                                >
                                    {{ institution.name }}
                                </option>
                            </f7-input>
                        </f7-col>

                        <!-- convenio -->
                        <f7-col width="40">
                            <template v-if="agreements">
                                <label>Convenio</label>
                                <f7-input
                                    type="select"
                                    @change="handleAgreementChange"
                                    style="min-width: 300px"
                                >
                                    <option value="">Seleccione...</option>
                                    <option
                                        v-for="(agreement, index) in Object.keys(agreements)"
                                        :key="index"
                                        :value="agreement"
                                        :selected="admission.agreement === agreement"
                                    >
                                        {{ agreement }}
                                    </option>
                                </f7-input>
                            </template>
                            <div v-else>Esta institucion no tiene convenios registrados.</div>
                        </f7-col>
                    </f7-row>
                    <f7-row class="margin-bottom">
                        <f7-col>
                            <label>Diagnostico</label>
                            <f7-input
                                type="text"
                                @input="admission.diagnosticObs = $event.target.value"
                                :value="admission.diagnosticObs"
                            ></f7-input>
                        </f7-col>
                    </f7-row>

                    <h4>Médico solicitante</h4>
                    <f7-row class="margin-bottom">
                        <f7-col>
                            <label>RUT</label>
                            <f7-input
                                type="text"
                                @input="admission.doctor.rut = $event.target.value"
                                :value="admission.doctor.rut"
                                @keyup.native.enter="searchMedicoSolicitante"
                                info="Presione ENTER para buscar"
                            ></f7-input>
                        </f7-col>
                        <f7-col>
                            <label>Nombre</label>
                            <f7-input
                                type="text"
                                @input="admission.doctor.name = $event.target.value"
                                :value="admission.doctor.name"
                            ></f7-input>
                        </f7-col>
                        <f7-col>
                            <label>Contacto #1</label>
                            <f7-input
                                type="text"
                                @input="admission.doctor.contacto1 = $event.target.value"
                                :value="admission.doctor.contacto1"
                            ></f7-input>
                        </f7-col>
                        <f7-col>
                            <label>Contacto #2</label>
                            <f7-input
                                type="text"
                                @input="admission.doctor.contacto2 = $event.target.value"
                                :value="admission.doctor.contacto2"
                            ></f7-input>
                        </f7-col>
                    </f7-row>
                </f7-card-content>
            </f7-card>

            <!-- Examenes -->
            <f7-card outline>
                <f7-card-content class="no-padding-top">
                    <div class="display-flex justify-content-space-between">
                        <h2>Exámenes</h2>
                        <f7-button
                            v-if="source === sources.OT_SEARCH"
                            @click="generateOTpdf(otFound.code)"
                            class="margin-vertical"
                            fill
                        >
                            Imprimir OT
                        </f7-button>
                    </div>

                    <ot-search @data-found="handleOTfound" :reset="resetAdmission">
                        <h4>Busqueda de OT</h4>
                    </ot-search>

                    <h4>{{ examsSectionTitle }}</h4>
                    <exams-form
                        :source="source"
                        @exam-added="handleExamAdded"
                        @exam-removed="handleExamRemoved"
                        @sample-added="handleSampleAdded"
                        @sample-deleted="handleSampleDeleted"
                        @exam-log-added="handleExamLogAdded"
                        :exams="examsBySource"
                        :ot-section="otFound.sectionId"
                        :reset="resetAdmission"
                        :saved="saved"
                    ></exams-form>

                    <div v-if="source === sources.OT_SEARCH">
                        <h4>Observaciones de la OT</h4>
                        <f7-input type="text" @input="otObsChanged" :value="otFound.observations"></f7-input>
                    </div>
                </f7-card-content>
            </f7-card>

            <div class="margin-vertical margin-horizontal-half">
                <f7-button
                    fill
                    class="margin-right"
                    color="primary"
                    @click="save"
                    v-if="source === sources.CREATE_ADMISSION || misprivx.modify_ot"
                    :disabled="saveButtonIsDisabled"
                >
                    {{ saveButtonText }}
                </f7-button>
            </div>
        </f7-block>

        <f7-popup class="ots-obs-popup" :close-by-backdrop-click="false">
            <f7-page>
                <f7-navbar title="Añadir observaciones"></f7-navbar>

                <f7-block>
                    <div class="margin-bottom">
                        <p>Paciente: {{ patientName(createdData.admission.patient) }}</p>
                        <p>{{ patientIdentificador(createdData.admission.patient) }}</p>
                    </div>

                    <f7-card outline class="margin-bottom" v-for="ot in createdData.ots" :key="ot.code">
                        <f7-card-content>
                            <p>
                                Observaciones OT
                                <b>{{ ot.code }}</b>
                            </p>
                            <ul>
                                <li v-for="exam in ot.exams" :key="exam.id">{{ exam.name }}</li>
                            </ul>

                            <f7-input
                                outline
                                placeholder="Escriba aqui las observaciones"
                                type="textarea"
                                @input="observacionChanged($event, ot.code)"
                            ></f7-input>

                            <ot-pdf
                                :id="ot.code"
                                style="display:none"
                                :admission="createdData.admission"
                                :ot="createdData.ots[ot.code]"
                            ></ot-pdf>

                            <f7-button @click="generateOTpdf(ot.code)">imprimir</f7-button>
                        </f7-card-content>
                    </f7-card>

                    <f7-button fill @click="saveObservaciones">Guardar</f7-button>
                </f7-block>
            </f7-page>
        </f7-popup>

        <ot-pdf
            :id="otFound.code"
            v-if="source === sources.OT_SEARCH"
            style="display:none"
            :admission="admission"
            :ot="otFound"
            :ot-exams="otExams"
        ></ot-pdf>
    </f7-page>
</template>

<script>
import { _sources } from "@/app/constants";

import PatientForm from "@/app/components/patient-form";
import ExamsForm from "./components/exams-form";
import OTSearch from "@/app/components/ot-search.vue";
import OTpdf from "./components/ot-pdf.vue";

import { validate, clean } from "rut.js";
import { _formatName } from "@/app/helpers";

import { mapActions, mapState } from "vuex";

import { jsPDF } from "jspdf";
import Canvg from "canvg";

export default {
    components: {
        "patient-form": PatientForm,
        "exams-form": ExamsForm,
        "ot-search": OTSearch,
        "ot-pdf": OTpdf,
    },

    data() {
        return {
            sources: _sources, // para poder ocuparlo en el template
            source: _sources.CREATE_ADMISSION,

            resetAdmission: false,

            admission: {
                id: null,
                patientId: "",
                patient: "", // raro esto pero puede romper algo si se cambia
                creationDate: "",
                opened: true,
                username: "",
                agreement: "",
                doctor: {
                    rut: "",
                    name: "",
                    contacto1: "",
                    contacto2: "",
                },

                exams: {},

                institution_id: "",
                institution: "",

                diagnosticObs: "",
                background: "",

                priority: "normal",
            },

            institutions: {},
            agreements: {},

            patientIsSelected: false,

            otFound: {},

            updates: {},

            // todos los examenes de /admExams asociados a la admision
            allAdmExams: {},

            saved: false,

            invalidReasons: [],

            createdData: {
                admission: {},
                ots: {},
            },
            observaciones: {},
        };
    },

    computed: {
        ...mapState(["profile_userInfo", "env", "misprivx"]),

        saveButtonText() {
            switch (this.source) {
                case _sources.CREATE_ADMISSION:
                    return "Grabar";
                case _sources.OT_SEARCH:
                    return "Guardar cambios";
                default:
                    return "";
            }
        },

        saveButtonIsDisabled() {
            return this.source === _sources.OT_SEARCH && Object.keys(this.updates).length === 0;
        },

        examsSectionTitle() {
            if (this.source === _sources.CREATE_ADMISSION) {
                return "Añadiendo exámenes a la admisión";
            } else if (this.source === _sources.OT_SEARCH) {
                return "Exámenes de la OT";
            }
        },

        admissionIsValid() {
            // procedencia y convenio no obligatorio?

            this.invalidReasons = [];
            let isValid = true;

            if (!this.patientIsSelected) {
                this.invalidReasons.push("Debe seleccionar un paciente");
                isValid = false;
            }

            if (!this.admission.priority.length) {
                this.invalidReasons.push("Debe establecer la prioridad");
                isValid = false;
            }

            const doctor = this.admission.doctor;

            if (doctor.rut && !validate(doctor.rut)) {
                this.invalidReasons.push("El RUT del medico solicitante es incorrecto");
                isValid = false;
            }

            if (doctor.rut && !doctor.name) {
                this.invalidReasons.push("Debe ingresar el nombre del médico solicitante");
                isValid = false;
            }

            if (Object.keys(this.admission.exams || {}).length === 0) {
                this.invalidReasons.push("Debe haber por lo menos un exámen añadido");
                isValid = false;
            }

            return isValid;
        },

        invalidReasonsList() {
            let list = "<ul>";

            this.invalidReasons.forEach((reason) => {
                list += `<li>${reason}</li>`;
            });

            list += "</ul>";

            return list;
        },

        // examenes del nodo /admExams segun viewingOT
        admExams() {
            const exams = {};

            Object.values(this.allAdmExams)
                .filter((admExam) => admExam.ot === this.otFound.code)
                .forEach((ex) => {
                    exams[ex.admExamId] = ex;
                });

            return exams;
        },

        // esta data se la pasamos al componente exams-form.
        // Aca siempre hay problemas por que pasamos examenes formateados de distinta manera
        // admission.exams y admExams tienen un formato distinto
        // Refactorizar para que exams-form reciba siempre examenes con formato de /admExams?
        otExams() {
            const exams = {};

            const admExams = Object.values(this.admExams).filter(
                (admExam) => admExam.ot === this.otFound.code
            );

            admExams.forEach((admExam) => {
                exams[admExam.examId] = this.admission.exams[admExam.examId];
            });

            return exams;
        },

        examsBySource() {
            if (this.source === _sources.CREATE_ADMISSION) {
                return this.admission.exams;
            } else if (this.source === _sources.OT_SEARCH) {
                return this.otExams;
            }

            return {};
        },
    },

    watch: {
        "admission.doctor": {
            deep: true,
            handler() {
                if (this.source === _sources.OT_SEARCH) {
                    const { id, doctor } = this.admission;
                    this.$set(this.updates, `/admissions/${id}/doctor`, doctor);
                }
            },
        },

        "admission.institution_id": function(institutionId) {
            if (institutionId) {
                this.agreements = this.institutions[institutionId].agreements;
            } else {
                this.agreements = {};
            }

            if (this.source === _sources.OT_SEARCH) {
                const { id, institution_id, institution } = this.admission;

                this.$set(this.updates, `/admissions/${id}/institution_id`, institution_id);
                this.$set(this.updates, `/admissions/${id}/institution`, institution);

                console.log("updates institution", this.updates);
            }
        },

        "admission.agreement": function() {
            if (this.source === _sources.OT_SEARCH) {
                const { id, agreement } = this.admission;

                this.$set(this.updates, `/admissions/${id}/agreement`, agreement);
            }
        },

        "admission.priority": function() {
            if (this.source === _sources.OT_SEARCH) {
                const { id, priority } = this.admission;

                this.$set(this.updates, `/admissions/${id}/priority`, priority);

                Object.keys(this.allAdmExams).forEach((admExamId) => {
                    this.$set(this.updates, `/ots/${this.allAdmExams[admExamId].ot}/priority`, priority);
                });
            }
        },

        "admission.diagnosticObs": function() {
            if (this.source === _sources.OT_SEARCH) {
                const { id, diagnosticObs } = this.admission;

                this.$set(this.updates, `/admissions/${id}/diagnosticObs`, diagnosticObs);
            }
        },

        otExams: {
            deep: true,
            handler() {
                if (this.source === _sources.OT_SEARCH) {
                    this.$set(this.updates, `/ots/${this.otFound.code}/exams`, this.otExams);
                }
            },
        },

        "admission.exams": {
            deep: true,
            handler() {
                if (this.source === _sources.OT_SEARCH) {
                    const { id, exams } = this.admission;

                    this.$set(this.updates, `/admissions/${id}/exams`, exams);

                    // bug: al actualizar un examen, se actualizan todos los admExams
                    // quedan en updates sin cambios con la contraparte en la db, al final
                    // no se actualizan pero no deberian estar en updates
                    Object.values(this.allAdmExams).forEach((admExam) => {
                        const exam = exams[admExam.examId];

                        if (exam) {
                            // if exam in admExams
                            const updated = {
                                ...admExam,
                                ...exam, // replace the changes with the modified exam from the admission
                            };

                            this.$set(this.updates, `/admExams/${admExam.admExamId}`, updated);
                        } else {
                            this.$set(this.updates, `/admExams/${admExam.admExamId}`, null);
                            this.$delete(this.allAdmExams, admExam.admExamId);
                        }
                    });

                    // todo: update ot

                    console.log("updates exams", this.updates);
                }
            },
        },

        "admission.patient": {
            deep: true,
            handler() {
                if (this.source === _sources.OT_SEARCH) {
                    const { id, patientId, patient } = this.admission;

                    this.$set(this.updates, `/admissions/${id}/patientId`, patientId);
                    this.$set(this.updates, `/admissions/${id}/patient`, patient);

                    // tenemos que actualizar al paciente en todos los examenes de /admExams que estan
                    // asociados a la admission que estamos viendo
                    Object.values(this.allAdmExams).forEach((admExam) => {
                        this.$set(this.allAdmExams[admExam.admExamId], "patientId", patientId);
                        this.$set(this.allAdmExams[admExam.admExamId], "patient", patient);

                        this.$set(this.updates, `/ots/${admExam.ot}/patientId`, patientId);
                        this.$set(this.updates, `/ots/${admExam.ot}/patient`, patient);

                        this.$set(
                            this.updates,
                            `/admExams/${admExam.admExamId}`,
                            this.allAdmExams[admExam.admExamId]
                        );
                    });

                    console.log("updates patient", this.updates);
                }
            },
        },

        "otFound.observations": function() {
            if (this.source === _sources.OT_SEARCH) {
                this.$set(this.updates, `/ots/${this.otFound.code}/observations`, this.otFound.observations);
            }
        },
    },

    created() {
        this.getInstitutions();
    },

    methods: {
        ...mapActions(["getAPIInfo"]),

        // medico solicitante related
        searchMedicoSolicitante() {
            if (!validate(this.admission.doctor.rut)) {
                this.$f7.dialog.alert("El RUT ingresado no es valido");
                return;
            }

            const rut = clean(this.admission.doctor.rut);

            this.$f7.dialog.preloader("Buscando médico");

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => this.getAPIInfo({ url: "clinicians/search?q=" + rut, userToken: idToken }))
                .then((resp) => {
                    const doctor = resp.data.data[0];

                    this.$f7.dialog.close();
                    this.$f7.toast.show({
                        text: "Médico encontrado",
                        closeTimeout: 2000,
                    });

                    this.admission.doctor.name = _formatName(
                        doctor.name,
                        doctor.lastname_f,
                        doctor.lastname_m
                    );
                })
                .catch((err) => {
                    console.error(err);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("No se encontró médico con RUT ingresado.", "Médico no encontrado");
                });
        },

        otObsChanged(event) {
            this.$set(this.otFound, "observations", event.target.value);
        },
        observacionChanged(event, otCode) {
            const obs = event.target.value;
            this.$set(this.createdData.ots[otCode], "observations", obs);
            this.$set(this.observaciones, otCode, obs);
        },
        saveObservaciones() {
            if (Object.keys(this.observaciones).length === 0) {
                this.$f7.popup.close(".ots-obs-popup");
                return;
            }

            this.$f7.dialog.preloader("Guardando observaciones...");

            const updates = {};
            Object.keys(this.observaciones).forEach((otCode) => {
                updates[`/ots/${otCode}/observations`] = this.observaciones[otCode];
            });

            this.$firebase
                .database()
                .ref()
                .update(updates)
                .then(() => {
                    this.createdData = {
                        admission: {},
                        ots: {},
                    };
                    this.observaciones = {};

                    this.$f7.dialog.close();
                })
                .catch((err) => {
                    console.error(err);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Hubo un error al guardar las observaciones. " + err);
                })
                .finally(() => this.$f7.popup.close(".ots-obs-popup"));
        },

        async generateOTpdf(otCode) {
            if (Object.keys(this.updates).length > 0) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Debe guardar los cambios antes de imprimir la OT");
                return;
            }

            this.$f7.dialog.preloader("Imprimiendo OT");

            const source = window.document.getElementById(otCode);

            source.style.setProperty("display", "block");

            // replace the svg barcode with an Image
            if (document.getElementById(`barcode-${otCode}`)) {
                let barcodeSvg = document.getElementById(`barcode-${otCode}`).firstChild.outerHTML;

                if (barcodeSvg) {
                    barcodeSvg = barcodeSvg.replace(/\r?\n|\r/g, "").trim();
                }

                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                const v = Canvg.fromString(ctx, barcodeSvg);
                await v.render();

                const imgData = canvas.toDataURL("image/png");
                const img = document.createElement("img");
                img.src = imgData;
                document.getElementById(`barcode-${otCode}`).replaceWith(img);
            }

            const doc = new jsPDF("landscape", "pt", "a4");
            doc.html(source, {
                html2canvas: {
                    scale: 0.95,
                    letterRendering: true,
                },
                callback: (doc) => {
                    doc.autoPrint();
                    doc.save(`${otCode}.pdf`);
                    source.style.setProperty("display", "none");
                    this.$f7.dialog.close();
                },
                x: 20,
                y: 10,
            });
        },

        handleExamLogAdded(payload) {
            const { examId, statusLogObj } = payload;

            const key = this.$firebase
                .database()
                .ref()
                .push().key;

            if (!this.admission.exams[examId].statusLog) {
                this.$set(this.admission.exams[examId], "statusLog", {});
            }

            this.$set(this.admission.exams[examId].statusLog, key, statusLogObj);
            this.$set(this.admission.exams[examId], "currentStatus", statusLogObj.status);
        },

        // ot-search handlers
        handleOTfound(data) {
            const { admission, ot } = data;

            this.admission = admission;
            this.patientIsSelected = true;

            if (!this.admission.exams) {
                this.$set(this.admission, "exams", {});
            }
            if (!this.admission.agreement) {
                this.$set(this.admission, "agreement", "");
            }
            if (!this.admission.doctor) {
                this.$set(this.admission, "doctor", {
                    rut: "",
                    name: "",
                    contacto1: "",
                    contacto2: "",
                });
            }

            this.otFound = ot;

            // esto es algun tipo de clear?
            this.updates = {};

            this.$f7.dialog.preloader("Cargando exámenes de la OT...");

            // al buscar una ot, sacamos la admision pero tambien necesitamos las otras ots
            // asociadas a la admision, por si se actualizan datos de la admision (paciente, prioridad, institucion, convenios...)
            this.$firebase
                .database()
                .ref("/admExams")
                .orderByChild("admissionId")
                .equalTo(this.admission.id)
                .once("value")
                .then((snapshot) => {
                    this.allAdmExams = snapshot.val() || {};

                    setTimeout(() => {
                        this.source = _sources.OT_SEARCH;
                    }, 0);

                    this.$f7.dialog.close();
                })
                .catch((err) => {
                    console.error(err);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Hubo un error al fetchear los examenes asociados a la OT." + err);
                });
        },

        // patient-form handlers
        handlePatientSearched(patient) {
            if (patient) {
                this.patientIsSelected = true;
                this.admission.patientId = patient.id;
                this.admission.patient = patient;
            } else {
                this.patientIsSelected = false;
                this.admission.patientId = "";
                this.admission.patient = "";
            }
        },
        handlePatientUpdated(patient) {
            this.admission.patient = patient;
        },

        // exams-form handlers
        handleExamAdded(exam) {
            this.$set(this.admission.exams, exam.id, exam);

            if (this.source === _sources.OT_SEARCH) {
                const admExamsKey = this.$firebase
                    .database()
                    .ref()
                    .push().key;

                // esto esta raro
                this.$set(this.admission.exams[exam.id], "admExamId", admExamsKey);

                this.$set(this.allAdmExams, admExamsKey, {
                    ...this.admission.exams[exam.id],
                    examId: exam.id,
                    admissionId: this.admission.id,
                    patientId: this.admission.patientId,
                    patient: this.admission.patient,
                    ot: this.otFound.code,
                });
            }
        },
        handleExamRemoved(exam) {
            this.$delete(this.admission.exams, exam.id);
        },
        handleSampleAdded(sample) {
            if (this.source === _sources.OT_SEARCH) {
                this.$set(this.updates, `/ots/${this.otFound.code}/samples/${sample.id}`, true);
            }
        },
        handleSampleDeleted(sampleId) {
            if (this.source === _sources.OT_SEARCH) {
                this.$set(this.updates, `/ots/${this.otFound.code}/samples/${sampleId}`, null);
            }
        },

        save() {
            if (this.source === _sources.CREATE_ADMISSION) {
                this.createAdmission();
            } else if (this.source === _sources.OT_SEARCH) {
                this.updateAdmission();
            }
        },

        // admission related methods
        createAdmission() {
            if (!this.admissionIsValid) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(this.invalidReasonsList);
                return;
            }

            this.$f7.dialog.confirm("¿Está seguro?", "Grabar admisión", () => {
                this.$f7.dialog.preloader("Generando admisión...");

                const admissionKey = this.$firebase
                    .database()
                    .ref()
                    .push().key;

                this.admission.id = admissionKey;
                this.admission.username = this.profile_userInfo.displayName;
                this.admission.creationDate = new Date().getTime();

                this.getCreateAdmissionUpdates()
                    .then((updates) => {
                        this.$firebase
                            .database()
                            .ref()
                            .update(updates)
                            .then(() => {
                                this.$f7.dialog.close();

                                this.$f7.popup.open(".ots-obs-popup");

                                this.clear(false);
                                this.saved = !this.saved;
                            })
                            .catch((err) => {
                                console.error(err);
                                this.$f7.dialog.close();
                                this.$f7.dialog.alert("Hubo un error al generar la admision. " + err);
                            });
                    })
                    .catch((err) => {
                        console.error(err);
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(err.message, err.code);
                    });
            });
        },
        updateAdmission() {
            if (!this.admissionIsValid) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(this.invalidReasonsList);
                return;
            }

            this.$f7.dialog.confirm("¿Está seguro de guardar los cambios?", () => {
                this.$firebase
                    .database()
                    .ref()
                    .update(this.updates)
                    .then(() => {
                        this.$f7.dialog.alert("Datos actualizados correctamente");
                        this.saved = !this.saved;

                        this.updates = {};
                    })
                    .catch((error) => {
                        console.error(error);
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(
                            "Hubo un error al actualizar la OT y su admisión asociada. " + error
                        );
                    });
            });
        },
        getCreateAdmissionUpdates() {
            const exams = Object.values(this.admission.exams || {});
            const grouped = this.groupBy(exams, "section_id");

            // todo: rename -> generateOTCodes
            const generateOts = this.$firebase.functions().httpsCallable("generateOts");

            return generateOts({
                env: this.env,
                priority: this.admission.priority,
                quantity: grouped.length,
            }).then((res) => {
                const codes = res.data;

                console.log("codigos generados desde la cloud function", codes);

                // añadir al nuevo nodo /admExams
                const admExamsUpdates = {};

                const otsUpdates = {};

                const ots = {};

                // a cada grupo de examenes asignar un código de OT
                grouped.forEach((exams, index) => {
                    const code = codes[index];

                    ots[code] = {
                        code,
                        exams: {},
                        admissionId: this.admission.id,
                        priority: this.admission.priority,
                        patientId: this.admission.patientId,
                        patient: this.admission.patient,
                        sectionId: exams[0].section_id, // mmm
                        createdAt: (Date.now() / 1000) | 0,
                        samples: {},
                    };

                    exams.forEach((exam) => {
                        ots[code].exams[exam.id] = exam;

                        // añadir las muestras para poder buscar OT por cod de muestra
                        Object.keys(exam.samples || {}).forEach((sampleId) => {
                            ots[code].samples[sampleId] = true;
                        });

                        const admExamsKey = this.$firebase
                            .database()
                            .ref()
                            .push().key;

                        this.$set(this.admission.exams[exam.id], "admExamId", admExamsKey);

                        admExamsUpdates[`/admExams/${admExamsKey}`] = {
                            ...this.admission.exams[exam.id],
                            examId: exam.id,
                            admissionId: this.admission.id,
                            patientId: this.admission.patientId,
                            patient: this.admission.patient,
                            ot: code,
                        };
                    });

                    otsUpdates[`/ots/${code}`] = ots[code];

                    this.$set(this.createdData.ots, code, ots[code]);
                });

                // write /admissions node
                const admissionUpdates = {};
                admissionUpdates[`/admissions/${this.admission.id}`] = this.admission;

                this.$set(this.createdData, "admission", this.admission);

                return {
                    ...admissionUpdates,
                    ...admExamsUpdates,
                    ...otsUpdates,
                };
            });
        },
        changeAdmissionPriority(priority) {
            this.admission.priority = priority;
        },
        resetLocalAdmission() {
            this.resetAdmission = !this.resetAdmission;

            this.admission = {
                id: null,
                patientId: "",
                patient: "", // raro esto pero puede romper algo si se cambia
                creationDate: "",
                opened: true,
                username: "",
                agreement: "",

                doctor: {
                    rut: "",
                    name: "",
                    contacto1: "",
                    contacto2: "",
                },

                exams: {},

                institution_id: "",
                institution: "",

                diagnosticObs: "",
                background: "",

                priority: "normal",
            };
        },

        // procedencia, convenio handlers
        getInstitutions() {
            this.$firebase
                .database()
                .ref("institutions/")
                .orderByChild("enabled")
                .equalTo(true)
                .once("value")
                .then((snapshot) => {
                    this.institutions = snapshot.val() || {};
                })
                .catch((err) => {
                    this.$f7.dialog.alert(err.message, err.code);
                });
        },
        handleInstitutionChange(ev) {
            console.log("institution change", ev.target.value);

            const institutionId = ev.target.value;

            if (!institutionId.length) {
                this.admission.institution_id = "";
                this.admission.institution = "";
                this.agreements = {};
                return;
            }

            const { id, name, agreements } = this.institutions[institutionId];

            this.admission.institution_id = id;
            this.admission.institution = name;
            this.admission.agreement = "";

            this.agreements = agreements;
        },
        handleAgreementChange(ev) {
            const agreement = ev.target.value;
            this.$set(this.admission, "agreement", agreement);
        },

        clear(confirm = true) {
            const _clear = () => {
                this.source = _sources.CREATE_ADMISSION;

                this.allAdmExams = {};

                this.resetLocalAdmission();

                this.updates = {};

                this.otFound = {};
            };

            if (confirm) {
                this.$f7.dialog.confirm("¿Está seguro?", () => {
                    _clear();
                });
            } else {
                _clear();
            }
        },

        // helpers
        groupBy(collection, property) {
            var i = 0,
                val,
                index,
                values = [],
                result = [];
            for (; i < collection.length; i++) {
                val = collection[i][property];
                index = values.indexOf(val);
                if (index > -1) result[index].push(collection[i]);
                else {
                    values.push(val);
                    result.push([collection[i]]);
                }
            }
            return result;
        },

        patientName(patient) {
            if (!patient) return "";

            return `${patient.nombres} ${patient.primerApellido} ${patient.segundoApellido}`;
        },
        patientIdentificador(patient) {
            if (!patient) return "";
            const tipoIdentificador = patient.tipoIdentificador;
            return `${tipoIdentificador}: ${patient.identificador}`;
        },
    },
};
</script>
