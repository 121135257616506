<template>
    <f7-page name="sampleDetails" @page:beforein="updateDetails">
        <f7-navbar 
            title="Detalles de muestra" 
            back-link
            back-link-url="/samples/" 
            back-link-force
        >
        </f7-navbar>
        <f7-block-title v-if="!selectedSample.name">
            Seleccione alguna muestra
        </f7-block-title>
      
        <f7-block v-else>
        <f7-card class="demo-card-header-pic">
            <f7-card-header>
                <f7-block-title medium> {{selectedSample.name}} </f7-block-title>
            </f7-card-header>
            <f7-card-content>
                <f7-row>
                    <f7-col width="35">
                        <p><span class="label">Código SNOMED CT:</span></p>
                    </f7-col>
                    <f7-col width="65">
                        <p>{{selectedSample.snomed_code}}</p>
                    </f7-col>
                </f7-row>
                <f7-row :key="isReloading">
                    <f7-col width="35">
                        <p><span class="label">Motivos de rechazo:</span></p>
                    </f7-col>
                    <f7-col width="65" v-if="isLoading">
                        <f7-preloader color="teal"></f7-preloader>
                    </f7-col>
                    <f7-col width="65" v-else>
                        <p v-if="noRejections">No hay rechazos asociados a la muestra</p>
                        <p v-for="(rejection, index) in rejections" :key="index">
                            {{rejection.reason}}
                        </p>
                    </f7-col>
                </f7-row>
                <p>{{selectedSample.description}}</p>
            </f7-card-content>
            <f7-card-footer>
                <f7-button 
                    fill 
                    small 
                    round 
                    icon-material="edit" 
                    @click="goToEdit"
                    >Editar
                </f7-button>
                <f7-button 
                    fill 
                    small 
                    round 
                    icon-material="delete" 
                    color="red"
                    @click="deleteSample"
                    >Eliminar
                </f7-button>
            </f7-card-footer>
        </f7-card>
        </f7-block>

    </f7-page>    
</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {
    data(){
        return {
            rejections: [],
            isLoading: true,
            noRejections: true,
            isReloading: false
        }
    },

    computed: {
        ...mapState(['selectedSample', 'profile_userInfo'])
    },

    methods: {
        ...mapActions(['getAPIInfo', 'updateInfo', 'baseUrl']),

        goToEdit(){
            this.$store.commit('setWhatTo', {what: 'sampleRejections', to: this.rejections});
            this.$f7.views.main.router.navigate('/new-sample/');
        },

        getRejections(){
            this.$firebase.auth().currentUser.getIdToken()
            .then(idToken => this.getAPIInfo({url: `samples/${this.selectedSample.id}/rejections`, userToken: idToken}))
            .then(resp => {
                this.rejections = resp.data.data;
                this.isLoading = false;
                this.noRejections = false;
            })
            .catch((err) => {
                this.isLoading = false;
                if(err.response.status !== 404){
                    this.$f7.dialog.alert(err.response.data.message || err.message, 'Ops, ha ocurrido un error');
                }
                else{
                    this.noRejections = true;
                }
            })
        },

        updateDetails(){
            this.getRejections();
            this.isReloading = !this.isReloading;
        },

        deleteSample(){
            this.$f7.dialog.confirm('¿Está seguro que desea eliminar la muestra?', 'Eliminar', () => {
                let {id, createdAt, updatedAt, ...editedSample} = this.selectedSample;
                editedSample.enabled = 0;
                this.$firebase.auth().currentUser.getIdToken()
                .then((idToken) => {
                    return this.updateInfo({url: 'samples/' + id, data: editedSample, userToken: idToken})
                })
                .then(() => {
                    this.$store.commit('removeFromArray', {array: 'allSamples', attr: 'id', value: id});
                    //Se agrega información al nodo log
                    let logInfo = {
                        who: this.profile_userInfo.uid,
                        wht: 'delete-sample',
                        whn: this.$moment().unix(),    
                        dls: 'Elimina tipo de muestra',
                        pth: this.baseUrl + 'samples/' + id
                    }
                    let logId = this.$firebase.database().ref('/log').push().key;
                    this.$firebase.database().ref('log/' + logId).set(logInfo)
                        .catch((err)=>{console.log(err.message)})
                    this.$f7.toast.create({text: 'El examen se ha eliminado', closeTimeout: 2000}).open();
                    this.$store.commit('setWhatTo', {what: 'selectedSample', to: {}});
                })
                .catch((err) => {
                    this.$f7.dialog.alert(err.response.data.message || err.message, 'Ops, ha ocurrido un error')
                })
            })
        }
    },
}
</script>

<style scoped>

.label{
    color: #8e8e93;
}

</style>