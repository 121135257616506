<template>
    <div>
        <f7-button
            @click="changeExamState(state)"
            :icon-material="icon"
            :tooltip="tooltip"
            :disabled="exam.currentStatus === reasonsType"
            :fill="fill"
            :color="color"
        >
            <slot></slot>
        </f7-button>

        <reasons-popup
            :opened="showReasonsPopup"
            :type="reasonsType"
            :mandatory="isReasonMandatory"
            @popup-closed="showReasonsPopup = false"
            @reason-selected="handleReasonSelected"
            :use-default="useDefaultReason"
        ></reasons-popup>
    </div>
</template>

<script>
import { mapState } from "vuex";

import ReasonsPopup from "@/app/admission/components/reasons-popup.vue";

export default {
    props: {
        state: String,
        exam: Object,
        isReasonMandatory: Boolean,
        closeReasonsPopup: Boolean,
        useDefaultReason: Boolean,
        fill: Boolean,
        color: String,
    },

    components: {
        "reasons-popup": ReasonsPopup,
    },

    data() {
        return {
            showReasonsPopup: false,
        };
    },

    computed: {
        ...mapState(["profile_userInfo"]),

        icon() {
            switch (this.state) {
                case "rejected":
                    return "highlight_off";
                case "pending":
                    return "alarm";
                case "admitted":
                    return "done";
            }
        },
        tooltip() {
            switch (this.state) {
                case "rejected":
                    return "Marcar como rechazado";
                case "pending":
                    return "Marcar como pendiente";
                case "admitted":
                    return "Marcar como admitido";
            }
        },

        reasonsType() {
            switch (this.state) {
                case "rejected":
                    return "rechazado";
                case "pending":
                    return "pendiente";
                case "admitted":
                    return "admitido";
            }
        },
    },

    watch: {
        closeReasonsPopup() {
            this.showReasonsPopup = false;
        },
    },

    methods: {
        changeExamState(state) {
            if (["rejected", "pending"].includes(state)) {
                this.showReasonsPopup = true;
            } else {
                const statusLog = {
                    who: this.profile_userInfo.displayName,
                    when: (Date.now() / 1000) | 0,
                    status: this.reasonsType,
                };

                this.$emit("status-changed", {
                    examId: this.exam.id,
                    statusLog: statusLog,
                });
            }
        },

        handleReasonSelected(reason) {
            const statusLog = {
                who: this.profile_userInfo.displayName,
                when: (Date.now() / 1000) | 0,
                status: this.reasonsType,
                reason,
            };

            this.$emit("status-changed", {
                examId: this.exam.id,
                statusLog: statusLog,
            });

            this.showReasonsPopup = false;
        },
    },
};
</script>
