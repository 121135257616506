<template>
    <div>
        <div v-if="patient.name">
            <f7-row v-if="translation">
                <f7-col>
                    <i>Name</i>
                </f7-col>
            </f7-row>

            <f7-row>
                <f7-col>
                    <b>Nombre</b>:
                    {{ patient.name.toUpperCase() }}
                </f7-col>
            </f7-row>
        </div>

        <div>
            <f7-row v-if="translation">
                <f7-col>
                    <i>Lastname father</i>
                </f7-col>
            </f7-row>

            <f7-row>
                <f7-col>
                    <b>Apellido paterno</b>:
                    {{ patient.lastname_f ? patient.lastname_f.toUpperCase() : "-" }}
                </f7-col>
            </f7-row>
        </div>

        <div>
            <f7-row v-if="translation">
                <f7-col>
                    <i>Lastname mother</i>
                </f7-col>
            </f7-row>

            <f7-row>
                <f7-col>
                    <b>Apellido materno</b>:
                    {{ patient.lastname_m ? patient.lastname_m.toUpperCase() : "-" }}
                </f7-col>
            </f7-row>
        </div>

        <div>
            <f7-row v-if="translation">
                <f7-col>
                    <i>Document</i>
                </f7-col>
            </f7-row>

            <f7-row>
                <f7-col>
                    <b v-if="patient.document_type === 1">RUN</b>
                    <b v-else>{{ patient.document_type_info ? patient.document_type_info.name : "-" }}</b>:

                    <span v-if="patient.document_type === 1">{{ formatRut(patient.document_number) || "-" }}</span>
                    <span v-else>{{ patient.document_number || "-" }}</span>
                </f7-col>
            </f7-row>
        </div>

        <div>
            <f7-row v-if="translation">
                <f7-col>
                    <i>Date of birth</i>
                </f7-col>
            </f7-row>

            <f7-row>
                <f7-col>
                    <b>Fecha nacimiento</b>:
                    {{ $moment(patient.birth_date, "YYYY-MM-DD").format("DD/MM/YYYY") || "-" }}
                </f7-col>
            </f7-row>
        </div>

        <div>
            <f7-row v-if="translation">
                <f7-col>
                    <i>Age</i>
                </f7-col>
            </f7-row>

            <f7-row>
                <f7-col>
                    <b>Edad</b>:
                    {{ patientAge(patient.birth_date, "YYYY-MM-DD") }}
                </f7-col>
            </f7-row>
        </div>

        <div>
            <f7-row v-if="translation">
                <f7-col>
                    <i>Phone</i>
                </f7-col>
            </f7-row>

            <f7-row>
                <f7-col>
                    <b>Teléfono</b>:
                    {{ patient.phone || "-" }}
                </f7-col>
            </f7-row>
        </div>
    </div>
</template>

<script>
import { _getDocumentType, _patientAge } from "@/app/helpers";
import { format } from "rut.js";

export default {
    props: {
        translation: {
            type: Boolean,
            default: false,
        },
        patient: Object,
    },

    data() {
        return {
            formatRut: format,

            getDocumentType: _getDocumentType,
            patientAge: _patientAge,
        };
    },
};
</script>
