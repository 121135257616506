import { examStatuses } from "@/app/constants";

export default {
    methods: {
        handleMainPlantillaPageDeleted(payload, isEditor) {
            const { pageNumber } = payload;

            const plantillaPagesCount = this.plantilla.pages.length;

            if (pageNumber <= 0) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Debe ingresar un número de página");
                return;
            }

            if (plantillaPagesCount === 1) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("La plantilla debe tener mínimo una página.");
                return;
            }

            if (pageNumber > plantillaPagesCount) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(`La página ${pageNumber} no existe.`);
                return;
            }

            this.$f7.dialog.confirm("¿Está seguro?", () => {
                const page = this.plantilla.pages[pageNumber - 1];

                const blocksInPage = Object.values(this.plantilla.blocks)
                    .filter((block) => block.position.y >= page.position.top && block.position.y <= page.position.bottom);

                if (!isEditor) {
                    const notAllowedBlocks = [
                        "resultado-cualitativo-block",
                        "resultado-cuantitativo-block",
                        "resultado-free-text-block",
                        "resultado-collection-block",
                        "observations-block",
                        "text-block",
                        "table-block",
                        "image-block",
                    ];

                    const notAllowedBlocksInPage = blocksInPage.filter((b) => notAllowedBlocks.includes(b.block.code));
                    const isAtLeastResultadoCargado = this.currentStatusIdByReportType >= examStatuses.resultado_cargado;

                    if (isAtLeastResultadoCargado && notAllowedBlocksInPage.length > 0) {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(`No puedes eliminar la página número ${pageNumber} debido a que contiene <b>bloques de resultados</b> o <b>bloques de datos</b>.`);
                        return;
                    }
                }

                const blockIdsInPage = blocksInPage.map((block) => block.id);

                this.deleteBlocks(blockIdsInPage);

                this.deletePage(pageNumber);

                this.$f7.toast.show({
                    text: `Se ha eliminado la página ${pageNumber}`,
                    closeTimeout: 2000,
                });
            });
        },

        deleteBlocks(blocks) {
            blocks.forEach((blockId) => this.$delete(this.plantilla.blocks, blockId));
        },

        deletePage(pageNumber) {
            this.plantilla.pages.splice(pageNumber - 1, 1);
        },
    },
};
