<template>
    <f7-popup :id="_uid" :opened="opened" @popup:closed="handlePopupClosed">
        <f7-page v-if="admission">
            <f7-navbar title="Seleccione la OT que quiere ver">
                <f7-nav-right>
                    <f7-link :popup-close="`#${_uid}`">Cancelar</f7-link>
                </f7-nav-right>
            </f7-navbar>

            <f7-block>
                <p v-if="admission.ots.length === 0" class="margin-horizontal">
                    Esta admisión no tiene OTs generadas.
                </p>

                <f7-list media-list class="no-margin-top margin-bottom">
                    <f7-list-item v-for="ot in admission.ots" :key="ot.id">
                        <div slot="title">
                            {{ ot.code }}
                        </div>
                        <div slot="subtitle">
                            Verás solo exámenes de la sección
                            <b>{{ ot.section.name }}</b>
                        </div>

                        <div slot="after">
                            <f7-button @click="selectOt(ot)" small fill>Seleccionar</f7-button>
                        </div>
                    </f7-list-item>
                    <f7-list-item>
                        <div slot="title">Ver admisión completa</div>
                        <div slot="subtitle">
                            Podrás ver
                            <b>todos los exámenes</b>
                            de esta admisión
                        </div>

                        <div slot="after">
                            <f7-button @click="selectAdmission" small fill color="blue">Ver</f7-button>
                        </div>
                    </f7-list-item>
                </f7-list>
            </f7-block>
        </f7-page>
    </f7-popup>
</template>

<script>
export default {
    props: {
        opened: Boolean,
        admission: Object,
    },

    data() {
        return {};
    },

    methods: {
        handlePopupClosed() {
            this.$emit("popup-closed");
        },

        selectOt(ot) {
            this.$emit("ot-selected", ot);
        },
        selectAdmission() {
            this.$emit("admission-selected", this.admission);
        },
    },
};
</script>
