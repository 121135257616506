<template>
    <f7-block>

        <template v-if="search">
            <f7-block-title class="no-margin-vertical no-margin-left">Búsqueda de médico</f7-block-title>
            <f7-row>
                <f7-col width="90">
                    <div class="list no-hairlines no-margin-vertical">
                        <ul>
                            <li :class="{
                                    'item-content': true, 
                                    'item-input':true, 
                                    'item-input-with-error-message': invalidRut, 
                                    'item-input-invalid': invalidRut 
                                }"
                            >
                                <f7-icon class="margin-right" material="search"></f7-icon>
                                <div class="item-inner">
                                    <div class="item-input-wrap">
                                        <input
                                            type="text"
                                            placeholder="Ingrese RUT"
                                            :value="query"
                                            @input="validateRut($event)"
                                            @keyup="searchWithEnterKey($event)"
                                            :class="{'input-invalid': true}"
                                        >
                                        <div v-if="invalidRut" class="item-input-error-message">RUT inválido</div>
                                        <span class="input-clear-button"></span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </f7-col>
                <f7-col width="10">
                    <f7-button
                        fill
                        small
                        class="margin-top"
                        icon-material="search"
                        text="Buscar"
                        @click="searchRut()"
                    >
                    </f7-button>
                </f7-col>
            </f7-row>

        </template>

        <template v-if="doctor.name && !addDoctorData">
            <f7-block-title class="margin-bottom-half">Datos médico</f7-block-title>
            <f7-list class="margin-bottom-half">
                <f7-list-item
                    header="Nombre"
                    :title="doctor.name"
                    >
                </f7-list-item>
                <f7-list-item
                    v-if="doctor.rut"
                    header="RUT"
                    :title="doctor.rut"
                    >
                </f7-list-item>
            </f7-list>
        </template>

        <template v-if="addDoctorData">
            <f7-list no-hairlines class="margin-bottom-half">
                <f7-list-input
                    label="Nombres"
                    type="text"
                    placeholder="Ingrese nombre"
                    :value="newDoctor.name"
                    @input="saveDoctorData($event, 'name')"
                    required validate
                    error-message="Debe indicar al menos un nombre"
                >
                </f7-list-input>
                <f7-list-input
                    label="Primer Apellido"
                    type="text"
                    placeholder="Ingrese primer apellido"
                    :value="newDoctor.lastname_f"
                    @input="saveDoctorData($event, 'lastname_f')"
                    required validate
                    error-message="Debe indicar al menos el primer apellido"
                >
                </f7-list-input>
                <f7-list-input
                    label="Segundo Apellido"
                    type="text"
                    placeholder="Ingrese segundo apellido"
                    :value="newDoctor.lastname_m"
                    @input="saveDoctorData($event, 'lastname_m')"
                >
                </f7-list-input>
                <f7-list-input v-if="createNewDoctor"
                    label="RUT"
                    type="text"
                    name="rut"
                    placeholder="Ingrese RUT"
                    :value="newDoctor.rut"
                    @input="validateRut($event)"
                    error-message='RUT no válido'
                    :error-message-force="invalidRut"
                >
                </f7-list-input>
            </f7-list>
        </template>

        <f7-row class="margin-top">
            <f7-col width="100" large="33">
                <f7-button
                    v-if="!addDoctorData"
                    outline
                    small
                    class="margin-bottom-half"
                    @click="noRut()"
                >
                    Agregar médico sin RUT
                </f7-button>
                <f7-button v-if="!search" outline small @click="showSearch()">
                    Activar búsqueda de médico
                </f7-button>
            </f7-col>
            <f7-col width="0" large="33">
            </f7-col>
            <f7-col width="100" large="33" class="text-align-center">
                <f7-link v-if="previousDoctor.name" class="margin-left" @click="repeatDoctor()">
                    Repetir {{previousDoctor.name}}
                </f7-link>
            </f7-col>
        </f7-row>

    </f7-block>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {validate, format, clean} from 'rut.js';

export default {
    data(){
        return{
            search: true,
            addDoctorData: false,
            createNewDoctor: false,
            query: '',
            invalidRut: false,
            doctor: {},
            newDoctor: {
                name: '',
                lastname_f: '',
                lastname_m: '',
                rut: null
            },
        }
    },

    props: ['doctorData'],

    computed: {
        ...mapState(['previousDoctor', 'selectedAdmission'])
    },

    methods: {
        ...mapActions(['getAPIInfo']),

        validateRut(event){
            let identifier = event.target.value;
            if(identifier.trim().length > 0){
                identifier = format(identifier);
                if(!validate(identifier)){
                    this.invalidRut = true;
                }
                else{
                    this.invalidRut = false;
                }
            }
            if(event.target.name === 'rut'){
                this.saveDoctorData(event, 'rut')
                this.doctor.rut = identifier;
            }
            else{
                this.query = identifier;
            }
        },

        capitalizeText(text){
            if (!text) {
                return '';
            }
            return text.charAt(0).toUpperCase() + text.slice(1);
        },

        formatName(names, firstLastName, secondLastName){
            let namesArray = names.split(' ');
            namesArray = namesArray.map((name)=>{return this.capitalizeText(name)});
            const lastNames =`${this.capitalizeText(firstLastName)} ${this.capitalizeText(secondLastName)}`;
            if(!namesArray[1]){
                return `${namesArray[0]} ${lastNames}`
            }
            else{
                return `${namesArray[0]} ${namesArray[1]} ${lastNames}`
            }
        },

        saveDoctorData(event, attr){
            this.newDoctor[attr] = event.target.value;
            let doctorData = {
                formatedName: this.formatName(this.newDoctor.name, this.newDoctor.lastname_f, this.newDoctor.lastname_m),
                rut: this.newDoctor.rut,
            }
            if(this.createNewDoctor){
                doctorData = Object.assign({}, this.newDoctor, doctorData)
            }
            this.$emit('saveDoctorData', doctorData)
        },

        searchRut(){
            let rut = clean(this.query);
            if(!rut || this.invalidRut){
                this.invalidRut = true;
                this.$f7.dialog.alert('Debe ingresar un rut válido', 'RUT inválido');
            }
            else{
                this.$f7.dialog.preloader('Buscando');
                this.$firebase.auth().currentUser.getIdToken()
                .then((idToken) => this.getAPIInfo({url: 'clinicians/search?q=' + rut, userToken: idToken}))
                .then((resp) => {
                    const doctor = resp.data.data[0];
                    const name = this.formatName(doctor.name, doctor.lastname_f, doctor.lastname_m);
                    this.$f7.dialog.close();
                    this.$f7.dialog.confirm(
                        `${name}, ¿Agregar como médico solicitante?`,
                        'Médico encontrado',
                        ()=>{
                            //Si esta en admisión abierta debería emitir un evento con los datos encontrados
                            //Si está en admisión cerrada se deben agregar los datos a la admisión en FB
                            this.doctor.name = name;
                            this.doctor.rut = format(rut);
                            this.search = false;
                            this.query = '';
                            this.$emit('saveDoctorData', this.doctor)
                        },
                    )
                })
                .catch((err)=>{
                    this.$f7.dialog.close();
                    this.$f7.dialog.confirm(
                        'No se encontró médico con RUT ingresado, ¿Desea agregar uno nuevo?',
                        'Médico no encontrado',
                        ()=>{
                            this.newDoctor.rut = format(rut);
                            this.search = false;
                            this.query = '';
                            this.createNewDoctor = true;
                            this.addDoctorData = true;
                        }
                    )
                })
            }
        },

        searchWithEnterKey(event) {
            if (event.keyCode === 13){
                this.searchRut();
            }
        },

        noRut(){
            this.search = false;
            this.addDoctorData = true;
            this.createNewDoctor = false;
            this.newDoctor.rut = null;
        },

        showSearch(){
            this.search = true;
            this.addDoctorData = false;
            this.doctor = {};
            this.newDoctor.rut = null;
        },

        repeatDoctor(){
            if(this.previousDoctor.name){
                this.doctor = this.previousDoctor;
                this.$emit('saveDoctorData', this.doctor)
                this.search = false;
            }
        }
    },

    mounted(){
        if(this.doctorData && this.doctorData.name){
            this.doctor = this.doctorData;
            this.search = false;
        }
    }

}
</script>