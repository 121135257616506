<template>
    <f7-popup :opened="opened" @popup:closed="$emit('popup-closed')">
        <f7-page v-if="admissionExam">
            <f7-navbar title="Flujograma del examen">
                <f7-nav-right>
                    <f7-link popup-close color="blue">Cerrar</f7-link>
                </f7-nav-right>
            </f7-navbar>

            <f7-block class="margin-top-half">
                <f7-row>
                    <f7-col>
                        <p>
                            <b>Exámen:</b>
                            {{ admissionExam.exam.name }}
                        </p>
                        <p>
                            <b>Sección:</b>
                            {{ admissionExam.exam_section.name }}
                        </p>
                        <p>
                            <b>Estado actual {{ chosenSelectedReportType ? `(${chosenSelectedReportType})` : "" }}:</b>
                            {{ currentStatusName }}
                        </p>
                    </f7-col>
                    <f7-col>
                        <p>
                            <b>Muestras:</b>
                            {{ admissionExamSamplesText }}
                        </p>

                        <p>
                            <b>Ingreso:</b>
                            {{
                                admissionExam.createdAt
                                    ? $moment(admissionExam.createdAt, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm")
                                    : "Sin fecha de carga"
                            }}
                        </p>
                    </f7-col>
                </f7-row>

                <f7-row
                    class="margin-bottom"
                    v-if="
                        (admissionExam.current_status_id < statuses.resultado_cargado ||
                            admissionExam.current_status_id === statuses.examen_rechazado ||
                            admissionExam.current_status_id === statuses.examen_pendiente) &&
                            showChangeStateButtons
                    "
                >
                    <f7-col v-if="misprivx.admit">
                        <f7-button :disabled="admitidoButtonDisabled" raised color="green" fill @click="changeToAdmitido">
                            Admitido
                        </f7-button>
                    </f7-col>
                    <f7-col v-if="misprivx.mark_exam_pending">
                        <f7-button :disabled="pendingButtonDisabled" raised color="yellow" fill @click="openReasonsPopup('pending')">
                            Pendiente
                        </f7-button>
                    </f7-col>
                    <f7-col v-if="misprivx.mark_exam_rejected">
                        <f7-button :disabled="rejectionButtonDisabled" raised color="red" fill @click="openReasonsPopup('rejection')">
                            Rechazado
                        </f7-button>
                    </f7-col>
                </f7-row>

                <div class="margin-vertical">
                    <label><b>Seleccione tipo de informe</b></label>
                    <f7-list no-hairlines class="no-margin-vertical">
                        <f7-list-input type="select" name="report-type" @change="reportTypeChanged">
                            <option value="">Todo el log</option>
                            <option v-for="reportType in reportTypesData" :key="reportType.id" :value="reportType.name">
                                {{ reportType.name }}
                            </option>
                        </f7-list-input>
                    </f7-list>
                </div>

                <table class="table table-bordered table-sm">
                    <thead>
                        <td v-if="chosenSelectedReportType">
                            <div><small>Estado en</small></div>
                            <div>
                                <b>{{ chosenSelectedReportType }}</b>
                            </div>
                        </td>
                        <th v-else>Estado</th>
                        <th>Usuario</th>
                        <th>Fecha</th>
                    </thead>
                    <tbody>
                        <tr v-for="(status, index) in statusLogsByReportType" :key="status.id" :class="{ 'table-info': index === 0 }">
                            <td>
                                <b>
                                    <span v-if="index === 0">
                                        <template v-if="chosenSelectedReportType">(Actual)</template>
                                        <template v-else>(Más reciente)</template>
                                    </span>
                                    {{ status.exam_status_meta.name }}
                                </b>

                                <!-- pendiente, rechazado -->
                                <div v-if="status.status_id === statuses.examen_pendiente" class="text-color-gray">
                                    Motivo: {{ status.pending_reasons_meta.name }}
                                </div>
                                <div v-if="status.status_id === statuses.examen_rechazado" class="text-color-gray">
                                    Motivo: {{ status.rejection_reasons_meta.name }}
                                </div>
                                <div v-if="!chosenSelectedReportType && status.detail">{{ status.detail }}</div>
                            </td>
                            <td>{{ status.whoName || "Sin información" }}</td>
                            <td>{{ $moment(status.when).format("llll") }}</td>
                        </tr>
                    </tbody>
                </table>

                <div v-if="statusLogsByReportType.length === 0">
                    No hay registros de logs de estado para este tipo de informe.
                </div>
            </f7-block>
        </f7-page>

        <reasons-popup
            :opened="reasonsPopupOpened"
            :type="reasonsType"
            @reason-selected="handleReasonSelected"
            @popup-closed="handleReasonsPopupClosed"
        ></reasons-popup>
    </f7-popup>
</template>

<script>
import ReasonsPopup from "@/app/components-migration/reasons-popup.vue";
import { examStatuses, reportTypes, examStatusesNames } from "@/app/constants";
import { mapActions, mapState } from "vuex";

export default {
    props: {
        opened: Boolean,
        admissionExam: Object,
        showChangeStateButtons: {
            type: Boolean,
            default: true,
        },
    },

    components: {
        "reasons-popup": ReasonsPopup,
    },

    data() {
        return {
            reportTypesData: reportTypes,
            statuses: examStatuses,
            reasonsPopupOpened: false,
            reasonsType: "pending",
            chosenSelectedReportType: null,
        };
    },

    watch: {
        opened() {
            if (this.opened) {
                this.chosenSelectedReportType = "";
            }
        },
    },

    computed: {
        ...mapState(["misprivx"]),

        statusLogsByReportType() {
            if (this.chosenSelectedReportType) {
                return this.admissionExam.status_log.filter((log) => log.detail === this.chosenSelectedReportType);
            }

            return this.admissionExam.status_log;
        },

        statusTypeId() {
            switch (this.reasonsType) {
                case "pending":
                    return 8;
                case "rejection":
                    return 9;
                default:
                    return 0;
            }
        },

        endpointBytype() {
            switch (this.reasonsType) {
                case "rejection":
                    return `admission_exams/status/${examStatuses.examen_rechazado}`;

                case "pending":
                    return `admission_exams/status/${examStatuses.examen_pendiente}`;
            }
        },

        admitidoButtonDisabled() {
            if (this.examIsPreadmitido()) return true;

            const samplesCount = this.admissionExam.samples ? this.admissionExam.samples.length : 0;
            return this.admissionExam.current_status_id === examStatuses.admitido || samplesCount === 0;
        },

        pendingButtonDisabled() {
            return this.admissionExam.current_status_id === examStatuses.examen_pendiente;
        },

        rejectionButtonDisabled() {
            return this.admissionExam.current_status_id === examStatuses.examen_rechazado;
        },

        admissionExamSamplesText() {
            if (this.admissionExam.samples.length === 0) {
                return "Sin muestras";
            }

            const sampleNames = this.admissionExam.samples.map((sample) => sample.meta.name);

            return sampleNames.join(", ");
        },

        currentStatusName() {
            let lastStatus;

            if (this.chosenSelectedReportType) {
                lastStatus = this.statusLogsByReportType[0];

                if (lastStatus && lastStatus.exam_status_meta) {
                    return lastStatus.exam_status_meta.name;
                } else {
                    return "Sin estado";
                }
            } else {
                return this.admissionExam.exam_status.name || "Sin estado";
            }
        },
    },

    methods: {
        ...mapActions(["updateInfo"]),

        handleReasonSelected(reason) {
            this.$emit("status-log-added", {
                examId: this.admissionExam.id,
                statusId: this.statusTypeId,
                reason: reason,
            });

            this.reasonsPopupOpened = false;
        },

        openReasonsPopup(type) {
            this.reasonsType = type;
            this.reasonsPopupOpened = true;
        },

        handleReasonsPopupClosed() {
            this.reasonsPopupOpened = false;
        },

        changeToAdmitido() {
            this.$emit("status-log-added", {
                examId: this.admissionExam.id,
                statusId: examStatuses.admitido,
            });
        },

        examIsPreadmitido() {
            const statusPreadmitido = this.admissionExam.status_log.find((sl) => sl.status_id === examStatuses.preadmitido);

            if (statusPreadmitido) return true;

            return false;
        },

        reportTypeChanged(e) {
            this.chosenSelectedReportType = e.target.value;
        },
    },
};
</script>
