<template>
    <f7-popup :opened="opened" @popup:closed="$emit('popup-closed')">
        <f7-page>
            <f7-navbar title="Examenes encontrados">
                <f7-nav-right>
                    <f7-link popup-close color="blue">Cerrar</f7-link>
                </f7-nav-right>
            </f7-navbar>

            <f7-block class="margin-top-half">
                <p>
                    La búsqueda ha arrojado más de un resultado. Seleccione un examen de la lista.
                </p>

                <f7-searchbar
                    search-container=".search-list"
                    search-in=".item-header, .item-title, .item-text"
                    :disable-button="!$theme.aurora"
                    placeholder="Buscar exámen"
                    :backdrop="false"
                ></f7-searchbar>

                <f7-list class="searchbar-not-found">
                    <f7-list-item title="No se han encontrado examenes"></f7-list-item>
                </f7-list>
                <f7-list media-list class="search-list searchbar-found">
                    <f7-list-item
                        media-item
                        v-for="exam in examsCopy"
                        :key="exam.id"
                        class="thin-list"
                        link="#"
                        @click="$emit('exam-selected', exam)"
                    >
                        <div slot="header">
                            <b>
                                {{ formatName(exam.admission.patient.name, exam.admission.patient.lastname_f, exam.admission.patient.lastname_m) }}
                                &bull; {{ exam.admission.patient.document_type_info.name }}: {{ exam.admission.patient.document_number }}
                            </b>
                            &bull; Creado el {{ $moment(exam.admission.createdAt, "YYYY-MM-DD").format("DD/MM/YYYY") }} &bull;
                        </div>
                        <div slot="title">
                            {{ exam.exam.name }}
                            &bull;
                            {{ exam.exam_section.name }}
                        </div>
                        <div slot="text">
                            CB Muestra: {{ getSampleCode(exam) }} &bull; OT: {{ exam.ot ? exam.ot.code : "Sin orden de trabajo" }} &bull;
                            {{ getExamStatusName(exam) }}
                        </div>
                    </f7-list-item>
                </f7-list>

                <div class="display-flex justify-content-center" v-if="exams.length > 0">
                    <f7-button fill large raised class="padding-horizontal" @click="loadMoreExams" v-if="pagination.page < pagination.totalPages">
                        Cargar más exámenes
                    </f7-button>
                    <div v-else class="text-color-gray">Se han cargado todos los examenes encontrados</div>
                </div>
            </f7-block>
        </f7-page>
    </f7-popup>
</template>

<script>
import { _formatName, _getDocumentType } from "@/app/helpers";
import SearchMixin from "@/app/mixins/search-mixin";
import { mapActions } from "vuex";
import { examStatusesIds } from "@/app/constants";

export default {
    mixins: [SearchMixin],

    props: {
        opened: Boolean,
        exams: Array,
        pagination: Object,
        examsSearchOptions: Object,
    },

    data() {
        return {
            // Helpers
            formatName: _formatName,
            getDocumentType: _getDocumentType,

            examsCopy: [],
            paginationCopy: {},
        };
    },

    watch: {
        exams() {
            if (this.exams) this.examsCopy = this.exams;
        },
        pagination() {
            if (this.pagination) this.paginationCopy = this.pagination;
        },
    },

    methods: {
        ...mapActions(["getAPIInfo"]),

        getExamStatusName(exam) {
            const examResults = exam.exam_result || [];

            if (examResults.length === 0) return exam.exam_status ? exam.exam_status.name : "Sin resultados";

            if (examResults.length === 1) {
                const result = examResults[0];

                if (result.report_type === "Informe preliminar") {
                    return `Informe preliminar: ${examStatusesIds[exam.status_id_preliminar] || exam.exam_status ? exam.exam_status.name : "Sin resultados"}`;
                } else if (result.report_type === "Informe final") {
                    return `Informe final: ${examStatusesIds[exam.status_id_final] || exam.exam_status ? exam.exam_status.name : "Sin resultados"}`;
                } else if (result.report_type === "Informe complementario") {
                    return `Informe complementario: ${examStatusesIds[exam.status_id_compl] || exam.exam_status ? exam.exam_status.name : "Sin resultados"}`;
                } else if (result.report_type === null) {
                    return exam.exam_status ? exam.exam_status.name : "Sin resultados";
                }
            }

            if (examResults.length > 1) return "Múltiples resultados";

            return "Sin resultados";
        },
        getSampleCode(exam) {
            if (!exam.samples) {
                return "Sin muestra";
            }
            if (exam.samples.length === 0) {
                return "Sin muestra";
            }

            return exam.samples[0].sample_code;
        },

        loadMoreExams() {
            this.$f7.dialog.preloader("Cargando más exámenes...");

            let queryString = this.queryStringFromOptions(this.examsSearchOptions, {
                rowsPerPage: 100,
            });

            const page = this.paginationCopy.page + 1;

            queryString += `&page=${page}`;

            console.log(queryString);

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({
                        url: "admission_exams?" + queryString,
                        userToken: idToken,
                    });
                })
                .then((response) => {
                    const responseData = response.data;

                    if (responseData.data && responseData.data.length) {
                        this.examsCopy.push(...responseData.data);
                        this.paginationCopy = responseData.pagination;
                    }

                    this.$f7.dialog.close();
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error);
                });
        },
    },
};
</script>
