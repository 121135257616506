<template>
    <f7-page name="institutionPrices">
        <f7-navbar title="Precios de los exámenes para esta institución" back-link back-link-force></f7-navbar>
        <f7-block-title v-if="!viewingInstitution">
            Seleccione alguna institución
        </f7-block-title>

        <f7-block v-else-if="!loading">
            <f7-card>
                <f7-card-header>
                    <f7-block-title medium>Precios de exámenes para la institución: {{ viewingInstitution.name }}</f7-block-title>
                </f7-card-header>
                <f7-card-content>
                    <!-- Searchbar -->
                    <f7-searchbar 
                        class="margin-bottom"
                        disable-button-text="Cancelar" 
                        placeholder="Buscar por nombre de examen" 
                        search-container=".search-list-exams"
                        search-in=".item-label"
                        :backdrop="false"
                    ></f7-searchbar>

                    <!-- Nothing found -->
                    <f7-list class="searchbar-not-found">
                        <f7-list-item title="Nothing found"></f7-list-item>
                    </f7-list>

                    <!-- Exams prices input list -->
                    <f7-list media-list class="search-list-exams searchbar-found">
                        <f7-list-input 
                            v-for="exam in allExams" 
                            :key="exam.id" 
                            :label="exam.name"
                            :value="getExamPrice(exam.id) | formatPrice"
                            type="text" 
                            placeholder="$" 
                            step="500" 
                            min="0" 
                            @blur="modifyPrice(exam.id, $event.target.value)"
                        ></f7-list-input>
                    </f7-list>
                </f7-card-content>
            </f7-card>
        </f7-block>
        
        <f7-block v-else class="row demo-preloaders align-items-stretch text-align-center margin-bottom">
            <f7-col>
                <f7-preloader></f7-preloader>
            </f7-col>
        </f7-block>
    </f7-page>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    data() {
        return {
            prices: [],
            loading: false,
        };
    },

    computed: {
        ...mapState("institutions", ["viewingInstitution"]),

        ...mapState(["allExams"]),
    },

    created() {
        this.getPrices();
    }, 

    methods: {
        ...mapActions(["getAPIInfo", "updateInfo"]),

        modifyPrice(examId, price) {
            const formattedPrice = Number(price.replace(/\./g, ""));

            if (isNaN(formattedPrice)) {
                this.$f7.dialog.alert("El precio ingresado tiene caracteres inválidos. Por favor, ingrese sólo números.");
                return;
            }

            // Chequear si el precio es igual a antes, si es así, retornar
            if (!this.checkIfPriceHasChanged(examId, formattedPrice)) {
                return;
            }

            console.log("modifying price for exam", {
                institution_id: this.viewingInstitution.id,
                exam_id: examId,
                price: formattedPrice,
            });

            const priceIndex = this.prices.findIndex((e) => e.exam_meta.id === examId);

            if (priceIndex === -1) {
                this.prices.push({
                    id: null,
                    exam_meta: { id: examId },
                    institution_meta: { id: this.viewingInstitution.id },
                    price: formattedPrice,
                })
            } else {
                this.prices[priceIndex].price = formattedPrice;
            }

            const priceId = this.prices[priceIndex] ? this.prices[priceIndex].id : null;

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.updateInfo({
                        url: `exams_prices/${this.viewingInstitution.id}/${examId}`,
                        userToken: idToken,
                        data: { price_id: priceId, price: formattedPrice },
                    })
                })
                .then((response) => {
                    console.log("se editó correctamente el precio del examen", response);

                    const updatedPrice = response.data.data;

                    const newPriceIndex = this.prices.findIndex((e) => e.exam_meta.id == updatedPrice.exam_id);
                    this.prices[newPriceIndex].id = updatedPrice.id;

                    this.$f7.toast.show({
                        text: "El precio del examen fue actualizado correctamente",
                        closeTimeout: 1300,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Error " + error);
                });
        },

        getPrices() {
            this.loading = true;

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: `exams_prices/${this.viewingInstitution.id}`, userToken: idToken });
                })
                .then((response) => {
                    this.prices = response.data.data;

                    console.log("prices were fetched", this.prices);

                    this.loading = false;

                    this.$f7.dialog.close();
                })
                .catch((err) => {
                    console.error(err);
                    this.$f7.dialog.alert("Error " + err);
                });
        },

        getExamPrice(examId) {
            if (!examId) return "";

            const examPriceData = this.prices.find((e) => e.exam_meta.id === examId);
            if (!examPriceData) return "";

            return Number(examPriceData.price);

            // const formatter = new Intl.NumberFormat('es-CL', {
            //     currency: 'CLP',
            //     minimumFractionDigits: 0
            // });

            // return formatter.format(Number(examPriceData.price));
        },

        checkIfPriceHasChanged(examId, newPrice) {
            const index = this.prices.findIndex((e) => e.exam_meta.id == examId);

            if (index === -1) {
                if (newPrice === 0) return false;

                return true;
            }

            return this.prices[index].price != newPrice;
        },
    },
};
</script>
