<template>
    <div id="pdf-pages-wrapper" :style="computedPlantillaStyles">
        <plantilla-pdf-page :preview-mode="previewMode" v-for="(page, i) in plantilla.pages" :key="i">
            <template v-if="groupedBlocks && groupedBlocks[i]">
                <slot v-for="addedBlock in groupedBlocks[i]">
                    <!-- Bloques básicos -->
                    <vue-draggable-resizable
                        v-if="addedBlock.block.code === 'logo-block'"
                        :w="addedBlock.size.width"
                        :h="addedBlock.size.height"
                        :x="addedBlock.position.x"
                        :y="addedBlock.position.y - page.position.top"
                        @dragstop="(x, y) => $emit('block-dragged', { x, y, addedBlock, page })"
                        @resizestop="(x, y, width, height) => $emit('block-resized', { x, y, width, height, addedBlock })"
                        :grid="[20, 20]"
                        :draggable="!previewMode"
                        :resizable="!previewMode"
                        tabindex="0"
                        @keyup.native.delete="handleDeleteBlock($event.keyCode, addedBlock)"
                        :class="blockComputedClasses"
                    >
                        <logo-block :width="addedBlock.size.width" :height="addedBlock.size.height"></logo-block>
                    </vue-draggable-resizable>

                    <vue-draggable-resizable
                        v-if="addedBlock.block.code === 'footer-block'"
                        :w="addedBlock.size.width"
                        :h="addedBlock.size.height"
                        :x="addedBlock.position.x"
                        :y="addedBlock.position.y - page.position.top"
                        @dragstop="(x, y) => $emit('block-dragged', { x, y, addedBlock, page })"
                        @resizestop="(x, y, width, height) => $emit('block-resized', { x, y, width, height, addedBlock })"
                        :grid="[20, 20]"
                        :draggable="!previewMode"
                        :resizable="!previewMode"
                        tabindex="0"
                        @keyup.native.delete="handleDeleteBlock($event.keyCode, addedBlock)"
                        :class="blockComputedClasses"
                    >
                        <footer-block :width="addedBlock.size.width" :height="addedBlock.size.height"></footer-block>
                    </vue-draggable-resizable>

                    <vue-draggable-resizable
                        v-if="addedBlock.block.code === 'patient-info-block'"
                        :w="addedBlock.size.width"
                        :h="addedBlock.size.height"
                        :x="addedBlock.position.x"
                        :y="addedBlock.position.y - page.position.top"
                        @dragstop="(x, y) => $emit('block-dragged', { x, y, addedBlock, page })"
                        @resizestop="(x, y, width, height) => $emit('block-resized', { x, y, width, height, addedBlock })"
                        :grid="[20, 20]"
                        :draggable="!previewMode"
                        :resizable="!previewMode"
                        tabindex="0"
                        @keyup.native.delete="handleDeleteBlock($event.keyCode, addedBlock)"
                        :class="blockComputedClasses"
                    >
                        <patient-info-block :patient="getExamPatient()" :translation="plantilla.options.translations"></patient-info-block>
                    </vue-draggable-resizable>

                    <vue-draggable-resizable
                        v-if="addedBlock.block.code === 'exam-info-block'"
                        :w="addedBlock.size.width"
                        :h="addedBlock.size.height"
                        :x="addedBlock.position.x"
                        :y="addedBlock.position.y - page.position.top"
                        @dragstop="(x, y) => $emit('block-dragged', { x, y, addedBlock, page })"
                        @resizestop="(x, y, width, height) => $emit('block-resized', { x, y, width, height, addedBlock })"
                        :grid="[20, 20]"
                        :draggable="!previewMode"
                        :resizable="!previewMode"
                        tabindex="0"
                        @keyup.native.delete="handleDeleteBlock($event.keyCode, addedBlock)"
                        :class="blockComputedClasses"
                    >
                        <exam-info-block :admission-exam="admissionExam" :translation="plantilla.options.translations"></exam-info-block>
                    </vue-draggable-resizable>

                    <!-- Bloques para carga de resultados -->
                    <vue-draggable-resizable
                        v-if="addedBlock.block.code === 'resultado-cualitativo-block'"
                        :w="addedBlock.size.width"
                        :h="addedBlock.size.height"
                        :x="addedBlock.position.x"
                        :y="addedBlock.position.y - page.position.top"
                        @dragstop="(x, y) => $emit('block-dragged', { x, y, addedBlock, page })"
                        @resizestop="(x, y, width, height) => $emit('block-resized', { x, y, width, height, addedBlock })"
                        :grid="[20, 20]"
                        :draggable="!previewMode"
                        :resizable="!previewMode"
                        tabindex="0"
                        @keydown.native.delete="handleDeleteBlock($event.keyCode, addedBlock)"
                        :class="blockComputedClasses"
                    >
                        <resultado-cualitativo-block
                            :admission-exam="admissionExam"
                            :defaults="addedBlock.defaults || {}"
                            :translation="plantilla.options.translations"
                            :exams="associatedExams"
                            :preview-mode="previewMode"
                            @resultado-cualitativo-exam-selected="(payload) => handleResultadoCualitativoExamSelected(payload, addedBlock)"
                            @resultado-cualitativo-result-changed="(payload) => handleResultadoCualitativoResultChanged(payload, addedBlock)"
                            :disable-inputs="shouldDisableBlockInputs"
                        ></resultado-cualitativo-block>
                    </vue-draggable-resizable>

                    <vue-draggable-resizable
                        v-if="addedBlock.block.code === 'resultado-cuantitativo-block'"
                        :w="addedBlock.size.width"
                        :h="addedBlock.size.height"
                        :x="addedBlock.position.x"
                        :y="addedBlock.position.y - page.position.top"
                        @dragstop="(x, y) => $emit('block-dragged', { x, y, addedBlock, page })"
                        @resizestop="(x, y, width, height) => $emit('block-resized', { x, y, width, height, addedBlock })"
                        :grid="[20, 20]"
                        :draggable="!previewMode"
                        :resizable="!previewMode"
                        tabindex="0"
                        @keydown.native.delete="handleDeleteBlock($event.keyCode, addedBlock)"
                        :class="blockComputedClasses"
                    >
                        <resultado-cuantitativo-block
                            :admission-exam="admissionExam"
                            :defaults="addedBlock.defaults || {}"
                            :translation="plantilla.options.translations"
                            :preview-mode="previewMode"
                            :exams="associatedExams"
                            @focus-changed="(value) => (isTextInputFocused = value)"
                            @resultado-cuantitativo-exam-selected="(payload) => handleResultadoCuantitativoExamSelected(payload, addedBlock)"
                            @resultado-cuantitativo-result-changed="(payload) => handleResultadoCuantitativoResultChanged(payload, addedBlock)"
                            :disable-inputs="shouldDisableBlockInputs"
                        ></resultado-cuantitativo-block>
                    </vue-draggable-resizable>

                    <vue-draggable-resizable
                        v-if="addedBlock.block.code === 'resultado-free-text-block'"
                        :w="addedBlock.size.width"
                        :h="addedBlock.size.height"
                        :x="addedBlock.position.x"
                        :y="addedBlock.position.y - page.position.top"
                        @dragstop="(x, y) => $emit('block-dragged', { x, y, addedBlock, page })"
                        @resizestop="(x, y, width, height) => $emit('block-resized', { x, y, width, height, addedBlock })"
                        :grid="[20, 20]"
                        :draggable="!isTextInputFocused && !previewMode"
                        :resizable="!previewMode"
                        tabindex="0"
                        @keydown.native.delete="handleDeleteBlock($event.keyCode, addedBlock)"
                        :class="blockComputedClasses"
                    >
                        <resultado-free-text-block
                            :admission-exam="admissionExam"
                            :defaults="addedBlock.defaults || {}"
                            :translation="plantilla.options.translations"
                            :exams="associatedExams"
                            :preview-mode="previewMode"
                            @focus-changed="(value) => (isTextInputFocused = value)"
                            @resultado-free-text-exam-selected="(payload) => handleResultadoFreeTextExamSelected(payload, addedBlock)"
                            @resultado-free-text-result-changed="(payload) => handleResultadoFreeTextResultChanged(payload, addedBlock)"
                            :disable-inputs="shouldDisableBlockInputs"
                        ></resultado-free-text-block>
                    </vue-draggable-resizable>

                    <vue-draggable-resizable
                        v-if="addedBlock.block.code === 'resultado-collection-block'"
                        :w="addedBlock.size.width"
                        :h="addedBlock.size.height"
                        :x="addedBlock.position.x"
                        :y="addedBlock.position.y - page.position.top"
                        @dragstop="(x, y) => $emit('block-dragged', { x, y, addedBlock, page })"
                        @resizestop="(x, y, width, height) => $emit('block-resized', { x, y, width, height, addedBlock })"
                        :grid="[20, 20]"
                        :draggable="!previewMode"
                        :resizable="!previewMode"
                        tabindex="0"
                        @keydown.native.delete="handleDeleteBlock($event.keyCode, addedBlock)"
                        :class="blockComputedClasses"
                    >
                        <resultado-collection-block
                            :admission-exam="admissionExam"
                            :associated-exams="associatedExams"
                            :block-id="addedBlock.id"
                            :collection-blocks="resultadoCollectionBlocks"
                            :defaults="addedBlock.defaults || {}"
                            :translation="plantilla.options.translations"
                            :preview-mode="previewMode"
                            @focus-changed="(value) => (isTextInputFocused = value)"
                            @resultado-collection-result-changed="(payload) => handleResultadoCollectionResultChanged(payload, addedBlock)"
                            :disable-inputs="shouldDisableBlockInputs"
                        ></resultado-collection-block>
                    </vue-draggable-resizable>

                    <vue-draggable-resizable
                        v-if="addedBlock.block.code === 'processing-method-block'"
                        :w="addedBlock.size.width"
                        :h="addedBlock.size.height"
                        :x="addedBlock.position.x"
                        :y="addedBlock.position.y - page.position.top"
                        @dragstop="(x, y) => $emit('block-dragged', { x, y, addedBlock, page })"
                        @resizestop="(x, y, width, height) => $emit('block-resized', { x, y, width, height, addedBlock })"
                        :grid="[20, 20]"
                        :draggable="!previewMode"
                        :resizable="!previewMode"
                        tabindex="0"
                        @keydown.native.delete="handleDeleteBlock($event.keyCode, addedBlock)"
                        :class="blockComputedClasses"
                    >
                        <processing-method-block
                            :defaults="addedBlock.defaults || {}"
                            :translation="plantilla.options.translations"
                            :preview-mode="previewMode"
                            :exams="associatedExams"
                            :exams-agrupadores="examsAgrupadores"
                            @processing-method-exam-selected="(payload) => handleProcessingMethodExamSelected(payload, addedBlock)"
                            @processing-method-method-selected="(payload) => handleProcessingMethodMethodSelected(payload, addedBlock)"
                            :disable-inputs="shouldDisableBlockInputs"
                        ></processing-method-block>
                    </vue-draggable-resizable>

                    <vue-draggable-resizable
                        v-if="addedBlock.block.code === 'report-type-block'"
                        :w="addedBlock.size.width"
                        :h="addedBlock.size.height"
                        :x="addedBlock.position.x"
                        :y="addedBlock.position.y - page.position.top"
                        @dragstop="(x, y) => $emit('block-dragged', { x, y, addedBlock, page })"
                        @resizestop="(x, y, width, height) => $emit('block-resized', { x, y, width, height, addedBlock })"
                        :grid="[20, 20]"
                        :draggable="!previewMode"
                        :resizable="!previewMode"
                        tabindex="0"
                        @keydown.native.delete="handleDeleteBlock($event.keyCode, addedBlock)"
                        :class="blockComputedClasses"
                    >
                        <report-type-block
                            :preview-mode="previewMode"
                            :translation="plantilla.options.translations"
                            :defaults="addedBlock.defaults || {}"
                            @report-type-selected="(payload) => handleReportTypeSelected(payload, addedBlock)"
                            :disable-inputs="shouldDisableBlockInputs"
                        ></report-type-block>
                    </vue-draggable-resizable>

                    <vue-draggable-resizable
                        v-if="addedBlock.block.code === 'observations-block'"
                        :w="addedBlock.size.width"
                        :h="addedBlock.size.height"
                        :x="addedBlock.position.x"
                        :y="addedBlock.position.y - page.position.top"
                        @dragstop="(x, y) => $emit('block-dragged', { x, y, addedBlock, page })"
                        @resizestop="(x, y, width, height) => $emit('block-resized', { x, y, width, height, addedBlock })"
                        :grid="[20, 20]"
                        :draggable="!previewMode && !isTextInputFocused"
                        :resizable="!previewMode"
                        tabindex="0"
                        @keydown.native.delete="handleDeleteBlock($event.keyCode, addedBlock)"
                        :class="blockComputedClasses"
                    >
                        <observations-block
                            :preview-mode="previewMode"
                            :translation="plantilla.options.translations"
                            :defaults="addedBlock.defaults || {}"
                            @observations-input-changed="(payload) => handleObservationsChanged(payload, addedBlock)"
                            @focus-changed="(value) => (isTextInputFocused = value)"
                            :disable-inputs="shouldDisableBlockInputs"
                        ></observations-block>
                    </vue-draggable-resizable>

                    <vue-draggable-resizable
                        v-if="addedBlock.block.code === 'class-interpretation-block'"
                        :w="addedBlock.size.width"
                        :h="addedBlock.size.height"
                        :x="addedBlock.position.x"
                        :y="addedBlock.position.y - page.position.top"
                        @dragstop="(x, y) => $emit('block-dragged', { x, y, addedBlock, page })"
                        @resizestop="(x, y, width, height) => $emit('block-resized', { x, y, width, height, addedBlock })"
                        :grid="[20, 20]"
                        :draggable="!previewMode"
                        :resizable="!previewMode"
                        tabindex="0"
                        @keydown.native.delete="handleDeleteBlock($event.keyCode, addedBlock)"
                        :class="blockComputedClasses"
                    >
                        <class-interpretation-block></class-interpretation-block>
                    </vue-draggable-resizable>

                    <!-- Bloques de datos -->
                    <vue-draggable-resizable
                        v-if="addedBlock.block.code === 'text-block'"
                        :w="addedBlock.size.width"
                        :h="addedBlock.size.height"
                        :x="addedBlock.position.x"
                        :y="addedBlock.position.y - page.position.top"
                        @dragstop="(x, y) => $emit('block-dragged', { x, y, addedBlock, page })"
                        @resizestop="(x, y, width, height) => $emit('block-resized', { x, y, width, height, addedBlock })"
                        :grid="[20, 20]"
                        :draggable="!previewMode && !isTextInputFocused"
                        :resizable="!previewMode"
                        tabindex="0"
                        @keyup.native.delete="handleDeleteBlock($event.keyCode, addedBlock)"
                        :class="blockComputedClasses"
                    >
                        <text-block
                            :defaults="addedBlock.defaults || {}"
                            :preview-mode="previewMode"
                            @focus-changed="(value) => (isTextInputFocused = value)"
                            @text-input-changed="(payload) => handleTextBlockInputChanged(payload, addedBlock)"
                            :disable-inputs="shouldDisableBlockInputs"
                        ></text-block>
                    </vue-draggable-resizable>

                    <vue-draggable-resizable
                        v-if="addedBlock.block.code === 'table-block'"
                        :w="addedBlock.size.width"
                        :h="addedBlock.size.height"
                        :x="addedBlock.position.x"
                        :y="addedBlock.position.y - page.position.top"
                        @dragstop="(x, y) => $emit('block-dragged', { x, y, addedBlock, page })"
                        @resizestop="(x, y, width, height) => $emit('block-resized', { x, y, width, height, addedBlock })"
                        :grid="[20, 20]"
                        :draggable="!previewMode"
                        :resizable="!previewMode"
                        tabindex="0"
                        @keydown.native.delete="handleDeleteBlock($event.keyCode, addedBlock)"
                        :class="blockComputedClasses"
                    >
                        <table-block
                            :preview-mode="previewMode"
                            :dimensions="addedBlock.defaults.dimensions || { cols: 2, rows: 2 }"
                            :data="addedBlock.defaults.data || []"
                            @dimension-changed="(payload) => handleTableDimensionChanged(payload, addedBlock)"
                            @input="(payload) => handleTableInputChanged(payload, addedBlock)"
                            @focus-changed="(value) => (isTextInputFocused = value)"
                            :disable-inputs="shouldDisableBlockInputs"
                        ></table-block>
                    </vue-draggable-resizable>

                    <vue-draggable-resizable
                        v-if="addedBlock.block.code === 'image-block'"
                        :w="addedBlock.size.width"
                        :h="addedBlock.size.height"
                        :x="addedBlock.position.x"
                        :y="addedBlock.position.y - page.position.top"
                        @dragstop="(x, y) => $emit('block-dragged', { x, y, addedBlock, page })"
                        @resizestop="(x, y, width, height) => $emit('block-resized', { x, y, width, height, addedBlock })"
                        :grid="[20, 20]"
                        :draggable="!previewMode"
                        :resizable="!previewMode"
                        :lock-aspect-ratio="true"
                        tabindex="0"
                        @keydown.native.delete="handleDeleteBlock($event.keyCode, addedBlock)"
                        :class="blockComputedClasses"
                    >
                        <image-block
                            :preview-mode="previewMode"
                            :defaults="addedBlock.defaults || {}"
                            :width="addedBlock.size.width"
                            :height="addedBlock.size.height"
                            @focus-changed="(value) => (isTextInputFocused = value)"
                            @image-url-changed="(payload) => handleImageBlockImageUrlChanged(payload, addedBlock)"
                            @image-uploaded-load-status="(payload) => handleImageBlockImageUploadStatus(payload, addedBlock)"
                            :disable-inputs="shouldDisableBlockInputs"
                        ></image-block>
                    </vue-draggable-resizable>

                    <!-- Bloques de firma -->
                    <vue-draggable-resizable
                        v-if="addedBlock.block.code === 'signature-block'"
                        :w="addedBlock.size.width"
                        :h="addedBlock.size.height"
                        :x="addedBlock.position.x"
                        :y="addedBlock.position.y - page.position.top"
                        @dragstop="(x, y) => $emit('block-dragged', { x, y, addedBlock, page })"
                        @resizestop="(x, y, width, height) => $emit('block-resized', { x, y, width, height, addedBlock })"
                        :grid="[20, 20]"
                        :draggable="!previewMode"
                        :resizable="!previewMode"
                        tabindex="0"
                        @keydown.native.delete="handleDeleteBlock($event.keyCode, addedBlock)"
                        :class="blockComputedClasses"
                    >
                        <signatures-block :defaults="addedBlock.defaults || {}"></signatures-block>
                    </vue-draggable-resizable>

                    <vue-draggable-resizable
                        v-if="addedBlock.block.code === 'responsable-info-block'"
                        :w="addedBlock.size.width"
                        :h="addedBlock.size.height"
                        :x="addedBlock.position.x"
                        :y="addedBlock.position.y - page.position.top"
                        @dragstop="(x, y) => $emit('block-dragged', { x, y, addedBlock, page })"
                        @resizestop="(x, y, width, height) => $emit('block-resized', { x, y, width, height, addedBlock })"
                        :grid="[20, 20]"
                        :draggable="!previewMode"
                        :resizable="!previewMode"
                        tabindex="0"
                        @keydown.native.delete="handleDeleteBlock($event.keyCode, addedBlock)"
                        :class="blockComputedClasses"
                    >
                        <responsable-info-block
                            :translation="plantilla.options.translations"
                            :defaults="addedBlock.defaults || {}"
                        ></responsable-info-block>
                    </vue-draggable-resizable>

                    <vue-draggable-resizable
                        v-if="addedBlock.block.code === 'director-tecnico-signature-block'"
                        :w="addedBlock.size.width"
                        :h="addedBlock.size.height"
                        :x="addedBlock.position.x"
                        :y="addedBlock.position.y - page.position.top"
                        @dragstop="(x, y) => $emit('block-dragged', { x, y, addedBlock, page })"
                        @resizestop="(x, y, width, height) => $emit('block-resized', { x, y, width, height, addedBlock })"
                        :grid="[20, 20]"
                        :draggable="!previewMode"
                        :resizable="!previewMode"
                        tabindex="0"
                        @keydown.native.delete="handleDeleteBlock($event.keyCode, addedBlock)"
                        :class="blockComputedClasses"
                    >
                        <director-tecnico-signature-block></director-tecnico-signature-block>
                    </vue-draggable-resizable>

                    <vue-draggable-resizable
                        v-if="addedBlock.block.code === 'cmio-signatures-block'"
                        :w="addedBlock.size.width"
                        :h="addedBlock.size.height"
                        :x="addedBlock.position.x"
                        :y="addedBlock.position.y - page.position.top"
                        @dragstop="(x, y) => $emit('block-dragged', { x, y, addedBlock, page })"
                        @resizestop="(x, y, width, height) => $emit('block-resized', { x, y, width, height, addedBlock })"
                        :grid="[20, 20]"
                        :draggable="!previewMode"
                        :resizable="!previewMode"
                        tabindex="0"
                        @keydown.native.delete="handleDeleteBlock($event.keyCode, addedBlock)"
                        :class="blockComputedClasses"
                    >
                        <cmio-signatures-block></cmio-signatures-block>
                    </vue-draggable-resizable>

                    <!-- QR -->
                    <vue-draggable-resizable
                        v-if="addedBlock.block.code === 'qr-verification-block'"
                        :w="addedBlock.size.width"
                        :h="addedBlock.size.height"
                        :x="addedBlock.position.x"
                        :y="addedBlock.position.y - page.position.top"
                        @dragstop="(x, y) => $emit('block-dragged', { x, y, addedBlock, page })"
                        @resizestop="(x, y, width, height) => $emit('block-resized', { x, y, width, height, addedBlock })"
                        :grid="[20, 20]"
                        :draggable="!previewMode"
                        :resizable="!previewMode"
                        tabindex="0"
                        @keydown.native.delete="handleDeleteBlock($event.keyCode, addedBlock)"
                        :class="blockComputedClasses"
                        lock-aspect-ratio
                    >
                        <qr-verification-block
                            :size="addedBlock.size.width"
                            :admission-exam-id="admissionExam.id"
                            :exam-result="admissionExam.exam_result"
                            :patient-id="admissionExam.admission.patient.id"
                            :defaults="addedBlock.defaults || {}"
                        ></qr-verification-block>
                    </vue-draggable-resizable>
                </slot>
            </template>
        </plantilla-pdf-page>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { examStatuses, resultTypes } from "@/app/constants";

import PlantillaPdfPage from "@/app/components-migration/plantillas/plantilla-pdf-page.vue";

import LogoBlock from "@/app/components-migration/plantillas/blocks/logo-block.vue";
import FooterBlock from "@/app/components-migration/plantillas/blocks/footer-block.vue";
import PatientInfoBlock from "@/app/components-migration/plantillas/blocks/patient-info-block.vue";
import ExamInfoBlock from "@/app/components-migration/plantillas/blocks/exam-info-block.vue";

import ResultadoCualitativoBlock from "@/app/components-migration/plantillas/blocks/resultado-cualitativo-block.vue";
import ResultadoCuantitativoBlock from "@/app/components-migration/plantillas/blocks/resultado-cuantitativo-block.vue";
import ResultadoFreeTextBlock from "@/app/components-migration/plantillas/blocks/resultado-free-text-block.vue";
import ResultadoCollectionBlock from "@/app/components-migration/plantillas/blocks/resultado-collection-block.vue";
import ProcessingMethodBlock from "@/app/components-migration/plantillas/blocks/processing-method-block.vue";
import ReportTypeBlock from "@/app/components-migration/plantillas/blocks/report-type-block.vue";

import TextBlock from "@/app/components-migration/plantillas/blocks/text-block.vue";
import ImageBlock from "@/app/components-migration/plantillas/blocks/image-block.vue";
import TableBlock from "@/app/components-migration/plantillas/blocks/table-block.vue";
import ObservationsBlock from "@/app/components-migration/plantillas/blocks/observations-block.vue";
import ClassInterpretationBlock from "@/app/components-migration/plantillas/blocks/class-interpretation-block.vue";
import QRVerificationBlock from "@/app/components-migration/plantillas/blocks/qr-verification-block.vue";

import SignaturesBlock from "@/app/components-migration/plantillas/blocks/signatures-block.vue";
import ResponsableInfoBlock from "@/app/components-migration/plantillas/blocks/responsable-info-block.vue";
import DirectorTecnicoSignatureBlock from "@/app/components-migration/plantillas/blocks/director-tecnico-signature-block.vue";
import CMIOSignaturesBlock from "@/app/components-migration/plantillas/blocks/cmio-signatures-block.vue";

export default {
    components: {
        "plantilla-pdf-page": PlantillaPdfPage,
        "patient-info-block": PatientInfoBlock,
        "exam-info-block": ExamInfoBlock,
        "text-block": TextBlock,
        "logo-block": LogoBlock,
        "footer-block": FooterBlock,
        "resultado-cualitativo-block": ResultadoCualitativoBlock,
        "resultado-cuantitativo-block": ResultadoCuantitativoBlock,
        "resultado-free-text-block": ResultadoFreeTextBlock,
        "resultado-collection-block": ResultadoCollectionBlock,
        "processing-method-block": ProcessingMethodBlock,
        "report-type-block": ReportTypeBlock,
        "signatures-block": SignaturesBlock,
        "responsable-info-block": ResponsableInfoBlock,
        "director-tecnico-signature-block": DirectorTecnicoSignatureBlock,
        "cmio-signatures-block": CMIOSignaturesBlock,
        "image-block": ImageBlock,
        "table-block": TableBlock,
        "observations-block": ObservationsBlock,
        "class-interpretation-block": ClassInterpretationBlock,
        "qr-verification-block": QRVerificationBlock,
    },

    props: {
        isEditor: Boolean,
        plantilla: Object,
        admissionExam: Object,
        reset: Boolean,
    },

    data() {
        return {
            isTextInputFocused: false,
        };
    },

    created() {
        this.initPlantilla();
    },

    computed: {
        ...mapGetters(["getExam"]),

        ...mapGetters("plantillas", ["currentStatusIdByReportType"]),

        ...mapState("plantillas", ["selectedReportType"]),

        ...mapState(["env"]),

        plantillaBlocks() {
            return Object.values(this.plantilla.blocks || {});
        },

        groupedBlocks() {
            if (this.plantillaBlocks.length === 0) return {};

            let groups = {};

            this.plantillaBlocks.forEach((addedBlock) => {
                let pageIndex = this.plantilla.pages.findIndex(
                    (p) => addedBlock.position.y >= p.position.top && addedBlock.position.y <= p.position.bottom
                );

                if (pageIndex === -1) {
                    pageIndex = 0;
                }

                if (!groups[pageIndex]) groups[pageIndex] = {};

                groups[pageIndex][addedBlock.id] = addedBlock;
            });

            return groups;
        },

        resultadoCollectionBlocks() {
            return this.plantillaBlocks.filter((b) => b.block.code === "resultado-collection-block");
        },

        previewMode() {
            return this.plantilla.options.previewMode || false;
        },

        examsAgrupadores() {
            if (!this.plantilla.exams || !Object.keys(this.plantilla.exams || {}).length) return [];

            const exams = [];

            Object.keys(this.plantilla.exams).forEach((examId) => {
                const exam = this.getExam(examId);
                exams.push(exam);
            });

            return exams;
        },

        associatedExams() {
            if (!this.plantilla.exams || !Object.keys(this.plantilla.exams || {}).length) return [];

            const exams = [];

            Object.keys(this.plantilla.exams).forEach((examId) => {
                const exam = this.getExam(examId);

                if (exam.associations && exam.associations.length) {
                    exam.associations.forEach((associatedExam) => {
                        exams.push(associatedExam.associated_exam);
                    });
                } else {
                    exams.push(exam);
                }
            });

            return exams;
        },

        shouldDisableBlockInputs() {
            return this.currentStatusIdByReportType >= examStatuses.examen_confirmado;
        },

        blockComputedClasses() {
            const classes = {};

            if (!this.previewMode) {
                classes["bg-color-white"] = true;
                classes["cursor-grab"] = true;
            } else {
                classes["border-none"] = true;
            }

            return classes;
        },

        computedPlantillaStyles() {
            const currentScale = this.plantilla.options.currentScale;
            const pagesCount = this.plantilla.pages.length;

            const styles = {
                transform: `scale(${currentScale})`,
            };

            // el valor base debe incrementar segun la cantidad de paginas
            const basePx = 50 + 2 * pagesCount;

            if (currentScale < 1) {
                const correction = basePx * ((10 - currentScale * 10) * pagesCount);

                styles["margin"] = `-${correction}px 0 0 0`;
            }

            // todo: añadir correcion para zoom

            return styles;
        },
    },

    watch: {
        reset() {
            this.initPlantilla();
        },
    },

    methods: {
        ...mapActions(["getAPIInfo"]),

        initPlantilla() {
            this.setDefaultObjectForEachBlock();

            if (!this.isEditor && this.admissionExam.exam_result && this.admissionExam.exam_result.length > 0) {
                const examResult = this.admissionExam.exam_result[0];

                this.setResultsDefaults(examResult);
                this.setProcessingMethodDefaults(examResult);
                this.setReportTypeDefults(examResult);
                this.setObservationsDefaults(examResult);

                this.setSignatureDefaults(this.admissionExam);

                this.setFirebaseDataDefaults();

                const plantillaShouldBeBlocked = this.currentStatusIdByReportType >= examStatuses.examen_impreso;

                this.$emit("plantilla-is-blocked", plantillaShouldBeBlocked);
            }
        },

        setDefaultObjectForEachBlock() {
            Object.values(this.plantilla.blocks || {}).forEach((block) => {
                if (!block.defaults) {
                    this.$set(this.plantilla.blocks[block.id], "defaults", {});
                }
            });
        },

        setResultsDefaults(examResult) {
            const hasResultado = (resultTypeId, exam) => {
                return exam.result_types ? exam.result_types.findIndex((rt) => rt.result_type === resultTypeId) !== -1 : false;
            };

            const isCollection = this.admissionExam.exam.associations.length > 0;

            const resultadoCollectionBlocks = Object.values(this.plantilla.blocks || {}).filter((b) => b.block.code === "resultado-collection-block");

            if (resultadoCollectionBlocks.length > 0) {
                let results = {};

                resultadoCollectionBlocks.forEach((collectionBlock) => {
                    const examsIds = Object.keys(collectionBlock.defaults?.results || {}).map((id) => Number(id));

                    const associatedExams = this.associatedExams.filter((e) => examsIds.includes(e.id));

                    const blockResults = {};

                    associatedExams.forEach((associatedExam) => {
                        const examId = associatedExam.id;

                        if (!blockResults[examId]) blockResults[examId] = {};

                        const detail = examResult.details.find((d) => d.exam_id === examId);

                        if (detail && hasResultado(resultTypes.cualitativo, associatedExam)) {
                            const detailResult = isCollection ? detail.value : detail.result;

                            blockResults[examId].cualitativo = { result: detailResult };
                        }
                        if (detail && hasResultado(resultTypes.cuantitativo, associatedExam)) {
                            const detailResult = isCollection ? detail.numerical_value : detail.numerical_result;
                            const unit = detail.unit;

                            blockResults[examId].cuantitativo = { result: detailResult, unit };
                        }
                        if (detail && hasResultado(resultTypes.free_text, associatedExam)) {
                            const detailResult = detail.result;

                            blockResults[examId].freeText = { result: detailResult };
                        }
                    });

                    results = {
                        ...results,
                        ...blockResults,
                    };

                    this.$set(this.plantilla.blocks[collectionBlock.id].defaults, "results", results);
                });
            } else {
                const resultBlocks = Object.values(this.plantilla.blocks || {}).filter(
                    (b) =>
                        b.block.code === "resultado-free-text-block" ||
                        b.block.code === "resultado-cualitativo-block" ||
                        b.block.code === "resultado-cuantitativo-block"
                );

                resultBlocks.forEach((resultBlock) => {
                    const detail = examResult.details.find((d) => d.exam_id === resultBlock.defaults.examId);

                    if (resultBlock.block.code === "resultado-cualitativo-block") {
                        const detailResult = isCollection ? detail.value : detail.result;
                        this.$set(this.plantilla.blocks[resultBlock.id].defaults, "result", detailResult);
                    } else if (resultBlock.block.code === "resultado-cuantitativo-block") {
                        const detailResult = isCollection ? detail.numerical_value : detail.numerical_result;
                        const unit = detail.unit;
                        this.$set(this.plantilla.blocks[resultBlock.id].defaults, "result", detailResult);
                        this.$set(this.plantilla.blocks[resultBlock.id].defaults, "unit", unit);
                    } else if (resultBlock.block.code === "resultado-free-text-block") {
                        // histopatologico (collection: false) -> detail.result
                        let detailResult = "";

                        if (detail.value) {
                            detailResult = detail.value;
                        } else if (detail.result) {
                            detailResult = detail.result;
                        }

                        this.$set(this.plantilla.blocks[resultBlock.id].defaults, "result", detailResult);
                    }
                });
            }
        },
        setProcessingMethodDefaults(examResult) {
            const processingMethodBlock = Object.values(this.plantilla.blocks || {}).find((b) => b.block.code === "processing-method-block");

            if (processingMethodBlock) {
                this.$set(this.plantilla.blocks[processingMethodBlock.id].defaults, "processingMethodId", examResult.method_id);
            }
        },
        setReportTypeDefults(examResult) {
            const reportTypeBlock = Object.values(this.plantilla.blocks || {}).find((b) => b.block.code === "report-type-block");

            if (reportTypeBlock) {
                this.$set(this.plantilla.blocks[reportTypeBlock.id].defaults, "reportType", examResult.report_type);
            }
        },
        setObservationsDefaults(examResult) {
            const observationsBlock = Object.values(this.plantilla.blocks || {}).find((b) => b.block.code === "observations-block");

            if (observationsBlock) {
                this.$set(this.plantilla.blocks[observationsBlock.id].defaults, "observations", examResult.observation);
            }
        },
        setSignatureDefaults(admissionExam) {
            let signedByUidByReportType;

            if (this.selectedReportType === "Informe preliminar") {
                signedByUidByReportType = admissionExam.signed_by_preliminar;
            } else if (this.selectedReportType === "Informe final") {
                signedByUidByReportType = admissionExam.signed_by_final;
            } else if (this.selectedReportType === "Informe complementario") {
                signedByUidByReportType = admissionExam.signed_by_compl;
            }

            const signatureBlock = Object.values(this.plantilla.blocks || {}).find((b) => b.block.code === "signature-block");
            const responsableInfoBlock = Object.values(this.plantilla.blocks || {}).find((b) => b.block.code === "responsable-info-block");

            if ((signatureBlock || responsableInfoBlock) && signedByUidByReportType) {
                this.$firebase
                    .auth()
                    .currentUser.getIdToken()
                    .then((idToken) => {
                        return this.getAPIInfo({ url: `users/${signedByUidByReportType}/signature`, userToken: idToken });
                    })
                    .then((resp) => {
                        const signatureObject = resp.data.signature;

                        let env = this.env === "production" ? "prod" : "dev";

                        if (signatureBlock) {
                            this.$set(this.plantilla.blocks[signatureBlock.id].defaults, "signatureName", signatureObject.user_name);
                            this.$set(this.plantilla.blocks[signatureBlock.id].defaults, "signatureUrl", signatureObject[`signature_url_${env}`]);
                        }

                        if (responsableInfoBlock) {
                            this.$set(this.plantilla.blocks[responsableInfoBlock.id].defaults, "responsableName", signatureObject.user_name);
                            this.$set(this.plantilla.blocks[responsableInfoBlock.id].defaults, "responsablePrefix", signatureObject.user_prefix);
                            this.$set(
                                this.plantilla.blocks[responsableInfoBlock.id].defaults,
                                "responsableSignture",
                                signatureObject[`signature_url_${env}`]
                            );
                        }
                    });
            }
        },
        async setFirebaseDataDefaults() {
            this.$f7.dialog.close();
            this.$f7.dialog.preloader("Cargando data de resultados...");

            const resultsDataSnapshot = await this.$firebase
                .database()
                .ref(`/data_results_plantillas/${this.admissionExam.id}/${this.plantilla.id}/${this.selectedReportType || "default"}`)
                .once("value");

            const resultsData = resultsDataSnapshot.val();

            console.log("results data fetched", resultsData);

            if (resultsData) {
                this.plantillaBlocks.forEach((block) => {
                    if (resultsData[block.id]) {
                        const data = resultsData[block.id];

                        const updatedBlockData = {
                            ...this.plantilla.blocks[block.id],
                            defaults: data.defaults,
                            size: data.size,
                            position: data.position,
                        };

                        this.$set(this.plantilla.blocks, block.id, updatedBlockData);
                    }
                });
            }

            this.$f7.dialog.close();
        },

        // Blocks handlers
        handleDeleteBlock(keyCode, addedBlock) {
            if (this.isTextInputFocused && (keyCode !== 46 || keyCode !== 8)) return;

            this.$f7.dialog.confirm("Esta acción será irreversible", "Eliminar bloque", () => {
                this.$emit("block-deleted", { blockId: addedBlock.id });
            });
        },

        // Text block
        handleTextBlockInputChanged(payload, addedBlock) {
            this.$emit("block-data-changed", {
                blockCode: addedBlock.block.code,
                blockId: addedBlock.id,
                data: payload,
            });
        },

        // Table block
        handleTableDimensionChanged(payload, addedBlock) {
            this.$emit("block-data-changed", {
                blockCode: addedBlock.block.code,
                blockId: addedBlock.id,
                data: payload,
                whatChanged: "table-dimensions",
            });
        },
        handleTableInputChanged(payload, addedBlock) {
            this.$emit("block-data-changed", {
                blockCode: addedBlock.block.code,
                blockId: addedBlock.id,
                data: payload,
                whatChanged: "table-data",
            });
        },

        // Resultado cualitativo handlers
        handleResultadoCualitativoExamSelected(payload, addedBlock) {
            this.$emit("block-data-changed", {
                blockCode: addedBlock.block.code,
                blockId: addedBlock.id,
                data: payload,
                whatChanged: "exam-selected",
            });
        },
        handleResultadoCualitativoResultChanged(payload, addedBlock) {
            this.$emit("block-data-changed", {
                blockCode: addedBlock.block.code,
                blockId: addedBlock.id,
                data: payload,
                whatChanged: "result-changed",
            });
        },

        // Resultado cuantitativo handlers
        handleResultadoCuantitativoExamSelected(payload, addedBlock) {
            this.$emit("block-data-changed", {
                blockCode: addedBlock.block.code,
                blockId: addedBlock.id,
                data: payload,
                whatChanged: "exam-selected",
            });
        },
        handleResultadoCuantitativoResultChanged(payload, addedBlock) {
            this.$emit("block-data-changed", {
                blockCode: addedBlock.block.code,
                blockId: addedBlock.id,
                data: payload,
                whatChanged: "result-changed",
            });
        },

        // Resultado free text handlers
        handleResultadoFreeTextExamSelected(payload, addedBlock) {
            this.$emit("block-data-changed", {
                blockCode: addedBlock.block.code,
                blockId: addedBlock.id,
                data: payload,
                whatChanged: "exam-selected",
            });
        },
        handleResultadoFreeTextResultChanged(payload, addedBlock) {
            this.$emit("block-data-changed", {
                blockCode: addedBlock.block.code,
                blockId: addedBlock.id,
                data: payload,
                whatChanged: "result-changed",
            });
        },
        handleResultadoCollectionResultChanged(payload, addedBlock) {
            this.$emit("block-data-changed", {
                blockCode: addedBlock.block.code,
                blockId: addedBlock.id,
                data: payload,
            });
        },

        // Processing methods handlers
        handleProcessingMethodExamSelected(payload, addedBlock) {
            this.$emit("block-data-changed", {
                blockCode: addedBlock.block.code,
                blockId: addedBlock.id,
                data: payload,
                whatChanged: "exam-selected",
            });
        },
        handleProcessingMethodMethodSelected(payload, addedBlock) {
            this.$emit("block-data-changed", {
                blockCode: addedBlock.block.code,
                blockId: addedBlock.id,
                data: payload,
                whatChanged: "method-selected",
            });
        },

        // Image block handlers
        handleImageBlockImageUrlChanged(payload, addedBlock) {
            this.$emit("block-data-changed", {
                blockCode: addedBlock.block.code,
                blockId: addedBlock.id,
                data: payload,
                whatChanged: "image-url-changed",
            });
        },
        handleImageBlockImageUploadStatus(payload, addedBlock) {
            this.$emit("block-data-changed", {
                blockCode: addedBlock.block.code,
                blockId: addedBlock.id,
                data: payload,
                whatChanged: "image-uploaded-load-status",
            });
        },

        // Report type handlers
        handleReportTypeSelected(payload, addedBlock) {
            this.$emit("block-data-changed", {
                blockCode: addedBlock.block.code,
                blockId: addedBlock.id,
                data: payload,
                whatChanged: "report-type-selected",
            });
        },

        // Observations handlers
        handleObservationsChanged(payload, addedBlock) {
            this.$emit("block-data-changed", {
                blockCode: addedBlock.block.code,
                blockId: addedBlock.id,
                data: payload,
            });
        },

        // Data getters
        getExamPatient() {
            return this.admissionExam.admission.patient;
        },
    },
};
</script>

<style scoped>
.cursor-grab {
    cursor: grab;
}
.border-none {
    border: none;
}
</style>
