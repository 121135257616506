<template>
    <f7-page >
        <f7-navbar>
            <f7-nav-title >{{ form.description }}</f7-nav-title>
        </f7-navbar>
        <form @submit.prevent="submit" >
            <f7-block>
                <f7-row class="mb-2">
                    <f7-col width="100" >
                        <label>Nombre del proveedor</label>
                        <f7-input :value="form.description" required type="text" placeholder="" info="Nombre comercial del proveedor" @change="form.description = $event.target.value" />
                    </f7-col>
                </f7-row>
                <f7-row class="mb-2">
                    <f7-col width="50" >
                        <label>RUT</label>
                        <f7-input :value="form.dnu" type="text" placeholder="" info="Documento fiscal del proveedor" @change="form.dnu = $event.target.value" />
                    </f7-col>
                    <f7-col width="50" >
                        <label>Telefono</label>
                        <f7-input :value="form.phone" type="text" placeholder="" info="Telefono de contacto" @input="formater($event.target.value)" />
                    </f7-col>
                </f7-row>
                <f7-row class="mb-2">
                    <f7-col width="100" >
                        <label>Nombre del vendedor</label>
                        <f7-input :value="form.seller" type="text" placeholder="" info="Nombre del vendedor asignado a la empresa " @change="form.seller = $event.target.value" />
                    </f7-col>
                </f7-row>
                <f7-row class="mb-7">
                    <f7-col width="100" >
                        <label>Observaciones</label>
                        <f7-input :value="form.details" rows="5" type="textarea" placeholder="" info="Observaciones y detalles referenciales" @change="form.details = $event.target.value" />
                    </f7-col>
                </f7-row>
                <f7-row class="mb-2">
                    <f7-col/>
                    <f7-col/>
                    <f7-col/>
                    <f7-col>
                        <f7-button fill type="submit">Guardar</f7-button>
                        
                    </f7-col>
                </f7-row>
            </f7-block>
        </form>
    </f7-page>
</template>

<script>

import {  validate, clean, format, getCheckDigit } from 'rut.js'


export default {
    props : {
        id : { required : true }
    },
    data(){
        return {
            form : {
                id : "",
                description : "",
                dnu : "",
                phone : "",
                seller : "",
                details : ""
            }
        } 
    },
    mounted(){
        let textareas = document.getElementsByTagName('textarea');
    
        for (let item of textareas) {
            item.setAttribute('rows', 5);
        }

       this.init();
    },
    methods : {
        formater(value){
            this.form.phone = value.replace(/[a-z]/gi, '') 
        },
        submit(){

            var reg = new RegExp("-")
            if(this.form.dnu.length == 9 && this.form.dnu.search(reg) == -1){
                this.$store.dispatch("toast",{ app : this, text : "Debe ingresar un RUT Valido" });
                return false;
            }

            if(!validate(this.form.dnu)){
                this.$store.dispatch("toast",{ app : this, text : "Debe ingresar un RUT Valido" });
                return false;
            }

            this.$firebase.auth().currentUser.getIdToken().then(async (token) => {
                var result = await this.$store.dispatch("apiRequest",{ url : "universal/" ,token , method : "PUT", data : { 'info' : this.form, 'model' : 'Provider', search : this.$props.id}, app : this});

                if(result.code == 200){
                    this.init();
                    this.$store.dispatch("updateState",{token, state : [{"path" : "universal/?model=Provider", "model" : "provider"}]});
                }
            });
        },
        init(){

            this.$firebase.auth().currentUser.getIdToken().then(async (token) => {

                var result = await this.$store.dispatch("apiRequest",{ url : "universal/search" ,token , method : "POST", data : { model: "Provider" ,field : "id" , "search" : this.$props.id}});
                console.log(result);
                this.form.id = this.$props.id;
                this.form.description = result.info.data[0].description;
                this.form.dnu = result.info.data[0].dnu;
                this.form.phone = result.info.data[0].phone;
                this.form.seller = result.info.data[0].seller;
                this.form.details = result.info.data[0].details;
                

            });
        }
    }

}
</script>

<style scoped>
.mb-2{
    margin-bottom: 4% !important;
}
.mb-7{
    margin-bottom: 7% !important;
}
.align-left{
    text-align: left;
}
.pull-right{
    float:right;
}
.pull-left{
    float: left;
}
</style>