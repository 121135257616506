<template>
    <div v-if="!previewMode">
        <block-toolbar :block="computedBlockInfo('resultado-cuantitativo-block')"></block-toolbar>
        <f7-block class="no-margin-vertical">
            <f7-row>
                <f7-col>
                    <label><b>Seleccione el exámen</b></label>
                    <f7-input type="select" @change="handleSelectedExam" class="margin-bottom" :disabled="disableInputs">
                        <option value="">Seleccione exámen...</option>
                        <option v-for="exam in exams" :key="exam.id" :value="exam.id" :selected="exam.id === defaults.examId">{{ exam.name }}</option>
                    </f7-input>
                </f7-col>
                <f7-col>
                    <div v-if="!selectedExam">
                        <p class="text-color-gray">Debe seleccionar un exámen</p>
                    </div>
                    <div v-else-if="selectedExam && !hasResultadoCuantitativo">
                        <p class="text-color-red"><b>Este exámen no tiene resultado cuantitativo</b></p>
                    </div>
                    <template v-else>
                        <label><b>Seleccione el resultado cuantitativo y unidad</b></label>
                        <f7-row>
                            <f7-col width="50">
                                <f7-input
                                    type="text"
                                    placeholder="Ingrese el resultado"
                                    @change="handleResultChanged($event.target.value, 'result')"
                                    :value="result"
                                    @focus="handleFocusChanged(true)"
                                    @blur="handleFocusChanged(false)"
                                    :disabled="disableInputs"
                                ></f7-input>
                            </f7-col>
                            <f7-col width="50">
                                <f7-input type="select" @change="handleResultChanged(Number($event.target.value), 'unit')" :disabled="disableInputs">
                                    <option value="">Seleccione unidad</option>
                                    <option v-for="aUnit in allUnits" :value="aUnit.id" :key="aUnit.id" :selected="aUnit.id == unit">
                                        {{ aUnit.name }}
                                    </option>
                                </f7-input>
                            </f7-col>
                        </f7-row>
                    </template>
                </f7-col>
            </f7-row>
        </f7-block>
    </div>
    <div v-else>
        <div v-if="selectedExam">
            <div v-if="translation"><i>Results</i></div>
            <div>
                <b>{{ `Resultado ${isCollection ? selectedExam.name : ""}`.trim() }}</b>
                :
                {{ `${result ? result : "Sin resultado"} ${unit ? getUnitName(unit) : "Sin unidad"}` }}
            </div>
        </div>
        <div v-else>Sin examen seleccionado</div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BlockToolbarMixin from "@/app/components-migration/plantillas/blocks/toolbar/block-toolbar-mixin";
import BlockToolbar from "@/app/components-migration/plantillas/blocks/toolbar/block-toolbar.vue";

export default {
    mixins: [BlockToolbarMixin],

    props: {
        defaults: Object,
        translation: Boolean,
        admissionExam: Object,
        exams: Array,
        previewMode: Boolean,
        disableInputs: Boolean,
    },

    components: {
        "block-toolbar": BlockToolbar,
    },

    data() {
        return {
            selectedExam: null,

            result: "",
            unit: 0,
        };
    },

    watch: {
        defaults: {
            immediate: true,
            deep: true,
            handler() {
                this.setDefaultsToBlock();
            },
        },

        exams() {
            if (this.exams.length === 0) {
                this.selectedExam = null;

                this.$emit("resultado-cuantitativo-exam-selected", {
                    examId: "",
                });
                this.$emit("resultado-cuantitativo-result-changed", { input: "result", value: "" });
                this.$emit("resultado-cuantitativo-result-changed", { input: "unit", value: "" });
            }
        },

        viewingPlantilla: {
            handler: function(plantilla) {
                if (plantilla) this.setDefaultsToBlock();
            }
        },
    },

    computed: {
        ...mapState("unit", ["allUnits"]),
        ...mapState("plantillas", ["viewingPlantilla"]),

        hasResultadoCuantitativo() {
            if (!this.selectedExam) return false;

            if (!this.selectedExam.result_types) return false;

            const found = this.selectedExam.result_types.find((rt) => rt.result_type === 2);

            return found ? true : false;
        },

        isCollection() {
            if (!this.admissionExam) return false;
            if (!this.admissionExam.exam) return false;
            if (!this.admissionExam.exam.associations) return false;

            return this.admissionExam.exam.associations.length > 0;
        },
    },

    created() {
        if (this.allUnits.length === 0) {
            this.getUnits();
        }
    },

    methods: {
        ...mapActions(["getAPIInfo"]),

        setDefaultsToBlock() {
            if (this.defaults.examId) {
                const exam = this.exams.find((e) => e.id === this.defaults.examId);

                this.selectedExam = exam;
            }

            if (this.defaults.result) this.result = this.defaults.result;
            if (this.defaults.unit) this.unit = this.defaults.unit;
        },

        handleSelectedExam(event) {
            const examId = Number(event.target.value);

            const exam = this.exams.find((e) => e.id === examId);

            this.selectedExam = exam;

            this.$emit("resultado-cuantitativo-exam-selected", {
                examId: this.selectedExam ? this.selectedExam.id : "",
            });
        },

        handleResultChanged(value, input) {
            this[input] = value

            this.$emit("resultado-cuantitativo-result-changed", { input, value });
        },

        handleFocusChanged(value) {
            this.$emit("focus-changed", value);
        },

        getUnitName(unitId) {
            const unit = this.allUnits.find((u) => u.id == unitId);

            if (!unit) return "Sin unidad";

            return unit.name;
        },

        getUnits() {
            this.$f7.dialog.preloader("Cargando...");

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({
                        url: "units",
                        userToken: idToken,
                    });
                })
                .then((response) => {
                    const responseData = response.data;

                    if (responseData) {
                        const units = responseData.data;

                        this.$store.commit("unit/set_all_units", units);
                    }

                    this.$f7.dialog.close();
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error);
                });
        },
    },
};
</script>
