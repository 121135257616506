<template>
    <f7-page name="exams">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title>Exámenes</f7-nav-title>
            <f7-nav-right>
                <f7-link icon-material="refresh" tooltip="Recargar Lista" @click="getExams"></f7-link>
                <f7-link class="searchbar-enable" data-searchbar=".searchbar-demo" icon-material="search"></f7-link>
            </f7-nav-right>
            <f7-searchbar
                class="searchbar-demo"
                remove-diacritics
                expandable
                search-container=".search-list"
                search-in=".item-title, .item-subtitle, .item-text, .item-header"
                :disable-button="!$theme.aurora"
                placeholder="Buscar examen"
            ></f7-searchbar>
        </f7-navbar>

        <f7-list class="searchbar-not-found">
            <f7-list-item title="No hay coincidencias"></f7-list-item>
        </f7-list>

        <f7-list v-if="noExams" class="searchbar-not-found">
            <f7-list-item title="No hay exámenes guardados"></f7-list-item>
        </f7-list>

        <f7-list class="no-margin-top search-list searchbar-found" media-list>
            <f7-list-item
                v-for="(exam, index) in $store.getters.sortArray('allExams')"
                :key="index"
                link="#"
                @click="redirectExamDetails(exam)"
            >
                <div slot="header">
                    <span v-if="!exam.enabled" class="text-color-red">Inactivo</span>
                </div>
                <div slot="title">{{ exam.name }}</div>
                <div slot="subtitle">{{ exam.section ? exam.section.name : "" }}</div>
                <div slot="text">{{ exam.description }}</div>
            </f7-list-item>
        </f7-list>

        <f7-fab position="right-bottom" slot="fixed" color="teal">
            <f7-icon material="add"></f7-icon>
            <f7-icon ios="f7:close" aurora="f7:close" md="material:close"></f7-icon>
            <f7-fab-buttons position="top">
                <f7-fab-button fab-close @click="redirectNewExam" label="Crear nuevo examen">
                    <f7-icon material="playlist_add"></f7-icon>
                </f7-fab-button>
            </f7-fab-buttons>
        </f7-fab>
    </f7-page>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
    data() {
        return {
            noExams: false,
        };
    },
    computed: {
        ...mapState(["allExams"]),
    },
    methods: {
        ...mapActions(["getAPIInfo"]),

        getExams() {
            this.$f7.dialog.preloader("Cargando");
            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: "exams/list", userToken: idToken });
                })
                .then((resp) => {
                    //let enabledExams = resp.data.data.filter((exam) => exam.enabled === 1);
                    const allExams = resp.data.data;
                    this.noExams = false;
                    this.$store.commit("setWhatTo", { what: "allExams", to: allExams });
                    this.$f7.dialog.close();
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    if (err.response.status !== 404) {
                        this.$f7.dialog.alert(err.response.data.message || err.message, "Ops, ha ocurrido un error");
                    } else {
                        this.noExams = true;
                    }
                });
        },

        redirectNewExam() {
            this.$store.commit("setWhatTo", { what: "selectedExam", to: null });
            this.$f7.views.main.router.navigate("/migration/mantenedores/exams/create-or-edit");
        },

        redirectExamDetails(exam) {
            this.$store.commit("setObjTo", { what: "selectedExam", to: exam });
            this.$f7.views.main.router.navigate("/migration/mantenedores/exams/details");
        },
    },
    mounted() {
        if (!this.allExams) {
            this.getExams();
        }
    },
};
</script>
