<template>
    <f7-page name="admission-index">
        <f7-navbar title="Exportar"></f7-navbar>

        <f7-block>
            <f7-card outline>
                <f7-card-header class="display-flex justify-content-space-between">
                    <div class="text-color-gray">Filtra exámenes que deseas exportar</div>
                    <f7-button small @click="clear(true)" color="red">Limpiar filtros</f7-button>
                </f7-card-header>
                <f7-card-content>
                    <f7c-alert v-if="!examsSearchIsLoading" class="margin-horizontal-half" type="info">
                        Si el archivo generado tiene más de {{ maxRows | formatPrice }} filas se exportará en formato CSV.
                    </f7c-alert>

                    <exams-search
                        use-export-fields
                        :default-options="getExamsSearchDefaultOptions"
                        @options-changed="handleExamsSearchOptionsChanged"
                        @is-loading="handleExamsSearchIsLoading"
                        :reset="reset"
                    ></exams-search>

                    <f7-button class="margin-horizontal margin-bottom" fill large raised @click="handleExportExams" v-if="!examsSearchIsLoading">
                        <f7-icon material="file_download"></f7-icon>
                        Exportar exámenes
                    </f7-button>
                </f7-card-content>
            </f7-card>
        </f7-block>
    </f7-page>
</template>

<script>
import { mapActions } from "vuex";
import ExamsSearch from "@/app/components-migration/exams-search.vue";
import SearchMixin from "@/app/mixins/search-mixin.js";

export default {
    mixins: [SearchMixin],

    components: {
        "exams-search": ExamsSearch,
    },

    data() {
        return {
            reset: false,

            maxRows: 10000,

            examsSearchIsLoading: true,
            examsSearchOptions: {},
        };
    },

    methods: {
        ...mapActions(["getAPIInfo"]),

        handleExamsSearchOptionsChanged(options) {
            this.examsSearchOptions = options;
        },

        handleExportExams() {
            if (this.examsSearchOptions.patient_cols.length === 0) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Debe seleccionar por lo menos un campo de paciente");
                return;
            }

            this.$f7.dialog.preloader("Exportando archivo...");

            const queryString = this.queryStringFromOptions(this.examsSearchOptions);

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({
                        url: "export/exams?" + queryString,
                        userToken: idToken,
                        responseType: "blob",
                    });
                })
                .then((response) => {
                    const responseExt = response.data.type;

                    let type, ext;

                    if (responseExt === "text/csv") {
                        type = "text/csv";
                        ext = "csv";
                    } else {
                        type = "application/vnd.openxmlformats";
                        ext = "xlsx";
                    }

                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data], {
                            type: type,
                        })
                    );

                    let fileLink = document.createElement("a");
                    document.body.appendChild(fileLink);
                    fileLink.href = fileURL;
                    const date = this.$moment().format("YYYY_MM_DD_HH_mm_ss");

                    fileLink.setAttribute("download", "INMUNOCEL_EXAMENES_" + date + "." + ext);
                    fileLink.classList.add("external");
                    fileLink.click();
                    window.URL.revokeObjectURL(fileURL);

                    this.$f7.dialog.close();
                })
                .catch((error) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error);
                });
        },

        handleExamsSearchIsLoading(isLoading) {
            this.examsSearchIsLoading = isLoading;
        },

        getExamsSearchDefaultOptions() {
            return { patient: "basic-info" };
        },

        clear(confirm = false) {
            const _clear = () => {
                this.reset = !this.reset;
                this.examsSearchIsLoading = false;
                this.examsSearchOptions = {};
            };

            if (confirm) {
                this.$f7.dialog.confirm("¿Esta seguro?", () => {
                    _clear();
                });
            } else {
                _clear();
            }
        },
    },
};
</script>
