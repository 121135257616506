module.exports = {
    namespaced: true,

    state: {
        viewingAdmission: null,
        lastSearch: null,

        // Estado de crear admisión
        shouldAskToLeave: false,

        updatedAdmission: null,
    },

    mutations: {
        set_viewing_admission(state, payload) {
            if (!payload) {
                state.viewingAdmission = null;
                return;
            }

            const { admission, context } = payload;

            state.viewingAdmission = { admission, context };
        },

        save_last_search(state, payload) {
            state.lastSearch = payload;
        },
        save_last_viewing_admission(state, admissionId) {
            state.lastSearch = {
                ...state.lastSearch,
                admissionId,
            };
        },
        delete_last_search(state) {
            state.lastSearch = null;
        },

        set_should_ask_to_leave(state, payload) {
            state.shouldAskToLeave = payload;
        },

        set_updated_admission(state, admission) {
            state.updatedAdmission = admission;
        },
    },
};
