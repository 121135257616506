<template>
    <f7-page name="admission-index">
        <f7-navbar>
            <f7-nav-title>Gestionar solicitudes Inmunopax</f7-nav-title>
        </f7-navbar>

        <f7-block>
            <f7-card outline>
                <f7-card-header class="display-flex justify-content-space-between">
                    <div class="text-color-gray">Buscador de solicitudes</div>
                    <f7-button small class="margin-left-half" color="red" @click="clearSearchFilters(true)">
                        Limpiar filtros
                    </f7-button>
                </f7-card-header>
                <f7-card-content>
                    <solicitudes-search
                        @is-loading="handleSolicitudesSearchIsLoading"
                        @options-changed="handleSolicitudesSearchOptionsChanged"
                        :reset="resetSolicitudesSearchComponent"
                    >
                        <template #search-footer>
                            <f7-button class="margin-horizontal margin-bottom" fill large raised @click="handleSearchSolicitudes">
                                <f7-icon material="search"></f7-icon>
                                Buscar solicitudes
                            </f7-button>
                        </template>
                    </solicitudes-search>
                </f7-card-content>
            </f7-card>
        </f7-block>

        <div v-if="admissionsToManage.length === 0 && searched" class="text-color-gray text-align-center">
            No se han encontrado solicitudes
        </div>
        <f7-block v-else-if="searched">
            <h3>Solicitudes encontradas: {{ computedPagination.totalRows }}</h3>

            <div v-if="computedPagination.totalRows" class="display-flex justify-content-space-between margin-bottom">
                <div>Mostrando {{ admissionsToManage.length }} de {{ computedPagination.totalRows }} solicitudes</div>
                <f7-button @click="clearLastSearchResults(true)" small>Limpiar resultados última búsqueda</f7-button>
            </div>

            <admissions-table no-highlights :admissions="admissionsToManage">
                <template #table-header>
                    <th>Documento paciente</th>
                    <th>Paciente</th>
                    <th>Exámenes</th>
                    <th>Institución</th>
                    <th>Solicitada el</th>
                    <th>Estado</th>
                    <th></th>
                </template>

                <template #table-row="{ admission, f }">
                    <td>{{ f.patientDocumentInfo(admission) }}</td>
                    <td>
                        <template v-if="admission.solicitudMeta && admission.solicitudMeta.status === paxSolicitudStatusData.pendiente">
                            <f7-icon
                                color="blue"
                                material="person_add"
                                v-if="!admission.patient.id"
                                tooltip="Este paciente será creado al aceptar esta solicitud"
                                size="18"
                            ></f7-icon>
                            <f7-icon
                                size="18"
                                color="green"
                                material="check_circle"
                                v-else
                                tooltip="Este paciente fue encontrado en Inmunocel"
                            ></f7-icon>
                        </template>
                        {{ f.patientFullName(admission) }}
                    </td>
                    <td>{{ f.examsInfo(admission) }}</td>
                    <td>{{ f.institutionInfo(admission) }}</td>
                    <td>
                        {{
                            admission.solicitudMeta
                                ? $moment.unix(admission.solicitudMeta.requested_at).format("llll")
                                : $moment(admission.createdAt).format("llll")
                        }}
                    </td>
                    <td>
                        <template v-if="admission.solicitudMeta">
                            <span :class="classByStatusCode(admission.solicitudMeta.status)">
                                <b>{{ getStatusByCode(admission.solicitudMeta.status) }}</b>
                            </span>
                        </template>
                        <span v-else class="text-color-green"><b>Admitida</b></span>
                    </td>
                    <td>
                        <f7-button fill small @click="viewAdmission(admission)">Ver</f7-button>
                    </td>
                </template>
            </admissions-table>

            <div class="display-flex justify-content-center" v-if="admissionsToManage.length > 0">
                <template v-if="computedPagination">
                    <f7-button
                        fill
                        large
                        raised
                        class="padding-horizontal"
                        @click="loadMore"
                        v-if="
                            computedPagination.admissionsPagination.page < computedPagination.admissionsPagination.totalPages ||
                                computedPagination.solicitudesPagination.page < computedPagination.solicitudesPagination.totalPages
                        "
                    >
                        Cargar más admisiones
                    </f7-button>
                    <div v-else class="text-color-gray">Se han cargado todas las solicitudes encontradas</div>
                </template>
            </div>
        </f7-block>
    </f7-page>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { paxSolicitudStatus } from "@/app/constants";
import SearchMixin from "@/app/mixins/search-mixin";
import SolicitudesSearch from "@/app/pax/components/solicitudes-search.vue";
import AdmissionsTable from "@/app/components-migration/admissions-table.vue";

export default {
    mixins: [SearchMixin],

    components: {
        "solicitudes-search": SolicitudesSearch,
        "admissions-table": AdmissionsTable,
    },

    data() {
        return {
            paxSolicitudStatusData: paxSolicitudStatus,

            resetSolicitudesSearchComponent: false,

            solicitudesSearchIsLoading: false,
            solicitudesSearchOptions: {},

            admissionsToManage: [],

            admissionsPagination: {},
            solicitudesPagination: {},

            searched: false,
        };
    },

    watch: {
        updatedSolicitud() {
            if (this.updatedSolicitud) {
                const index = this.admissionsToManage.findIndex((a) => a.solicitudMeta && a.solicitudMeta.solicitud_id === this.updatedSolicitud.solicitud_id);
                const admissionToManage = this.admissionsToManage[index];

                let admission;

                if (this.updatedSolicitud.admissionData) {
                    admission = this.updatedSolicitud.admissionData.admission;
                } else {
                    admission = admissionToManage;
                }

                if (!admission) return;

                admission.solicitudMeta = {};

                admission.solicitudMeta.status = this.updatedSolicitud.status.code;
                admission.solicitudMeta.status_reason = this.updatedSolicitud.status.reason;
                admission.solicitudMeta.requested_at = admissionToManage.solicitudMeta.requested_at;

                this.$set(this.admissionsToManage, index, admission);

                this.$f7.toast.show({
                    text: "Se actualizó el estado de la solicitud.",
                    closeTimeout: 2000,
                });

                this.$store.commit("solicitudes/set_updated_solicitud", null);
            }
        },
    },

    computed: {
        ...mapState("solicitudes", ["updatedSolicitud"]),

        computedPagination() {
            const admissionsPagination = this.admissionsPagination;
            const solicitudesPagination = this.solicitudesPagination;

            return {
                admissionsPagination,
                solicitudesPagination,
                totalRows: admissionsPagination.totalRows || 0 + solicitudesPagination.totalRows || 0,
            };
        },
    },

    async created() {
        this.solicitudesSearchOptions = { statuses: [1] };

        await this.handleSearchSolicitudes();
    },

    methods: {
        ...mapActions(["getAPIInfo"]),

        viewAdmission(admission) {
            const admissionCopy = { ...admission };

            const { solicitudMeta } = admissionCopy;
            delete admissionCopy.solicitudMeta;

            this.$store.commit("admission/set_viewing_admission", {
                admission,
                context: {
                    manageSolicitud: solicitudMeta,
                },
            });

            this.$f7.views.main.router.navigate("/migration/admissions/index");
        },

        handleSolicitudesSearchIsLoading(isLoading) {
            this.solicitudesSearchIsLoading = isLoading;
        },

        handleSolicitudesSearchOptionsChanged(options) {
            this.solicitudesSearchOptions = options;
        },

        async handleSearchSolicitudes() {
            this.$f7.dialog.preloader("Buscando solicitudes...");

            this.clearLastSearchResults();

            this.searched = true;

            if (this.solicitudesSearchOptions.statuses.length === 0) {
                this.solicitudesSearchOptions.statuses = [paxSolicitudStatus.pendiente, paxSolicitudStatus.admitida, paxSolicitudStatus.rechazada];
            }

            const admissionsToManage = [];

            try {
                if (this.solicitudesSearchOptions.statuses.includes(paxSolicitudStatus.admitida)) {
                    const { admissions, pagination } = await this.searchAdmissions(this.solicitudesSearchOptions);
                    admissionsToManage.push(...admissions);
                    this.admissionsPagination = pagination;
                }

                if (
                    this.solicitudesSearchOptions.statuses.includes(paxSolicitudStatus.pendiente) ||
                    this.solicitudesSearchOptions.statuses.includes(paxSolicitudStatus.rechazada)
                ) {
                    const { solicitudes, pagination } = await this.searchSolicitudes(this.solicitudesSearchOptions);
                    const parsedSolicitudes = this.parseSolicitudes(solicitudes);
                    admissionsToManage.push(...parsedSolicitudes);
                    this.solicitudesPagination = pagination;
                }

                this.admissionsToManage = admissionsToManage;

                this.$f7.dialog.close();
            } catch (error) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Hubo un error al cargar las solicitudes. " + error);
            }
        },

        async searchAdmissions(searchOptions) {
            const options = { ...searchOptions };
            options.source = "pax";
            delete options.statuses;

            const queryString = this.queryStringFromOptions(options, {
                rowsPerPage: 50,
            });

            const idToken = await this.$firebase.auth().currentUser.getIdToken();

            const response = await this.getAPIInfo({
                url: "admissions?" + queryString,
                userToken: idToken,
            });

            const admissions = response.data.data;
            const pagination = response.data.pagination;

            return { admissions, pagination };
        },
        async searchSolicitudes(searchOptions) {
            const options = { ...searchOptions };
            options.statuses = options.statuses.filter((s) => s !== paxSolicitudStatus.admitida);

            const queryString = this.queryStringFromOptions(options, {
                rowsPerPage: 50,
            });

            const idToken = await this.$firebase.auth().currentUser.getIdToken();

            const response = await this.getAPIInfo({
                url: "pax/solicitudes/search?" + queryString,
                userToken: idToken,
            });

            const solicitudes = response.data.data;
            const pagination = response.data.pagination;

            return { solicitudes, pagination };
        },

        async loadMore() {
            this.$f7.dialog.preloader("Cargando más solicitudes...");

            const loadMoreAdmissions = this.computedPagination.admissionsPagination.page < this.computedPagination.admissionsPagination.totalPages;
            const loadMoreSolicitudes = this.computedPagination.solicitudesPagination.page < this.computedPagination.solicitudesPagination.totalPages;

            // todo: refactor
            try {
                if (loadMoreAdmissions) {
                    const options = { ...this.solicitudesSearchOptions };
                    options.source = "pax"
                    delete options.statuses;

                    let queryString = this.queryStringFromOptions(options, {
                        rowsPerPage: 50,
                    });

                    const page = this.admissionsPagination.page + 1;

                    queryString += `&page=${page}`;

                    const idToken = await this.$firebase.auth().currentUser.getIdToken();

                    const response = await this.getAPIInfo({
                        url: "admissions?" + queryString,
                        userToken: idToken,
                    });

                    const admissions = response.data.data;
                    this.admissionsToManage.push(...admissions);
                    this.admissionsPagination = response.data.pagination;
                }

                if (loadMoreSolicitudes) {
                    const options = { ...this.solicitudesSearchOptions };
                    options.statuses = options.statuses.filter((s) => s !== paxSolicitudStatus.admitida);

                    let queryString = this.queryStringFromOptions(options, {
                        rowsPerPage: 50,
                    });

                    const page = this.solicitudesPagination.page + 1;

                    queryString += `&page=${page}`;

                    const idToken = await this.$firebase.auth().currentUser.getIdToken();

                    const response = await this.getAPIInfo({
                        url: "pax/solicitudes/search?" + queryString,
                        userToken: idToken,
                    });

                    const solicitudes = response.data.data;
                    const parsedSolicitudes = this.parseSolicitudes(solicitudes);
                    this.admissionsToManage.push(...parsedSolicitudes);
                    this.solicitudesPagination = response.data.pagination;
                }

                this.$f7.dialog.close();
            } catch (error) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Hubo un error al cargar mas solicitudes. " + error);
            }
        },

        // Formateamos las admisiones de pax para mostrarlas en componente admissions-table
        parseSolicitudes(solicitudes) {
            const admissions = [];

            solicitudes.forEach((solicitud) => {
                const admission = solicitud.data;

                const parsedAdmission = {
                    solicitudMeta: {},
                };

                parsedAdmission.solicitudMeta.solicitud_id = solicitud.id;
                parsedAdmission.solicitudMeta.status = solicitud.status_code;
                parsedAdmission.solicitudMeta.status_reason = solicitud.status_reason;
                parsedAdmission.solicitudMeta.requested_at = admission.createdAt;
                parsedAdmission.solicitudMeta.inmuno_patient_id = admission.patient.inmuno_patient_id;

                parsedAdmission.patient = this.parsePatient(admission);
                parsedAdmission.institution = admission.institution;
                parsedAdmission.institution_id = admission.institution_id;
                parsedAdmission.exams = this.parseExams(admission);

                parsedAdmission.priority = admission.priority;
                parsedAdmission.agreement_id = admission.agreement_id || 0;
                parsedAdmission.diagnostic_obc = admission.diagnostic_obc || "";

                parsedAdmission.doctor_rut = admission.doctor_rut;
                parsedAdmission.doctor_name = admission.doctor_name;
                parsedAdmission.doctor_contacto_1 = admission.doctor_contacto_1;
                parsedAdmission.doctor_contacto_2 = admission.doctor_contacto_2;

                admissions.push(parsedAdmission);
            });

            return admissions;
        },

        /**
         * Parsers
         */
        parsePatient(paxAdmission) {
            const paxPatient = paxAdmission.patient;

            const patient = {};

            patient.id = paxPatient.inmuno_patient_id || null;
            patient.comuna_id = paxPatient.comuna_id || null;
            patient.address = paxPatient.address || "";
            patient.email = paxPatient.email || "";
            patient.birth_date = paxPatient.birth_date || "" ? this.$moment(paxPatient.birth_date, "DD/MM/YYYY").format("YYYY-MM-DD") : "";
            patient.document_number = paxPatient.document_number || "";
            patient.country_id = paxPatient.country_id;
            patient.name = paxPatient.name || "";
            patient.prevision = paxPatient.prevision || null;
            patient.lastname_f = paxPatient.lastname_f || "";
            patient.lastname_m = paxPatient.lastname_m || "";
            patient.gender = paxPatient.gender || null;
            patient.phone = paxPatient.phone || "";
            patient.document_type = paxPatient.document_type || null;
            patient.document_type_info = {};

            if (paxPatient.document_type === 1) {
                patient.document_type_info.id = 1;
                patient.document_type_info.name = "RUT";
            } else if (paxPatient.document_type === 2) {
                patient.document_type_info.id = 2;
                patient.document_type_info.name = "PASAPORTE";
            } else if (paxPatient.document_type === 3) {
                patient.document_type_info.id = 3;
                patient.document_type_info.name = "DNI";
            } else {
                patient.document_type_info.id = 4;
                patient.document_type_info.name = "OTRO";
            }

            return patient;
        },
        parseExams(paxAdmission) {
            const exams = [];

            Object.values(paxAdmission.exams).forEach((admissionExam) => {
                const newExam = {};

                newExam.section_id = admissionExam.exam_section.id;
                newExam.exam_section = admissionExam.exam_section;
                newExam.exam = {
                    id: admissionExam.exam.id,
                    code: admissionExam.exam.code,
                    name: admissionExam.exam.name,
                };
                newExam.current_status_id = -1;
                newExam.exam_status = {
                    id: -1,
                    name: "Preadmitido",
                };
                newExam.status_log = [
                    {
                        status_id: -1,
                        when: new Date(paxAdmission.createdAt * 1000),
                        exam_status_meta: {
                            id: -1,
                            name: "Preadmitido",
                        },
                    },
                ];

                newExam.createdAt = this.$moment(admissionExam.createdAt).toDate();

                newExam.admission = {
                    createdAt: new Date(paxAdmission.createdAt * 1000),
                };

                const samples = [];

                admissionExam.samples.forEach((sample) => {
                    const newSample = {};

                    newSample.id = sample.meta.id;
                    newSample.sample_code = "";
                    newSample.quantity = sample.quantity;
                    newSample.meta = sample.meta;

                    samples.push(newSample);
                });

                newExam.samples = samples;

                exams.push(newExam);
            });

            return exams;
        },

        clearSearchFilters(confirm) {
            const _clear = () => {
                this.resetSolicitudesSearchComponent = !this.resetSolicitudesSearchComponent;
            };

            if (confirm) {
                this.$f7.dialog.confirm("¿Está seguro?", () => {
                    _clear();
                });
            } else {
                _clear();
            }
        },
        clearLastSearchResults(confirm) {
            const _clear = () => {
                this.admissionsToManage = [];
                this.admissionsPagination = {};
                this.solicitudesPagination = {};
                this.searched = false;
            };

            if (confirm) {
                this.$f7.dialog.confirm("¿Está seguro?", () => {
                    _clear();
                });
            } else {
                _clear();
            }
        },

        getStatusByCode(statusCode) {
            switch (statusCode) {
                case 1:
                    return "Pendiente";
                case 2:
                    return "Admitida";
                case 3:
                    return "Rechazada";
                default:
                    return "Sin identificar";
            }
        },
        classByStatusCode(statusCode) {
            switch (statusCode) {
                case 1:
                    return "text-color-yellow";
                case 2:
                    return "text-color-green";
                case 3:
                    return "text-color-red";
                default:
                    return "text-color-gray";
            }
        },
    },
};
</script>
