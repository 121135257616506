<template>
    <f7-page name="newAgreement">
        <f7-navbar v-if="!isEditor" title="Agregar nuevo convenio" back-link></f7-navbar>
        <f7-navbar v-else title="Editar convenio" back-link></f7-navbar>

        <f7-list no-hairlines-md>
            <f7-list-input
                label="Nombre convenio"
                type="text"
                name="agreementName"
                placeholder="Nombre"
                :value="agreement.name"
                @input="agreement.name = $event.target.value"
                required
                validate
                error-message="Debe indicar el nombre del convenio"
            ></f7-list-input>
            <f7-list-input
                label="Descripción"
                type="textarea"
                name="description"
                placeholder="Descripción (Opcional)"
                :value="agreement.description"
                @input="agreement.description = $event.target.value"
            ></f7-list-input>
        </f7-list>

        <f7-block>
            <f7-button
                :icon-material="!isEditor ? 'playlist_add' : 'edit'"
                large
                outline
                :text="!isEditor ? 'Agregar Convenio' : 'Guardar cambios'"
                @click="!isEditor ? saveAgreement() : editAgreement()"
            />
        </f7-block>
    </f7-page>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    data() {
        return {
            agreement: {
                name: "",
                description: "",
                id: "",
            },
            isEditor: false,
        };
    },
    computed: {
        ...mapState(["profile_userInfo"]),

        ...mapState("agreements", ["viewingAgreement"]),
    },
    methods: {
        ...mapActions(["createNewInfo", "updateInfo"]),

        setAddOrEdit() {
            if (this.viewingAgreement) {
                this.isEditor = true;
                this.agreement = { ...this.viewingAgreement };
            }
        },

        redirectAgreementDetails() {
            this.$store.commit("agreements/set_viewing_agreement", this.agreement);
            this.$f7.views.main.router.navigate("/migration/mantenedores/agreements/details");
        },

        saveAgreement() {
            if (!this.agreement.name) {
                this.$f7.dialog.alert("Es necesario ingresar el nombre del convenio", "Error");
                return;
            }

            this.$f7.dialog.preloader("Guardando...");

            let agreement = { ...this.agreement };
            delete agreement.id;

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.createNewInfo({
                        url: "agreements",
                        userToken: idToken,
                        data: agreement,
                    });
                })
                .then((response) => {
                    const createdAgreement = response.data.data;

                    this.$store.commit("agreements/add_agreement", createdAgreement);

                    this.agreement = createdAgreement;

                    this.$f7.dialog.close();
                    this.$f7.toast.create({ text: "Los datos se han guardado con éxito", closeTimeout: 2500 }).open();

                    this.redirectAgreementDetails();
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, err.code);
                });
        },

        editAgreement() {
            if (!this.agreement.name) {
                this.$f7.dialog.alert("Es necesario ingresar el nombre del convenio", "Error");
                return;
            }

            this.$f7.dialog.preloader("Editando...");

            let agreement = { ...this.agreement };
            delete agreement.id;
            delete agreement.createdAt;
            delete agreement.updatedAt;

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.updateInfo({
                        url: `agreements/${this.agreement.id}`,
                        userToken: idToken,
                        data: agreement,
                    });
                })
                .then((response) => {
                    const updatedAgreement = response.data.data;

                    this.$store.commit("agreements/update_agreement", updatedAgreement);

                    this.agreement = updatedAgreement;

                    this.$f7.dialog.close();
                    this.$f7.toast.create({ text: "Los datos se han guardado con éxito", closeTimeout: 2500 }).open();

                    this.redirectAgreementDetails();
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, err.code);
                });
        },
    },
    mounted() {
        this.setAddOrEdit();
    },
};
</script>
