<template>
    <div>
        <div class="text-align-center">
            <div>
                <img class="signature" :src="signatureDataToShow.signatureUrl" alt="Firma del usuario firmador" />
            </div>
            <span>
                TM. {{ signatureDataToShow.signatureName }}
                <br />
                Responsable examen
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        defaults: Object,
    },

    computed: {
        signatureDataToShow() {
            return {
                signatureName: this.defaults.signatureName || "Usuario firmador",
                signatureUrl: this.defaults.signatureUrl || "../../../../static/img/default_signature.png",
            };
        },
    },
};
</script>

<style scoped>
.signature {
    width: 200px;
    height: 100px;
}
</style>
