<template>
    <f7-page name="carga-resultados-firma" class="bg-color-gray">
        <f7-navbar>
            <f7-nav-left>
                <f7-link @click="backConfirm" icon-material="arrow_back"></f7-link>
            </f7-nav-left>
            <f7-nav-title>Firmando y cargando resultado de un examen</f7-nav-title>
            <f7-nav-right>
                <main-plantilla-control-buttons
                    :plantilla="plantilla"
                    :locked="plantillaIsLocked"
                    class="display-flex"
                    @button-clicked="handleMainPlantillaControlButtonClicked"
                ></main-plantilla-control-buttons>
            </f7-nav-right>
        </f7-navbar>

        <f7-block class="margin-top-half display-flex justify-content-center">
            <main-plantilla
                :plantilla="plantilla"
                :admission-exam="viewingAdmissionExam"
                @block-dragged="handleMainPlantillaBlockDragged"
                @block-resized="handleMainPlantillaBlockResized"
                @block-deleted="handleMainPlantillaBlockDeleted"
                @block-data-changed="handleBlockDataChangedInEditor"
                @plantilla-is-blocked="handleBlockPlantilla"
                :reset="resetMainPlantilla"
            ></main-plantilla>
        </f7-block>

        <main-plantilla-side-panel
            :opened="sidePanelOpened"
            :blocks="blocks"
            :plantilla="plantilla"
            :admission-exam="viewingAdmissionExam"
            :plantilla-was-changed="plantillaWasChanged"
            :user-has-signature="userHasSignature"
            @save-plantilla="handleMainPlantillaSaveInEditor"
            @info-changed="handleMainPlantillaInfoChanged"
            @block-added="handleMainPlantillaBlockAdded"
            @page-deleted="handleMainPlantillaPageDeleted"
            @exam-action-triggered="handleExamActionTriggered"
            @side-panel-closed="sidePanelOpened = false"
        ></main-plantilla-side-panel>

        <main-plantilla-pages-controls
            :plantilla="plantilla"
            @zoom-changed="handleMainPlantillaZoomChanged"
            @page-added="handleMainPlantillaPageAdded"
        ></main-plantilla-pages-controls>
    </f7-page>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { examStatuses } from "@/app/constants";

import MainPlantilla from "@/app/components-migration/plantillas/main-plantilla/main-plantilla.vue";
import MainPlantillaControlButtons from "@/app/components-migration/plantillas/main-plantilla/control-buttons.vue";
import MainPlantillaPagesControls from "@/app/components-migration/plantillas/main-plantilla/pages-controls.vue";
import MainPlantillaSidePanel from "@/app/components-migration/plantillas/main-plantilla/side-panel.vue";

import MainPlantillaMixin from "@/app/components-migration/plantillas/main-plantilla/main-plantilla-mixin";

export default {
    mixins: [MainPlantillaMixin],

    components: {
        "main-plantilla": MainPlantilla,
        "main-plantilla-control-buttons": MainPlantillaControlButtons,
        "main-plantilla-pages-controls": MainPlantillaPagesControls,
        "main-plantilla-side-panel": MainPlantillaSidePanel,
    },

    data() {
        return {
            plantilla: {},

            plantillaWasCloned: false,
            originalPlantillaBlocksIds: [],
            originalPlantillaPagesCount: 1,

            plantillaIsLocked: false,

            userHasSignature: false,

            resetMainPlantilla: false,
        };
    },

    computed: {
        ...mapState(["misprivx", "profile_userInfo"]),

        ...mapState("plantillas", ["viewingPlantilla", "viewingAdmissionExam", "triggerReload"]),

        ...mapGetters("plantillas", ["currentStatusIdByReportType"]),

        plantillaWasChanged() {
            let plantillaWasChanged = false;

            const viewingPlantillaBlocksIds = Object.keys(this.plantilla.blocks || {});
            const viewingPlantillaPagesCount = this.plantilla.pages.length;

            if (
                this.originalPlantillaBlocksIds.length !== viewingPlantillaBlocksIds.length ||
                !this.originalPlantillaBlocksIds.every((b) => viewingPlantillaBlocksIds.includes(b)) ||
                this.originalPlantillaPagesCount !== viewingPlantillaPagesCount
            ) {
                plantillaWasChanged = true;
            }

            return plantillaWasChanged;
        },
    },

    watch: {
        misprivx: {
            deep: true,
            handler() {
                console.log("privs cambiaron");

                if (!this.misprivx.change_exam_status) {
                    this.redirectToIndex();
                    return;
                }

                let hasAtLeastOne = false;

                Object.keys(this.misprivx.change_exam_status).forEach((privId) => {
                    if (this.misprivx.change_exam_status[privId]) {
                        hasAtLeastOne = true;
                    }
                });

                if (!hasAtLeastOne) {
                    this.redirectToIndex();
                    return;
                }
            },
        },

        plantillaIsLocked(value) {
            if (value) {
                this.plantilla.options.previewMode = true;
            }
        },

        triggerReload() {
            this.initPlantilla();
        },
    },

    created() {
        this.initPlantilla();

        const uid = this.profile_userInfo.uid;

        this.$firebase
            .auth()
            .currentUser.getIdToken()
            .then((idToken) => this.getAPIInfo({ url: `users/check-signature/${uid}`, userToken: idToken }))
            .then(() => {
                console.log("Firma verificada");
                this.userHasSignature = true;
            })
            .catch((error) => {
                console.log("error", error);
                console.log("usuario sin firma autorizada");
                this.userHasSignature = false;
            });
    },

    methods: {
        ...mapActions(["getAPIInfo"]),

        initPlantilla() {
            this.plantilla = JSON.parse(JSON.stringify(this.viewingPlantilla));

            this.originalPlantillaBlocksIds = Object.keys(this.plantilla.blocks || {});
            this.originalPlantillaPagesCount = this.plantilla.pages.length;

            this.resetMainPlantilla = !this.resetMainPlantilla;
        },

        handleBlockPlantilla(shouldBeBlocked) {
            this.plantillaIsLocked = shouldBeBlocked;
        },

        backConfirm() {
            this.$f7.dialog.confirm("¿Está seguro de volver?", () => {
                this.$f7.views.main.router.back();
            });
        },

        redirectToIndex() {
            this.$f7.views.main.router.navigate("/migration/carga-resultados", { force: true });
            this.$store.commit("plantillas/set_viewing_admission_exam", null);
            this.$store.commit("plantillas/set_viewing_plantilla", null);
        },
    },
};
</script>
