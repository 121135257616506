<template>
    <div>
        <block-toolbar v-if="!previewMode" :block="computedBlockInfo('image-block')">
            <template #toolbar-content>
                <div class="display-flex">
                    <input
                        v-if="!image"
                        type="file"
                        accept="image/*"
                        @change="uploadImage($event)"
                        id="file-input"
                        class="margin-top-half margin-left-half"
                        :disabled="disableInputs"
                    />

                    <div v-if="image" class="display-flex justify-content-space-between">
                        <div>
                            <f7-button v-if="!usingCachedImage && imageIsPreview" small @click="finishUploadImage">
                                Finalizar subida de imagen
                            </f7-button>
                        </div>

                        <f7-button color="red" small @click="removeImage" :disabled="disableInputs">Quitar imagen</f7-button>
                    </div>
                    <f7-button small @click="clearCache" :disabled="disableInputs">Limpiar cache</f7-button>
                </div>
            </template>
        </block-toolbar>

        <img v-if="imageUrl" :src="imageUrl" :width="width" :height="height" />
    </div>
</template>

<script>
import BlockToolbarMixin from "@/app/components-migration/plantillas/blocks/toolbar/block-toolbar-mixin";
import BlockToolbar from "@/app/components-migration/plantillas/blocks/toolbar/block-toolbar.vue";

export default {
    mixins: [BlockToolbarMixin],

    props: {
        defaults: Object,
        previewMode: Boolean,
        width: Number,
        height: Number,
        disableInputs: Boolean,
    },

    components: {
        "block-toolbar": BlockToolbar,
    },

    data() {
        return {
            image: null,
            imageUrl: "",

            cache: {},
            usingCachedImage: false,
            justUploaded: false,
        };
    },

    computed: {
        filename() {
            if (!this.image) return "";
            return this.image.name;
        },

        imageIsPreview() {
            return this.imageUrl.startsWith("blob");
        },
    },

    watch: {
        imageUrl() {
            let target;

            if (this.imageIsPreview) {
                target = "";
            } else {
                target = this.imageUrl;
            }

            this.$emit("image-url-changed", {
                imageUrl: target,
            });
        },
    },

    created() {
        // Sin esto al setear la imageUrl que se guardo en firebase imageUrl no se actualiza de inmediato
        setTimeout(() => {
            if (this.defaults.imageUrl) {
                this.imageUrl = this.defaults.imageUrl;
            }
        }, 0);
    },

    methods: {
        uploadImage(event) {
            const file = event.target.files[0];
            this.image = file;

            if (this.cache[this.filename]) {
                this.imageUrl = this.cache[this.filename];
                this.usingCachedImage = true;
                this.justUploaded = false;
            } else {
                this.imageUrl = URL.createObjectURL(file);
                this.usingCachedImage = false;

                this.$emit("image-uploaded-load-status", {
                    finishLoading: false,
                });
            }
        },

        async finishUploadImage() {
            this.$f7.dialog.preloader("Subiendo imagen...");

            const path = "images/img-plantillas/";

            await this.$firebase
                .app()
                .storage("gs://inmunoportal-dev")
                .ref(path + this.filename)
                .put(this.image);

            const imageUrl = await this.$firebase
                .app()
                .storage("gs://inmunoportal-dev")
                .ref(path + this.filename)
                .getDownloadURL();

            this.$set(this.cache, this.filename, imageUrl);
            this.usingCachedImage = true;
            this.justUploaded = true;

            this.imageUrl = imageUrl;

            this.$emit("image-uploaded-load-status", {
                finishLoading: true,
            });

            this.$f7.dialog.close();
        },

        removeImage() {
            this.image = null;
            this.imageUrl = "";
            this.usingCachedImage = false;
            this.justUploaded = false;

            this.$emit("image-uploaded-load-status", {
                removeUploadStatus: true,
            });
        },

        clearCache() {
            if (this.usingCachedImage) {
                this.imageUrl = "";
            }

            if (this.image) {
                this.imageUrl = URL.createObjectURL(this.image);
            }

            this.cache = {};

            this.usingCachedImage = false;
        },
    },
};
</script>
