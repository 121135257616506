var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-page',{attrs:{"name":"exams-filter"}},[_c('f7-navbar',{attrs:{"title":"Filtro de exámenes firmados","subtitle":_vm.focused_section.name,"back-link":""}}),_vm._v(" "),_c('f7-block',[_c('f7-row',{attrs:{"no-gap":""}},[_c('f7-col',{attrs:{"width":"90","medium":"15"}}),_vm._v(" "),_c('f7-col',{attrs:{"width":"0","medium":"70"}},[_c('f7-card',{staticClass:"padding"},[_c('f7-list',{staticClass:"no-margin-top",attrs:{"no-hairlines-md":""}},[_c('f7-list-input',{attrs:{"label":"Seleccione la fecha de admisión","type":"datepicker","placeholder":"Fecha de informe","readonly":"","clear-button":"","calendar-params":{
                                    closeOnSelect: true, 
                                    maxDate: _vm.currentDate, 
                                    dateFormat: 'dd-mm-yyyy'
                                },"error-message":"Debe indicar una fecha","error-message-force":_vm.invalidDate},on:{"change":function($event){return _vm.selectDate($event)}}},[_c('f7-icon',{staticClass:"calendar-icon",attrs:{"slot":"media","material":"today"},slot:"media"})],1)],1)],1)],1),_vm._v(" "),_c('f7-col',{attrs:{"width":"100","medium":"15"}})],1),_vm._v(" "),(_vm.signedExams.length > 0)?_c('f7-row',{staticClass:"margin-top"},[_c('f7-col',{attrs:{"width":"100","medium":"20"}},[_c('f7-block-title',{staticClass:"margin-top-half no-margin-bottom"},[_vm._v("\n                        Examenes encontrados: "+_vm._s(_vm.exams.length)+"\n                    ")])],1),_vm._v(" "),_c('f7-col',{attrs:{"width":"0","medium":"60"}}),_vm._v(" "),_c('f7-col',{attrs:{"width":"100","medium":"20"}},[_c('f7-button',{staticClass:"institution-button",attrs:{"raised":"","outline":"","small":"","round":"","icon-material":"sort","text":"Filtrar por institución"},on:{"click":function($event){return _vm.showInstitutionFilter()}}})],1)],1):_vm._e(),_vm._v(" "),(_vm.noExams)?_c('f7-list',[_c('f7-list-item',{attrs:{"title":"No hay examenes firmados en la fecha seleccionada"}})],1):_c('f7-list',{attrs:{"media-list":""}},_vm._l((_vm.exams),function(exam){return _c('f7-list-item',{key:exam.id,attrs:{"subtitle":_vm.lastNamesAndNames(exam.admission.patient) + ' | ' 
                        + _vm.formatId(exam.admission.patient),"title":exam.name,"header":'Admitido el '+ _vm.formatDate(exam.admission.creationDate) 
                        + ' @' + exam.admission.institution}},[_c('div',{staticClass:"text-align-center",attrs:{"slot":"media"},slot:"media"},[_c('img',{staticClass:"avatar",attrs:{"src":(exam.admission.patient.sexo ==='Masculino') ? 
                            ('../static/img/masc.jpg') : ('../static/img/feme.jpg')}}),_vm._v(" "),_c('br'),_vm._v(" "),_c('span',[_vm._v("\n                            "+_vm._s(_vm.suage(exam.admission.patient.fechaNacimiento))+"\n                        ")])]),_vm._v(" "),_c('div',{attrs:{"slot":"text"},slot:"text"},_vm._l((exam.admission.samples),function(sample){return _c('div',{key:sample.id},[(exam.samples[sample.id])?[_c('f7-icon',{attrs:{"material":"opacity"}}),_vm._v(" \n                                "+_vm._s(sample.name)+" ("),_c('b',[_vm._v("#"+_vm._s(sample.id))]),_vm._v(")\n                            ")]:_vm._e()],2)}),0),_vm._v(" "),_c('span',{attrs:{"slot":"after"},slot:"after"},[_c('f7-link',{staticClass:"margin-right",attrs:{"icon-material":"assignment_returned","tooltip":"Descargar resultados"},on:{"click":function($event){return _vm.printReport(true, exam)}}}),_vm._v(" "),_c('f7-link',{staticClass:"margin-right",attrs:{"icon-material":"printer","tooltip":"Ver resultados"},on:{"click":function($event){return _vm.printReport(false, exam)}}}),_vm._v(" "),_c('f7-link',{attrs:{"icon-material":"pageview","tooltip":"Ver detalles orden de trabajo"},on:{"click":function($event){return _vm.showOrderDetails(exam)}}})],1)])}),1)],1),_vm._v(" "),_c('f7-popover',{staticClass:"institutions-filter"},[_c('f7-list',{staticClass:"institution-list margin-vertical-half"},_vm._l((_vm.filteredInstitutions),function(institution){return _c('f7-list-item',{key:institution.name,attrs:{"checkbox":"","title":institution.name},on:{"change":function($event){return _vm.selectInstitution(institution, $event)}}})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }