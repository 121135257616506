<template>
    <f7-page name="new-unit">
        <f7-navbar v-if="!isEditor" title="Crear nueva unidad de medida" back-link></f7-navbar>
        <f7-navbar v-else title="Editar unidad de medida" back-link></f7-navbar>

        <f7-list no-hairlines-md>
            <f7-list-input
                label="Unidad"
                type="text"
                name="unitName"
                placeholder="Unidad"
                :value="unit.name"
                @input="unit.name = $event.target.value"
                required
                validate
                error-message="Debe indicar el nombre de la unidad"
            ></f7-list-input>
        </f7-list>

        <f7-block>
            <f7-button v-if="!isEditor" icon-material="playlist_add" large outline @click="addNewUnit()">
                Agregar unidad
            </f7-button>
            <f7-button v-else icon-material="edit" large outline @click="editUnit()">
                Guardar cambios
            </f7-button>
        </f7-block>
    </f7-page>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    data() {
        return {
            unit: {
                name: "",
                id: "",
            },
            isEditor: false,
        };
    },

    computed: {
        ...mapState(["profile_userInfo"]),
        ...mapState("unit", ["viewingUnit"]),
    },

    methods: {
        ...mapActions(["createNewInfo", "updateInfo"]),

        setAddOrEdit() {
            if (this.viewingUnit) {
                this.unit = { ...this.viewingUnit };
                this.isEditor = true;
            }
        },

        addNewUnit() {
            if (!this.unit.name) {
                this.$f7.dialog.alert("Es necesario ingresar una unidad de medida", "Error");
                return;
            }

            this.$f7.dialog.preloader("Guardando...");

            let unit = { ...this.unit };
            delete unit.id;

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.createNewInfo({
                        url: "units",
                        userToken: idToken,
                        data: unit,
                    });
                })
                .then((response) => {
                    const createdUnit = response.data.data;

                    this.$store.commit("unit/add_unit", createdUnit);

                    this.unit = createdUnit;

                    this.$f7.dialog.close();
                    this.$f7.toast.create({ text: "Los datos se han guardado con éxito", closeTimeout: 2500 }).open();

                    this.reditectToUnitDetails();
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, err.code);
                });
        },

        editUnit() {
            if (!this.unit.name) {
                this.$f7.dialog.alert("Es necesario ingresar una unidad de medida", "Error");
                return;
            }

            this.$f7.dialog.preloader("Guardando...");

            let unit = { ...this.unit };
            delete unit.id;

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.updateInfo({
                        url: `units/${this.unit.id}`,
                        userToken: idToken,
                        data: unit,
                    });
                })
                .then((response) => {
                    const updatedUnit = response.data.data;

                    this.$store.commit("unit/update_unit", updatedUnit);

                    this.unit = updatedUnit;

                    this.$f7.dialog.close();
                    this.$f7.toast.create({ text: "Los datos se han guardado con éxito", closeTimeout: 2500 }).open();

                    this.reditectToUnitDetails();
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, err.code);
                });
        },

        reditectToUnitDetails() {
            this.$store.commit("unit/set_viewing_unit", this.unit);
            this.$f7.views.main.router.navigate("/migration/mantenedores/units/details");
        },
    },

    mounted() {
        this.setAddOrEdit();
    },
};
</script>
