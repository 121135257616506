<template>
    <f7-page class="bg-color-gray">
        <f7-navbar back-link>
            <f7-nav-title>Vista previa</f7-nav-title>
            <f7-nav-right>
                <f7-button fill class="margin-right" @click="generateExamResultPDF">Imprimir</f7-button>
            </f7-nav-right>
        </f7-navbar>

        <f7-block class="margin-top-half display-flex justify-content-center">
            <main-plantilla :plantilla="plantilla" :admission-exam="admissionExam"></main-plantilla>
        </f7-block>
    </f7-page>
</template>

<script>
import { mapState } from "vuex";
import FirmarHandlersMixin from "@/app/components-migration/plantillas/main-plantilla/firmar-handlers-mixin";
import MainPlantilla from "@/app/components-migration/plantillas/main-plantilla/main-plantilla.vue";

export default {
    mixins: [FirmarHandlersMixin],

    components: {
        "main-plantilla": MainPlantilla,
    },

    data() {
        return {
            admissionExam: {},
            plantilla: {},
        };
    },

    computed: {
        ...mapState("resultPdf", ["viewingAdmissionExam", "usingPlantilla"]),
    },

    created() {
        if (this.viewingAdmissionExam) {
            this.admissionExam = this.viewingAdmissionExam;
        }

        if (this.usingPlantilla) {
            this.plantilla = this.usingPlantilla;

            this.plantilla.options.previewMode = true;
            this.plantilla.options.currentScale = 1
        }
    },
};
</script>
