<template>
    <div class="text-align-left">
        <span v-if="responsableName">
            <div v-if="translation"><i>Authorized by:</i></div>
            <div>Autorizado por: <b>{{ responsablePrefix }} {{ responsableName }}</b></div>
        </span>
        <span v-else class="text-color-red">
            <div v-if="translation"><i>This exam has not been authorized</i></div>
            <div>Este exámen no ha sido autorizado</div>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        translation: Boolean,
        defaults: Object,
    },

    computed: {
        responsableName() {
            return this.defaults.responsableName;
        },

        responsablePrefix() {
            return this.defaults.responsablePrefix;
        }
    },
};
</script>
