export default {
    methods: {
        updateTableBlockDefaults(payload) {
            const { blockId, data } = payload;

            if (payload.whatChanged === "table-dimensions") {
                const { dataType, value } = data;

                const valueBeforeSet = this.plantilla.blocks[blockId].defaults.dimensions[dataType] + value;

                if (valueBeforeSet > 0) {
                    const isDeleting = value < 0;

                    if (isDeleting) {
                        if (dataType === "cols") {
                            // Eliminar la data de última columna
                            if (this.plantilla.blocks[blockId].defaults.data) {
                                const lastColIndex = this.plantilla.blocks[blockId].defaults.dimensions.cols - 1;

                                if (this.plantilla.blocks[blockId].defaults.data[lastColIndex]) {
                                    this.plantilla.blocks[blockId].defaults.data.pop();
                                }
                            }
                        } else {
                            // Eliminar la data de última fila
                            const lastRowIndex = this.plantilla.blocks[blockId].defaults.dimensions.rows - 1;

                            if (this.plantilla.blocks[blockId].defaults.data) {
                                this.plantilla.blocks[blockId].defaults.data.forEach((colValue, colIndex) => {
                                    if (this.plantilla.blocks[blockId].defaults.data[colIndex][lastRowIndex]) {
                                        this.plantilla.blocks[blockId].defaults.data[colIndex].pop();
                                    }
                                });
                            }
                        }
                    }

                    this.plantilla.blocks[blockId].defaults.dimensions[dataType] += value;

                    const dataTypeText = dataType === "rows" ? "filas" : "columnas";

                    this.$f7.toast.show({
                        text: `Se ha modificado la cantidad de <b>${dataTypeText}</b> en la tabla.`,
                        closeTimeout: 2000,
                    });
                }
            }
            if (payload.whatChanged === "table-data") {
                const { value, row, col } = data;

                if (!this.plantilla.blocks[blockId].defaults.data) {
                    this.$set(this.plantilla.blocks[blockId].defaults, "data", []);
                }

                if (!this.plantilla.blocks[blockId].defaults.data[col]) {
                    this.$set(this.plantilla.blocks[blockId].defaults.data, col, []);
                }

                this.$set(this.plantilla.blocks[blockId].defaults.data[col], row, value);
            }
        },

        updateTextBlockDefaults(payload) {
            const { blockId, data } = payload;

            const updatedBlock = this.plantilla.blocks[blockId];

            if (!updatedBlock.defaults) updatedBlock.defaults = {};

            updatedBlock.defaults.text = data;

            this.$set(this.plantilla.blocks, updatedBlock.id, updatedBlock);
        },

        updateObservationsDefaults(payload) {
            const { blockId, data } = payload;

            const updatedBlock = this.plantilla.blocks[blockId];

            if (!updatedBlock.defaults) updatedBlock.defaults = {};

            updatedBlock.defaults.text = data;

            this.$set(this.plantilla.blocks, updatedBlock.id, updatedBlock);
        },

        updateResultadoCualitativoDefaults(payload) {
            const blockId = payload.blockId;

            const updatedBlock = this.plantilla.blocks[blockId];

            if (!updatedBlock.defaults) updatedBlock.defaults = {};

            if (payload.whatChanged === "exam-selected") {
                updatedBlock.defaults.examId = payload.data.examId;
                updatedBlock.defaults.result = "";

                this.$set(this.plantilla.blocks, updatedBlock.id, updatedBlock);
            }
            if (payload.whatChanged === "result-changed") {
                updatedBlock.defaults.result = payload.data.result;

                this.$set(this.plantilla.blocks, updatedBlock.id, updatedBlock);
            }
        },

        updateResultadoCuantitativoDefaults(payload) {
            const blockId = payload.blockId;

            const updatedBlock = this.plantilla.blocks[blockId];

            if (!updatedBlock.defaults) updatedBlock.defaults = {};

            if (payload.whatChanged === "exam-selected") {
                updatedBlock.defaults.examId = payload.data.examId;
                updatedBlock.defaults.result = "";
                updatedBlock.defaults.unit = "";

                this.$set(this.plantilla.blocks, updatedBlock.id, updatedBlock);
            }
            if (payload.whatChanged === "result-changed") {
                const { input, value } = payload.data;

                updatedBlock.defaults[input] = value;

                this.$set(this.plantilla.blocks, updatedBlock.id, updatedBlock);
            }
        },

        updateResultadoFreeTextDefaults(payload) {
            const blockId = payload.blockId;

            const updatedBlock = this.plantilla.blocks[blockId];

            if (!updatedBlock.defaults) updatedBlock.defaults = {};

            if (payload.whatChanged === "exam-selected") {
                updatedBlock.defaults.examId = payload.data.examId;
                updatedBlock.defaults.result = "";

                this.$set(this.plantilla.blocks, updatedBlock.id, updatedBlock);
            }
            if (payload.whatChanged === "result-changed") {
                const { value } = payload.data;

                updatedBlock.defaults.result = value;

                this.$set(this.plantilla.blocks, updatedBlock.id, updatedBlock);
            }
        },

        updateResultadoCollectionDefaults(payload) {
            const { blockId, data } = payload;

            const updatedBlock = this.plantilla.blocks[blockId];

            if (!updatedBlock.defaults) updatedBlock.defaults = {};

            updatedBlock.defaults = data;

            this.$set(this.plantilla.blocks, updatedBlock.id, updatedBlock);
        },

        updateProcessingMethodDefaults(payload) {
            const { blockId, data } = payload;

            const updatedBlock = this.plantilla.blocks[blockId];

            if (!updatedBlock.defaults) updatedBlock.defaults = {};

            if (payload.whatChanged === "exam-selected") {
                updatedBlock.defaults.examId = data.examId;
                updatedBlock.defaults.processingMethodId = "";

                this.$set(this.plantilla.blocks, updatedBlock.id, updatedBlock);
            }
            if (payload.whatChanged === "method-selected") {
                const { processingMethodId } = payload.data;

                updatedBlock.defaults.processingMethodId = processingMethodId;

                this.$set(this.plantilla.blocks, updatedBlock.id, updatedBlock);
            }
        },

        updateImageBlockDefaults(payload) {
            const { blockId, data } = payload;

            const updatedBlock = this.plantilla.blocks[blockId];

            if (!updatedBlock.defaults) updatedBlock.defaults = {};

            if (payload.whatChanged === "image-url-changed") {
                updatedBlock.defaults.imageUrl = data.imageUrl;

                this.$set(this.plantilla.blocks, updatedBlock.id, updatedBlock);
            }

            if (payload.whatChanged === "image-uploaded-load-status") {
                const { finishLoading, removeUploadStatus } = data;

                if (removeUploadStatus) {
                    let newUpdatedBlock = { ...updatedBlock };
                    delete newUpdatedBlock.defaults.imageUploadFinished;

                    this.$set(this.plantilla.blocks, updatedBlock.id, newUpdatedBlock);
                } else {
                    updatedBlock.defaults.imageUploadFinished = finishLoading;

                    this.$set(this.plantilla.blocks, updatedBlock.id, updatedBlock);
                }
            }
        },

        updateReportTypeBlockDefaults(payload) {
            const { blockId, data, whatChanged } = payload;

            const updatedBlock = this.plantilla.blocks[blockId];

            if (!updatedBlock.defaults) updatedBlock.defaults = {};

            if (whatChanged === "report-type-selected") {
                updatedBlock.defaults.reportType = data.reportType;

                this.$set(this.plantilla.blocks, updatedBlock.id, updatedBlock);
            }
        },
    },
};
