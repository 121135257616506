<template>
    <f7-page name="pending-reasons-details">
        <f7-navbar title="Detalles motivo de pendiente" back-link back-link-url="/migration/mantenedores/pending-reasons" back-link-force></f7-navbar>

        <f7-block-title v-if="!viewingPendingReason || !viewingPendingReason.id">
            Seleccione algún motivo de pendiente
        </f7-block-title>

        <f7-block v-else>
            <f7-card>
                <f7-card-header>
                    <f7-block-title medium>{{ viewingPendingReason.name }}</f7-block-title>
                </f7-card-header>
                <f7-card-content>
                    <p>{{ viewingPendingReason.description }}</p>
                </f7-card-content>
                <f7-card-footer>
                    <f7-button fill small round icon-material="edit" @click="goToEdit">Editar</f7-button>
                </f7-card-footer>
            </f7-card>
        </f7-block>
    </f7-page>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState(["profile_userInfo"]),

        ...mapState("reasons", ["viewingPendingReason"]),
    },

    methods: {
        goToEdit() {
            this.$f7.views.main.router.navigate("/migration/mantenedores/pending-reasons/create-or-edit");
        },
    },
};
</script>
