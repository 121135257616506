<template>
    <f7-page >
        <f7-navbar title="Filtrar por proveedores">
            <f7-nav-right>
                <f7-link popup-close>Cerrar</f7-link>
            </f7-nav-right>
        </f7-navbar>
        <f7-block>
            <f7-row>
                <template v-for="item of provider">
                    <f7-col width="50" :key="item.id">
                        <f7-list no-hairlines-md>
                            <f7-list-item checkbox @change="changeProvider($event.target.checked,item.description)" :title="item.description"></f7-list-item>
                        </f7-list>
                    </f7-col>

                </template>
                
            </f7-row>
        </f7-block>
    </f7-page>
</template>

<script>

import moment from 'moment';
import { mapState } from 'vuex';
export default {
    computed : {
        ...mapState(["provider"])
    },
    data(){
        return {
            providers : []
        }
    },
    methods :{
        changeProvider(check, provider){
            if(check == true){
                this.providers.push(provider)
            }else{
                let temp = [];
                for(const item of this.providers){
                    if(item != provider){
                        temp.push(item)
                    }
                }
                this.providers = temp;
            }
            this.$emit("updateStock", this.providers)
        }
    }
  
}
</script>

<style scoped>
.mb-2{
    margin-bottom: 4% !important;
    

}
.mb-1{
    margin-bottom: 3% !important;
    
}
.align-left{
    text-align: left;
}
.align-center{
    text-align: center !important;
}
.padding-1{
    padding: 0% 1%;
}
.toast{
    left: 45% !important;
}
.toast-text{
    font-size: 1.3em;
}
.icon{
    font-size: 13px;
}
</style>