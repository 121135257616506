<template>
    <div>
        <div v-if="admissionExam.admission.institution">
            <f7-row v-if="translation">
                <f7-col>
                    <i>Institution</i>
                </f7-col>
            </f7-row>

            <f7-row>
                <f7-col>
                    <b>Institución</b>
                    :
                    {{ admissionExam.admission.institution.name }}
                </f7-col>
            </f7-row>
        </div>

        <div v-if="admissionExam.exam_section.name">
            <f7-row v-if="translation">
                <f7-col>
                    <i>Section</i>
                </f7-col>
            </f7-row>

            <f7-row>
                <f7-col>
                    <b>Sección</b>
                    :
                    {{ admissionExam.exam_section.name }}
                </f7-col>
            </f7-row>
        </div>

        <div v-if="admissionExam.samples && admissionExam.samples.length > 0">
            <div v-for="sample in admissionExam.samples" :key="sample.id">
                <div v-if="sample.meta">
                    <f7-row v-if="translation">
                        <f7-col>
                            <i>Sample type</i>
                        </f7-col>
                    </f7-row>
                    <f7-row>
                        <f7-col>
                            <b>Tipo de muestra</b>
                            :
                            {{ sample.meta.name }}
                        </f7-col>
                    </f7-row>
                </div>

                <div v-if="sample.sample_code">
                    <f7-row v-if="translation">
                        <f7-col>
                            <i>Sample Code</i>
                        </f7-col>
                    </f7-row>
                    <f7-row>
                        <f7-col>
                            <b>Id Muestra</b>
                            :
                            {{ sample.sample_code }}
                        </f7-col>
                    </f7-row>
                </div>
            </div>
        </div>

        <div>
            <f7-row v-if="translation">
                <f7-col>
                    <i>Entered on</i>
                </f7-col>
            </f7-row>

            <f7-row>
                <f7-col>
                    <b>Ingreso</b>
                    :
                    {{ $moment(admissionExam.createdAt).format("DD/MM/YYYY HH:mm") }}
                </f7-col>
            </f7-row>
        </div>

        <div>
            <f7-row v-if="translation">
                <f7-col>
                    <i>Report</i>
                </f7-col>
            </f7-row>

            <f7-row>
                <f7-col>
                    <b>Informe</b>
                    :
                    {{ informadoDate ? $moment(informadoDate).format("DD/MM/YYYY HH:mm") : "-" }}
                </f7-col>
            </f7-row>
        </div>
    </div>
</template>

<script>
import { examStatuses } from "@/app/constants";
import { mapGetters, mapState } from "vuex";

export default {
    props: {
        translation: Boolean,
        admissionExam: Object,
    },

    computed: {
        ...mapGetters("plantillas", ["currentStatusIdByReportType"]),

        ...mapState("plantillas", ["selectedReportType"]),

        informadoDate() {
            if (this.currentStatusIdByReportType < examStatuses.examen_firmado) {
                return "";
            }
            if (!this.admissionExam.status_log) {
                return "";
            }
            if (this.admissionExam.status_log.length === 0) {
                return "";
            }

            let firmadoLog = this.admissionExam.status_log.find(
                (sl) => sl.status_id === examStatuses.examen_firmado && sl.detail === this.selectedReportType
            );

            if (!firmadoLog && this.admissionExam.exam_result.length === 1) {
                firmadoLog = this.admissionExam.status_log.find((sl) => sl.status_id === examStatuses.examen_firmado);
            }

            if (!firmadoLog) {
                return "";
            }

            return firmadoLog.when;
        },
    },
};
</script>
