<template>
  <f7-page name="pdf">
      <f7-navbar title="Visor PDF" :subtitle="'Página '+page+'/'+numPages" class="nohideback" back-link="Volver">
        <f7-nav-right>
          <f7-link @click="prevPage()" icon-material="keyboard_arrow_left"></f7-link>
           <f7-link @click="nextpage()" icon-material="keyboard_arrow_right"></f7-link>
          <f7-link @click="rotate += 90" icon-material="screen_rotation"></f7-link></f7-nav-right>
           <f7-link @click="marcarComoImpreso" icon-material="printer"></f7-link>
           <f7-link v-if="viewingFocusedPDFExam.current_status_id === examStatusesData.examen_impreso" @click="revertirImpreso">
               <f7-icon material="printer" color="red" tooltip="Revertir estado impreso"></f7-icon>
           </f7-link>

      </f7-navbar>

     <pdf :src="focusedPDF" ref="myPdfComponent" class="all100pdf" :page="page" @error="pdferror" @num-pages="numPages = $event" @progress="actualizarLoadedRatio($event)" :rotate="rotate"></pdf>

  </f7-page>
</template>
<script>
import { examStatuses } from "@/app/constants";
 import {mapActions, mapState} from 'vuex';
 import pdf from 'vue-pdf';

export default {
  components: {
    pdf
  },
data() {

      return {
          examStatusesData: examStatuses,
       numPages: 1,
       rotate: 0,
       page: 1,
       loadedRatio: 0,
       progressdialog: null
      }

},
computed:{
  ...mapState(['focusedPDF', 'viewingFocusedPDFExam']),
},
  mounted() {
    this.progressdialog = this.$f7.dialog.progress('Cargando archivo', 0);
    this.progressdialog.setText('0%');
    console.log('cargando archiov',this.focusedPDF);
    },
methods:{
    ...mapActions(["updateInfo"]),

  printmeplease(){

  },

  marcarComoImpreso() {
      const admissionExam = this.viewingFocusedPDFExam;

      console.log("admission exam", admissionExam);

      if (admissionExam.current_status_id === this.examStatusesData.examen_impreso) {
          this.$refs.myPdfComponent.print();
          return;
      }

      this.$f7.dialog.confirm("El exámen quedará en estado Exámen Impreso. ¿Esta seguro?", () => {
            this.$f7.dialog.preloader("Cambiando el estado del examen...");

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.updateInfo({
                        url: `admission_exams/status/${this.examStatusesData.examen_impreso}`,
                        userToken: idToken,
                        data: {
                            exams: [
                                { admission_exam_id: admissionExam.id },
                            ],
                        },
                    });
                })
                .then(response => {
                    const updatedAdmissionExam = response.data.updatedAdmissionExams[0];

                    this.$store.commit("plantillas/update_viewing_admission_exam", updatedAdmissionExam);
                    this.$store.commit("setWhatTo", { what: "viewingFocusedPDFExam", to: updatedAdmissionExam });

                    this.$refs.myPdfComponent.print();

                    this.$f7.dialog.close();
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, err.code);
                    console.error(err);
                });
        });
  },

  revertirImpreso() {
      const admissionExam = this.viewingFocusedPDFExam;

      this.$f7.dialog.confirm("El exámen quedará en estado Exámen Firmado. ¿Esta seguro?", () => {
            this.$f7.dialog.preloader("Cambiando el estado del examen...");

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.updateInfo({
                        url: `admission_exams/status/${this.examStatusesData.examen_firmado}`,
                        userToken: idToken,
                        data: {
                            exams: [
                                {
                                    admission_exam_id: admissionExam.id,
                                    revert: true,
                                },
                            ],
                        },
                    });
                })
                .then(response => {
                    const updatedAdmissionExam = response.data.updatedAdmissionExams[0];

                    this.$store.commit("plantillas/update_viewing_admission_exam", updatedAdmissionExam);
                    this.$store.commit("setWhatTo", { what: "viewingFocusedPDFExam", to: updatedAdmissionExam });

                    this.$f7.dialog.close();
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, err.code);
                    console.error(err);
                });
      });
  },

  nextpage(){
    if(this.page<this.numPages){
      this.page++;
    }
  },
  prevPage(){
    if(this.page>1){
      this.page--;
    }
  },
  actualizarLoadedRatio(cuanto){
   console.log(cuanto);
    let percet=Math.floor(cuanto * 100);
    this.progressdialog.setProgress(percet);
    this.progressdialog.setText(percet + '%');
    if(cuanto==1){
       this.progressdialog.close();
    }
  },
  pdferror(err) {

            console.log(err);
        }
}
}
</script>
<style >
.all100pdf{
  width:100%;
}
.view-master-detail .page-master-detail .navbar.nohideback .link.back {
  display:flex!important;
}
</style>



