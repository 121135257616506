<template>
    <f7-popup class="patient-more-info" :opened="popupOpened" @popup:closed="$emit('popup-closed')">
        <f7-page>
            <f7-navbar title="Información del paciente">
                <f7-nav-right>
                    <f7-link popup-close>Cerrar</f7-link>
                </f7-nav-right>
            </f7-navbar>
            <f7-block class="margin-top-half">
                <f7c-alert type="warning" v-if="!readonly">
                    <div>
                        <b>IMPORTANTE</b>
                    </div>

                    Este módulo debe usarse solo para corregir errores de ingreso/actualizaciones de datos del
                    paciente , cambios puntuales de información tales como: cambio de nombre, telefono, error
                    al ingreso del rut, etc... Cualquier modificación afectará la admisión actual, y el
                    mantenedor de pacientes (todas las admisiones futuras).

                    <div>
                        <b>
                            NO UTILICE ESTE MODULO PARA 'CAMBIAR AL PACIENTE' asociado a la admisión.
                        </b>
                    </div>
                </f7c-alert>
                <f7-row class="margin-vertical">
                    <f7-col>
                        <label>Tipo de documento</label>
                        <f7-input
                            type="select"
                            @change="patient.tipoIdentificador = $event.target.value"
                            :value="patient.tipoIdentificador"
                            :disabled="
                                readonly ||
                                    (type === 'complete-data' && originalData.tipoIdentificador.length > 0)
                            "
                        >
                            <option value="">Seleccione...</option>
                            <option value="RUT">RUT</option>
                            <option value="PASAPORTE">Pasaporte</option>
                            <option value="DNI">DNI</option>
                            <option value="otro">Otro</option>
                        </f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Número de documento</label>
                        <f7-input
                            type="text"
                            @input="patient.identificador = $event.target.value"
                            :value="patient.identificador"
                            :disabled="
                                readonly ||
                                    (type === 'complete-data' && originalData.identificador.length > 0)
                            "
                            :clear-button="type === 'modify-data'"
                        ></f7-input>
                    </f7-col>
                </f7-row>

                <f7-row class="margin-bottom">
                    <f7-col>
                        <label>Apellido paterno</label>
                        <f7-input
                            class="input-uppercase"
                            type="text"
                            @input="patient.primerApellido = $event.target.value"
                            :value="patient.primerApellido"
                            :disabled="
                                readonly ||
                                    (type === 'complete-data' && originalData.primerApellido.length > 0)
                            "
                            :clear-button="type === 'modify-data'"
                        ></f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Apellido materno</label>
                        <f7-input
                            class="input-uppercase"
                            type="text"
                            @input="patient.segundoApellido = $event.target.value"
                            :value="patient.segundoApellido"
                            :disabled="
                                readonly ||
                                    (type === 'complete-data' && originalData.segundoApellido.length > 0)
                            "
                            :clear-button="type === 'modify-data'"
                        ></f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Nombres</label>
                        <f7-input
                            class="input-uppercase"
                            type="text"
                            @input="patient.nombres = $event.target.value"
                            :value="patient.nombres"
                            :disabled="
                                readonly || (type === 'complete-data' && originalData.nombres.length > 0)
                            "
                            :clear-button="type === 'modify-data'"
                        ></f7-input>
                    </f7-col>
                </f7-row>

                <f7-row class="margin-bottom">
                    <f7-col>
                        <label>Fecha de nacimiento</label>
                        <f7-input
                            type="text"
                            @input="patient.fechaNacimiento = $event.target.value"
                            :value="patient.fechaNacimiento | formatDate"
                            :maxlength="10"
                            :disabled="
                                readonly ||
                                    (type === 'complete-data' && originalData.fechaNacimiento.length > 0)
                            "
                            :clear-button="type === 'modify-data'"
                        ></f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Edad</label>
                        <f7-input>
                            <span class="uppercase">{{ patientAge(patient.fechaNacimiento) }}</span>
                        </f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Sexo</label>
                        <f7-input
                            type="select"
                            @change="patient.sexo = $event.target.value"
                            :value="patient.sexo"
                            :disabled="readonly || (type === 'complete-data' && originalData.sexo.length > 0)"
                        >
                            <option value="">Seleccione...</option>
                            <option value="Masculino">M</option>
                            <option value="Femenino">F</option>
                        </f7-input>
                    </f7-col>
                    <f7-col>
                        <!-- bug: como deshabilitar un f7-list-item? -->
                        <label>Nacionalidad</label>
                        <f7-list no-hairlines class="no-margin-top">
                            <f7-list-item
                                class="smart-select-list-item-sm"
                                :title="patient.nacionalidad || 'Seleccione...'"
                                smart-select
                                :smart-select-params="{
                                    openIn: 'popup',
                                    searchbar: true,
                                    searchbarPlaceholder: 'Buscar nacionalidad',
                                    closeOnSelect: true,
                                    setValueText: false,
                                    popupCloseLinkText: 'Cerrar',
                                    pageTitle: 'Seleccione una nacionalidad',
                                }"
                                :disabled="readonly || (type === 'complete-data' && originalData.nacionalidad.length > 0)"
                            >
                                <select
                                    @change="patient.nacionalidad = $event.target.value"
                                    :value="patient.nacionalidad"
                                >
                                    <option value="">Seleccione...</option>
                                    <option v-for="country in paises" :key="country.id" :value="country.name">
                                        {{ country.name }}
                                    </option>
                                </select>
                            </f7-list-item>
                        </f7-list>
                    </f7-col>
                </f7-row>

                <f7-row class="margin-bottom">
                    <f7-col>
                        <label>Dirección</label>
                        <f7-input
                            class="input-uppercase"
                            type="text"
                            @input="patient.direccion = $event.target.value"
                            :value="patient.direccion"
                            :disabled="
                                readonly || (type === 'complete-data' && originalData.direccion.length > 0)
                            "
                            :clear-button="type === 'modify-data'"
                        ></f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Comuna</label>
                        <f7-list no-hairlines class="no-margin-top">
                            <f7-list-item
                                class="smart-select-list-item-sm"
                                :title="patient.comuna || 'Seleccione...'"
                                smart-select
                                :smart-select-params="{
                                    openIn: 'popup',
                                    searchbar: true,
                                    searchbarPlaceholder: 'Buscar comuna',
                                    closeOnSelect: true,
                                    setValueText: false,
                                    popupCloseLinkText: 'Cerrar',
                                    pageTitle: 'Seleccione una comuna',
                                }"
                                :disabled="
                                    readonly || (type === 'complete-data' && originalData.comuna.length > 0)
                                "
                            >
                                <select
                                    @change="patient.comuna = $event.target.value"
                                    :value="patient.comuna"
                                >
                                    <option value="">Seleccione...</option>
                                    <optgroup
                                        v-for="region in comunas.regions"
                                        :key="region.number"
                                        :label="region.name"
                                    >
                                        <option
                                            v-for="(comuna, index) in region.communes"
                                            :key="index"
                                            :value="`${comuna.name}-${region.romanNumber}`"
                                        >
                                            {{ comuna.name }}
                                        </option>
                                    </optgroup>
                                </select>
                            </f7-list-item>
                        </f7-list>
                    </f7-col>
                    <f7-col>
                        <label>Telefono</label>
                        <f7-input
                            class="input-uppercase"
                            type="text"
                            @input="patient.telefono = $event.target.value"
                            :value="patient.telefono"
                            :disabled="
                                readonly || (type === 'complete-data' && originalData.telefono.length > 0)
                            "
                            :clear-button="type === 'modify-data'"
                        ></f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Mail</label>
                        <f7-input
                            class="input-uppercase"
                            type="email"
                            @input="patient.email = $event.target.value"
                            :value="patient.email"
                            :disabled="
                                readonly || (type === 'complete-data' && originalData.email.length > 0)
                            "
                            :clear-button="type === 'modify-data'"
                        ></f7-input>
                    </f7-col>
                </f7-row>

                <f7-row class="margin-bottom">
                    <f7-col>
                        <label>Previsión</label>
                        <f7-input
                            type="select"
                            @change="patient.prevision = $event.target.value"
                            :value="patient.prevision"
                            :disabled="
                                readonly || (type === 'complete-data' && originalData.prevision.length > 0)
                            "
                        >
                            <option value="">Seleccione...</option>
                            <option value="prevision_1">Prevision 1</option>
                            <option value="prevision_2">Prevision 2</option>
                        </f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Estado</label>
                        <f7-input
                            class="input-uppercase"
                            type="text"
                            @input="patient.estado = $event.target.value"
                            :value="patient.estado"
                            :disabled="
                                readonly || (type === 'complete-data' && originalData.estado.length > 0)
                            "
                            :clear-button="type === 'modify-data'"
                        ></f7-input>
                    </f7-col>
                </f7-row>

                <f7-button @click="$emit('modify-patient')" v-if="!readonly">
                    {{ type === "complete-data" ? "Completar datos" : "Modificar paciente" }}
                </f7-button>
            </f7-block>
        </f7-page>
    </f7-popup>
</template>

<script>
import { _patientAge } from "@/app/helpers";
import countries from "@/app/countries.json";
import communes from "@/app/comunas.json";

export default {
    props: {
        popupOpened: Boolean,
        patient: Object,
        type: String,
        readonly: Boolean,
    },

    data() {
        return {
            paises: countries,
            comunas: communes,

            originalData: {},
            patientAge: _patientAge,
        };
    },

    watch: {
        patient(value) {
            this.originalData = Object.assign({}, value);
        },
    },
};
</script>
