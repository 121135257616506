<template>
    <f7-page name="admission-index">
        <f7-navbar title="Carga de Resultados">
            <f7-nav-right>
                <template>
                    <f7-button outline class="margin-right" color="blue" @click="clear">
                        Limpiar
                    </f7-button>
                </template>
            </f7-nav-right>
        </f7-navbar>

        <f7-block class="no-margin no-padding">
            <f7-card outline>
                <f7-card-content class="no-padding-top">
                    <ot-search @data-found="handleOTfound" :reset="reset">
                        <h4>Búsqueda por OT</h4>
                    </ot-search>
                </f7-card-content>
            </f7-card>

            <f7-card outline>
                <f7-card-content class="no-padding-top">
                    <exams-search @exams-found="handleExamsFound">
                        <h4>Búsqueda de exámenes</h4>
                    </exams-search>
                </f7-card-content>
            </f7-card>

            <f7-card outline>
                <f7-card-content class="no-padding-top">
                    <patient-form
                        :source="source"
                        read-only
                        @patient-searched="handlePatientSearched"
                        :reset="reset"
                    >
                        <h4>Búsqueda de exámenes por paciente</h4>
                    </patient-form>
                </f7-card-content>
            </f7-card>

            <div class="margin-horizontal-half padding-top">
                <exams-table
                    v-if="Object.keys(examsFound).length"
                    :source="source"
                    :exams="examsFound"
                ></exams-table>
            </div>
        </f7-block>
    </f7-page>
</template>

<script>
import OTSearch from "@/app/components/ot-search.vue";
import ExamsSearch from "@/app/components/exams-search.vue";
import PatientForm from "@/app/components/patient-form.vue";
import ExamsTable from "@/app/components/exams-table.vue";

import { _sources } from "@/app/constants";

export default {
    components: {
        "ot-search": OTSearch,
        "exams-search": ExamsSearch,
        "patient-form": PatientForm,
        "exams-table": ExamsTable,
    },

    data() {
        return {
            source: "",
            examsFound: {},
            reset: false,
        };
    },

    methods: {
        handleOTfound(data) {
            const { ot, admExams } = data;

            this.examsFound = admExams;

            this.source = _sources.OT_SEARCH;
        },
        handleExamsFound(exams) {
            console.log("exams found", exams);
            this.examsFound = exams;
            this.source = _sources.EXAMS_SEARCH;
        },
        handlePatientSearched(patient) {
            console.log("patient searched", patient);
            if (patient) {
                this.$firebase
                    .database()
                    .ref("/admExams")
                    .orderByChild("patientId")
                    .equalTo(patient.id)
                    .once("value")
                    .then((snapshot) => {
                        this.examsFound = snapshot.val() || {};

                        this.source = _sources.EXAMS_SEARCH;
                    })
                    .catch((err) => {
                        console.error(err);
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert("Hubo un error al cargar los examenes del paciente. " + err);
                    });
            }
        },
        clear() {
            this.$f7.dialog.confirm("¿Está seguro?", () => {
                this.reset = !this.reset;
                this.examsFound = {};
                this.otPatient = {};
            });
        },
    },
};
</script>
