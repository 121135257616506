<template>
    <f7-page>
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title >Traslado de muestras</f7-nav-title>
        </f7-navbar>
        <f7-block>
            <f7-row class="padding-horizontal margin-horizontal">
                <f7-col 
                    v-for="(section, index) in sections" 
                    :key="index" 
                    width="100" medium="50"
                    class="margin-vertical">
                    <f7-button 
                        large raised fill 
                        class="font-button"
                        icon-material="input"
                        @click="moveSample(section)">
                        {{section.name}}
                    </f7-button>
                </f7-col>
            </f7-row>
            <f7-row class="padding-horizontal margin">
                <f7-col width="100" medium="50">
                    <f7-button 
                        large raised outline 
                        class="font-button margin-bottom"
                        href="/sample-info/" 
                        icon-f7="camera_on_rectangle_fill"> 
                        Detalles de muestra
                    </f7-button>
                </f7-col>
                <f7-col width="100" medium="50">
                    <f7-button 
                        large raised outline 
                        class="font-button" 
                        icon-material="delete"
                        @click="moveSample({})"> 
                        Descartar muestra
                    </f7-button>
                </f7-col>
            </f7-row>
            
        </f7-block>
    </f7-page>
</template>

<script>
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState(['sections'])
    },
    methods: {
        moveSample(section){
            this.$store.commit('setObjTo',{what: 'focused_section', to: section});
            this.$f7.views.main.router.navigate('/sample-location/');
        },
    }
}
</script>

<style>

.font-button{
    font-size: 20px;
}

</style>