<template>
    <f7-page>
        <f7-navbar title="Detalles de Ingresos">
            <f7-nav-right>
                <f7-link popup-close>Cerrar</f7-link>
            </f7-nav-right>
        </f7-navbar>
        <f7-block>
            <f7-row>
                <f7-col>
                    <f7-list no-hairlines-md>
                        <f7-list-input
                            required
                            type="select"
                            label="Filtro de busqueda"
                            :value="table.searchFilter"
                            @change="preSearch($event.target.value)"
                        >
                            <option value="deposit_code">Codigo</option>
                            <option value="entry">Fecha de entrada</option>
                            <option value="expiration">Fecha de expiracion</option>
                        </f7-list-input>
                    </f7-list>
                </f7-col>
                <f7-col>
                    <f7-list no-hairlines-md>
                        <f7-list-input
                            @input="preFilter($event.target.value)"
                            label="Informacion a buscar"
                            type="text"
                            :value="table.searchData"
                            placeholder="Busqueda...."
                        ></f7-list-input>
                    </f7-list>
                </f7-col>
                <f7-col>
                    <f7-list no-hairlines-md>
                        <f7-list-item
                            checkbox
                            :checked="closed"
                            @change="changeStock2($event.target.checked)"
                            title="Partidas cerradas"
                        ></f7-list-item>
                    </f7-list>
                </f7-col>
                <f7-col width="10">
                    <f7-list no-hairlines-md>
                        <f7-list-input required type="select" label="Mostrar" :value="table.perPage" @change="perPageChange($event.target.value)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                        </f7-list-input>
                    </f7-list>
                </f7-col>
            </f7-row>
            <f7-row>
                <f7-col>
                    <div class="data-table card">
                        <table>
                            <thead>
                                <tr>
                                    <td style="width:10%">Codigo</td>
                                    <td style="width:20%">Proveedor</td>
                                    <td style="width:20%">Marca</td>
                                    <td style="width:10%">Cantidad</td>
                                    <td style="width:10%">Usados</td>
                                    <td style="width:10%">Fecha Ingreso</td>
                                    <td style="width:10%">Fecha Expiracion</td>
                                    <td style="width:10%">Responsable</td>
                                    <td class="align-center"><f7-icon f7="barcode"></f7-icon></td>
                                    <td class="align-center"><f7-icon material="edit"></f7-icon></td>
                                    <td class="align-center"><f7-icon f7="trash"></f7-icon></td>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="item in table.pages[table.page - 1]">
                                    <tr v-if="show(item.quantity, item.used.length)" :key="item.id">
                                        <td>{{ item.deposit_code }}</td>
                                        <td>{{ item.provider.description }}</td>
                                        <td>{{ item.brand.description }}</td>
                                        <td>{{ item.quantity }}</td>
                                        <td>{{ item.used.length }}</td>
                                        <td>{{ formated(item.entry) }}</td>
                                        <td>{{ formated(item.expiration) }}</td>
                                        <td>{{ item.user }}</td>
                                        <td class="align-center">
                                            <f7-button tooltip="Impresion de etiquetas" @click="printBarcode(item)" btn fill>
                                                <f7-icon class="icon" f7="barcode"></f7-icon>
                                            </f7-button>
                                        </td>
                                        <td class="align-center">
                                            <f7-button tooltip="Editar ingreso" @click="$emit('edit-entry', item)" btn fill>
                                                <f7-icon class="icon" material="edit"></f7-icon>
                                            </f7-button>
                                        </td>
                                        <td class="align-center">
                                            <f7-button v-if="disableElim(item)" @click="deleteEntry(item.id, item.supply_id)" btn fill>
                                                <f7-icon class="icon" f7="trash"></f7-icon>
                                            </f7-button>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </f7-col>
            </f7-row>
            <f7-row>
                <f7-col>
                    <div class="data-table card">
                        <f7-row style="padding:1%">
                            <f7-col>
                                <f7-button :disabled="enablePre" @click="table.page--" small btn outline>Anterior</f7-button>
                            </f7-col>
                            <f7-col width="5">
                                <f7-input type="number" min="1" :max="limitPage" @input="table.page = $event.target.value" :value="table.page" />
                            </f7-col>
                            <f7-col>
                                <f7-button :disabled="enableNext" @click="table.page++" small btn outline>Siguiente</f7-button>
                            </f7-col>
                            <f7-col style="text-align:center" width="25">
                                mostrando pagina {{ this.table.page }} de {{ this.table.pages.length == 0 ? 1 : this.table.pages.length }}, Total:
                                {{ totalRegisters }} Registros
                            </f7-col>

                            <f7-col></f7-col>
                            <f7-col></f7-col>
                            <f7-col></f7-col>
                            <f7-col></f7-col>
                        </f7-row>
                    </div>
                </f7-col>
            </f7-row>
        </f7-block>
    </f7-page>
</template>

<script>
import moment from "moment";
import { printermixin } from "../../../mixins/printermixin";

export default {
    mixins: [printermixin],

    props: {
        supplyDetail: { required: true },
    },

    computed: {
        totalRegisters() {
            let total = 0;

            for (const item of this.table.pages) {
                total += parseInt(item.length);
            }

            return total;
        },
        data() {
            return this.paginate(this.elements);
        },
        limitPage() {
            return this.table.pages.length;
        },
        enablePre() {
            return this.table.page > 1 ? false : true;
        },
        enableNext() {
            return this.table.page < this.limitPage ? false : true;
        },
        stockCheck() {
            return this.table.stock;
        },
        limitCheck() {
            return this.table.limit;
        },
        all() {
            return this.table.all;
        },
    },
    data() {
        return {
            elements: [],
            closed: false,
            table: {
                page: 1,
                perPage: 10,
                pages: [],
                searchFilter: "deposit_code",
                searchData: "",
                stock: true,
                limit: false,
                all: false,
            },
        };
    },
    watch: {
        supplyDetail: {
            handler() {
                this.elements = this.supplyDetail;
                this.chunkArray();
            },
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        changeStock2(value) {
            console.log("test");
            this.closed = value;
            this.chunkArray();
        },
        show(total, used) {
            if (this.closed) {
                return total == used ? true : false;
            } else {
                return total > used ? true : false;
            }
        },
        printBarcode(item) {
            this.$f7.dialog.prompt(
                "Impresion de etiquetas",
                "¿Confirme la cantidad de etiquetas a imprimir?",
                (value) => {
                    try {
                        this.makeSupplyLabel(
                            item.deposit_code,
                            this.formated(item.entry),
                            this.formated(item.expiration),
                            item.supply.description,
                            value
                        );
                    } catch (error) {
                        console.log(error);
                    }
                },
                () => {},
                1
            );
        },
        disableElim(item) {
            return item.used.length > 0 ? false : true;
        },
        formated(value) {
            if (value != null) {
                return moment(value).format("DD/MM/YYYY");
            } else {
                return "";
            }
        },
        init() {
            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then(async (token) => {
                    let values = await this.$store.dispatch("apiRequest", {
                        app: this,
                        url: "universal/search",
                        token,
                        method: "POST",
                        data: { relation: ["provider", "brand", "supply"], model: "Deposit", field: "id", search: this.$props.supply_id },
                    });
                    this.elements = values.info.data;
                });
        },
        deleteEntry(id, supply) {
            this.$f7.dialog.confirm("Eliminar registro de ingreso", "¿Desea eliminar el registro de ingreso?", () => {
                this.$firebase
                    .auth()
                    .currentUser.getIdToken()
                    .then(async (token) => {
                        await this.$store.dispatch("apiRequest", {
                            app: this,
                            url: "universal/",
                            token,
                            method: "PUT",
                            data: { info: { deletedAt: moment().format("YYYY-MM-DD") }, model: "Deposit", search: id },
                        });
                        this.$emit("reloadDetail", supply);
                        this.$emit("updateStock");
                    });
            });
        },
        chunkArray() {
            var results = [];
            var filtered = [];
            var postFilter = [];
            const work = this.elements;

            if (this.table.searchData != "") {
                work.forEach((element) => {
                    if (element[this.table.searchFilter].search(this.table.searchData) != -1) {
                        filtered.push(element);
                    }
                });
            } else {
                filtered = work;
            }

            for (const item of filtered) {
                if (this.closed == true) {
                    if (item.quantity == item.used.length) {
                        postFilter.push(item);
                    }
                } else {
                    if (item.quantity > item.used.length) {
                        postFilter.push(item);
                    }
                }
            }

            results = this.chunkArrayInGroups(postFilter, this.table.perPage);

            this.table.pages = results;
        },
        chunkArrayInGroups(arr, size) {
            var myArray = [];
            for (var i = 0; i < arr.length; i += size) {
                myArray.push(arr.slice(i, i + size));
            }
            return myArray;
        },
        preFilter(value) {
            if (value != "") {
                if (value != "no-value") {
                    this.table.searchData = value;
                }

                this.chunkArray();
            } else {
                this.table.searchData = "";

                this.chunkArray();
            }
        },
        preSearch(value) {
            this.table.searchFilter = value;

            this.chunkArray();
        },
        perPageChange(value) {
            this.table.perPage = value;
            this.chunkArray();
        },
        log(value) {
            console.log(value);
        },
        changeStock(value) {
            this.table.stock = value;
            this.chunkArray();
        },
        changeLimit(value) {
            this.table.limit = value;
            this.chunkArray();
        },
    },
};
</script>

<style scoped>
.mb-2 {
    margin-bottom: 4% !important;
}
.mb-1 {
    margin-bottom: 3% !important;
}
.align-left {
    text-align: left;
}
.align-center {
    text-align: center !important;
}
.padding-1 {
    padding: 0% 1%;
}
.toast {
    left: 45% !important;
}
.toast-text {
    font-size: 1.3em;
}
.icon {
    font-size: 13px;
}
</style>
