<template>
    <f7-page name="newSample"  @page:afterin="getSelectedRejections">
        <f7-navbar v-if="!isEditor" title="Agregar nuevo tipo de muestra" back-link></f7-navbar>
        <f7-navbar v-else title="Editar muestra" back-link></f7-navbar>
        
        <f7-list no-hairlines-md class="no-margin-top">
            <f7-list-input
                label="Nombre de muestra"
                type="text"
                name="sampleName"
                placeholder="Nombre muestra"
                :value="sample.name"
                @input="sample.name = $event.target.value"
                required validate
                error-message="Debe indicar el nombre de la muestra"
            ></f7-list-input>
            <f7-list-input
                label="Código SNOMED CT"
                type="text"
                name="snomed_code"
                placeholder="Código SNOMED CT"
                :value="sample.snomed_code"
                @input="sample.snomed_code = $event.target.value"
            ></f7-list-input>
            <f7-list-input
                label="Descripción"
                type="textarea"
                name="description"
                placeholder="Descripción (Opcional)"
                :value="sample.description"
                @input="sample.description = $event.target.value"
            ></f7-list-input>
        </f7-list>

        <div class="list no-hairlines no-margin-top margin-bottom-half">
            <ul>
                <li>
                    <!-- Add smart-select-init class -->
                    <a href="#" class="item-link smart-select select-rejection" 
                        data-searchbar="true"
                        data-searchbar-placeholder="Busque el motivo de rechazo"
                        data-open-in="popup"
                        data-popup-close-link-text="Cerrar"
                        @smartselect:close="selectRejections"
                        >
                        <!-- select -->
                        <select name="select-rejection" multiple>
                            <option 
                                v-for="(reason, index) in $store.getters.objToArray('allReasons')"
                                :key="index" 
                                :value="reason.name"
                                :selected="rejectionNames.includes(reason.name)"
                                >{{reason.name}}
                            </option>
                        </select>
                        <div class="item-content">
                            <div class="item-inner">
                                <div class="item-title">Motivos de rechazo</div>
                            </div>
                        </div>      
                    </a>
                </li>
            </ul>
        </div>

        <f7-block>
            <f7-row>
                <f7-col width="10" medium="33">
                </f7-col>
                <f7-col width="0" medium="33">
                    <f7-button
                        :icon-material="!isEditor ? 'playlist_add' : 'edit'"
                        fill
                        raised
                        round
                        :text="!isEditor ? 'Agregar nueva muestra' : 'Guardar cambios'"
                        @click=" !isEditor ? addSample() : editSample()" 
                    />
                </f7-col>
                <f7-col width="10" medium="33">
                </f7-col>
            </f7-row>
        </f7-block>
    </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    data(){
        return {
            sample: {
                name: '',
                description: '',
                snomed_code: '',
                enabled: 1
            },
            isEditor: false,
            rejectionNames: [],
        }
    },
    computed: {
        ...mapState(['selectedSample', 'profile_userInfo', 'allReasons', 'sampleRejections', 'baseUrl']),
    },
    methods: {
        ...mapActions(['getAPIInfo', 'createNewInfo', 'updateInfo', 'deleteAPIInfo']),

        setAddOrEdit(){
            //Si está activado el modo de editar muestra se deben mostrar datos de muestra seleccionada
            if(this.selectedSample.name){
                this.isEditor = true;
                this.sample = { ...this.selectedSample};
                this.rejectionNames = this.sampleRejections.map(rejection => rejection.reason);
            }
        },

        getSelectedRejections(){
            //Se obtienen los motivos de rechazo seleccionados para mostrarlos en smart-select
            if(this.isEditor){
                this.$f7.smartSelect.create({
                    el: '.select-rejection', 
                    setValueText: true, 
                    formatValueText:(values) => {
                        return values.length > 0 ? values : this.rejectionNames
                    }
                })
            }
        },

        getRejections(){
            if(!this.allReasons){
                this.$firebase.database().ref('rejections/').orderByChild('enabled').equalTo(true).once('value')
                    .then((snapshot) => {
                        this.$store.commit('setObjTo', {what: 'allReasons', to: snapshot.val()});
                    })
                    .catch((err)=>{
                        this.$f7.dialog.alert(err.message, err.code);
                });
            }
        },

        selectRejections(){
            const value = this.$f7.smartSelect.get('.select-rejection').getValue();
            if(value.length > 0){
                this.rejectionNames = value;
                // this.sample.rejections = {};
                // value.forEach((item)=>{this.sample.rejections[item] = true});
            }
        },

        addSample(){
            let sample = {...this.sample}
            if(!sample.name){
                this.$f7.dialog.alert('Debe ingresar un nombre para la muestra', 'Error')
            }
            else{
                (!sample.description) && delete sample.description;
                (!sample.snomed_code) && delete sample.snomed_code;
                let userToken
                this.$f7.dialog.preloader('Guardando');
                this.$firebase.auth().currentUser.getIdToken()
                .then((idToken) => {
                    userToken = idToken;
                    return this.createNewInfo({url: 'samples/', data: sample, userToken})
                })
                .then((resp) => {
                    const newSample = resp.data.data;
                    const rejections = this.rejectionNames.map((rejection) => {
                        const newRejection = {reason: rejection}
                        return this.createNewInfo({url: `samples/${newSample.id}/rejections`, data: newRejection, userToken})
                    })
                    Promise.all(rejections)
                    .then(() => {
                        this.$store.commit('pushToArray', {array: 'allSamples', value: newSample})
                        this.$store.commit('setObjTo', {what: 'selectedSample', to: newSample});
                        
                        //Se agrega información al nodo log
                        let info = {
                            who: this.profile_userInfo.uid,
                            wht: 'add-sample',
                            whn: this.$moment().unix(),    
                            dls: 'Crea nuevo tipo de muestra',
                            pth: this.baseUrl + 'samples/',
                        }
                        let logId = this.$firebase.database().ref('/log').push().key;
                        this.$firebase.database().ref('log/' + logId).set(info)
                            .catch((err)=>{console.log(err.message)})

                        this.$f7.dialog.close();
                        this.$f7.toast.create({
                            text: 'Los datos se han guardado con éxito', 
                            closeTimeout: 2000, 
                            position: "center"
                        }).open();
                        this.$f7.views.main.router.navigate('/sample/');
                    })
                    .catch((err) => {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(err.response.data.message || err.message, 'Ops, ha ocurrido un error')
                    });
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.response.data.message || err.message, 'Ops, ha ocurrido un error')
                });
            }
        },

        editSample(){
            let {id, createdAt, updatedAt, ...editedSample} = this.sample;
            if(!editedSample.name){
                this.$f7.dialog.alert('Debe ingresar un nombre para la muestra', 'Error')
                return
            }
            (!editedSample.description) && delete editedSample.description;
            (!editedSample.snomed_code) && delete editedSample.snomed_code;
            this.$f7.dialog.preloader('Guardando cambios');
            let userToken
            this.$firebase.auth().currentUser.getIdToken()
            .then((idToken) => {
                userToken = idToken;
                return this.updateInfo({url: 'samples/' + id, data: editedSample, userToken})
            })
            .then(() => {
                this.sampleRejections.forEach((rejection) => {
                    if(!this.rejectionNames.includes(rejection.reason)){
                        this.deleteAPIInfo({url: `exams/${this.sample.id}/rejections/${rejection.id}`, userToken})
                        .catch((err) => this.$f7.dialog.alert(err.response.data.message || err.message, 'Ops, ha ocurrido un error'))
                    }
                });
                this.rejectionNames.forEach((name) => {
                    if(!this.sampleRejections.find((rejection) => rejection.reason === name)){
                        let newRejection = {reason: name};
                            this.createNewInfo({
                                url: `samples/${id}/rejections`, 
                                data: newRejection, 
                                userToken
                            })
                        .catch((err) => this.$f7.dialog.alert(err.response.data.message || err.message, 'Ops, ha ocurrido un error'))
                    }
                })
                //Se agrega información al nodo log
                let logInfo = {
                    who: this.profile_userInfo.uid,
                    wht: 'edit-sample',
                    whn: this.$moment().unix(),    
                    dls: 'Edita tipo de muestra',
                    pth: this.baseUrl + '/samples/' + this.sample.id,
                }
                let logId = this.$firebase.database().ref('/log').push().key;
                this.$firebase.database().ref('log/' + logId).set(logInfo)
                    .catch((err)=>{console.log(err.message)})

                this.$store.commit('replaceInArray', {array: 'allSamples', attr: 'id', value: id, newValue: this.sample});
                this.$store.commit('setObjTo', {what: 'selectedSample', to: this.sample});
                this.$f7.dialog.close();
                this.$f7.toast.create({
                    text: 'Los cambios se han guardado con éxito', 
                    closeTimeout: 2000, 
                    position: "center"
                }).open();
                this.$f7.views.main.router.navigate('/sample/');
            })
            .catch((err) => {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(err.response.data.message || err.message, 'Ops, ha ocurrido un error')
            });
        }
    },

    mounted(){
        this.getRejections();
        this.setAddOrEdit();
    }
}
</script>