module.exports = {
    namespaced: true,

    state: {
        allAgreements: [],

        viewingAgreement: null,
    },

    mutations: {
        set_all_agreements(state, agreements) {
            state.allAgreements = agreements;
        },

        set_viewing_agreement(state, agreement) {
            state.viewingAgreement = agreement;
        },

        add_agreement(state, agreement) {
            state.allAgreements.push(agreement);
        },

        update_agreement(state, updatedAgreement) {
            const index = state.allAgreements.findIndex((a) => a.id === updatedAgreement.id);

            state.allAgreements.splice(index, 1, updatedAgreement);
        },
    },
};
