<template>
    <f7-page >
        <f7-navbar title="Agregar nuevo proveedor">
            <f7-nav-right>
                <f7-link popup-close>Cerrar</f7-link>
            </f7-nav-right>
        </f7-navbar>
        <form @submit.prevent="create" >
            <f7-block>
                <f7-row class="mb-2">
                    <f7-col width="100" >
                        <label>Nombre del proveedor</label>
                        <f7-input :value="form.description" required type="text" placeholder="" info="Nombre comercial del proveedor" @change="form.description = $event.target.value" />
                    </f7-col>
                </f7-row>
                <f7-row class="mb-2">
                    <f7-col width="50" >
                        <label>RUT</label>
                        <f7-input :value="form.dnu" type="text" placeholder="" info="Documento fiscal del proveedor" @input="saveDnu($event.target.value)" />
                    </f7-col>
                    <f7-col width="50" >
                        <label>Telefono</label>
                        <f7-input :value="getPhone" type="text" placeholder="" info="Telefono de contacto" @input="formater($event.target.value)" />
                    </f7-col>
                </f7-row>
                <f7-row class="mb-2">
                    <f7-col width="100" >
                        <label>Nombre del vendedor</label>
                        <f7-input :value="form.seller" type="text" placeholder="" info="Nombre del vendedor asignado a la empresa " @change="form.seller = $event.target.value" />
                    </f7-col>
                </f7-row>
                <f7-row class="mb-7">
                    <f7-col width="100" >
                        <label>Observaciones</label>
                        <f7-input :value="form.details" rows="5" type="textarea" placeholder="" info="Observaciones y detalles referenciales" @change="form.details = $event.target.value" />
                    </f7-col>
                </f7-row>
                <f7-row class="mb-2">
                    <f7-col />
                    <f7-col />
                    <f7-col />
                    <f7-col  >
                        <f7-button fill type="submit">Guardar</f7-button>
                        
                    </f7-col>
                </f7-row>
            </f7-block>
        </form>
    </f7-page>
</template>

<script>

import {  validate, clean, format, getCheckDigit } from 'rut.js'

export default {

    data(){
        return {
            form : {
                description : "",
                dnu : "",
                phone : "",
                seller : "",
                details : ""
            }
        } 
    },
    mounted(){
        let textareas = document.getElementsByTagName('textarea');
    
        for (let item of textareas) {
            item.setAttribute('rows', 5);
        }

       this.init2();
    },
    computed : {
        getPhone(){
            return this.form.phone ;
        }
    },
    methods : {
        saveDnu(value){
            this.form.dnu = format(value);
        },
        formater(value){
            this.form.phone = value.replace(/[a-z]/gi, '') 
        },
        create(){
            var reg = new RegExp("-")
            if(this.form.dnu.length == 9 && this.form.dnu.search(reg) == -1){
                this.$store.dispatch("toast",{ app : this, text : "Debe ingresar un RUT Valido" });
                return false;
            }
            if(!validate(this.form.dnu)){
                this.$store.dispatch("toast",{ app : this, text : "Debe ingresar un RUT Valido" });
                return false;
            }

            this.$firebase.auth().currentUser.getIdToken().then(async (token) => {
                var result = await this.$store.dispatch("apiRequest",{ url : "universal/" ,token , method : "POST", data : { 'info' : this.form, 'model' : 'Provider'}, app : this});

                if(result.code == 200){
                    this.init2();
                    this.$store.dispatch("updateState",{token, state : [{"path" : "universal/?model=Provider", "model" : "provider"}]});
                }
                this.init2();
            });
        },
        init2(){
            console.log("init provider")
            this.form.description = "";
            this.form.dnu = "";
            this.form.phone = "";
            this.form.seller = "";
            this.form.details = "";
        }
    }

}
</script>

<style scoped>
.mb-2{
    margin-bottom: 4% !important;
}
.mb-7{
    margin-bottom: 7% !important;
}
.align-left{
    text-align: left;
}
.pull-right{
    float:right;
}
.pull-left{
    float: left;
}
</style>