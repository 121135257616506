<template>
    <f7-page >
        <f7-navbar title="Reingreso de insumos">
            <f7-nav-right>
                <f7-link popup-close>Cerrar</f7-link>
            </f7-nav-right>
        </f7-navbar>
        <form @submit.prevent="submit" >
            <f7-block>
                <f7-row>
                    <f7-col>
                        <f7-list no-hairlines-md>
                            <li class="">
                                <div class="item-content item-input item-input-with-value">
                                    <div class="item-inner">
                                        <div class="item-title item-label">
                                            Codigo de insumo
                                        </div>
                                        <div class="item-input-wrap">
                                            <input :value="code" list="deposit2" type="text" placeholder="0000000000000" @input="setSupply($event.target.value)" >
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <datalist id="deposit2">
                                <option v-for="(item) in supplyList" :key="item.id" :value="item.description" />
                            </datalist>
                        </f7-list>
                    </f7-col>
                </f7-row>
                <f7-row>
                  
                    <f7-col>
                        <f7-list no-hairlines-md>
                            <f7-list-input required type="select" label="Filtro de busqueda" :value="table.searchFilter" @change="preSearch($event.target.value)">
                                <option value="code">Codigo</option>
                                <option value="description">Descripcion</option>
                                <option value="date">Fecha</option>
                            </f7-list-input>                        
                        </f7-list>
                    </f7-col>
                    <f7-col >
                        <f7-list no-hairlines-md>
                            <f7-list-input @input="preFilter($event.target.value)" label="Informacion a buscar" type="text" :value="table.searchData" placeholder="Busqueda...."></f7-list-input>
                        </f7-list>
                    </f7-col>
                   
                    <f7-col/>
                    <f7-col width="10">
                        <f7-list no-hairlines-md>
                            <f7-list-input required type="select" label="Mostrar" :value="table.perPage" @change="perPageChange($event.target.value)">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="100">100</option>
                                <option value="500">500</option>
                            </f7-list-input>                        
                        </f7-list>
                    </f7-col>
                </f7-row>
                <f7-row>
                    <f7-col>
                        <div class="data-table card" style="min-height: 60vh; max-height: 40vh">
                            <table style="overflow-y:scroll">
                                <thead>
                                    <tr>
                                        <td style="width:10%" >Codigo de egreso</td>
                                        <td style="width:20%" >Descripcion del insumo</td>
                                        <td style="width:10%" >Cantidad Egresada</td>
                                        <td style="width:20%" >Fecha de Egreso</td>
                                        <td style="width:30%" >Responsable del Retiro</td>
                                        <td style="width:10%" ><f7-icon f7="arrow_turn_up_right"/></td>
                                    </tr>
                                </thead>
                                <tbody style="overflow-y:scroll">
                                    <tr v-for="(item) in table.pages[table.page - 1]" :key="item.id" style="">
                                    <td>{{ item.code }}</td>
                                    <td>{{ item.description }}</td>
                                    <td>{{ item.quantity }}</td>
                                    <td>{{ item.date }}</td>
                                    <td>{{ item.user }}</td>
                                    <td>
                                       <f7-row>
                                            <f7-col>
                                                <f7-button tooltip="Reingresar a stock" small fill @click="rollback(item.id, item.quantity,item.deposit)" ><f7-icon class="icon" f7="arrow_turn_up_right" sm /></f7-button>
                                            </f7-col>
                                            <f7-col>
                                            </f7-col>
                                            <f7-col>
                                            </f7-col>
                                        </f7-row>
                                    </td>
                                 </tr>
                                </tbody>
                            
                            </table>
                            
                        </div>
                    </f7-col>
                </f7-row>
                <f7-row>
                    <f7-col>
                        <div class="data-table card">
                            <f7-row style="padding:1%">
                            <f7-col>
                                <f7-button :disabled="enablePre" @click="table.page--" small btn outline>Anterior</f7-button>
                            </f7-col>
                            <f7-col width="5">
                                    <f7-input type="number" min="1" :max="limitPage" @input="table.page = $event.target.value" :value="table.page" />

                            </f7-col>
                            <f7-col>
                                <f7-button :disabled="enableNext"  @click="table.page++" small btn outline>Siguiente</f7-button>
                            </f7-col>
                            <f7-col style="text-align:center" width="25">mostrando pagina {{ this.table.page}} de {{ (this.table.pages.length == 0) ? 1 :this.table.pages.length  }}, Total: {{ totalRegisters }} Registros</f7-col>
                            
                            <f7-col></f7-col>
                            <f7-col></f7-col>
                            <f7-col></f7-col>
                            <f7-col></f7-col>
                        </f7-row>
                        </div>
                    </f7-col>
                   
                </f7-row>
            </f7-block>
        </form>
    </f7-page>
</template>

<script>

import moment from 'moment';
import { mapActions, mapState } from 'vuex';

export default {
    props : {
       reboot : { required : true }
   },
    data() {
        return {
            datos : [],
            flag : "",
            table : {
                page : 1,
                perPage : 10,
                pages : [],
                searchFilter : "code",
                searchData : "",
                stock : true,
                limit : false,
                all : false
            },
            supplyList : [],
            code : ""
        }
    },
    watch : {
        reboot : {
            handler() {
                this.init()
            }
        }
    },

    computed : {
        ...mapState(["stock", "supply"]),
        totalRegisters(){

            let total = 0;

            for(const item of this.table.pages){
                total += parseInt(item.length);
            }

            return total;
        },
        data () {
            return this.paginate(this.datos);
        },
        limitPage(){
            return this.table.pages.length;
        },
        enablePre(){    
            return (this.table.page > 1) ? false : true;
        },
        enableNext(){
            return (this.table.page < this.limitPage) ? false : true;
        },
        stockCheck(){
            return this.table.stock;
        },
        limitCheck(){
            return this.table.limit;
        },
        all(){
            return this.table.all;
        }
    },
    methods : {
        init(){
            this.code = "";
            this.table.pages = [];
            this.table.page = 1

        },
        insumoList(value){
            this.supplyList = []
            var total = 0;
            for(const item of this.stock){
                var code = item.deposit_code;
                var description = "";
                for(const supply of this.supply){
                    if(supply.id == item.supply_id){
                        description = supply.description;
                    }
                }
                var stringFinal = code+" - "+description;
                var reg = new RegExp(value)
                if(stringFinal.search(reg)){
                    if(item.used.length > 0){
                        if(total < 20 ){
                            this.supplyList.push({id : item.id, description : stringFinal})
                            total++;
                        }
                    }
                    

                }
            }
        },
        rollback(id,quantity, deposit){
            this.$f7.dialog.prompt('Reingreso de insumos a stock', '¿Indique la cantidad a reingresar?', (value) => {
                if(isNaN(value) == true){ return this.$store.dispatch('toast',{app : this, text : "Valor no valido, solo se permiten numeros"})}
                if(value > quantity){ return this.$store.dispatch('toast',{app : this, text : "El valor no puede ser mayor a la cantidad retirada en el registro"})}
                this.$firebase.auth().currentUser.getIdToken().then(async (token) => {
                    await this.$store.dispatch("apiRequest",{app : this ,url : "supply/rollbackEgress" ,token , method : "POST", data : {code : id, quantity : value, deposit}});
                    this.$emit("updateStock")
                    this.setSupply(this.flag)
                });
            })
        },
        setSupply(value){
            this.code = value;
            this.insumoList(value)
            if(value.search(" - ")){
                value = value.split(" - ")[0];
            }

            this.datos = []
            this.stock.forEach(element =>  {
                if(element.deposit_code == value){
                    
                  
                    this.$firebase.auth().currentUser.getIdToken().then(async (token) => {
                        let datos = [];
                        let values = await this.$store.dispatch("apiRequest",{app : this, url : "supply/egressHistorical" ,token , method : "POST", data : { "search" : element.id}});
                        
                        for(const item of values.info.data) {

                            datos.push(
                                {
                                    id : item.id,
                                    code : item.id,
                                    description : item.description,
                                    quantity : item.quantity,
                                    date : item.date,
                                    user : item.user,
                                    deposit : item.deposit

                                }
                            );
                        }
                        console.log(datos)
                        this.datos = datos;
                        this.chunkArray();
                        this.flag = value;
                        return;
                    });
                }
            });
            this.chunkArray();
        },
        chunkArray(){

            var results = [];
            var filtered = [];
            var postFilter = [];
            const work = this.datos;
            
            if(this.table.searchData != ""){
                work.forEach(element => {
                  

                    if(element[this.table.searchFilter].search(this.table.searchData) != -1){
                        filtered.push(element)
                    }
                });
            }else{
                filtered = work;
            }

          

            results = this.chunkArrayInGroups(filtered, this.table.perPage)
    
            this.table.pages = results;
        },
        chunkArrayInGroups(arr, size) {
            var myArray = [];
            for(var i = 0; i < arr.length; i += size) {
                myArray.push(arr.slice(i, i+size));
            }
            return myArray;
        },
        preFilter(value){
            if(value != ""){
                if(value != "no-value"){
                    this.table.searchData = value;
                }


                this.chunkArray()
            }else{
                this.table.searchData = "";

                this.chunkArray()
            }
           
        },
        preSearch(value){
           
            this.table.searchFilter = value;
            

            this.chunkArray()
        },
        perPageChange(value){
            this.table.perPage = value;
            this.chunkArray()
        },
        log(value){
            console.log(value)
        },
        changeStock(value){
            this.table.stock = value;
            this.chunkArray()
        },
        changeLimit(value){
            this.table.limit = value;
            this.chunkArray()
        },
        allStock(value){
            this.table.all = value;
            this.chunkArray()
        }
    }
}
</script>

<style scoped>
.icon{
    font-size: 13px;
}
</style>