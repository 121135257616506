<template>
    <f7-page name="Proveedores" >
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title >Proveedores</f7-nav-title>
            <f7-nav-right>
                <f7-link icon-material="refresh" tooltip="Recargar Lista" ></f7-link>
                <f7-link class="searchbar-enable" data-searchbar=".searchbar-demo" icon-material="search"></f7-link>
            </f7-nav-right>
            <f7-searchbar
                class="searchbar-demo"
                expandable
                remove-diacritics
                search-container=".search-list"
                search-in=".item-title"
                :disable-button="!$theme.aurora"
                placeholder="Buscar proveedor"
            ></f7-searchbar>
        </f7-navbar>

        <f7-list class="searchbar-not-found">
            <f7-list-item title="No hay coincidencias"></f7-list-item>
        </f7-list>

        <f7-list class="no-margin-top search-list searchbar-found" media-list>
          <f7-list-item
                v-for="(item, index) in $store.state.provider"
                :key="index" 
                :title="item.description"
                :subtitle="item.dnu"
                :text="item.details"
                link="#"
                @click="detailView(item.id)"
            ></f7-list-item>
        </f7-list>

        <f7-fab position="right-bottom" slot="fixed" color="teal">
            <f7-icon material="add"></f7-icon>
            <f7-icon ios="f7:close" aurora="f7:close" md="material:close"></f7-icon>
            <f7-fab-buttons position="top">
                <f7-fab-button @click="newView"  fab-close label="Crear nuevo proveedor">
                    <f7-icon material="playlist_add"></f7-icon>
                </f7-fab-button>
            </f7-fab-buttons>
        </f7-fab>
    </f7-page>
    
</template>

<script>

export default {
    components: {

    },
    methods: {
        newView(){
           this.$f7.views.main.router.navigate('/provider/new');
        },
        detailView(id){
           this.$f7.views.main.router.navigate('/provider/'+id);
        }
    },
    mounted() {

        this.$firebase.auth().currentUser.getIdToken().then((token) => {
            this.$store.dispatch("updateState",{ token , state : [
                {"path" : "universal/?model=Provider", "model" : "provider"},
            ]});
        });
    }

}
</script>

<style>

</style>