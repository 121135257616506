<template>
    <div v-if="!previewMode">
        <block-toolbar :block="computedBlockInfo('report-type-block')"></block-toolbar>

        <f7-block class="no-margin-vertical">
            <label><b>Seleccione el tipo de reporte</b></label>
            <f7-input type="select" @change="handleSelectReportType($event.target.value)" :disabled="disableInputs">
                <option value="">Seleccione...</option>
                <option
                    v-for="reportType in reportTypes"
                    :key="reportType.id"
                    :value="reportType.name"
                    :selected="reportType.name == selectedReportTypeData"
                >
                    {{ reportType.name }}
                </option>
            </f7-input>
        </f7-block>
    </div>
    <div v-else>
        <div v-if="translation"><i>Report type</i></div>
        <div>
            <b>Tipo de reporte</b>
            :
            {{ selectedReportTypeData ? selectedReportTypeData : "Sin tipo de reporte seleccionado" }}
            <span v-if="translation && selectedReportTypeData">
                / <i>{{ traducirReportType(selectedReportTypeData) }}</i>
            </span>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { reportTypes as reportTypesData } from "@/app/constants";
import BlockToolbarMixin from "@/app/components-migration/plantillas/blocks/toolbar/block-toolbar-mixin";
import BlockToolbar from "@/app/components-migration/plantillas/blocks/toolbar/block-toolbar.vue";

export default {
    mixins: [BlockToolbarMixin],

    props: {
        previewMode: Boolean,
        translation: Boolean,
        defaults: Object,
        disableInputs: Boolean,
    },

    components: {
        "block-toolbar": BlockToolbar,
    },

    data() {
        return {
            reportTypes: reportTypesData,
            selectedReportTypeData: "",
        };
    },

    watch: {
        defaults: {
            immediate: true,
            deep: true,
            handler() {
                this.setDefaultsToBlock();
            },
        },

        selectedReportType: {
            immediate: true,
            handler() {
                this.handleSelectReportType(this.selectedReportType);
            }
        },

        viewingPlantilla: {
            handler: function(plantilla) {
                if (plantilla) {
                    this.setDefaultsToBlock();

                    if (this.selectedReportType) {
                        this.handleSelectReportType(this.selectedReportType);
                    }
                }
            },
        },
    },

    computed: {
        ...mapState("plantillas", ["selectedReportType", "viewingPlantilla"])
    },

    methods: {
        setDefaultsToBlock() {
            if (this.defaults.reportType) {
                this.selectedReportTypeData = this.selectedReportType ? this.selectedReportType : this.defaults.reportType;
            }
        },

        handleSelectReportType(reportType) {
            this.selectedReportTypeData = reportType;
            this.$emit("report-type-selected", { reportType });
        },

        traducirReportType(reportType) {
            const traducciones = {
                "Informe preliminar": "Preliminary report",
                "Informe final": "Final report",
                "Informe complementario": "Complementary report",
            };

            return traducciones[reportType] || reportType;
        },
    },
};
</script>
