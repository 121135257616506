import HomePage from "../pages/home.vue";
import profile from "../pages/profile.vue";
import members from "../pages/members.vue";
import member from "../pages/member.vue";
import privilegios from "../pages/privilegios.vue";
import hold from "../pages/hold.vue";
import pdfviewer from "../pages/pdf.vue";
import scanner from "../pages/scanner";
import SampleInfo from "../pages/sample-info";
import SampleMovementSelector from "../pages/sample-movement-selector";
import ChangeSampleLocation from "../pages/change-sample-location";
import Export from "../pages/export";

import coladetrabajo from "../pages/coladetrabajo.vue";
import ordendetrabajo from "../pages/ordenTrabajo.vue";
import pendientesIngreso from "../pages/pendientesIngreso.vue";
import pendientesfirma from "../pages/pendientesfirma.vue";
import historicofirmados from "../pages/historicofirmados.vue";
import ExamsFilter from "../pages/exams-filter.vue";
import editPatientMeta from "../pages/editPatientMeta.vue";

import HomeAdmission from "../pages/admission/homeAdmission";
import NewOrderAdmission from "../pages/admission/newOrderAdmission";
import Admission from "../pages/admission/admission.vue";
import NewExamAdmission from "../pages/admission/new-exam-admission.vue";
import SampleAdmission from "../pages/admission/new-sample-admission.vue";
import ImportAdmissionsLegacy from "../pages/admission/import-admissions-legacy.vue";

import resultados from "../pages/resultados";
import resultado_paciente from "../pages/resultado_paciente";

import pcrcovidbatch from "../pages/pcrcovidbatch";

import paxs from "../pages/maintainers/paxs";
import onepax from "../pages/maintainers/onepax";

import pacientes from "../pages/maintainers/pacientes";
import neopaciente from "../pages/maintainers/neopaciente";
import PatientDetails from "../pages/maintainers/patientDetails";

import Institutions from "../pages/maintainers/institutions";
import NewInstitution from "../pages/maintainers/newInstitution";
import InstitutionDetails from "../pages/maintainers/institutionDetails";

import Samples from "../pages/maintainers/samples";
import NewSample from "../pages/maintainers/newSample";
import SampleDetails from "../pages/maintainers/sampleDetails";

import Agreements from "../pages/maintainers/agreements";
import NewAgreement from "../pages/maintainers/newAgreement";
import AgreementDetails from "../pages/maintainers/agreementDetails";

import RejectionReasons from "../pages/maintainers/rejections";
import NewRejectionReason from "../pages/maintainers/newRejection";
import RejectionDetails from "../pages/maintainers/rejectionDetails";

import Exams from "../pages/maintainers/exams";
import NewExam from "../pages/maintainers/newExam";
import ExamDetails from "../pages/maintainers/examDetails";

import ProcessingMethods from "../pages/maintainers/processing-methods";
import NewProcessingMethod from "../pages/maintainers/new-processing-method";
import ProcessingMethodDetails from "../pages/maintainers/processing-method-details";

import Units from "../pages/maintainers/units";
import NewUnit from "../pages/maintainers/new-unit";
import UnitDetails from "../pages/maintainers/unit-details";

import supply from "../pages/supply/supply";
import newSupply from "../pages/supply/modules/newSupply.vue";
import editSupply from "../pages/supply/modules/editSupply.vue";

import entry from "../pages/entry/entry.vue";
/**
 * Pages de admission previo a la migración
 */
import AdmissionIndexPage from "@/app/admission/index.vue";
import ListaDeTrabajoIndexPage from "@/app/lista-de-trabajo/index.vue";
import ResultadosIndexPage from "@/app/resultados/index.vue";

/**
 * Pages de admission después de migración
 */
import AdmissionListPage from "@/app/admission-migration/list.vue";
import AdmissionIndexMigration from "@/app/admission-migration/index.vue";
import ListaDeTrabajoIndexMigration from "@/app/lista-de-trabajo-migration/index.vue";
import CargaResultadosIndexMigration from "@/app/carga-resultados/index.vue";
import CargaResultadosFirma from "@/app/carga-resultados/firma.vue";
import ExportIndexPage from "@/app/export/index.vue";
import ImportAdmissions from "../app/import-covid/import-admissions.vue";

/**
 * Mantenedores migrados
 */
import MantenedorUnits from "@/app/mantenedores/units/list.vue";
import MantenedorUnitsDetails from "@/app/mantenedores/units/details.vue";
import MantenedorUnitsCreateOrEdit from "@/app/mantenedores/units/create-or-edit.vue";

import MantenedorRejectionReasons from "@/app/mantenedores/rejection-reasons/list.vue";
import MantenedorRejectionReasonsDetails from "@/app/mantenedores/rejection-reasons/details.vue";
import MantenedorRejectionReasonsCreateOrEdit from "@/app/mantenedores/rejection-reasons/create-or-edit.vue";

import MantenedorPendingReasons from "@/app/mantenedores/pending-reasons/list.vue";
import MantenedorPendingReasonsDetails from "@/app/mantenedores/pending-reasons/details.vue";
import MantenedorPendingReasonsCreateOrEdit from "@/app/mantenedores/pending-reasons/create-or-edit.vue";

import MantenedorAgreements from "@/app/mantenedores/agreements/list.vue";
import MantenedorAgreementsDetails from "@/app/mantenedores/agreements/details.vue";
import MantenedorAgreementsCreateOrEdit from "@/app/mantenedores/agreements/create-or-edit.vue";

import MantenedorInstitutions from "@/app/mantenedores/institutions/list.vue";
import MantenedorInstitutionsDetails from "@/app/mantenedores/institutions/details.vue";
import MantenedorInstitutionsCreateOrEdit from "@/app/mantenedores/institutions/create-or-edit.vue";
import MantenedorInstitucionesPrices from "@/app/mantenedores/institutions/prices.vue";

import MantenedorMembers from "@/app/mantenedores/members/list.vue";
import MantenedorMembersDetails from "@/app/mantenedores/members/details.vue";

import MantenedorPatients from "@/app/mantenedores/patients/list.vue";

import MantenedorExams from "@/app/mantenedores/exams/list.vue";
import MantenedorExamsDetails from "@/app/mantenedores/exams/details.vue";
import MantenedorExamsCreateOrEdit from "@/app/mantenedores/exams/create-or-edit.vue";

import MantenedorPlantillas from "@/app/mantenedores/plantillas/list.vue";
import MantenedorPlantillasCreateOrEdit from "@/app/mantenedores/plantillas/create-or-edit.vue";

import MantenedorPax from "@/app/mantenedores/pax/list.vue";
import MantenedorPaxDetails from "@/app/mantenedores/pax/details.vue";

//Mantenedor de proveedores

import MantenedorProviders from "../pages/providers/providersList.vue";
import newProvider from "../pages/providers/modules/newProvider.vue";
import editProvider from "../pages/providers/modules/editProvider.vue";

//Mantenedor de facturas
import MantenedorFacturas from "../pages/invoice/invoice.vue";

// Features COVID
import FirmaMasivaCOVID from "@/app/firma-masiva/index.vue";

import ResultPdfPreview from "@/app/components-migration/result-pdf-preview.vue";

// Inmunopax
import PaxSolicitudesAdmissions from "@/app/pax/pax-solicitudes.vue";

// Lab config
import LabConfigIndex from "@/app/lab-config/index.vue";

import zebra from "../pages/zebra.vue";

var routes = [
    {
        path: "/",
        component: HomePage,
    },
    {
        path: "/profile/",
        component: profile,
        options: {
            transition: "f7-cover-v",
        },
    },

    {
        path: "/pcrcovidbatch/",
        component: pcrcovidbatch,
    },
    {
        path: "/printer/",
        component: zebra,
    },
    {
        path: "/admit/",
        component: HomeAdmission,
    },
    {
        path: "/resultados/",
        component: resultados,
        master: true,
        detailRoutes: [
            {
                path: "/resultado_paciente/",
                component: resultado_paciente,
            },
        ],
    },
    {
        path: "/coladetrabajo/",
        component: coladetrabajo,
    },
    {
        path: "/editPatientMeta/:admissionId",
        component: editPatientMeta,
    },
    {
        path: "/pendientesIngreso/",
        component: pendientesIngreso,
    },
    {
        path: "/pendientesfirma/",
        component: pendientesfirma,
    },
    {
        path: "/historicofirmados/",
        component: historicofirmados,
    },
    {
        path: "/exams-filter/",
        component: ExamsFilter,
    },
    {
        path: "/ordendetrabajo/",
        component: ordendetrabajo,
    },
    {
        path: "/pdfviewer/",
        component: pdfviewer,
    },
    {
        path: "/scanner/",
        component: scanner,
    },
    {
        path: "/hold/",
        component: hold,
    },
    {
        path: "/sample-info/",
        component: SampleInfo,
    },
    {
        path: "/sample-move/",
        component: SampleMovementSelector,
    },
    {
        path: "/sample-location/",
        component: ChangeSampleLocation,
    },
    {
        path: "/entry/",
        component: entry,
    },
    //Rutas Mantenedores
    {
        path: "/members/",
        component: members,
        master: true,
        detailRoutes: [
            {
                path: "/member/",
                component: member,
            },
        ],
    },
    {
        path: "/pacientes/",
        component: pacientes,
        master: true,
        detailRoutes: [
            {
                path: "/neopaciente/",
                component: neopaciente,
            },
            {
                path: "/paciente/",
                component: PatientDetails,
            },
        ],
    },
    {
        path: "/supply/",
        component: supply,
        master: true,
        detailRoutes: [
            {
                path: "/supply/new",
                component: newSupply,
            },
            {
                path: "/supply/:id",
                component: editSupply,
            },
        ],
    },
    {
        path: "/providers/",
        component: MantenedorProviders,
        master: true,
        detailRoutes: [
            {
                path: "/provider/new",
                component: newProvider,
            },
            {
                path: "/provider/:id",
                component: editProvider,
            },
        ],
    },
    {
        path: "/paxs/",
        component: paxs,
        master: true,
        detailRoutes: [
            {
                path: "/onepax/",
                component: onepax,
            },
        ],
    },
    {
        path: "/privilegios/",
        component: privilegios,
    },
    {
        path: "/institutions/",
        component: Institutions,
        master: true,
        detailRoutes: [
            {
                path: "/new-institution/",
                component: NewInstitution,
            },
            {
                path: "/institution/",
                component: InstitutionDetails,
            },
        ],
    },
    {
        path: "/samples/",
        component: Samples,
        master: true,
        detailRoutes: [
            {
                path: "/new-sample/",
                component: NewSample,
            },
            {
                path: "/sample/",
                component: SampleDetails,
            },
        ],
    },
    {
        path: "/agreements/",
        component: Agreements,
        master: true,
        detailRoutes: [
            {
                path: "/new-agreement/",
                component: NewAgreement,
            },
            {
                path: "/agreement/",
                component: AgreementDetails,
            },
        ],
    },
    {
        path: "/rejections/",
        component: RejectionReasons,
        master: true,
        detailRoutes: [
            {
                path: "/new-rejection/",
                component: NewRejectionReason,
            },
            {
                path: "/rejection-details/",
                component: RejectionDetails,
            },
        ],
    },
    {
        path: "/exams/",
        component: Exams,
    },
    {
        path: "/new-exam/",
        component: NewExam,
    },
    {
        path: "/invoice/",
        component: MantenedorFacturas,
    },
    {
        path: "/exam/:examId",
        component: ExamDetails,
    },
    {
        path: "/exam/",
        component: ExamDetails,
    },
    {
        path: "/methods/",
        component: ProcessingMethods,
        master: true,
        detailRoutes: [
            {
                path: "/new-method/",
                component: NewProcessingMethod,
            },
            {
                path: "/method-details/",
                component: ProcessingMethodDetails,
            },
        ],
    },
    {
        path: "/units/",
        component: Units,
        master: true,
        detailRoutes: [
            {
                path: "/new-unit/",
                component: NewUnit,
            },
            {
                path: "/unit-details/",
                component: UnitDetails,
            },
        ],
    },
    {
        path: "/export/",
        component: Export,
    },
    {
        path: "/admission/",
        component: Admission,
    },
    {
        path: "/exam-admission/:examId",
        component: NewExamAdmission,
    },
    {
        path: "/exam-admission/",
        component: NewExamAdmission,
    },
    {
        path: "/sample-admission/:sampleId/:changeId",
        component: SampleAdmission,
    },
    {
        path: "/sample-admission/:sampleId",
        component: SampleAdmission,
    },
    {
        path: "/sample-admission/",
        component: SampleAdmission,
    },
    {
        path: "/add-order/",
        component: NewOrderAdmission,
    },

    /**
     * Nuevas rutas. Previo a migración.
     */
    {
        path: "/admissions/index",
        component: AdmissionIndexPage,
    },
    {
        path: "/lista-de-trabajo/index",
        component: ListaDeTrabajoIndexPage,
    },
    {
        path: "/resultados/index",
        component: ResultadosIndexPage,
    },
    {
        path: "/import-admissions-legacy/",
        component: ImportAdmissionsLegacy,
    },

    /**
     * Nuevas rutas. Posterior a migración.
     */
    {
        path: "/migration/mantenedores/units",
        component: MantenedorUnits,
        master: true,
        detailRoutes: [
            {
                path: "/migration/mantenedores/units/details",
                component: MantenedorUnitsDetails,
            },
            {
                path: "/migration/mantenedores/units/create-or-edit",
                component: MantenedorUnitsCreateOrEdit,
            },
        ],
    },
    {
        path: "/migration/mantenedores/rejection-reasons",
        component: MantenedorRejectionReasons,
        master: true,
        detailRoutes: [
            {
                path: "/migration/mantenedores/rejection-reasons/details",
                component: MantenedorRejectionReasonsDetails,
            },
            {
                path: "/migration/mantenedores/rejection-reasons/create-or-edit",
                component: MantenedorRejectionReasonsCreateOrEdit,
            },
        ],
    },
    {
        path: "/migration/mantenedores/pending-reasons",
        component: MantenedorPendingReasons,
        master: true,
        detailRoutes: [
            {
                path: "/migration/mantenedores/pending-reasons/details",
                component: MantenedorPendingReasonsDetails,
            },
            {
                path: "/migration/mantenedores/pending-reasons/create-or-edit",
                component: MantenedorPendingReasonsCreateOrEdit,
            },
        ],
    },
    {
        path: "/migration/mantenedores/agreements",
        component: MantenedorAgreements,
        master: true,
        detailRoutes: [
            {
                path: "/migration/mantenedores/agreements/details",
                component: MantenedorAgreementsDetails,
            },
            {
                path: "/migration/mantenedores/agreements/create-or-edit",
                component: MantenedorAgreementsCreateOrEdit,
            },
        ],
    },
    {
        path: "/migration/mantenedores/institutions",
        component: MantenedorInstitutions,
        master: true,
        detailRoutes: [
            {
                path: "/migration/mantenedores/institutions/details",
                component: MantenedorInstitutionsDetails,
            },
            {
                path: "/migration/mantenedores/institutions/create-or-edit",
                component: MantenedorInstitutionsCreateOrEdit,
            },
            {
                path: "/migration/mantenedores/institutions/prices",
                component: MantenedorInstitucionesPrices,
            },
        ],
    },
    {
        path: "/migration/mantenedores/members",
        component: MantenedorMembers,
        master: true,
        detailRoutes: [
            {
                path: "/migration/mantenedores/members/details",
                component: MantenedorMembersDetails,
            },
        ],
    },
    {
        path: "/migration/mantenedores/patients",
        component: MantenedorPatients,
    },

    {
        path: "/migration/mantenedores/exams",
        component: MantenedorExams,
    },
    {
        path: "/migration/mantenedores/exams/details",
        component: MantenedorExamsDetails,
    },
    {
        path: "/migration/mantenedores/exams/create-or-edit",
        component: MantenedorExamsCreateOrEdit,
    },

    {
        path: "/migration/mantenedores/plantillas",
        component: MantenedorPlantillas,
    },
    {
        path: "/migration/mantenedores/plantillas/create-or-edit",
        component: MantenedorPlantillasCreateOrEdit,
    },
    {
        path: "/migration/mantenedores/pax",
        component: MantenedorPax,
        master: true,
        detailRoutes: [
            {
                path: "/migration/mantenedores/pax/details",
                component: MantenedorPaxDetails,
            },
        ],
    },

    {
        path: "/migration/admissions/index",
        component: AdmissionIndexMigration,
    },
    {
        path: "/migration/admissions/list",
        component: AdmissionListPage,
    },
    {
        path: "/migration/export",
        component: ExportIndexPage,
    },
    {
        path: "/migration/lista-de-trabajo",
        component: ListaDeTrabajoIndexMigration,
    },
    {
        path: "/migration/carga-resultados",
        component: CargaResultadosIndexMigration,
    },
    {
        path: "/migration/carga-resultados/firma",
        component: CargaResultadosFirma,
    },
    {
        path: "/migration/firma-masiva",
        component: FirmaMasivaCOVID,
    },
    {
        path: "/result-pdf-preview",
        component: ResultPdfPreview
    },
    {
        path: "/import-admissions/",
        component: ImportAdmissions,
    },

    {
        path: "/pax/solicitudes/admissions",
        component: PaxSolicitudesAdmissions,
    },

    {
        path: "/lab-config",
        component: LabConfigIndex,
    },
];

export default routes;
