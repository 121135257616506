<template>
    <div class="fixed-in-corner margin-right-half">
        <f7-fab
            v-if="plantilla.options.currentScale !== 1"
            color="blue"
            style="margin-bottom: 210px"
            @click="$emit('zoom-changed', { zoom: 'restore' })"
        >
            <f7-icon material="restore_page"></f7-icon>
        </f7-fab>
        <f7-fab color="blue" style="margin-bottom: 140px" @click="$emit('zoom-changed', { zoom: 'in' })">
            <f7-icon material="zoom_in"></f7-icon>
        </f7-fab>
        <f7-fab color="blue" style="margin-bottom: 70px" @click="$emit('zoom-changed', { zoom: 'out' })">
            <f7-icon material="zoom_out"></f7-icon>
        </f7-fab>
        <f7-fab color="primary" @click="$emit('page-added')">
            <f7-icon material="add"></f7-icon>
        </f7-fab>
    </div>
</template>

<script>
export default {
    props: {
        plantilla: Object,
    },
};
</script>

<style scoped>
.fixed-in-corner {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 99999;
}
</style>
