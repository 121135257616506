<template>
    <f7-page name="admission-index">
        <f7-navbar title="Lista de Trabajo">
            <f7-nav-right>
                <f7-button outline class="margin-right" color="blue" @click="clear">
                    Limpiar
                </f7-button>
            </f7-nav-right>
        </f7-navbar>

        <f7-block class="no-margin no-padding">
            <f7-card outline>
                <f7-card-content class="no-padding-top">
                    <ot-search @data-found="handleOTfound" :reset="clearView">
                        <h4>Búsqueda por OT</h4>
                    </ot-search>
                </f7-card-content>
            </f7-card>

            <!-- busqueda de examenes -->
            <f7-card outline>
                <f7-card-content class="no-padding-top">
                    <exams-search @exams-found="handleExamsFound">
                        <h4>Búsqueda de exámenes</h4>
                    </exams-search>
                </f7-card-content>
            </f7-card>

            <!-- Paciente asociado a la admision -->
            <div v-if="Object.keys(otAdmission).length" class="margin-horizontal padding-top">
                <h4>Paciente asociado a la admisión</h4>
                <f7-row class="margin-bottom">
                    <f7-col>
                        <label>Tipo de documento</label>
                        <f7-input type="select" :value="viewingPatient.tipoIdentificador" disabled>
                            <option value="">Seleccione...</option>
                            <option value="RUT">RUN</option>
                            <option value="PASAPORTE">Pasaporte</option>
                            <option value="DNI">DNI</option>
                        </f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Número de documento</label>
                        <f7-input type="text" :value="viewingPatient.identificador" disabled></f7-input>
                    </f7-col>
                </f7-row>

                <f7-row class="margin-bottom">
                    <f7-col>
                        <label>Apellido paterno</label>
                        <f7-input
                            class="input-uppercase"
                            type="text"
                            :value="viewingPatient.primerApellido"
                            disabled
                        ></f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Apellido materno</label>
                        <f7-input
                            class="input-uppercase"
                            type="text"
                            :value="viewingPatient.segundoApellido"
                            disabled
                        ></f7-input>
                    </f7-col>
                    <f7-col>
                        <label>Nombres</label>
                        <f7-input
                            class="input-uppercase"
                            type="text"
                            :value="viewingPatient.nombres"
                            disabled
                        ></f7-input>
                    </f7-col>
                </f7-row>
            </div>

            <div v-if="Object.keys(foundExams).length" class="margin-horizontal padding-bottom">
                <div class="display-flex justify-content-space-between align-items-center">
                    <h4>{{ examsTitle }}</h4>
                    <f7-button fill small @click="moveToLab">
                        Pasar a laboratorio
                    </f7-button>
                </div>

                <exams-table
                    selectable
                    :selected-exams="selectedExams"
                    :exams="foundExams"
                    @exam-selection-changed="handleExamSelectionChanged"
                    @exam-status-changed="handleExamStatusChanged"
                    :buttons="['patient', 'flujograma']"
                ></exams-table>
            </div>
        </f7-block>
    </f7-page>
</template>

<script>
import { mapState } from "vuex";
import OTSearch from "@/app/components/ot-search.vue";
import ExamsSearch from "@/app/components/exams-search.vue";
import ExamsTable from "@/app/components/exams-table.vue";

import { _sources } from "@/app/constants";

export default {
    components: {
        "ot-search": OTSearch,
        "exams-search": ExamsSearch,
        "exams-table": ExamsTable,
    },

    data() {
        return {
            sources: _sources,
            source: null,

            clearView: false,

            foundExams: {},
            otAdmission: {}, // if source is ot-search

            selectedExams: {},

            viewingOt: "",

            showReasonsPopup: false,

            currentExamId: "",

            examsPreviousState: {},

            viewingPatient: {},
        };
    },

    computed: {
        ...mapState(["profile_userInfo", "misprivx", "profile_userInfo"]),

        examsTitle() {
            if (this.source === _sources.OT_SEARCH) {
                return "Exámenes de la OT";
            } else {
                return "Exámenes";
            }
        },
    },

    methods: {
        handleExamStatusChanged(data) {
            const { examId, statusLog } = data;

            const exam = Object.values(this.foundExams).find(ex => ex.examId === examId);

            this.addLog(exam, statusLog.status, statusLog.reason);
        },

        handleExamSelectionChanged(data) {
            const { selected, exam } = data;

            if (selected) {
                this.$set(this.selectedExams, exam.admExamId, true);
            } else {
                this.$delete(this.selectedExams, exam.admExamId);
            }
        },

        // todo: refactor, move to exams-table
        addLog(exam, status, reason = null) {
            this.$f7.dialog.preloader("Cambiando estado del examen...");

            const statusLogKey = this.$firebase
                .database()
                .ref()
                .push().key;

            const statusLogObj = {
                who: this.profile_userInfo.displayName,
                when: (Date.now() / 1000) | 0,
                status: status,
                reason,
            };

            const updates = {};

            updates[`/admExams/${exam.admExamId}/currentStatus`] = status;
            updates[`/admExams/${exam.admExamId}/statusLog/${statusLogKey}`] = statusLogObj;

            this.$firebase
                .database()
                .ref(`/admExams/${exam.admExamId}`)
                .once("value")
                .then((snapshot) => {
                    console.log("adm exam", snapshot.val());
                    const admExam = snapshot.val() || {};

                    updates[`/ots/${admExam.ot}/exams/${exam.id}/currentStatus`] = status;
                    updates[`/ots/${admExam.ot}/exams/${exam.id}/statusLog/${statusLogKey}`] = statusLogObj;

                    updates[`/admissions/${admExam.admissionId}/exams/${admExam.id}/currentStatus`] = status;
                    updates[
                        `/admissions/${admExam.admissionId}/exams/${admExam.id}/statusLog/${statusLogKey}`
                    ] = statusLogObj;

                    console.log("updates", updates);

                    this.$firebase
                        .database()
                        .ref()
                        .update(updates)
                        .then(() => {
                            this.$set(this.foundExams[exam.admExamId], "currentStatus", status);
                            this.$set(this.foundExams[exam.admExamId].statusLog, statusLogKey, statusLogObj);

                            this.$f7.dialog.close();
                        });
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Hubo un error al cargar el examen. " + err);
                });
        },

        handleOTfound(data) {
            const { admission, ot, admExams } = data;

            this.source = _sources.OT_SEARCH;

            this.viewingOt = ot.code;

            // todo: filter admission.exams by ot
            this.otAdmission = admission;
            this.viewingPatient = admission.patient;

            const exams = {};


            // guardar el estado anterior de cada examen. Para al cambiar estado poder volver al anterior
            Object.values(admission.exams || {}).forEach((exam) => {
                exams[exam.admExamId] = exam;

                this.$set(this.examsPreviousState, exam.admExamId, exam.currentStatus);
            });

            this.foundExams = admExams;
        },

        handleExamsFound(exams) {
            console.log("handle exams found", exams);

            this.foundExams = exams;

            this.viewingPatient = {};
            this.otAdmission = {};

            this.source = _sources.EXAMS_SEARCH;

            Object.values(this.foundExams).forEach((exam) => {
                this.$set(this.examsPreviousState, exam.admExamId, exam.currentStatus);
            });
        },

        moveToLab() {
            if (Object.keys(this.selectedExams).length === 0) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Debe seleccionar por lo menos un examen");
                return;
            }

            this.$f7.dialog.confirm("¿Está seguro de pasar los examenes seleccionados a laboratorio?", () => {
                this.$f7.dialog.preloader("Pasando examenes a laboratorio...");

                const promises = [];

                Object.keys(this.selectedExams).forEach((admExamId) => {
                    promises.push(
                        this.$firebase
                            .database()
                            .ref(`/admExams/${admExamId}`)
                            .once("value")
                    );
                });

                Promise.all(promises)
                    .then((snapshots) => {
                        const updates = {};

                        const statusLogKey = this.$firebase
                            .database()
                            .ref()
                            .push().key;

                        const status = "en_lab";

                        const statusLogObj = {
                            who: this.profile_userInfo.displayName,
                            when: (Date.now() / 1000) | 0,
                            status,
                        };

                        snapshots.forEach((snapshot) => {
                            const admExam = snapshot.val() || {};

                            // update /admissions
                            updates[
                                `/admissions/${admExam.admissionId}/exams/${admExam.examId}/currentStatus`
                            ] = status;
                            updates[
                                `/admissions/${admExam.admissionId}/exams/${
                                    admExam.examId
                                }/statusLog/${statusLogKey}`
                            ] = statusLogObj;

                            // update /ots
                            updates[`/ots/${admExam.ot}/exams/${admExam.examId}/currentStatus`] = status;
                            updates[
                                `/ots/${admExam.ot}/exams/${admExam.examId}/statusLog/${statusLogKey}`
                            ] = statusLogObj;

                            // update /admExams
                            updates[`/admExams/${admExam.admExamId}/currentStatus`] = status;
                            updates[
                                `/admExams/${admExam.admExamId}/statusLog/${statusLogKey}`
                            ] = statusLogObj;
                        });

                        this.$firebase
                            .database()
                            .ref()
                            .update(updates)
                            .then(() => {
                                console.log("cambiados a laboratorio");

                                Object.keys(this.selectedExams).forEach((admExamId) => {
                                    this.foundExams[admExamId] = {
                                        ...this.foundExams[admExamId],
                                        currentStatus: status,
                                        statusLog: {
                                            ...this.foundExams[admExamId].statusLog,
                                            [statusLogKey]: statusLogObj,
                                        },
                                    };
                                });

                                this.selectedExams = {};

                                this.$f7.dialog.close();
                            });
                    })
                    .catch((err) => {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert("Error al cargar la data de los exámenes. " + err);
                    });
            });
        },

        clear() {
            this.$f7.dialog.confirm("¿Está seguro?", () => {
                this.source = null;
                this.clearView = !this.clearView;

                this.search = {
                    seccion: "",
                    estado: "",
                };

                this.foundExams = {};
                this.otAdmission = {};

                this.selectedExams = {};

                this.viewingOt = "";

                this.currentExamId = "";
                this.examsPreviousState = {};

                this.viewingPatient = {};
            });
        },
    },
};
</script>
