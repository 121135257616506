<template>
    <div class="table-responsive">
        <table class="table table-sm table-bordered table-hover" v-if="patients.length > 0">
            <thead class="thead-default">
                <slot name="table-header"></slot>
            </thead>
            <tbody>
                <tr v-for="patient in patients" :key="patient.id">
                    <slot name="table-row" :patient="patient" :f="getFunctionsForScopedSlot()"></slot>
                </tr>
            </tbody>
        </table>

        <div class="display-flex justify-content-center" v-if="patients.length > 0">
            <f7-button fill large raised class="padding-horizontal" @click="$emit('load-more')" v-if="pagination.page < pagination.totalPages">
                Cargar más pacientes
            </f7-button>
            <div v-else class="text-color-gray">Se han cargado todos los pacientes encontrados</div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        patients: Array,
        pagination: Object,
    },

    computed: {
        ...mapGetters(["getPrevision"]),
    },

    methods: {
        patientDocumentInfo(patient) {
            if (patient.document_type_info) {
                return `${patient.document_type_info.name} ${patient.document_number}`;
            }

            return "Sin documentos del paciente";
        },
        patientFullName(patient) {
            if (patient) {
                return `${patient.name || ""} ${patient.lastname_f || ""} ${patient.lastname_m || ""}`;
            } else {
                return "Sin paciente";
            }
        },
        patientPrevision(patient) {
            if (patient) {
                if (!patient.prevision) return "Sin previsión";

                return this.getPrevision(patient.prevision).name;
            } else {
                return "Sin paciente";
            }
        },

        getFunctionsForScopedSlot() {
            const { patientDocumentInfo, patientFullName, patientPrevision } = this;

            return {
                patientDocumentInfo,
                patientFullName,
                patientPrevision,
            };
        },
    },
};
</script>
