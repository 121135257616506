<template>
    <f7-page name="newRejectionReason">
        <f7-navbar v-if="!isEditor" title="Agregar nuevo motivo de rechazo" back-link></f7-navbar>
        <f7-navbar v-else title="Editar motivo de rechazo" back-link></f7-navbar>
    
        <f7-list no-hairlines-md>
            <f7-list-input
                label="Motivo de rechazo"
                type="text"
                name="rejection-name"
                placeholder="Motivo de rechazo"
                :value="rejectionReason.name"
                @input="rejectionReason.name = $event.target.value"
                required validate
                error-message="Debe indicar el nombre del motivo de rechazo"
            ></f7-list-input>
            <f7-list-input
                label="Descripción"
                type="textarea"
                name="rejection-description"
                placeholder="Descripción (Opcional)"
                :value="rejectionReason.description"
                @input="rejectionReason.description = $event.target.value"
            ></f7-list-input>
        </f7-list>

        <f7-block>
            <f7-row>
                <f7-col width="10" medium="33">
                </f7-col>
                <f7-col width="0" medium="33">
                    <f7-button
                        :icon-material="!isEditor ? 'playlist_add' : 'edit'" 
                        fill
                        raised
                        round
                        :text="!isEditor ? 'Agregar motivo de rechazo' : 'Guardar cambios'"
                        @click="!isEditor ? addRejectionReason() : editRejectionReason()" 
                    />
                </f7-col>
                <f7-col width="10" medium="33">
                </f7-col>
            </f7-row>
        </f7-block>
    </f7-page>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data(){
        return {
            rejectionReason: {
                name: '',
                description: '',
                id: '',
                enabled: true
            },
            isEditor: false
        }
    },
    computed: {
        ...mapState(['selectedReason', 'profile_userInfo']),
    },
    methods: {
        setAddOrEdit(){
            //Si hay un motivo de rechazo seleccionado se activa el modo editor
            if(this.selectedReason.name){
                this.isEditor = true;
                this.rejectionReason = { ...this.selectedReason};
            }
        },

        redirectRejectionDetails(){
            this.$store.commit('setWhatTo',{what: 'selectedReason', to: this.rejectionReason});
            this.$f7.views.main.router.navigate('/rejection-details/');
        },

        addRejectionReason(){
            this.$f7.dialog.preloader('Guardando');
            const id = this.$firebase.database().ref('rejections/').push().key;
            this.rejectionReason.id = id;
            this.$firebase.database().ref('rejections/' + id).set(this.rejectionReason)
                .then(()=>{
                    this.$store.commit('updateObjectAttribute', {
                        obj: 'allReasons',
                        attr: this.rejectionReason.id,
                        value: this.rejectionReason
                    })

                    //Se agrega información al nodo log
                    let info = {
                        who: this.profile_userInfo.uid,
                        wht: 'add-rejection',
                        whn: this.$moment().unix(),    
                        dls: 'Crea nuevo motivo de rechazo',
                        pth: 'rejections/' + this.rejectionReason.id,
                    }
                    let id = this.$firebase.database().ref('/log').push().key;
                    this.$firebase.database().ref('log/' + id).set(info)
                        .catch((err)=>{console.log(err.message)})

                    this.redirectRejectionDetails();
                    this.$f7.dialog.close();
                    this.$f7.toast.create({text: 'Los datos se han guardado con éxito', closeTimeout: 2500}).open();
                })
                .catch((err)=>{
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, err.code);
                })
        },
        editRejectionReason(){
            this.$f7.dialog.preloader('Editando');
            this.$firebase.database().ref('rejections/' + this.rejectionReason.id).update(this.rejectionReason)
            .then(()=>{
                this.$store.commit('updateObjectAttribute', {
                    obj: 'allReasons',
                    attr: this.rejectionReason.id,
                    value: this.rejectionReason
                })
                this.redirectRejectionDetails();

                //Se agrega información al nodo log
                let info = {
                    who: this.profile_userInfo.uid,
                    wht: 'edit-rejection',
                    whn: this.$moment().unix(),    
                    dls: 'Edita motivo de rechazo',
                    pth: 'rejections/' + this.rejectionReason.id,
                }
                let id = this.$firebase.database().ref('/log').push().key;
                this.$firebase.database().ref('log/' + id).set(info)
                    .catch((err)=>{console.log(err.message)})

                this.$f7.dialog.close();
                this.$f7.toast.create({text: 'Los cambios se han guardado con éxito', closeTimeout: 2500}).open();
            })
            .catch((err)=>{
                this.$f7.dialog.close();
                this.$f7.dialog.alert(err.message, err.code);
            })
        }
    },
    mounted(){
        this.setAddOrEdit();
    }
}
</script>