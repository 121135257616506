import { mapGetters, mapState } from "vuex";
import { examStatuses } from "@/app/constants";
import blocksJson from "@/app/data/blocks.json";
import BlockDataUpdateMixin from "@/app/components-migration/plantillas/main-plantilla/block-data-update-mixin";
import FirmarHandlersMixin from "@/app/components-migration/plantillas/main-plantilla/firmar-handlers-mixin";
import PageHandlersMixin from "@/app/components-migration/plantillas/main-plantilla/page-handlers-mixin";

export default {
    mixins: [BlockDataUpdateMixin, FirmarHandlersMixin, PageHandlersMixin],

    computed: {
        ...mapState(["profile_userInfo"]),
        ...mapGetters(["getExam"]),
        ...mapGetters("plantillas", ["currentStatusIdByReportType"]),
    },

    data() {
        return {
            isTextInputFocused: false,

            sidePanelOpened: false,

            plantilla: {
                name: "",
                description: "",
                blocks: {},
                pages: [{ position: { top: 0, bottom: 1169.3 } }],
                options: {
                    translations: false,
                    previewMode: false,
                    currentScale: 1,
                },
            },

            blockInitialPosition: { x: 200, y: 100 },

            pageToDelete: -1,

            blocks: blocksJson,
        };
    },

    methods: {
        async handleMainPlantillaSaveInEditor() {
            this.$set(this.plantilla, "createdAt", this.$timestamp());
            this.$set(this.plantilla, "createdBy", this.profile_userInfo.displayName);

            const errors = this.plantillaIsValid();

            if (errors.length > 0) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(`<ul>${errors.map((e) => `<li>${e}</li>`)}</ul>`);
                return;
            }

            let preloaderText = "Guardando los cambios...";

            if (!this.plantilla.id) {
                const plantillaKey = this.$firebase
                    .database()
                    .ref("/results_plantillas")
                    .push().key;
                this.plantilla.id = plantillaKey;

                preloaderText = "Creando una nueva plantilla...";
            }

            this.$f7.dialog.preloader(preloaderText);

            try {
                await this.$firebase
                    .database()
                    .ref(`/results_plantillas/${this.plantilla.id}`)
                    .set(this.plantilla);

                this.$store.commit("plantillas/update_plantilla", this.plantilla);

                this.isEditing = true;

                this.$f7.dialog.close();
            } catch (error) {
                console.error(error);
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Error al crear plantilla", error);
            }
        },

        async handleMainPlantillaControlButtonClicked(payload) {
            const { buttonClicked } = payload;

            if (buttonClicked === "view-options") {
                this.sidePanelOpened = true;
            }
            if (buttonClicked === "preview-mode") {
                if (!this.plantilla.options.hasOwnProperty("previewMode")) {
                    this.$set(this.plantilla.options, "previewMode", false);
                }

                this.plantilla.options.previewMode = !this.plantilla.options.previewMode;
            }
            if (buttonClicked === "translations") {
                this.plantilla.options.translations = !this.plantilla.options.translations;
            }
            if (buttonClicked === "save") {
                await this.handleMainPlantillaSaveInEditor();
            }
        },

        handleMainPlantillaDuplicatedInEditor() {
            this.$f7.dialog.prompt(
                "Nombre de la plantilla copia",
                "Duplicar plantilla",
                async (plantillaName) => {
                    let plantillaCopy = JSON.parse(JSON.stringify(this.plantilla)); // JSON.parse ?

                    const plantillaKey = this.$firebase
                        .database()
                        .ref()
                        .push().key;

                    plantillaCopy.id = plantillaKey;
                    plantillaCopy.name = plantillaName;
                    plantillaCopy.createdAt = this.$timestamp();
                    plantillaCopy.createdBy = this.profile_userInfo.displayName;
                    plantillaCopy.isUsed = null;

                    try {
                        await this.$firebase
                            .database()
                            .ref(`/results_plantillas/${plantillaCopy.id}`)
                            .set(plantillaCopy);

                        this.$store.commit("plantillas/add_plantilla", plantillaCopy);

                        this.$set(this, "plantilla", plantillaCopy);

                        this.$f7.dialog.close();
                    } catch (error) {
                        console.error(error);
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert("Error al crear plantilla", error);
                    }
                },
                () => {},
                `${this.plantilla.name} (copy)`
            );
        },

        handleMainPlantillaZoomChanged(payload) {
            const { zoom } = payload;

            if (!this.plantilla.options.hasOwnProperty("currentScale")) {
                this.$set(this.plantilla.options, "currentScale", 1);
            }

            if (zoom === "in") {
                this.plantilla.options.currentScale += 0.1;
            } else if (zoom === "out") {
                this.plantilla.options.currentScale -= 0.1;
            } else if (zoom === "restore") {
                this.plantilla.options.currentScale = 1;
            }
        },

        handleMainPlantillaPageAdded() {
            if (this.viewingAdmissionExam && this.currentStatusIdByReportType >= examStatuses.examen_impreso) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("No puedes añadir páginas si el examen ya fue impreso.");
                return;
            }

            const previousPage = this.plantilla.pages[this.plantilla.pages.length - 1];

            this.plantilla.pages.push({
                position: {
                    top: previousPage.position.bottom + 10,
                    bottom: previousPage.position.bottom + 1043,
                },
            });

            this.$f7.toast.show({
                text: `Página ${this.plantilla.pages.length} ha sido creada.`,
                closeTimeout: 2000,
            });
        },

        handleMainPlantillaInfoChanged(payload) {
            const { input, value } = payload;

            this.$set(this.plantilla, input, value);
        },

        handleMainPlantillaAssociatedExamChanged(payload) {
            const { actionType, examId } = payload;

            if (actionType === "add") {
                this.$set(this.plantilla.exams, examId, true);

                const exam = this.getExam(examId);
                this.plantilla.section = exam.section.id;
            } else if (actionType === "remove") {
                this.$delete(this.plantilla.exams, examId);
                this.$delete(this.plantilla, "section");
            }
        },

        handleMainPlantillaBlockAdded(payload) {
            const { blockCode } = payload;

            const block = this.blocks.find((b) => b.code === blockCode);

            if (!block) return;

            const blockKey = this.$firebase
                .database()
                .ref()
                .push().key;

            if (!this.plantilla.blocks) this.$set(this.plantilla, "blocks", {});

            const firstPageTopPosition = this.plantilla.pages[0].position.top;

            this.$set(this.plantilla.blocks, blockKey, {
                id: blockKey,
                block: { code: block.code },
                position: {
                    x: this.blockInitialPosition.x,
                    y: this.blockInitialPosition.y + firstPageTopPosition,
                },
                size: block.size, // by default the size of the block
                defaults: {},
            });

            if (blockCode === "table-block") {
                let dimensions = {
                    cols: 2,
                    rows: 2,
                };

                this.$set(this.plantilla.blocks[blockKey].defaults, "dimensions", dimensions);
            }

            this.$f7.toast.show({
                text: `Se ha añadido <b>${block.displayName}</b>`,
                closeTimeout: 2000,
            });
        },

        handleMainPlantillaBlockDragged(payload) {
            const { x, y, addedBlock, page } = payload;

            const updatedBlock = this.plantilla.blocks[addedBlock.id];

            updatedBlock.position = { x, y: y + page.position.top };

            this.$set(this.plantilla.blocks, updatedBlock.id, updatedBlock);
        },

        handleMainPlantillaBlockResized(payload) {
            const { x, y, width, height, addedBlock } = payload;

            const updatedBlock = this.plantilla.blocks[addedBlock.id];

            updatedBlock.size = { width, height };

            this.$set(this.plantilla.blocks, updatedBlock.id, updatedBlock);
        },

        handleMainPlantillaBlockDeleted(payload, isEditor) {
            const { blockId } = payload;

            const blockToDelete = this.plantilla.blocks[blockId];

            const basicBlocks = [
                "logo-block",
                "patient-info-block",
                "exam-info-block",
                "signature-block",
                "cmio-signatures-block",
                "responsable-info-block",
                "class-interpretation-block",
                "director-tecnico-signature-block",
            ];

            const isBasicBlock = basicBlocks.includes(blockToDelete.block.code);

            if (!isEditor) {
                if (!isBasicBlock && this.viewingAdmissionExam && this.currentStatusIdByReportType >= examStatuses.examen_confirmado) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("No puedes eliminar bloques de datos si el examen ya fue confirmado");
                    return;
                }
            }

            const foundBlock = this.plantilla.blocks[blockId];

            this.$delete(this.plantilla.blocks, foundBlock.id);
        },

        handleBlockDataChangedInEditor(payload) {
            switch (payload.blockCode) {
                case "text-block":
                    this.updateTextBlockDefaults(payload);
                    return;
                case "resultado-cualitativo-block":
                    this.updateResultadoCualitativoDefaults(payload);
                    return;
                case "resultado-cuantitativo-block":
                    this.updateResultadoCuantitativoDefaults(payload);
                    return;
                case "resultado-free-text-block":
                    this.updateResultadoFreeTextDefaults(payload);
                    return;
                case "resultado-collection-block":
                    this.updateResultadoCollectionDefaults(payload);
                    return;
                case "table-block":
                    this.updateTableBlockDefaults(payload);
                    return;
                case "processing-method-block":
                    this.updateProcessingMethodDefaults(payload);
                    return;
                case "image-block":
                    this.updateImageBlockDefaults(payload);
                    return;
                case "report-type-block":
                    this.updateReportTypeBlockDefaults(payload);
                    return;
                case "observations-block":
                    this.updateObservationsDefaults(payload);
            }
        },

        plantillaIsValid() {
            const errors = [];

            if (this.plantilla.name.trim().length === 0) {
                errors.push("Debe ingresar el nombre de la plantilla");
            }

            if (Object.keys(this.plantilla.blocks || {}).length === 0) {
                errors.push("La plantilla debe tener por lo menos un bloque");
            }

            if (Object.keys(this.plantilla.exams || {}).length === 0) {
                errors.push("La plantilla debe tener un exámen asociado");
            }

            const reportTypeBlock = Object.values(this.plantilla.blocks || {}).find((b) => b.block.code === "report-type-block");
            if (!reportTypeBlock) {
                errors.push("La plantilla debe tener el bloque Tipo de Reporte");
            }

            const requireExamSelectedBlocks = [
                "resultado-cualitativo-block",
                "resultado-cuantitativo-block",
                "resultado-free-text-block",
                "processing-method-block",
            ];

            Object.values(this.plantilla.blocks || {}).forEach((block) => {
                if (requireExamSelectedBlocks.includes(block.block.code)) {
                    if (block.defaults && block.defaults.examId) {
                        console.log("bloque validado");
                    } else {
                        const blockName = this.blocks.find((b) => b.code === block.block.code).displayName;
                        errors.push(`El bloque ${blockName} debe tener un examen asociado`);
                    }
                }
            });

            const imageBlocks = Object.values(this.plantilla.blocks || {}).filter((b) => b.block.code === "image-block");

            imageBlocks.forEach((imageBlock) => {
                if (imageBlock.defaults.hasOwnProperty("imageUploadFinished") && imageBlock.defaults.imageUploadFinished === false) {
                    errors.push("El bloque Imagen debe tener una imagen asociada");
                }
            });

            return errors;
        },
    },
};
