<template>
    <f7-page name="paxs" @page:beforein="getPaxUsers(false)">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title subtitle="pax.inmunocel.com">Usuarios de PAX</f7-nav-title>
            <f7-nav-right>
                <f7-link @click="getPaxUsers(true)" icon-material="refresh"></f7-link>
            </f7-nav-right>
        </f7-navbar>
        <f7-progressbar v-if="cargandoDataSearch" infinite></f7-progressbar>
        <f7-list class="no-margin-top paxlistx" media-list>
            <f7-list-item
                v-for="(unpax, idx) in allPaxsUsersHolder"
                :key="'pax' + idx"
                :title="unpax.institution ? unpax.institution.name : 'Institución no identificada'"
                :subtitle="unpax.email"
                link="#"
                @click="navegaAPax(unpax)"
            >
                <img :src="unpax.photoURL || '../../static/img/default_original.png'" slot="media" />
                <span slot="footer" class="text-color-red" v-if="unpax.disabled">Inactivado</span>
            </f7-list-item>
        </f7-list>

        <f7-fab position="right-bottom" slot="fixed" color="teal">
            <f7-icon material="add"></f7-icon>
            <f7-icon ios="f7:close" aurora="f7:close" md="material:close"></f7-icon>
            <f7-fab-buttons position="top">
                <f7-fab-button fab-close @click="createPaxPopupOpened = true" label="Crear cuenta Inmunopax">
                    <f7-icon material="person_add"></f7-icon>
                </f7-fab-button>
            </f7-fab-buttons>
        </f7-fab>

        <!-- Create Inmunopax account popup -->
        <create-pax-account
            :opened="createPaxPopupOpened"
            @popup-closed="createPaxPopupOpened = false"
            @account-created="handleAccountCreated"
        ></create-pax-account>
    </f7-page>
</template>

<script>
import { mapState } from "vuex";

import CreatePaxAccount from "@/components/create-pax-account";

export default {
    components: {
        "create-pax-account": CreatePaxAccount,
    },

    data: function() {
        return {
            cargandoDataSearch: false,
            resultsBusqueda: [],

            createPaxPopupOpened: false,
        };
    },

    computed: {
        ...mapState(["allPaxsUsersHolder"]),
    },

    methods: {
        getPaxUsers(force) {
            if (this.allPaxsUsersHolder.length == 0 || force) {
                if (this.allPaxsUsersHolder.length == 0) {
                    this.$f7.dialog.preloader("Cargando...");
                } else {
                    this.cargandoDataSearch = true;
                }
                let paxusers = this.$firebase.functions().httpsCallable("paxusers");
                return paxusers({ prod: this.$store.state.env === "production" ? true : false })
                    .then((allpaxUsersInfo) => {
                        console.log(allpaxUsersInfo);
                        let linfo = allpaxUsersInfo.data || {};
                        let allPaxsusers = linfo.paxusers || [];
                        this.$store.commit("setWhatTo", { what: "allPaxsUsersHolder", to: allPaxsusers });
                        this.$f7.dialog.close();
                        this.cargandoDataSearch = false;
                    })
                    .catch((error) => {
                        this.$f7.dialog.close();
                        this.cargandoDataSearch = false;
                        this.$f7.dialog.alert(error.message, error.code);
                    });
            }
        },
        navegaAPax(paxmeta) {
            this.$store.commit("setObjTo", { what: "focusedPax", to: paxmeta });
            this.$f7.views.main.router.navigate("/migration/mantenedores/pax/details/");
        },
        handleAccountCreated(account) {
            console.log("account", account);
            console.log("all paxs users holder", this.allPaxsUsersHolder);

            const updatedPaxUsers = this.allPaxsUsersHolder;
            updatedPaxUsers.push(account);

            this.$store.commit("setWhatTo", { what: "allPaxsUsersHolder", to: updatedPaxUsers });

            this.createPaxPopupOpened = false;
        }
    },
};
</script>

<style>
.paxlistx img {
    width: 40px;
}
</style>
