module.exports = {
    namespaced: true,

    state: {
        viewingAdmissionExam: null,

        usingPlantilla: null,
    },

    mutations: {
        set_viewing_admission_exam(state, admissionExam) {
            state.viewingAdmissionExam = admissionExam;
        },

        set_using_plantilla(state, plantilla) {
            state.usingPlantilla = plantilla;
        },
    },
};
