<template>
    <div>
        <f7-list no-hairlines class="no-margin-bottom margin-top-half">
            <f7-list-item divider title="Resultados"></f7-list-item>
        </f7-list>
        <f7-row>
            <f7-col width="100" medium="40">
                <f7-block-title class="margin-vertical-half">
                    Seleccione el o los tipos de resultados que debe tener el examen:
                </f7-block-title>
                <f7-list no-hairlines class="no-margin-bottom margin-top-half">
                    <f7-list-item
                        checkbox
                        title="Cualitativo"
                        value="cualitativo"
                        :checked="resultsTypes.includes('cualitativo')"
                        @change="selectResultType($event)"
                    ></f7-list-item>
                    <f7-list-item
                        checkbox
                        title="Cuantitativo"
                        value="cuantitativo"
                        :checked="resultsTypes.includes('cuantitativo')"
                        @change="selectResultType($event)"
                    ></f7-list-item>
                </f7-list>
            </f7-col>
            <f7-col width="0" medium="10"></f7-col>
            <f7-col width="100" medium="50" v-if="resultsTypes.includes('cualitativo')">
                <f7-block-title class="margin-top-half no-margin-bottom">
                    Ingrese los valores posibles (sólo cualitativos):
                </f7-block-title>
                <f7-row>
                    <f7-col width="65">
                        <div class="list no-hairlines no-margin-bottom margin-top-half">
                            <ul>
                                <li
                                    :class="{
                                        'item-content': true,
                                        'item-input': true,
                                        'item-input-outline': true,
                                        'item-input-with-error-message': invalidValue,
                                        'item-input-invalid': invalidValue,
                                    }"
                                >
                                    <div class="item-inner">
                                        <div class="item-input-wrap">
                                            <input
                                                type="text"
                                                placeholder="Ej: Positivo"
                                                :value="newValue"
                                                @input="inputNewValue($event)"
                                                @keyup="$event.keyCode === 13 && addValue()"
                                            />
                                            <div v-if="invalidValue" class="item-input-error-message">Debe indicar un valor</div>
                                            <span class="input-clear-button"></span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </f7-col>
                    <f7-col width="35">
                        <f7-button class="margin-top margin-right" outline small @click="addValue()">
                            Agregar
                        </f7-button>
                    </f7-col>
                </f7-row>
                <f7-list class="no-margin-bottom margin-top-half margin-left">
                    <f7-list-item v-for="(qv, index) in qualitativeValues" :key="index">
                        <div slot="title">
                            <span :class="{'text-color-gray': qv.inactive}">{{ qv.qualitativeValue }}</span>
                        </div>
                        <div slot="after" v-if="newQualitativeValues[qv.qualitativeValue]">
                            <f7-button small color="red" @click="removeNewAddedQualitativeResult(qv)">Quitar</f7-button>
                        </div>
                        <div slot="after" v-else-if="validResultTypesValuesCount > 2">
                            <div class="display-flex">
                                <f7-button
                                    v-if="qualitativeValueIsActive(qv)"
                                    small
                                    color="red"
                                    @click="toggleActiveStatusResultType(qv, false)"
                                >
                                    Inactivar
                                </f7-button>
                                <f7-button v-else small color="green" @click="toggleActiveStatusResultType(qv, true)">Activar</f7-button>

                                <f7-button small color="red" tooltip="Eliminar" @click="deleteResultTypeValue(qv)">
                                    <f7-icon material="delete"></f7-icon>
                                </f7-button>
                            </div>
                        </div>
                    </f7-list-item>
                </f7-list>
            </f7-col>
        </f7-row>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    props: {
        resultTypesSaved: Boolean,
    },
    data() {
        return {
            resultsTypes: [],
            newValue: '',
            qualitativeValues: [],
            invalidValue: false,

            // los values en este objeto se muestran con el boton "quitar"
            newQualitativeValues: {},

            qualitativeValuesNoLegacy: [],
        }
    },

    watch: {
        resultTypesSaved() {
            console.log("resultados guardados");

            this.newQualitativeValues = {};
        },

        examResults: {
            handler: function(examResults) {
                examResults.forEach(er => {
                    if (er.name === "cualitativo") {
                        const updatedQualitativeValues = [];

                        er.result_type_values.forEach(rtv => {
                            updatedQualitativeValues.push({
                                id: rtv.id,
                                qualitativeValue: rtv.value,
                                inactive: rtv.inactive,
                            });
                        });

                        this.$set(this, 'qualitativeValues', updatedQualitativeValues);
                    }
                });
            },
        },
    },

    computed: {
        ...mapState(['selectedExam', 'examResults']),

        validResultTypesValuesCount() {
            if (!this.qualitativeValues || !this.qualitativeValues.length) {
                return 0;
            }

            return this.qualitativeValues.filter(qv => {
                if (!qv.id) return false;
                if (Number.isNaN(qv.id)) return false;
                return true;
            }).length;
        },
    },

    methods: {
        ...mapActions(["updateInfo", "deleteAPIInfo"]),

        selectResultType(event) {
            if(event.target.checked){
                this.resultsTypes.push(event.target.value);
                this.sendResultsInfo();
            }
            else{
                const index = this.resultsTypes.indexOf(event.target.value);
                this.resultsTypes.splice(index, 1);
                this.sendResultsInfo();
            }
        },

        inputNewValue(event) {
            this.invalidValue = false;
            this.newValue = event.target.value;
        },

        addValue(){
            if(!this.newValue){
                this.invalidValue = true;
                return
            }
            else{
                this.qualitativeValues.push({
                    qualitativeValue: this.newValue,
                    inactive: false,
                });

                this.newQualitativeValues[this.newValue] = true;

                this.sendResultsInfo();

                this.newValue = '';
            }
        },

        addWithEnterKey(){
            if (event.keyCode === 13){
                this.searchRut();
            }
        },

        removeNewAddedQualitativeResult(qualitativeValueObj) {
            const index = this.qualitativeValues.findIndex(qv => qv.qualitativeValue === qualitativeValueObj.qualitativeValue);
            this.qualitativeValues.splice(index, 1);
            this.$delete(this.newQualitativeValues, qualitativeValueObj.qualitativeValue);
            this.sendResultsInfo();
        },

        qualitativeValueIsActive(qualitativeValueObj) {
            const value = qualitativeValueObj.qualitativeValue;
            return !this.qualitativeValues.find(qv => qv.qualitativeValue === value).inactive;
        },

        sendResultsInfo() {
            const resultsInfo = {
                resultsTypes: this.resultsTypes,
                qualitativeValues: this.qualitativeValues,
                qualitativeValuesNoLegacy: this.qualitativeValuesNoLegacy,
            };
            this.$emit('addResultsInfo', resultsInfo);
        },

        toggleActiveStatusResultType(qv, activate) {
            console.log({ qv, activate });

            this.$f7.dialog.confirm(
                "Se modificará el estado de inactividad del tipo de resultado. Si queda como <b>inactivo</b> se ocultará en todas las vistas.",
                "¿Estás seguro?",
                async () => {
                    this.$f7.dialog.preloader("Modificando tipo de resultado...");

                    const inactiveStatus = !activate;

                    const token = await this.$firebase.auth().currentUser.getIdToken();

                    await this.updateInfo({
                        url: `exams/${this.selectedExam.code}/${qv.id}/toggle-active`,
                        data: { inactive: inactiveStatus },
                        userToken: token,
                    });

                    this.$set(qv, "inactive", inactiveStatus);

                    this.$f7.dialog.close();
                }
            );
        },

        deleteResultTypeValue(qv) {
            console.log("should delete qv", qv);

            this.$f7.dialog.confirm(
                "Se eliminará el tipo de resultado. Esta acción solo hará efecto si ningún examen ha sido informado utilizando este tipo de resultado. Si desea ocultar el tipo de resultado en otras vistas, considere <b>inactivarlo</b>.",
                "¿Estás seguro?",
                async () => {
                    try {
                        this.$f7.dialog.preloader("Eliminando tipo de resultado...");

                        const token = await this.$firebase.auth().currentUser.getIdToken();

                        const response = await this.deleteAPIInfo({
                            url: `exams/${this.selectedExam.code}/${qv.id}/?qualitativeValue=${qv.qualitativeValue}`,
                            userToken: token,
                        });

                        console.log("response", response);

                        const index = this.qualitativeValues.findIndex(v => v.id === qv.id);
                        this.qualitativeValues.splice(index, 1);

                        this.$store.commit("deleteQualititaiveResult", {
                            examId: this.selectedExam.id,
                            resultTypeValueId: qv.id,
                        });

                        this.$f7.dialog.close();
                    } catch (error) {
                        const errorMessage = error.response && error.response.status === 409 ? error.response.data.message : error.message;

                        console.error(error);
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(errorMessage);
                    }
                });
        }
    },

    mounted() {
        if(this.selectedExam){
            this.resultsTypes = this.examResults.map((result) => {
                if(result.name === 'cualitativo'){
                    result.values.forEach(value=> {
                        this.qualitativeValues.push({
                            id: value.id,
                            qualitativeValue: value.name,
                            inactive: value.inactive,
                        });
                    });
                }
                return result.name
            });
            this.sendResultsInfo();
        }
    }
}
</script>
