<template>
    <f7-block class="padding-vertical">
        <f7-block v-if="isLoading" class="row demo-preloaders align-items-stretch text-align-center margin-bottom">
            <f7-col>
                <f7-preloader></f7-preloader>
            </f7-col>
        </f7-block>
        <template v-else>
            <div>
                <f7-list class="margin-bottom">
                    <f7-list-item
                        title="Institución"
                        smart-select
                        :smart-select-params="{
                            openIn: 'popup',
                            searchbar: true,
                            searchbarPlaceholder: 'Buscar instituciones',
                            setValueText: false,
                        }"
                    >
                        <select multiple v-model="options.institutions">
                            <option v-for="institution in allInstitutions" :key="institution.id" :value="institution.id">
                                {{ institution.name }}
                            </option>
                        </select>
                        <div slot="after">{{ optionsInstitutionsArray.join(", ") }}</div>
                    </f7-list-item>

                    <f7-list-item
                        title="Estado de la solicitud"
                        smart-select
                        :smart-select-params="{
                            openIn: 'popup',
                            setValueText: false,
                        }"
                    >
                        <select multiple v-model="options.statuses">
                            <option v-for="status in allStatuses" :key="status.id" :value="status.id">
                                {{ status.name }}
                            </option>
                        </select>
                        <div slot="after">{{ optionsStatusesArray.join(", ") }}</div>
                    </f7-list-item>
                </f7-list>

                <f7-block>
                    <p><b>Fecha de solicitud</b></p>
                    <f7-row class="margin-bottom padding-bottom">
                        <f7-col>
                            <label>Fecha inicio</label>
                            <f7-input
                                class="input-uppercase margin-top-half"
                                type="text"
                                :maxlength="10"
                                @input="options.initDate = $event.target.value"
                                :value="options.initDate | formatDate"
                                placeholder="dd/MM/AAAA"
                            ></f7-input>
                        </f7-col>
                        <f7-col>
                            <label>Fecha término</label>
                            <f7-input
                                class="input-uppercase margin-top-half"
                                type="text"
                                :maxlength="10"
                                @input="options.endDate = $event.target.value"
                                :value="options.endDate | formatDate"
                                placeholder="dd/MM/AAAA"
                            ></f7-input>
                        </f7-col>
                    </f7-row>
                </f7-block>
            </div>

            <slot name="search-footer"></slot>
        </template>
    </f7-block>
</template>

<script>
import { mapState } from "vuex";

export default {
    props: {
        reset: Boolean,
    },

    data() {
        return {
            loadingInstitutions: false,

            allStatuses: [
                { id: 1, name: "Pendientes" },
                { id: 2, name: "Admitidas" },
                { id: 3, name: "Rechazadas" },
            ],

            options: {
                institutions: [],
                statuses: [1], // por defecto las pendientes
                initDate: "",
                endDate: "",
            },
        };
    },

    computed: {
        ...mapState(["allInstitutions"]),

        isLoading() {
            const isLoading = this.loadingInstitutions;

            this.$emit("is-loading", isLoading);

            return isLoading;
        },

        optionsInstitutionsArray() {
            if (!this.options.institutions.length) return [];

            return this.options.institutions.map((institutionId) => {
                const institution = this.allInstitutions.find((i) => i.id == institutionId);

                return institution ? institution.name : "Sin institución";
            });
        },
        optionsStatusesArray() {
            if (!this.options.statuses.length) return [];

            return this.options.statuses.map((statusId) => {
                const status = this.allStatuses.find((s) => s.id == statusId);

                return status ? status.name : "Sin estado";
            });
        },
    },

    watch: {
        reset() {
            this.options.institutions = [];
            this.options.statuses = [];
            this.options.initDate = "";
            this.options.endDate = "";
        },

        options: {
            deep: true,
            handler() {
                this.emitOptionsChanged();
            },
        },
    },

    created() {
        this.getInstitutions();

        this.emitOptionsChanged();
    },

    methods: {
        emitOptionsChanged() {
            this.$emit("options-changed", this.options);
        },

        getInstitutions() {
            if (this.allInstitutions) {
                return;
            }

            this.$f7.dialog.preloader("Cargando...");

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({ url: "institutions", userToken: idToken });
                })
                .then((response) => {
                    const institutions = response.data.data;
                    this.$store.commit("setWhatTo", { what: "allInstitutions", to: institutions });

                    this.$f7.dialog.close();
                })
                .catch((err) => {
                    console.error(err);
                    this.$f7.dialog.alert("Error " + err);
                });
        },
    },
};
</script>
