<template>
    <div>
        <h3 class="margin-horizontal">Paciente en el Excel</h3>
        <patient-form 
            :patient="comparison.excelPatient"
            :admissionId="comparison.admissionId"
            readonly
            :highlighted-fields="differentFields"
        ></patient-form>
        <f7-button fill class="margin-horizontal" @click="$emit('use-patient', 'excelPatient')">
            Utilizar este paciente y actualizarlo en la base de datos
        </f7-button>

        <h3 class="margin-horizontal">Paciente en la base de datos</h3>
        <patient-form 
            :patient="comparison.dbPatient"
            :admissionId="comparison.admissionId"
            readonly
            :highlighted-fields="differentFields"
        ></patient-form>
        <f7-button fill class="margin-horizontal margin-bottom" @click="$emit('use-patient', 'dbPatient')">
            Utilizar este paciente
        </f7-button>
    </div>
</template>

<script>
import PatientForm from "./patient-form.vue";
export default {
    components: { PatientForm },
    props: {
        comparison: {
            type: Object,
            required: true,
        }
    },
    computed: {
        differentFields() {
            const diffFields = {};

            const { dbPatient, excelPatient } = this.comparison;

            if (this.compareStringFields(dbPatient.address, excelPatient.address)) {
                diffFields.address = true;
            }
            if (this.compareStringFields(dbPatient.birth_date, excelPatient.birth_date)) {
                diffFields.birth_date = true;
            }
            if (dbPatient.comuna_id != excelPatient.comuna_id) {
                diffFields.comuna_id = true;
            }
            if (dbPatient.country_id != excelPatient.country_id) {
                diffFields.country_id = true;
            }
            if (this.compareStringFields(dbPatient.document_number, excelPatient.document_number)) {
                diffFields.document_number = true;
            }
            if (dbPatient.document_type != excelPatient.document_type) {
                diffFields.document_type = true;
            }
            if (this.compareStringFields(dbPatient.email, excelPatient.email)) {
                diffFields.email = true;
            }
            if (dbPatient.gender != excelPatient.gender) {
                diffFields.gender = true;
            }
            if (this.compareStringFields(dbPatient.lastname_f, excelPatient.lastname_f)) {
                diffFields.lastname_f = true;
            }
            if (this.compareStringFields(dbPatient.lastname_m, excelPatient.lastname_m)) {
                diffFields.lastname_m = true;
            }
            if (this.compareStringFields(dbPatient.name, excelPatient.name)) {
                diffFields.name = true;
            }
            if (this.compareStringFields(dbPatient.phone, excelPatient.phone)) {
                diffFields.phone = true;
            }
            if (dbPatient.prevision != excelPatient.prevision) {
                diffFields.prevision = true;
            }

            return diffFields;
        },
    },

    methods: {
        compareStringFields(first, second) {
            let cleanFirst = first;
            let cleanSecond = second;

            if (cleanFirst === "") {
                cleanFirst = null
            }

            if (cleanSecond === "") {
                cleanSecond = null
            }

            return cleanFirst != cleanSecond;
        }
    }
}
</script>