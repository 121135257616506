<template>
    <f7-page name="admission-list">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title>
                Admisiones
            </f7-nav-title>
        </f7-navbar>

        <f7-block class="margin-bottom-half">
            <f7c-alert type="info" dismissible v-if="lastSearch && !currentSearch" class="margin-horizontal-half">
                Mostrando los resultados de la última búsqueda.
            </f7c-alert>
            <f7-card outline>
                <f7-card-header class="display-flex justify-content-space-between">
                    <div class="text-color-gray">Buscador de admisiones</div>
                    <div class="display-flex">
                        <f7-button small v-if="showingAdmissionsSearch" @click="showingAdmissionsSearch = false">Ocultar</f7-button>
                        <f7-button small v-else @click="showingAdmissionsSearch = true">Mostrar</f7-button>
                        <f7-button small class="margin-left-half" color="red" @click="clearSearchFilters(true)">
                            Limpiar filtros
                        </f7-button>
                    </div>
                </f7-card-header>
                <f7-card-content v-show="showingAdmissionsSearch">
                    <admissions-search
                        @is-loading="handleAdmissionsSearchIsLoading"
                        @options-changed="handleAdmissionsSearchOptionsChanged"
                        @search-triggered="handleSearchAdmissions"
                        :reset="resetAdmissionsSearchComponent"
                    >
                        <template #search-footer>
                            <f7-button class="margin-horizontal margin-bottom" fill large raised @click="handleSearchAdmissions">
                                <f7-icon material="search"></f7-icon>
                                Buscar admisiones
                            </f7-button>
                        </template>
                    </admissions-search>
                </f7-card-content>
            </f7-card>

            <f7-block v-if="!admissionsSearchIsLoading">
                <template v-if="pagination.totalRows">
                    <div class="display-flex justify-content-space-between margin-bottom">
                        <h2 class="no-margin">Viendo {{ admissions.length }} de {{ pagination.totalRows }} admisiones</h2>
                        <f7-button v-if="currentSearch" @click="clearLastSearchResults(true)" small>Limpiar resultados última búsqueda</f7-button>
                    </div>
                    <div class="margin-bottom">
                        <label><b>Filtrar datos por paciente, institución, código de OT ó examenes</b></label>
                        <f7-input
                            type="text"
                            @input="filterInput = $event.target.value"
                            clear-button
                            class="margin-bottom padding-vertical-half"
                            placeholder="Ingrese su búsqueda..."
                        ></f7-input>
                        <div v-if="filterInput.length > 0" class="margin-top-half text-color-gray">
                            Mostrando {{ filteredAdmissions.length }} admisiones filtradas
                        </div>
                    </div>
                </template>

                <admissions-table :admissions="filteredAdmissions" :pagination="pagination" @load-more="handleLoadMoreAdmissions">
                    <template #table-header>
                        <th>OT</th>
                        <th>Documento paciente</th>
                        <th>Paciente</th>
                        <th>Examenes</th>
                        <th>Institución</th>
                        <th>Prioridad</th>
                        <th>Creada el</th>
                        <th></th>
                    </template>

                    <template #table-row="{ admission, f }">
                        <td>{{ f.otsCodesInfo(admission) }}</td>
                        <td>{{ f.patientDocumentInfo(admission) }}</td>
                        <td>{{ f.patientFullName(admission) }}</td>
                        <td>{{ f.examsInfo(admission) }}</td>
                        <td>{{ f.institutionInfo(admission) }}</td>
                        <td>{{ f.priorityInfo(admission) }}</td>
                        <td>{{ $moment(admission.createdAt).format("DD/MM/YYYY HH:mm") }}</td>
                        <td>
                            <f7-button fill small @click="f.handleViewAdmission(admission)">Ver</f7-button>
                        </td>
                    </template>
                </admissions-table>
            </f7-block>
        </f7-block>
    </f7-page>
</template>

<script>
import { mapActions, mapState } from "vuex";
import AdmissionsSearch from "@/app/components-migration/admissions-search.vue";
import AdmissionsTable from "@/app/components-migration/admissions-table.vue";
import SearchMixin from "@/app/mixins/search-mixin.js";

import { formatString } from "@/app/helpers";

export default {
    mixins: [SearchMixin],

    components: {
        "admissions-search": AdmissionsSearch,
        "admissions-table": AdmissionsTable,
    },

    data() {
        return {
            resetAdmissionsSearchComponent: false,

            currentSearch: false,

            showingAdmissionsSearch: true,
            admissionsSearchIsLoading: false,

            admissionsSearchOptions: {},

            admissions: [],
            pagination: {},

            filterInput: "",
        };
    },

    computed: {
        ...mapState("admission", ["lastSearch", "updatedAdmission"]),

        searchQueryString() {
            const queryString = this.queryStringFromOptions(this.admissionsSearchOptions, {
                rowsPerPage: 100,
            });

            return queryString;
        },

        filteredAdmissions() {
            const filterInput = formatString(this.filterInput);

            if (filterInput.length === 0) return this.admissions;

            const filteredAdmissions = this.admissions.filter((admission) => {
                const { patient, institution, ots, exams } = admission;

                let matched = false;

                if (!matched) {
                    let cleanPatientFullname = "";

                    if (patient.name) cleanPatientFullname += patient.name;
                    if (patient.lastname_f) cleanPatientFullname += patient.lastname_f;
                    if (patient.lastname_m) cleanPatientFullname += patient.lastname_m;

                    cleanPatientFullname = formatString(cleanPatientFullname);

                    const patientDocumentNumber = formatString(patient.document_number);

                    if (cleanPatientFullname.match(filterInput) || patientDocumentNumber.match(filterInput)) {
                        matched = true;
                    }
                }
                if (!matched) {
                    if (institution && institution.name) {
                        const cleanInstitutionName = formatString(institution.name);
                        if (cleanInstitutionName.match(filterInput)) {
                            matched = true;
                        }
                    }
                }
                if (!matched) {
                    if (ots && ots.length) {
                        ots.forEach((ot) => {
                            const cleanOtCode = ot.code.toLowerCase();
                            if (cleanOtCode.match(filterInput)) {
                                matched = true;
                            }
                        });
                    }
                }
                if (!matched) {
                    if (exams && exams.length) {
                        exams.forEach((exam) => {
                            const examName = exam.exam ? (exam.exam.name || "") : ""
                            const cleanedExamName = formatString(examName);

                            const examCode = exam.exam ? (exam.exam.code || "") : ""
                            const cleanedExamCode = examCode;

                            if (cleanedExamName.match(filterInput) || cleanedExamCode.match(filterInput)) {
                                matched = true;
                            }
                        });
                    }
                }

                return matched;
            });

            return filteredAdmissions;
        },
    },

    watch: {
        updatedAdmission() {
            if (this.updatedAdmission) {
                const index = this.admissions.findIndex((a) => a.id === this.updatedAdmission.id);

                this.$set(this.admissions, index, this.updatedAdmission);

                this.$store.commit("admission/set_updated_admission", null);
            }
        },
    },

    created() {
        if (this.lastSearch) {
            this.admissionsSearchOptions = this.lastSearch.searchOptions;
            this.admissions = this.lastSearch.admissions;
            this.pagination = this.lastSearch.pagination;
        }
    },

    methods: {
        ...mapActions(["getAPIInfo"]),

        handleSearchAdmissions() {
            this.$f7.dialog.preloader("Buscando admisiones...");

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({
                        url: "admissions?" + this.searchQueryString,
                        userToken: idToken,
                    });
                })
                .then((response) => {
                    this.admissions = response.data.data;
                    this.pagination = response.data.pagination;

                    this.currentSearch = true;

                    if (
                        this.admissionsSearchOptions.ot_code &&
                        this.admissions.length === 1 &&
                        this.admissions[0].ots &&
                        this.admissions[0].ots.length === 1
                    ) {
                        const admission = this.admissions[0];
                        const ot = admission.ots[0];

                        const examsSelectedByOT = admission.exams.filter((exam) => exam.section_id === ot.section_id);
                        const otsSelected = admission.ots.filter((admissionOT) => admissionOT.section_id === ot.section_id);

                        const context = ot ? { otSelected: ot } : null;
                        admission.exams = examsSelectedByOT;
                        admission.ots = otsSelected;

                        this.$store.commit("admission/set_viewing_admission", { admission, context });
                        this.$store.commit("setWhatTo", { what: "activeTab", to: "/migration/admissions/index" });

                        this.$f7.dialog.close();

                        this.$f7.views.main.router.navigate("/migration/admissions/index");

                        return;
                    } else {
                        this.$store.commit("admission/save_last_search", {
                            searchOptions: this.admissionsSearchOptions,
                            admissions: this.admissions,
                            pagination: this.pagination,
                        });
                    }

                    this.$f7.dialog.close();
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error);
                });
        },

        handleLoadMoreAdmissions() {
            this.$f7.dialog.preloader("Cargando más admisiones...");

            let queryString = this.searchQueryString;

            const page = this.pagination.page + 1;

            queryString += `&page=${page}`;

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({
                        url: "admissions?" + queryString,
                        userToken: idToken,
                    });
                })
                .then((response) => {
                    const responseData = response.data;

                    if (responseData.data && responseData.data.length) {
                        this.admissions.push(...responseData.data);
                        this.pagination = responseData.pagination;

                        this.$store.commit("admission/save_last_search", {
                            searchOptions: this.admissionsSearchOptions,
                            admissions: this.admissions,
                            pagination: this.pagination,
                        });
                    }

                    this.$f7.dialog.close();
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error);
                });
        },

        handleAdmissionsSearchIsLoading(isLoading) {
            this.admissionsSearchIsLoading = isLoading;
        },

        handleAdmissionsSearchOptionsChanged(options) {
            this.admissionsSearchOptions = options;
        },

        clearSearchFilters(confirm = false) {
            const _clear = () => {
                this.resetAdmissionsSearchComponent = !this.resetAdmissionsSearchComponent;

                this.showingAdmissionsSearch = true;
                this.admissionsSearchIsLoading = false;
                this.admissionsSearchOptions = {};
            };

            if (confirm) {
                this.$f7.dialog.confirm("¿Esta seguro?", () => {
                    _clear();
                });
            } else {
                _clear();
            }
        },

        clearLastSearchResults(confirm) {
            const _clear = () => {
                this.currentSearch = false;
                this.admissions = [];
                this.pagination = {};
                this.filterInput = "";
                this.$store.commit("admission/delete_last_search");
            };

            if (confirm) {
                this.$f7.dialog.confirm("¿Está seguro?", () => _clear());
            } else {
                _clear();
            }
        },
    },
};
</script>
