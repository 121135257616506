var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-page',{staticClass:"backgris",attrs:{"name":"ordenTrabajo"}},[_c('f7-navbar',{attrs:{"title":_vm.orden.name,"subtitle":"orden de trabajo","back-link":""}}),_vm._v(" "),_c('f7-row',[_c('f7-col',{attrs:{"width":"25"}},[_c('f7-block',{staticClass:"bg-color-white padding"},[_c('img',{staticClass:"innerpaxavatar",attrs:{"src":_vm.patinfo.sexo == 'Masculino' ? '../static/img/masc.jpg' : '../static/img/feme.jpg',"alt":"avatar"}}),_vm._v(" "),_c('f7-list',{staticClass:"paxinfo no-margin"},[_c('f7-list-item',{attrs:{"header":"Apellidos","title":(_vm.patinfo.primerApellido || '-') +
                                ' ' +
                                (_vm.patinfo.segundoApellido ? _vm.patinfo.segundoApellido : '')}}),_vm._v(" "),_c('f7-list-item',{attrs:{"header":"Nombres","title":_vm.patinfo.nombres}}),_vm._v(" "),_c('f7-list-item',{attrs:{"header":_vm.patinfo.tipoIdentificador,"title":_vm.formatId(_vm.patinfo)}}),_vm._v(" "),_c('f7-list-item',{attrs:{"header":"Nacionalidad","title":_vm.patinfo.nacionalidad}}),_vm._v(" "),_c('f7-list-item',{attrs:{"header":"Fecha de nacimiento","title":_vm.patinfo.fechaNacimiento}}),_vm._v(" "),_c('f7-list-item',{attrs:{"header":"Teléfono","title":_vm.patinfo.telefono || 'Sin Información'}})],1),_vm._v(" "),_c('f7-button',{attrs:{"outline":""},on:{"click":function($event){return _vm.gotoEditPaxMeta()}}},[_vm._v("Editar datos paciente")])],1),_vm._v(" "),_c('f7-list',{staticClass:"bg-color-white"},[_c('f7-list-item',{attrs:{"divider":"","title":"Log"}}),_vm._v(" "),(_vm.fecthingMembers)?_c('f7-progressbar',{attrs:{"infinite":""}}):_vm._e(),_vm._v(" "),_vm._l((_vm.logexamen),function(action,indx){return _c('f7-list-item',{key:'a' + indx,attrs:{"title":action.wht,"footer":_vm.$moment.unix(action.whn).format('DD/MM/YYYY @ HH:mm'),"header":action.who}})})],2)],1),_vm._v(" "),_c('f7-col',{attrs:{"width":"75"}},[_c('f7-block',{staticClass:"unbloqueexamen"},[_c('f7-list',{staticClass:"no-margin-top toplistin",attrs:{"no-hairlines":""}},[_c('f7-list-item',{attrs:{"title":_vm.orden.name}},[(
                                _vm.focused_orden.examen.firmado && _vm.misprivx.vercolas[_vm.focused_section.id].fir
                            )?_c('f7-button',{staticClass:"margin-right",attrs:{"slot":"after","fill":"","small":"","color":"red","icon-material":"report_problem"},on:{"click":function($event){return _vm.reverseSignature()}},slot:"after"},[_vm._v("\n                            Revertir Firma\n                        ")]):_vm._e(),_vm._v(" "),_c('span',{attrs:{"slot":"after"},slot:"after"},[_vm._v("\n                            "+_vm._s(_vm.examStatus)+"\n                        ")])],1)],1),_vm._v(" "),_c('f7-block-title',[_vm._v("\n                    Muestras asociadas\n                    "),(_vm.loadingSamplesLog)?_c('f7-preloader',{attrs:{"size":13}}):_vm._e()],1),_vm._v(" "),_vm._l((_vm.orden.samples),function(trued,sampleid){return _c('f7-row',{key:sampleid,staticClass:"rowdesample"},[_c('f7-col',{attrs:{"width":"10"}},[_c('f7-button',{attrs:{"color":"blue","icon-material":"print","fill":"","raised":""},on:{"click":function($event){return _vm.requestPrint(sampleid)}}})],1),_vm._v(" "),_c('f7-col',{attrs:{"width":"90"}},[_c('f7-list',{staticClass:"no-margin",attrs:{"inset":"","accordion-list":""}},[_c('f7-list-item',{attrs:{"accordion-item":"","title":_vm.focused_orden.samplinfo[sampleid].name,"footer":sampleid,"after":_vm.getSampleLocation(sampleid)}},[_c('f7-icon',{attrs:{"slot":"after","material":"location_on"},slot:"after"}),_vm._v(" "),_c('f7-accordion-content',{staticClass:"fondogrixsamplog"},[_c('f7-list',{staticClass:"sampllog",attrs:{"inset":""}},_vm._l((_vm.samplesLog[sampleid]),function(log,index){return _c('f7-list-item',{key:index,attrs:{"title":log.wht,"footer":"","header":_vm.formatDate(log.whn)}},[_c('f7-icon',{attrs:{"slot":"after","material":"home"},slot:"after"})],1)}),1)],1)],1)],1)],1)],1)}),_vm._v(" "),_c('results-form',{attrs:{"sendResults":_vm.saveChanges}}),_vm._v(" "),_c('hr'),_vm._v(" "),_c('f7-row',{staticClass:"margin-bottom"},[_c('f7-col',[(
                                _vm.focused_orden.examen.ingresado &&
                                    !_vm.focused_orden.examen.confirmado &&
                                    _vm.misprivx.vercolas[_vm.focused_section.id].con
                            )?_c('f7-button',{attrs:{"fill":"","raised":"","color":"purple"},on:{"click":function($event){return _vm.changeStatus('confirmado', _vm.focused_orden.examen)}}},[_vm._v("\n                            Confirmar\n                        ")]):_vm._e()],1),_vm._v(" "),_c('f7-col',[(
                                _vm.focused_orden.examen.confirmado &&
                                    !_vm.focused_orden.examen.firmado &&
                                    _vm.misprivx.vercolas[_vm.focused_section.id].fir
                            )?_c('f7-button',{attrs:{"outline":"","color":"purple"},on:{"click":function($event){return _vm.changeStatus('firmado', _vm.focused_orden.examen)}}},[_vm._v("\n                            Firmar\n                        ")]):_vm._e()],1)],1),_vm._v(" "),(_vm.focused_orden.examen.firmado && !_vm.misprivx.resultados)?_c('f7-row',{staticClass:"margin-bottom text-align-center"},[_c('p',{staticClass:"no-margin"},[_vm._v("\n                        Su usuario no tiene privilegios para generar/imprimir PDFs de resultados. Un\n                        usuario con permisos de gestor de privielgios debe activarselos desde el\n                        mantenedor de miembros.\n                    ")])]):(_vm.focused_orden.examen.firmado && _vm.misprivx.resultados)?_c('f7-row',{staticClass:"margin-bottom"},[_c('f7-link',{attrs:{"disabled":"","icon-material":"assignment_returned"},on:{"click":function($event){return _vm.ViewAndPrint(
                                true,
                                { id: _vm.focused_orden.admisionid },
                                [_vm.focused_orden.examen.id],
                                _vm.patinfo.id
                            )}}},[_vm._v("\n                        Descargar\n                    ")]),_vm._v(" "),_c('f7-link',{attrs:{"icon-material":"printer"},on:{"click":function($event){return _vm.ViewAndPrint(
                                false,
                                { id: _vm.focused_orden.admisionid },
                                [_vm.focused_orden.examen.id],
                                _vm.patinfo.id
                            )}}},[_vm._v("\n                        Ver e Imprimir\n                    ")])],1):_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }