<template>
    <div>
        <block-toolbar v-if="!previewMode && blockNumber === 1" :block="computedBlockInfo('resultado-collection-block')"></block-toolbar>

        <div>
            <div v-if="associatedExams.length === 0" class="text-color-gray text-align-center margin-top">
                No hay exámenes asociados para mostrar
            </div>
            <template v-else>
                <table class="table table-sm table-bordered bg-color-white table-collection">
                    <thead class="thead-default" v-if="blockNumber === 1">
                        <tr>
                            <th class="font-normal ">
                                <div v-if="translation"><i>Exam</i></div>
                                <div><b>Exámen</b></div>
                            </th>
                            <th v-if="atLeastOneHas(resultTypesData.cualitativo)" class="font-normal">
                                <div v-if="translation"><i>Cualitative result</i></div>
                                <div><b>Resultado cualitativo</b></div>
                            </th>
                            <th v-if="atLeastOneHas(resultTypesData.cuantitativo)" class="font-normal">
                                <div v-if="translation"><i>Cuantitative result</i></div>
                                <div><b>Resultado cuantitativo</b></div>
                            </th>
                            <th v-if="atLeastOneHas(resultTypesData.free_text)" class="font-normal">
                                <div v-if="translation"><i>Free text result</i></div>
                                <div><b>Resultado texto libre</b></div>
                            </th>
                            <th v-if="admissionExam.exam.has_classes" class="font-normal">
                                <div v-if="translation"><i>Class</i></div>
                                <div><b>Clase</b></div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="exam in associatedExamsByBlockNumber" :key="exam.id">
                            <td>{{ exam.name }}</td>
                            <td v-if="atLeastOneHas(resultTypesData.cualitativo)">
                                <f7-input
                                    v-if="!previewMode"
                                    type="select"
                                    @change="(e) => handleResultChanged(exam.id, 'cualitativo-result', e.target.value)"
                                    :disabled="disableInputs"
                                >
                                    <option value="">Seleccione...</option>
                                    <option
                                        v-for="resultTypeValue in getCualitativoResultTypeValues(exam)"
                                        :key="resultTypeValue.id"
                                        :value="resultTypeValue.value"
                                        :selected="shouldSelectCualitativoValue(exam.id, resultTypeValue.value)"
                                        :disabled="resultTypeValue.inactive"
                                    >
                                        {{ resultTypeValue.value }}
                                    </option>
                                </f7-input>
                                <div v-else>{{ getResultDataForPreview(exam.id, "cualitativo-result") }}</div>
                            </td>
                            <td v-if="atLeastOneHas(resultTypesData.cuantitativo)">
                                <f7-row v-if="!previewMode">
                                    <f7-col>
                                        <f7-input
                                            type="text"
                                            placeholder="Ingrese resultado"
                                            @change="(e) => handleResultChanged(exam.id, 'cuantitativo-result', e.target.value)"
                                            :disabled="disableInputs"
                                            :value="getExamCuantitativoData(exam.id, 'result')"
                                            @focus="handleFocusChanged(true)"
                                            @blur="handleFocusChanged(false)"
                                        ></f7-input>
                                    </f7-col>
                                    <f7-col>
                                        <f7-input
                                            type="select"
                                            @change="(e) => handleResultChanged(exam.id, 'cuantitativo-unit', e.target.value)"
                                            :disabled="disableInputs"
                                        >
                                            <option value="">Seleccione unidad...</option>
                                            <option
                                                v-for="unit in allUnits"
                                                :value="unit.id"
                                                :key="unit.id"
                                                :selected="unit.id == getExamCuantitativoData(exam.id, 'unit')"
                                                @focus="handleFocusChanged(true)"
                                                @blur="handleFocusChanged(false)"
                                            >
                                                {{ unit.name }}
                                            </option>
                                        </f7-input>
                                    </f7-col>
                                </f7-row>
                                <div v-else>
                                    {{ getResultDataForPreview(exam.id, "cuantitativo-result") }}
                                    {{ getResultDataForPreview(exam.id, "cuantitativo-unit") }}
                                </div>
                            </td>
                            <td v-if="atLeastOneHas(resultTypesData.free_text)">
                                <f7-input
                                    v-if="!previewMode"
                                    type="text"
                                    placeholder="Ingrese resultado"
                                    @change="(e) => handleResultChanged(exam.id, 'free-text-result', e.target.value)"
                                    :disabled="disableInputs"
                                    :value="getExamFreeTextData(exam.id)"
                                ></f7-input>
                                <div v-else>
                                    {{ getResultDataForPreview(exam.id, "free-text-result") }}
                                </div>
                            </td>
                            <td v-if="admissionExam.exam.has_classes" :class="`class-int-${calculateResultClass(exam.id)} text-align-center`">
                                {{ calculateResultClass(exam.id) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { resultTypes, classes } from "@/app/constants";
import BlockToolbarMixin from "@/app/components-migration/plantillas/blocks/toolbar/block-toolbar-mixin";
import BlockToolbar from "@/app/components-migration/plantillas/blocks/toolbar/block-toolbar.vue";

export default {
    mixins: [BlockToolbarMixin],

    props: {
        previewMode: Boolean,
        translation: Boolean,
        defaults: Object,
        disableInputs: Boolean,
        associatedExams: Array,
        blockId: String,
        collectionBlocks: Array,
        admissionExam: Object,
    },

    components: {
        "block-toolbar": BlockToolbar,
    },

    data() {
        return {
            resultTypesData: resultTypes,

            results: {},
        };
    },

    created() {
        if (this.allUnits.length === 0) {
            this.getUnits();
        }
    },

    watch: {
        associatedExams() {
            if (this.associatedExams.length === 0) {
                this.resetResults();
            }
        },

        defaults: {
            immediate: true,
            deep: true,
            handler() {
                this.setDefaultsToBlock();
            },
        },

        results: {
            deep: true,
            handler() {
                this.$emit("resultado-collection-result-changed", {
                    results: this.results,
                });
            },
        },

        associatedExamsByBlockNumber: {
            deep: true,
            immediate: true,
            handler() {
                const hasResultado = (resultTypeId, exam) => {
                    return exam.result_types ? exam.result_types.findIndex((rt) => rt.result_type === resultTypeId) !== -1 : false;
                };

                const results = {};

                this.associatedExamsByBlockNumber.forEach((exam) => {
                    const examResult = {};

                    if (hasResultado(resultTypes.cualitativo, exam)) {
                        const cualitativoResult = this.results[exam.id]?.cualitativo?.result || "";

                        examResult.cualitativo = { result: cualitativoResult };
                    }
                    if (hasResultado(resultTypes.cuantitativo, exam)) {
                        const cuantitativoResult = this.results[exam.id]?.cuantitativo?.result || "";
                        const cuantitativoUnit = this.results[exam.id]?.cuantitativo?.unit || "";

                        examResult.cuantitativo = { result: cuantitativoResult, unit: cuantitativoUnit };
                    }
                    if (hasResultado(resultTypes.free_text, exam)) {
                        const freeTextResult = this.results[exam.id]?.freeText?.result || "";

                        examResult.freeText = { result: freeTextResult };
                    }

                    results[exam.id] = examResult;
                });

                this.results = results;
            },
        },

        viewingPlantilla: {
            handler: function(plantilla) {
                console.log("viewingPlantilla watcher en bloque coleccion", this.defaults);
                if (plantilla) this.setDefaultsToBlock();
            },
        },
    },

    computed: {
        ...mapState("unit", ["allUnits"]),
        ...mapState("plantillas", ["examsPerCollectionBlock", "viewingPlantilla"]),

        hasResults() {
            return Object.keys(this.results || {}).length > 0;
        },

        blockNumber() {
            const index = this.collectionBlocks.findIndex((b) => b.id === this.blockId);

            return index + 1;
        },

        associatedExamsByBlockNumber() {
            const associatedExams = this.associatedExams;

            const index = this.blockNumber * this.examsPerCollectionBlock - this.examsPerCollectionBlock;
            const quantity = this.examsPerCollectionBlock * this.blockNumber;

            return associatedExams.slice(index, quantity);
        },
    },

    methods: {
        ...mapActions(["getAPIInfo"]),

        setDefaultsToBlock() {
            if (this.defaults.results) this.results = this.defaults.results;
        },

        handleResultChanged(examId, input, value) {
            if (!this.results[examId]) {
                this.$set(this.results, examId, {});
            }

            switch (input) {
                case "cualitativo-result":
                    this.$set(this.results[examId], "cualitativo", { result: value });

                    break;

                case "cuantitativo-result":
                    if (!this.results[examId].cuantitativo) {
                        this.$set(this.results[examId], "cuantitativo", { result: "", unit: "" });
                    }

                    this.$set(this.results[examId].cuantitativo, "result", value);

                    break;

                case "cuantitativo-unit":
                    if (!this.results[examId].cuantitativo) {
                        this.$set(this.results[examId], "cuantitativo", { result: "", unit: "" });
                    }

                    this.$set(this.results[examId].cuantitativo, "unit", value);

                    break;

                case "free-text-result":
                    this.$set(this.results[examId], "freeText", { result: value });

                    break;
            }
        },

        resetResults() {
            this.results = {};
        },

        getResultDataForPreview(examId, input) {
            if (!this.results[examId]) return "Sin resultado";

            switch (input) {
                case "cualitativo-result":
                    if (!this.results[examId].cualitativo) return "Sin resultado";

                    return this.results[examId].cualitativo.result || "Sin resultado";

                case "cuantitativo-result":
                    if (!this.results[examId].cuantitativo) return "Sin resultado";

                    return this.results[examId].cuantitativo.result || "Sin resultado";

                case "cuantitativo-unit":
                    if (!this.results[examId].cuantitativo) return "Sin unidad";

                    if (!this.results[examId].cuantitativo.unit) return "Sin unidad";

                    return this.getUnitName(this.results[examId].cuantitativo.unit);

                case "free-text-result":
                    if (!this.results[examId].freeText) return "Sin resultado";

                    return this.results[examId].freeText.result || "Sin resultado";
            }
        },

        shouldSelectCualitativoValue(examId, resultTypeValue) {
            if (!this.results[examId]) return false;
            if (!this.results[examId].cualitativo) return false;

            return this.results[examId].cualitativo.result === resultTypeValue;
        },
        getExamCuantitativoData(examId, property) {
            if (!this.results[examId]) return "";
            if (!this.results[examId].cuantitativo) return "";

            return this.results[examId].cuantitativo[property] || "";
        },
        getExamFreeTextData(examId) {
            if (!this.results[examId]) return "";
            if (!this.results[examId].freeText) return "";

            return this.results[examId].freeText.result || "";
        },

        atLeastOneHas(resultTypeId) {
            const count = this.associatedExams.filter((exam) => {
                if (exam.result_types && exam.result_types.length > 0) {
                    const hasResultType = exam.result_types.findIndex((rt) => rt.result_type === resultTypeId);
                    return hasResultType !== -1;
                }
            }).length;

            return count > 0;
        },

        getCualitativoResultTypeValues(exam) {
            if (exam.result_types && exam.result_types.length) {
                const cualitativoResultType = exam.result_types.find((rt) => rt.result_type === resultTypes.cualitativo);

                if (!cualitativoResultType) return [];

                return cualitativoResultType.result_type_values ? cualitativoResultType.result_type_values : [];
            }

            return [];
        },

        getUnitName(unitId) {
            const unit = this.allUnits.find((u) => u.id == unitId);

            if (!unit) return "Sin unidad";

            return unit.name;
        },

        getUnits() {
            this.$f7.dialog.preloader("Cargando...");

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({
                        url: "units",
                        userToken: idToken,
                    });
                })
                .then((response) => {
                    const responseData = response.data;

                    if (responseData) {
                        const units = responseData.data;

                        this.$store.commit("unit/set_all_units", units);
                    }

                    this.$f7.dialog.close();
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error);
                });
        },

        calculateResultClass(examId) {
            let examResult;

            if (this.results[examId] && this.results[examId].cuantitativo) {
                examResult = Number(this.results[examId].cuantitativo.result);
            }

            if (!examResult) return "—";

            const foundClass = classes.find((c) => c.minVal <= examResult && examResult < c.maxVal);

            if (!foundClass) return "Clase no identificada";

            return foundClass.class;
        },

        handleFocusChanged(value) {
            this.$emit("focus-changed", value);
        },
    },
};
</script>

<style scoped>
.font-normal {
    font-weight: normal;
}
table.table-collection th,
table.table-collection td {
    max-width: 100px !important;
}
</style>
