<template>
    <f7-popup :id="_uid" :opened="opened" @popup:opened="handlePopupOpened" @popup:closed="handlePopupClosed">
        <f7-page>
            <f7-navbar :title="`Elija un motivo de ${reasonText}`">
                <f7-nav-right>
                    <f7-link :popup-close="`#${_uid}`">Cancelar</f7-link>
                </f7-nav-right>
            </f7-navbar>

            <f7-block v-if="!loading">
                <div class="margin-bottom-half margin-horizontal-half">Crear y seleccionar nuevo motivo</div>
                <f7-row class="margin-horizontal-half margin-bottom">
                    <f7-col width="80">
                        <f7-input outline class="padding-half" type="text" :value="newReason" @input="newReason = $event.target.value"></f7-input>
                    </f7-col>
                    <f7-col width="20">
                        <f7-button fill @click="createNewReason">Crear</f7-button>
                    </f7-col>
                </f7-row>

                <f7-list no-hairlines media-list class="no-margin-top thin-list">
                    <f7-list-item v-for="reason in reasons" :key="reason.id">
                        <div slot="title">
                            {{ reason.name }}
                        </div>

                        <div slot="after">
                            <f7-button @click="selectReason(reason)" small>Seleccionar</f7-button>
                        </div>
                    </f7-list-item>
                </f7-list>
            </f7-block>
            <template v-else>
                <f7-block class="row demo-preloaders align-items-stretch text-align-center no-margin no-padding margin-top">
                    <f7-col>
                        <f7-preloader></f7-preloader>
                    </f7-col>
                </f7-block>
            </template>
        </f7-page>
    </f7-popup>
</template>

<script>
import { mapActions } from "vuex";

export default {
    props: {
        opened: Boolean,
        type: String,
    },

    data() {
        return {
            loading: false,

            reasons: [],

            newReason: "",
        };
    },

    computed: {
        reasonText() {
            switch (this.type) {
                case "rejection":
                    return "rechazo";

                case "pending":
                    return "pendiente";
            }
        },

        endpointBytype() {
            switch (this.type) {
                case "rejection":
                    return "reasons/rejection";

                case "pendiente":
                default:
                    return "reasons/pending";
            }
        },
    },

    methods: {
        ...mapActions(["getAPIInfo", "createNewInfo"]),

        selectReason(reason) {
            this.$emit("reason-selected", reason);
        },

        createNewReason() {
            if (this.newReason.length === 0) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Debe indicar una razón");
                return;
            }

            this.$f7.dialog.preloader("Creando nueva razón...");

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.createNewInfo({
                        url: this.endpointBytype,
                        userToken: idToken,
                        data: {
                            name: this.newReason,
                        },
                    });
                })
                .then((response) => {
                    const createdReason = response.data.data;

                    this.$emit("reason-selected", createdReason);

                    this.newReason = "";

                    this.$f7.dialog.close();
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error);
                });
        },

        handlePopupOpened() {
            this.loading = true;

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({
                        url: this.endpointBytype,
                        userToken: idToken,
                    });
                })
                .then((response) => {
                    this.reasons = response.data.data;

                    this.$f7.dialog.close();
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error);
                })
                .finally(() => (this.loading = false));
        },

        handlePopupClosed() {
            this.reasons = [];
            this.newReason = "";
            this.$emit("popup-closed");
        },
    },
};
</script>
