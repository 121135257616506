<template>
    <f7-page >
        <f7-navbar title="Asignar secciones">
            <f7-nav-right>
                <f7-link popup-close>Cerrar</f7-link>
            </f7-nav-right>
        </f7-navbar>
        <f7-block>
            <f7-row>
                <template v-for="item of section">
                    <f7-col width="50" :key="item.id">
                        <f7-list no-hairlines-md>
                            <f7-list-item :checked="checkedBox(item.id)" checkbox @change="changeSection($event.target.checked,item.id)" :title="item.name"></f7-list-item>
                        </f7-list>
                    </f7-col>

                </template>
                
            </f7-row>
        </f7-block>
    </f7-page>
</template>

<script>

import moment from 'moment';
import { mapState } from 'vuex';
export default {
    props : {
        sections : {
            required : true
        }
    },
    computed : {
        ...mapState(["section"]),
        
    },
    methods :{
        changeSection(check, section){
            
            this.$emit("changeSections", { check , section })
            this.$forceUpdate()
        },
        checkedBox(value){
            
            for(const item of this.sections){
                console.log(item)
                if(item == value){
                    return true
                }
            }
            return false
        }
    }
  
}
</script>

<style scoped>
.mb-2{
    margin-bottom: 4% !important;
    

}
.mb-1{
    margin-bottom: 3% !important;
    
}
.align-left{
    text-align: left;
}
.align-center{
    text-align: center !important;
}
.padding-1{
    padding: 0% 1%;
}
.toast{
    left: 45% !important;
}
.toast-text{
    font-size: 1.3em;
}
.icon{
    font-size: 13px;
}
</style>