<template>
    <f7-page name="patients-list">
        <f7-navbar title="Mantenedor de Pacientes"></f7-navbar>

        <f7-block>
            <f7-card outline>
                <f7-card-header>
                    <div class="text-color-gray">Buscador de pacientes</div>
                </f7-card-header>
                <f7-card-content>
                    <patients-search @fields-changed="handlePatientFieldsChanged" @is-loading="handlePatientsSearchIsLoading">
                        <template #search-footer>
                            <f7-button class="margin-horizontal margin-bottom" fill large raised @click="handleSearchPatients">
                                <f7-icon material="search"></f7-icon>
                                Buscar pacientes
                            </f7-button>
                        </template>
                    </patients-search>
                </f7-card-content>
            </f7-card>

            <f7-block v-if="!patientsSearchIsLoading">
                <h3>Pacientes encontrados: {{ patientsPagination.totalRows }}</h3>

                <div v-if="patientsPagination.totalRows" class="margin-bottom">
                    Mostrando {{ patients.length }} de {{ patientsPagination.totalRows }} pacientes
                </div>

                <patients-table :patients="patients" :pagination="patientsPagination" @load-more="handleLoadMorePatients">
                    <template #table-header>
                        <th>Documento</th>
                        <th>Nombre</th>
                        <th>Edad</th>
                        <th>Previsión</th>
                        <th></th>
                    </template>

                    <template #table-row="{ patient, f }">
                        <td>{{ f.patientDocumentInfo(patient) }}</td>
                        <td>{{ f.patientFullName(patient) }}</td>
                        <td>{{ patientAge(patient.birth_date, "YYYY-MM-DD") }}</td>
                        <td>{{ f.patientPrevision(patient) }}</td>
                        <td>
                            <f7-button fill small @click="handleOpenEditPatient(patient)">
                                <span v-if="misprivx.update_patient">Editar</span>
                                <span v-else>Ver</span>
                            </f7-button>
                        </td>
                    </template>
                </patients-table>
            </f7-block>
        </f7-block>

        <create-patient-popup
            :opened="createPatientPopupOpened"
            @patient-created="handlePatientCreated"
            @popup-closed="createPatientPopupOpened = false"
        ></create-patient-popup>

        <create-patient-popup
            edit
            :readonly="!misprivx.update_patient"
            :opened="editPatientPopupOpened"
            :patient="viewingPatient"
            @patient-edited="handlePatientEdited"
            @popup-closed="handleCloseEditPatient"
        ></create-patient-popup>

        <f7-fab position="right-bottom" slot="fixed" color="teal">
            <f7-icon material="add"></f7-icon>
            <f7-icon ios="f7:close" aurora="f7:close" md="material:close"></f7-icon>
            <f7-fab-buttons position="top">
                <f7-fab-button fab-close @click="createPatientPopupOpened = true" label="Crear nuevo paciente">
                    <f7-icon material="playlist_add"></f7-icon>
                </f7-fab-button>
            </f7-fab-buttons>
        </f7-fab>
    </f7-page>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { _patientAge } from "@/app/helpers";
import SearchMixin from "@/app/mixins/search-mixin.js";
import PatientsSearch from "@/app/components-migration/patients-search.vue";
import PatientsTable from "@/app/components-migration/patients-table.vue";
import CreatePatientPopup from "@/app/admission-migration/components/create-patient-popup.vue";
export default {
    mixins: [SearchMixin],

    components: {
        "patients-search": PatientsSearch,
        "patients-table": PatientsTable,
        "create-patient-popup": CreatePatientPopup,
    },

    data() {
        return {
            patientAge: _patientAge,

            patientsSearchIsLoading: true,
            searchPatientFields: {},

            patients: [],
            patientsPagination: {},

            createPatientPopupOpened: false,
            editPatientPopupOpened: false,

            viewingPatient: {},
        };
    },

    computed: {
        ...mapState(["misprivx"]),

        searchQueryString() {
            const queryString = this.queryStringFromOptions(this.searchPatientFields, {
                rowsPerPage: 100,
            });

            return queryString;
        },
    },

    methods: {
        ...mapActions(["getAPIInfo"]),

        handlePatientsSearchIsLoading(isLoading) {
            this.patientsSearchIsLoading = isLoading;
        },
        handlePatientFieldsChanged(patientFields) {
            this.searchPatientFields = patientFields;
            console.log("patient fields", patientFields);
        },

        handleSearchPatients() {
            this.$f7.dialog.preloader("Buscando pacientes...");

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({
                        url: "patients/search?" + this.searchQueryString,
                        userToken: idToken,
                    });
                })
                .then((response) => {
                    console.log("response", response);
                    const { data } = response;

                    this.patients = data.data;
                    this.patientsPagination = data.pagination;

                    this.$f7.dialog.close();
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error);
                });
        },

        handleLoadMorePatients() {
            this.$f7.dialog.preloader("Cargando más pacientes...");

            let queryString = this.searchQueryString;

            const page = this.patientsPagination.page + 1;

            queryString += `&page=${page}`;

            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return this.getAPIInfo({
                        url: "patients/search?" + queryString,
                        userToken: idToken,
                    });
                })
                .then((response) => {
                    const responseData = response.data;

                    if (responseData.data && responseData.data.length) {
                        this.patients.push(...responseData.data);
                        this.patientsPagination = responseData.pagination;
                    }

                    this.$f7.dialog.close();
                })
                .catch((error) => {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error);
                });
        },

        handlePatientCreated(patient) {
            this.createPatientPopupOpened = false;

            if (this.patients.length === 0) {
                this.patients.push(patient);
            }

            this.$f7.toast.show({
                text: "Paciente creado con éxito",
                closeTimeout: 2000,
            });
        },

        handleOpenEditPatient(patient) {
            this.editPatientPopupOpened = true;
            this.viewingPatient = patient;
        },
        handleCloseEditPatient() {
            this.editPatientPopupOpened = false;
            this.viewingPatient = {};
        },

        handlePatientEdited(patient) {
            const index = this.patients.findIndex((p) => p.id === patient.id);

            this.$set(this.patients, index, patient);

            this.handleCloseEditPatient();
        },
    },
};
</script>
