<template>
    <f7-page name="institutionDetails">
        <f7-navbar title="Detalles institución" back-link back-link-url="/migration/mantenedores/institutions" back-link-force></f7-navbar>
        <f7-block-title v-if="!viewingInstitution">
            Seleccione alguna institución
        </f7-block-title>

        <f7-block v-else>
            <f7-card>
                <f7-card-header>
                    <f7-block-title medium>{{ viewingInstitution.name }}</f7-block-title>
                </f7-card-header>
                <f7-card-content>
                    <f7-block-title class="no-margin-top no-margin-left">DATOS INSTITUCIÓN</f7-block-title>
                    <p class="text-color-red" v-if="viewingInstitution.enabled === 0">Institución inactiva</p>
                    <p>
                        <b>Tipo identificador:</b>
                        {{ viewingInstitution.identifier_type ? viewingInstitution.identifier_type : "Sin tipo de identificador" }}
                    </p>
                    <p>
                        <b>País:</b>
                        {{ viewingInstitution.country ? viewingInstitution.country.name_es : "Sin país" }}
                    </p>
                    <p>
                        <b>Dirección:</b>
                        {{ viewingInstitution.address ? viewingInstitution.address : "Sin dirección" }}
                    </p>

                    <f7-block-title class="no-margin-top no-margin-left">DATOS DEL CONTACTO</f7-block-title>
                    <p>
                        <b>Nombre del contacto:</b>
                        {{ viewingInstitution.contact_name ? viewingInstitution.contact_name : "Sin nombre de contacto" }}
                    </p>
                    <p>
                        <b>Email:</b>
                        {{ viewingInstitution.email ? viewingInstitution.email : "Sin email de contacto" }}
                    </p>
                </f7-card-content>
                <f7-card-footer>
                    <f7-button fill small round icon-material="edit" @click="goToEdit">Editar</f7-button>
                    <f7-button fill small round icon-material="attach_money" @click="goToPrices">Precios</f7-button>
                    <f7-button fill small round icon-material="delete" color="red" @click="toggleActive(0)" v-if="viewingInstitution.enabled === 1">
                        Inactivar
                    </f7-button>
                    <f7-button fill small round icon-material="done" color="green" @click="toggleActive(1)" v-else>Activar</f7-button>
                </f7-card-footer>
            </f7-card>
        </f7-block>
    </f7-page>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    computed: {
        ...mapState("institutions", ["viewingInstitution"]),
    },

    methods: {
        ...mapActions(["updateInfo"]),

        goToEdit() {
            this.$f7.views.main.router.navigate("/migration/mantenedores/institutions/create-or-edit");
        },

        goToPrices() {
            this.$f7.views.main.router.navigate("/migration/mantenedores/institutions/prices");
        },

        toggleActive(status) {
            const statusText = status === 1 ? "Activar institución" : "Inactivar institución";

            this.$f7.dialog.confirm("¿Está seguro?", statusText, () => {
                this.$firebase
                    .auth()
                    .currentUser.getIdToken()
                    .then((idToken) => {
                        return this.updateInfo({
                            url: `institutions/${this.viewingInstitution.id}`,
                            userToken: idToken,
                            data: { enabled: status },
                        });
                    })
                    .then((response) => {
                        const updatedInstitution = response.data.data;

                        this.$store.dispatch("institutions/updateInstitution", updatedInstitution);

                        this.institution = updatedInstitution;

                        this.$f7.dialog.close();
                        this.$f7.toast.create({ text: "Los datos se han guardado con éxito", closeTimeout: 2500 }).open();
                    })
                    .catch((err) => {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(err.message, err.code);
                    });
            });
        },
    },
};
</script>
