<template>
    <div>
        <block-toolbar v-if="!previewMode" :block="computedBlockInfo('table-block')">
            <template #toolbar-content>
                <div class="display-flex">
                    <f7-button
                        @click="$emit('dimension-changed', { dataType: 'rows', value: 1 })"
                        small
                        icon-material="add"
                        icon-size="14"
                        class="display-flex no-margin"
                    >
                        Añadir fila
                    </f7-button>
                    <f7-button
                        @click="$emit('dimension-changed', { dataType: 'rows', value: -1 })"
                        small
                        icon-material="remove"
                        icon-size="14"
                        class="display-flex no-margin"
                        color="red"
                    >
                        Quitar fila
                    </f7-button>

                    <f7-button
                        @click="$emit('dimension-changed', { dataType: 'cols', value: 1 })"
                        small
                        icon-material="add"
                        icon-size="14"
                        class="display-flex no-margin"
                    >
                        Añadir columna
                    </f7-button>
                    <f7-button
                        @click="$emit('dimension-changed', { dataType: 'cols', value: -1 })"
                        small
                        icon-material="remove"
                        icon-size="14"
                        class="display-flex no-margin"
                        color="red"
                    >
                        Quitar columna
                    </f7-button>
                </div>
            </template>
        </block-toolbar>

        <table class="table table-sm table-bordered bg-color-white">
            <thead class="thead-default">
                <tr>
                    <th v-for="(cols, index) in dimensions.cols" :key="index">
                        <f7-input
                            v-if="!previewMode"
                            type="text"
                            @input="$emit('input', { value: $event.target.value, row: 0, col: index })"
                            @focus="$emit('focus-changed', true)"
                            @blur="$emit('focus-changed', false)"
                            :value="data[index] && data[index][0] ? data[index][0] : ''"
                            placeholder="Escriba aquí"
                            :disabled="disableInputs"
                        ></f7-input>

                        <span v-else>{{ data[index] && data[index][0] ? data[index][0] : "" }}</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, i) in dimensions.rows - 1" :key="i">
                    <td v-for="(col, j) in dimensions.cols" :key="j">
                        <f7-input
                            v-if="!previewMode"
                            type="text"
                            @input="$emit('input', { value: $event.target.value, row: i + 1, col: j })"
                            @focus="$emit('focus-changed', true)"
                            @blur="$emit('focus-changed', false)"
                            :value="data[j] && data[j][i + 1] ? data[j][i + 1] : ''"
                            placeholder="Escriba aquí"
                            :disabled="disableInputs"
                        ></f7-input>

                        <span v-else>{{ data[j] && data[j][i + 1] ? data[j][i + 1] : "" }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import BlockToolbarMixin from "@/app/components-migration/plantillas/blocks/toolbar/block-toolbar-mixin";
import BlockToolbar from "@/app/components-migration/plantillas/blocks/toolbar/block-toolbar.vue";

export default {
    mixins: [BlockToolbarMixin],

    props: {
        previewMode: Boolean,
        dimensions: Object,
        data: Array,
        disableInputs: Boolean,
    },

    components: {
        "block-toolbar": BlockToolbar,
    },
};
</script>
